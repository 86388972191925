export default function reducer(
    state = {
      isLoader:false
    },
    action
  ) {
    switch (action.type) {
    case 'SHOW_LOADER': {
        return {
            ...state,
            isLoader:true
        };
    }
    case 'HIDE_LOADER': {
        return {
            ...state,
            isLoader:false,
        };
    }
    default: {
        return { ...state };
    }
    }
  }
  