import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

class page404 extends Component {
    render() {
        return (
            <div className="container">
                <div className="pagenot-found-wrapper">
                    <h2>404 - Page not found</h2>
                    <h5>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</h5>
                    <span className="subsCribeButton"><NavLink tag={Link} to='/'>Home</NavLink></span>
                </div>
            </div>
        );
    }
}

export default page404;