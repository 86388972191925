import React, { Component } from "react";
import ReactGA from "react-ga";
import {
  Row,
  Col,
} from "reactstrap";
import { userAuthentication } from "../action/loginAction";
import { kidsCategoryEbookAction } from "../action/kidsHomeAction";
import EmailSignUpConfirmation from "./EmailSignUpConfirmation";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import {
  api_base_url,
  loginGuestToken,
  encryptDecryptKey,
  googleAnalyticsTrackingId,
} from "../config";
import axios from "axios";
import OwlCarousel from "react-owl-carousel2";
import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);
const optionss = {
  nav: true,
  rewind: true,
  autoplay: true,
  navText: ["<div></div>", "<div></div>"],
  margin: 15,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class signUpComponent extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (userDetailsObject.loginSuccess) {
        // this.props.history.push("/");
        this.props.history.push("/library");
      }
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      loginEmail: "",
      loginEmailError: "",
      loginPassword: "",
      forgetPassword: "",
      forgetPasswordError: "",
      successMessage: "",
      loginPasswordError: "",
      socialLoginError: "",
      isLoading: false,
      ForgetPasswordModal: false,
      selectedLanguage: selectedLanguage || "EN",
      clientIpAdrees: "",
      redirect: false,
      enterValidEmailError: "",
      loginPasswordChnage: "",
    };
    this.handleOnchangeEmail = this.handleOnchangeEmail.bind(this);
    this.handleOnchangePassword = this.handleOnchangePassword.bind(this);
    this.onSubmitLogin = this.onSubmitLogin.bind(this);
    this.facebookCustomButton = this.facebookCustomButton.bind(this);
    this.forgetPasswordToggle = this.forgetPasswordToggle.bind(this);
    this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this);
    this.handleOnchangeResetPassword = this.handleOnchangeResetPassword.bind(
      this
    );
    this.closePasswordModel = this.closePasswordModel.bind(this);
    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.clientIpAddress();
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }
  componentWillReceiveProps(nextProps) {
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }

  componentWillUnmount() {
    let auditionFormStatus = localStorage.getItem("auditionForm");
    if (auditionFormStatus !== "" && auditionFormStatus == "true") {
      localStorage.removeItem("auditionForm");
    }
  }

  facebookCustomButton() {
    return (
      <span>
        <img src="images/icon_facebook.png" alt="Facebook" />
        <span>{strings.loginWithFacebook}</span>
      </span>
    );
  }

  forgetPasswordToggle() {
    this.setState({
      ForgetPasswordModal: !this.state.ForgetPasswordModal,
    });
  }

  handleOnchangeEmail(event) {
    this.setState({
      loginEmail: event.target.value,
      forgetPassword: event.target.value,
    });
  }
  handleOnchangeResetPassword(event) {
    this.setState({
      forgetPassword: event.target.value,
    });
  }
  handleOnchangePassword(event) {
    this.setState({
      loginPassword: event.target.value,
    });
  }
  cartProductsCount(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/cart/", { headers: headers })
      .then((response) => {
        if (response.data.status === true) {
          this.props.dispatch({
            type: "PRODUCT_CART_COUNT",
            payload: response.data.data,
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  onSubmitLogin(event) {
    event.preventDefault();
    this.setState({
      loginEmailError: "",
      loginPasswordError: "",
      enterValidEmailError: "",
      loginPasswordChnage: "",
    });
    var isValid = false;
    if (this.state.loginEmail === "") {
      this.setState({
        loginEmailError: strings.enterEmail,
      });
      isValid = false;
    } else if (emailReg.test(this.state.loginEmail) === false) {
      this.setState({
        loginEmailError: strings.enterValidEmail,
      });
      isValid = false;
    }
    if (this.state.loginPassword === "") {
      this.setState({
        loginPasswordError: strings.enterPassword,
      });
    } else {
      isValid = true;
    }
    if (isValid) {
      this.setState({
        isLoading: true,
      });
      var apiParams = {
        login_type: "normal",
        email: this.state.loginEmail,
        password: this.state.loginPassword,
        first_name: "",
        last_name: "",
        customer_ip_address: this.state.clientIpAdrees,
      };
      var headers = {
        "Content-Type": "application/json",
        "guest-login-token": loginGuestToken,
      };
      axios
        .post(api_base_url + "/api/v1/login/", apiParams, { headers: headers })
        .then((response) => {
          this.setState({
            isLoading: false,
          });
          if (response.data["status"] === "1") {
            var apiParams = {
              name: "",
              type: "web",
              device_id: this.state.clientIpAdrees,
              registration_id: this.state.clientIpAdrees,
              token: response.data["token"],
            };
            axios
              .post(api_base_url + "/api/register-device/", apiParams)
              .then((response) => { })
              .catch((err) => {
                // console.log("Error - ", err);
              });
            let auditionFormStatus = localStorage.getItem("auditionForm");
            if (auditionFormStatus !== "" && auditionFormStatus == "true") {
              let parentThis = this;
              setTimeout(function () {
                parentThis.props.history.push("/audition");
              }, 300);
              localStorage.removeItem("auditionForm");


            } else {
              // this.props.history.push("/");
              this.props.history.push("/library");
              localStorage.removeItem("auditionForm");
            }
            this.cartProductsCount(response.data["token"]);
            this.props.dispatch(kidsCategoryEbookAction(response.data["token"]));

            // this.props.history.push("/");
            return this.props.dispatch(
              userAuthentication(
                this.state.loginEmail,
                response.data["token"],
                response.data["is_subscribed"],
                response.data["first_name"],
                response.data["last_name"],
                response.data["profile_image"],
                response.data["country_code"],
                response.data["state"],
                response.data["mobile"],
                response.data["instituteName"],
              )
            );
          } else if (
            response.data["status"] === "2" ||
            response.data["status"] === "11"
          ) {
            this.setState({
              loginPasswordError: response.data["message"],
            });
          } else if (response.data["status"] === "10") {
            this.setState({
              enterValidEmailError: response.data["message"]["email"][0],
            });
          } else if (response.data["status"] === "5") {
            this.setState({
              loginPasswordChnage: response.data["message"],
            });
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  closePasswordModel() {
    this.setState({
      ForgetPasswordModal: false,
    });
  }

  onSubmitResetPassword(e) {
    e.preventDefault();
    this.setState({
      forgetPasswordError: "",
      successMessage: "",
    });
    var isValid = false;
    var isValidEmailAddress = false;
    if (this.state.forgetPassword === "") {
      this.setState({
        forgetPasswordError: strings.enterEmail,
      });
    } else if (emailReg.test(this.state.forgetPassword) === false) {
      this.setState({
        forgetPasswordError: strings.enterValidEmail,
      });
    } else {
      isValid = true;
    }
    if (isValid) {
      var apiParams = {
        email: this.state.forgetPassword,
      };
      axios
        .post(api_base_url + "/api/reset-password/", apiParams)
        .then((response) => {
          if (response.data.status === true) {
            this.setState({
              successMessage:
                "Your request has been proceed successfully, Please check your email address for reference.",
              forgetPassword: "",
            });
            setTimeout(() => {
              this.closePasswordModel();
            }, 3000);
          }
          if (response.data.status === false) {
            this.setState({
              forgetPasswordError: response.data.message[0],
              forgetPassword: "",
            });
            setTimeout(() => {
              this.closePasswordModel();
            }, 3000);
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
          this.setState({
            isLoading: false,
          });
        });
    }
  }
  clientIpAddress() {
    axios
      .get("http://jsonip.com/")
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            clientIpAdrees: response.data.ip,
          });
        }
      })
      .catch((err) => {
        // console.log("Error - ", err);
      });
  }

  render() {
    const componentClicked = () => {
      this.setState({
        socialLoginError: "",
      });
    };
    const responseSuccessGoogle = (googleResponse) => {
      if (googleResponse["profileObj"] && googleResponse["accessToken"]) {
        this.setState({
          isLoading: true,
        });
        var apiParams = {
          login_type: "google",
          email: googleResponse["profileObj"]["email"] || "",
          uid: googleResponse["profileObj"]["googleId"] || "",
          access_token: googleResponse["accessToken"] || "",
          customer_ip_address: this.state.clientIpAdrees,
          extra_data: googleResponse || {},
        };
        var headers = {
          "Content-Type": "application/json",
          "guest-login-token": loginGuestToken,
        };
        axios
          .post(api_base_url + "/api/v1/login/", apiParams, {
            headers: headers,
          })
          .then((response) => {
            this.setState({
              isLoading: false,
            });
            if (response.data["status"] === "1") {
              var apiParams = {
                name: "",
                type: "web",
                device_id: this.state.clientIpAdrees,
                registration_id: this.state.clientIpAdrees,
                token: response.data["token"],
              };
              axios
                .post(api_base_url + "/api/register-device/", apiParams)
                .then((response) => { })
                .catch((err) => {
                  // console.log("Error - ", err);
                });

              this.cartProductsCount(response.data["token"]);
              let auditionFormStatus = localStorage.getItem("auditionForm");
              if (auditionFormStatus !== "" && auditionFormStatus == "true") {
                let parentThis = this;
                setTimeout(function () {
                  parentThis.props.history.push("/audition");
                }, 300);
                localStorage.removeItem("auditionForm");

              } else {
                // this.props.history.push("/");
                this.props.history.push("/library");
                localStorage.removeItem("auditionForm");
              }

              return this.props.dispatch(
                userAuthentication(
                  googleResponse["profileObj"]["email"] || "",
                  response.data["token"],
                  response.data["is_subscribed"],
                  response.data["first_name"],
                  response.data["last_name"],
                  response.data["profile_image"],
                  response.data["country_code"],
                  response.data["state"],
                  response.data["mobile"],
                  response.data["instituteName"],
                )
              );
            } else if (
              response.data["status"] === "2" ||
              response.data["status"] === "10" ||
              response.data["status"] === "11"
            ) {
              this.setState({
                socialLoginError: response.data["message"],
              });
            }
          })
          .catch((err) => {
            // console.log("Error - ", err);
            this.setState({
              isLoading: false,
            });
          });
      }
    };
    const responseFailureGoogle = (error) => {
      // console.log("Google Error", error);
    };
    const responseFacebook = (socialLoginResponse) => {
      if (socialLoginResponse["email"] != undefined) {
        this.setState({
          isLoading: true,
        });
        var apiParams = {
          login_type: "facebook",
          email: socialLoginResponse["email"] || "",
          uid: socialLoginResponse["userID"] || "",
          access_token: socialLoginResponse["accessToken"] || "",
          customer_ip_address: this.state.clientIpAdrees,
          extra_data: socialLoginResponse || {},
        };
        var headers = {
          "Content-Type": "application/json",
          "guest-login-token": loginGuestToken,
        };
        axios
          .post(api_base_url + "/api/v1/login/", apiParams, {
            headers: headers,
          })
          .then((response) => {
            this.setState({
              isLoading: false,
            });
            if (response.data["status"] === "1") {
              var apiParams = {
                name: "",
                type: "web",
                device_id: this.state.clientIpAdrees,
                registration_id: this.state.clientIpAdrees,
                token: response.data["token"],
              };
              axios
                .post(api_base_url + "/api/register-device/", apiParams)
                .then((response) => { })
                .catch((err) => {
                  // console.log("Error - ", err);
                });
              this.cartProductsCount(response.data["token"]);
              let auditionFormStatus = localStorage.getItem("auditionForm");
              if (auditionFormStatus !== "" && auditionFormStatus == "true") {
                let parentThis = this;
                setTimeout(function () {
                  parentThis.props.history.push("/audition");
                }, 300);
                localStorage.removeItem("auditionForm");

              } else {
                // this.props.history.push("/");
                this.props.history.push("/library");
                localStorage.removeItem("auditionForm");
              }

              return this.props.dispatch(
                userAuthentication(
                  socialLoginResponse["email"] || "",
                  response.data["token"],
                  response.data["is_subscribed"],
                  response.data["first_name"],
                  response.data["last_name"],
                  response.data["profile_image"],
                  response.data["country_code"],
                  response.data["state"],
                  response.data["mobile"],
                  response.data["instituteName"],
                )
              );
            } else if (
              response.data["status"] === "2" ||
              response.data["status"] === "10" ||
              response.data["status"] === "11"
            ) {
              this.setState({
                socialLoginError: response.data["message"],
              });
            }
          })
          .catch((err) => {
            // console.log("Error - ", err);
            this.setState({
              isLoading: false,
            });
          });
      }
    };
    return (
      <div>
        <div className="container">
          <div className="sign-up-first-section">
            <div className="text-center">
              <h1 className="sign-up-heading-text">Welcome to the audio world of Snovel.</h1>
              <h1 className="sign-up-heading-text">Your newest way of experiencing content.</h1>
            </div>
            <div className="text-center">
              <h2 className="sign-up-sub-heading-text">A truly immersive experience with world-class quality content.</h2>
            </div>
            <div className="text-center register-now-try-free-snovel-outer">
              <span onClick={() => { window.scrollTo(0, document.body.scrollHeight); }}>Register Now & Try for free</span>
            </div>
          </div>
        </div>
        <div className="kids-main-wrapper">
          <section className="baner-section kidsHomePage-Banner">
            {this.state.selectedLanguage === "EN" ? (
              <OwlCarousel id="homePage" ref="car" options={optionss}>
                <div>
                  <img src="/images/sign-up-banner-01.png" />
                </div>
              </OwlCarousel>
            ) : (
              <OwlCarousel id="homePage" ref="car" options={optionss}>
                <div>
                  <img src="/images/sign-up-banner-01.png" />
                </div>
              </OwlCarousel>
            )}
          </section>
          <div className="clearfix"></div>
          <div>
            {this.state.isLoading ? (
              <div id="loading">
                <div id="loading-center">
                  <div id="loading-center-absolute">
                    <img src="images/loader.gif" />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="container">
              <Row className="custom-signup-section">
                <Col md="3" lg="3" sm="3" xs="6" className="text-center">
                  <span>Huge collection<br />in various genre</span>
                </Col>
                <Col md="3" lg="3" sm="3" xs="6" className="text-center">
                  <span>Popular stories<br />by famous authors</span>
                </Col>
                <Col md="3" lg="3" sm="3" xs="6" className="text-center vertical-center-section">
                  <span>Curated content</span>
                </Col>
                <Col md="3" lg="3" sm="3" xs="6" className="text-center vertical-center-section">
                  <span>Happy listeners</span>
                </Col>
              </Row>
              <div className="welComeWrp">
                <div class="welcomeBack signup-model signup-page">
                  <br />
                  <h4 className="createAccount">{strings.WelcometoSnovel}</h4>
                  <h5 class="model-title-sub">{strings.JoinSnovel}</h5>
                  <EmailSignUpConfirmation isFromNewSignUp={true} />
                </div>
                <div class="loginErrorMsg">{this.state.socialLoginError}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStatesToProps(globalData) {
  return {
    authData: globalData.authonticationDetails,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(signUpComponent));
