import React, { Component } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from "reactstrap";
import classnames from 'classnames';
import {
  api_base_url,
  dashboardToken,
  encryptDecryptKey,
  googleAnalyticsTrackingId,
  getHoursFormat,
} from "../config";
import { CircularProgressbar } from 'react-circular-progressbar';
import axios from "axios";
import SimpleCrypto from "simple-crypto-js";
import { withRouter } from "react-router-dom";
import { languageLabCategoryAction } from "../action/languageLabAction";
import swal from "sweetalert";
import {

  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormGroup,
} from "reactstrap";
import OwlCarousel from "react-owl-carousel2";
import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);
const optionss = {
  nav: true,
  rewind: true,
  autoplay: true,
  navText: ["<div></div>", "<div></div>"],
  margin: 15,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};
class LanguageLabPage extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      selectedLanguage: selectedLanguage || "EN",
      isLoading: false,
      audioObject: undefined,
      selectedIndex: "",
      latestLaunchAudio: {},
      latestLaunchLastPlayed: "",
      kidsPopularAudio: {},
      kidsPopularLastPlayed: "",
      latestLauchApiProducts: [],
      kidsPopularPicksData: [],
      kidsCategoryList: [],
      kidsCategoryProductList: [],
      subCategoryId: [],
      kidsCategoryLastPlayed: "",
      isCategoryAPIcall: false,
      userToken: token || "",
      activeTab: 1,
      modalhandler: false,
      completeduser: [],
      audioTicketcomplete: false,
      completedAudioListening: [],
      completedAudioListeningMR: [],
      isHovering: false
    };
    ReactGA.pageview("/language-lab");


    this.userCompletionAudio = this.userCompletionAudio.bind(this);


    this.userCompletionCertificate = this.userCompletionCertificate.bind(this)
    this.togglehandler = this.togglehandler.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }
  componentDidMount() {
    if (!this.state.isCategoryAPIcall) {
      let pathName = this.props.location && this.props.location.pathname ? this.props.location.pathname : "";
      let splitArray = pathName.split("/");
      let currentSlug = splitArray[splitArray.length - 1];
      this.props.dispatch(languageLabCategoryAction(currentSlug));
    }

    this.userCompletionAudio();


  }
  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.isPauseInnerProduct === true && this.state.audioObject) {
      this.state.audioObject.pause();
      this.setState({
        latestLaunchLastPlayed: "",
        kidsPopularLastPlayed: "",
        kidsCategoryLastPlayed: "",
      });
      this.props.onPauseSample("innerProduct");
    }
    if (
      nextProps.languageLabCategoryReducer !== prevState.languageLabCategoryReducer &&
      nextProps.languageLabCategoryReducer.length > 0
    ) {
      this.setState({
        kidsCategoryList: nextProps.languageLabCategoryReducer,
        isCategoryAPIcall: true,
      });


    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  componentWillUnmount() {
    if (this.state.audioObject) {
      this.state.audioObject.pause();
    }
  }

  productDetailsId(productID, slug) {
    localStorage.setItem("productId", productID);
    this.props.history.push("/audio-course-product-details/" + productID + "/" + slug);
  }


  togglehandler() {

    this.setState({
      modalhandler: !this.state.modalhandler
    });
  }


  kidsCategoryLaunchPlayAudio(map_url) {
    if (this.state.kidsPopularAudio[map_url]) {
      this.setState({
        kidsCategoryLastPlayed: map_url,
        latestLaunchLastPlayed: "",
        kidsPopularLastPlayed: "",
      });
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                kidsCategoryLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newLatestLaunchAudio = {};
            newLatestLaunchAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              kidsPopularAudio: newLatestLaunchAudio,
              kidsCategoryLastPlayed: map_url,
              latestLaunchLastPlayed: "",
              kidsPopularLastPlayed: "",
            });
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }

  kidsCategoryLaunchPauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      kidsCategoryLastPlayed: "",
    });
  }

  // handleCompletion() {
  //   if (true) {
  //     this.setState({
  //       audioTicketcomplete: !this.state.audioTicketcomplete
  //     });
  //   } else {
  //     this.setState({ modalhandler: true })
  //   }


  // }




  userCompletionAudio() {

    var headers = {
      "Content-Type": "application/json",
      "guest-product-token": this.state.userToken,
    };

    axios.get(api_base_url + '/api/audio-type-completion/language-lab/', { headers: headers })
      .then(response => {


        let usercertify = []
        usercertify.push(response.data)
        this.setState({ completeduser: usercertify })
      })
  }

  userCompletionCertificate(language) {
    var headers = {
      "Content-Type": "application/json",
      "guest-product-token": this.state.userToken,
    }
    axios.get(api_base_url + '/api/language-lab-completion-certificate/' + language.toLowerCase() + '/', { headers: headers })
      .then((response) => {
        if (response.data.status) {
          setTimeout(() => {
            window.open(response.data.certificate_path);
          }, 100);
        }


      })
  }

  // handleClick() {
  //   this.userCompletionCertificate()


  //   window.open(this.state.completedAudioListening[0].certificate_path);



  // }

  // userCompletionCertificatemr() {
  //   var headers = {
  //     "Content-Type": "application/json",
  //     "guest-product-token": this.state.userToken,
  //   }
  //   axios.get(api_base_url + '/api/language-lab-completion-certificate/mr/', { headers: headers })
  //     .then((response) => {
  //  
  //       let audiocertifyMR = [];
  //       audiocertifyMR.push(response.data);
  //       this.setState({ completedAudioListeningMR: audiocertifyMR })
  //     })
  // }


  handleMouseOver() {
    this.setState({ isHovering: true });
  };

  handleMouseOut() {
    this.setState({ isHovering: false });
  };


  render() {
    return (
      <div className="kids-main-wrapper language-lab-main-wrapper">
        {this.props.loader.isLoader && (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        )}
        {/* <section className="baner-section kidsHomePage-Banner">
          {this.state.selectedLanguage === "EN" ? (
            <OwlCarousel id="homePage" ref="car" options={optionss}>
              <div>
                <img src="/images/languageLabBanner01.png" />
              </div>
            </OwlCarousel>
          ) : (
            <OwlCarousel id="homePage" ref="car" options={optionss}>
              <div>
                <img src="/images/languageLabBanner01.png" />
              </div>
            </OwlCarousel>
          )}
        </section> */}
        <div className="container-fluid stellar-wrapper-footer">
          {
            strings._language === strings._defaultLanguage ? (
              <img src="/images/web banner_01-01.jpg"
                className="langauge-lab-img"

              />

            ) : (

              <img src="/images/web banner_01marathi-01.jpg"
                className="langauge-lab-img"
              />
            )
          }

        </div>
        <div className="contentMainWrap mt-5">
          <div className="container language-lab-outer">
            <Row>
              <Col md="12">
                <h2 className="categoryCommonTitle">{strings.audionCourse}</h2>
                <p className="">{strings.audionCourseDescription}</p>
                <h2 className="categoryCommonTitle">{strings.whatIsLanguageLab}</h2>
                <p className="">{strings.languageLabDescription}</p>
                <div className="purpleStrip">{strings.impactfulAudioCourses}</div>
              </Col>
            </Row>
            {this.state.kidsCategoryList.length > 0 && (
              <Row className="catTabsPnl">
                <Col lg="3" sm="3">
                  <h3>{strings.categories}</h3>
                  <div className="catLinks">
                    <Nav tabs>
                      {this.state.kidsCategoryList.map((cat, catIndex) => {
                        return (
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === catIndex + 1 })}
                              onClick={() => {
                                this.setState({
                                  activeTab: catIndex + 1,
                                });
                              }}
                            >
                              {
                                cat.custom_translation[this.state.selectedLanguage] ?
                                  cat.custom_translation[this.state.selectedLanguage].name
                                  :
                                  cat.custom_translation["EN"].name
                              }
                            </NavLink>
                          </NavItem>
                        )
                      })}
                    </Nav>
                  </div>
                </Col>

                <Col lg="9" sm="9" className="catContentPnl">
                  {this.state.kidsCategoryList.length > 0 && (
                    <TabContent activeTab={this.state.activeTab}>
                      {this.state.kidsCategoryList.map((cat, catIndex) => {
                        return (
                          <TabPane tabId={catIndex + 1}>
                            <h3 className="">
                              <>
                                <div style={{ display: 'flex', position: "relative" }}>
                                  {
                                    cat.custom_translation[this.state.selectedLanguage] ?
                                      cat.custom_translation[this.state.selectedLanguage].name
                                      :
                                      cat.custom_translation["EN"].name
                                  }




                                  {this.state.completeduser.map((ele) => (
                                    <div className="circularProgress-language-two">
                                      <CircularProgressbar
                                        value={ele.audio_type_listening_completion || 0}
                                        text={`${Math.round(ele.audio_type_listening_completion) || 0}%`}
                                        strokeWidth={5}

                                      />

                                    </div>

                                  ))

                                  }




                                  {/* 
                                  {
                                    this.state.completeduser.map(ele => (
                                      ele.audio_type_listening_completion !== 100 ? (

                                        <a href={ele.certificate_path}
                                          download="proposed_file_name" target='_blank'
                                          className="subscribe_btn_excersise_lab"

                                        >
                                          excercise
                                        </a>

                                      ) : (
                                        <button className="subscribe_btn_excersise_lab"
                                          onClick={this.togglehandler}>Excersice</button>
                                      )
                                    ))
                                  } */}

                                  {/* {
                                    strings._language === strings._defaultLanguage ? ( */}
                                  {
                                    this.state.completeduser.map(ele => (
                                      ele.audio_type_listening_completion === 100 ? (



                                        <>

                                          <a
                                            onClick={() => this.userCompletionCertificate(this.state.selectedLanguage)}
                                            // href={ele.certificate_path}
                                            className="subscribe_btn_excersise_lab"
                                            download="proposed_file_name"
                                          // target='_blank'
                                          >

                                            <img src="/images/downloadPdf.png"
                                              className="rounded-circle dowmload-cert"
                                              alt="download certificate"
                                              onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}
                                            />
                                            {this.state.isHovering && <p className="language-lab-out">{strings.certificate}</p>}
                                          </a>
                                          <p className="subscribe_btn_excersise_lab11">"Congratulations! Download your audio course completion certificate now""</p>

                                        </>



                                      ) : (
                                        <div>

                                          <a onClick={this.togglehandler}
                                            className="subscribe_btn_excersise_lab"
                                          >
                                            <img src="/images/downloadPdf.png" alt=""
                                              className="rounded-circle dowmload-cert"
                                              onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}
                                            />
                                            {this.state.isHovering && <p className="language-lab-out">{strings.certificate}</p>}
                                          </a>

                                        </div>
                                      )
                                    ))
                                  }
                                  {/* ) : (
                                      this.state.completeduser.map(ele => (
                                        ele.audio_type_listening_completion !== 100 ? (

                                          this.state.completedAudioListeningMR.map((ele) => (

                                            <>

                                              <a
                                                href={ele.certificate_path}
                                                className="subscribe_btn_excersise_lab"
                                                download="proposed_file_name" target='_blank'>

                                                <img src="/images/downloadPdf.png"
                                                  className="rounded-circle dowmload-cert"
                                                  alt="download certificate"
                                                  onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}
                                                />
                                                {this.state.isHovering && <p className="language-lab-out">{strings.certificate}</p>}
                                              </a>
                                              <p className="subscribe_btn_excersise_lab11">"Congratulations! Download your audio course completion certificate now""</p>

                                            </>
                                          ))


                                        ) : (
                                          <div>

                                            <a onClick={this.togglehandler}
                                              className="subscribe_btn_excersise_lab"
                                            >
                                              <img src="/images/downloadPdf.png" alt=""
                                                className="rounded-circle dowmload-cert"
                                                onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}
                                              />
                                              {this.state.isHovering && <p className="language-lab-out">{strings.certificate}</p>}
                                            </a>

                                          </div>
                                        )
                                      ))

                                    )
                                  } */}

                                </div>
                              </>
                            </h3>



                            {
                              <Modal isOpen={this.state.modalhandler} toggle={this.togglehandler}
                                style={{ marginTop: "50vh", padding: "15px" }}
                              >
                                <ModalHeader toggle={this.togglehandler} className="modal-text">{strings.downloadCert}</ModalHeader>
                                <ModalBody>
                                  <p className="language-lab-out">{strings.ExcersiseMsgLab}</p>
                                </ModalBody>


                              </Modal>
                            }

                            {(cat.sliders || []).map((product, productIndex) => {
                              return (
                                <div className="tabContentWrp">
                                  <div
                                    className="thmbWrp"
                                  >
                                    {product.is_free && (
                                      <span className="freeProduct">{strings.FreeText}</span>
                                    )}
                                    <img
                                      onClick={() => {
                                        this.productDetailsId(
                                          product.id,
                                          product.product_slug
                                        );
                                      }}
                                      className="imgThmb cursorPointer"
                                      src={
                                        (product.images || []).length > 0 ?
                                          product.images[0]["image2"] || "/images/default.jpg"
                                          :
                                          "/images/default.jpg"
                                      }
                                    />
                                    {(product.sampletracks || []).length > 0 && (
                                      <div>
                                        {product.sampletracks[0].map_url !=
                                          this.state.kidsCategoryLastPlayed && (
                                            <span
                                              className="btnPlay cursorPointer"
                                              onClick={() => {
                                                this.kidsCategoryLaunchPlayAudio(
                                                  product.sampletracks[0].map_url
                                                );
                                              }}
                                            >
                                              <img src="images/play.png" />
                                            </span>
                                          )}
                                        {product.sampletracks[0].map_url ==
                                          this.state.kidsCategoryLastPlayed && (
                                            <span
                                              className="btnPlay cursorPointer"
                                              onClick={() => {
                                                this.kidsCategoryLaunchPauseAudio(
                                                  product.sampletracks[0].map_url
                                                );
                                              }}
                                            >
                                              <img src="images/round-pause-button.svg" />
                                            </span>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="tabrgtContent">
                                    <h4>
                                      <span
                                        className="cursorPointer"
                                        onClick={() => {
                                          this.productDetailsId(
                                            product.id,
                                            product.product_slug
                                          );
                                        }}
                                      >
                                        {
                                          product.custom_translation[this.state.selectedLanguage] ?
                                            product.custom_translation[this.state.selectedLanguage].name
                                            :
                                            product.custom_translation["EN"].name
                                        }
                                      </span>
                                    </h4>
                                    <div className="timeCounterPnl">
                                      <img src="images/chapter-counter.jpg" alt="" title="" /> {product.total_tracks || 0} {(product.total_tracks || 0) > 1 ? strings.chapters : strings.chapter} <span className="pipe">|</span>
                                      <img src="images/time-icn.jpg" alt="" title="" /> {getHoursFormat(product.total_tracks_length || 0)}
                                    </div>
                                    <p
                                      className=""
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          product.custom_translation[this.state.selectedLanguage] ?
                                            product.custom_translation[this.state.selectedLanguage].shortDescription
                                            :
                                            product.custom_translation["EN"].shortDescription
                                      }}
                                    ></p>
                                    <p className="readMoreLink">
                                      <span
                                        className="cursorPointer"
                                        onClick={() => {
                                          this.productDetailsId(
                                            product.id,
                                            product.product_slug
                                          );
                                        }}
                                      >
                                        {strings.readMoreText}
                                      </span>
                                    </p>
                                  </div>
                                  <br className="clr" />
                                </div>
                              )
                            })}
                          </TabPane>
                        )
                      })}
                    </TabContent>
                  )}
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    languageTab: state.languageTab,
    loader: state.loader,
    languageLabCategoryReducer: state.languageLabCategoryReducer.languageLabCategoryList,
    authData: state.authonticationDetails,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(LanguageLabPage));
