import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { googleAnalyticsTrackingId } from '../../config';
import $ from "jquery";
ReactGA.initialize(googleAnalyticsTrackingId);

class proceedForPayment extends Component {
    constructor(props) {
        super(props);
        var paymentCode = "";
        var isPayAutoRenewal = false;
        if (this.props.location.state === undefined) {
            this.props.history.push({ pathname: "/" });
        } else {
            paymentCode = this.props.location.state.detail;
            isPayAutoRenewal = this.props.location.state.isPayAutoRenewal;
            if (isPayAutoRenewal) {
                var nth = 0;
                paymentCode = paymentCode.replace(/<script/g, function (match, i, original) {
                    nth++;
                    return (nth === 1) ? "<script id='srcScript'" : ((nth === 2) ? "<script id='customScript'" : match);
                });
            }
        }
        this.state = {
            paymentMethod: paymentCode,
            isPayAutoRenewal: isPayAutoRenewal,
            isShowLoader: true,
        }
        ReactGA.pageview(this.props.location.pathname);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.state.paymentMethod) {
            if (this.state.isPayAutoRenewal) {
                var scriptSrc = $('#outerElement #srcScript').attr("src");
                const anotherScript = document.createElement("script");
                anotherScript.src = scriptSrc;
                anotherScript.async = true;
                document.body.appendChild(anotherScript);
                setTimeout(() => {
                    var scriptCode = $('#outerElement #customScript').html();
                    const script = document.createElement("script");
                    script.innerHTML = scriptCode;
                    script.async = true;
                    document.body.appendChild(script);
                    this.setState({ isShowLoader: false });
                }, 4000);
            } else {
                var ccAvenueForm = document.getElementById('nonseamless');
                if (ccAvenueForm) {
                    document.getElementById('nonseamless').submit();
                } else {
                    document.getElementById('payuForm').submit();
                }
            }
        }
    }
    render() {
        const {
            paymentMethod,
            isPayAutoRenewal,
            isShowLoader,
        } = this.state;
        return (
            <div>
                {isShowLoader &&
                    <div id="loading">
                        <div id="loading-center">
                        <div id="loading-center-absolute">
                            <img src="images/loader.gif"/>
                        </div>
                        </div>
                    </div>
                }
                <div class="container text-center" id="outerElement" style={{ display: (isPayAutoRenewal? 'block' : 'none'), marginTop: '40px'}} dangerouslySetInnerHTML={{__html: paymentMethod}}></div>
            </div>
        );
    }
}

export default proceedForPayment;
