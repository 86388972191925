import React, { Component } from 'react';
class StarRating extends Component {
    constructor(props) {
        super(props);
    };
    render() {
        var newRating = parseInt(this.props.rating);
        var stars = [];
        for(var i = 1; i <= 5; i++){
            stars.push(<span>{newRating < i && <img src="images/star.png" alt=""/>}{newRating >= i && <img src="images/active_star.png" alt=""/>}</span>);
        }
        return (
            <div class="starRating">{stars}</div>
        );
    }
}

export default StarRating;