const initialState = {
    browseCategoryProducts:{}
}

export default function browseCategoryProductDetailsData (state = initialState, action) {
    switch (action.type) {
        case 'BROWSE_CATEGORY_PRODUCTS': {
            return { ...state, browseCategoryProducts: action.payload};
        }
        case 'FAILED_BROWSE_CATEGORY_PRODUCTS': {
            return { ...state };
        }
        default: {
            return { ...state };
        }
    }
}