import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  productNotification,
  productMediaNews,
} from "../../action/notificationAction";
import SimpleCrypto from "simple-crypto-js";
import OwlCarousel from "react-owl-carousel2";
import axios from "axios";
import { api_base_url, encryptDecryptKey } from "../../config";
import { connect } from "react-redux";
import LocalizedStrings from "react-localization";
import languages from "../../language/language.json";
var strings = new LocalizedStrings(languages);

const optionss = {
  nav: true,
  rewind: true,
  autoplay: false,
  navText: ["<div></div>", "<div></div>"],
  margin: 15,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 5,
    },
  },
};
class AlertNotificationModal extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      token: token || "",
      promotionalNotification: [],
      publishNotification: [],
      promotionalEpisodeData: [],
      selectedLanguage: selectedLanguage || "EN",
      monthShortName: {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sept",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      },
    };
    this.closeAndRedirectLibrary = this.closeAndRedirectLibrary.bind(this);
  }
  componentWillMount() {
    this.props.dispatch(productNotification(this.state.token));
  }
  componentWillReceiveProps(nextProps) {
    console.log("++++::: " + JSON.stringify(this.props));
    if (
      this.props.promotionalNotification !==
      nextProps.promotionalNotification.length > 0
    ) {
      this.setState({
        promotionalNotification: nextProps.promotionalNotification,
      });
    }
    if (
      this.props.publishNotification !==
      nextProps.publishNotification.length > 0
    ) {
      this.setState({
        publishNotification: nextProps.publishNotification,
      });
    }
    if (
      this.props.promotionalEpisodeData !==
      nextProps.promotionalEpisodeData.length > 0
    ) {
      this.setState({
        promotionalEpisodeData: nextProps.promotionalEpisodeData,
      });
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  numberSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  subscriptionDateString(dateString) {
    var dateStringArray = dateString.split("T");
    var newDate = dateStringArray[0];
    var dateStringArray = newDate.split("-");
    var newYear = dateStringArray[0];
    var newMonth = parseInt(dateStringArray[1]) + "";
    var newDay = parseInt(dateStringArray[2]);
    var newString =
      this.numberSuffix(newDay) +
      " " +
      this.state.monthShortName[newMonth] +
      " " +
      newYear;
    return newString;
  }
  productDetailsId(productID, title) {
    var titleUrl = title.toLowerCase();
    var productTitle = titleUrl.split(" ").join("-");
    this.props.history.push(
      "/product-details/" + productID + "/" + productTitle
    );
  }

  closeAndRedirectLibrary() {
    this.props.dispatch({ type: "MY_LIBRARY_ACTIVE_TAB", payload: true });
    this.props.history.push("/library");
  }
  selectedtrackLibrary(productId, trackId) {
    this.props.dispatch({ type: "MY_LIBRARY_ACTIVE_TAB", payload: true });
    this.props.dispatch({
      type: "SELECTED_TRACK_ID",
      payload: [productId, trackId],
    });
    this.props.history.push("/library");
  }
  // promotionalNotificationFunction(){
  //     return this.state.promotionalNotification.map((items, index) => {

  //         return (
  //             <div className="alertTopCol">
  //                   <div className="alertTopColImg">
  //                     <img src="images/default.jpg" alt=""/>
  //                     {items.is_read === false &&
  //                     <div className="newImage"><div className="newAlert">{strings.NewText}</div></div>
  //                     }
  //                   </div>
  //                   <p><span className="notificationTitle" onClick={this.closeAndRedirectLibrary}> {items.title}</span><br /><span>Launched Date: {this.subscriptionDateString(items.date_created)}</span></p>
  //             </div>
  //         );
  //     })
  // }
  promotionalEpisodeFunction() {
    console.log(
      "promotionalEpisodeFunction: " + this.state.promotionalEpisodeData.length
    );
    return this.state.promotionalEpisodeData.map((items, index) => {
      return (
        <div className="alertTopCol">
          <div className="alertTopColImg">
            {items.product[0].images.length > 0 ? (
              <img
                onClick={() => {
                  this.selectedtrackLibrary(
                    items.product[0].id,
                    items.track[0].id
                  );
                }}
                src={items.product[0].images[0].image3}
                alt=""
              />
            ) : (
              <img
                onClick={() => {
                  this.selectedtrackLibrary(
                    items.product[0].id,
                    items.track[0].id
                  );
                }}
                src="images/default.jpg"
                alt=""
              />
            )}
            {items.is_read === false && (
              <div className="newImage">
                <div className="newAlert">{strings.NewText}</div>
              </div>
            )}
          </div>
          <p>
            <span
              className="notificationTitle"
              onClick={() => {
                this.selectedtrackLibrary(
                  items.product[0].id,
                  items.track[0].id
                );
              }}
            >
              {items.product[0].custom_translation[
                this.state.selectedLanguage
              ] !== undefined &&
                items.product[0].custom_translation[this.state.selectedLanguage]
                  .name}
            </span>
            <br />
            <span>
              {strings.LaunchedDate}:{" "}
              {this.subscriptionDateString(items.date_created)}
            </span>
          </p>
        </div>
      );
    });
  }
  publishNotificationFunction() {
    return this.state.publishNotification.map((items, index) => {
      return (
        <div>
          <div className="alertTopCol">
            <div className="alertTopColImg">
              {items.product[0].images.length > 0 ? (
                <img
                  onClick={() => {
                    this.productDetailsId(
                      items.product[0].id,
                      items.product[0].name
                    );
                  }}
                  src={items.product[0].images[0].image3}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => {
                    this.productDetailsId(
                      items.product[0].id,
                      items.product[0].name
                    );
                  }}
                  src="images/default.jpg"
                  alt=""
                />
              )}
              {items.is_read === false && (
                <div className="newImage">
                  <div className="newAlert">{strings.NewText}</div>
                </div>
              )}
            </div>
            <p>
              <span
                className="notificationTitle"
                onClick={() => {
                  this.productDetailsId(
                    items.product[0].id,
                    items.product[0].name
                  );
                }}
              >
                {items.product[0].custom_translation[
                  this.state.selectedLanguage
                ] !== undefined &&
                  items.product[0].custom_translation[
                    this.state.selectedLanguage
                  ].name}
              </span>
              <br />
              <span>
                {strings.LaunchedDate}:{" "}
                {this.subscriptionDateString(items.date_created)}
              </span>
            </p>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="notification-model-wrapper">
        <h4 className="modal-title">{strings.AlertNotification}</h4>
        <div className="alertTopDiv">
          <h5>{strings.NewProductLaunch}</h5>
          {this.state.publishNotification.length > 0 ? (
            <OwlCarousel ref="car" options={optionss}>
              {this.publishNotificationFunction()}
            </OwlCarousel>
          ) : (
            "Data Not Found"
          )}
        </div>
        {this.state.promotionalEpisodeData.length > 0 && (
          <div className="alertTopDiv">
            <h5>{strings.NewEpisodeLaunch}</h5>
            <OwlCarousel ref="car" options={optionss}>
              {this.promotionalEpisodeFunction()}
            </OwlCarousel>
          </div>
        )}
        {this.state.promotionalNotification.length > 0 && (
          <div className="alertTopDiv">
            <h5>{strings.promotionalNotification}</h5>
            <br />
            {this.state.promotionalNotification.map(function (item, i) {
              console.log("test");
              return (
                <div>
                  <p>
                    <b>{item.title}</b>
                  </p>
                  <p>{item.body}</p>
                  <br />
                </div>
              );
            })}
            {/* <OwlCarousel ref="car" options={optionss}>
              {this.promotionalEpisodeFunction()}
            </OwlCarousel> */}
          </div>
        )}
        {/* <div className="alertTopDiv">
                   <h5>New Episode Launch</h5>
                    {this.state.promotionalNotification.length>0 ? <OwlCarousel ref="car" options={optionss} >{this.promotionalNotificationFunction()}</OwlCarousel> : "Data Not Found"}
                </div> */}
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    promotionalNotification:
      state.productNotificationReducer.promotionalNotification,
    publishNotification: state.productNotificationReducer.publishNotification,
    promotionalEpisodeData: state.productNotificationReducer.promotionalEpisode,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(AlertNotificationModal));
