import React, { Component } from 'react';
import ReactGA from 'react-ga';
import {
    Button, Form, FormGroup, Label,
    Input, FormText, Row, Col, NavLink,
} from 'reactstrap';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { Route, Link, withRouter } from 'react-router-dom';
import { api_base_url, loginGuestToken, facebookAppId, googleClientId, googleAnalyticsTrackingId } from '../../config';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import languages from '../../language/language.json';
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

class confirmPassword extends Component {
    constructor(props) {
        super(props);
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
        }
        this.state = {
            resetPassword: '',
            confirmResetPassword: '',
            resetPassError: '',
            confirmResetPassError: '',
            tokenFromAPI: '',
            isLoading: true,
            confirmApiError: '',
            selectedLanguage: selectedLanguage || 'EN'
        };
        this.resetPasswordValue = this.resetPasswordValue.bind(this);
        this.confirmPasswordValue = this.confirmPasswordValue.bind(this);
        this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this);
        this.getValidTokenFronAPI();
        ReactGA.pageview(this.props.location.pathname);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    resetPasswordValue(event) {
        this.setState({
            resetPassword: event.target.value
        })
    }

    confirmPasswordValue(event) {
        this.setState({
            confirmResetPassword: event.target.value
        })
    }

    componentWillReceiveProps(nextProps) {
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
            this.setState({
                selectedLanguage: selectedLanguage
            });
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
            this.setState({
                selectedLanguage: "EN"
            });
        }
    }

    getValidTokenFronAPI() {
        var tokenFrom = this.props.match.params.token;
        this.setState({
            tokenFromAPI: this.props.match.params.token
        })
        var apiParams = {
            token: tokenFrom
        };
        axios.post(api_base_url + '/api/reset-password-token/', apiParams)
            .then(response => {
                if (response.data.status === true) {
                    this.setState({ isLoading: false });
                } else {
                    swal("", strings.linkExpired, "error").then(response => {
                        this.props.history.push('/');
                    });
                }
            }).catch(err => {
                // console.log('Error - ', err);
            })
    }

    onSubmitResetPassword(event) {
        event.preventDefault();
        this.setState({
            resetPassError: '',
            confirmResetPassError: '',
            confirmApiError: ''
        })
        var isValid = false;
        if (this.state.resetPassword === '') {
            this.setState({
                resetPassError: strings.enterPassword
            });
        } else if (this.state.resetPassword.length < 5) {
            this.setState({
                resetPassError: strings.PasswordLess
            });
        } else if (this.state.resetPassword.length > 15) {
            this.setState({
                resetPassError: strings.PasswordMore
            });
        } else if (this.state.confirmResetPassword === '') {
            this.setState({
                confirmResetPassError: strings.enterPassword
            });
        } else if (this.state.confirmResetPassword !== this.state.resetPassword) {
            this.setState({
                confirmResetPassError: strings.confirmResetPassError
            });
        } else {
            var isValid = true;
        }
        if (isValid) {
            var apiParams = {
                password: this.state.resetPassword,
                confirm_password: this.state.confirmResetPassword,
                token: this.props.match.params.token
            };
            axios.post(api_base_url + '/api/reset-password-confirm/', apiParams)
                .then(response => {
                    if (response.data.status === true) {
                        this.props.history.push('/sign-in');
                    } else {
                        this.setState({
                            confirmApiError: response.data.message[0].password
                        })
                    }
                }).catch(err => {
                    // console.log('Error - ', err);
                })
        }
    }
    render() {

        return (
            <div>
                {this.state.isLoading ?
                    <div id="loading">
                        <div id="loading-center">
                            <div id="loading-center-absolute">
                                <img src="images/loader.gif" />
                            </div>
                        </div>
                    </div> : null
                }

                <div class="clearfix"></div>
                <div className="container">
                    <div className="LoginForm_Wrapper welComeWrp">
                        <div class="welcomeBack"><h1>{strings.updatePassword}</h1>
                            <Form onSubmit={this.onSubmitResetPassword} className="confirmPasswordForm">
                                <FormGroup>
                                    <Input
                                        type="password"
                                        name="password"
                                        value={this.state.resetPassword}
                                        id="loginPassword"
                                        placeholder={strings.passwordText}
                                        onChange={this.resetPasswordValue} />
                                </FormGroup>
                                <div class="loginErrorMsg">{this.state.resetPassError}</div>
                                <FormGroup>
                                    <Input
                                        type="password"
                                        name="confirmPassword"
                                        value={this.state.confirmResetPassword}
                                        id="loginPassword"
                                        placeholder={strings.confirmPasswordText}
                                        onChange={this.confirmPasswordValue} />
                                </FormGroup>
                                <div class="loginErrorMsg">{this.state.resetPassError}</div>
                                <div class="loginErrorMsg">{this.state.confirmResetPassError}</div>
                                <div class="loginErrorMsg">{this.state.confirmApiError}</div>
                                <Button type='submit' >{strings.submitText}</Button>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="subBenefit_Wrapeer">
                    <div className="container">
                        <Row>
                            <Col xs="12" md="12" lg="4">
                                <h2>{strings.benefitOfOneHead}</h2>
                                <p>{strings.benefitOfOne}</p>
                            </Col>
                            <Col xs="12" md="12" lg="4" className="subsCriptionMid">
                                <h2>{strings.benefitOfTwoHead}</h2>
                                <p>{strings.benefitOfTwo}</p>
                            </Col>
                            <Col xs="12" md="12" lg="4">
                                <h2>{strings.benefitOfThreeHead}</h2>
                                <p>{strings.benefitOfThree}</p>
                            </Col>
                        </Row>
                        <div class="subsCriBtnBtm">
                            <span class="subsCribeButton">
                                <a href="/subscribe">{strings.subscribeNow}</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default confirmPassword;