import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel2";
import classnames from "classnames";
import $ from "jquery";
import { Route, Link, Redirect, withRouter } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import {
  api_base_url,
  dashboardToken,
  encryptDecryptKey,
  googleAnalyticsTrackingId,
  getHoursFormat,
} from "../config";
import axios from "axios";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import swal from "sweetalert";
import { homeTrendingProductDetailsData } from "../action/trendingProductAction";
import StarRatings from "react-star-ratings";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  PinterestIcon,
} from "react-share";
import AudioPlayer from "react-modular-audio-player";
import { browseCategoryProductDetailsDataAction } from "../action/browseCategoryAction";
import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
import { validate } from "@babel/types";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

const options = {
  nav: true,
  rewind: true,
  loop: true,
  autoplay: false,
  margin: 15,
  navText: ["<div></div>", "<div></div>"],
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
};
const optionss = {
  nav: true,
  autoplay: false,
  navText: ["<div></div>", "<div></div>"],
  margin: 15,
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  },
};
let rearrangedPlayer = [
  {
    className: "tier-top",
    innerComponents: [
      {
        type: "rewind",
        style: { width: "fit-content" },
      },
      {
        type: "play",
        style: { width: "fit-content" },
      },
      {
        type: "forward",
        style: { width: "fit-content" },
      },
      {
        type: "seek",
      },
      {
        type: "volume",
      },
    ],
  },
];

const front_base_url = window.location.origin;
class browseSnovel extends Component {
  constructor(props) {
    super(props);
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    this.state = {
      token: token || "",
      activeTab: "1",
      innerActiveTab: "2",
      isLoading: false,
      topSnovelTrending: [],
      selectedLanguage: selectedLanguage || "EN",
      audioObject: undefined,
      audioPlayerObject: undefined,
      topSnovelLastPlayed: "",
      browseTopAudio: {},
      latestLaunchApiData: [],
      latestLaunchAudio: {},
      latestLaunchLastPlayed: "",
      productTypeAudio: {},
      productTypeLastPlayed: "",
      selectedPopularIndex: 0,
      popoverOpenShare: false,
      popularTitleSelectedAudio: [],
      audiobookAllProducts: [],
      audiobookEditorPicks: [],
      audiobookPopularAuthors: [],
      audiobookPopularTitles: [],
      audiobookTotalCount: "",
      snovelOriginalAllProducts: [],
      snovelOriginalEditorPicks: [],
      snovelOriginalPopularAuthors: [],
      snovelOriginalPopularTitles: [],
      snovelOriginalTotalCount: "",
      podcastAllProducts: [],
      podcastEditorPicks: [],
      podcastPopularAuthors: [],
      podcastPopularTitles: [],
      podcastTotalCount: "",
      menuSelectedCategory: [],
      isSelectedCategory: false,
      isSelectedCategoryName: "",
      isSelectedPostType: "",
      cartFailureMessage: "",
      latestProductToken: token || dashboardToken,
      topPopoverOpenShare: false,
      elementProductId: "",
      isIndian: null,
    };
    this.tabToggle = this.tabToggle.bind(this);
    this.innerTabToggle = this.innerTabToggle.bind(this);
    this.popOverToggle = this.popOverToggle.bind(this);
    this.enableTabs = this.enableTabs.bind(this);
    this.topSocialToggle = this.topSocialToggle.bind(this);
    ReactGA.pageview(this.props.location.pathname);
  }
  tabToggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.state.audioObject) {
      this.state.audioObject.pause();
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //     if(this.state.isSelectedCategory === true) {
  //         this.scroollRef.current.scrollIntoView({behavior: 'smooth'});
  //     }
  // }
  cartProductsModal(variantId) {
    if (this.props.authData.loginSuccess) {
      var apiParams = {
        email: "",
        discount_amount: 0.0,
        discount_amount_inr: 0.0,
        gift_cart: false,
        cart_lines: [
          {
            variant: variantId,
            quantity: 1,
            data: {},
          },
        ],
      };

      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .post(api_base_url + "/api/cart/", apiParams, { headers: headers })
        .then((response) => {
          if (response.data.status === true) {
            this.props.dispatch({
              type: "PRODUCT_CART_COUNT",
              payload: response.data.data.quantity,
            });
            this.props.dispatch({ type: "MODEL_SELECTED", payload: true });
          } else {
            // console.log("Subscribe page error");
          }
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          // console.log("fetched.", err);
          this.setState({
            cartFailureMessage: "Product already added in cart",
          });
        });
    } else {
      this.props.history.push("/sign-in");
    }
  }
  popOverToggle() {
    this.setState({
      popoverOpenShare: !this.state.popoverOpenShare,
    });
  }

  topSocialToggle(elementId) {
    this.setState({
      topPopoverOpenShare: !this.state.topPopoverOpenShare,
      elementProductId: elementId,
    });
  }

  browsePauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      topSnovelLastPlayed: "",
    });
  }

  browsePlayAudio(map_url) {
    if (this.state.browseTopAudio[map_url]) {
      this.setState({
        topSnovelLastPlayed: map_url,
        latestLaunchLastPlayed: "",
        productTypeLastPlayed: "",
      });
      if (this.state.audioPlayerObject) {
        this.state.audioPlayerObject.pause();
      }
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                topSnovelLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newTrendingAudio = {};
            newTrendingAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              browseTopAudio: newTrendingAudio,
              topSnovelLastPlayed: map_url,
              latestLaunchLastPlayed: "",
              productTypeLastPlayed: "",
            });
            if (this.state.audioPlayerObject) {
              this.state.audioPlayerObject.pause();
            }
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }

  innerTabToggle(updatedPostType, tab) {
    if (this.state.innerActiveTab !== tab) {
      this.setState({
        innerActiveTab: tab,
      });
      this.browseProductsData(updatedPostType, tab);
    }
  }
  componentWillMount() {
    this.props.dispatch(homeTrendingProductDetailsData());
    if (!Object.keys(this.props.browseCategoryProductDetailsData).length) {
      this.props.dispatch(
        browseCategoryProductDetailsDataAction(null, null, 2)
      );
    }
    this.latestLaunchProductFunction();
  }
  componentWillReceiveProps(nextProps) {
    var topSnovelBrowse = [];
    if (nextProps.trendingProductsDetails.treandingProducts.length > 0) {
      nextProps.trendingProductsDetails.treandingProducts.forEach((element) => {
        topSnovelBrowse.push(element.trending_picks);
      });
      this.setState({
        topSnovelTrending: topSnovelBrowse[0][0] || [],
      });
    }
    if (nextProps.isIndian !== null && nextProps.isIndian !== undefined) {
      this.setState({ isIndian: nextProps.isIndian });
    }
    if (nextProps.browseCategoryProductDetailsData.total_count !== undefined) {
      this.setState({
        audiobookTotalCount:
          nextProps.browseCategoryProductDetailsData.total_count.audiobook ||
          "",
        snovelOriginalTotalCount:
          nextProps.browseCategoryProductDetailsData.total_count
            .snoveloriginal || "",
        podcastTotalCount:
          nextProps.browseCategoryProductDetailsData.total_count.podcast || "",
      });
    }
    if (nextProps.browseCategoryProductDetailsData.audiobook !== undefined) {
      this.setState({
        audiobookAllProducts:
          nextProps.browseCategoryProductDetailsData.audiobook.all_product ||
          [],
        audiobookEditorPicks:
          nextProps.browseCategoryProductDetailsData.audiobook.editor_picks ||
          [],
        audiobookPopularAuthors:
          nextProps.browseCategoryProductDetailsData.audiobook
            .popular_authors || [],
        audiobookPopularTitles:
          nextProps.browseCategoryProductDetailsData.audiobook.popular_titles ||
          [],
        activeTab: "1",
      });
    }
    if (
      nextProps.browseCategoryProductDetailsData.snoveloriginal !== undefined
    ) {
      this.setState({
        snovelOriginalAllProducts:
          nextProps.browseCategoryProductDetailsData.snoveloriginal
            .all_product || [],
        snovelOriginalEditorPicks:
          nextProps.browseCategoryProductDetailsData.snoveloriginal
            .editor_picks || [],
        snovelOriginalPopularAuthors:
          nextProps.browseCategoryProductDetailsData.snoveloriginal
            .popular_authors || [],
        snovelOriginalPopularTitles:
          nextProps.browseCategoryProductDetailsData.snoveloriginal
            .popular_titles || [],
        activeTab: "2",
      });
    }
    if (nextProps.browseCategoryProductDetailsData.podcast !== undefined) {
      this.setState({
        podcastAllProducts:
          nextProps.browseCategoryProductDetailsData.podcast.all_product || [],
        podcastEditorPicks:
          nextProps.browseCategoryProductDetailsData.podcast.editor_picks || [],
        podcastPopularAuthors:
          nextProps.browseCategoryProductDetailsData.podcast.popular_authors ||
          [],
        podcastPopularTitles:
          nextProps.browseCategoryProductDetailsData.podcast.popular_titles ||
          [],
        activeTab: "3",
      });
    }

    if (
      this.props.selectedPostAndCategory !== "" &&
      nextProps.selectedPostAndCategory.menuSelectedCategory[0]
        .selectedCategory !== false
    ) {
      this.setState({
        isSelectedCategoryName:
          nextProps.selectedPostAndCategory.menuSelectedCategory[0]
            .selectedCategory,
        isSelectedPostType:
          nextProps.selectedPostAndCategory.menuSelectedCategory[0]
            .selectedPostType,
        isSelectedCategory: true,
        innerActiveTab: "1",
      });
    }

    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  enableTabs() {
    this.setState({
      isSelectedCategory: false,
      isSelectedCategoryName: "",
      isSelectedPostType: "",
      innerActiveTab: "2",
    });
    this.props.dispatch({
      type: "MENU_UNSELECTED_CATEGORY",
      payload: [false, null],
    });
    this.props.dispatch(browseCategoryProductDetailsDataAction(null, null, 2));
  }
  productDetailsId(productID, slug) {
    localStorage.setItem("productId", productID);
    this.props.history.push("/product-details/" + productID + "/" + slug);
  }
  /****  Author details page link **********/
  authorDetailsId(authorID, authorName) {
    var titleUrl = authorName.toLowerCase();
    var authorTitle = titleUrl.replace(".", "");
    var authorName = authorTitle.split(" ").join("-");
    this.props.history.push("/author/" + authorID + "/" + authorName);
  }
  authorLinkList(authorList) {
    return authorList.map((authorName, index) => {
      if (
        authorName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        return (
          <NavLink
            tag={Link}
            onClick={() => {
              this.authorDetailsId(authorName.id, authorName.name);
            }}
            key={index}
          >
            <span key={index}>
              {authorName.custom_translation[this.state.selectedLanguage].name}
            </span>
          </NavLink>
        );
      }
    });
  }

  /*****  Top slider content **********/
  topSnovelSliderData() {
    return this.state.topSnovelTrending.map((element, index) => {
      var productIndex = index;
      var authorArray = [];
      element.authors.map((authorName) => {
        if (
          authorName.custom_translation[this.state.selectedLanguage] !==
          undefined
        ) {
          authorArray.push(
            authorName.custom_translation[this.state.selectedLanguage].name
          );
        }
      });
      return (
        <div key={index}>
          {element.images.length > 0 ? (
            <div className="topBrowseImage">
              <img
                src={element.images[0]["image3"]}
                className="sampleProduct_thumb"
              />
            </div>
          ) : (
            <div>
              <img src="/images/default.jpg" className="sampleProduct_thumb" />
            </div>
          )}
          <div className="sliderWrapper">
            <span
              className="browseSlideTxtOne"
              onClick={() => {
                this.productDetailsId(element.id, element.product_slug);
              }}
            >
              {element.custom_translation[this.state.selectedLanguage].name}
            </span>
            <br />
            <span className="browseSlideTxtTwo">
              <span>{strings.authorText} - </span>{" "}
              {this.authorLinkList(element.authors)}
            </span>
            <br />
            <div className="browseSlideCategory">
              {element.category.map((categoryList, index) => {
                if (
                  categoryList.custom_translation[
                  this.state.selectedLanguage
                  ] !== undefined
                ) {
                  return (
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_SELECTED_CATEGORY",
                          payload: [
                            categoryList.name,
                            element.product_audio_type,
                            categoryList.id,
                          ],
                        });
                        this.setState({
                          innerActiveTab: "1",
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(
                            element.product_audio_type,
                            categoryList.name,
                            1
                          )
                        );
                      }}
                      key={index}
                    >
                      <span key={index}>
                        {
                          categoryList.custom_translation[
                            this.state.selectedLanguage
                          ].name
                        }
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div>
          </div>
          <div className="sliderTrack_Wrap">
            {element.sampletracks.length > 0 ? (
              <div>
                {element.sampletracks[0].map_url !=
                  this.state.topSnovelLastPlayed && (
                    <img
                      onClick={() => {
                        this.browsePlayAudio(element.sampletracks[0].map_url);
                      }}
                      src="/images/play_icon_small.png"
                    />
                  )}
                {element.sampletracks[0].map_url ==
                  this.state.topSnovelLastPlayed && (
                    <img
                      onClick={() => {
                        this.browsePauseAudio(element.sampletracks[0].map_url);
                      }}
                      src="/images/Puse.png"
                    />
                  )}
              </div>
            ) : (
              <div className="emptyTrack">
                <img src="/images/play_icon_small.png" />
              </div>
            )}
          </div>
          <div className="shareDiv">
            <div className="starRating">
              <StarRatings
                rating={element.total_reviews}
                starRatedColor="#ff8c00"
                numberOfStars={5}
                name="rating"
                starDimension="16px"
                starSpacing="3px"
              />
            </div>
            <div className="priceBrwSlide">
              {this.state.isIndian !== null ?
                <Fragment>
                  {this.state.isIndian ? strings.priceSign + "" + element.inr_price.amount
                    : "$" + element.price.amount}
                </Fragment>
                :
                <Fragment>
                  {this.props.authData.userCountry === "IN"
                    ? strings.priceSign + "" + element.inr_price.amount
                    : "$" + element.price.amount}
                </Fragment>
              }
            </div>
            <div className="shareIconDiv">
              <span className="shareIcon">
                <a href="javascript:;">
                  <img
                    src="images/share_icon.png"
                    alt=""
                    onClick={() => {
                      this.topSocialToggle(element.id);
                    }}
                  />
                </a>
              </span>
              <div
                className="popupShareButton"
                style={{
                  display:
                    this.state.topPopoverOpenShare &&
                      this.state.elementProductId === element.id
                      ? "block"
                      : "none",
                }}
              >
                <div className="popupShareButton_inline">
                  <FacebookShareButton
                    url={front_base_url +
                      "/product-details/" +
                      element.id +
                      "/" +
                      element.name
                    }
                    quote={element.name}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={
                      this.getSharingContent(this.state.topSnovelTrending[productIndex]) + front_base_url +
                      "/product-details/" +
                      element.id +
                      "/" +
                      element.name
                    }
                    quote={element.name}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={
                      this.getSharingContent(this.state.topSnovelTrending[productIndex]) + front_base_url +
                      "/product-details/" +
                      element.id +
                      "/" +
                      element.name
                    }
                    quote={element.name}
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <PinterestShareButton
                    url={
                      this.getSharingContent(this.state.topSnovelTrending[productIndex]) + front_base_url +
                      "/product-details/" +
                      element.id +
                      "/" +
                      element.name
                    }
                    media={`${String(
                      element.images.length > 0 && element.images[0]["image3"]
                    )}`}
                  >
                    <PinterestIcon size={32} round />
                  </PinterestShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  updateSletedIndex(slideIndex) {
    this.setState({
      selectedPopularIndex: slideIndex,
    });
    this.getPopularTitleAudio(this.state.latestLaunchApiData[slideIndex]);
  }
  getPopularTitleAudio(productDetails) {
    if (productDetails.sampletracks.length > 0) {
      var apiParams = {
        map_url: productDetails.sampletracks[0].map_url || "",
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            this.setState({
              popularTitleSelectedAudio: [{ src: url }],
            });
            var newAudioObject = $(".audio-player audio");
            if (newAudioObject.length > 0) {
              var parentThis = this;
              this.setState({
                audioPlayerObject: newAudioObject[0],
              });
              newAudioObject[0].onerror = function () {
                const isFirefox = typeof InstallTrigger !== "undefined";
                if (isFirefox) {
                  swal(
                    "",
                    "Something went wrong with your browser. Please try another one.",
                    "error"
                  );
                }
              };
              newAudioObject[0].addEventListener("play", function () {
                if (parentThis.state.audioObject) {
                  parentThis.state.audioObject.pause();
                  parentThis.setState({
                    productTypeLastPlayed: "",
                    topSnovelLastPlayed: "",
                    latestLaunchLastPlayed: "",
                  });
                }
              });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    } else {
      this.setState({
        popularTitleSelectedAudio: [],
      });
    }
  }
  latestLaunchPauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      latestLaunchLastPlayed: "",
    });
  }
  latestLaunchProductFunction() {
    var headers = {
      "Content-Type": "application/json",
      "index-api-token": this.state.latestProductToken,
    };
    axios
      .get(api_base_url + "/api/v2/browse-latest-launch/10/", { headers: headers })
      .then((response) => {
        if (response.data.status == true) {
          this.setState({
            latestLaunchApiData: response.data.latest_launch || [],
          });
        } else {
          // console.log("Not Loaded");
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  /****** User is take subscription then can add product in library to click on add library button */
  addToLibraryProduct(productID) {
    var apiParams = {
      product: productID,
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/add-to-library/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          this.props.history.push("/library");
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  latestLaunchPlayAudio(map_url) {
    if (this.state.latestLaunchAudio[map_url]) {
      this.setState({
        latestLaunchLastPlayed: map_url,
        topSnovelLastPlayed: "",
        productTypeLastPlayed: "",
      });
      if (this.state.audioPlayerObject) {
        this.state.audioPlayerObject.pause();
      }
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                latestLaunchLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newLatestLaunchAudio = {};
            newLatestLaunchAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              latestLaunchAudio: newLatestLaunchAudio,
              latestLaunchLastPlayed: map_url,
              topSnovelLastPlayed: "",
              productTypeLastPlayed: "",
            });
            if (this.state.audioPlayerObject) {
              this.state.audioPlayerObject.pause();
            }
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }
  /********   Lates Launch slider content  ************/
  latestLaunchSliderContent() {
    return this.state.latestLaunchApiData.map((sliderElement, slideIndex) => {
      return (
        <div>
          <div className="popular-slider-content">
            {slideIndex == this.state.selectedPopularIndex && (
              <span className="selectedProductSlider">
                <img src="/images/up-arrow-black.png" />
              </span>
            )}
            <div className="videoThumbDiv">
              {sliderElement.images.length > 0 ? (
                <div className="sliderThumb">
                  {sliderElement.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                  <img
                    src={sliderElement.images[0]["image2"]}
                    className="sampleProduct_thumb"
                    onClick={() => {
                      this.updateSletedIndex(slideIndex);
                    }}
                  />
                </div>
              ) : (
                <div className="sliderThumb">
                  {sliderElement.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                  <img
                    src="/images/default.jpg"
                    className="sampleProduct_thumb"
                    onClick={() => {
                      this.productDetailsId(
                        sliderElement.id,
                        sliderElement.product_slug
                      );
                    }}
                  />
                </div>
              )}
              <div className="sliderTrack_Wrap custom-player-section">
                {sliderElement.sampletracks.length > 0 && (
                  <div>
                    {sliderElement.sampletracks[0].map_url !=
                      this.state.latestLaunchLastPlayed && (
                        <img
                          onClick={() => {
                            this.latestLaunchPlayAudio(
                              sliderElement.sampletracks[0].map_url
                            );
                          }}
                          src="/images/play.png"
                        />
                      )}
                    {sliderElement.sampletracks[0].map_url ==
                      this.state.latestLaunchLastPlayed && (
                        <img
                          onClick={() => {
                            this.latestLaunchPauseAudio(
                              sliderElement.sampletracks[0].map_url
                            );
                          }}
                          src="/images/round-pause-button.svg"
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="videoThumbTitleDiv">
              {sliderElement.custom_translation[this.state.selectedLanguage] !==
                undefined && (
                <div className="">
                  <span
                    onClick={() => {
                      this.updateSletedIndex(slideIndex);
                    }}
                  >
                    {
                      sliderElement.custom_translation[
                        this.state.selectedLanguage
                      ].name
                    }
                  </span>
                </div>
              )}
              <div className="briefTitle">
                <span>{strings.authorText} -</span>{" "}
                {this.authorLinkList(sliderElement.authors)}
              </div>
            </div> */}
            {/* <div className="sliderProductCategory">
              {sliderElement.category.map((categoryList, index) => {
                if (
                  categoryList.custom_translation[
                    this.state.selectedLanguage
                  ] !== undefined
                ) {
                  return (
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_SELECTED_CATEGORY",
                          payload: [
                            categoryList.name,
                            sliderElement.product_audio_type,
                            categoryList.id,
                          ],
                        });
                        this.setState({
                          innerActiveTab: "1",
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(
                            sliderElement.product_audio_type,
                            categoryList.name,
                            1
                          )
                        );
                      }}
                      key={index}
                    >
                      <span key={index}>
                        {categoryList.custom_translation[
                          this.state.selectedLanguage
                        ].name.toString()}
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div> */}
          </div>
        </div>
      );
    });
  }
  authorsSelected(authorsList) {
    var authorArray = [];
    authorsList.map((authorName) => {
      if (
        authorName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        authorArray.push(
          authorName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return authorArray.toString();
  }
  authorsLIst(authorsList) {
    return authorsList.map((authorName) => {
      if (
        authorName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        return (
          <span>
            {authorName.custom_translation[this.state.selectedLanguage].name}
          </span>
        );
      }
    });
  }
  directorsSelected(directorList) {
    var directorArray = [];
    directorList.map((directorName) => {
      if (
        directorName.custom_translation[this.state.selectedLanguage] !=
        undefined
      ) {
        directorArray.push(
          directorName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return directorArray.toString();
  }
  artistsSelected(artistsList) {
    var artistsArray = [];
    artistsList.map((artistsName) => {
      if (
        artistsName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        artistsArray.push(
          artistsName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return artistsArray.toString();
  }
  categorySelected(categoryList) {
    var categoryArray = [];
    categoryList.map((categoryName) => {
      if (
        categoryName.custom_translation[this.state.selectedLanguage] !=
        undefined
      ) {
        categoryArray.push(
          categoryName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return categoryArray.join("| ");
  }

  browseProductsData(updatePostType, selectedTab) {
    if (updatePostType === "audiobook") {
      this.tabToggle("1");
      if (this.state.isSelectedPostType === "") {
        this.props.dispatch(
          browseCategoryProductDetailsDataAction(
            updatePostType,
            null,
            parseInt(selectedTab)
          )
        );
        this.setState({
          innerActiveTab: selectedTab + "",
        });
      } else {
        this.props.dispatch(
          browseCategoryProductDetailsDataAction(
            updatePostType,
            this.state.isSelectedCategoryName,
            1
          )
        );
        this.setState({
          innerActiveTab: "1",
        });
      }
    } else if (updatePostType === "snoveloriginal") {
      this.tabToggle("2");
      if (this.state.isSelectedPostType === "") {
        this.props.dispatch(
          browseCategoryProductDetailsDataAction(
            updatePostType,
            null,
            parseInt(selectedTab)
          )
        );
        this.setState({
          innerActiveTab: selectedTab + "",
        });
      } else {
        this.props.dispatch(
          browseCategoryProductDetailsDataAction(
            updatePostType,
            this.state.isSelectedCategoryName,
            1
          )
        );
        this.setState({
          innerActiveTab: "1",
        });
      }
    } else {
      this.tabToggle("3");
      if (this.state.isSelectedPostType === "") {
        this.props.dispatch(
          browseCategoryProductDetailsDataAction(
            updatePostType,
            null,
            parseInt(selectedTab)
          )
        );
        this.setState({
          innerActiveTab: selectedTab + "",
        });
      } else {
        this.props.dispatch(
          browseCategoryProductDetailsDataAction(
            updatePostType,
            this.state.isSelectedCategoryName,
            1
          )
        );
        this.setState({
          innerActiveTab: "1",
        });
      }
    }
  }
  /********* Product Type Play, Pause Audio **************/
  productTypePlayAudio(map_url) {
    if (this.state.productTypeAudio[map_url]) {
      this.setState({
        productTypeLastPlayed: map_url,
        topSnovelLastPlayed: "",
        latestLaunchLastPlayed: "",
      });
      if (this.state.audioPlayerObject) {
        this.state.audioPlayerObject.pause();
      }
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                productTypeLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newLatestLaunchAudio = {};
            newLatestLaunchAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              productTypeAudio: newLatestLaunchAudio,
              productTypeLastPlayed: map_url,
              topSnovelLastPlayed: "",
              latestLaunchLastPlayed: "",
            });
            if (this.state.audioPlayerObject) {
              this.state.audioPlayerObject.pause();
            }
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }
  productTypePauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      productTypeLastPlayed: "",
    });
  }
  /********* End Product Type Play, Pause Audio **********/
  /******* Audio Book Tab content or products *********/
  audiobookAllProductsData() {
    return this.state.audiobookAllProducts.map((audiobookAllProduct, index) => {
      var authorArray = [];
      audiobookAllProduct.authors.map((authorName) => {
        if (
          authorName.custom_translation[this.state.selectedLanguage] !==
          undefined
        ) {
          authorArray.push(
            authorName.custom_translation[this.state.selectedLanguage].name
          );
        }
      });
      return (
        <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
          <div
            className="videoThumbDiv"
            id={"PopoverLast" + audiobookAllProduct.id}
          >
            {audiobookAllProduct.images.length > 0 ? (
              <Fragment>
                {audiobookAllProduct.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={audiobookAllProduct.images[0]["image2"]} alt="" />
              </Fragment>
            ) : (
              <Fragment>
                {audiobookAllProduct.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
              </Fragment>
            )}
            <div className="sliderTrack_Wrap">
              {audiobookAllProduct.sampletracks.length > 0 && (
                <div>
                  {audiobookAllProduct.sampletracks[0].map_url !=
                    this.state.productTypeLastPlayed && (
                      <img
                        onClick={() => {
                          this.productTypePlayAudio(
                            audiobookAllProduct.sampletracks[0].map_url
                          );
                        }}
                        src="/images/play.png"
                      />
                    )}
                  {audiobookAllProduct.sampletracks[0].map_url ==
                    this.state.productTypeLastPlayed && (
                      <img
                        onClick={() => {
                          this.productTypePauseAudio(
                            audiobookAllProduct.sampletracks[0].map_url
                          );
                        }}
                        src="/images/round-pause-button.svg"
                      />
                    )}
                </div>
              )}
            </div>
          </div>
          {/* <div className="videoThumbTitleDiv">
            <span
              className="cursorPointer"
              onClick={() => {
                this.productDetailsId(
                  audiobookAllProduct.id,
                  audiobookAllProduct.product_slug
                );
              }}
            >
              {audiobookAllProduct.custom_translation[
                this.state.selectedLanguage
              ] != undefined &&
                audiobookAllProduct.custom_translation[
                  this.state.selectedLanguage
                ].name}
            </span>
            <br />
            {audiobookAllProduct.authors.length > 0 && (
              <span className="briefTitle">
                <span>{strings.authorText}: </span>{" "}
                {this.authorLinkList(audiobookAllProduct.authors)}{" "}
              </span>
            )}
          </div> */}
          {/* <div className="videoThumbCateDiv">
            {audiobookAllProduct.category.map((categoryList, index) => {
              if (
                categoryList.custom_translation[this.state.selectedLanguage] !==
                undefined
              ) {
                return (
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      this.props.dispatch({
                        type: "MENU_SELECTED_CATEGORY",
                        payload: [
                          categoryList.name,
                          audiobookAllProduct.product_audio_type,
                          categoryList.id,
                        ],
                      });
                      this.setState({
                        innerActiveTab: "1",
                      });
                      this.props.dispatch(
                        browseCategoryProductDetailsDataAction(
                          audiobookAllProduct.product_audio_type,
                          categoryList.name,
                          1
                        )
                      );
                    }}
                    key={index}
                  >
                    <span key={index}>
                      {
                        categoryList.custom_translation[
                          this.state.selectedLanguage
                        ].name
                      }
                    </span>
                  </NavLink>
                );
              }
            })}
          </div> */}
          <UncontrolledPopover
            trigger="hover"
            placement="right"
            target={"PopoverLast" + audiobookAllProduct.id}
          >
            <PopoverBody>
              <div className="threeDot">
                <div className="treeDotTop">
                  <h4>
                    <span
                      className="cursorPointer"
                      onClick={() => {
                        this.productDetailsId(
                          audiobookAllProduct.id,
                          audiobookAllProduct.product_slug
                        );
                      }}
                    >
                      {audiobookAllProduct.custom_translation[
                        this.state.selectedLanguage
                      ] != undefined &&
                        audiobookAllProduct.custom_translation[
                          this.state.selectedLanguage
                        ].name}
                    </span>
                  </h4>
                  <span className="cateTitle">
                    (As{" "}
                    {audiobookAllProduct.product_audio_type === "audiobook" &&
                      strings.audiobook}
                    )
                  </span>
                </div>
                {audiobookAllProduct.authors.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.authorText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {this.authorLinkList(audiobookAllProduct.authors)}
                    </div>
                  </div>
                )}
                {audiobookAllProduct.directors.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.directorText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes">
                        {this.directorsSelected(audiobookAllProduct.directors)}
                      </span>
                    </div>
                  </div>
                )}
                {audiobookAllProduct.artists.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.artistsText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes">
                        {this.artistsSelected(audiobookAllProduct.artists)}
                      </span>
                    </div>
                  </div>
                )}
                <div className="videoCnt browseGenre">
                  <div className="headingText">
                    <span className="author">{strings.genreText}</span>
                  </div>
                  <div className="colon-symbol">:</div>
                  <div className="loopdata">
                    {audiobookAllProduct.category.map((categoryList, index) => {
                      if (
                        categoryList.custom_translation[
                        this.state.selectedLanguage
                        ] !== undefined
                      ) {
                        return (
                          <NavLink
                            tag={Link}
                            onClick={() => {
                              this.props.dispatch({
                                type: "MENU_SELECTED_CATEGORY",
                                payload: [
                                  categoryList.name,
                                  audiobookAllProduct.product_audio_type,
                                  categoryList.id,
                                ],
                              });
                              this.setState({
                                innerActiveTab: "1",
                              });
                              this.props.dispatch(
                                browseCategoryProductDetailsDataAction(
                                  audiobookAllProduct.product_audio_type,
                                  categoryList.name,
                                  1
                                )
                              );
                            }}
                            key={index}
                          >
                            <span key={index}>
                              {
                                categoryList.custom_translation[
                                  this.state.selectedLanguage
                                ].name
                              }
                            </span>
                          </NavLink>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="videoCnt">
                  <span className="author">{strings.durationText}</span>:
                  <span className="authorDes brwHrs">
                    {getHoursFormat(audiobookAllProduct.total_tracks_length) +
                      " " +
                      strings.hrsText}
                  </span>
                </div>
                <div className="starRatingBrw">
                  <StarRatings
                    rating={audiobookAllProduct.total_reviews}
                    starRatedColor="#ff8c00"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                  />
                </div>
                {!audiobookAllProduct.is_free && (
                  <div className="videoPrice">
                    <span>
                      {this.state.isIndian !== null ?
                        <Fragment>
                          {this.state.isIndian ? strings.priceSign + "" + audiobookAllProduct.inr_price.amount
                            : "$" + audiobookAllProduct.inr_price.amount}
                        </Fragment>
                        :
                        <Fragment>
                          {this.props.authData.userCountry === "IN"
                            ? strings.priceSign + "" + audiobookAllProduct.inr_price.amount
                            : "$" + audiobookAllProduct.price.amount}
                        </Fragment>
                      }
                    </span>
                  </div>
                )}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </Col>
      );
    });
  }
  audiobookPopularTitlesData() {
    return this.state.audiobookPopularTitles.map(
      (audiobookPopularTitle, index) => {
        var authorArray = [];
        audiobookPopularTitle.authors.map((authorName) => {
          if (
            authorName.custom_translation[this.state.selectedLanguage] !==
            undefined
          ) {
            authorArray.push(
              authorName.custom_translation[this.state.selectedLanguage].name
            );
          }
        });
        return (
          <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
            <div
              className="videoThumbDiv"
              id={"Popover" + audiobookPopularTitle.id}
            >
              {audiobookPopularTitle.images.length > 0 ? (
                <Fragment>
                  {audiobookPopularTitle.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={audiobookPopularTitle.images[0]["image2"]} alt="" />
                </Fragment>
              ) : (
                <Fragment>
                  {audiobookPopularTitle.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
                </Fragment>
              )}
              <div className="sliderTrack_Wrap">
                {audiobookPopularTitle.sampletracks.length > 0 && (
                  <div>
                    {audiobookPopularTitle.sampletracks[0].map_url !=
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePlayAudio(
                              audiobookPopularTitle.sampletracks[0].map_url
                            );
                          }}
                          src="/images/play.png"
                        />
                      )}
                    {audiobookPopularTitle.sampletracks[0].map_url ==
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePauseAudio(
                              audiobookPopularTitle.sampletracks[0].map_url
                            );
                          }}
                          src="/images/round-pause-button.svg"
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="videoThumbTitleDiv">
              <span
                onClick={() => {
                  this.productDetailsId(
                    audiobookPopularTitle.id,
                    audiobookPopularTitle.product_slug
                  );
                }}
              >
                {audiobookPopularTitle.custom_translation[
                  this.state.selectedLanguage
                ] != undefined &&
                  audiobookPopularTitle.custom_translation[
                    this.state.selectedLanguage
                  ].name}
              </span>
              <br />
              {audiobookPopularTitle.authors.length > 0 && (
                <span className="briefTitle">
                  {strings.authorText}:{" "}
                  {this.authorLinkList(audiobookPopularTitle.authors)}
                </span>
              )}
            </div> */}
            {/* <div className="videoThumbCateDiv">
              {audiobookPopularTitle.category.map((categoryList, index) => {
                if (
                  categoryList.custom_translation[
                    this.state.selectedLanguage
                  ] !== undefined
                ) {
                  return (
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_SELECTED_CATEGORY",
                          payload: [
                            categoryList.name,
                            audiobookPopularTitle.product_audio_type,
                            categoryList.id,
                          ],
                        });
                        this.setState({
                          innerActiveTab: "1",
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(
                            audiobookPopularTitle.product_audio_type,
                            categoryList.name,
                            1
                          )
                        );
                      }}
                      key={index}
                    >
                      <span key={index}>
                        {
                          categoryList.custom_translation[
                            this.state.selectedLanguage
                          ].name
                        }
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div> */}
            <UncontrolledPopover
              trigger="hover"
              placement="right"
              target={"Popover" + audiobookPopularTitle.id}
            >
              <PopoverBody>
                <div className="threeDot">
                  <div className="treeDotTop">
                    <h4>
                      <span
                        className="cursorPointer"
                        onClick={() => {
                          this.productDetailsId(
                            audiobookPopularTitle.id,
                            audiobookPopularTitle.product_slug
                          );
                        }}
                      >
                        {audiobookPopularTitle.custom_translation[
                          this.state.selectedLanguage
                        ] != undefined &&
                          audiobookPopularTitle.custom_translation[
                            this.state.selectedLanguage
                          ].name}
                      </span>
                    </h4>
                    <span className="cateTitle">
                      (As{" "}
                      {audiobookPopularTitle.product_audio_type ===
                        "audiobook" && strings.audiobook}
                      )
                    </span>
                  </div>
                  {audiobookPopularTitle.authors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        {this.authorLinkList(audiobookPopularTitle.authors)}
                      </div>
                    </div>
                  )}
                  {audiobookPopularTitle.directors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.directorsSelected(
                            audiobookPopularTitle.directors
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {audiobookPopularTitle.artists.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.artistsSelected(audiobookPopularTitle.artists)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="videoCnt browseGenre">
                    <div className="headingText">
                      <span className="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {audiobookPopularTitle.category.map(
                        (categoryList, index) => {
                          if (
                            categoryList.custom_translation[
                            this.state.selectedLanguage
                            ] !== undefined
                          ) {
                            return (
                              <NavLink
                                tag={Link}
                                onClick={() => {
                                  this.props.dispatch({
                                    type: "MENU_SELECTED_CATEGORY",
                                    payload: [
                                      categoryList.name,
                                      audiobookPopularTitle.product_audio_type,
                                      categoryList.id,
                                    ],
                                  });
                                  this.setState({
                                    innerActiveTab: "1",
                                  });
                                  this.props.dispatch(
                                    browseCategoryProductDetailsDataAction(
                                      audiobookPopularTitle.product_audio_type,
                                      categoryList.name,
                                      1
                                    )
                                  );
                                }}
                                key={index}
                              >
                                <span key={index}>
                                  {
                                    categoryList.custom_translation[
                                      this.state.selectedLanguage
                                    ].name
                                  }
                                </span>
                              </NavLink>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.durationText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes brwHrs">
                        {getHoursFormat(
                          audiobookPopularTitle.total_tracks_length
                        ) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                  <div className="starRatingBrw">
                    <StarRatings
                      rating={audiobookPopularTitle.total_reviews}
                      starRatedColor="#ff8c00"
                      numberOfStars={5}
                      name="rating"
                      starDimension="20px"
                    />
                  </div>
                  {!audiobookPopularTitle.is_free && (
                    <div className="videoPrice">
                      <span>
                        {this.state.isIndian !== null ?
                          <Fragment>
                            {this.state.isIndian ? strings.priceSign + "" + audiobookPopularTitle.inr_price.amount
                              : "$" + audiobookPopularTitle.inr_price.amount}
                          </Fragment>
                          :
                          <Fragment>
                            {this.props.authData.userCountry === "IN"
                              ? strings.priceSign + "" + audiobookPopularTitle.inr_price.amount
                              : "$" + audiobookPopularTitle.inr_price.amount}
                          </Fragment>
                        }
                      </span>
                    </div>
                  )}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
        );
      }
    );
  }
  audiobookPopularAuthorsData() {
    return this.state.audiobookPopularAuthors.map((audiobookPopular, index) => {
      var authorArray = [];
      audiobookPopular.authors.map((authorName) => {
        if (
          authorName.custom_translation[this.state.selectedLanguage] !==
          undefined
        ) {
          authorArray.push(
            authorName.custom_translation[this.state.selectedLanguage].name
          );
        }
      });
      return (
        <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
          <div
            className="videoThumbDiv"
            id={"PopoverAuthor" + audiobookPopular.id}
          >
            {audiobookPopular.images.length > 0 ? (
              <Fragment>
                {audiobookPopular.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={audiobookPopular.images[0]["image2"]} alt="" />
              </Fragment>
            ) : (
              <Fragment>
                {audiobookPopular.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
              </Fragment>
            )}
            <div className="sliderTrack_Wrap">
              {audiobookPopular.sampletracks.length > 0 && (
                <div>
                  {audiobookPopular.sampletracks[0].map_url !=
                    this.state.productTypeLastPlayed && (
                      <img
                        onClick={() => {
                          this.productTypePlayAudio(
                            audiobookPopular.sampletracks[0].map_url
                          );
                        }}
                        src="/images/play.png"
                      />
                    )}
                  {audiobookPopular.sampletracks[0].map_url ==
                    this.state.productTypeLastPlayed && (
                      <img
                        onClick={() => {
                          this.productTypePauseAudio(
                            audiobookPopular.sampletracks[0].map_url
                          );
                        }}
                        src="/images/round-pause-button.svg"
                      />
                    )}
                </div>
              )}
            </div>
          </div>
          {/* <div className="videoThumbTitleDiv">
            <span
              onClick={() => {
                this.productDetailsId(
                  audiobookPopular.id,
                  audiobookPopular.product_slug
                );
              }}
            >
              {audiobookPopular.custom_translation[
                this.state.selectedLanguage
              ] != undefined &&
                audiobookPopular.custom_translation[this.state.selectedLanguage]
                  .name}
            </span>
            <br />
            {audiobookPopular.authors.length > 0 && (
              <span className="briefTitle">
                {strings.authorText}:{" "}
                {this.authorLinkList(audiobookPopular.authors)}
              </span>
            )}
          </div> */}
          {/* <div className="videoThumbCateDiv">
            {audiobookPopular.category.map((categoryList, index) => {
              if (
                categoryList.custom_translation[this.state.selectedLanguage] !==
                undefined
              ) {
                return (
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      this.props.dispatch({
                        type: "MENU_SELECTED_CATEGORY",
                        payload: [
                          categoryList.name,
                          audiobookPopular.product_audio_type,
                          categoryList.id,
                        ],
                      });
                      this.setState({
                        innerActiveTab: "1",
                      });
                      this.props.dispatch(
                        browseCategoryProductDetailsDataAction(
                          audiobookPopular.product_audio_type,
                          categoryList.name,
                          1
                        )
                      );
                    }}
                    key={index}
                  >
                    <span key={index}>
                      {
                        categoryList.custom_translation[
                          this.state.selectedLanguage
                        ].name
                      }
                    </span>
                  </NavLink>
                );
              }
            })}
          </div> */}
          <UncontrolledPopover
            trigger="hover"
            placement="right"
            target={"PopoverAuthor" + audiobookPopular.id}
          >
            <PopoverBody>
              <div className="threeDot">
                <div className="treeDotTop">
                  <h4>
                    <span
                      className="cursorPointer"
                      onClick={() => {
                        this.productDetailsId(
                          audiobookPopular.id,
                          audiobookPopular.product_slug
                        );
                      }}
                    >
                      {audiobookPopular.custom_translation[
                        this.state.selectedLanguage
                      ] != undefined &&
                        audiobookPopular.custom_translation[
                          this.state.selectedLanguage
                        ].name}
                    </span>
                  </h4>
                  <span className="cateTitle">
                    (As{" "}
                    {audiobookPopular.product_audio_type === "audiobook" &&
                      strings.audiobook}
                    )
                  </span>
                </div>
                {audiobookPopular.authors.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.authorText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {this.authorLinkList(audiobookPopular.authors)}
                    </div>
                  </div>
                )}
                {audiobookPopular.directors.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.directorText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes">
                        {this.directorsSelected(audiobookPopular.directors)}
                      </span>
                    </div>
                  </div>
                )}
                {audiobookPopular.artists.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.artistsText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes">
                        {this.artistsSelected(audiobookPopular.artists)}
                      </span>
                    </div>
                  </div>
                )}
                <div className="videoCnt browseGenre">
                  <div className="headingText">
                    <span className="author">{strings.genreText}</span>
                  </div>
                  <div className="colon-symbol">:</div>
                  <div className="loopdata">
                    {audiobookPopular.category.map((categoryList, index) => {
                      if (
                        categoryList.custom_translation[
                        this.state.selectedLanguage
                        ] !== undefined
                      ) {
                        return (
                          <NavLink
                            tag={Link}
                            onClick={() => {
                              this.props.dispatch({
                                type: "MENU_SELECTED_CATEGORY",
                                payload: [
                                  categoryList.name,
                                  audiobookPopular.product_audio_type,
                                  categoryList.id,
                                ],
                              });
                              this.setState({
                                innerActiveTab: "1",
                              });
                              this.props.dispatch(
                                browseCategoryProductDetailsDataAction(
                                  audiobookPopular.product_audio_type,
                                  categoryList.name,
                                  1
                                )
                              );
                            }}
                            key={index}
                          >
                            <span key={index}>
                              {
                                categoryList.custom_translation[
                                  this.state.selectedLanguage
                                ].name
                              }
                            </span>
                          </NavLink>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="videoCnt">
                  <div className="headingText">
                    <span className="author">{strings.durationText}</span>
                  </div>
                  <div className="colon-symbol">:</div>
                  <div className="loopdata">
                    <span className="authorDes brwHrs">
                      {getHoursFormat(audiobookPopular.total_tracks_length) +
                        " " +
                        strings.hrsText}
                    </span>
                  </div>
                </div>
                <div className="starRatingBrw">
                  <StarRatings
                    rating={audiobookPopular.total_reviews}
                    starRatedColor="#ff8c00"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                  />
                </div>
                {!audiobookPopular.is_free && (
                  <div className="videoPrice">
                    <span>
                      {this.state.isIndian !== null ?
                        <Fragment>
                          {this.state.isIndian ? strings.priceSign + "" + audiobookPopular.inr_price.amount
                            : "$" + audiobookPopular.price.amount}
                        </Fragment>
                        :
                        <Fragment>
                          {this.props.authData.userCountry === "IN"
                            ? strings.priceSign + "" + audiobookPopular.inr_price.amount
                            : "$" + audiobookPopular.price.amount}
                        </Fragment>
                      }
                    </span>
                  </div>
                )}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </Col>
      );
    });
  }
  audiobookEditorPicksData() {
    return this.state.audiobookEditorPicks.map(
      (audiobookEditorPicks, index) => {
        var authorArray = [];
        audiobookEditorPicks.authors.map((authorName) => {
          if (
            authorName.custom_translation[this.state.selectedLanguage] !==
            undefined
          ) {
            authorArray.push(
              authorName.custom_translation[this.state.selectedLanguage].name
            );
          }
        });
        return (
          <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
            <div
              className="videoThumbDiv"
              id={"PopoverEditor" + audiobookEditorPicks.id}
            >
              {audiobookEditorPicks.images.length > 0 ? (
                <Fragment>
                  {audiobookEditorPicks.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={audiobookEditorPicks.images[0]["image2"]} alt="" />
                </Fragment>
              ) : (
                <Fragment>
                  {audiobookEditorPicks.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
                </Fragment>
              )}
              <div className="sliderTrack_Wrap">
                {audiobookEditorPicks.sampletracks.length > 0 && (
                  <div>
                    {audiobookEditorPicks.sampletracks[0].map_url !=
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePlayAudio(
                              audiobookEditorPicks.sampletracks[0].map_url
                            );
                          }}
                          src="/images/play.png"
                        />
                      )}
                    {audiobookEditorPicks.sampletracks[0].map_url ==
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePauseAudio(
                              audiobookEditorPicks.sampletracks[0].map_url
                            );
                          }}
                          src="/images/round-pause-button.svg"
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="videoThumbTitleDiv">
              <span
                onClick={() => {
                  this.productDetailsId(
                    audiobookEditorPicks.id,
                    audiobookEditorPicks.product_slug
                  );
                }}
              >
                {audiobookEditorPicks.custom_translation[
                  this.state.selectedLanguage
                ] != undefined &&
                  audiobookEditorPicks.custom_translation[
                    this.state.selectedLanguage
                  ].name}
              </span>
              <br />
              {audiobookEditorPicks.authors.length > 0 && (
                <span className="briefTitle">
                  {strings.authorText}:{" "}
                  {this.authorLinkList(audiobookEditorPicks.authors)}
                </span>
              )}
            </div> */}
            {/* <div className="videoThumbCateDiv">
              {audiobookEditorPicks.category.map((categoryList, index) => {
                if (
                  categoryList.custom_translation[
                    this.state.selectedLanguage
                  ] !== undefined
                ) {
                  return (
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_SELECTED_CATEGORY",
                          payload: [
                            categoryList.name,
                            audiobookEditorPicks.product_audio_type,
                            categoryList.id,
                          ],
                        });
                        this.setState({
                          innerActiveTab: "1",
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(
                            audiobookEditorPicks.product_audio_type,
                            categoryList.name,
                            1
                          )
                        );
                      }}
                      key={index}
                    >
                      <span key={index}>
                        {
                          categoryList.custom_translation[
                            this.state.selectedLanguage
                          ].name
                        }
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div> */}
            <UncontrolledPopover
              trigger="hover"
              placement="right"
              target={"PopoverEditor" + audiobookEditorPicks.id}
            >
              <PopoverBody>
                <div className="threeDot">
                  <div className="treeDotTop">
                    <h4>
                      <span
                        className="cursorPointer"
                        onClick={() => {
                          this.productDetailsId(
                            audiobookEditorPicks.id,
                            audiobookEditorPicks.product_slug
                          );
                        }}
                      >
                        {audiobookEditorPicks.custom_translation[
                          this.state.selectedLanguage
                        ] != undefined &&
                          audiobookEditorPicks.custom_translation[
                            this.state.selectedLanguage
                          ].name}
                      </span>
                    </h4>
                    <span className="cateTitle">
                      (As{" "}
                      {audiobookEditorPicks.product_audio_type ===
                        "audiobook" && strings.audiobook}
                      )
                    </span>
                  </div>
                  {audiobookEditorPicks.authors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        {this.authorLinkList(audiobookEditorPicks.authors)}
                      </div>
                    </div>
                  )}
                  {audiobookEditorPicks.directors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.directorsSelected(
                            audiobookEditorPicks.directors
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {audiobookEditorPicks.artists.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.artistsSelected(audiobookEditorPicks.artists)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="videoCnt browseGenre">
                    <div className="headingText">
                      <span className="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {audiobookEditorPicks.category.map(
                        (categoryList, index) => {
                          if (
                            categoryList.custom_translation[
                            this.state.selectedLanguage
                            ] !== undefined
                          ) {
                            return (
                              <NavLink
                                tag={Link}
                                onClick={() => {
                                  this.props.dispatch({
                                    type: "MENU_SELECTED_CATEGORY",
                                    payload: [
                                      categoryList.name,
                                      audiobookEditorPicks.product_audio_type,
                                      categoryList.id,
                                    ],
                                  });
                                  this.setState({
                                    innerActiveTab: "1",
                                  });
                                  this.props.dispatch(
                                    browseCategoryProductDetailsDataAction(
                                      audiobookEditorPicks.product_audio_type,
                                      categoryList.name,
                                      1
                                    )
                                  );
                                }}
                                key={index}
                              >
                                <span key={index}>
                                  {
                                    categoryList.custom_translation[
                                      this.state.selectedLanguage
                                    ].name
                                  }
                                </span>
                              </NavLink>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.durationText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes brwHrs">
                        {getHoursFormat(
                          audiobookEditorPicks.total_tracks_length
                        ) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                  <div className="starRatingBrw">
                    <StarRatings
                      rating={audiobookEditorPicks.total_reviews}
                      starRatedColor="#ff8c00"
                      numberOfStars={5}
                      name="rating"
                      starDimension="20px"
                    />
                  </div>
                  {!audiobookEditorPicks.is_free && (
                    <div className="videoPrice">
                      <span>
                        {this.state.isIndian !== null ?
                          <Fragment>
                            {this.state.isIndian ? strings.priceSign + "" + audiobookEditorPicks.inr_price.amount
                              : "$" + audiobookEditorPicks.price.amount}
                          </Fragment>
                          :
                          <Fragment>
                            {this.props.authData.userCountry === "IN"
                              ? strings.priceSign + "" + audiobookEditorPicks.inr_price.amount
                              : "$" + audiobookEditorPicks.price.amount}
                          </Fragment>
                        }
                      </span>
                    </div>
                  )}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
        );
      }
    );
  }

  /*********** End of audio book content ************* */

  /********Snovel Original tab content and products ************/
  snovelOriginalAllProductsData() {
    return this.state.snovelOriginalAllProducts.map(
      (snovelOriginalAllProduct, index) => {
        var authorArray = [];
        snovelOriginalAllProduct.authors.map((authorName) => {
          if (
            authorName.custom_translation[this.state.selectedLanguage] !==
            undefined
          ) {
            authorArray.push(
              authorName.custom_translation[this.state.selectedLanguage].name
            );
          }
        });
        return (
          <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
            <div
              className="videoThumbDiv"
              id={"PopoverLast" + snovelOriginalAllProduct.id}
            >
              {snovelOriginalAllProduct.images.length > 0 ? (
                <Fragment>
                  {snovelOriginalAllProduct.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={snovelOriginalAllProduct.images[0]["image2"]} alt="" />
                </Fragment>
              ) : (
                <Fragment>
                  {snovelOriginalAllProduct.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
                </Fragment>
              )}
              <div className="sliderTrack_Wrap">
                {snovelOriginalAllProduct.sampletracks.length > 0 && (
                  <div>
                    {snovelOriginalAllProduct.sampletracks[0].map_url !=
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePlayAudio(
                              snovelOriginalAllProduct.sampletracks[0].map_url
                            );
                          }}
                          src="/images/play.png"
                        />
                      )}
                    {snovelOriginalAllProduct.sampletracks[0].map_url ==
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePauseAudio(
                              snovelOriginalAllProduct.sampletracks[0].map_url
                            );
                          }}
                          src="/images/round-pause-button.svg"
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="videoThumbTitleDiv">
              <span
                onClick={() => {
                  this.productDetailsId(
                    snovelOriginalAllProduct.id,
                    snovelOriginalAllProduct.product_slug
                  );
                }}
              >
                {snovelOriginalAllProduct.custom_translation[
                  this.state.selectedLanguage
                ] != undefined &&
                  snovelOriginalAllProduct.custom_translation[
                    this.state.selectedLanguage
                  ].name}
              </span>
              <br />
              {snovelOriginalAllProduct.authors.length > 0 && (
                <span className="briefTitle">
                  {strings.authorText}:{" "}
                  {this.authorLinkList(snovelOriginalAllProduct.authors)}
                </span>
              )}
            </div> */}
            {/* <div className="videoThumbCateDiv">
              {snovelOriginalAllProduct.category.map((categoryList, index) => {
                if (
                  categoryList.custom_translation[
                    this.state.selectedLanguage
                  ] !== undefined
                ) {
                  return (
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_SELECTED_CATEGORY",
                          payload: [
                            categoryList.name,
                            snovelOriginalAllProduct.product_audio_type,
                            categoryList.id,
                          ],
                        });
                        this.setState({
                          innerActiveTab: "1",
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(
                            snovelOriginalAllProduct.product_audio_type,
                            categoryList.name,
                            1
                          )
                        );
                      }}
                      key={index}
                    >
                      <span key={index}>
                        {
                          categoryList.custom_translation[
                            this.state.selectedLanguage
                          ].name
                        }
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div> */}
            <UncontrolledPopover
              trigger="hover"
              placement="right"
              target={"PopoverLast" + snovelOriginalAllProduct.id}
            >
              <PopoverBody>
                <div className="threeDot">
                  <div className="treeDotTop">
                    <h4>
                      <span
                        className="cursorPointer"
                        onClick={() => {
                          this.productDetailsId(
                            snovelOriginalAllProduct.id,
                            snovelOriginalAllProduct.product_slug
                          );
                        }}
                      >
                        {snovelOriginalAllProduct.custom_translation[
                          this.state.selectedLanguage
                        ] != undefined &&
                          snovelOriginalAllProduct.custom_translation[
                            this.state.selectedLanguage
                          ].name}
                      </span>
                    </h4>
                    <span className="cateTitle">
                      (As{" "}
                      {snovelOriginalAllProduct.product_audio_type ===
                        "snoveloriginal" && strings.snoveloriginal}
                      )
                    </span>
                  </div>
                  {snovelOriginalAllProduct.authors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        {this.authorLinkList(snovelOriginalAllProduct.authors)}
                      </div>
                    </div>
                  )}
                  {snovelOriginalAllProduct.directors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.directorsSelected(
                            snovelOriginalAllProduct.directors
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {snovelOriginalAllProduct.artists.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.artistsSelected(
                            snovelOriginalAllProduct.artists
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="videoCnt browseGenre">
                    <div className="headingText">
                      <span className="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {snovelOriginalAllProduct.category.map(
                        (categoryList, index) => {
                          if (
                            categoryList.custom_translation[
                            this.state.selectedLanguage
                            ] !== undefined
                          ) {
                            return (
                              <NavLink
                                tag={Link}
                                onClick={() => {
                                  this.props.dispatch({
                                    type: "MENU_SELECTED_CATEGORY",
                                    payload: [
                                      categoryList.name,
                                      snovelOriginalAllProduct.product_audio_type,
                                      categoryList.id,
                                    ],
                                  });
                                  this.setState({
                                    innerActiveTab: "1",
                                  });
                                  this.props.dispatch(
                                    browseCategoryProductDetailsDataAction(
                                      snovelOriginalAllProduct.product_audio_type,
                                      categoryList.name,
                                      1
                                    )
                                  );
                                }}
                                key={index}
                              >
                                <span key={index}>
                                  {
                                    categoryList.custom_translation[
                                      this.state.selectedLanguage
                                    ].name
                                  }
                                </span>
                              </NavLink>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.durationText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes brwHrs">
                        {getHoursFormat(
                          snovelOriginalAllProduct.total_tracks_length
                        ) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                  <div className="starRatingBrw">
                    <StarRatings
                      rating={snovelOriginalAllProduct.total_reviews}
                      starRatedColor="#ff8c00"
                      numberOfStars={5}
                      name="rating"
                      starDimension="20px"
                    />
                  </div>
                  {!snovelOriginalAllProduct.is_free && (
                    <div className="videoPrice">
                      <span>
                        {this.state.isIndian !== null ?
                          <Fragment>
                            {this.state.isIndian ? strings.priceSign + "" + snovelOriginalAllProduct.inr_price.amount
                              : "$" + snovelOriginalAllProduct.price.amount}
                          </Fragment>
                          :
                          <Fragment>
                            {this.props.authData.userCountry === "IN"
                              ? strings.priceSign + "" + snovelOriginalAllProduct.inr_price.amount
                              : "$" + snovelOriginalAllProduct.price.amount}
                          </Fragment>
                        }
                      </span>
                    </div>
                  )}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
        );
      }
    );
  }
  snovelOriginalPopularTitlesData() {
    return this.state.snovelOriginalPopularTitles.map(
      (originalPopularTitle, index) => {
        var authorArray = [];
        originalPopularTitle.authors.map((authorName) => {
          if (
            authorName.custom_translation[this.state.selectedLanguage] !==
            undefined
          ) {
            authorArray.push(
              authorName.custom_translation[this.state.selectedLanguage].name
            );
          }
        });
        return (
          <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
            <div
              className="videoThumbDiv"
              id={"Popover" + originalPopularTitle.id}
            >
              {originalPopularTitle.images.length > 0 ? (
                <Fragment>
                  {originalPopularTitle.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={originalPopularTitle.images[0]["image2"]} alt="" />
                </Fragment>
              ) : (
                <Fragment>
                  {originalPopularTitle.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
                </Fragment>
              )}
              <div className="sliderTrack_Wrap">
                {originalPopularTitle.sampletracks.length > 0 && (
                  <div>
                    {originalPopularTitle.sampletracks[0].map_url !=
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePlayAudio(
                              originalPopularTitle.sampletracks[0].map_url
                            );
                          }}
                          src="/images/play.png"
                        />
                      )}
                    {originalPopularTitle.sampletracks[0].map_url ==
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePauseAudio(
                              originalPopularTitle.sampletracks[0].map_url
                            );
                          }}
                          src="/images/round-pause-button.svg"
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="videoThumbTitleDiv">
              <span
                onClick={() => {
                  this.productDetailsId(
                    originalPopularTitle.id,
                    originalPopularTitle.product_slug
                  );
                }}
              >
                {originalPopularTitle.custom_translation[
                  this.state.selectedLanguage
                ] != undefined &&
                  originalPopularTitle.custom_translation[
                    this.state.selectedLanguage
                  ].name}
              </span>
              <br />
              {originalPopularTitle.authors.length > 0 && (
                <span className="briefTitle">
                  {strings.authorText}:{" "}
                  {this.authorLinkList(originalPopularTitle.authors)}
                </span>
              )}
            </div> */}
            {/* <div className="videoThumbCateDiv">
              {originalPopularTitle.category.map((categoryList, index) => {
                if (
                  categoryList.custom_translation[
                    this.state.selectedLanguage
                  ] !== undefined
                ) {
                  return (
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_SELECTED_CATEGORY",
                          payload: [
                            categoryList.name,
                            originalPopularTitle.product_audio_type,
                            categoryList.id,
                          ],
                        });
                        this.setState({
                          innerActiveTab: "1",
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(
                            originalPopularTitle.product_audio_type,
                            categoryList.name,
                            1
                          )
                        );
                      }}
                      key={index}
                    >
                      <span key={index}>
                        {
                          categoryList.custom_translation[
                            this.state.selectedLanguage
                          ].name
                        }
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div> */}
            <UncontrolledPopover
              trigger="hover"
              placement="right"
              target={"Popover" + originalPopularTitle.id}
            >
              <PopoverBody>
                <div className="threeDot">
                  <div className="treeDotTop">
                    <h4>
                      <span
                        className="cursorPointer"
                        onClick={() => {
                          this.productDetailsId(
                            originalPopularTitle.id,
                            originalPopularTitle.product_slug
                          );
                        }}
                      >
                        {originalPopularTitle.custom_translation[
                          this.state.selectedLanguage
                        ] != undefined &&
                          originalPopularTitle.custom_translation[
                            this.state.selectedLanguage
                          ].name}
                      </span>
                    </h4>
                    <span className="cateTitle">
                      (As{" "}
                      {originalPopularTitle.product_audio_type ===
                        "snoveloriginal" && strings.snoveloriginal}
                      )
                    </span>
                  </div>
                  {originalPopularTitle.authors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        {this.authorLinkList(originalPopularTitle.authors)}
                      </div>
                    </div>
                  )}
                  {originalPopularTitle.directors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.directorsSelected(
                            originalPopularTitle.directors
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {originalPopularTitle.artists.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.artistsSelected(originalPopularTitle.artists)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="videoCnt browseGenre">
                    <div className="headingText">
                      <span className="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {originalPopularTitle.category.map(
                        (categoryList, index) => {
                          if (
                            categoryList.custom_translation[
                            this.state.selectedLanguage
                            ] !== undefined
                          ) {
                            return (
                              <NavLink
                                tag={Link}
                                onClick={() => {
                                  this.props.dispatch({
                                    type: "MENU_SELECTED_CATEGORY",
                                    payload: [
                                      categoryList.name,
                                      originalPopularTitle.product_audio_type,
                                      categoryList.id,
                                    ],
                                  });
                                  this.setState({
                                    innerActiveTab: "1",
                                  });
                                  this.props.dispatch(
                                    browseCategoryProductDetailsDataAction(
                                      originalPopularTitle.product_audio_type,
                                      categoryList.name,
                                      1
                                    )
                                  );
                                }}
                                key={index}
                              >
                                <span key={index}>
                                  {
                                    categoryList.custom_translation[
                                      this.state.selectedLanguage
                                    ].name
                                  }
                                </span>
                              </NavLink>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.durationText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes brwHrs">
                        {getHoursFormat(
                          originalPopularTitle.total_tracks_length
                        ) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                  <div className="starRatingBrw">
                    <StarRatings
                      rating={originalPopularTitle.total_reviews}
                      starRatedColor="#ff8c00"
                      numberOfStars={5}
                      name="rating"
                      starDimension="20px"
                    />
                  </div>
                  {!originalPopularTitle.is_free && (
                    <div className="videoPrice">
                      <span>
                        {this.state.isIndian !== null ?
                          <Fragment>
                            {this.state.isIndian ? strings.priceSign + "" + originalPopularTitle.inr_price.amount
                              : "$" + originalPopularTitle.price.amount}
                          </Fragment>
                          :
                          <Fragment>
                            {this.props.authData.userCountry === "IN"
                              ? strings.priceSign + "" + originalPopularTitle.inr_price.amount
                              : "$" + originalPopularTitle.price.amount}
                          </Fragment>
                        }
                      </span>
                    </div>
                  )}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
        );
      }
    );
  }
  snovelOriginalPopularAuthorsData() {
    return this.state.snovelOriginalPopularAuthors.map(
      (originalAuthorPopular, index) => {
        var authorArray = [];
        originalAuthorPopular.authors.map((authorName) => {
          if (
            authorName.custom_translation[this.state.selectedLanguage] !==
            undefined
          ) {
            authorArray.push(
              authorName.custom_translation[this.state.selectedLanguage].name
            );
          }
        });
        return (
          <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
            <div
              className="videoThumbDiv"
              id={"PopoverAuthor" + originalAuthorPopular.id}
            >
              {originalAuthorPopular.images.length > 0 ? (
                <Fragment>
                  {originalAuthorPopular.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={originalAuthorPopular.images[0]["image2"]} alt="" />
                </Fragment>
              ) : (
                <Fragment>
                  {originalAuthorPopular.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
                </Fragment>
              )}
              <div className="sliderTrack_Wrap">
                {originalAuthorPopular.sampletracks.length > 0 && (
                  <div>
                    {originalAuthorPopular.sampletracks[0].map_url !=
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePlayAudio(
                              originalAuthorPopular.sampletracks[0].map_url
                            );
                          }}
                          src="/images/play.png"
                        />
                      )}
                    {originalAuthorPopular.sampletracks[0].map_url ==
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePauseAudio(
                              originalAuthorPopular.sampletracks[0].map_url
                            );
                          }}
                          src="/images/round-pause-button.svg"
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="videoThumbTitleDiv">
              <span
                onClick={() => {
                  this.productDetailsId(
                    originalAuthorPopular.id,
                    originalAuthorPopular.product_slug
                  );
                }}
              >
                {originalAuthorPopular.custom_translation[
                  this.state.selectedLanguage
                ] != undefined &&
                  originalAuthorPopular.custom_translation[
                    this.state.selectedLanguage
                  ].name}
              </span>
              <br />
              {originalAuthorPopular.authors.length > 0 && (
                <span className="briefTitle">
                  {strings.authorText}:{" "}
                  {this.authorLinkList(originalAuthorPopular.authors)}
                </span>
              )}
            </div> */}
            {/* <div className="videoThumbCateDiv">
              {originalAuthorPopular.category.map((categoryList, index) => {
                if (
                  categoryList.custom_translation[
                    this.state.selectedLanguage
                  ] !== undefined
                ) {
                  return (
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_SELECTED_CATEGORY",
                          payload: [
                            categoryList.name,
                            originalAuthorPopular.product_audio_type,
                            categoryList.id,
                          ],
                        });
                        this.setState({
                          innerActiveTab: "1",
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(
                            originalAuthorPopular.product_audio_type,
                            categoryList.name,
                            1
                          )
                        );
                      }}
                      key={index}
                    >
                      <span key={index}>
                        {
                          categoryList.custom_translation[
                            this.state.selectedLanguage
                          ].name
                        }
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div> */}
            <UncontrolledPopover
              trigger="hover"
              placement="right"
              target={"PopoverAuthor" + originalAuthorPopular.id}
            >
              <PopoverBody>
                <div className="threeDot">
                  <div className="treeDotTop">
                    <h4>
                      <span
                        className="cursorPointer"
                        onClick={() => {
                          this.productDetailsId(
                            originalAuthorPopular.id,
                            originalAuthorPopular.product_slug
                          );
                        }}
                      >
                        {originalAuthorPopular.custom_translation[
                          this.state.selectedLanguage
                        ] != undefined &&
                          originalAuthorPopular.custom_translation[
                            this.state.selectedLanguage
                          ].name}
                      </span>
                    </h4>
                    <span className="cateTitle">
                      (As{" "}
                      {originalAuthorPopular.product_audio_type ===
                        "snoveloriginal" && strings.snoveloriginal}
                      )
                    </span>
                  </div>
                  {originalAuthorPopular.authors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        {this.authorLinkList(originalAuthorPopular.authors)}
                      </div>
                    </div>
                  )}
                  {originalAuthorPopular.directors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.directorsSelected(
                            originalAuthorPopular.directors
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {originalAuthorPopular.artists.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.artistsSelected(originalAuthorPopular.artists)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="videoCnt browseGenre">
                    <div className="headingText">
                      <span className="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {originalAuthorPopular.category.map(
                        (categoryList, index) => {
                          if (
                            categoryList.custom_translation[
                            this.state.selectedLanguage
                            ] !== undefined
                          ) {
                            return (
                              <NavLink
                                tag={Link}
                                onClick={() => {
                                  this.props.dispatch({
                                    type: "MENU_SELECTED_CATEGORY",
                                    payload: [
                                      categoryList.name,
                                      originalAuthorPopular.product_audio_type,
                                      categoryList.id,
                                    ],
                                  });
                                  this.setState({
                                    innerActiveTab: "1",
                                  });
                                  this.props.dispatch(
                                    browseCategoryProductDetailsDataAction(
                                      originalAuthorPopular.product_audio_type,
                                      categoryList.name,
                                      1
                                    )
                                  );
                                }}
                                key={index}
                              >
                                <span key={index}>
                                  {
                                    categoryList.custom_translation[
                                      this.state.selectedLanguage
                                    ].name
                                  }
                                </span>
                              </NavLink>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.durationText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes brwHrs">
                        {getHoursFormat(
                          originalAuthorPopular.total_tracks_length
                        ) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                  <div className="starRatingBrw">
                    <StarRatings
                      rating={originalAuthorPopular.total_reviews}
                      starRatedColor="#ff8c00"
                      numberOfStars={5}
                      name="rating"
                      starDimension="20px"
                    />
                  </div>
                  {!originalAuthorPopular.is_free && (
                    <div className="videoPrice">
                      <span>
                        {this.state.isIndian !== null ?
                          <Fragment>
                            {this.state.isIndian ? strings.priceSign + "" + originalAuthorPopular.inr_price.amount
                              : "$" + originalAuthorPopular.price.amount}
                          </Fragment>
                          :
                          <Fragment>
                            {this.props.authData.userCountry === "IN"
                              ? strings.priceSign + "" + originalAuthorPopular.inr_price.amount
                              : "$" + originalAuthorPopular.price.amount}
                          </Fragment>
                        }
                      </span>
                    </div>
                  )}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
        );
      }
    );
  }
  snovelOriginalEditorPicksData() {
    return this.state.snovelOriginalEditorPicks.map(
      (originalEditorPicks, index) => {
        var authorArray = [];
        originalEditorPicks.authors.map((authorName) => {
          if (
            authorName.custom_translation[this.state.selectedLanguage] !==
            undefined
          ) {
            authorArray.push(
              authorName.custom_translation[this.state.selectedLanguage].name
            );
          }
        });
        return (
          <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
            <div
              className="videoThumbDiv"
              id={"PopoverEditor" + originalEditorPicks.id}
            >
              {originalEditorPicks.images.length > 0 ? (
                <Fragment>
                  {originalEditorPicks.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={originalEditorPicks.images[0]["image2"]} alt="" />
                </Fragment>
              ) : (
                <Fragment>
                  {originalEditorPicks.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
                </Fragment>
              )}
              <div className="sliderTrack_Wrap">
                {originalEditorPicks.sampletracks.length > 0 && (
                  <div>
                    {originalEditorPicks.sampletracks[0].map_url !=
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePlayAudio(
                              originalEditorPicks.sampletracks[0].map_url
                            );
                          }}
                          src="/images/play.png"
                        />
                      )}
                    {originalEditorPicks.sampletracks[0].map_url ==
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePauseAudio(
                              originalEditorPicks.sampletracks[0].map_url
                            );
                          }}
                          src="/images/round-pause-button.svg"
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="videoThumbTitleDiv">
              <span
                onClick={() => {
                  this.productDetailsId(
                    originalEditorPicks.id,
                    originalEditorPicks.product_slug
                  );
                }}
              >
                {originalEditorPicks.custom_translation[
                  this.state.selectedLanguage
                ] != undefined &&
                  originalEditorPicks.custom_translation[
                    this.state.selectedLanguage
                  ].name}
              </span>
              <br />
              {originalEditorPicks.authors.length > 0 && (
                <span className="briefTitle">
                  {strings.authorText}:{" "}
                  {this.authorLinkList(originalEditorPicks.authors)}
                </span>
              )}
            </div> */}
            {/* <div className="videoThumbCateDiv">
              {originalEditorPicks.category.map((categoryList, index) => {
                if (
                  categoryList.custom_translation[
                    this.state.selectedLanguage
                  ] !== undefined
                ) {
                  return (
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_SELECTED_CATEGORY",
                          payload: [
                            categoryList.name,
                            originalEditorPicks.product_audio_type,
                            categoryList.id,
                          ],
                        });
                        this.setState({
                          innerActiveTab: "1",
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(
                            originalEditorPicks.product_audio_type,
                            categoryList.name,
                            1
                          )
                        );
                      }}
                      key={index}
                    >
                      <span key={index}>
                        {
                          categoryList.custom_translation[
                            this.state.selectedLanguage
                          ].name
                        }
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div> */}
            <UncontrolledPopover
              trigger="hover"
              placement="right"
              target={"PopoverEditor" + originalEditorPicks.id}
            >
              <PopoverBody>
                <div className="threeDot">
                  <div className="treeDotTop">
                    <h4>
                      <span
                        className="cursorPointer"
                        onClick={() => {
                          this.productDetailsId(
                            originalEditorPicks.id,
                            originalEditorPicks.product_slug
                          );
                        }}
                      >
                        {originalEditorPicks.custom_translation[
                          this.state.selectedLanguage
                        ] != undefined &&
                          originalEditorPicks.custom_translation[
                            this.state.selectedLanguage
                          ].name}
                      </span>
                    </h4>
                    <span className="cateTitle">
                      (As{" "}
                      {originalEditorPicks.product_audio_type ===
                        "snoveloriginal" && strings.snoveloriginal}
                      )
                    </span>
                  </div>
                  {originalEditorPicks.authors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        {this.authorLinkList(originalEditorPicks.authors)}
                      </div>
                    </div>
                  )}
                  {originalEditorPicks.directors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.directorsSelected(
                            originalEditorPicks.directors
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {originalEditorPicks.artists.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.artistsSelected(originalEditorPicks.artists)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="videoCnt browseGenre">
                    <div className="headingText">
                      <span className="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {originalEditorPicks.category.map(
                        (categoryList, index) => {
                          if (
                            categoryList.custom_translation[
                            this.state.selectedLanguage
                            ] !== undefined
                          ) {
                            return (
                              <NavLink
                                tag={Link}
                                onClick={() => {
                                  this.props.dispatch({
                                    type: "MENU_SELECTED_CATEGORY",
                                    payload: [
                                      categoryList.name,
                                      originalEditorPicks.product_audio_type,
                                      categoryList.id,
                                    ],
                                  });
                                  this.setState({
                                    innerActiveTab: "1",
                                  });
                                  this.props.dispatch(
                                    browseCategoryProductDetailsDataAction(
                                      originalEditorPicks.product_audio_type,
                                      categoryList.name,
                                      1
                                    )
                                  );
                                }}
                                key={index}
                              >
                                <span key={index}>
                                  {
                                    categoryList.custom_translation[
                                      this.state.selectedLanguage
                                    ].name
                                  }
                                </span>
                              </NavLink>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.durationText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes brwHrs">
                        {getHoursFormat(
                          originalEditorPicks.total_tracks_length
                        ) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                  <div className="starRatingBrw">
                    <StarRatings
                      rating={originalEditorPicks.total_reviews}
                      starRatedColor="#ff8c00"
                      numberOfStars={5}
                      name="rating"
                      starDimension="20px"
                    />
                  </div>
                  {!originalEditorPicks.is_free && (
                    <div className="videoPrice">
                      <span>
                        {this.state.isIndian !== null ?
                          <Fragment>
                            {this.state.isIndian ? strings.priceSign + "" + originalEditorPicks.inr_price.amount
                              : "$" + originalEditorPicks.price.amount}
                          </Fragment>
                          :
                          <Fragment>
                            {this.props.authData.userCountry === "IN"
                              ? strings.priceSign + "" + originalEditorPicks.inr_price.amount
                              : "$" + originalEditorPicks.price.amount}
                          </Fragment>
                        }
                      </span>
                    </div>
                  )}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
        );
      }
    );
  }
  /******** End of Snovel Original products *************/
  /******** Podcast tab content and products ************/
  podcastAllProductsData() {
    return this.state.podcastAllProducts.map((podcastAllProduct, index) => {
      var authorArray = [];
      podcastAllProduct.authors.map((authorName) => {
        if (
          authorName.custom_translation[this.state.selectedLanguage] !==
          undefined
        ) {
          authorArray.push(
            authorName.custom_translation[this.state.selectedLanguage].name
          );
        }
      });
      return (
        <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
          <div
            className="videoThumbDiv"
            id={"PopoverLast" + podcastAllProduct.id}
          >
            {podcastAllProduct.images.length > 0 ? (<Fragment>
              {podcastAllProduct.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={podcastAllProduct.images[0]["image2"]} alt="" />
            </Fragment>
            ) : (<Fragment>
              {podcastAllProduct.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
            </Fragment>
            )}
            <div className="sliderTrack_Wrap">
              {podcastAllProduct.sampletracks.length > 0 && (
                <div>
                  {podcastAllProduct.sampletracks[0].map_url !=
                    this.state.productTypeLastPlayed && (
                      <img
                        onClick={() => {
                          this.productTypePlayAudio(
                            podcastAllProduct.sampletracks[0].map_url
                          );
                        }}
                        src="/images/play.png"
                      />
                    )}
                  {podcastAllProduct.sampletracks[0].map_url ==
                    this.state.productTypeLastPlayed && (
                      <img
                        onClick={() => {
                          this.productTypePauseAudio(
                            podcastAllProduct.sampletracks[0].map_url
                          );
                        }}
                        src="/images/round-pause-button.svg"
                      />
                    )}
                </div>
              )}
            </div>
          </div>
          {/* <div className="videoThumbTitleDiv">
            <span
              onClick={() => {
                this.productDetailsId(
                  podcastAllProduct.id,
                  podcastAllProduct.product_slug
                );
              }}
            >
              {podcastAllProduct.custom_translation[
                this.state.selectedLanguage
              ] != undefined &&
                podcastAllProduct.custom_translation[
                  this.state.selectedLanguage
                ].name}
            </span>
            <br />
            {podcastAllProduct.authors.length > 0 && (
              <span className="briefTitle">
                {strings.authorText}:{" "}
                {this.authorLinkList(podcastAllProduct.authors)}
              </span>
            )}
          </div> */}
          {/* <div className="videoThumbCateDiv">
            {podcastAllProduct.category.map((categoryList, index) => {
              if (
                categoryList.custom_translation[this.state.selectedLanguage] !==
                undefined
              ) {
                return (
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      this.props.dispatch({
                        type: "MENU_SELECTED_CATEGORY",
                        payload: [
                          categoryList.name,
                          podcastAllProduct.product_audio_type,
                          categoryList.id,
                        ],
                      });
                      this.setState({
                        innerActiveTab: "1",
                      });
                      this.props.dispatch(
                        browseCategoryProductDetailsDataAction(
                          podcastAllProduct.product_audio_type,
                          categoryList.name,
                          1
                        )
                      );
                    }}
                    key={index}
                  >
                    <span key={index}>
                      {
                        categoryList.custom_translation[
                          this.state.selectedLanguage
                        ].name
                      }
                    </span>
                  </NavLink>
                );
              }
            })}
          </div> */}
          <UncontrolledPopover
            trigger="hover"
            placement="right"
            target={"PopoverLast" + podcastAllProduct.id}
          >
            <PopoverBody>
              <div className="threeDot">
                <div className="treeDotTop">
                  <h4>
                    <span
                      className="cursorPointer"
                      onClick={() => {
                        this.productDetailsId(
                          podcastAllProduct.id,
                          podcastAllProduct.product_slug
                        );
                      }}
                    >
                      {podcastAllProduct.custom_translation[
                        this.state.selectedLanguage
                      ] != undefined &&
                        podcastAllProduct.custom_translation[
                          this.state.selectedLanguage
                        ].name}
                    </span>
                  </h4>
                  <span className="cateTitle">
                    (As{" "}
                    {podcastAllProduct.product_audio_type === "podcast" &&
                      strings.podcast}
                    )
                  </span>
                </div>
                {podcastAllProduct.authors.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.authorText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {this.authorLinkList(podcastAllProduct.authors)}
                    </div>
                  </div>
                )}
                {podcastAllProduct.directors.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.directorText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes">
                        {this.directorsSelected(podcastAllProduct.directors)}
                      </span>
                    </div>
                  </div>
                )}
                {podcastAllProduct.artists.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.artistsText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes">
                        {this.artistsSelected(podcastAllProduct.artists)}
                      </span>
                    </div>
                  </div>
                )}
                <div className="videoCnt browseGenre">
                  <div className="headingText">
                    <span className="author">{strings.genreText}</span>
                  </div>
                  <div className="colon-symbol">:</div>
                  <div className="loopdata">
                    {podcastAllProduct.category.map((categoryList, index) => {
                      if (
                        categoryList.custom_translation[
                        this.state.selectedLanguage
                        ] !== undefined
                      ) {
                        return (
                          <NavLink
                            tag={Link}
                            onClick={() => {
                              this.props.dispatch({
                                type: "MENU_SELECTED_CATEGORY",
                                payload: [
                                  categoryList.name,
                                  podcastAllProduct.product_audio_type,
                                  categoryList.id,
                                ],
                              });
                              this.setState({
                                innerActiveTab: "1",
                              });
                              this.props.dispatch(
                                browseCategoryProductDetailsDataAction(
                                  podcastAllProduct.product_audio_type,
                                  categoryList.name,
                                  1
                                )
                              );
                            }}
                            key={index}
                          >
                            <span key={index}>
                              {
                                categoryList.custom_translation[
                                  this.state.selectedLanguage
                                ].name
                              }
                            </span>
                          </NavLink>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="videoCnt">
                  <div className="headingText">
                    <span className="author">{strings.durationText}</span>
                  </div>
                  <div className="colon-symbol">:</div>
                  <div className="loopdata">
                    <span className="authorDes brwHrs">
                      {getHoursFormat(podcastAllProduct.total_tracks_length) +
                        " " +
                        strings.hrsText}
                    </span>
                  </div>
                </div>
                <div className="starRatingBrw">
                  <StarRatings
                    rating={podcastAllProduct.total_reviews}
                    starRatedColor="#ff8c00"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                  />
                </div>
                {!podcastAllProduct.is_free && (
                  <div className="videoPrice">
                    <span>
                      {this.state.isIndian !== null ?
                        <Fragment>
                          {this.state.isIndian ? strings.priceSign + "" + podcastAllProduct.inr_price.amount
                            : "$" + podcastAllProduct.price.amount}
                        </Fragment>
                        :
                        <Fragment>
                          {this.props.authData.userCountry === "IN"
                            ? strings.priceSign + "" + podcastAllProduct.inr_price.amount
                            : "$" + podcastAllProduct.price.amount}
                        </Fragment>
                      }
                    </span>
                  </div>
                )}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </Col>
      );
    });
  }
  podcastPopularTitlesData() {
    return this.state.podcastPopularTitles.map((podcastPopularTitle, index) => {
      var authorArray = [];
      podcastPopularTitle.authors.map((authorName) => {
        if (
          authorName.custom_translation[this.state.selectedLanguage] !==
          undefined
        ) {
          authorArray.push(
            authorName.custom_translation[this.state.selectedLanguage].name
          );
        }
      });
      return (
        <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
          <div
            className="videoThumbDiv"
            id={"Popover" + podcastPopularTitle.id}
          >
            {podcastPopularTitle.images.length > 0 ? (<Fragment>
              {podcastPopularTitle.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={podcastPopularTitle.images[0]["image2"]} alt="" />
            </Fragment>
            ) : (<Fragment>
              {podcastPopularTitle.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
            </Fragment>
            )}
            <div className="sliderTrack_Wrap">
              {podcastPopularTitle.sampletracks.length > 0 && (
                <div>
                  {podcastPopularTitle.sampletracks[0].map_url !=
                    this.state.productTypeLastPlayed && (
                      <img
                        onClick={() => {
                          this.productTypePlayAudio(
                            podcastPopularTitle.sampletracks[0].map_url
                          );
                        }}
                        src="/images/play.png"
                      />
                    )}
                  {podcastPopularTitle.sampletracks[0].map_url ==
                    this.state.productTypeLastPlayed && (
                      <img
                        onClick={() => {
                          this.productTypePauseAudio(
                            podcastPopularTitle.sampletracks[0].map_url
                          );
                        }}
                        src="/images/round-pause-button.svg"
                      />
                    )}
                </div>
              )}
            </div>
          </div>
          {/* <div className="videoThumbTitleDiv">
            <span
              onClick={() => {
                this.productDetailsId(
                  podcastPopularTitle.id,
                  podcastPopularTitle.product_slug
                );
              }}
            >
              {podcastPopularTitle.custom_translation[
                this.state.selectedLanguage
              ] != undefined &&
                podcastPopularTitle.custom_translation[
                  this.state.selectedLanguage
                ].name}
            </span>
            <br />
            {podcastPopularTitle.authors.length > 0 && (
              <span className="briefTitle">
                {strings.authorText}:{" "}
                {this.authorLinkList(podcastPopularTitle.authors)}
              </span>
            )}
          </div> */}
          {/* <div className="videoThumbCateDiv">
            {podcastPopularTitle.category.map((categoryList, index) => {
              if (
                categoryList.custom_translation[this.state.selectedLanguage] !==
                undefined
              ) {
                return (
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      this.props.dispatch({
                        type: "MENU_SELECTED_CATEGORY",
                        payload: [
                          categoryList.name,
                          podcastPopularTitle.product_audio_type,
                          categoryList.id,
                        ],
                      });
                      this.setState({
                        innerActiveTab: "1",
                      });
                      this.props.dispatch(
                        browseCategoryProductDetailsDataAction(
                          podcastPopularTitle.product_audio_type,
                          categoryList.name,
                          1
                        )
                      );
                    }}
                    key={index}
                  >
                    <span key={index}>
                      {
                        categoryList.custom_translation[
                          this.state.selectedLanguage
                        ].name
                      }
                    </span>
                  </NavLink>
                );
              }
            })}
          </div> */}
          <UncontrolledPopover
            trigger="hover"
            placement="right"
            target={"Popover" + podcastPopularTitle.id}
          >
            <PopoverBody>
              <div className="threeDot">
                <div className="treeDotTop">
                  <h4>
                    <span
                      className="cursorPointer"
                      onClick={() => {
                        this.productDetailsId(
                          podcastPopularTitle.id,
                          podcastPopularTitle.product_slug
                        );
                      }}
                    >
                      {podcastPopularTitle.custom_translation[
                        this.state.selectedLanguage
                      ] != undefined &&
                        podcastPopularTitle.custom_translation[
                          this.state.selectedLanguage
                        ].name}
                    </span>
                  </h4>
                  <span className="cateTitle">
                    (As{" "}
                    {podcastPopularTitle.product_audio_type === "podcast" &&
                      strings.podcast}
                    )
                  </span>
                </div>
                {podcastPopularTitle.authors.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.authorText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {this.authorLinkList(podcastPopularTitle.authors)}
                    </div>
                  </div>
                )}
                {podcastPopularTitle.directors.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.directorText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes">
                        {this.directorsSelected(podcastPopularTitle.directors)}
                      </span>
                    </div>
                  </div>
                )}
                {podcastPopularTitle.artists.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.artistsText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes">
                        {this.artistsSelected(podcastPopularTitle.artists)}
                      </span>
                    </div>
                  </div>
                )}
                <div className="videoCnt browseGenre">
                  <div className="headingText">
                    <span className="author">{strings.genreText}</span>
                  </div>
                  <div className="colon-symbol">:</div>
                  <div className="loopdata">
                    {podcastPopularTitle.category.map((categoryList, index) => {
                      if (
                        categoryList.custom_translation[
                        this.state.selectedLanguage
                        ] !== undefined
                      ) {
                        return (
                          <NavLink
                            tag={Link}
                            onClick={() => {
                              this.props.dispatch({
                                type: "MENU_SELECTED_CATEGORY",
                                payload: [
                                  categoryList.name,
                                  podcastPopularTitle.product_audio_type,
                                  categoryList.id,
                                ],
                              });
                              this.setState({
                                innerActiveTab: "1",
                              });
                              this.props.dispatch(
                                browseCategoryProductDetailsDataAction(
                                  podcastPopularTitle.product_audio_type,
                                  categoryList.name,
                                  1
                                )
                              );
                            }}
                            key={index}
                          >
                            <span key={index}>
                              {
                                categoryList.custom_translation[
                                  this.state.selectedLanguage
                                ].name
                              }
                            </span>
                          </NavLink>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="videoCnt">
                  <div className="headingText">
                    <span className="author">{strings.durationText}</span>
                  </div>
                  <div className="colon-symbol">:</div>
                  <div className="loopdata">
                    <span className="authorDes brwHrs">
                      {getHoursFormat(podcastPopularTitle.total_tracks_length) +
                        " " +
                        strings.hrsText}
                    </span>
                  </div>
                </div>
                <div className="starRatingBrw">
                  <StarRatings
                    rating={podcastPopularTitle.total_reviews}
                    starRatedColor="#ff8c00"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                  />
                </div>
                {!podcastPopularTitle.is_free && (
                  <div className="videoPrice">
                    <span>
                      {this.state.isIndian !== null ?
                        <Fragment>
                          {this.state.isIndian ? strings.priceSign + "" + podcastPopularTitle.inr_price.amount
                            : "$" + podcastPopularTitle.price.amount}
                        </Fragment>
                        :
                        <Fragment>
                          {this.props.authData.userCountry === "IN"
                            ? strings.priceSign + "" + podcastPopularTitle.inr_price.amount
                            : "$" + podcastPopularTitle.price.amount}
                        </Fragment>
                      }
                    </span>
                  </div>
                )}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </Col>
      );
    });
  }
  podcastPopularAuthorsData() {
    return this.state.podcastPopularAuthors.map(
      (podcastAuthorPopular, index) => {
        var authorArray = [];
        podcastAuthorPopular.authors.map((authorName) => {
          if (
            authorName.custom_translation[this.state.selectedLanguage] !==
            undefined
          ) {
            authorArray.push(
              authorName.custom_translation[this.state.selectedLanguage].name
            );
          }
        });
        return (
          <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
            <div
              className="videoThumbDiv"
              id={"PopoverAuthor" + podcastAuthorPopular.id}
            >
              {podcastAuthorPopular.images.length > 0 ? (<Fragment>
                {podcastAuthorPopular.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={podcastAuthorPopular.images[0]["image2"]} alt="" />
              </Fragment>
              ) : (
                <Fragment>
                  {podcastAuthorPopular.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
                </Fragment>
              )}
              <div className="sliderTrack_Wrap">
                {podcastAuthorPopular.sampletracks.length > 0 && (
                  <div>
                    {podcastAuthorPopular.sampletracks[0].map_url !=
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePlayAudio(
                              podcastAuthorPopular.sampletracks[0].map_url
                            );
                          }}
                          src="/images/play.png"
                        />
                      )}
                    {podcastAuthorPopular.sampletracks[0].map_url ==
                      this.state.productTypeLastPlayed && (
                        <img
                          onClick={() => {
                            this.productTypePauseAudio(
                              podcastAuthorPopular.sampletracks[0].map_url
                            );
                          }}
                          src="/images/round-pause-button.svg"
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="videoThumbTitleDiv">
              <span
                onClick={() => {
                  this.productDetailsId(
                    podcastAuthorPopular.id,
                    podcastAuthorPopular.product_slug
                  );
                }}
              >
                {podcastAuthorPopular.custom_translation[
                  this.state.selectedLanguage
                ] != undefined &&
                  podcastAuthorPopular.custom_translation[
                    this.state.selectedLanguage
                  ].name}
              </span>
              <br />
              {podcastAuthorPopular.authors.length > 0 && (
                <span className="briefTitle">
                  {strings.authorText}:{" "}
                  {this.authorLinkList(podcastAuthorPopular.authors)}
                </span>
              )}
            </div> */}
            {/* <div className="videoThumbCateDiv">
              {podcastAuthorPopular.category.map((categoryList, index) => {
                if (
                  categoryList.custom_translation[
                    this.state.selectedLanguage
                  ] !== undefined
                ) {
                  return (
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_SELECTED_CATEGORY",
                          payload: [
                            categoryList.name,
                            podcastAuthorPopular.product_audio_type,
                            categoryList.id,
                          ],
                        });
                        this.setState({
                          innerActiveTab: "1",
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(
                            podcastAuthorPopular.product_audio_type,
                            categoryList.name,
                            1
                          )
                        );
                      }}
                      key={index}
                    >
                      <span key={index}>
                        {
                          categoryList.custom_translation[
                            this.state.selectedLanguage
                          ].name
                        }
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div> */}
            <UncontrolledPopover
              trigger="hover"
              placement="right"
              target={"PopoverAuthor" + podcastAuthorPopular.id}
            >
              <PopoverBody>
                <div className="threeDot">
                  <div className="treeDotTop">
                    <h4>
                      <span
                        className="cursorPointer"
                        onClick={() => {
                          this.productDetailsId(
                            podcastAuthorPopular.id,
                            podcastAuthorPopular.product_slug
                          );
                        }}
                      >
                        {podcastAuthorPopular.custom_translation[
                          this.state.selectedLanguage
                        ] != undefined &&
                          podcastAuthorPopular.custom_translation[
                            this.state.selectedLanguage
                          ].name}
                      </span>
                    </h4>
                    <span className="cateTitle">
                      (As{" "}
                      {podcastAuthorPopular.product_audio_type === "podcast" &&
                        strings.podcast}
                      )
                    </span>
                  </div>
                  {podcastAuthorPopular.authors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        {this.authorLinkList(podcastAuthorPopular.authors)}
                      </div>
                    </div>
                  )}
                  {podcastAuthorPopular.directors.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.directorsSelected(
                            podcastAuthorPopular.directors
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {podcastAuthorPopular.artists.length > 0 && (
                    <div className="videoCnt">
                      <div className="headingText">
                        <span className="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {this.artistsSelected(podcastAuthorPopular.artists)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="videoCnt browseGenre">
                    <div className="headingText">
                      <span className="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {podcastAuthorPopular.category.map(
                        (categoryList, index) => {
                          if (
                            categoryList.custom_translation[
                            this.state.selectedLanguage
                            ] !== undefined
                          ) {
                            return (
                              <NavLink
                                tag={Link}
                                onClick={() => {
                                  this.props.dispatch({
                                    type: "MENU_SELECTED_CATEGORY",
                                    payload: [
                                      categoryList.name,
                                      podcastAuthorPopular.product_audio_type,
                                      categoryList.id,
                                    ],
                                  });
                                  this.setState({
                                    innerActiveTab: "1",
                                  });
                                  this.props.dispatch(
                                    browseCategoryProductDetailsDataAction(
                                      podcastAuthorPopular.product_audio_type,
                                      categoryList.name,
                                      1
                                    )
                                  );
                                }}
                                key={index}
                              >
                                <span key={index}>
                                  {
                                    categoryList.custom_translation[
                                      this.state.selectedLanguage
                                    ].name
                                  }
                                </span>
                              </NavLink>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.durationText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes brwHrs">
                        {getHoursFormat(
                          podcastAuthorPopular.total_tracks_length
                        ) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                  <div className="starRatingBrw">
                    <StarRatings
                      rating={podcastAuthorPopular.total_reviews}
                      starRatedColor="#ff8c00"
                      numberOfStars={5}
                      name="rating"
                      starDimension="20px"
                    />
                  </div>
                  {!podcastAuthorPopular.is_free && (
                    <div className="videoPrice">
                      <span>
                        {this.state.isIndian !== null ?
                          <Fragment>
                            {this.state.isIndian ? strings.priceSign + "" + podcastAuthorPopular.inr_price.amount
                              : "$" + podcastAuthorPopular.price.amount}
                          </Fragment>
                          :
                          <Fragment>
                            {this.props.authData.userCountry === "IN"
                              ? strings.priceSign + "" + podcastAuthorPopular.inr_price.amount
                              : "$" + podcastAuthorPopular.price.amount}
                          </Fragment>
                        }
                      </span>
                    </div>
                  )}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
        );
      }
    );
  }
  podcastEditorPicksData() {
    return this.state.podcastEditorPicks.map((podcastEditorPicks, index) => {
      var authorArray = [];
      podcastEditorPicks.authors.map((authorName) => {
        if (
          authorName.custom_translation[this.state.selectedLanguage] !==
          undefined
        ) {
          authorArray.push(
            authorName.custom_translation[this.state.selectedLanguage].name
          );
        }
      });
      return (
        <Col xs="6" md="4" className="browse-space" lg="2" key={index}>
          <div
            className="videoThumbDiv"
            id={"PopoverEditor" + podcastEditorPicks.id}
          >
            {podcastEditorPicks.images.length > 0 ? (<Fragment>
              {podcastEditorPicks.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src={podcastEditorPicks.images[0]["image2"]} alt="" />
            </Fragment>
            ) : (<Fragment>
              {podcastEditorPicks.is_free && <span className="freeProduct">{strings.FreeText}</span>} <img src="/images/video_thumb1.jpg" alt="" />
            </Fragment>

            )}
            <div className="sliderTrack_Wrap">
              {podcastEditorPicks.sampletracks.length > 0 && (
                <div>
                  {podcastEditorPicks.sampletracks[0].map_url !=
                    this.state.productTypeLastPlayed && (
                      <img
                        onClick={() => {
                          this.productTypePlayAudio(
                            podcastEditorPicks.sampletracks[0].map_url
                          );
                        }}
                        src="/images/play.png"
                      />
                    )}
                  {podcastEditorPicks.sampletracks[0].map_url ==
                    this.state.productTypeLastPlayed && (
                      <img
                        onClick={() => {
                          this.productTypePauseAudio(
                            podcastEditorPicks.sampletracks[0].map_url
                          );
                        }}
                        src="/images/round-pause-button.svg"
                      />
                    )}
                </div>
              )}
            </div>
          </div>
          {/* <div className="videoThumbTitleDiv">
            <span
              onClick={() => {
                this.productDetailsId(
                  podcastEditorPicks.id,
                  podcastEditorPicks.product_slug
                );
              }}
            >
              {podcastEditorPicks.custom_translation[
                this.state.selectedLanguage
              ] != undefined &&
                podcastEditorPicks.custom_translation[
                  this.state.selectedLanguage
                ].name}
            </span>
            <br />
            {podcastEditorPicks.authors.length > 0 && (
              <span className="briefTitle">
                {strings.authorText}:{" "}
                {this.authorLinkList(podcastEditorPicks.authors)}
              </span>
            )}
          </div> */}
          {/* <div className="videoThumbCateDiv">
            {podcastEditorPicks.category.map((categoryList, index) => {
              if (
                categoryList.custom_translation[this.state.selectedLanguage] !==
                undefined
              ) {
                return (
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      this.props.dispatch({
                        type: "MENU_SELECTED_CATEGORY",
                        payload: [
                          categoryList.name,
                          podcastEditorPicks.product_audio_type,
                          categoryList.id,
                        ],
                      });
                      this.setState({
                        innerActiveTab: "1",
                      });
                      this.props.dispatch(
                        browseCategoryProductDetailsDataAction(
                          podcastEditorPicks.product_audio_type,
                          categoryList.name,
                          1
                        )
                      );
                    }}
                    key={index}
                  >
                    <span key={index}>
                      {
                        categoryList.custom_translation[
                          this.state.selectedLanguage
                        ].name
                      }
                    </span>
                  </NavLink>
                );
              }
            })}
          </div> */}
          <UncontrolledPopover
            trigger="hover"
            placement="right"
            target={"PopoverEditor" + podcastEditorPicks.id}
          >
            <PopoverBody>
              <div className="threeDot">
                <div className="treeDotTop">
                  <h4>
                    <span
                      className="cursorPointer"
                      onClick={() => {
                        this.productDetailsId(
                          podcastEditorPicks.id,
                          podcastEditorPicks.product_slug
                        );
                      }}
                    >
                      {podcastEditorPicks.custom_translation[
                        this.state.selectedLanguage
                      ] != undefined &&
                        podcastEditorPicks.custom_translation[
                          this.state.selectedLanguage
                        ].name}
                    </span>
                  </h4>
                  <span className="cateTitle">
                    (As{" "}
                    {podcastEditorPicks.product_audio_type === "podcast" &&
                      strings.podcast}
                    )
                  </span>
                </div>
                {podcastEditorPicks.authors.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.authorText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {this.authorLinkList(podcastEditorPicks.authors)}
                    </div>
                  </div>
                )}
                {podcastEditorPicks.directors.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.directorText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes">
                        {this.directorsSelected(podcastEditorPicks.directors)}
                      </span>
                    </div>
                  </div>
                )}
                {podcastEditorPicks.artists.length > 0 && (
                  <div className="videoCnt">
                    <div className="headingText">
                      <span className="author">{strings.artistsText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      <span className="authorDes">
                        {this.artistsSelected(podcastEditorPicks.artists)}
                      </span>
                    </div>
                  </div>
                )}
                <div className="videoCnt browseGenre">
                  <div className="headingText">
                    <span className="author">{strings.genreText}</span>
                  </div>
                  <div className="colon-symbol">:</div>
                  <div className="loopdata">
                    {podcastEditorPicks.category.map((categoryList, index) => {
                      if (
                        categoryList.custom_translation[
                        this.state.selectedLanguage
                        ] !== undefined
                      ) {
                        return (
                          <NavLink
                            tag={Link}
                            onClick={() => {
                              this.props.dispatch({
                                type: "MENU_SELECTED_CATEGORY",
                                payload: [
                                  categoryList.name,
                                  podcastEditorPicks.product_audio_type,
                                  categoryList.id,
                                ],
                              });
                              this.setState({
                                innerActiveTab: "1",
                              });
                              this.props.dispatch(
                                browseCategoryProductDetailsDataAction(
                                  podcastEditorPicks.product_audio_type,
                                  categoryList.name,
                                  1
                                )
                              );
                            }}
                            key={index}
                          >
                            <span key={index}>
                              {
                                categoryList.custom_translation[
                                  this.state.selectedLanguage
                                ].name
                              }
                            </span>
                          </NavLink>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="videoCnt">
                  <div className="headingText">
                    <span className="author">{strings.durationText}</span>
                  </div>
                  <div className="colon-symbol">:</div>
                  <div className="loopdata">
                    <span className="authorDes brwHrs">
                      {getHoursFormat(podcastEditorPicks.total_tracks_length) +
                        " " +
                        strings.hrsText}
                    </span>
                  </div>
                </div>
                <div className="starRatingBrw">
                  <StarRatings
                    rating={podcastEditorPicks.total_reviews}
                    starRatedColor="#ff8c00"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                  />
                </div>
                {!podcastEditorPicks.is_free && (
                  <div className="videoPrice">
                    <span>
                      {this.state.isIndian !== null ?
                        <Fragment>
                          {this.state.isIndian ? strings.priceSign + "" + podcastEditorPicks.inr_price.amount
                            : "$" + podcastEditorPicks.price.amount}
                        </Fragment>
                        :
                        <Fragment>
                          {this.props.authData.userCountry === "IN"
                            ? strings.priceSign + "" + podcastEditorPicks.inr_price.amount
                            : "$" + podcastEditorPicks.price.amount}
                        </Fragment>
                      }
                    </span>
                  </div>
                )}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </Col>
      );
    });
  }
  /******** End of podcast products *************/
  getSharingContent(productData) {
    var selectedLanguage = this.state.selectedLanguage;
    let productDetailsData = productData;
    var productDetail = (productDetailsData.custom_translation ? productDetailsData.custom_translation : {});
    var productAuthorDetails = productDetailsData.authors || [];
    var productName = productDetailsData.name;
    var Authors = '';
    if (Object.keys(productDetail).length > 0) {
      if (selectedLanguage == 'MR') {
        productName = productDetail.MR.name;
      } else {
        productName = productDetail.EN.name;
      }
    }
    var sharingContent = strings.socialSharingText + "\n" +
      productName + "\n" +
      strings.authorText + ": ";
    if (productDetailsData && productAuthorDetails) {
      productAuthorDetails.forEach((author, index) => {
        let autorDetails = (author.custom_translation ? author.custom_translation : {});
        var authorName = author.name;
        if (Object.keys(autorDetails).length > 0) {
          if (selectedLanguage == 'MR') {
            authorName = autorDetails.MR.name;
          } else {
            authorName = autorDetails.EN.name;
          }
        }
        Authors = Authors + authorName + (index < productAuthorDetails.length - 1 ? ', ' : '.');
      });
    }
    var happyListening = "\n" + strings.happyListening + "\n";
    return sharingContent + Authors + happyListening;
  }

  render() {
    return (
      <div>
        {this.props.loader.isLoader && (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        )}

        <div className="browseType_Wrapper">
          <div className="homeTabs">
            <div className="container mobileRemovePadding">
              <Nav tabs className="nav-pills">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1",
                    })}
                    onClick={() => {
                      this.browseProductsData("audiobook", 2);
                    }}
                  >
                    <div className="tabIcon_image">
                      {this.state.activeTab === "1" ? (
                        <img src="/images/Audiobook_Active.svg" />
                      ) : (
                        <img src="/images/Audiobook_Normal.svg" />
                      )}
                    </div>
                    <div className="tabRight_Content">{strings.audiobook} </div>
                  </NavLink>
                </NavItem>
                <NavItem className="snovelOriginal">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2",
                    })}
                    onClick={() => {
                      this.browseProductsData("snoveloriginal", 2);
                    }}
                  >
                    <div className="tabIcon_image">
                      {this.state.activeTab === "2" ? (
                        <img src="/images/ico_SnovelOriginals-Active.png" />
                      ) : (
                        <img src="/images/ico_SnovelOriginals-Normal.png" />
                      )}
                    </div>
                    <div className="tabRight_Content">
                      {strings.snoveloriginal}{" "}
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "3",
                    })}
                    onClick={() => {
                      this.browseProductsData("podcast", 2);
                    }}
                  >
                    <div className="tabIcon_image">
                      {this.state.activeTab === "3" ? (
                        <img src="/images/ico_Podcast-Active.png" />
                      ) : (
                        <img src="/images/ico_Podcast-Normal.png" />
                      )}
                    </div>
                    <div className="tabRight_Content">{strings.podcast} </div>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <hr />
            <div className="container">
              {this.state.isSelectedCategory === true ? (
                <div className="selectedTabTxtDiv" id="SelectedCategoryWrap">
                  <span className="selectedTabTxtBrd">
                    {this.state.isSelectedCategoryName}
                    {this.state.isSelectedCategory}{" "}
                    <i class="fa fa-close" onClick={this.enableTabs}></i>
                  </span>
                </div>
              ) : (
                ""
              )}
              <TabContent
                activeTab={this.state.activeTab}
                className="audioBook_Wrapper"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <Nav tabs className="innerProductsTab">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.innerActiveTab === "1",
                            })}
                            onClick={() => {
                              this.innerTabToggle("audiobook", "1");
                            }}
                          >
                            {strings.allText}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: this.state.innerActiveTab === "2" },
                              {
                                disableTab:
                                  this.state.isSelectedCategory == true,
                              }
                            )}
                            onClick={() => {
                              this.innerTabToggle("audiobook", "2");
                            }}
                          >
                            {strings.popularTitles}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: this.state.innerActiveTab === "3" },
                              {
                                disableTab:
                                  this.state.isSelectedCategory == true,
                              }
                            )}
                            onClick={() => {
                              this.innerTabToggle("audiobook", "3");
                            }}
                          >
                            {strings.popularAuthors}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: this.state.innerActiveTab === "4" },
                              {
                                disableTab:
                                  this.state.isSelectedCategory == true,
                              }
                            )}
                            onClick={() => {
                              this.innerTabToggle("audiobook", "4");
                            }}
                          >
                            {strings.EditorsPick}
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.innerActiveTab}>
                        <TabPane tabId="1">
                          <div>
                            <Row>{this.audiobookAllProductsData()}</Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <Row>{this.audiobookPopularTitlesData()}</Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div>
                            <Row>{this.audiobookPopularAuthorsData()}</Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="4">
                          <div>
                            <Row>{this.audiobookEditorPicksData()}</Row>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <Nav tabs className="innerProductsTab">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.innerActiveTab === "1",
                            })}
                            onClick={() => {
                              this.innerTabToggle("snoveloriginal", "1");
                            }}
                          >
                            {strings.allText}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: this.state.innerActiveTab === "2" },
                              {
                                disableTab:
                                  this.state.isSelectedCategory == true,
                              }
                            )}
                            onClick={() => {
                              this.innerTabToggle("snoveloriginal", "2");
                            }}
                          >
                            {strings.popularTitles}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: this.state.innerActiveTab === "3" },
                              {
                                disableTab:
                                  this.state.isSelectedCategory == true,
                              }
                            )}
                            onClick={() => {
                              this.innerTabToggle("snoveloriginal", "3");
                            }}
                          >
                            {strings.popularAuthors}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: this.state.innerActiveTab === "4" },
                              {
                                disableTab:
                                  this.state.isSelectedCategory == true,
                              }
                            )}
                            onClick={() => {
                              this.innerTabToggle("snoveloriginal", "4");
                            }}
                          >
                            {strings.EditorsPick}
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.innerActiveTab}>
                        <TabPane tabId="1">
                          <div>
                            <Row>{this.snovelOriginalAllProductsData()}</Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <Row>{this.snovelOriginalPopularTitlesData()}</Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div>
                            <Row>{this.snovelOriginalPopularAuthorsData()}</Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="4">
                          <div>
                            <Row>{this.snovelOriginalEditorPicksData()}</Row>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <Nav tabs className="innerProductsTab">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.innerActiveTab === "1",
                            })}
                            onClick={() => {
                              this.innerTabToggle("podcast", "1");
                            }}
                          >
                            {strings.allText}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: this.state.innerActiveTab === "2" },
                              {
                                disableTab:
                                  this.state.isSelectedCategory == true,
                              }
                            )}
                            onClick={() => {
                              this.innerTabToggle("podcast", "2");
                            }}
                          >
                            {strings.popularTitles}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: this.state.innerActiveTab === "3" },
                              {
                                disableTab:
                                  this.state.isSelectedCategory == true,
                              }
                            )}
                            onClick={() => {
                              this.innerTabToggle("podcast", "3");
                            }}
                          >
                            {strings.popularAuthors}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: this.state.innerActiveTab === "4" },
                              {
                                disableTab:
                                  this.state.isSelectedCategory == true,
                              }
                            )}
                            onClick={() => {
                              this.innerTabToggle("podcast", "4");
                            }}
                          >
                            {strings.EditorsPick}
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.innerActiveTab}>
                        <TabPane tabId="1">
                          <div>
                            <Row>{this.podcastAllProductsData()}</Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <Row>{this.podcastPopularTitlesData()}</Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div>
                            <Row>{this.podcastPopularAuthorsData()}</Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="4">
                          <div>
                            <Row>{this.podcastEditorPicksData()}</Row>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
        <div className="browseTopSliderWrp">
          <div className="container">
            <h3 className="topSnovelTitle">{strings.topSnovel}</h3>
            {this.state.topSnovelTrending.length > 0 ? (
              <OwlCarousel ref="car" options={options}>
                {this.topSnovelSliderData()}
              </OwlCarousel>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="browseLatest_Lauch">
          <div className="container">
            <hr />
            <h2>{strings.latestLaunch}</h2>
            <Row>
              <Col md="5" className="popularImage_Wrap">
                {this.state.latestLaunchApiData.length > 0 && (
                  <div>
                    <div className="popularImage_Wrapper">
                      {this.state.latestLaunchApiData[
                        this.state.selectedPopularIndex
                      ].images.length > 0 ? (
                        <img
                          src={
                            this.state.latestLaunchApiData[
                              this.state.selectedPopularIndex
                            ].images[0]["image3"]
                          }
                          alt="video"
                        />
                      ) : (
                        <img src="/images/default.jpg" />
                      )}
                    </div>
                    <div className="audioPlayer_Wrapper">
                      {this.state.latestLaunchApiData[
                        this.state.selectedPopularIndex
                      ].sampletracks.length > 0 &&
                        this.state.popularTitleSelectedAudio.length > 0 ? (
                        <AudioPlayer
                          rearrange={rearrangedPlayer}
                          audioFiles={this.state.popularTitleSelectedAudio}
                          playIcon={"/images/Play.png"}
                          playHoverIcon={"/images/Play.png"}
                          pauseIcon={"/images/Puse.png"}
                          pauseHoverIcon={"/images/Puse.png"}
                          forwardIcon={"/images/forword.png"}
                          forwardHoverIcon={"/images/forword.png"}
                          rewindIcon={"/images/rewind.png"}
                          rewindHoverIcon={"/images/rewind.png"}
                          volumeIcon={"/images/speaker.png"}
                          volumeEngagedIcon={"/images/speaker.png"}
                          muteEngagedIcon={"/images/speaker.png"}
                        />
                      ) : (
                        <div className="blankAudioPlayer">
                          <AudioPlayer
                            rearrange={rearrangedPlayer}
                            audioFiles={[""]}
                            playIcon={"/images/Play.png"}
                            playHoverIcon={"/images/Play.png"}
                            pauseIcon={"/images/Puse.png"}
                            pauseHoverIcon={"/images/Puse.png"}
                            forwardIcon={"/images/forword.png"}
                            forwardHoverIcon={"/images/forword.png"}
                            rewindIcon={"/images/rewind.png"}
                            rewindHoverIcon={"/images/rewind.png"}
                            volumeIcon={"/images/speaker.png"}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Col>
              <Col md="7">
                {this.state.latestLaunchApiData.length > 0 && (
                  <div class="videoDesc">
                    <h4>
                      {this.state.latestLaunchApiData[
                        this.state.selectedPopularIndex
                      ].custom_translation[this.state.selectedLanguage] !==
                        undefined &&
                        this.state.latestLaunchApiData[
                          this.state.selectedPopularIndex
                        ].custom_translation[this.state.selectedLanguage][
                        "name"
                        ]}
                    </h4>
                    <span class="productPostType">
                      {this.state.latestLaunchApiData[
                        this.state.selectedPopularIndex
                      ].product_audio_type === "audiobook" ? (
                        <span>{strings.audiobook}</span>
                      ) : this.state.latestLaunchApiData[
                        this.state.selectedPopularIndex
                      ].product_audio_type === "snoveloriginal" ? (
                        <span>{strings.snoveloriginal}</span>
                      ) : (
                        <span>{strings.podcast}</span>
                      )}
                    </span>
                    <div className="browse-product-details">
                      {this.state.latestLaunchApiData[
                        this.state.selectedPopularIndex
                      ].authors.length > 0 && (
                          <div className="videoCnt">
                            <div className="headingText">
                              <span className="author">{strings.authorText}</span>
                            </div>
                            <div className="colon-symbol">:</div>
                            <div className="loopdata">
                              {this.authorLinkList(
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].authors
                              )}
                            </div>
                          </div>
                        )}
                      {this.state.latestLaunchApiData[
                        this.state.selectedPopularIndex
                      ].directors.length > 0 && (
                          <div className="videoCnt">
                            <div className="headingText">
                              <span className="author">
                                {strings.directorText}
                              </span>
                            </div>
                            <div className="colon-symbol">:</div>
                            <div className="loopdata">
                              <span className="authorDes">
                                {this.directorsSelected(
                                  this.state.latestLaunchApiData[
                                    this.state.selectedPopularIndex
                                  ].directors
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      {this.state.latestLaunchApiData[
                        this.state.selectedPopularIndex
                      ].artists.length > 0 && (
                          <div className="videoCnt">
                            <div className="headingText">
                              <span className="author">
                                {strings.artistsText}
                              </span>
                            </div>
                            <div className="colon-symbol">:</div>
                            <div className="loopdata">
                              <span className="authorDes">
                                {this.artistsSelected(
                                  this.state.latestLaunchApiData[
                                    this.state.selectedPopularIndex
                                  ].artists
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      <div className="videoCnt">
                        <div className="headingText">
                          <span className="author">{strings.genreText}</span>
                        </div>
                        <div className="colon-symbol">:</div>
                        <div className="loopdata">
                          {this.state.latestLaunchApiData[
                            this.state.selectedPopularIndex
                          ].category.map((categoryList, index) => {
                            if (
                              categoryList.custom_translation[
                              this.state.selectedLanguage
                              ] != undefined
                            ) {
                              return (
                                <NavLink
                                  tag={Link}
                                  to={{
                                    pathname: "/browse",
                                  }}
                                  onClick={() => {
                                    this.props.dispatch({
                                      type: "MENU_SELECTED_CATEGORY",
                                      payload: [
                                        categoryList.name,
                                        this.state.latestLaunchApiData[
                                          this.state.selectedPopularIndex
                                        ].product_audio_type,
                                        categoryList.id,
                                      ],
                                    });
                                    this.setState({
                                      innerActiveTab: "1",
                                    });
                                    this.props.dispatch(
                                      browseCategoryProductDetailsDataAction(
                                        this.state.latestLaunchApiData[
                                          this.state.selectedPopularIndex
                                        ].product_audio_type,
                                        categoryList.name,
                                        1
                                      )
                                    );
                                  }}
                                  key={index}
                                  className="genreLinkText"
                                >
                                  <span key={index}>
                                    {
                                      categoryList.custom_translation[
                                        this.state.selectedLanguage
                                      ].name
                                    }
                                  </span>
                                </NavLink>
                              );
                            }
                          })}
                        </div>
                      </div>
                      <div className="videoCnt">
                        <div className="headingText">
                          <span className="author">{strings.durationText}</span>
                        </div>
                        <div className="colon-symbol">:</div>
                        <div className="loopdata">
                          <span className="authorDes">
                            {getHoursFormat(
                              this.state.latestLaunchApiData[
                                this.state.selectedPopularIndex
                              ].total_tracks_length
                            ) +
                              " " +
                              strings.hrsText}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="readMoreVideo">
                      <span
                        className="readMoreProduct"
                        onClick={() => {
                          this.productDetailsId(
                            this.state.latestLaunchApiData[
                              this.state.selectedPopularIndex
                            ].id,
                            this.state.latestLaunchApiData[
                              this.state.selectedPopularIndex
                            ].product_slug
                          );
                        }}
                      >
                        {strings.readMoreText}
                      </span>
                    </div>
                    <div className="shareDiv">
                      <div className="starRating">
                        <StarRatings
                          rating={
                            this.state.latestLaunchApiData[
                              this.state.selectedPopularIndex
                            ].total_reviews
                          }
                          starRatedColor="#ff8c00"
                          numberOfStars={5}
                          name="rating"
                          starDimension="20px"
                        />
                      </div>
                      <div className="shareIconDiv">
                        <span className="shareIcon">
                          <img
                            src="images/share_icon.png"
                            alt=""
                            onClick={this.popOverToggle}
                          />
                        </span>
                        <div
                          className="popupShareButton"
                          style={{
                            display: this.state.popoverOpenShare
                              ? "block"
                              : "none",
                          }}
                        >
                          <div className="popupShareButton_inline">
                            <FacebookShareButton
                              url={front_base_url +
                                "/product-details/" +
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].id +
                                "/" +
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].name
                              }
                              quote={
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].name
                              }
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                              url={
                                this.getSharingContent(this.state.latestLaunchApiData[this.state.selectedPopularIndex]) + front_base_url +
                                "/product-details/" +
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].id +
                                "/" +
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].name
                              }
                              quote={
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].name
                              }
                            >
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <WhatsappShareButton
                              url={
                                this.getSharingContent(this.state.latestLaunchApiData[this.state.selectedPopularIndex]) + front_base_url +
                                "/product-details/" +
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].id +
                                "/" +
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].name
                              }
                              quote={
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].name
                              }
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <PinterestShareButton
                              url={
                                this.getSharingContent(this.state.latestLaunchApiData[this.state.selectedPopularIndex]) + front_base_url +
                                "/product-details/" +
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].id +
                                "/" +
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].name
                              }
                              media={`${String(
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].images.length > 0 &&
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].images[0]["image3"]
                              )}`}
                            >
                              <PinterestIcon size={32} round />
                            </PinterestShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!this.state.latestLaunchApiData[
                      this.state.selectedPopularIndex
                    ].is_free ? (
                      <div className="videoPrice">
                        {this.state.isIndian !== null ?
                          <Fragment>
                            {this.state.isIndian ? strings.priceSign + "" + this.state.latestLaunchApiData[this.state.selectedPopularIndex].inr_price.amount
                              : "$" + this.state.latestLaunchApiData[this.state.selectedPopularIndex].price.amount}
                          </Fragment>
                          :
                          <Fragment>
                            {this.props.authData.userCountry === "IN"
                              ? strings.priceSign + "" + this.state.latestLaunchApiData[this.state.selectedPopularIndex].inr_price.amount
                              : "$" + this.state.latestLaunchApiData[this.state.selectedPopularIndex].price.amount}
                          </Fragment>
                        }
                      </div>
                    ) : (
                      <div className="videoPrice"></div>
                    )}
                    <div className="videoPurchase">
                      {this.props.authData.subscribeStatus === false ||
                        this.props.authData.subscribeStatus == "" ? (
                        <div>
                          <span className="subsCribeButton">
                            <a href="/subscribe">{strings.subscribe}</a>
                          </span>
                          {this.state.latestLaunchApiData[
                            this.state.selectedPopularIndex
                          ].is_purchased ||
                            this.state.latestLaunchApiData[
                              this.state.selectedPopularIndex
                            ].is_free ? (
                            <span className="purchaseButton">
                              <a href="/library">{strings.goToLibrary}</a>
                            </span>
                          ) : (
                            <span>
                              {this.state.latestLaunchApiData[
                                this.state.selectedPopularIndex
                              ].inr_price.amount > 0 && (
                                  <span className="purchaseButton">
                                    <a
                                      href="javascript:;"
                                      onClick={() => {
                                        this.cartProductsModal(
                                          this.state.latestLaunchApiData[
                                            this.state.selectedPopularIndex
                                          ].variant
                                        );
                                      }}
                                    >
                                      {strings.purchaseTitle}
                                    </a>
                                  </span>
                                )}
                            </span>
                          )}
                        </div>
                      ) : (
                        <span className="purchaseButton">
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.addToLibraryProduct(
                                this.state.latestLaunchApiData[
                                  this.state.selectedPopularIndex
                                ].id
                              );
                            }}
                          >
                            {strings.addToLibrary}
                          </a>
                        </span>
                      )}
                      {this.state.cartFailureMessage.length > 0 && (
                        <div className="loginErrorMsg">
                          {this.state.cartFailureMessage}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Col>
              <Col md="12" className="popularProduct_Slider">
                {this.state.latestLaunchApiData.length > 0 ? (
                  <OwlCarousel ref="car" options={optionss}>
                    {this.latestLaunchSliderContent()}
                  </OwlCarousel>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <hr />
          </div>
        </div>

        <div className="browseReady_Begin">
          <div class="container">
            <Row>
              <div class="readyBrw">
                <h2>{strings.readyToBegin}</h2>
                <p>{strings.readyToBeginDesc}</p>
                <div class="track-btn">
                  <span class="subsCribeButton">
                    <a href="/subscribe">{strings.subscribeNow}</a>
                  </span>
                  <span class="purchaseButton">
                    <a href="/try-free-snovel">
                      {strings.tryFreeSnovel}
                    </a>
                  </span>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    trendingProductsDetails: state.trendingProducts,
    selectedPostAndCategory: state.selectedPostAndCategory,
    browseCategoryProductDetailsData:
      state.browseCategoryProductDetailsData.browseCategoryProducts,
    loader: state.loader,
    authData: state.authonticationDetails,
    isIndian: state.isReisteredDevice.isIndian,
  };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(browseSnovel));
