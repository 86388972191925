export default function reducer(
  state = {
    tab: 'home'
  },
  action
) {
  switch (action.type) {
    case 'TAB_CHANGED': {
      return { ...state, tab: action.payload };
    }
    case 'TAB_RESET': {
      return { ...state, tab: 'home' };
    }
    default: {
      return { ...state };
    }
  }
}
