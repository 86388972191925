import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import { connect } from 'react-redux';
import HomeSlider from '../homeSlider.json';
import {
    CustomLeftArrow,
    CustomRightArrow
} from "./CustomArrows";
import { NavLink } from 'reactstrap';
import swal from 'sweetalert';
import OwlCarousel from 'react-owl-carousel2';
import { Route, Link, Redirect, withRouter } from 'react-router-dom';
import { api_base_url, dashboardToken } from '../config';
import axios from 'axios';
import AudioPlayer from 'react-modular-audio-player';
import { homeTrendingProductDetailsData } from '../action/trendingProductAction';
import { browseCategoryProductDetailsDataAction } from '../action/browseCategoryAction';
import LocalizedStrings from 'react-localization';
import languages from '../language/language.json';
import { validate } from '@babel/types';
var strings = new LocalizedStrings(languages);

const trending = {
    nav: true,
    autoplay: false,
    margin: 15,
    navText: ["<div></div>", "<div></div>"],
    responsive: {
        0: {
            items: 2
        },
        600: {
            items: 3
        },
        1000: {
            items: 6
        }
    }
};
const editor = {
    nav: true,
    loop: true,
    rewind: true,
    autoplay: false,
    margin: 15,
    navText: ["<div></div>", "<div></div>"],
    responsive: {
        0: {
            items: 2
        },
        600: {
            items: 3
        },
        1000: {
            items: 6
        }
    }
};

class innerProductSlider extends Component {
    constructor(props) {
        super(props);
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
        }

        this.state = {
            currentSlide: '',
            selectedLanguage: selectedLanguage || 'EN',
            audioObject: undefined,
            selectedIndex: '',
            editorChoiceAudio: {},
            trendingAudio: {},
            editorChoiceLastPlayed: '',
            trendingLastPlayed: '',
            trendingApiProducts: [],
            trendingApiProductsReducer: [],
            editorChoiceApiProducts: [],
        }
    }
    productDetailsId(productID, slug) {
        localStorage.setItem('productId', productID);
        this.props.history.push('/product-details/' + productID + '/' + slug);
    }

    /*****   Author Specific details link ***************/
    authorDetailsId(authorID, authorName) {
        var titleUrl = authorName.toLowerCase();
        var authorTitle = titleUrl.replace('.', '');
        var authorName = authorTitle.replace(' ', '-');
        this.props.history.push('/author/' + authorID + '/' + authorName);
    }
    authorLinkList(authorList) {
        return authorList.map((authorName, index) => {
            if (authorName.custom_translation[this.state.selectedLanguage] != undefined) {
                return (<NavLink tag={Link} className="pl-0"
                    onClick={() => { this.authorDetailsId(authorName.id, authorName.name) }} key={index}>
                    <span key={index}>{authorName.custom_translation[this.state.selectedLanguage].name}</span>
                </NavLink>);
            }
        });
    }
    componentWillMount() {
        this.props.dispatch(homeTrendingProductDetailsData());
    }
    componentWillUnmount() {
        if (this.state.audioObject) {
            this.state.audioObject.pause();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.isPauseInnerProduct === true && this.state.audioObject) {
            this.state.audioObject.pause();
            this.setState({
                editorChoiceLastPlayed: '',
                trendingLastPlayed: ''
            });
            this.props.onPauseSample('innerProduct');
        }
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
            this.setState({
                selectedLanguage: selectedLanguage
            });
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
            this.setState({
                selectedLanguage: "EN"
            });
        }
    }

    trendingPauseAudio(map_url) {
        this.state.audioObject.pause();
        this.setState({
            trendingLastPlayed: ''
        });
    }

    componentDidMount() {
        // this.trendingProductsData();
        this.editorChoicesProductsData();
    }

    trendingProductsData() {
        var headers = {
            'Content-Type': 'application/json',
            'index-api-token': dashboardToken
        }
        axios.get(api_base_url + '/api/v2/index-trending-this-week/10', { headers: headers })
            .then(response => {
                if (response.data.status == true) {
                    this.setState({
                        trendingApiProducts: response.data.trending_picks || []
                    })
                } else {
                    //   console.log("Not Loaded");
                }
            })
            .catch(err => {
                // console.log('fetched.', err)
            });
    }

    trendingPlayAudio(map_url) {
        if (this.state.trendingAudio[map_url]) {
            this.setState({
                trendingLastPlayed: map_url,
                editorChoiceLastPlayed: ''
            });
            this.props.onPlaySample('innerProduct');
            var playPromise = this.state.audioObject.play();
            if (playPromise !== undefined) {
                playPromise.then(function () { }).catch(function (error) {
                    const isFirefox = typeof InstallTrigger !== 'undefined';
                    if (isFirefox) {
                        swal("", "Something went wrong with your browser. Please try another one.", "error");
                    }
                });
            }
        } else {
            if (this.state.audioObject) {
                this.state.audioObject.pause();
            }
            var apiParams = {
                map_url: map_url
            };
            var headers = {
                'Content-Type': 'application/json',
                'index-api-token': dashboardToken
            };
            axios.post(api_base_url + '/api/get-sample-signed-url/', apiParams, { headers: headers }).then(response => {
                if (response.data) {
                    var url = response.data.data.signed_url || "";
                    var audio = new Audio(url);
                    var parentThis = this;
                    audio.addEventListener("ended", function () {
                        parentThis.setState({
                            trendingLastPlayed: ''
                        });
                    });
                    audio.type = 'audio/mpeg';
                    var newTrendingAudio = {};
                    newTrendingAudio[map_url] = url;
                    this.setState({
                        audioObject: audio,
                        trendingAudio: newTrendingAudio,
                        trendingLastPlayed: map_url,
                        editorChoiceLastPlayed: ''
                    });
                    this.props.onPlaySample('innerProduct');
                    var playPromise = audio.play();
                    if (playPromise !== undefined) {
                        playPromise.then(function () { }).catch(function (error) {
                            const isFirefox = typeof InstallTrigger !== 'undefined';
                            if (isFirefox) {
                                swal("", "Something went wrong with your browser. Please try another one.", "error");
                            }
                        });
                    }
                }
            }).catch(err => {
                // console.log('Error - ', err);
            });
        }
    }
    /******** Trending slider content **********/
    trendingSliderContent() {
        return this.state.trendingApiProducts.map((sliderElement, slideIndex) => {
            var authorArray = [];
            sliderElement.authors.map(authorName => {
                if (authorName.custom_translation[this.state.selectedLanguage] !== undefined) {
                    authorArray.push(authorName.custom_translation[this.state.selectedLanguage].name);
                }
            })
            return (
                <div>
                    {sliderElement.images.length > 0 ? <div className="sliderThumb">{sliderElement.is_free && <span className="freeProduct">{strings.FreeText}</span>}<img src={sliderElement.images[0]["image2"]} className="sampleProduct_thumb" onClick={() => { this.productDetailsId(sliderElement.id, sliderElement.product_slug) }} /></div> : <div className="sliderThumb">{sliderElement.is_free && <span className="freeProduct">{strings.FreeText}</span>}<img src="/images/default.jpg" className="sampleProduct_thumb" onClick={() => { this.productDetailsId(sliderElement.id, sliderElement.product_slug) }} /></div>}
                    {/* {sliderElement.custom_translation[this.state.selectedLanguage] !== undefined && <div className="sliderProductTitle"><span onClick={() => {this.productDetailsId(sliderElement.id, sliderElement.product_slug)}}>{sliderElement.custom_translation[this.state.selectedLanguage].name}</span></div>}
                       {sliderElement.authors.length>0 && <div className="sliderProductAuthor">
                           
                           {this.authorLinkList(sliderElement.authors)}</div>}
                       <div className="sliderProductCategory">
                        {sliderElement.category.map((categoryList, index) => {
                            if(categoryList.custom_translation[this.state.selectedLanguage] !== undefined){
                            return <NavLink tag={Link} to={{
                                pathname: '/browse'
                              }} onClick={() => {
                                this.props.dispatch({
                                 type: 'MENU_SELECTED_CATEGORY',
                                 payload: [categoryList.name, sliderElement.product_audio_type, categoryList.id]
                               });
                               this.props.dispatch(browseCategoryProductDetailsDataAction(sliderElement.product_audio_type, categoryList.name, 1));
                               }} key={index}><span key={index}>{categoryList.custom_translation[this.state.selectedLanguage].name}</span></NavLink>
                            }  
                            })}
                        </div> */}
                    <div className="sliderTrack_Wrap">
                        {sliderElement.sampletracks.length > 0 &&
                            <div>
                                {sliderElement.sampletracks[0].map_url != this.state.trendingLastPlayed &&
                                    <img onClick={() => { this.trendingPlayAudio(sliderElement.sampletracks[0].map_url); }} src="/images/play.png" />
                                }
                                {sliderElement.sampletracks[0].map_url == this.state.trendingLastPlayed &&
                                    <img onClick={() => { this.trendingPauseAudio(sliderElement.sampletracks[0].map_url); }} src="/images/round-pause-button.svg" />
                                }
                            </div>
                        }
                    </div>
                </div>
            );
        })

    }

    editorChoicePauseAudio(map_url) {
        this.state.audioObject.pause();
        this.setState({
            editorChoiceLastPlayed: ''
        });
    }

    editorChoicePlayAudio(map_url) {
        if (this.state.editorChoiceAudio[map_url]) {
            this.setState({
                editorChoiceLastPlayed: map_url,
                trendingLastPlayed: ''
            });
            this.props.onPlaySample('innerProduct');
            var playPromise = this.state.audioObject.play();
            if (playPromise !== undefined) {
                playPromise.then(function () { }).catch(function (error) {
                    const isFirefox = typeof InstallTrigger !== 'undefined';
                    if (isFirefox) {
                        swal("", "Something went wrong with your browser. Please try another one.", "error");
                    }
                });
            }
        } else {
            if (this.state.audioObject) {
                this.state.audioObject.pause();
            }
            var apiParams = {
                map_url: map_url
            };
            var headers = {
                'Content-Type': 'application/json',
                'index-api-token': dashboardToken
            };
            axios.post(api_base_url + '/api/get-sample-signed-url/', apiParams, { headers: headers }).then(response => {
                if (response.data) {
                    var url = response.data.data.signed_url || "";
                    var audio = new Audio(url);
                    var parentThis = this;
                    audio.addEventListener("ended", function () {
                        parentThis.setState({
                            editorChoiceLastPlayed: ''
                        });
                    });
                    audio.type = 'audio/mpeg';
                    var newEditorChoiceAudio = {};
                    newEditorChoiceAudio[map_url] = url;
                    this.setState({
                        audioObject: audio,
                        editorChoiceAudio: newEditorChoiceAudio,
                        editorChoiceLastPlayed: map_url,
                        trendingLastPlayed: ''
                    });
                    this.props.onPlaySample('innerProduct');
                    var playPromise = audio.play();
                    if (playPromise !== undefined) {
                        playPromise.then(function () { }).catch(function (error) {
                            const isFirefox = typeof InstallTrigger !== 'undefined';
                            if (isFirefox) {
                                swal("", "Something went wrong with your browser. Please try another one.", "error");
                            }
                        });
                    }
                }
            }).catch(err => {
                // console.log('Error - ', err);
            });
        }
    }

    editorChoicesProductsData() {
        var headers = {
            'Content-Type': 'application/json',
            'index-api-token': dashboardToken
        }
        axios.get(api_base_url + '/api/v2/index-editors-pick/10', { headers: headers })
            .then(response => {
                if (response.data.status == true) {
                    this.setState({
                        editorChoiceApiProducts: response.data.editor_choice || []
                    })
                } else {
                    //   console.log("Not Loaded");
                }
            })
            .catch(err => {
                // console.log('fetched.', err);
            });
    }
    /******** Editor Pick slider content **********/
    editorChoicesSliderContent() {
        return this.state.editorChoiceApiProducts.map((sliderElement, slideIndex) => {
            return (
                <div>
                    {sliderElement.images.length > 0 ? <div className="sliderThumb">{sliderElement.is_free && <span className="freeProduct">{strings.FreeText}</span>}<img src={sliderElement.images[0]["image2"]} className="sampleProduct_thumb" onClick={() => { this.productDetailsId(sliderElement.id, sliderElement.product_slug) }} /></div> : <div className="sliderThumb">{sliderElement.is_free && <span className="freeProduct">{strings.FreeText}</span>}<img src="/images/default.jpg" className="sampleProduct_thumb" onClick={() => { this.productDetailsId(sliderElement.id, sliderElement.product_slug) }} /></div>}
                    {/* {sliderElement.custom_translation[this.state.selectedLanguage] !== undefined && <div className="sliderProductTitle"><span onClick={() => {this.productDetailsId(sliderElement.id, sliderElement.product_slug)}}>{sliderElement.custom_translation[this.state.selectedLanguage].name}</span></div>}
                       {sliderElement.authors.length>0 && <div className="sliderProductAuthor">
                          
                           {this.authorLinkList(sliderElement.authors)}</div>}
                       <div className="sliderProductCategory">
                        {sliderElement.category.map((categoryList, index) => {
                            if(categoryList.custom_translation[this.state.selectedLanguage] !== undefined){
                                return <NavLink tag={Link} to={{
                                    pathname: '/browse'
                                  }} onClick={() => {
                                    this.props.dispatch({
                                     type: 'MENU_SELECTED_CATEGORY',
                                     payload: [categoryList.name, sliderElement.product_audio_type, categoryList.id]
                                   });
                                   this.props.dispatch(browseCategoryProductDetailsDataAction(sliderElement.product_audio_type, categoryList.name, 1));
                                   }} key={index}><span key={index}>{categoryList.custom_translation[this.state.selectedLanguage].name}</span></NavLink>
                            }
                                
                            })}
                        </div> */}

                    <div className="sliderTrack_Wrap">
                        {sliderElement.sampletracks.length > 0 &&
                            <div>
                                {sliderElement.sampletracks[0].map_url != this.state.editorChoiceLastPlayed &&
                                    <img onClick={() => { this.editorChoicePlayAudio(sliderElement.sampletracks[0].map_url); }} src="/images/play.png" />
                                }
                                {sliderElement.sampletracks[0].map_url == this.state.editorChoiceLastPlayed &&
                                    <img onClick={() => { this.editorChoicePauseAudio(sliderElement.sampletracks[0].map_url); }} src="/images/round-pause-button.svg" />
                                }
                            </div>
                        }
                    </div>

                </div>
            );
        })
    }
    getCategoryPage() {
        var post_type = 'family'
        var customTranslation = 'editors_pick'
        this.props.dispatch({
          type: "MENU_SELECTED_CATEGORY",
          payload: ['editors_pick', 'family', 1],
        });
        this.props.dispatch(
          browseCategoryProductDetailsDataAction(null, 'editors_pick', 1)
        );
        localStorage.setItem('post_type', post_type);
        localStorage.setItem("categoryName", JSON.stringify(customTranslation));
        this.props.history.push("/family-category-details/" + 'editors_pick');
    }

    render() {
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 5
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 4
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2
            }
        }
        return (
            <div>
                <div class="container">
                    <div className="innerSlider_Wrapper">
                        {this.state.editorChoiceApiProducts.length > 0 &&

                            <div className="newSliderContentWrapper kidsCategorySliders">
                                <div className="kidsCategoryMain-wrapper">
                                    <h4 className={"float-left custom-category-title"}>
                                        {strings.editorPicks}
                                    </h4>
                                    {this.state.editorChoiceApiProducts.length > 5 ?
                                        <button
                                            className={"category-view-all-button"}
                                            onClick={() => {
                                                this.getCategoryPage();
                                            }}
                                        >
                                            {strings.viewAll}
                                        </button>
                                        :
                                        ""
                                    }
                                    <OwlCarousel ref="car" options={editor} >{this.editorChoicesSliderContent()}</OwlCarousel>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }
}

function mapStatesToProps(state) {
    return {
        homeMainCategoryList: state.homeMainCategoryList.homePageAllData,
        homePageLatestProducts: state.homeMainCategoryList.homePageLatestProducts,
        languageTab: state.languageTab,
        trendingProductsDetails: state.trendingProducts
    };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(innerProductSlider));