import React, { Component } from "react";
import { userSubscribeProfileDetails } from "../../action/userProfileAction";
import SimpleCrypto from "simple-crypto-js";
import { encryptDecryptKey } from "../../config";
import { connect } from "react-redux";
import {
  Row,
  Col,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
} from "reactstrap";
import {
  api_base_url
} from "../../config";
import { Route, Link, withRouter} from "react-router-dom";
import axios from "axios";
import { userAuthentication } from "../../action/loginAction";
import MyProfileModal from "../modals/myProfile";
import AlertNotificationModal from "../modals/alertNotification";
import LocalizedStrings from "react-localization";
import languages from "../../language/language.json";
var strings = new LocalizedStrings(languages);

class userProfileSubscribe extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""

      ) {
        token = userDetailsObject.token || "";
        this.getUserSubscriptionDetails(userDetailsObject.token);
      }
    }
    console.log(this.props.openProfileEdit)
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }

    this.state = {
      token: token || "",
      userShortName: "",
      subscription: [],
      monthShortName: {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sept",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      },
      myProfileModal: false,
      notificationModal: false,
      selectedLanguage: selectedLanguage || "EN",
      notificationCount: 0,
      subscriptionlist: [],
      profileCompleted: true,
    };
    this.myProfileToggle = this.myProfileToggle.bind(this);
    this.notificationToggle = this.notificationToggle.bind(this);
    this.refreshUserDetails = this.refreshUserDetails.bind(this);
  }
  componentWillMount() {
    this.props.dispatch(userSubscribeProfileDetails(this.state.token));
  }

  refreshUserDetails(
    newEmail,
    newSubscribeStatus,
    newFirstName,
    newLastName,
    newProfileImage,
    newCountry,
    newState,
    newMobile,
    instituteName
  ) {
    this.props.dispatch(
      userAuthentication(
        newEmail,
        this.state.token,
        newSubscribeStatus,
        newFirstName,
        newLastName,
        newProfileImage,
        newCountry,
        newState,
        newMobile,
        instituteName
      )
    );
    if (newFirstName == "" && newLastName == "") {
      this.setState({
        shortName: "UN",
      });
    } else {
      var newShortName = "";
      if (newFirstName != "") {
        var firstNameLetter = newFirstName.charAt(0);
        newShortName = firstNameLetter.toUpperCase();
      }
      if (newLastName != "") {
        var lastNameLetter = newLastName.charAt(0);
        newShortName = newShortName + lastNameLetter.toUpperCase();
      }
      this.setState({
        userShortName: newShortName,
      });
    }
  }

  numberSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  subscriptionDateString(dateString) {
    var dateStringArray = dateString.split("-");
    var newYear = dateStringArray[0];
    var newMonth = parseInt(dateStringArray[1]) + "";
    var newDay = parseInt(dateStringArray[2]);
    var newString =
      this.numberSuffix(newDay) +
      " " +
      this.state.monthShortName[newMonth] +
      ", " +
      newYear;
    return newString;
  }

  componentWillReceiveProps(nextProps) {
    // if(this.props.openProfileEdit == true){
     
    //   this.myProfileToggle();
     
    // } 
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
    this.setState({
      notificationCount: nextProps.notificationCount,
    });
    if (nextProps.userShortName !== "" && nextProps.userShortName.length > 0) {
      this.setState({
        userShortName: nextProps.userShortName,
      });
    }

    if (
      nextProps.userSubscriptionReducer !== "" &&
      nextProps.userSubscriptionReducer.length > 0
    ) {
      this.setState({
        subscription: nextProps.userSubscriptionReducer,
      });
    }
  }
  myProfileToggle() {
    this.setState({
      myProfileModal: !this.state.myProfileModal,
    });
  }
  notificationToggle() {
    this.setState({
      notificationModal: !this.state.notificationModal,
    });
    setTimeout(() => {
      this.setState({ notificationCount: 0 });
    }, 1000);
  }



  getUserSubscriptionDetails(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/v1/order-new/multiple-subscription/", {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status) {
          var my_subscriptionData = response.data.data || [];
          this.setState({
            subscriptionlist: my_subscriptionData,
          });
          if (my_subscriptionData.length == 0) {
            this.getSubscribePackages();
          }
        }

        this.getMyBasketDetails(token);
        this.getMyLibraryDetails(token, true);
        this.getMySnovelDetails(this.state.token);
        this.getNotificationList(token);
      })
      .catch((err) => {
        // console.log("error ", err);

      });
  }



  render() {
    var isSubscriptionActive = false;
    return (
      <div>
        <div className="container">
          <div className="billingTopWrp">
            <Row className="left-right-margin-none">
              <Col md="9" lg="9">
                {this.props.authData.userProfile ? (
                  <div className="billProfileLFtLogo">
                    <img
                      src={this.props.authData.userProfile}
                      alt="Profile Image"
                    />{" "}
                  </div>
                ) : (
                  <div className="billProfileLFtLogo">
                    <img
                      src={process.env.PUBLIC_URL + "/images/user_circle.png"}
                      alt="Profile Image"
                    />
                  </div>
                )}
                {this.state.subscriptionlist.length > 0 && this.state.subscriptionlist.map((subscription, index) => {
                  if (index === 0 || index === 1 && subscription.is_current === true) {
                    isSubscriptionActive = true;
                    return (
                      <div className="planNameDiv">
                        {/* {console.log("subscription", this.state.subscriptionlist)} */}
                        <p>{strings.Planname}:</p>
                        <h1>
                          {isSubscriptionActive ?
                            subscription.subscription_type :
                            (
                              <span class="subscription-expired">
                                {" "}
                                ({strings.subscriptionExpired})
                              </span>
                            )}
                        </h1>
                        <p>
                          {strings.startDate}:{" "}
                          <span>
                            {this.subscriptionDateString(
                              subscription.start_date
                            )}
                          </span>{" "}
                          | {strings.endDate}:{" "}
                          <span>
                            {this.subscriptionDateString(
                              subscription.end_date
                            )}
                          </span>
                        </p>
                      </div>
                    )
                  }
                })}
                {this.state.subscription.length == 0 && (
                  <div className="planNameDiv">
                    <p className="no-subscription">
                      {strings.Subscriptionnotactivated}.
                    </p>
                  </div>
                )}
              </Col>
              <Col md="3" lg="3">
                <div className="billingProfile">
                  <a href="javascript:;" onClick={this.myProfileToggle}>
                    {strings.MyProfile}
                  </a>
                  <div
                    onClick={this.notificationToggle}
                    className="billingBell"
                    data-toggle="modal"
                    data-target="#myalertPop"
                  >
                    <img src="images/bellbilling_icon.png" alt="" />
                    {this.state.notificationCount > 0 && (
                      <span>{this.state.notificationCount}</span>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          isOpen={this.state.myProfileModal}
          toggle={this.myProfileToggle}
          size={"lg"}
          className={"common-modal"}
        >
          <ModalHeader toggle={this.myProfileToggle}></ModalHeader>
          <ModalBody>
            <MyProfileModal
              selectedLanguage={this.state.selectedLanguage}
              closeModal={this.myProfileToggle}
              refreshData={this.refreshUserDetails}
              profileStatus={this.state.profileCompleted}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.notificationModal}
          toggle={this.notificationToggle}
          size={"lg"}
          className={"common-modal alert-modal"}
        >
          <ModalHeader toggle={this.notificationToggle}></ModalHeader>
          <ModalBody>
            <AlertNotificationModal />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    userSubscriptionReducer:
      state.userSubscriptionReducer.userSubscriptionDetails,
    userShortName: state.userSubscriptionReducer.shortName,
    authData: state.authonticationDetails,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(userProfileSubscribe));
