import axios from 'axios';
import { api_base_url, dashboardToken } from '../config';

export function homeFeaturedAction() {
  return function (dispatch) {
    dispatch({
      type: "SHOW_LOADER"
    });
    var promises = [];
    var chintooFeatured = new Promise((resolve, reject) => {
      var headers = {
        'Content-Type': 'application/json',
        'index-api-token': dashboardToken
      };
      axios.get(api_base_url + '/api/v2/kids-index-featured/10', { headers: headers })
        .then(response => {
          if (response["status"] === 200) {
            resolve(response.data && response.data.featured_product ? response.data.featured_product : []);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    promises.push(chintooFeatured);
    var familyFeatured = new Promise((resolve, reject) => {
      var headers = {
        'Content-Type': 'application/json',
        'index-api-token': dashboardToken
      };
      axios.get(api_base_url + '/api/v2/index-featured/10', { headers: headers })
        .then(response => {
          if (response["status"] === 200) {
            resolve(response.data && response.data.featured_product ? response.data.featured_product : []);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    promises.push(familyFeatured);
    var kidsPopular = new Promise((resolve, reject) => {
      var headers = {
        'Content-Type': 'application/json',
        'index-api-token': dashboardToken
      };
      axios.get(api_base_url + '/api/v2/kids-index-popular/10', { headers: headers })
        .then(response => {
          if (response["status"] === 200) {
            resolve(response.data && response.data.popular_picks ? response.data.popular_picks : []);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    promises.push(kidsPopular);
    var kidsEditorsPick = new Promise((resolve, reject) => {
      var headers = {
        'Content-Type': 'application/json',
        'index-api-token': dashboardToken
      };
      axios.get(api_base_url + '/api/v2/kids-index-editors-pick/10', { headers: headers })
        .then(response => {
          if (response["status"] === 200) {
            resolve(response.data && response.data.editor_choice ? response.data.editor_choice : []);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    promises.push(kidsEditorsPick);
    var familyPopular = new Promise((resolve, reject) => {
      var headers = {
        'Content-Type': 'application/json',
        'index-api-token': dashboardToken
      };
      axios.get(api_base_url + '/api/v2/index-popular/10', { headers: headers })
        .then(response => {
          if (response["status"] === 200) {
            resolve(response.data && response.data.popular_picks ? response.data.popular_picks : []);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    promises.push(familyPopular);
    var familyEditorsPick = new Promise((resolve, reject) => {
      var headers = {
        'Content-Type': 'application/json',
        'index-api-token': dashboardToken
      };
      axios.get(api_base_url + '/api/v2/index-editors-pick/10', { headers: headers })
        .then(response => {
          if (response["status"] === 200) {
            resolve(response.data && response.data.editor_choice ? response.data.editor_choice : []);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    promises.push(familyEditorsPick);
    // var languageLabFeatured = new Promise((resolve, reject) => {
    //   var headers = {
    //     'Content-Type': 'application/json',
    //     'index-api-token': dashboardToken
    //   };
    //   axios.get(api_base_url + '/api/language-lab-index-featured/10', { headers: headers })
    //     .then(response => {
    //       if (response["status"] === 200) {
    //         resolve(response.data && response.data.featured_product ? response.data.featured_product : []);
    //       } else {
    //         resolve([]);
    //       }
    //     })
    //     .catch((err) => {
    //       reject(err);
    //     });
    // });
    // promises.push(languageLabFeatured);
    Promise.all(promises)
      .then((result) => {
        dispatch({
          type: "HOME_FEATURED_PRODUCTS",
          payload: result
        });
        dispatch({
          type: "HIDE_LOADER",
        });
      })
      .catch((err) => {
        dispatch({
          type: "FAILED_HOME_FEATURED_PRODUCTS",
          payload: []
        });
        dispatch({
          type: "HIDE_LOADER",
        });
      });
  }
}