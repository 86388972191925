import React, { Component } from "react";
import ReactGA from "react-ga";
import classnames from "classnames";
import {
  Row,
  Col,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
} from "reactstrap";
import { userAuthentication } from "../../action/loginAction";
import SimpleCrypto from "simple-crypto-js";
import {
  api_base_url,
  encryptDecryptKey,
  guestSubscriptionToken,
} from "../../config";
import axios from "axios";
import { connect } from "react-redux";
import { Route, Link, withRouter} from "react-router-dom";
import MyProfileModal from "../modals/myProfile";
import MySnovelTab from "../tabs/mySnovel";
import SubscriptionTab from "../tabs/subscription";
import PurchasedInLibraryTab from "../tabs/purchasedInLibrary";
import MyOrder from "../tabs/myOrderData";
import AlertNotificationModal from "../modals/alertNotification";
import { orderProductAction } from "../../action/orderAction";

import LocalizedStrings from "react-localization";
import languages from "../../language/language.json";
var strings = new LocalizedStrings(languages);

class userProfile extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var isLoggedInWIthMob = "";
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
        isLoggedInWIthMob = userDetailsObject.isLoggedInWithMobile;
        this.getUserProfileDetails(userDetailsObject.token);
        this.getUserSubscriptionDetails(userDetailsObject.token);
      } else {
        this.props.history.push("/");
      }
    } else {
      this.props.history.push("/");
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      isLoading: true,
      myProfileModal: false,
      notificationModal: false,
      activeTab: "1",
      subscription: [],
      monthShortName: {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sept",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      },
      token: token,
      shortName: "",
      purshaseProductCount: 0,
      userOrderCount: 0,
      mySnovelPurchaseCount: "",
      renewSubscriptionDays: "",
      runTimeDuration: 0,
      purchasedProducts: [],
      mySnovelProducts: [],
      notificationCount: 0,
      myBasketCount: 0,
      selectedLanguage: selectedLanguage || "EN",
      userOrderList: [],
      subscribePackages: [],
      apiLimit: 10,
      apiOffset: 0,
      userOrderListOffset: 0,
      userOrderLimit: 10,
      isStartScrollEventListener: false,
      isStartMyOrdersScrollEventListener: false,
      subscribeSort: [],
      isNewUser: false,
      loginwithMobile: isLoggedInWIthMob
    };
    this.libraryTabToggle = this.libraryTabToggle.bind(this);
    this.myProfileToggle = this.myProfileToggle.bind(this);
    this.refreshUserDetails = this.refreshUserDetails.bind(this);
    this.notificationToggle = this.notificationToggle.bind(this);
    ReactGA.pageview(this.props.location.pathname);
    this.openBasketModel = this.openBasketModel.bind(this);
    this.changeOffsetEvent = this.changeOffsetEvent.bind(this);
    this.changeOffsetMyOrder = this.changeOffsetMyOrder.bind(this);
    this.userSubscriptionPurchase = this.userSubscriptionPurchase.bind(this);
  }

  changeOffsetEvent() {
    this.setState({
      apiOffset: this.state.apiOffset + 10,
      apiLimit: this.state.apiLimit,
    });
    if (this.state.purshaseProductCount > this.state.apiOffset) {
      this.getMyLibraryDetails(this.state.token, false);
    }
  }
  changeOffsetMyOrder() {
    this.setState({
      userOrderListOffset: this.state.userOrderListOffset + 10,
      userOrderLimit: this.state.userOrderLimit,
    });
    if (this.state.userOrderCount > this.state.userOrderListOffset) {
      this.props.dispatch(
        orderProductAction(
          this.state.token,
          this.state.userOrderListOffset,
          this.state.userOrderLimit
        )
      );
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { location } = this.props;
      if (location.state) {
        this.myProfileToggle()
        this.setState({
          isNewUser: true
        })
      }else {
      this.setState({
        isNewUser: false
      })
    }
  }
  openBasketModel() {
    this.props.dispatch({ type: "MODEL_SELECTED", payload: true });
  }
  getUserProfileDetails(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/v1/my-profile/", { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var my_subscription = response.data.data.my_subscription || [];
          var profileDetails = response.data.data.my_profile || [];
          this.setState({
            subscription: my_subscription,
            renewSubscriptionDays: response.data.data.renew_in || "",
          });
          if (my_subscription.length == 0) {
            this.getSubscribePackages();
          }
          // console.log("subscription", response)
          var firstName = profileDetails["first_name"] || "";
          var lastName = profileDetails["last_name"] || "";
          if (firstName == "" && lastName == "") {
            this.setState({
              shortName: "UN",
            });
          } else {
            var newShortName = "";
            if (firstName != "") {
              var firstNameLetter = firstName.charAt(0);
              newShortName = firstNameLetter.toUpperCase();
            }
            if (lastName != "") {
              var lastNameLetter = lastName.charAt(0);
              newShortName = newShortName + lastNameLetter.toUpperCase();
            }
            this.setState({
              shortName: newShortName,
            });
          }
        }
        this.getMyBasketDetails(token);
        this.getMyLibraryDetails(token, true);
        this.getMySnovelDetails(this.state.token);
        this.getNotificationList(token);
      })
      .catch((err) => {
        // console.log("error ", err);
        this.getMyBasketDetails(token);
        this.getMyLibraryDetails(token, true);
        this.getMySnovelDetails(this.state.token);
        this.getNotificationList(token);
        this.getSubscribePackages();
      });
  }
  getUserSubscriptionDetails(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/v1/order-new/multiple-subscription/", {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status) {
          var my_subscriptionData = response.data.data || [];
          this.setState({
            subscription: my_subscriptionData,
          });
          if (my_subscriptionData.length == 0) {
            this.getSubscribePackages();
          }
        }

        this.getMyBasketDetails(token);
        this.getMyLibraryDetails(token, true);
        this.getMySnovelDetails(this.state.token);
        this.getNotificationList(token);
      })
      .catch((err) => {
        // console.log("error ", err);
        this.getMyBasketDetails(token);
        this.getMyLibraryDetails(token, true);
        this.getMySnovelDetails(this.state.token);
        this.getNotificationList(token);
        this.getSubscribePackages();
      });
  }

  getSubscribePackages() {
    var headers = {
      "Content-Type": "application/json",
      "guest-subscription-token": guestSubscriptionToken,
    };
    axios
      .get(api_base_url + "/api/subscription-list/", { headers: headers })
      .then((response) => {
        let user = [];
        for (let responseJson of response) {
          user.push(responseJson.subscription_duration)
        }
        this.setState({ subscribeSort: user })
        // console.log("subscribeSort", this.state.subscribeSort)
        if (response.status == 200) {
          this.setState({
            subscribePackages: response.data || [],
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
      });
  }

  getMySnovelDetails(token) {
    this.getMySnovelDetailsAfterAnalytics(token);
    var headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(api_base_url + "/api/player-analytics/", { headers: headers })
      .then((response) => { })
      .catch((err) => {
        // console.log("error ", err);
      });
  }
  getMySnovelDetailsAfterAnalytics(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/my-snovel/", { headers: headers })
      .then((response) => {
        if (response.data.status) {
          this.setState({
            // purshaseProductCount: response.data.all_product_count,
            renewSubscriptionDays: response.data.renew_in || "",
            runTimeDuration: response.data.my_snovel_runtime || 0,
            mySnovelProducts: response.data.last_browse || [],
            mySnovelPurchaseCount: response.data.purchased_products || 0,
          });
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          isLoading: false,
        });
      });
  }

  getMyBasketDetails(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/cart/", { headers: headers })
      .then((response) => {
        if (response.data.status) {
          this.props.dispatch({
            type: "PRODUCT_CART_COUNT",
            payload: response.data.data,
          });
        } else {
          this.setState({
            myBasketCount: 0,
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          myBasketCount: 0,
        });
      });
  }

  getMyLibraryDetails(token, isFromLibrary) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    var apiParams = {
      offset: this.state.apiOffset,
      limit: this.state.apiLimit,
    };
    if (isFromLibrary) {
      apiParams.offset = 0;
      apiParams.limit = 10;
    }
    axios
      .post(api_base_url + "/api/my-library/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          if (isFromLibrary) {
            this.setState({
              purchasedProducts: response.data.data.mylibrary_products || [],
              purshaseProductCount:
                response.data.data.mylibrary_products_count || 0,
              isStartScrollEventListener: true,
            });
          } else {
            var newPurchasedProducts =
              response.data.data.mylibrary_products || [];
            var oldPurchasedProducts = this.state.purchasedProducts;
            var finalPurchasedProducts = oldPurchasedProducts.concat(
              newPurchasedProducts
            );
            this.setState({
              purchasedProducts:
                finalPurchasedProducts ||
                response.data.data.mylibrary_products ||
                [],
              purshaseProductCount:
                response.data.data.mylibrary_products_count || 0,
              isStartScrollEventListener: true,
            });
          }
        } else {
          this.setState({
            purchasedProducts: [],
          });
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          purchasedProducts: [],
          isLoading: false,
        });
      });
  }

  getNotificationList(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    var apiParams = {
      offset: 0,
      limit: 10,
    };
    axios
      .post(api_base_url + "/api/my-notification/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status) {
          this.setState({
            notificationCount: response.data.count || 0,
          });
        } else {
          this.setState({
            notificationCount: 0,
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          notificationCount: 0,
        });
      });
  }

  libraryTabToggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    if (tab == "1") {
      this.setState({
        isLoading: true,
        apiLimit: 10,
        apiOffset: 0,
      });
      this.getMyLibraryDetails(this.state.token, true);
      this.getMySnovelDetails(this.state.token);
    } else if (tab == "3") {
      this.setState({
        isLoading: true,
        userOrderLimit: 10,
        userOrderListOffset: 0,
      });
      this.props.dispatch(orderProductAction(this.state.token, 0, 10));
      this.getMySnovelDetails(this.state.token);
    }
  }
  myProfileToggle() {
    this.setState({
      myProfileModal: !this.state.myProfileModal,
    });
  }
  // let userDetails1 = localStorage.getItem("userDetails");
  // userDetails1 = simpleCrypto.decrypt(userDetails1);
  // var userDetailsObject1 = JSON.parse(userDetails1);
  refreshUserDetails(
    newEmail,
    newSubscribeStatus,
    newFirstName,
    newLastName,
    newProfileImage,
    newCountry,
    newState,
    newMobile,
    instituteName
  ) {
    this.props.dispatch(
      userAuthentication(
        newEmail,
        this.state.token,
        newSubscribeStatus,
        newFirstName,
        newLastName,
        newProfileImage,
        newCountry,
        newState,
        newMobile,
        instituteName,
        this.state.loginwithMobile,
      )
    );
    if (newFirstName == "" && newLastName == "") {
      this.setState({
        shortName: "UN",
      });
    } else {
      var newShortName = "";
      if (newFirstName != "") {
        var firstNameLetter = newFirstName.charAt(0);
        newShortName = firstNameLetter.toUpperCase();
      }
      if (newLastName != "") {
        var lastNameLetter = newLastName.charAt(0);
        newShortName = newShortName + lastNameLetter.toUpperCase();
      }
      this.setState({
        shortName: newShortName,
      });
    }
  }
  notificationToggle() {
    this.setState({
      notificationModal: !this.state.notificationModal,
    });
    setTimeout(() => {
      this.setState({ notificationCount: 0 });
    }, 1000);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      myBasketCount: nextProps.productCartCountReducer || 0,
    });
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
    if (nextProps.userSpecificOrderData.allOrderData.length >= 0 && JSON.stringify(nextProps.userSpecificOrderData) !== JSON.stringify(this.props.userSpecificOrderData)) {
      var oldUserOrderList = [];
      if (this.state.userOrderListOffset !== 0) {
        oldUserOrderList = this.state.userOrderList;
      }
      var newUserOrderList = nextProps.userSpecificOrderData.allOrderData || [];
      var finalUserOrderList = oldUserOrderList.concat(newUserOrderList);
      this.setState({
        userOrderList: finalUserOrderList,
        userOrderCount: nextProps.userSpecificOrderData.allOrderCount,
        isLoading: false,
        isStartMyOrdersScrollEventListener: true,
      });
    }
    if (nextProps.myLibraryActiveTabReducer === true) {
      this.setState({
        activeTab: "2",
        notificationModal: false,
      });
    }
  }
  numberSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  subscriptionDateString(dateString) {
    var dateStringArray = dateString.split("-");
    var newYear = dateStringArray[0];
    var newMonth = parseInt(dateStringArray[1]) + "";
    var newDay = parseInt(dateStringArray[2]);
    var newString =
      this.numberSuffix(newDay) +
      " " +
      this.state.monthShortName[newMonth] +
      ", " +
      newYear;
    return newString;
  }

  userSubscriptionPurchase() {
    if (this.props.authData.subscribeStatus) {
      this.getMySnovelDetails(this.state.token);
      this.libraryTabToggle("2");
    } else {
      this.props.history.push("/subscribe");
    }
  }

  render() {
    var { subscription } = this.state;
    var isSubscriptionActive = false;
    return (
      <div>
        {this.state.isLoading ? (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        ) : null}
        <div className="container">
          <div className="billingTopWrp">
            <Row className="left-right-margin-none">
              <Col md="9" lg="9" className="planLeft">
                {this.props.authData.userProfile ? (
                  <div className="billProfileLFtLogo">
                    <img
                      src={this.props.authData.userProfile}
                      alt="Profile Image"
                    />{" "}
                  </div>
                ) : (
                  <div className="billProfileLFtLogo">
                    <img
                      src={process.env.PUBLIC_URL + "/images/user_circle.png"}
                      alt="Profile Image"
                    />
                  </div>
                )}
                {subscription.length > 0 && subscription.map((subscription, index) => {
                  if (index === 0 || index === 1 && subscription.is_current === true) {
                    isSubscriptionActive = true;
                    return (
                      <div className="planNameDiv">
                        {/* {console.log("subscription", subscription)} */}
                        <p>{strings.Planname}:</p>
                        <h1>
                          {isSubscriptionActive ?
                            subscription.subscription_type :
                            (
                              <span class="subscription-expired">
                                {" "}
                                ({strings.subscriptionExpired})
                              </span>
                            )}
                        </h1>
                        <p>
                          {strings.startDate}:{" "}
                          <span>
                            {this.subscriptionDateString(
                              subscription.start_date
                            )}
                          </span>{" "}
                          | {strings.endDate}:{" "}
                          <span>
                            {this.subscriptionDateString(
                              subscription.end_date
                            )}
                          </span>
                        </p>
                      </div>
                    )
                  }
                })}
                {!isSubscriptionActive && subscription.length > 0 &&
                  <div className="planNameDiv">
                    <p>{strings.Planname}:</p>
                    <h1>
                      <span class="subscription-expired">
                        {" "}
                        ({strings.subscriptionExpired})
                      </span>
                    </h1>
                  </div>
                }
                {subscription.length == 0 && (
                  <div className="planNameDiv">
                    <p class="no-subscription">
                      {strings.Subscriptionnotactivated}.
                    </p>
                  </div>
                )}
              </Col>
              <Col md="3" lg="3" className="planRight">
                <div className="billingProfile">
                  <a href="javascript:;" onClick={this.myProfileToggle}>
                    {strings.MyProfile}
                  </a>
                  <div
                    onClick={this.notificationToggle}
                    className="billingBell"
                    data-toggle="modal"
                    data-target="#myalertPop"
                  >
                    <img src="images/bellbilling_icon.png" alt="" />
                    {this.state.notificationCount > 0 && (
                      <span>{this.state.notificationCount}</span>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="clearfix"></div>
          <div class="browseType_Wrapper" id="libraryTabOuter">
            <div class="homeTabs">
              <div>
                <Nav tabs className="innerProductsTab" id="slidecontrols">
                  {/* <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.libraryTabToggle('1'); }}
                                      >
                                        {strings.MySnovel}
                                      </NavLink>
                                    </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.libraryTabToggle("1");
                      }}
                    >
                      {strings.PurchasedinLibrary}
                      {this.state.purshaseProductCount != 0 && (
                        <span>&nbsp;{this.state.purshaseProductCount}</span>
                      )}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={this.userSubscriptionPurchase}
                    >
                      {strings.Subscription}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={() => {
                        this.libraryTabToggle("3");
                      }}
                    >
                      {strings.MyOrders}
                    </NavLink>
                  </NavItem>
                </Nav>
                <div class="libraryBskDiv cursorPointer" onClick={this.openBasketModel} style={{ width: "125px", float: "left" }}>
                  <img src="images/library_basket_icon.png" alt="" />
                  <p>
                    {strings.myBasket}
                    <span>{this.state.myBasketCount}</span>
                  </p>
                </div>
                <div className="clearfix"></div>
                <TabContent activeTab={this.state.activeTab}>
                  {/* <TabPane tabId="1">
                                        <MySnovelTab
                                            runTimeDuration={this.state.runTimeDuration}
                                            subscription={this.state.subscription}
                                            renewSubscriptionDays={this.state.renewSubscriptionDays}
                                            goToLibraryTab={this.libraryTabToggle}
                                            selectedLanguage={this.state.selectedLanguage}
                                            purshaseProductCount={this.state.mySnovelPurchaseCount}
                                            mySnovelProducts={this.state.mySnovelProducts}
                                        />
                                    </TabPane> */}
                  <TabPane tabId="1">
                    <PurchasedInLibraryTab
                      purchasedProducts={this.state.purchasedProducts}
                      selectedLanguage={this.state.selectedLanguage}
                      changeOffset={this.changeOffsetEvent}
                      isStartScrollEventListener={
                        this.state.isStartScrollEventListener
                      }
                      runTimeDuration={this.state.runTimeDuration}
                      subscription={this.state.subscription}
                      renewSubscriptionDays={this.state.renewSubscriptionDays}
                      goToLibraryTab={this.libraryTabToggle}
                      selectedLanguage={this.state.selectedLanguage}
                      purshaseProductCount={this.state.mySnovelPurchaseCount}
                      mySnovelProducts={this.state.mySnovelProducts}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <SubscriptionTab
                      subscription={this.state.subscription}
                      renewSubscriptionDays={this.state.renewSubscriptionDays}
                      selectedLanguage={this.state.selectedLanguage}
                      subscribePackages={this.state.subscribePackages}
                      mySnovelProducts={this.state.mySnovelProducts}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <MyOrder
                      selectedLanguage={this.state.selectedLanguage}
                      orderList={this.state.userOrderList}
                      changeOffsetOrder={this.changeOffsetMyOrder}
                      isStartMyOrdersScrollEventListener={
                        this.state.isStartMyOrdersScrollEventListener
                      }
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="mySnovelFaq">
            <Row className="left-right-margin-none">
              <Col lg="9" className="visitFaq">
                {strings.queryPurchasing}
                <br />
                <span>
                  <NavLink className="visit-faq-link" tag={Link} to="/faq">
                    {strings.visitourFAQ}
                  </NavLink>
                </span>
              </Col>
              <Col lg="3" className="visitFaq emailRight">
                {strings.ContactUs}:
                <a href="mailto:hello@snovel.in">hello@snovel.in</a>
              </Col>
            </Row>
          </div>
        </div>
        <div className="snovelApp_Wrapper">
          <div className="container">
            <Row>
              <Col md="12" lg="8">
                <div className="snovelapp-left">
                  <div class="left-contents">
                    <h2>{strings.getTheSnovelApp}</h2>
                    <p>{strings.snovelAppDesc}</p>
                    <div class="app-btn">
                      <a
                        href="https://apps.apple.com/in/app/snovel/id1064404656"
                        title=""
                        target="_blank"
                      >
                        <div class="iphone-app-btn">
                          <img
                            src="images/iphone-app-icon.png"
                            alt="iphone-app"
                            class="iphone-app-icon"
                          />
                          <p>{strings.appledownload}</p>
                        </div>
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.sn.snovel&hl=en"
                        title=""
                        target="_blank"
                      >
                        <div class="android-app-btn">
                          <img
                            src="images/android-app-icon.png"
                            alt="android-app"
                            class="android-app-icon"
                          />

                          <p>{strings.platStoreDownload}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="12" lg="4">
                <div className="snovelapp-right">
                  <div class="right-contents">
                    <h2>{strings.giftSnovelExp}</h2>
                    <p>{strings.snovelAppDescright}</p>
                    <div class="snovel-gift">
                      <div class="gift-icon">
                        <img
                          src="images/gift-icon.png"
                          alt="gift-icon"
                          class="gift-icon1"
                        />
                      </div>
                      <div class="gift-text">
                        <a href="/gift-snovel">
                          {" "}
                          <p>{strings.giftSnovelExp}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          isOpen={this.state.myProfileModal}
          toggle={this.myProfileToggle}
          size={"lg"}
          className={"common-modal"}
        >
          <ModalHeader toggle={this.myProfileToggle}></ModalHeader>
          <ModalBody>
            <MyProfileModal
              selectedLanguage={this.state.selectedLanguage}
              closeModal={this.myProfileToggle}
              refreshData={this.refreshUserDetails}
              profileStatus={this.state.isNewUser}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.notificationModal}
          toggle={this.notificationToggle}
          size={"lg"}
          className={"common-modal"}
        >
          <ModalHeader toggle={this.notificationToggle}></ModalHeader>
          <ModalBody>
            <AlertNotificationModal />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
function mapStatesToProps(globalData) {
  return {
    authData: globalData.authonticationDetails,
    userSpecificOrderData: globalData.userSpecificOrderData,
    productCartCountReducer: globalData.productCartCountReducer.cartCount,
    myLibraryActiveTabReducer: globalData.myLibraryActiveTabReducer.activeTab,
  };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(userProfile));
