const initialState = {
    selectedLanguage : "EN"
}

export default function languageReducerAction (state = initialState, action) {
    switch (action.type) {
        case 'CHNAGE_LANGUAGE': {
            return { 
                ...state,
                selectedLanguage : action.payload
             };
        }
        
        default: {
            return { ...state };
        }
    }
  }