import React, { Component, Fragment } from "react";
import { NavLink, Row, Table } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import axios from "axios";
import SimpleCrypto from "simple-crypto-js";
import { userAuthentication } from "../../action/loginAction";
import {
  api_base_url,
  googleAnalyticsTrackingId,
  encryptDecryptKey,
  subscriptionDefaultImage,
} from "../../config";
import LocalizedStrings from "react-localization";
import languages from "../../language/language.json";
var strings = new LocalizedStrings(languages);
ReactGA.initialize(googleAnalyticsTrackingId);

class paymentFailure extends Component {
  constructor(props) {
    super(props);
    /***** Get User Data *****/
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    var firstName = "";
    var lastName = "";
    var userEmail = "";
    var newProfileImage = "";
    var userCountryCode = "";
    var userStateCode = "";
    var userMobileNumber = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
        firstName = userDetailsObject.firstName || "";
        lastName = userDetailsObject.lastName || "";
        userEmail = userDetailsObject.userEmail || "";
        newProfileImage = userDetailsObject.userProfile || "";
        userCountryCode = userDetailsObject.userCountry || "";
        userStateCode = userDetailsObject.userState || "";
        userMobileNumber = userDetailsObject.userMobile || "";
      }
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      isLoading: true,
      token: token || "",
      firstName: firstName || "",
      lastName: lastName || "",
      userEmail: userEmail || "",
      newProfileImage: newProfileImage || "",
      userCountryCode: userCountryCode || "",
      userStateCode: userStateCode || "",
      userMobileNumber: userMobileNumber || "",
      orderSpecificDetails: {},
      monthShortName: {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sept",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      },
      selectedLanguage: selectedLanguage || "EN",
      isIndian: null,
    };
    ReactGA.pageview(this.props.location.pathname);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
    this.userSubscribeedAfterLogin();
    this.orderDetails();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isIndian !== null && nextProps.isIndian !== undefined) {
      this.setState({ isIndian: nextProps.isIndian });
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  numberSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  subscriptionDateString(dateString) {
    var dateStringArray = dateString.split("-");
    var newYear = dateStringArray[0];
    var newMonth = parseInt(dateStringArray[1]) + "";
    var newDay = parseInt(dateStringArray[2]);
    var newString =
      this.numberSuffix(newDay) +
      " " +
      this.state.monthShortName[newMonth] +
      ", " +
      newYear;
    return newString;
  }
  userSubscribeedAfterLogin() {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .get(api_base_url + "/api/user-subscribed/", { headers: headers })
      .then((response) => {
        if (response.data.status === true) {
          this.props.dispatch(
            userAuthentication(
              this.state.userEmail,
              this.state.token,
              response.data.is_subsribed,
              this.state.firstName,
              this.state.lastName,
              this.state.newProfileImage,
              this.state.userCountryCode,
              this.state.userStateCode,
              this.state.userMobileNumber
            )
          );
        }
      })
      .catch((err) => {
        // console.log("error ", err);
      });
  }
  orderDetails() {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .get(
        api_base_url +
        "/api/order-details/" +
        this.props.match.params.token +
        "/",
        { headers: headers }
      )
      .then((response) => {
        if (response.data.status === true) {
          this.setState({
            orderSpecificDetails: response.data.data[0],
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  orderImageDetails(orderImages) {
    return orderImages.map((orderImages, index) => {
      return (
        <tr>
          <td className="">
            <div className="orderImage">
              {orderImages.images.length > 0 ? (
                <img src={orderImages.images[0].image1} />
              ) : orderImages.product_type == "subscription_product" ? (
                <img
                  src={subscriptionDefaultImage}
                  alt="video"
                  className="popularImage"
                />
              ) : (
                <img
                  src={"/images/default.jpg"}
                  alt="video"
                  className="popularImage"
                />
              )}
            </div>
          </td>
          <td className="">
            {orderImages.custom_translation[this.state.selectedLanguage].name}
          </td>
        </tr>
      );
    });
  }
  render() {
    return (
      <div>
        <div>
          {this.state.isLoading ? (
            <div id="loading">
              <div id="loading-center">
                <div id="loading-center-absolute">
                  <img src="images/loader.gif" />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="container">
          <br />
          <div className="pageTitle_Wrapper text-center">
            <h1 className="pageTitle">{strings.PaymentFailed}</h1>
          </div>
          <div class="clearfix"></div>
          <Row>
            {Object.keys(this.state.orderSpecificDetails).length > 0 && (
              <Table className="paymentSuccess">
                <thead responsive={true}>
                  <tr>
                    <th className="orders-table__header imageHeader">
                      <span className="nobr">
                        {strings.orderId} # {this.state.orderSpecificDetails.id}
                      </span>
                    </th>
                    <th className="orders-table__header">
                      <span className="nobr">
                        {strings.datePurchased} -{" "}
                        {this.subscriptionDateString(
                          this.state.orderSpecificDetails.created
                        )}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.orderImageDetails(
                    this.state.orderSpecificDetails.product_details
                  )}
                  <tr className="">
                    <td colspan="2" align="right">
                      <strong>{strings.totalPrice}</strong> -{" "}
                      {this.state.isIndian !== null ?
                        <Fragment>
                          {this.state.isIndian ? strings.priceSign + "" + this.state.orderSpecificDetails.total_gross_inr.amount
                            : "$" + this.state.orderSpecificDetails.total_gross.amount}
                        </Fragment>
                        :
                        <Fragment>
                          {this.state.orderSpecificDetails.order_currency === "2"
                            ? strings.priceSign + " " + this.state.orderSpecificDetails.total_gross_inr.amount
                            : "$" + this.state.orderSpecificDetails.total_gross.amount}
                        </Fragment>
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            <div class="giftSubsCription payment-success">
              <NavLink tag={Link} to="/library">
                {strings.goToLibrary}
              </NavLink>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    isIndian: state.isReisteredDevice.isIndian,
  };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(paymentFailure));
