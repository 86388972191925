const initialState = {
    promotionalNotification:[],
    promotionalEpisode:[],
    publishNotification:[],
    accoladesNews: [],
    mediaNews: []
}

export default function productNotificationReducer (state = initialState, action) {
    switch (action.type) {
        case 'PRODUCT_NOTIFICATION': {
            var responseData = action.payload;
            var promotionalNotificationData = [];
            var publishNotificationData = [];
            var promotionalEpisodeData = [];
            responseData.map((responseType, index) => {
                if(responseType.notification_type === 'promotional_notification') {
                    promotionalNotificationData.push(responseType);
                }
                if(responseType.notification_type === 'product_publish') {
                    publishNotificationData.push(responseType);
                }
                if(responseType.notification_type === 'episode_launch') {
                    promotionalEpisodeData.push(responseType);
                }
            })
            return { ...state, 
                promotionalNotification: promotionalNotificationData,
                publishNotification: publishNotificationData,
                promotionalEpisode: promotionalEpisodeData
            };
        }
        case 'ACCOLADES_NEWS_NOTIFICATION': {
            return { ...state, accoladesNews: action.payload };
        }
        case 'MEDIA_NEWS_NOTIFICATION': {
            return { ...state, mediaNews:action.payload };
        }
        case 'FAILED_PRODUCT_NOTIFICATION': {
            return { ...state };
        }
        default: {
            return { ...state };
        }
    }
}