import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel2";
import classnames from "classnames";
import $ from "jquery";
import { Route, Link, Redirect, withRouter } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import {
    api_base_url,
    dashboardToken,
    encryptDecryptKey,
    googleAnalyticsTrackingId,
    getHoursFormat,
} from "../config";
import axios from "axios";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    UncontrolledPopover,
    PopoverBody,
    Breadcrumb,
    BreadcrumbItem,
} from "reactstrap";
import swal from "sweetalert";
import { homeTrendingProductDetailsData } from "../action/trendingProductAction";
import StarRatings from "react-star-ratings";
import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    PinterestIcon,
} from "react-share";
import AudioPlayer from "react-modular-audio-player";
import { browseCategoryProductDetailsDataAction } from "../action/browseCategoryAction";
import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
import { validate } from "@babel/types";
import { categoryActions, familyCategoryAction } from "../action/freeSnovelHomeAction";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);


let rearrangedPlayer = [
    {
        className: "tier-top",
        innerComponents: [
            {
                type: "rewind",
                style: { width: "fit-content" },
            },
            {
                type: "play",
                style: { width: "fit-content" },
            },
            {
                type: "forward",
                style: { width: "fit-content" },
            },
            {
                type: "seek",
            },
            {
                type: "volume",
            },
        ],
    },
];

const front_base_url = window.location.origin;
const path = window.location.pathname;
class snovelFamilyCategoryProducts extends Component {
    constructor(props) {
        super(props);
        var selectedLanguage = window.localStorage.getItem("language");
        var title = JSON.parse(localStorage.getItem("customTranslation"));
        var category = JSON.parse(localStorage.getItem("categoryName"));
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
        }
        var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
        var userDetails = localStorage.getItem("userDetails");
        var token = "";
        if (userDetails) {
            userDetails = simpleCrypto.decrypt(userDetails);
            var userDetailsObject = JSON.parse(userDetails);
            if (
                userDetailsObject.loginSuccess == true &&
                userDetailsObject.token != ""
            ) {
                token = userDetailsObject.token || "";
            }
        }
        this.state = {
            token: token || "",
            activeTab: "1",
            innerActiveTab: "1",
            isLoading: false,
            topSnovelTrending: [],
            selectedLanguage: selectedLanguage || "EN",
            audioObject: undefined,
            audioPlayerObject: undefined,
            topSnovelLastPlayed: "",
            browseTopAudio: {},
            latestLaunchApiData: [],
            latestLaunchAudio: {},
            latestLaunchLastPlayed: "",
            productTypeAudio: {},
            productTypeLastPlayed: "",
            selectedPopularIndex: 0,
            popoverOpenShare: false,
            popularTitleSelectedAudio: [],
            audiobookAllProducts: [],
            audiobookTotalCount: "",
            podcastTotalCount: "",
            menuSelectedCategory: [],
            isSelectedCategory: false,
            isSelectedCategoryName: "",
            isSelectedPostType: "",
            cartFailureMessage: "",
            latestProductToken: token || dashboardToken,
            topPopoverOpenShare: false,
            elementProductId: "",
            isIndian: null,
            activePath: "",
            headingText: title || "",
            latestLaunchData: [],
            categoryName: category || "",
        };
        this.tabToggle = this.tabToggle.bind(this);
        this.innerTabToggle = this.innerTabToggle.bind(this);
        this.popOverToggle = this.popOverToggle.bind(this);
        this.enableTabs = this.enableTabs.bind(this);
        this.topSocialToggle = this.topSocialToggle.bind(this);
        ReactGA.pageview(this.props.location.pathname);
    }
    tabToggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const urlPath = window.location.pathname;
        const active = urlPath.split("/")[2];
        this.setState({
            activePath: active
        })
        // this.props.dispatch(
        //     browseCategoryProductDetailsDataAction("family", active, 1)
        // );
    }

    componentWillUnmount() {
        if (this.state.audioObject) {
            this.state.audioObject.pause();
        }
    }


    cartProductsModal(variantId) {
        if (this.props.authData.loginSuccess) {
            var apiParams = {
                email: "",
                discount_amount: 0.0,
                discount_amount_inr: 0.0,
                gift_cart: false,
                cart_lines: [
                    {
                        variant: variantId,
                        quantity: 1,
                        data: {},
                    },
                ],
            };

            var headers = {
                "Content-Type": "application/json",
                Authorization: "Token " + this.state.token,
            };
            axios
                .post(api_base_url + "/api/cart/", apiParams, { headers: headers })
                .then((response) => {
                    if (response.data.status === true) {
                        this.props.dispatch({
                            type: "PRODUCT_CART_COUNT",
                            payload: response.data.data.quantity,
                        });
                        this.props.dispatch({ type: "MODEL_SELECTED", payload: true });
                    } else {
                        // console.log("Subscribe page error");
                    }
                    this.setState({ isLoading: false });
                })
                .catch((err) => {
                    // console.log("fetched.", err);
                    this.setState({
                        cartFailureMessage: "Product already added in cart",
                    });
                });
        } else {
            this.props.history.push("/sign-in");
        }
    }
    popOverToggle() {
        this.setState({
            popoverOpenShare: !this.state.popoverOpenShare,
        });
    }

    topSocialToggle(elementId) {
        this.setState({
            topPopoverOpenShare: !this.state.topPopoverOpenShare,
            elementProductId: elementId,
        });
    }

    browsePauseAudio(map_url) {
        this.state.audioObject.pause();
        this.setState({
            topSnovelLastPlayed: "",
        });
    }

    browsePlayAudio(map_url) {
        if (this.state.browseTopAudio[map_url]) {
            this.setState({
                topSnovelLastPlayed: map_url,
                latestLaunchLastPlayed: "",
                productTypeLastPlayed: "",
            });
            if (this.state.audioPlayerObject) {
                this.state.audioPlayerObject.pause();
            }
            var playPromise = this.state.audioObject.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(function () { })
                    .catch(function (error) {
                        const isFirefox = typeof InstallTrigger !== "undefined";
                        if (isFirefox) {
                            swal(
                                "",
                                "Something went wrong with your browser. Please try another one.",
                                "error"
                            );
                        }
                    });
            }
        } else {
            if (this.state.audioObject) {
                this.state.audioObject.pause();
            }
            var apiParams = {
                map_url: map_url,
            };
            var headers = {
                "Content-Type": "application/json",
                "index-api-token": dashboardToken,
            };
            axios
                .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
                    headers: headers,
                })
                .then((response) => {
                    if (response.data) {
                        var url = response.data.data.signed_url || "";
                        var audio = new Audio(url);
                        var parentThis = this;
                        audio.addEventListener("ended", function () {
                            parentThis.setState({
                                topSnovelLastPlayed: "",
                            });
                        });
                        audio.type = "audio/mpeg";
                        var newTrendingAudio = {};
                        newTrendingAudio[map_url] = url;
                        this.setState({
                            audioObject: audio,
                            browseTopAudio: newTrendingAudio,
                            topSnovelLastPlayed: map_url,
                            latestLaunchLastPlayed: "",
                            productTypeLastPlayed: "",
                        });
                        if (this.state.audioPlayerObject) {
                            this.state.audioPlayerObject.pause();
                        }
                        var playPromise = audio.play();
                        if (playPromise !== undefined) {
                            playPromise
                                .then(function () { })
                                .catch(function (error) {
                                    const isFirefox = typeof InstallTrigger !== "undefined";
                                    if (isFirefox) {
                                        swal(
                                            "",
                                            "Something went wrong with your browser. Please try another one.",
                                            "error"
                                        );
                                    }
                                });
                        }
                    }
                })
                .catch((err) => {
                    // console.log("Error - ", err);
                });
        }
    }

    innerTabToggle(updatedPostType, tab) {
        if (this.state.innerActiveTab !== tab) {
            this.setState({
                innerActiveTab: tab,
            });
            this.browseProductsData(updatedPostType, tab);
        }
    }
    componentWillMount() {
        const activePath = JSON.parse(localStorage.getItem("categoryName"));
        var post_type = localStorage.getItem("post_type");
        this.props.dispatch(browseCategoryProductDetailsDataAction(post_type, activePath, 1));
        if (!Object.keys(this.props.browseCategoryProductDetailsData).length) {
            this.props.dispatch(
                browseCategoryProductDetailsDataAction(post_type, activePath, 1)
            );
        }
        // this.latestLaunchProductFunction();
    }
    componentWillReceiveProps(nextProps) {
        var post_type = localStorage.getItem("post_type");
        var topSnovelBrowse = [];
        if (nextProps.trendingProductsDetails.treandingProducts.length > 0) {
            nextProps.trendingProductsDetails.treandingProducts.forEach((element) => {
                topSnovelBrowse.push(element.trending_picks);
            });
            this.setState({
                topSnovelTrending: topSnovelBrowse[0][0] || [],
            });
        }
        if (nextProps.isIndian !== null && nextProps.isIndian !== undefined) {
            this.setState({ isIndian: nextProps.isIndian });
        }
        if (nextProps.browseCategoryProductDetailsData.total_count !== undefined) {
            this.setState({
                audiobookTotalCount:
                    nextProps.browseCategoryProductDetailsData.total_count[post_type] ||
                    "",
            });
        }
        if (nextProps.browseCategoryProductDetailsData[post_type] !== undefined) {
            this.setState({
                audiobookAllProducts:
                    nextProps.browseCategoryProductDetailsData[post_type].all_product ||
                    [],
            });
        } else {
            this.setState({
                latestLaunchData: nextProps.browseCategoryProductDetailsData || []
            })
        }
        if (
            this.props.selectedPostAndCategory !== "" &&
            nextProps.selectedPostAndCategory.menuSelectedCategory[0]
                .selectedCategory !== false
        ) {
            this.setState({
                isSelectedCategoryName:
                    nextProps.selectedPostAndCategory.menuSelectedCategory[0]
                        .selectedCategory,
                isSelectedPostType:
                    nextProps.selectedPostAndCategory.menuSelectedCategory[0]
                        .selectedPostType,
                isSelectedCategory: true,
                innerActiveTab: "1",
            });
        }

        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
            this.setState({
                selectedLanguage: selectedLanguage,
            });
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
            this.setState({
                selectedLanguage: "EN",
            });
        }
    }
    enableTabs() {
        this.setState({
            isSelectedCategory: false,
            isSelectedCategoryName: "",
            isSelectedPostType: "",
            innerActiveTab: "1",
        });
        this.props.dispatch({
            type: "MENU_UNSELECTED_CATEGORY",
            payload: [false, null],
        });
        this.props.dispatch(browseCategoryProductDetailsDataAction(null, null, 1));
    }
    productDetailsId(productID, slug) {
        localStorage.setItem("productId", productID);
        this.props.history.push("/product-details/" + productID + "/" + slug);
    }
    /****  Author details page link **********/
    authorDetailsId(authorID, authorName) {
        var titleUrl = authorName.toLowerCase();
        var authorTitle = titleUrl.replace(".", "");
        var authorName = authorTitle.split(" ").join("-");
        this.props.history.push("/author/" + authorID + "/" + authorName);
    }
    authorLinkList(authorList) {
        return authorList.map((authorName, index) => {
            if (
                authorName.custom_translation[this.state.selectedLanguage] != undefined
            ) {
                return (
                    <NavLink
                        tag={Link}
                        onClick={() => {
                            this.authorDetailsId(authorName.id, authorName.name);
                        }}
                        key={index}
                    >
                        <span key={index}>
                            {authorName.custom_translation[this.state.selectedLanguage].name}
                        </span>
                    </NavLink>
                );
            }
        });
    }


    updateSletedIndex(slideIndex) {
        this.setState({
            selectedPopularIndex: slideIndex,
        });
        this.getPopularTitleAudio(this.state.latestLaunchApiData[slideIndex]);
    }
    getPopularTitleAudio(productDetails) {
        if (productDetails.sampletracks.length > 0) {
            var apiParams = {
                map_url: productDetails.sampletracks[0].map_url || "",
            };
            var headers = {
                "Content-Type": "application/json",
                "index-api-token": dashboardToken,
            };
            axios
                .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
                    headers: headers,
                })
                .then((response) => {
                    if (response.data) {
                        var url = response.data.data.signed_url || "";
                        this.setState({
                            popularTitleSelectedAudio: [{ src: url }],
                        });
                        var newAudioObject = $(".audio-player audio");
                        if (newAudioObject.length > 0) {
                            var parentThis = this;
                            this.setState({
                                audioPlayerObject: newAudioObject[0],
                            });
                            newAudioObject[0].onerror = function () {
                                const isFirefox = typeof InstallTrigger !== "undefined";
                                if (isFirefox) {
                                    swal(
                                        "",
                                        "Something went wrong with your browser. Please try another one.",
                                        "error"
                                    );
                                }
                            };
                            newAudioObject[0].addEventListener("play", function () {
                                if (parentThis.state.audioObject) {
                                    parentThis.state.audioObject.pause();
                                    parentThis.setState({
                                        productTypeLastPlayed: "",
                                        topSnovelLastPlayed: "",
                                        latestLaunchLastPlayed: "",
                                    });
                                }
                            });
                        }
                    }
                })
                .catch((err) => {
                    // console.log("Error - ", err);
                });
        } else {
            this.setState({
                popularTitleSelectedAudio: [],
            });
        }
    }
    latestLaunchPauseAudio(map_url) {
        this.state.audioObject.pause();
        this.setState({
            latestLaunchLastPlayed: "",
        });
    }
    latestLaunchProductFunction() {
        var headers = {
            "Content-Type": "application/json",
            "index-api-token": this.state.latestProductToken,
        };
        axios
            .get(api_base_url + "/api/v2/browse-latest-launch/10/", { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    this.setState({
                        latestLaunchApiData: response.data.latest_launch || [],
                    });
                } else {
                    // console.log("Not Loaded");
                }
            })
            .catch((err) => {
                // console.log("fetched.", err);
            });
    }
    /****** User is take subscription then can add product in library to click on add library button */
    addToLibraryProduct(productID) {
        var apiParams = {
            product: productID,
        };
        var headers = {
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
        };
        axios
            .post(api_base_url + "/api/add-to-library/", apiParams, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status === true) {
                    this.props.history.push("/library");
                }
            })
            .catch((err) => {
                // console.log("fetched.", err);
            });
    }
    latestLaunchPlayAudio(map_url) {
        if (this.state.latestLaunchAudio[map_url]) {
            this.setState({
                latestLaunchLastPlayed: map_url,
                topSnovelLastPlayed: "",
                productTypeLastPlayed: "",
            });
            if (this.state.audioPlayerObject) {
                this.state.audioPlayerObject.pause();
            }
            var playPromise = this.state.audioObject.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(function () { })
                    .catch(function (error) {
                        const isFirefox = typeof InstallTrigger !== "undefined";
                        if (isFirefox) {
                            swal(
                                "",
                                "Something went wrong with your browser. Please try another one.",
                                "error"
                            );
                        }
                    });
            }
        } else {
            if (this.state.audioObject) {
                this.state.audioObject.pause();
            }
            var apiParams = {
                map_url: map_url,
            };
            var headers = {
                "Content-Type": "application/json",
                "index-api-token": dashboardToken,
            };
            axios
                .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
                    headers: headers,
                })
                .then((response) => {
                    if (response.data) {
                        var url = response.data.data.signed_url || "";
                        var audio = new Audio(url);
                        var parentThis = this;
                        audio.addEventListener("ended", function () {
                            parentThis.setState({
                                latestLaunchLastPlayed: "",
                            });
                        });
                        audio.type = "audio/mpeg";
                        var newLatestLaunchAudio = {};
                        newLatestLaunchAudio[map_url] = url;
                        this.setState({
                            audioObject: audio,
                            latestLaunchAudio: newLatestLaunchAudio,
                            latestLaunchLastPlayed: map_url,
                            topSnovelLastPlayed: "",
                            productTypeLastPlayed: "",
                        });
                        if (this.state.audioPlayerObject) {
                            this.state.audioPlayerObject.pause();
                        }
                        var playPromise = audio.play();
                        if (playPromise !== undefined) {
                            playPromise
                                .then(function () { })
                                .catch(function (error) {
                                    const isFirefox = typeof InstallTrigger !== "undefined";
                                    if (isFirefox) {
                                        swal(
                                            "",
                                            "Something went wrong with your browser. Please try another one.",
                                            "error"
                                        );
                                    }
                                });
                        }
                    }
                })
                .catch((err) => {
                    // console.log("Error - ", err);
                });
        }
    }

    authorsSelected(authorsList) {
        var authorArray = [];
        authorsList.map((authorName) => {
            if (
                authorName.custom_translation[this.state.selectedLanguage] != undefined
            ) {
                authorArray.push(
                    authorName.custom_translation[this.state.selectedLanguage].name
                );
            }
        });
        return authorArray.toString();
    }
    authorsLIst(authorsList) {
        return authorsList.map((authorName) => {
            if (
                authorName.custom_translation[this.state.selectedLanguage] != undefined
            ) {
                return (
                    <span>
                        {authorName.custom_translation[this.state.selectedLanguage].name}
                    </span>
                );
            }
        });
    }
    directorsSelected(directorList) {
        var directorArray = [];
        directorList.map((directorName) => {
            if (
                directorName.custom_translation[this.state.selectedLanguage] !=
                undefined
            ) {
                directorArray.push(
                    directorName.custom_translation[this.state.selectedLanguage].name
                );
            }
        });
        return directorArray.toString();
    }
    artistsSelected(artistsList) {
        var artistsArray = [];
        artistsList.map((artistsName) => {
            if (
                artistsName.custom_translation[this.state.selectedLanguage] != undefined
            ) {
                artistsArray.push(
                    artistsName.custom_translation[this.state.selectedLanguage].name
                );
            }
        });
        return artistsArray.toString();
    }
    categorySelected(categoryList) {
        var categoryArray = [];
        categoryList.map((categoryName) => {
            if (
                categoryName.custom_translation[this.state.selectedLanguage] !=
                undefined
            ) {
                categoryArray.push(
                    categoryName.custom_translation[this.state.selectedLanguage].name
                );
            }
        });
        return categoryArray.join("| ");
    }

    browseProductsData(updatePostType, selectedTab) {
        if (updatePostType === "family") {
            this.tabToggle("1");
            if (this.state.isSelectedPostType === "") {
                this.props.dispatch(
                    browseCategoryProductDetailsDataAction(
                        updatePostType,
                        null,
                        parseInt(selectedTab)
                    )
                );
                this.setState({
                    innerActiveTab: selectedTab + "",
                });
            } else {
                this.props.dispatch(
                    browseCategoryProductDetailsDataAction(
                        updatePostType,
                        this.state.isSelectedCategoryName,
                        1
                    )
                );
                this.setState({
                    innerActiveTab: "1",
                });
            }
        }
    }
    /********* Product Type Play, Pause Audio **************/
    productTypePlayAudio(map_url) {
        if (this.state.productTypeAudio[map_url]) {
            this.setState({
                productTypeLastPlayed: map_url,
                topSnovelLastPlayed: "",
                latestLaunchLastPlayed: "",
            });
            if (this.state.audioPlayerObject) {
                this.state.audioPlayerObject.pause();
            }
            var playPromise = this.state.audioObject.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(function () { })
                    .catch(function (error) {
                        const isFirefox = typeof InstallTrigger !== "undefined";
                        if (isFirefox) {
                            swal(
                                "",
                                "Something went wrong with your browser. Please try another one.",
                                "error"
                            );
                        }
                    });
            }
        } else {
            if (this.state.audioObject) {
                this.state.audioObject.pause();
            }
            var apiParams = {
                map_url: map_url,
            };
            var headers = {
                "Content-Type": "application/json",
                "index-api-token": dashboardToken,
            };
            axios
                .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
                    headers: headers,
                })
                .then((response) => {
                    if (response.data) {
                        var url = response.data.data.signed_url || "";
                        var audio = new Audio(url);
                        var parentThis = this;
                        audio.addEventListener("ended", function () {
                            parentThis.setState({
                                productTypeLastPlayed: "",
                            });
                        });
                        audio.type = "audio/mpeg";
                        var newLatestLaunchAudio = {};
                        newLatestLaunchAudio[map_url] = url;
                        this.setState({
                            audioObject: audio,
                            productTypeAudio: newLatestLaunchAudio,
                            productTypeLastPlayed: map_url,
                            topSnovelLastPlayed: "",
                            latestLaunchLastPlayed: "",
                        });
                        if (this.state.audioPlayerObject) {
                            this.state.audioPlayerObject.pause();
                        }
                        var playPromise = audio.play();
                        if (playPromise !== undefined) {
                            playPromise
                                .then(function () { })
                                .catch(function (error) {
                                    const isFirefox = typeof InstallTrigger !== "undefined";
                                    if (isFirefox) {
                                        swal(
                                            "",
                                            "Something went wrong with your browser. Please try another one.",
                                            "error"
                                        );
                                    }
                                });
                        }
                    }
                })
                .catch((err) => {
                    // console.log("Error - ", err);
                });
        }
    }
    productTypePauseAudio(map_url) {
        this.state.audioObject.pause();
        this.setState({
            productTypeLastPlayed: "",
        });
    }
    productDetailsId(productID, slug) {
        localStorage.setItem('productId', productID);
        this.props.history.push('/product-details/' + productID + '/' + slug);
    }
    /********* End Product Type Play, Pause Audio **********/
    /******* Audio Book Tab content or products *********/
    audiobookAllProductsData() {
        return this.state.audiobookAllProducts.map((audiobookAllProduct, index) => {
            var authorArray = [];
            audiobookAllProduct.authors.map((authorName) => {
                if (
                    authorName.custom_translation[this.state.selectedLanguage] !==
                    undefined
                ) {
                    authorArray.push(
                        authorName.custom_translation[this.state.selectedLanguage].name
                    );
                }
            });
            return (
                <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
                    <div
                        className="videoThumbDiv"
                        id={"PopoverLast" + audiobookAllProduct.id}
                    >
                        {audiobookAllProduct.images ? audiobookAllProduct.images.length > 0 ? (
                            <Fragment>
                                {audiobookAllProduct.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                                <img
                                    onClick={() => {
                                        this.productDetailsId(audiobookAllProduct.id, audiobookAllProduct.product_slug)
                                    }}
                                    src={audiobookAllProduct.images[0]["image2"]}
                                    alt=""
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                {audiobookAllProduct.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                                <img
                                    onClick={() => {
                                        this.productDetailsId(audiobookAllProduct.id, audiobookAllProduct.product_slug)
                                    }}
                                    src="/images/video_thumb1.jpg"
                                    alt=""
                                />
                            </Fragment>
                        ) : ""}
                        <div className="sliderTrack_Wrap">
                            {audiobookAllProduct.sampletracks ? audiobookAllProduct.sampletracks.length > 0 && (
                                <div>
                                    {audiobookAllProduct.sampletracks[0].map_url !=
                                        this.state.productTypeLastPlayed && (
                                            <img
                                                onClick={() => {
                                                    this.productTypePlayAudio(
                                                        audiobookAllProduct.sampletracks[0].map_url
                                                    );
                                                }}
                                                src="/images/play.png"
                                            />
                                        )}
                                    {audiobookAllProduct.sampletracks[0].map_url ==
                                        this.state.productTypeLastPlayed && (
                                            <img
                                                onClick={() => {
                                                    this.productTypePauseAudio(
                                                        audiobookAllProduct.sampletracks[0].map_url
                                                    );
                                                }}
                                                src="/images/round-pause-button.svg"
                                            />
                                        )}
                                </div>
                            ) : ""}
                        </div>
                    </div>
                    <UncontrolledPopover
                        trigger="hover"
                        placement="right"
                        target={"PopoverLast" + audiobookAllProduct.id}
                    >
                        <PopoverBody>
                            <div className="threeDot">
                                <div className="treeDotTop">
                                    <h4>
                                        <span
                                            className="cursorPointer"
                                            onClick={() => {
                                                this.productDetailsId(
                                                    audiobookAllProduct.id,
                                                    audiobookAllProduct.product_slug
                                                );
                                            }}
                                        >
                                            {audiobookAllProduct.custom_translation[
                                                this.state.selectedLanguage
                                            ] != undefined &&
                                                audiobookAllProduct.custom_translation[
                                                    this.state.selectedLanguage
                                                ].name}
                                        </span>
                                    </h4>
                                    <span className="cateTitle">
                                        (As{" "}
                                        {audiobookAllProduct.product_audio_type === "audiobook" &&
                                            strings.audiobook}
                                        )
                                    </span>
                                </div>
                                {audiobookAllProduct.authors ? audiobookAllProduct.authors.length > 0 && (
                                    <div className="videoCnt">
                                        <div className="headingText">
                                            <span className="author">{strings.authorText}</span>
                                        </div>
                                        <div className="colon-symbol">:</div>
                                        <div className="loopdata">
                                            {this.authorLinkList(audiobookAllProduct.authors)}
                                        </div>
                                    </div>
                                ) : ""}
                                {audiobookAllProduct.directors.length > 0 && (
                                    <div className="videoCnt">
                                        <div className="headingText">
                                            <span className="author">{strings.directorText}</span>
                                        </div>
                                        <div className="colon-symbol">:</div>
                                        <div className="loopdata">
                                            <span className="authorDes">
                                                {this.directorsSelected(audiobookAllProduct.directors)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {audiobookAllProduct.artists ? audiobookAllProduct.artists.length > 0 && (
                                    <div className="videoCnt">
                                        <div className="headingText">
                                            <span className="author">{strings.artistsText}</span>
                                        </div>
                                        <div className="colon-symbol">:</div>
                                        <div className="loopdata">
                                            <span className="authorDes">
                                                {this.artistsSelected(audiobookAllProduct.artists)}
                                            </span>
                                        </div>
                                    </div>
                                ) : ""}
                                <div className="videoCnt browseGenre">
                                    <div className="headingText">
                                        <span className="author">{strings.genreText}</span>
                                    </div>
                                    <div className="colon-symbol">:</div>
                                    <div className="loopdata">
                                        {audiobookAllProduct.category.map((categoryList, index) => {
                                            if (
                                                categoryList.custom_translation[
                                                this.state.selectedLanguage
                                                ] !== undefined
                                            ) {
                                                return (
                                                    <NavLink
                                                        tag={Link}
                                                        onClick={() => {
                                                            this.props.dispatch({
                                                                type: "MENU_SELECTED_CATEGORY",
                                                                payload: [
                                                                    categoryList.name,
                                                                    audiobookAllProduct.product_audio_type,
                                                                    categoryList.id,
                                                                ],
                                                            });
                                                            this.setState({
                                                                innerActiveTab: "1",
                                                            });
                                                            this.props.dispatch(
                                                                browseCategoryProductDetailsDataAction(
                                                                    audiobookAllProduct.product_audio_type,
                                                                    categoryList.name,
                                                                    1
                                                                )
                                                            );
                                                        }}
                                                        key={index}
                                                    >
                                                        <span key={index}>
                                                            {
                                                                categoryList.custom_translation[
                                                                    this.state.selectedLanguage
                                                                ].name
                                                            }
                                                        </span>
                                                    </NavLink>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="videoCnt">
                                    <span className="author">{strings.durationText}</span>:
                                    <span className="authorDes brwHrs">
                                        {getHoursFormat(audiobookAllProduct.total_tracks_length) +
                                            " " +
                                            strings.hrsText}
                                    </span>
                                </div>
                                <div className="starRatingBrw">
                                    <StarRatings
                                        rating={audiobookAllProduct.total_reviews}
                                        starRatedColor="#ff8c00"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="20px"
                                    />
                                </div>
                                {!audiobookAllProduct.is_free && (
                                    <div className="videoPrice">
                                        <span>
                                            {this.state.isIndian !== null ?
                                                <Fragment>
                                                    {this.state.isIndian ? strings.priceSign + "" + audiobookAllProduct.inr_price.amount
                                                        : "$" + audiobookAllProduct.inr_price.amount}
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    {this.props.authData.userCountry === "IN"
                                                        ? strings.priceSign + "" + audiobookAllProduct.inr_price.amount
                                                        : "$" + audiobookAllProduct.price.amount}
                                                </Fragment>
                                            }
                                        </span>
                                    </div>
                                )}
                            </div>
                        </PopoverBody>
                    </UncontrolledPopover>
                </Col>
            );
        });
    }

    LatestLaunchAllData() {
        return this.state.latestLaunchData.map((audiobookAllProduct, index) => {
            return (
                <Col xs="6" md="4" lg="2" className="browse-space" key={index}>
                    <div
                        className="videoThumbDiv"
                        id={"PopoverLast" + audiobookAllProduct.id}
                    >
                        {audiobookAllProduct.images ? audiobookAllProduct.images.length > 0 ? (
                            <Fragment>
                                {audiobookAllProduct.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                                <img
                                    onClick={() => {
                                        this.productDetailsId(audiobookAllProduct.id, audiobookAllProduct.product_slug)
                                    }}
                                    src={audiobookAllProduct.images[0]["image2"]}
                                    alt=""
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                {audiobookAllProduct.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                                <img
                                    onClick={() => {
                                        this.productDetailsId(audiobookAllProduct.id, audiobookAllProduct.product_slug)
                                    }}
                                    src="/images/video_thumb1.jpg"
                                    alt=""
                                />
                            </Fragment>
                        ) : ""}
                        <div className="sliderTrack_Wrap">
                            {audiobookAllProduct.sampletracks ? audiobookAllProduct.sampletracks.length > 0 && (
                                <div>
                                    {audiobookAllProduct.sampletracks[0].map_url !=
                                        this.state.productTypeLastPlayed && (
                                            <img
                                                onClick={() => {
                                                    this.productTypePlayAudio(
                                                        audiobookAllProduct.sampletracks[0].map_url
                                                    );
                                                }}
                                                src="/images/play.png"
                                            />
                                        )}
                                    {audiobookAllProduct.sampletracks[0].map_url ==
                                        this.state.productTypeLastPlayed && (
                                            <img
                                                onClick={() => {
                                                    this.productTypePauseAudio(
                                                        audiobookAllProduct.sampletracks[0].map_url
                                                    );
                                                }}
                                                src="/images/round-pause-button.svg"
                                            />
                                        )}
                                </div>
                            ) : ""}
                        </div>
                    </div>
                    <UncontrolledPopover
                        trigger="hover"
                        placement="right"
                        target={"PopoverLast" + audiobookAllProduct.id}
                    >
                        <PopoverBody>
                            <div className="threeDot">
                                <div className="treeDotTop">
                                    <h4>
                                        <span
                                            className="cursorPointer"
                                            onClick={() => {
                                                this.productDetailsId(
                                                    audiobookAllProduct.id,
                                                    audiobookAllProduct.product_slug
                                                );
                                            }}
                                        >
                                            {audiobookAllProduct.custom_translation[
                                                this.state.selectedLanguage
                                            ] != undefined &&
                                                audiobookAllProduct.custom_translation[
                                                    this.state.selectedLanguage
                                                ].name}
                                        </span>
                                    </h4>
                                    {/* <span className="cateTitle">
                                        (As{" "}
                                        {audiobookAllProduct.product_audio_type === "audiobook" &&
                                            strings.audiobook}
                                        )
                                    </span> */}
                                </div>
                                {audiobookAllProduct.authors[0] ? audiobookAllProduct.authors[0].length > 0 && (
                                    <div className="videoCnt">
                                        <div className="headingText">
                                            <span className="author">{strings.authorText}</span>
                                        </div>
                                        <div className="colon-symbol">:</div>
                                        <div className="loopdata">
                                            {this.authorLinkList(audiobookAllProduct.authors[0])}
                                        </div>
                                    </div>
                                ) :
                                    ""
                                }
                                {audiobookAllProduct.directors ? audiobookAllProduct.directors.length > 0 && (
                                    <div className="videoCnt">
                                        <div className="headingText">
                                            <span className="author">{strings.directorText}</span>
                                        </div>
                                        <div className="colon-symbol">:</div>
                                        <div className="loopdata">
                                            <span className="authorDes">
                                                {this.directorsSelected(audiobookAllProduct.directors)}
                                            </span>
                                        </div>
                                    </div>
                                ) :
                                    ""
                                }
                                {audiobookAllProduct.directors ? audiobookAllProduct.artists.length > 0 && (
                                    <div className="videoCnt">
                                        <div className="headingText">
                                            <span className="author">{strings.artistsText}</span>
                                        </div>
                                        <div className="colon-symbol">:</div>
                                        <div className="loopdata">
                                            <span className="authorDes">
                                                {this.artistsSelected(audiobookAllProduct.artists)}
                                            </span>
                                        </div>
                                    </div>
                                ) :
                                    ""
                                }
                                <div className="videoCnt browseGenre">
                                    <div className="headingText">
                                        <span className="author">{strings.genreText}</span>
                                    </div>
                                    <div className="colon-symbol">:</div>
                                    <div className="loopdata">
                                        {audiobookAllProduct.category.map((categoryList, index) => {
                                            if (
                                                categoryList.custom_translation[
                                                this.state.selectedLanguage
                                                ] !== undefined
                                            ) {
                                                return (
                                                    <NavLink
                                                        tag={Link}
                                                        onClick={() => {
                                                            this.props.dispatch({
                                                                type: "MENU_SELECTED_CATEGORY",
                                                                payload: [
                                                                    categoryList.name,
                                                                    audiobookAllProduct.product_audio_type,
                                                                    categoryList.id,
                                                                ],
                                                            });
                                                            this.setState({
                                                                innerActiveTab: "1",
                                                            });
                                                            this.props.dispatch(
                                                                browseCategoryProductDetailsDataAction(
                                                                    audiobookAllProduct.product_audio_type,
                                                                    categoryList.name,
                                                                    1
                                                                )
                                                            );
                                                        }}
                                                        key={index}
                                                    >
                                                        <span key={index}>
                                                            {
                                                                categoryList.custom_translation[
                                                                    this.state.selectedLanguage
                                                                ].name
                                                            }
                                                        </span>
                                                    </NavLink>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="videoCnt">
                                    <span className="author">{strings.durationText}</span>:
                                    <span className="authorDes brwHrs">
                                        {getHoursFormat(audiobookAllProduct.total_tracks_length) +
                                            " " +
                                            strings.hrsText}
                                    </span>
                                </div>
                                <div className="starRatingBrw">
                                    <StarRatings
                                        rating={audiobookAllProduct.total_reviews}
                                        starRatedColor="#ff8c00"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="20px"
                                    />
                                </div>
                                {!audiobookAllProduct.is_free && (
                                    <div className="videoPrice">
                                        <span>
                                            {this.state.isIndian !== null ?
                                                <Fragment>
                                                    {this.state.isIndian ? strings.priceSign + "" + audiobookAllProduct.inr_price.amount
                                                        : "$" + audiobookAllProduct.inr_price.amount}
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    {this.props.authData.userCountry === "IN"
                                                        ? strings.priceSign + "" + audiobookAllProduct.inr_price.amount
                                                        : "$" + audiobookAllProduct.price.amount}
                                                </Fragment>
                                            }
                                        </span>
                                    </div>
                                )}
                            </div>
                        </PopoverBody>
                    </UncontrolledPopover>
                </Col>
            );
        });
    }



    render() {
        return (
            <div>
                {this.props.loader.isLoader && (
                    <div id="loading">
                        <div id="loading-center">
                            <div id="loading-center-absolute">
                                <img src="images/loader.gif" />
                            </div>
                        </div>
                    </div>
                )}

                <div className="browseType_Wrapper">
                    <div className="homeTabs">
                        {this.state.categoryName == "latest_launch" || this.state.categoryName == "popular_titles" || this.state.categoryName == "editors_pick" ?
                            <>
                                {this.state.latestLaunchData.length > 0 ?
                                    <div className="container">
                                        {Object.keys(this.state.latestLaunchData).length > 0 &&
                                            <div className="custom-breadcrumb-outer">
                                                <Breadcrumb>
                                                    <BreadcrumbItem>
                                                        <NavLink tag={Link} to="/family">
                                                            {strings.familyText}
                                                        </NavLink>
                                                    </BreadcrumbItem>
                                                    {this.state.categoryName == "latest_launch" ?
                                                        <BreadcrumbItem active>
                                                            {strings.latestLaunch}
                                                        </BreadcrumbItem>
                                                        :
                                                        ""
                                                    }
                                                    {this.state.categoryName == "popular_titles" ?
                                                        <BreadcrumbItem active>
                                                            {strings.popularTitles}
                                                        </BreadcrumbItem>
                                                        :
                                                        ""
                                                    }
                                                    {this.state.categoryName == "editors_pick" ?
                                                        <BreadcrumbItem active>
                                                            {strings.editorPicks}
                                                        </BreadcrumbItem>
                                                        :
                                                        ""
                                                    }

                                                </Breadcrumb>
                                            </div>}

                                        <TabContent
                                            activeTab={this.state.activeTab}
                                            className="audioBook_Wrapper"
                                        >
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col sm="12">
                                                        <TabContent activeTab={this.state.innerActiveTab}>
                                                            <TabPane tabId="1">
                                                                <div>
                                                                    <Row>{this.LatestLaunchAllData()}</Row>
                                                                </div>
                                                            </TabPane>

                                                        </TabContent>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                    :
                                    ""


                                }
                            </>
                            :
                            <div>
                                {this.state.audiobookAllProducts.length > 0 ?
                                    <div className="container">
                                        {Object.keys(this.state.audiobookAllProducts).length > 0 &&
                                            <div className="custom-breadcrumb-outer">
                                                <Breadcrumb>
                                                    <BreadcrumbItem>
                                                        <NavLink tag={Link} to="/family">
                                                            {strings.familyText}
                                                        </NavLink>
                                                    </BreadcrumbItem>
                                                    <BreadcrumbItem active>
                                                        {
                                                            this.state.headingText[this.state.selectedLanguage] ?
                                                                this.state.headingText[this.state.selectedLanguage].name
                                                                :
                                                                this.state.headingText["EN"].name
                                                        }

                                                    </BreadcrumbItem>
                                                </Breadcrumb>
                                            </div>}

                                        <TabContent
                                            activeTab={this.state.activeTab}
                                            className="audioBook_Wrapper"
                                        >
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col sm="12">
                                                        <TabContent activeTab={this.state.innerActiveTab}>
                                                            <TabPane tabId="1">
                                                                <div>
                                                                    <Row>{this.audiobookAllProductsData()}</Row>
                                                                </div>
                                                            </TabPane>

                                                        </TabContent>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                    :
                                    ""
                                }
                            </div>

                        }

                    </div>
                </div>
            </div>
        );
    }
}

function mapStatesToProps(state) {
    return {
        trendingProductsDetails: state.trendingProducts,
        selectedPostAndCategory: state.selectedPostAndCategory,
        browseCategoryProductDetailsData:
            state.browseCategoryProductDetailsData.browseCategoryProducts,
        loader: state.loader,
        authData: state.authonticationDetails,
        isIndian: state.isReisteredDevice.isIndian,
    };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(snovelFamilyCategoryProducts));
