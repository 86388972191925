import axios from "axios";
import { Callbacks } from "jquery";
import promise from "redux-promise-middleware";
import { api_base_url } from "../config";

export const categoryActions = {
  familyCategoryDetailsAction,
};
export function familyCategoryAction() {
  return function (dispatch) {
    var headers = {
      "Content-Type": "application/json",
      "index-api-token": "rb9jB8KSb4WQkHIFZ92r7GAUuVtcNgnQ",
    };

    dispatch({
      type: "SHOW_LOADER",
    });
    var apiParams = {
        menu_slug: "family"
      }
    axios
      .post(api_base_url + "/api/v1/menu/menu-details/", apiParams,{ headers: headers })
      .then((response) => {
        if (response) {
          // var updatedArray = [];
          var promises = [];
          response.data.menu_categories.forEach(
            (element) => {
                // console.log("element",element)
              var mainCategory = new Object();
              mainCategory["name"] = element["name"];
              mainCategory["id"] = element["id"];
              mainCategory["custom_translation"] =
              element["custom_translation"];
              mainCategory["product_type_value"] =
                element["product_type_value"];
              mainCategory["sliders"] = [];
              let categoryName = element["name"];
              let categoryProductType = element["product_type_value"];
              var output = new Promise((resolve, reject) => {
                var apiParamCatProd = {
                    browse_product: 2,
                    category: categoryName,
                    product_type: categoryProductType
                }
                axios
                  .post(
                    api_base_url +
                    "/api/v2/browse-snovel-products/",apiParamCatProd,
                    { headers: headers }
                  )
                  .then((response) => {
                    if (response["status"] === 200) {
                      mainCategory["sliders"] =
                      response.data.data.total_products[categoryProductType].all_product;
                      resolve(mainCategory);
                      // console.log(mainCategory)
                    }
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
              promises.push(output);
            }
          );
          Promise.all(promises).then((result) => {
            dispatch({
              type: "FAMILY_CATEGORY_LIST",
              payload: result,
            });
            dispatch({
              type: "HIDE_LOADER",
            });
          });
        } else {
          dispatch({
            type: "FAILED_FAMILY_CATEGORY_LIST",
            payload: [],
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
        dispatch({
          type: "HIDE_LOADER",
        });
      });
  };
}


function familyCategoryDetailsAction(categoryID, callbacks) {
  var headers = {
    "Content-Type": "application/json",
    "index-api-token": "rb9jB8KSb4WQkHIFZ92r7GAUuVtcNgnQ",
  };
  return function (dispatch) {
    dispatch({
      type: "SHOW_LOADER",
    });
    var apiParams = {
      menu_slug: "family"
    }
    axios
    .post(api_base_url + "/api/v1/menu/menu-details/", apiParams,{ headers: headers })
    .then((response) => {
        dispatch({
          type: "HIDE_LOADER",
        });
        callbacks(response["data"], null);
      })
      .catch((err) => {
        dispatch({
          type: "HIDE_LOADER",
        });
        callbacks(null, err);
        // console.log(err);
      });
  };
}

