import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import swal from 'sweetalert';
import { api_base_url, dashboardToken } from '../config';
import {
    Row,
    Col
} from "reactstrap";
import { categoryActions } from "../action/kidsHomeAction";
import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
var strings = new LocalizedStrings(languages);
class ShowLanguageLabCategoryProducts extends Component {
    constructor(props) {
        super(props);
        var getCategoryId = localStorage.getItem("categoryID");
        var getCategoryName = localStorage.getItem('categoryName');
        var parseResult = JSON.parse(getCategoryName);
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
        }
        this.state = {
            categoryID: getCategoryId || "",
            categoryList: [],
            kidsPopularAudio: {},
            kidsPopularLastPlayed: '',
            audioObject: undefined,
            kidsCategoryLastPlayed: '',
            selectedLanguage: selectedLanguage || "EN",
            categoryTitle: {}

        }
    }
    componentWillReceiveProps(nextProps, prevState) {
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
            this.setState({
                selectedLanguage: selectedLanguage,
            });
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
            this.setState({
                selectedLanguage: "EN",
            });
        }

        if (nextProps.isPauseInnerProduct === true && this.state.audioObject) {
            this.state.audioObject.pause();
            this.setState({
                kidsPopularLastPlayed: ''
            });
            this.props.onPauseSample('innerProduct');
        }
    }
    componentWillUnmount() {
        if (this.state.audioObject) {
            this.state.audioObject.pause();
        }
    }
    componentDidMount() {
        this.props.dispatch(categoryActions.languageLabCategoryDetailsAction(this.props.match.params.id, (res, err) => {
            if (res['category_product_list'].length > 0) {
                this.setState({
                    categoryList: res['category_product_list'],
                    categoryTitle: res['category_details']
                })
            } else {
                // console.log("Something went wrong");
            }
        }));
    }


    kidsCategoryLaunchPlayAudio(map_url) {
        if (this.state.kidsPopularAudio[map_url]) {
            this.setState({
                kidsCategoryLastPlayed: map_url,
            });

            var playPromise = this.state.audioObject.play();
            if (playPromise !== undefined) {
                playPromise.then(function () { }).catch(function (error) {
                    const isFirefox = typeof InstallTrigger !== 'undefined';
                    if (isFirefox) {
                        swal("", "Something went wrong with your browser. Please try another one.", "error");
                    }
                });
            }
        } else {
            if (this.state.audioObject) {
                this.state.audioObject.pause();
            }
            var apiParams = {
                map_url: map_url
            };
            var headers = {
                'Content-Type': 'application/json',
                'index-api-token': dashboardToken
            };
            axios.post(api_base_url + '/api/get-sample-signed-url/', apiParams, { headers: headers }).then(response => {
                if (response.data) {
                    var url = response.data.data.signed_url || "";
                    var audio = new Audio(url);
                    var parentThis = this;
                    audio.addEventListener("ended", function () {
                        parentThis.setState({
                            kidsCategoryLastPlayed: ''
                        });
                    });
                    audio.type = 'audio/mpeg';
                    var newLatestLaunchAudio = {};
                    newLatestLaunchAudio[map_url] = url;
                    this.setState({
                        audioObject: audio,
                        kidsPopularAudio: newLatestLaunchAudio,
                        kidsCategoryLastPlayed: map_url,
                        kidsPopularLastPlayed: ''
                    });
                    // this.props.onPlaySample('innerProduct');
                    var playPromise = audio.play();
                    if (playPromise !== undefined) {
                        playPromise.then(function () { }).catch(function (error) {
                            const isFirefox = typeof InstallTrigger !== 'undefined';
                            if (isFirefox) {
                                swal("", "Something went wrong with your browser. Please try another one.", "error");
                            }
                        });
                    }
                }
            }).catch(err => {
                // console.log('Error - ', err);
            });
        }
    }

    kidsCategoryLaunchPauseAudio(map_url) {
        this.state.audioObject.pause();
        this.setState({
            kidsCategoryLastPlayed: ''
        });
    }
    productDetailsId(productID, slug) {
        localStorage.setItem('productId', productID);
        this.props.history.push('/product-details/' + productID + '/' + slug);
    }

    render() {
        return (
            <div>
                <div className="container">
                    {Object.keys(this.state.categoryTitle).length > 0 &&
                        <div className="mainCategory-Name">{this.state.categoryTitle.custom_translations[this.state.selectedLanguage].name}</div>
                    }
                    {this.state.categoryList.length > 0 &&
                        <Row className="kidsCat-wrapper">
                            {this.state.categoryList.map((catlist, catIndex) => {
                                return <Col xs="6" md="4" lg="2" key={catIndex} className="owl-item">
                                    {catlist.images.length > 0 ? <div className="sliderThumb">{catlist.is_free && <span className="freeProduct">{strings.FreeText}</span>}<img src={catlist.images[0]["image2"]} className="sampleProduct_thumb" onClick={() => { this.productDetailsId(catlist.id, catlist.product_slug) }} /></div> : <div className="sliderThumb">{catlist.is_free && <span className="freeProduct">{strings.FreeText}</span>}<img src="/images/default.jpg" className="sampleProduct_thumb" onClick={() => { this.productDetailsId(catlist.id, catlist.product_slug) }} /></div>}
                                    <div className="sliderTrack_Wrap">
                                        {catlist.sampletracks.length > 0 &&
                                            <div>
                                                {catlist.sampletracks[0].map_url != this.state.kidsCategoryLastPlayed &&
                                                    <img onClick={() => { this.kidsCategoryLaunchPlayAudio(catlist.sampletracks[0].map_url) }} src="/images/play.png" />
                                                }
                                                {catlist.sampletracks[0].map_url == this.state.kidsCategoryLastPlayed &&
                                                    <img onClick={() => { this.kidsCategoryLaunchPauseAudio(catlist.sampletracks[0].map_url); }} src="/images/round-pause-button.svg" />
                                                }
                                            </div>
                                        }
                                    </div>
                                </Col>
                            })}
                        </Row>
                    }
                </div>
            </div>
        );
    }
}
function mapStatesToProps(state) {
    return {
        languageTab: state.languageTab,
    };
}
const myConnector = connect(mapStatesToProps);
export default myConnector(ShowLanguageLabCategoryProducts);