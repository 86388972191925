import React, { Component } from "react";
import ReactGA from "react-ga";
import axios from "axios";
import {
  api_base_url,
  googleAnalyticsTrackingId,
  aboutusTestimonial,
  encryptDecryptKey,
} from "../../config";
import { Row, Col, NavLink } from "reactstrap";
import LocalizedStrings from "react-localization";
import SimpleCrypto from "simple-crypto-js";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { productMediaNews } from "../../action/notificationAction";
import languages from "../../language/language.json";
// TODO
import { Player } from "video-react";
import "video-react/dist/video-react.css";

var strings = new LocalizedStrings(languages);
ReactGA.initialize(googleAnalyticsTrackingId);

class aboutUs extends Component {
  constructor(props) {
    super(props);
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    this.state = {
      isLoading: false,
      testimonials: [],
      selectedLanguage: selectedLanguage || "EN",
      mediaNews: [],
      mediaNewModel: false,
      newsModelData: {},
      token: token || "",
      monthShortName: {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sept",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      },
    };
    this.closeCartProdcutModel = this.closeCartProdcutModel.bind(this);
    ReactGA.pageview(this.props.location.pathname);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    var headers = {
      "Content-Type": "application/json",
      "index-api-token": aboutusTestimonial,
    };
    axios
      .get(api_base_url + "/api/testimonials/10/", { headers: headers })
      .then((response) => {
        if (response.data.status === true) {
          this.setState({
            testimonials: response.data.data,
          });
        }
      })
      .catch((err) => {
        // console.log("Error - ", err);
      });
  }
  componentWillMount() {
    this.props.dispatch(productMediaNews(this.state.token));
  }
  componentWillReceiveProps(nextProps) {
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
    if (this.props.mediaNews !== nextProps.mediaNews.length > 0) {
      this.setState({
        mediaNews: nextProps.mediaNews,
      });
    }
  }
  dispalyAllTestimonials() {
    return this.state.testimonials.map((comment, index) => {
      return (
        <Col sm="6">
          <div className={"firstComment one" + index}>
            {comment.custom_translations[this.state.selectedLanguage] && (
              <div
                className="happy-user-comment"
                dangerouslySetInnerHTML={{
                  __html:
                    comment.custom_translations[this.state.selectedLanguage][
                    "comments"
                    ],
                }}
              ></div>
            )}
            <span>
              <strong>
                {comment.custom_translations[this.state.selectedLanguage] &&
                  comment.custom_translations[this.state.selectedLanguage].user}
              </strong>{" "}
            </span>
          </div>
        </Col>
      );
    });
  }

  numberSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  subscriptionDateString(dateString) {
    var dateStringArray = dateString.split("T");
    var newDate = dateStringArray[0];
    var dateStringArray = newDate.split("-");
    var newYear = dateStringArray[0];
    var newMonth = parseInt(dateStringArray[1]) + "";
    var newDay = parseInt(dateStringArray[2]);
    var newString =
      this.numberSuffix(newDay) +
      " " +
      this.state.monthShortName[newMonth] +
      " " +
      newYear;
    return newString;
  }

  mediaNewsFunction() {
    return this.state.mediaNews.map((medianews, index) => {
      return (
        <div className="mediaNewsLft">
          <div
            className="mediaNewsTitle"
            onClick={() => {
              this.showMediaNewModel(medianews, index);
            }}
          >
            {medianews.title}
          </div>
          <p dangerouslySetInnerHTML={{ __html: medianews.description }}></p>
          <div className="mediaNewsDate">
            {this.subscriptionDateString(medianews.created_date)}
          </div>
        </div>
      );
    });
  }
  showMediaNewModel(medianews, selectedNews) {
    this.setState({
      mediaNewModel: true,
      newsModelData: medianews,
    });
  }
  showMediaNewsDataModel(medianewsData, selectedNews) {
    return <img src={this.state.newsModelData.image} />;
  }
  closeCartProdcutModel() {
    this.setState({
      mediaNewModel: false,
    });
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        ) : null}

        {/* <div className="aboutTopWrp">
          <div className="container">
            <div className="">
              <h1>{strings.AudioExperience}</h1>
              <h3>{strings.postTpyes}</h3>
              <p>{strings.AboutUsContent}</p>
            </div>
          </div>
        </div> */}
        {/* <div className="aboutUsmiddlewrp">
          <div className="container">
           
            <div className="text-center">
              <div className="aboutUs-videoWrap">
                <Player
                  poster="/images/about-video.png"
                  src="/images/IMG_4190.mp4"
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="container" >
          <img src="/images/SNOVEL_Abt us_A-02.jpg" alt=""

            style={{ width: "100%", height: "350px" }}
          />
        </div>
        <div className="container mt-4">
          <div className="happyUsers">
            <h3>
              {strings.Happyusers}
              <img src="images/happy_users.png" alt="" />
            </h3>
            <div className="happyUserComment">
              <Row>{this.dispalyAllTestimonials()}</Row>
            </div>
          </div>
          <hr />
          <div className="mediaSection-wrapper">
            <div className="alertTopDiv">
              <h5>Media news</h5>
              <div className="accolades_news">
                {this.state.mediaNews.length > 0
                  ? this.mediaNewsFunction()
                  : "Data Not Found"}
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.mediaNewModel}
          className={"news-notification-modal"}
        >
          <ModalHeader>
            <button
              type="button"
              class="close closeReview"
              data-dismiss="modal"
              onClick={this.closeCartProdcutModel}
            >
              &times;
            </button>
          </ModalHeader>
          <ModalBody>
            {typeof Object.keys(this.state.newsModelData) !== undefined &&
              Object.keys(this.state.newsModelData).length > 0 &&
              this.showMediaNewsDataModel()}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    promotionalNotification:
      state.productNotificationReducer.promotionalNotification,
    publishNotification: state.productNotificationReducer.publishNotification,
    mediaNews: state.productNotificationReducer.mediaNews,
  };
}

const myConnector = connect(mapStatesToProps);
export default myConnector(aboutUs);
