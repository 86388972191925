import React, { Component } from "react";
import ReactGA from "react-ga";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { userAuthentication } from "../action/loginAction";
import { kidsCategoryEbookAction } from "../action/kidsHomeAction";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { Route, Link, withRouter } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import {
  api_base_url,
  loginGuestToken,
  facebookAppId,
  googleClientId,
  encryptDecryptKey,
  googleAnalyticsTrackingId,
  newMobileRegularExpression,
  countryList,
  mobileLoginGuestToken,
} from "../config";
import axios from "axios";
import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class signInComponant extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (userDetailsObject.loginSuccess) {
        this.props.history.push("/");
      }
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      loginEmail: "",
      loginEmailError: "",
      loginPassword: "",
      forgetPassword: "",
      forgetPasswordError: "",
      successMessage: "",
      loginPasswordError: "",
      socialLoginError: "",
      isLoading: false,
      ForgetPasswordModal: false,
      selectedLanguage: selectedLanguage || "EN",
      clientIpAdrees: "",
      redirect: false,
      enterValidEmailError: "",
      loginPasswordChnage: "",
      mobilenumber: "",
      otp: ["", "", "", "", "", ""],
      loginWithOtpModal: false,
      showOtpScreen: false,
      mobileNumberError: "",
      otpError: "",
      selectedCountry: "+91",
      isDropdownOpen: false,
      otpDigits: ["", "", "", "", "", "",],
    };
    this.inputRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
    this.handleOnchangeEmail = this.handleOnchangeEmail.bind(this);
    this.handleOnchangePassword = this.handleOnchangePassword.bind(this);
    this.onSubmitLogin = this.onSubmitLogin.bind(this);
    this.facebookCustomButton = this.facebookCustomButton.bind(this);
    this.forgetPasswordToggle = this.forgetPasswordToggle.bind(this);
    this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this);
    this.handleOnchangeResetPassword = this.handleOnchangeResetPassword.bind(
      this
    );
    this.closePasswordModel = this.closePasswordModel.bind(this);
    this.loginWithOtpToggle = this.loginWithOtpToggle.bind(this);
    this.onSubmitSendOtp = this.onSubmitSendOtp.bind(this);
    this.onSubmitLoginWithOtp = this.onSubmitLoginWithOtp.bind(this);
    this.handleOnChangeMobileNumber = this.handleOnChangeMobileNumber.bind(this);
    this.handleOnchangeOtp = this.handleOnchangeOtp.bind(this);
    this.handleSelectCountry = this.handleSelectCountry.bind(this)
    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.clientIpAddress();
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }
  componentWillReceiveProps(nextProps) {
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }

  componentWillUnmount() {
    let auditionFormStatus = localStorage.getItem("auditionForm");
    if (auditionFormStatus !== "" && auditionFormStatus == "true") {
      localStorage.removeItem("auditionForm");
    }
  }

  facebookCustomButton() {
    return (
      <span>
        <img src="images/icon_facebook.png" alt="Facebook" />
        <span>{strings.loginWithFacebook}</span>
      </span>
    );
  }

  forgetPasswordToggle() {
    this.setState({
      ForgetPasswordModal: !this.state.ForgetPasswordModal,
    });
  }

  loginWithOtpToggle() {
    this.setState({
      loginWithOtpModal: !this.state.loginWithOtpModal,
    });
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));
  }


  handleOnchangeEmail(event) {
    this.setState({
      loginEmail: event.target.value,
      forgetPassword: event.target.value,
    });
  }

  handleOnchangeResetPassword(event) {
    this.setState({
      forgetPassword: event.target.value,
    });
  }
  handleOnchangePassword(event) {
    this.setState({
      loginPassword: event.target.value,
    });
  }

  handleOnChangeMobileNumber(event) {
    let val = event.target.value + ""
    let maxLength = 10 // <===
    let newValue = val.length < maxLength ? val : val.toString().substring(0, maxLength);
    this.setState({ mobilenumber: newValue });
  }


  handleOnchangeOtp(event, index) {
    const value = event.target.value;
    if (isNaN(value)) {
      return;
    }
    const otpCopy = [...this.state.otp];
    otpCopy[index] = value;
    this.setState({
      otp: otpCopy,
    });
    if (value !== "") {
      if (index === this.state.otp.length - 1) {
        this.inputRefs[index].current.blur();
      } else {
        this.inputRefs[index + 1].current.focus();
      }
    }
  }
  handleOnKeyDown = (event, index) => {
    if (event.key === "Backspace" && this.state.otp[index] === "") {
      if (index === 0) {
        return;
      }
      this.inputRefs[index - 1].current.focus();
    }
  };

  handleSelectCountry = (event) => {
    this.setState({
      selectedCountry: event.target.value,
      isDropdownOpen: false
    });
  }

  cartProductsCount(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/cart/", { headers: headers })
      .then((response) => {
        if (response.data.status === true) {
          this.props.dispatch({
            type: "PRODUCT_CART_COUNT",
            payload: response.data.data,
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  onSubmitLogin(event) {
    event.preventDefault();
    this.setState({
      loginEmailError: "",
      loginPasswordError: "",
      enterValidEmailError: "",
      loginPasswordChnage: "",
    });
    var isValid = false;
    if (this.state.loginEmail === "") {
      this.setState({
        loginEmailError: strings.enterEmail,
      });
      isValid = false;
    } else if (emailReg.test(this.state.loginEmail) === false) {
      this.setState({
        loginEmailError: strings.enterValidEmail,
      });
      isValid = false;
    }
    if (this.state.loginPassword === "") {
      this.setState({
        loginPasswordError: strings.enterPassword,
      });
    } else {
      isValid = true;
    }
    if (isValid) {
      this.setState({
        isLoading: true,
      });
      var apiParams = {
        login_type: "normal",
        email: this.state.loginEmail,
        password: this.state.loginPassword,
        first_name: "",
        last_name: "",
        customer_ip_address: this.state.clientIpAdrees,
      };
      var headers = {
        "Content-Type": "application/json",
        "guest-login-token": loginGuestToken,
      };
      axios
        .post(api_base_url + "/api/v1/login/", apiParams, { headers: headers })
        .then((response) => {
          this.setState({
            isLoading: false,
          });
          if (response.data["status"] === "1") {
            var apiParams = {
              name: "",
              type: "web",
              device_id: this.state.clientIpAdrees,
              registration_id: this.state.clientIpAdrees,
              token: response.data["token"],
            };
            // axios
            //   .post(api_base_url + "/api/register-device/", apiParams)
            //   .then((response) => { })
            //   .catch((err) => {
            //     // console.log("Error - ", err);
            //   });
            let auditionFormStatus = localStorage.getItem("auditionForm");
            if (auditionFormStatus !== "" && auditionFormStatus == "true") {
              let parentThis = this;
              setTimeout(function () {
                parentThis.props.history.push("/audition");
              }, 300);
              localStorage.removeItem("auditionForm");


            } else {
              this.props.history.push("/language-lab");
              localStorage.removeItem("auditionForm");
            }
            this.cartProductsCount(response.data["token"]);
            this.props.dispatch(kidsCategoryEbookAction(response.data["token"]));

            // this.props.history.push("/");
            return this.props.dispatch(
              userAuthentication(
                this.state.loginEmail,
                response.data["token"],
                response.data["is_subscribed"],
                response.data["first_name"],
                response.data["last_name"],
                response.data["profile_image"],
                response.data["country_code"],
                response.data["state"],
                response.data["mobile"],
                response.data["instituteName"],
                "false"
              )
            );

          } else if (
            response.data["status"] === "2" ||
            response.data["status"] === "11"
          ) {
            this.setState({
              loginPasswordError: response.data["message"],
            });

          } else if (response.data["status"] === "10") {
            this.setState({
              enterValidEmailError: response.data["message"]["email"][0],
            });
          } else if (response.data["status"] === "5") {
            this.setState({
              loginPasswordChnage: response.data["message"],
            });
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  closePasswordModel() {
    this.setState({
      ForgetPasswordModal: false,
    });
  }

  onSubmitSendOtp(e) {
    e.preventDefault();
    this.setState({
      mobileNumberError: ""

    });
    var apiParams = {
      mobile_number: this.state.mobilenumber,
    };
    var headers = {
      "Content-Type": "application/json",
      "GUEST-API-TOKEN": mobileLoginGuestToken,
    };
    var isValid = false;
    if (!this.state.mobilenumber || !newMobileRegularExpression.test(this.state.mobilenumber)
    ) {
      this.setState({
        mobileNumberError: strings.ValidNumber,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    if (isValid) {
      axios
        .post(api_base_url + "/api/otp/send-otp/", apiParams, { headers: headers })
        .then((response) => {
          if (response.data['message'] == 'success') {
            this.setState({
              showOtpScreen: true,
            });

          }
        })
        .catch((error) => {
          // Error
          switch (error.response.status) {
            case 403:
              //handle route to the page
              this.setState({
                mobileNumberError: strings.ValidNumber,
              });
            default:
              break
          }
        });


    }
  }

  onSubmitLoginWithOtp(e) {
    e.preventDefault();
    var apiParams = {
      mobile_number: this.state.mobilenumber,
      device_type: "web",
      otp: (this.state.otp).join(""),
    };
    var headers = {
      "Content-Type": "application/json",
      "GUEST-API-TOKEN": mobileLoginGuestToken,
    };
    this.setState({
      otpError: ""

    });

    if (this.state.otp[0] !== "" && this.state.otp[1] !== "" && this.state.otp[2] !== "" && this.state.otp[3] !== "" &&
      this.state.otp[4] !== "" && this.state.otp[5] !== "") {
      // this.setState({
      //   isLoading: true,
      // });
      axios
        .post(api_base_url + "/api/otp/verify-otp/", apiParams, { headers: headers })
        .then((response) => {
          this.setState({
            isLoading: false,
          });
          // console.log(response.status);
          if (response.status == 200) {
            this.setState({
              showOtpScreen: false,
            });
            this.cartProductsCount(response.data["token"]);
            this.props.dispatch(kidsCategoryEbookAction(response.data["token"]));


            this.props.dispatch(
              userAuthentication(
                "",
                response.data["token"],
                response.data["is_subscribed"],
                response.data["first_name"],
                response.data["last_name"],
                response.data["profile_image"],
                response.data["country_code"],
                response.data["state"],
                response.data["mobile"],
                response.data["instituteName"],
                "true"
              )
            );
            if (response.data["is_new_user"] == true) {
              // this.props.history.push(`/sign-up/${response.data["token"]}/#${response.data["is_new_user"]}`)
              this.props.history.push({
                pathname: "/library",
                state: { is_new_user: response.data["is_new_user"] }
              })

            } else {
              this.props.history.push("/")
            }

          } else if (response.status == 403) {
            // console.log(response);
            this.setState({
              otpError: strings.validOtp,
            });
          }
          // console.log(response);
        }).catch((error) => {
          this.setState({
            otpError: strings.validOtp,
          });
        })
      // this.props.history.push("/audio-courses");

    } else {
      this.setState({
        otpError: strings.validOtp,
      });
    }
  }

  handleChange = (e) => {

    const val = e.target.value

    const max = 1000

    const maxLength = max.toString().length - 1

    const newVal = val < max ? val : parseInt(val.toString().substring(0, maxLength))

    this.setState({ mobilenumber: newVal });

  }

  onSubmitResetPassword(e) {
    e.preventDefault();
    this.setState({
      forgetPasswordError: "",
      successMessage: "",
    });
    var isValid = false;
    var isValidEmailAddress = false;
    if (this.state.forgetPassword === "") {
      this.setState({
        forgetPasswordError: strings.enterEmail,
      });
    } else if (emailReg.test(this.state.forgetPassword) === false) {
      this.setState({
        forgetPasswordError: strings.enterValidEmail,
      });
    } else {
      isValid = true;
    }
    if (isValid) {
      var apiParams = {
        email: this.state.forgetPassword,
      };
      axios
        .post(api_base_url + "/api/reset-password/", apiParams)
        .then((response) => {
          if (response.data.status === true) {
            this.setState({
              successMessage:
                "Your request has been proceed successfully, Please check your email address for reference.",
              forgetPassword: "",
            });
            setTimeout(() => {
              this.closePasswordModel();
            }, 3000);
          }
          if (response.data.status === false) {
            this.setState({
              forgetPasswordError: response.data.message[0],
              forgetPassword: "",
            });
            setTimeout(() => {
              this.closePasswordModel();
            }, 3000);
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
          this.setState({
            isLoading: false,
          });
        });
    }
  }
  clientIpAddress() {
    axios
      .get("http://jsonip.com/")
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            clientIpAdrees: response.data.ip,
          });
        }
      })
      .catch((err) => {
        // console.log("Error - ", err);
      });
  }

  render() {
    const componentClicked = () => {
      this.setState({
        socialLoginError: "",
      });
    };
    const responseSuccessGoogle = (googleResponse) => {
      if (googleResponse["profileObj"] && googleResponse["accessToken"]) {
        this.setState({
          isLoading: true,
        });
        var apiParams = {
          login_type: "google",
          email: googleResponse["profileObj"]["email"] || "",
          uid: googleResponse["profileObj"]["googleId"] || "",
          access_token: googleResponse["accessToken"] || "",
          customer_ip_address: this.state.clientIpAdrees,
          extra_data: googleResponse || {},
        };
        var headers = {
          "Content-Type": "application/json",
          "guest-login-token": loginGuestToken,
        };
        axios
          .post(api_base_url + "/api/v1/login/", apiParams, {
            headers: headers,
          })
          .then((response) => {
            this.setState({
              isLoading: false,
            });
            if (response.data["status"] === "1") {
              var apiParams = {
                name: "",
                type: "web",
                device_id: this.state.clientIpAdrees,
                registration_id: this.state.clientIpAdrees,
                token: response.data["token"],
              };
              axios
                .post(api_base_url + "/api/register-device/", apiParams)
                .then((response) => { })
                .catch((err) => {
                  // console.log("Error - ", err);
                });

              this.cartProductsCount(response.data["token"]);
              let auditionFormStatus = localStorage.getItem("auditionForm");
              if (auditionFormStatus !== "" && auditionFormStatus == "true") {
                let parentThis = this;
                setTimeout(function () {
                  parentThis.props.history.push("/audition");
                }, 300);
                localStorage.removeItem("auditionForm");

              } else {
                this.props.history.push("/");
                localStorage.removeItem("auditionForm");
              }

              return this.props.dispatch(
                userAuthentication(
                  googleResponse["profileObj"]["email"] || "",
                  response.data["token"],
                  response.data["is_subscribed"],
                  response.data["first_name"],
                  response.data["last_name"],
                  response.data["profile_image"],
                  response.data["country_code"],
                  response.data["state"],
                  response.data["mobile"],
                  response.data["instituteName"],
                  "false"
                )
              );
            } else if (
              response.data["status"] === "2" ||
              response.data["status"] === "10" ||
              response.data["status"] === "11"
            ) {
              this.setState({
                socialLoginError: response.data["message"],
              });
            }
          })
          .catch((err) => {
            // console.log("Error - ", err);
            this.setState({
              isLoading: false,
            });
          });
      }
    };
    const responseFailureGoogle = (error) => {
      // console.log("Google Error", error);
    };
    const responseFacebook = (socialLoginResponse) => {
      if (socialLoginResponse["email"] != undefined) {
        this.setState({
          isLoading: true,
        });
        var apiParams = {
          login_type: "facebook",
          email: socialLoginResponse["email"] || "",
          uid: socialLoginResponse["userID"] || "",
          access_token: socialLoginResponse["accessToken"] || "",
          customer_ip_address: this.state.clientIpAdrees,
          extra_data: socialLoginResponse || {},
        };
        var headers = {
          "Content-Type": "application/json",
          "guest-login-token": loginGuestToken,
        };
        axios
          .post(api_base_url + "/api/v1/login/", apiParams, {
            headers: headers,
          })
          .then((response) => {
            this.setState({
              isLoading: false,
            });
            if (response.data["status"] === "1") {
              var apiParams = {
                name: "",
                type: "web",
                device_id: this.state.clientIpAdrees,
                registration_id: this.state.clientIpAdrees,
                token: response.data["token"],
              };
              axios
                .post(api_base_url + "/api/register-device/", apiParams)
                .then((response) => { })
                .catch((err) => {
                  // console.log("Error - ", err);
                });
              this.cartProductsCount(response.data["token"]);
              let auditionFormStatus = localStorage.getItem("auditionForm");
              if (auditionFormStatus !== "" && auditionFormStatus == "true") {
                let parentThis = this;
                setTimeout(function () {
                  parentThis.props.history.push("/audition");
                }, 300);
                localStorage.removeItem("auditionForm");

              } else {
                this.props.history.push("/");
                localStorage.removeItem("auditionForm");
              }

              return this.props.dispatch(
                userAuthentication(
                  socialLoginResponse["email"] || "",
                  response.data["token"],
                  response.data["is_subscribed"],
                  response.data["first_name"],
                  response.data["last_name"],
                  response.data["profile_image"],
                  response.data["country_code"],
                  response.data["state"],
                  response.data["mobile"],
                  response.data["instituteName"],
                  "false"
                )
              );
            } else if (
              response.data["status"] === "2" ||
              response.data["status"] === "10" ||
              response.data["status"] === "11"
            ) {
              this.setState({
                socialLoginError: response.data["message"],
              });
            }
          })
          .catch((err) => {
            // console.log("Error - ", err);
            this.setState({
              isLoading: false,
            });
          });
      }
    };
    return (
      <div>
        {this.state.isLoading ? (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        ) : null}

        <div class="clearfix"></div>
        <div className="container">
          <div className="LoginForm_Wrapper welComeWrp">
            <div class="welcomeBack">
              <h1>{strings.welcomeText}</h1>
              <Form onSubmit={this.onSubmitLogin}>
                <FormGroup>
                  <Input
                    type="text"
                    name="email"
                    value={this.state.loginEmail}
                    id="loginEmail"
                    placeholder={strings.emailText}
                    onChange={this.handleOnchangeEmail}
                  />
                </FormGroup>
                {this.state.enterValidEmailError.length > 0 ? (
                  <div class="loginErrorMsg">
                    {this.state.enterValidEmailError}
                  </div>
                ) : (
                  <div class="loginErrorMsg">{this.state.loginEmailError}</div>
                )}

                <FormGroup>
                  <Input
                    type="password"
                    name="password"
                    value={this.state.loginPassword}
                    id="loginPassword"
                    placeholder={strings.passwordText}
                    onChange={this.handleOnchangePassword}
                  />
                </FormGroup>
                <div class="loginErrorMsg">{this.state.loginPasswordError}</div>
                <div class="loginErrorMsg green">
                  {this.state.loginPasswordChnage}
                </div>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> {strings.rememberMe}
                  </Label>
                </FormGroup>
                <Button type="submit">{strings.signInSmall}</Button>
              </Form>
              <div class="orWith">{strings.orWithText}</div>
              <Form>
                <div
                  class="loginWithOtp"
                >
                  <Button className="loginwithotpbtn" onClick={this.loginWithOtpToggle}>{strings.loginWithMobile}</Button>
                </div>
              </Form>

              <div class="faceLogin">
                <FacebookLogin
                  appId={facebookAppId}
                  fields="name,email,picture"
                  onClick={componentClicked}
                  textButton={this.facebookCustomButton()}
                  callback={responseFacebook}
                />
              </div>
              <div class="googleLogin">
                <GoogleLogin
                  clientId={googleClientId}
                  render={(renderProps) => (
                    <button
                      class="google-login-button"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <img src="images/google-glass-logo.png" alt="Gmail" />{" "}
                      <span>{strings.loginWithGmail}</span>
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={responseSuccessGoogle}
                  onFailure={responseFailureGoogle}
                  cookiePolicy={"single_host_origin"}
                />
              </div>
            </div>
            <div class="loginErrorMsg">{this.state.socialLoginError}</div>
            <div class="forGotPass">
              <span onClick={this.forgetPasswordToggle}>
                {strings.forgotPassword}?
              </span>
            </div>
            <div class="forGotPass forGotRight">
              {strings.newToSnovel}?
              <NavLink
                tag={Link}
                onClick={() => {
                  this.props.dispatch({
                    type: "SIGNUP_SELECTED_MODEL",
                    payload: true,
                  });
                }}
              >
                {strings.signUpSmall}
              </NavLink>
            </div>
          </div>
        </div>
        <div className="subBenefit_Wrapeer">
          <div className="container">
            <Row>
              <Col xs="12" md="12" lg="4">
                <h2>{strings.benefitOfOneHead}</h2>
                <p>{strings.benefitOfOne}</p>
              </Col>
              <Col xs="12" md="12" lg="4" className="subsCriptionMid">
                <h2>{strings.benefitOfTwoHead}</h2>
                <p>{strings.benefitOfTwo}</p>
              </Col>
              <Col xs="12" md="12" lg="4">
                <h2>{strings.benefitOfThreeHead}</h2>
                <p>{strings.benefitOfThree}</p>
              </Col>
            </Row>
            <div class="subsCriBtnBtm">
              <span class="subsCribeButton">
                <a href="/subscribe">{strings.subscribeNow}</a>
              </span>
            </div>
          </div>
        </div>
        <div>
          <Modal
            isOpen={this.state.ForgetPasswordModal}
            toggle={this.forgetPasswordToggle}
            className={"forgetPass " + this.props.className}
          >
            <ModalHeader toggle={this.forgetPasswordToggle}></ModalHeader>
            <ModalBody>
              <h4>{strings.ForgetPassword}</h4>
              <p>{strings.Resettingpassword}.</p>
              <div className="ForgetPassword">
                <form
                  id="register-form"
                  onSubmit={this.onSubmitResetPassword}
                  autocomplete="off"
                  class="form"
                >
                  <FormGroup>
                    <Input
                      type="text"
                      name="forgetemail"
                      value={this.state.forgetPassword}
                      id="forgetEmail"
                      placeholder="Enter your email address"
                      onChange={this.handleOnchangeResetPassword}
                    />
                  </FormGroup>
                  <div className="loginErrorMsg">
                    {this.state.forgetPasswordError}
                  </div>
                  <div className="resetPasswordSuccess">
                    {this.state.successMessage}
                  </div>
                  <div class="form-group">
                    <input
                      name="recover-submit"
                      class="btn btn-lg btn-primary btn-block"
                      value="Send"
                      type="submit"
                    />
                  </div>
                </form>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={this.state.loginWithOtpModal}
            toggle={this.loginWithOtpToggle}
            className={"forgetPass " + this.props.className}
          >
            <ModalHeader toggle={this.loginWithOtpToggle}></ModalHeader>
            <ModalBody>
              <h4 className="loginWithOtpHeading">{strings.loginWithMobile}</h4>
              {/* <p>{strings.Resettingpassword}.</p> */}
              <div className="ForgetPassword">
                <Form
                  id="register-form"
                  onSubmit={this.state.showOtpScreen == false ? this.onSubmitSendOtp : this.onSubmitLoginWithOtp}
                  autocomplete="off"
                  class="form"
                >
                  {this.state.showOtpScreen == false ?
                    <FormGroup className="mobileNumberByCountryCode">

                      <Input
                        className='mobilenumberotp'
                        type="number"
                        name="phone"
                        value={this.state.mobilenumber}
                        id="phone"
                        maxLength="10"
                        pattern="[0-9]{10}"
                        placeholder="Enter your mobile number"
                        onChange={this.handleOnChangeMobileNumber}
                      />

                    </FormGroup>
                    :

                    <FormGroup className="mobileNumberByCountryCode">
                      <Input
                        className='mobilenumberotp'
                        type="number"
                        name="phone"
                        value={this.state.mobilenumber}
                        id="phone"
                        maxLength="10"
                        // pattern="[1-9]{1}[0-9]{9}"
                        placeholder="Enter your mobile number"
                        onChange={(e) => { this.handleChange(e) }}
                        // onChange={this.handleOnchangeResetPassword}
                        disabled
                      />

                    </FormGroup>
                  }
                  <div class="loginwithotp-error-message">
                    {this.state.mobileNumberError}
                  </div>
                  {this.state.showOtpScreen == true ?
                    <div>
                      <div className="otp-text">
                        <span>We have sent an OTP on your mobile number</span>
                      </div>
                      <div className="otp-group">

                        {this.state.otpDigits.map((digit, idx) => (
                          <input
                            key={idx}
                            type="number"
                            inputMode="numeric"
                            autoComplete="one-time-code"
                            pattern="\d{1}"
                            maxLength={1}
                            className="otp-input"
                            ref={this.inputRefs[idx]}
                            onChange={(event) => this.handleOnchangeOtp(event, idx)}
                            onKeyDown={(event) => this.handleOnKeyDown(event, idx)}
                          // value={digit}
                          />
                        ))}
                      </div>
                    </div>
                    :
                    ""
                  }
                  <div class="loginwithotp-error-message">
                    {this.state.otpError}
                  </div>
                  {this.state.showOtpScreen == false ?
                    <div class="form-group">
                      <input
                        name="recover-submit"
                        class="btn btn-lg btn-primary btn-block"
                        value="Send"
                        type="submit"
                      />
                      <label className="loginwithMobilemsg">
                        {strings.loginwithmobilemsg}
                      </label>
                    </div>
                    :
                    <div class="form-group">
                      <input
                        name="recover-submit"
                        class="btn btn-lg btn-primary btn-block"
                        value="Submit"
                        type="submit"
                      />
                      <div class="ResendOtp">
                        <span onClick={this.onSubmitSendOtp}>Resend OTP</span>
                      </div>
                    </div>
                  }
                </Form>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStatesToProps(globalData) {
  return {
    authData: globalData.authonticationDetails,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(signInComponant));
