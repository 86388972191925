import axios from "axios";
import { Callbacks } from "jquery";
import promise from "redux-promise-middleware";
import { api_base_url } from "../config";

export const categoryActions = {
  languageLabCategoryDetailsAction,
  kidsCategoryDetailsAction,
  kidsCategoryPaginationAction,
  kidsCategoryEbookAction,
};
export function kidsCategoryAction() {
  return function (dispatch) {
    var headers = {
      "Content-Type": "application/json",
      "index-api-token": "rb9jB8KSb4WQkHIFZ92r7GAUuVtcNgnQ",
    };

    var productHeaders = {
      "Content-Type": "application/json",
      "guest-product-token": "d06aa4e4-44bb-11e9-b210-d663bd873d93",
    };
    dispatch({
      type: "SHOW_LOADER",
    });
    axios
      .get(api_base_url + "/api/v2/kids-subcategory/", { headers: headers })
      .then((response) => {
        if (response.data.status === true) {
          // var updatedArray = [];
          var promises = [];
          response.data.product_type[0].product_type_categories.forEach(
            (element) => {
              var mainCategory = new Object();
              mainCategory["id"] = element["id"];
              mainCategory["custom_translation"] =
                element["custom_translation"];
              mainCategory["sliders"] = [];
              let categoryID = element["id"];
              var output = new Promise((resolve, reject) => {
                axios
                  .get(
                    api_base_url +
                    "/api/v2/kid-category-products/" +
                    categoryID +
                    "/?size=10",
                    { headers: productHeaders }
                  )
                  .then((response) => {
                    if (response["status"] === 200) {
                      mainCategory["sliders"] =
                        response["data"]["category_product_list"];
                      resolve(mainCategory);
                    }
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
              promises.push(output);
            }
          );
          Promise.all(promises).then((result) => {
            dispatch({
              type: "KIDS_CATEGORY_LIST",
              payload: result,
            });
            dispatch({
              type: "HIDE_LOADER",
            });
          });
        } else {
          dispatch({
            type: "FAILED_KIDS_CATEGORY_LIST",
            payload: [],
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
        dispatch({
          type: "HIDE_LOADER",
        });
      });
  };
}

export function kidsCategoryEbookAction(token) {
  return function (dispatch) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/ebooks/", { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "EBOOK_PDF_DETIALS",
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: "FAILED_EBOOK_PDF_DETIALS",
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  };
}

function languageLabCategoryDetailsAction(categoryID, callbacks) {
  var productHeaders = {
    "Content-Type": "application/json",
    "guest-product-token": "d06aa4e4-44bb-11e9-b210-d663bd873d93",
  };
  return function (dispatch) {
    axios
      .get(api_base_url + "/api/language-lab-products/" + categoryID + "/", {
        headers: productHeaders,
      })
      .then((response) => {
        callbacks(response["data"], null);
      })
      .catch((err) => {
        callbacks(null, err);
        // console.log(err);
      });
  };
}

function kidsCategoryDetailsAction(categoryID, callbacks) {
  var productHeaders = {
    "Content-Type": "application/json",
    "guest-product-token": "d06aa4e4-44bb-11e9-b210-d663bd873d93",
  };
  return function (dispatch) {
    dispatch({
      type: "SHOW_LOADER",
    });
    axios
      .get(api_base_url + "/api/v2/kid-category-products/" + categoryID + "/", {
        headers: productHeaders,
      })
      .then((response) => {
        dispatch({
          type: "HIDE_LOADER",
        });
        callbacks(response["data"], null);
      })
      .catch((err) => {
        dispatch({
          type: "HIDE_LOADER",
        });
        callbacks(null, err);
        // console.log(err);
      });
  };
}

function kidsCategoryPaginationAction(apiUrl, callbacks) {
  var productHeaders = {
    "Content-Type": "application/json",
    "guest-product-token": "d06aa4e4-44bb-11e9-b210-d663bd873d93",
  };
  return function (dispatch) {
    axios
      .get(apiUrl, {
        headers: productHeaders,
      })
      .then((response) => {
        callbacks(response["data"], null);
      })
      .catch((err) => {
        callbacks(null, err);
        // console.log(err);
      });
  };
}
