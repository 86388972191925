import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import { userAuthentication } from "../../action/loginAction";
import { Route, Redirect, Link, withRouter } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormGroup,
  ModalFooter,
  Button
} from "reactstrap";
import swal from "sweetalert";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  PinterestIcon,
} from "react-share";
import {
  api_base_url,
  guestProductToken,
  dashboardToken,
  emailReg,
  encryptDecryptKey,
  googleAnalyticsTrackingId,
  nameRegularExpression,
  getHoursFormat,
  guestSubscriptionToken
} from "../../config";
import axios from "axios";
import { connect } from "react-redux";
import SimpleCrypto from "simple-crypto-js";
import StarRatings from "react-star-ratings";
import { Helmet } from "react-helmet";
import AudioPlayer from "react-modular-audio-player";
import { browseCategoryProductDetailsDataAction } from "../../action/browseCategoryAction";
import OwlCarousel from "react-owl-carousel2";
import CartProductModal from "../product/cart";
import LocalizedStrings from "react-localization";
import languages from "../../language/language.json";
import $ from "jquery";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);
let rearrangedPlayer = [
  {
    innerComponents: [
      {
        type: "rewind",
        style: { width: "fit-content" },
      },
      {
        type: "play",
        style: { width: "fit-content" },
      },
      {
        type: "forward",
        style: { width: "fit-content" },
      },
      {
        type: "seek",
      },
      {
        type: "volume",
      },
    ],
  },
];

const optionss = {
  nav: true,
  autoplay: false,
  navText: ["<div></div>", "<div></div>"],
  margin: 15,
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  },
};
const reviewOption = {
  nav: true,
  loop: false,
  autoplay: false,
  navText: ["<div></div>", "<div></div>"],
  navRewind: false,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};



class productDetails extends Component {
  constructor(props) {
    super(props);
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    var productId = localStorage.getItem("productId");
    var productIdObject = JSON.parse(productId);
    this.state = {
      token: token || guestProductToken,
      tokenToCheck: token,
      productId: "",
      productDetailsData: {},
      selectedLanguage: selectedLanguage || "EN",
      isLoading: false,
      product11: "",
      familyrespose: "",
      popularTitleSelectedAudio: [],
      cartProductModal: false,
      categorySliderData: [],
      authorSliderAudio: {},
      latestLaunchLastPlayed: "",
      audioObject: undefined,
      audioPlayerObject: undefined,
      popoverOpenShare: false,
      giftProductModal: false,
      cartDetailsProducts: [],
      audioId: [],
      subId: [],
      Product_reps: "",
      email: "",
      emailError: "",
      firstName: "",
      lastName: "",
      message: "",
      firstNameError: "",
      lastNameError: "",
      messageError: "",
      reviewProductModal: false,
      rating: 0,
      reviewBody: "",
      reviewTitle: "",
      reviewTitleError: "",
      reviewBodyError: "",
      reviewProductModalSuccess: false,
      cartFailureMessage: "",
      ratingRequire: "",
      condition: false,
      giftProductModalDetail: false,
      acceptTerm: false,
      acceptTermError: "",
      showLargeDescription: true,
      productLastPlayedTrackData: [],
      selectedTrackIndex: 0,
      trackId: 0,
      isPaused: false,
      coupneVoucher: "",
      isAddedCoupon: false,
      couponError: "",
      cuponMessageSuccess: "",
      cuponMessage: "",
      modal: false,
      showLargeTrackDescription: false,
      giftProdName: "",
      showPlayAllButton: false,
      isIndian: null,
      currcountry: "",
      subscribePackages: [],
      audioIdType: []
    }


    this.toggle2 = this.toggle2.bind(this);

    this.cartProductsModal = this.cartProductsModal.bind(this);
    this.popOverToggle = this.popOverToggle.bind(this);
    ReactGA.pageview(this.props.location.pathname);
    this.onSubmitGift = this.onSubmitGift.bind(this);
    this.closeGiftProductModal = this.closeGiftProductModal.bind(this);
    this.reviewProductModalFunction =
      this.reviewProductModalFunction.bind(this);
    this.changeRating = this.changeRating.bind(this);
    this.closeReviewModel = this.closeReviewModel.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.reviewCloseModel = this.reviewCloseModel.bind(this);
    this.cancelReview = this.cancelReview.bind(this);
    this.toggleReviewHeight = this.toggleReviewHeight.bind(this);
    this.addToLibraryProduct = this.addToLibraryProduct.bind(this);
    this.makePaymentGiftedProduct = this.makePaymentGiftedProduct.bind(this);
    this.cancelGiftPayment = this.cancelGiftPayment.bind(this);
    this.readMoreDesc = this.readMoreDesc.bind(this);
    this.getSharingContent = this.getSharingContent.bind(this);
    this.productLastEpisodsTracks = this.productLastEpisodsTracks.bind(this);
    this.handleCoupenValue = this.handleCoupenValue.bind(this);
    this.applyCoupen = this.applyCoupen.bind(this);
    this.removeCopenCode = this.removeCopenCode.bind(this);
    this.playProductTrack = this.playProductTrack.bind(this);
    this.showReviewSection = this.showReviewSection.bind(this);
    this.tracksDescription = this.tracksDescription.bind(this);
    this.readMoreTrackDesc = this.readMoreTrackDesc.bind(this);
    //this.getSubscribePackages = this.getSubscribePackages.bind(this)
    this.getuserCountry();
  }

  toggle2() {
    // console.log("hello");
    this.setState({
      modal: !this.state.modal
    });

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isIndian !== null && nextProps.isIndian !== undefined) {
      this.setState({ isIndian: nextProps.isIndian });
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
    if (
      Object.keys(nextProps.selectedProductToPlay.selectedProduct).length > 0 &&
      nextProps.selectedProductToPlay.isPlayFromSection == "purchasedInLibrary"
    ) {
      var newSelectedProduct = nextProps.selectedProductToPlay.selectedProduct;
      var newTrackIndex = nextProps.selectedProductToPlay.selectedTrackIndex;
      var newPauseStatus = nextProps.selectedProductToPlay.isPauseFromPlayer;
      this.setState({
        productID: newSelectedProduct.id,
        selectedTrackIndex: newTrackIndex,
        isPaused: newPauseStatus,
        trackId:
          this.state.productLastPlayedTrackData.length > 0
            ? this.state.productLastPlayedTrackData[newTrackIndex].id
            : 0,
      });
    } else if (
      Object.keys(nextProps.selectedProductToPlay.selectedProduct).length > 0 &&
      nextProps.selectedProductToPlay.isPlayFromSection == "productDetails"
    ) {
      var newSelectedProduct = nextProps.selectedProductToPlay.selectedProduct;
      var newTrackIndex = nextProps.selectedProductToPlay.selectedTrackIndex;
      var newPauseStatus = nextProps.selectedProductToPlay.isPauseFromPlayer;
      this.setState({
        productID: newSelectedProduct.id,
        selectedTrackIndex: newTrackIndex,
        isPaused: newPauseStatus,
        trackId:
          this.state.productLastPlayedTrackData.length > 0
            ? this.state.productLastPlayedTrackData[newTrackIndex].id
            : 0,
      });
    }
  }

  toggleReviewHeight() {
    this.setState({ condition: !this.state.condition });
  }

  changeRating(rating) {
    this.setState({
      rating: rating,
    });
  }

  getuserCountry = async () => {
    let getcountrycode;
    try {
      getcountrycode = await localStorage.getItem("currentcountrycode")
      this.setState({ currcountry: getcountrycode })
      // alert(this.state.currcountry)
    }
    catch (e) {

    }
  }

  onSubmitReview(e) {
    e.preventDefault();
    this.setState({
      reviewTitleError: "",
      reviewBodyError: "",
      ratingRequire: "",
    });

    var isValid = false;
    if (this.state.rating === 0) {
      this.setState({
        ratingRequire: strings.thisFieldRequire,
      });
      isValid = false;
    }
    if (this.state.reviewTitle == "") {
      this.setState({
        reviewTitleError: strings.thisFieldRequire,
      });
      isValid = false;
    }
    if (this.state.reviewBody == "") {
      this.setState({
        reviewBodyError: strings.thisFieldRequire,
      });
      isValid = false;
    }
    if (
      this.state.rating !== 0 &&
      this.state.reviewTitle !== "" &&
      this.state.reviewBody !== ""
    ) {
      isValid = true;
    }

    if (isValid) {
      var apiParams = {
        product: this.state.productDetailsData.id,
        score: this.state.rating,
        title: this.state.reviewTitle,
        body: this.state.reviewBody,
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .post(api_base_url + "/api/review-add/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status === true) {
            this.setState({
              reviewProductModal: false,
              reviewProductModalSuccess: true,
            });
          }
        })
        .catch((err) => {
          // console.log("fetched.", err);
        });
    }
  }

  closeReviewModel() {
    this.setState({
      reviewProductModal: false,
      reviewProductModalSuccess: false,
    });
  }
  reviewCloseModel() {
    this.setState({
      reviewProductModalSuccess: false,
      reviewBody: "",
      reviewTitle: "",
      rating: 0,
    });
  }
  readMoreDesc() {
    this.setState({
      showLargeDescription: !this.state.showLargeDescription,
    });
  }

  readMoreTrackDesc() {
    this.setState({
      showLargeTrackDescription: !this.state.showLargcomeTrackDescription,
    });
  }

  popOverToggle() {
    this.setState({
      popoverOpenShare: !this.state.popoverOpenShare,
    });
  }
  reviewProductModalFunction() {
    this.setState({
      reviewProductModal: true,
    });
  }
  getProductName = () => {
    return this.state.giftProdName != ""
      ? this.state.giftProdName[this.state.selectedLanguage].name
      : "";
  };

  cartProductsModal(variantId) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .get(api_base_url + "/api/otp/check-user-profile-status/", { headers: headers })
      .then((response) => {
        if (!response.data.is_profile_completed) {
          // console.log(response.data.is_profile_completed);
          this.props.history.push({
            pathname: "/library",
            state: { is_new_user: true, redirectionFromCart: true}
          })
          // this.props.history.push({
          //   pathname:"/library", 
          //   state: response.data.is_profile_completed})
          // this.toggleEditProfile()
        } else {
          if (this.props.authData.loginSuccess) {
            this.setState({
              cartFailureMessage: "",
            });
            var apiParams = {
              email: "",
              discount_amount: 0.0,
              discount_amount_inr: 0.0,
              gift_cart: false,
              cart_lines: [
                {
                  variant: variantId,
                  quantity: 1,
                  data: {},
                },
              ],
            };
            var headers = {
              "Content-Type": "application/json",
              Authorization: "Token " + this.state.token,
            };
            axios
              .post(api_base_url + "/api/cart/", apiParams, { headers: headers })
              .then((response) => {
                if (response.data.status === true) {
                  this.props.dispatch({
                    type: "PRODUCT_CART_COUNT",
                    payload: response.data.data.quantity,
                  });
                  this.props.dispatch({ type: "MODEL_SELECTED", payload: true });
                } else {
                  this.setState({
                    cartFailureMessage: "Product already added in cart",
                  });
                }
                this.setState({ isLoading: false });
              })
              .catch((err) => {
                // console.log("fetched.", err);
                this.setState({
                  cartFailureMessage: "Product already added in cart",
                });
              });
          } else {
            this.props.history.push("/sign-in");
          }
        }
      })
  }

  cancelReview() {
    this.setState({
      reviewBody: "",
      reviewTitle: "",
      reviewTitleError: "",
      reviewBodyError: "",
      reviewProductModalSuccess: false,
      reviewProductModal: false,
      ratingRequire: "",
    });
  }

  /**********************
   *  Author details page *
   **********************/
  authorDetailsId(authorID, authorName) {
    var titleUrl = authorName.toLowerCase();
    var authorTitle = titleUrl.replace(".", "");
    var authorName = authorTitle.split(" ").join("-");
    this.props.history.push("/author/" + authorID + "/" + authorName);
  }






  userSubscribeedAfterLogin() {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .get(api_base_url + "/api/user-subscribed/", { headers: headers })
      .then((response) => {

        let subType = []
        for (let responseJson of response.data.audio_types) {
          subType.push(responseJson.audio_type_id)

        }
        this.setState({ subId: subType })
        //console.log("subId----", response)
        if (response.data.status === true) {
          this.props.dispatch(
            userAuthentication(
              this.state.userEmail,
              this.state.token,
              response.data.is_subsribed,
              this.state.firstName,
              this.state.lastName,
              this.state.newProfileImage,
              this.state.userCountryCode,
              this.state.userStateCode,
              this.state.userMobileNumber,
            )
          );
        }
      })
      .catch((err) => {
        // console.log("error ", err);
      });
  }







  /**********************
   *  Product details page *
   **********************/
  productDetailPage(stateProductId) {
    this.setState({ isLoading: true });
    var headers = {
      "Content-Type": "application/json",
      "guest-product-token": this.state.token,
    };
    if (this.state.audioPlayerObject) {
      this.state.audioPlayerObject.pause();
    }
    if (this.state.audioObject) {
      this.state.audioObject.pause();
      this.setState({
        latestLaunchLastPlayed: "",
      });
    }
    axios
      .get(api_base_url + "/api/v2/product-details/" + stateProductId + "/", {
        headers: headers,
      })
      .then((response) => {


        let audioType = []
        for (let responseJson of response.data.category_product_list) {
          audioType.push(responseJson.audio_type.id)

        }

        let audioTypedata = []
        for (let responseJson of response.data.category_product_list) {
          audioTypedata.push(responseJson.purchase_only)

        }
        this.setState({ audioIdType: audioTypedata })
        // console.log("audioId----", this.state.audioIdType)

        for (let Jsonresp of response.data.product_details) {


          this.setState({ Product_reps: Jsonresp.is_free })
        }


        // console.log("product_type", this.state.Product_reps)

        if (response.statusText === "OK") {
          if (
            Array.isArray(response.data.product_details) &&
            response.data.product_details.length
          ) {
            let productInformation = response.data.product_details[0] || {};
            if (productInformation.id && productInformation.product_slug && productInformation.audio_type && productInformation.audio_type && productInformation.audio_type.slug === "language-lab") {
              this.props.history.push("/audio-course-product-details/" + productInformation.id + "/" + productInformation.product_slug);
            }
            this.setState({
              productDetailsData: response.data.product_details[0],
              categorySliderData: response.data.category_product_list,
            });

          } else {
            this.props.history.push("/");
          }
          if (
            this.state.productDetailsData.is_free ||
            this.state.productDetailsData.is_purchased ||
            this.props.authData.subscribeStatus != false
          ) {
            this.setState({
              showPlayAllButton: true,
            });
          }
          if (response.data.product_details[0].sampletracks.length > 0) {
            var apiParams = {
              map_url:
                response.data.product_details[0].sampletracks[0].map_url || "",
            };
            var headers = {
              "Content-Type": "application/json",
              "index-api-token": dashboardToken,
            };
            axios
              .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
                headers: headers,
              })
              .then((response) => {
                if (response.data) {
                  var url = response.data.data.signed_url || "";
                  this.setState({
                    popularTitleSelectedAudio: [{ src: url }],
                  });
                  var newAudioObject = $(".audio-player audio");
                  if (newAudioObject.length > 0) {
                    var parentThis = this;
                    this.setState({
                      audioPlayerObject: newAudioObject[0],
                    });
                    newAudioObject[0].onerror = function () {
                      const isFirefox = typeof InstallTrigger !== "undefined";
                      if (isFirefox) {
                        swal(
                          "",
                          "Something went wrong with your browser. Please try another one.",
                          "error"
                        );
                      }
                    };
                    newAudioObject[0].addEventListener("play", function () {
                      if (parentThis.state.audioObject) {
                        parentThis.state.audioObject.pause();
                        parentThis.setState({
                          latestLaunchLastPlayed: "",
                        });
                      }
                    });
                  }
                }
              })
              .catch((err) => {
                // console.log("Error - ", err);
              });
          } else {
            this.setState({
              popularTitleSelectedAudio: [],
            });
          }
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        // console.log("Error - ", err);
        this.setState({ isLoading: false });
      });
  }

  /**********************
   *  Product Analytics  *
   **********************/
  productAnalytics(productID) {
    var apiParams = {
      product_id: productID,
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/product-analytics/", apiParams, {
        headers: headers,
      })
      .then((response) => { })
      .catch((err) => {
        // console.log("error", err);
      });
  }

  productDetailsId(productID, slug) {
    this.props.history.push("/product-details/" + productID + "/" + slug);
    window.scrollTo(0, 0);
  }

  /*****************************
   * Gift product to other user
   ****************************/
  giftProductToOther(varientID) {
    this.setState({
      giftProductModal: true,
    });
    if (
      this.state.productDetailsData != undefined &&
      this.state.productDetailsData.custom_translation != undefined
    ) {
      this.setState({
        giftProdName: this.state.productDetailsData.custom_translation,
      });
    }
  }
  closeGiftProductModal() {
    this.setState({
      giftProductModal: false,
    });
    this.cancelGiftPayment();
  }
  /*****************************
   * Fill gifted person details
   *****************************/
  onSubmitGift(e) {
    e.preventDefault();
    this.setState({
      emailError: "",
      firstNameError: "",
      lastNameError: "",
      messageError: "",
    });
    var isValid = true;
    if (this.state.firstName == "") {
      this.setState({
        firstNameError: strings.firstNameError,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.firstName)) {
      this.setState({
        firstNameError:
          "First name field accept alphabets(A-Z,a-z), length (2-50) char only",
      });
      isValid = false;
    }
    if (this.state.lastName == "") {
      this.setState({
        lastNameError: strings.lastNameError,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.lastName)) {
      this.setState({
        lastNameError:
          "Last name field accept alphabets(A-Z,a-z), length (2-50) char only",
      });
      isValid = false;
    }
    if (this.state.message == "") {
      this.setState({
        messageError: strings.messageTextError,
      });
      isValid = false;
    } else if (this.state.message.length > 500) {
      this.setState({
        messageError: "Message should not be more than 500 characters",
      });
      isValid = false;
    }
    if (this.state.email == "") {
      this.setState({
        emailError: strings.enterEmail,
      });
      isValid = false;
    } else if (emailReg.test(this.state.email) === false) {
      this.setState({
        emailError: strings.enterValidEmail,
      });
      isValid = false;
    } else if (this.state.email.length > 50) {
      this.setState({
        messageError: "Email should not be more than 50 characters",
      });
      isValid = false;
    }

    if (isValid) {
      var apiParams = {
        email: "",
        discount_amount: 0.0,
        discount_amount_inr: 0.0,
        gift_cart: true,
        cart_lines: [
          {
            variant: this.state.productDetailsData.variant,
            quantity: 1,
            data: {},
          },
        ],
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .post(api_base_url + "/api/cart/", apiParams, { headers: headers })
        .then((response) => {
          if (response.data.status === true) {
            this.setState({
              cartDetailsProducts: response.data.data,
              giftProductModalDetail: true,
              giftProductModal: false,
            });
          }
          if (response.data.status === false) {
            this.setState({
              cartFailureMessage: "Product already added in cart",
            });
          }
        })
        .catch((err) => {
          // console.log("fetched.", err);
        });
    }
  }

  /**********************************
   * Make payment for gifted product
   *********************************/
  makePaymentGiftedProduct() {
    if (this.state.acceptTerm) {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      var orderCurrency = "1";
      if (this.state.currcountry === "IN") {
        orderCurrency = "2";
      }
      var apiParams = {
        order_currency: orderCurrency,
        gift_order: true,
        email: this.state.email,
        cart_token: this.state.cartDetailsProducts.token,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        message: this.state.message,
      };
      axios
        .post(api_base_url + "/api/v1/orders/", apiParams, { headers: headers })
        .then((response) => {
          if (response.data.status === true) {
            var orderToken = response.data.data.order_token;
            var headers = {
              "Content-Type": "application/json",
              Authorization: "Token " + this.state.token,
            };
            var apiParams = {
              token: orderToken,
            };
            if (this.state.currcountry === "IN") {
              axios
                .post(api_base_url + "/api/v1/payu-checkout/", apiParams, {
                  headers: headers,
                })
                .then((response) => {
                  if (response.data == "cc_avenue") {
                    window.open(
                      api_base_url + "/order/ccavenue-pay/" + orderToken,
                      "_self",
                      "top=10",
                      "replace=false"
                    );
                  } else {
                    this.props.history.push({
                      pathname: "/proceed-payment",
                      state: { detail: response.data, isPayAutoRenewal: false },
                    });
                  }
                })
                .catch((err) => {
                  // console.log("fetched.", err);
                });
            } else {
              axios
                .post(api_base_url + "/api/v1/paypal-checkout/", apiParams, {
                  headers: headers,
                })
                .then((response) => {
                  this.props.history.push({
                    pathname: "/proceed-payment",
                    state: { detail: response.data, isPayAutoRenewal: false },
                  });
                })
                .catch((err) => {
                  // console.log("fetched.", err);
                });
            }
          }
        })
        .catch((err) => {
          // console.log("fetched.", err);
        });
    } else {
      this.setState({
        acceptTermError: "Please accept terms of service",
      });
    }
  }
  /*****  Cancel gift product */
  cancelGiftPayment() {
    this.setState({
      firstName: "",
      lastName: "",
      message: "",
      email: "",
      giftProductModalDetail: false,
    });
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      token: this.state.cartDetailsProducts.token,
    };
    axios
      .post(api_base_url + "/api/cancel-gift-order/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  componentDidMount() {
    this.setState({
      productId: this.props.match.params.id,
    });
    this.productDetailPage(this.props.match.params.id);
    this.userSubscribeedAfterLogin();
    this.productAnalytics(this.props.match.params.id);
    this.productLastPlayedTrack(this.props.match.params.id);


    // this.getSubscribePackages()
    this.unlisten = this.props.history.listen((location, action) => {
      var pathName = location.pathname;
      var lastRoute = this.props.location.pathname;
      if (
        pathName.indexOf("product-details") > -1 &&
        lastRoute.indexOf("product-details") > -1 &&
        pathName != lastRoute
      ) {
        var newArray = pathName.split("/");
        var productID = newArray[2];
        if (productID) {
          localStorage.setItem("productId", productID);
          this.setState({
            productId: productID,
          });
          this.productDetailPage(productID);
          this.productAnalytics(productID);
          this.productLastPlayedTrack(productID);
        }
      }
    });
    if (
      this.props.authData.loginSuccess == true &&
      (this.state.productDetailsData.is_free ||
        this.state.productDetailsData.is_purchased)
    ) {
      this.playProductTrack(this.state.selectedTrackIndex);
    }
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    if (this.state.audioObject) {
      this.state.audioObject.pause();
    }
    this.unlisten();
  }

  /**********************
   *  Product last played tracks  *
   **********************/
  productLastPlayedTrack(productID) {
    var headers = {
      "Content-Type": "application/json",
      "index-api-token": dashboardToken,
    };
    axios
      .get(api_base_url + "/api/tracks/" + productID + "/0/50/", {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status) {
          this.setState({
            productLastPlayedTrackData: response.data.last_product_track || [],
          });
        }
      })
      .catch((err) => {
        // console.log("Error - ", err);
      });
  }

  /*****************************
   * Author List
   *****************************/
  authorsSelected(authorsList) {
    var authorArray = [];
    authorsList.map((authorName) => {
      if (
        authorName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        authorArray.push(
          authorName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return authorArray.toString();
  }
  directorsSelected(directorList) {
    var directorArray = [];
    directorList.map((directorName) => {
      if (
        directorName.custom_translation[this.state.selectedLanguage] !=
        undefined
      ) {
        directorArray.push(
          directorName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return directorArray.toString();
  }
  artistsSelected(artistsList) {
    var artistsArray = [];
    artistsList.map((artistsName) => {
      if (
        artistsName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        artistsArray.push(
          artistsName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return artistsArray.join(", ");
  }
  categorySelected(categoryList) {
    var categoryArray = [];
    categoryList.map((categoryName) => {
      if (
        categoryName.custom_translation[this.state.selectedLanguage] !=
        undefined
      ) {
        categoryArray.push(
          categoryName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return categoryArray.join(", ");
  }
  /********************
   * Pause audio file
   ********************/
  authorPauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      latestLaunchLastPlayed: "",
    });
  }

  /**************************
   * Play audio file
   *************************/
  authorPlayAudio(map_url) {
    if (this.state.authorSliderAudio[map_url]) {
      this.setState({
        latestLaunchLastPlayed: map_url,
      });
      if (this.state.audioPlayerObject) {
        this.state.audioPlayerObject.pause();
      }
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                latestLaunchLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newLatestLaunchAudio = {};
            newLatestLaunchAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              latestLaunchAudio: newLatestLaunchAudio,
              latestLaunchLastPlayed: map_url,
            });
            if (this.state.audioPlayerObject) {
              this.state.audioPlayerObject.pause();
            }
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }
  singleProductSlider() {
    return this.state.categorySliderData.map((sliderContent, index) => {
      return (
        <div>
          <div class="videoThumbDiv">
            {sliderContent.images.length > 0 ? (
              <div
                className="product-details-image-slider"
                onClick={() => {
                  this.productDetailsId(
                    sliderContent.id,
                    sliderContent.product_slug
                  );
                }}
              >
                {sliderContent.is_free && (
                  <span className="freeProduct">{strings.FreeText}</span>
                )}{" "}
                <img src={sliderContent.images[0]["image2"]} />
              </div>
            ) : (
              <div className="singleSliderDefualt">
                {sliderContent.is_free && (
                  <span className="freeProduct">{strings.FreeText}</span>
                )}{" "}
                <img src="/images/default.jpg" />
              </div>
            )}
          </div>
          {/* <div class="videoThumbTitleDiv">
            {sliderContent.custom_translation[this.state.selectedLanguage] !=
              undefined && (
              <span
                onClick={() => {
                  this.productDetailsId(
                    sliderContent.id,
                    sliderContent.product_slug
                  );
                }}
              >
                {
                  sliderContent.custom_translation[this.state.selectedLanguage]
                    .name
                }
              </span>
            )}
            <br />
            <span class="briefTitle">
              {strings.authorText}:{" "}
              <a
                href="javascript:;"
                onClick={() => {
                  this.authorDetailsId(
                    sliderContent.authors[0].id,
                    sliderContent.authors[0].name
                  );
                }}
              >
                {this.authorsSelected(sliderContent.authors)}
              </a>
            </span>
          </div> */}
          {/* <div class="videoThumbCateDiv">
            {sliderContent.category.map((categoryList, index) => {
              if (
                categoryList.custom_translation[this.state.selectedLanguage] !==
                undefined
              ) {
                return (
                  <NavLink
                    tag={Link}
                    to={{
                      pathname: "/browse",
                    }}
                    onClick={() => {
                      this.props.dispatch({
                        type: "MENU_SELECTED_CATEGORY",
                        payload: [
                          categoryList.name,
                          sliderContent.product_audio_type,
                          categoryList.id,
                        ],
                      });
                      this.props.dispatch(
                        browseCategoryProductDetailsDataAction(
                          sliderContent.product_audio_type,
                          categoryList.name,
                          1
                        )
                      );
                    }}
                    key={index}
                  >
                    <span key={index}>
                      {categoryList.custom_translation[
                        this.state.selectedLanguage
                      ].name.toString()}
                    </span>
                  </NavLink>
                );
              }
            })}
          </div> */}
          <div className="new_sliderTrack_Wrap">
            {sliderContent.sampletracks.length > 0 && (
              <div>
                {/* // <div> */}
                {sliderContent.sampletracks[0].map_url !=
                  this.state.latestLaunchLastPlayed && (
                    <img
                      onClick={() => {
                        this.authorPlayAudio(
                          sliderContent.sampletracks[0].map_url
                        );
                      }}
                      src="/images/play.png"
                    />
                  )}
                {sliderContent.sampletracks[0].map_url ==
                  this.state.latestLaunchLastPlayed && (
                    <img
                      onClick={() => {
                        this.authorPauseAudio(
                          sliderContent.sampletracks[0].map_url
                        );
                      }}
                      src="/images/round-pause-button.svg"
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      );
    });
  }

  /******* Product review section *********/
  reviewSliderData() {
    return this.state.productDetailsData.reviews.map((items, index) => {
      return (
        <div>
          <div
            className={
              this.state.condition
                ? "reviewCntTest reviewCntTestCondition"
                : "reviewCntTest"
            }
          >
            <h4>{items.title}</h4>
            <p>{items.body}</p>
          </div>
          <div className="readMoreReview" onClick={this.toggleReviewHeight}>
            {items.body.length > 100 && (
              <span>
                {this.state.condition ? strings.LessText : strings.readMoreText}
              </span>
            )}
          </div>
        </div>
      );
    });
  }

  getDuration(duration) {
    var newDuration = "";
    if (duration == 0) {
      newDuration = duration;
    } else {
      var newMinutes = duration / 60;
      newDuration = parseFloat(newMinutes / 60).toFixed(2);
    }
    return newDuration + " " + strings.hrsText;
  }

  /****************************************************************************
   * When user click on add to libarary button then product is added his account
   *****************************************************************************/

  addToLibraryProduct(productID) {
    var apiParams = {
      product: productID,
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/add-to-library/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          this.props.history.push("/library");
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }

  goToLibraryProduct(productId) {
    this.props.dispatch({ type: "MY_LIBRARY_ACTIVE_TAB", payload: "0" });
    this.props.dispatch({
      type: "SELECTED_PRODUCT_DETAILS",
      payload: [productId, "productdetails"],
    });
    this.props.history.push("/library");
  }




  // getSubscribePackages() {
  //   var headers = {
  //     "Content-Type": "application/json",
  //     "guest-subscription-token": guestSubscriptionToken,
  //   };
  //   axios
  //     .get(api_base_url + "/api/subscription-list/", { headers: headers })
  //     .then((response) => {
  //       let usePurchased = []
  //       for (let responseJson of response.data) {
  //         usePurchased.push(responseJson)
  //       }

  //       this.setState({
  //         subscribePackages: usePurchased
  //       });

  //       console.log("guestSubscriptionToken---", this.state.subscribePackages)
  //     })
  //     .catch((err) => {
  //       // console.log("error ", err);
  //     });
  // }





  getSharingContent() {
    var selectedLanguage = this.state.selectedLanguage;
    let productDetailsData = this.state.productDetailsData;
    var productDetail = productDetailsData.custom_translation
      ? productDetailsData.custom_translation
      : {};
    var productAuthorDetails = productDetailsData.authors || [];
    var productName = productDetailsData.name;
    var Authors = "";
    if (Object.keys(productDetail).length > 0) {
      if (selectedLanguage == "MR") {
        productName = productDetail.MR.name;
      } else {
        productName = productDetail.EN.name;
      }
    }
    var sharingContent =
      strings.socialSharingText +
      "\n" +
      productName +
      "\n" +
      strings.authorText +
      ": ";
    if (productDetailsData && productAuthorDetails) {
      productAuthorDetails.forEach((author, index) => {
        let autorDetails = author.custom_translation
          ? author.custom_translation
          : {};
        var authorName = author.name;
        if (Object.keys(autorDetails).length > 0) {
          if (selectedLanguage == "MR") {
            authorName = autorDetails.MR.name;
          } else {
            authorName = autorDetails.EN.name;
          }
        }
        Authors =
          Authors +
          authorName +
          (index < productAuthorDetails.length - 1 ? ", " : ".");
      });
    }
    var happyListening = "\n" + strings.happyListening + "\n";
    return sharingContent + Authors + happyListening;
  }

  playProductTrack(trackIndex) {

    var audiolist = this.state.audioId;
    var subcriptionlist = this.state.subId;
    let CombineList = audiolist.every(f => subcriptionlist.includes(f))



    let Product_check = this.state.Product_reps

    // console.log("is_free", this.state.Product_check)

    if (CombineList === true || Product_check === true) {
      if (this.props.authData.loginSuccess == true) {
        var productData = this.state.productDetailsData;
        var tracksData = this.state.productLastPlayedTrackData;
        productData["producttracks"] = tracksData;
        var productDetailsData = productData;
        if (
          this.props.authData.subscribeStatus != false ||
          productDetailsData.is_free ||
          productDetailsData.is_purchased
        ) {
          if (this.state.selectedTrackIndex !== trackIndex) {
            this.props.dispatch({
              type: "PLAY_SELECTED_PRODUCT",
              payload: [
                productDetailsData,
                trackIndex,
                false,
                false,
                false,
                false,
                "productDetails",
              ],
            });
            this.setState({
              selectedTrackIndex: trackIndex,
              trackId: tracksData[trackIndex].id,
              isPaused: false,
            });
          } else {
            if (this.state.isPaused) {
              this.props.dispatch({
                type: "PLAY_SELECTED_PRODUCT",
                payload: [
                  productDetailsData,
                  trackIndex,
                  false,
                  true,
                  false,
                  false,
                  "productDetails",
                ],
              });
              this.setState({
                isPaused: false,
              });
            } else {
              this.props.dispatch({
                type: "PLAY_SELECTED_PRODUCT",
                payload: [
                  productDetailsData,
                  trackIndex,
                  true,
                  false,
                  false,
                  false,
                  "productDetails",
                ],
              });
              this.setState({
                isPaused: true,
              });
            }
          }
        } else {
          this.cartProductsModal(productDetailsData.variant);
        }
      }
    }
    else {
      //toast("Please subscribe or purchase the product to listen")
      // this.props.history.push("/sign-in")
      this.setState({ modal: true })
    }
  }

  productLastEpisodsTracks(productLastTracksData) {
    var selectedLanguage = this.state.selectedLanguage;
    var charMaxLength = 150;
    return productLastTracksData.map((track, trackIndex) => {
      return (
        <div className="episod__wrap">
          <div className="episod__left">
            <div class="videoThumbDiv sliderThumb">
              <img src={track.image} class="sampleProduct_thumb" />
              <div class="sliderTrack_Wrap">
                <img
                  src={
                    this.state.trackId == track.id && !this.state.isPaused
                      ? "/images/round-pause-button.svg"
                      : "/images/play.png"
                  }
                  onClick={() => {
                    this.playProductTrack(trackIndex);
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
          <div className="episod__right">
            <div class="sliderProductTitle">
              <span
                onClick={() => {
                  this.playProductTrack(trackIndex);
                }}
              >
                {track.custom_translation[selectedLanguage].name}
              </span>
            </div>
            <div className="episod__duration">
              <span>
                {getHoursFormat(track.track_length) + " " + strings.hrsText}
              </span>
            </div>
            <div className="episod__description">
              <p>
                {track.custom_translation[selectedLanguage].description !==
                  "" &&
                  track.custom_translation[selectedLanguage].description !== null
                  ? this.tracksDescription(
                    track.custom_translation[selectedLanguage].description,
                    charMaxLength
                  )
                  : track.description
                    ? this.tracksDescription(track.description, charMaxLength)
                    : ""}
              </p>
            </div>
          </div>
        </div>
      );
    });
  }

  tracksDescription(trackDescription, charMaxLength) {
    if (trackDescription != null && trackDescription.length > charMaxLength) {
      var lesDescStr = trackDescription.substring(0, charMaxLength);
      return (
        <Fragment>
          <span>
            {!this.state.showLargeTrackDescription &&
              lesDescStr + (!this.state.showLargeTrackDescription ? "..." : "")}
          </span>
          {this.state.showLargeTrackDescription && (
            <span>{trackDescription}</span>
          )}
          <span className="readMoreReview" onClick={this.readMoreTrackDesc}>
            {!this.state.showLargeTrackDescription
              ? strings.readMoreText
              : strings.LessText}
          </span>
        </Fragment>
      );
    } else {
      return trackDescription;
    }
  }

  handleCoupenValue(e) {
    this.setState({ coupneVoucher: e.target.value });
    if (this.state.coupneVoucher.length > 0) {
      this.setState({
        hiddenRemoveCupon: true,
      });
    }
  }

  /*********  Apply coupon code **************/
  applyCoupen() {
    this.setState({
      cuponMessageSuccess: "",
      cuponMessage: "",
      couponError: "",
    });

    var isValid = false;

    if (this.state.coupneVoucher === "") {
      this.setState({
        couponError: strings.EnterValidCoupon,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    if (isValid) {
      var apiParams = {
        token: this.state.cartDetailsProducts.token,
        voucher_code: this.state.coupneVoucher,
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .post(api_base_url + "/api/apply-coupon/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status === true) {
            var newDiscount = response.data.data.discount_amount;
            if (this.state.currcountry === "IN") {
              newDiscount = response.data.data.discount_amount_inr;
            }
            var newIgstAmount = 0;
            var newCgstAmount = 0;
            var newSgstAmount = 0;
            if (this.state.currcountry === "IN") {
              var newTotal = this.state.AfterCuponAmount - newDiscount;
              if (this.state.igstPercent !== 0) {
                newIgstAmount = (newTotal * this.state.igstPercent) / 100;
              } else {
                newCgstAmount = (newTotal * this.state.cgstPercent) / 100;
                newSgstAmount = (newTotal * this.state.sgstPercent) / 100;
              }
            }
            this.setState({
              isAddedCoupon: true,
              coupenDiscountAmount: newDiscount,
              cuponMessageSuccess: response.data.message,
              cuponMessage: "",
              igstAmount: newIgstAmount,
              cgstAmount: newCgstAmount,
              sgstAmount: newSgstAmount,
            });
          }
          if (response.data.status === false) {
            this.setState({
              cuponMessage: response.data.data[0].voucher_code[0],
            });
          }
        })
        .catch((err) => {
          // console.log("fetched.", err);
        });
    }
  }

  /*****  Remove coupon code **********/
  removeCopenCode() {
    var apiParams = {
      token: this.state.cartDetailsProducts.token,
      voucher_code: "",
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/apply-coupon/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          var newIgstAmount = 0;
          var newCgstAmount = 0;
          var newSgstAmount = 0;
          if (this.state.currcountry === "IN") {
            if (this.state.igstPercent !== 0) {
              newIgstAmount =
                (this.state.AfterCuponAmount * this.state.igstPercent) / 100;
            } else {
              newCgstAmount =
                (this.state.AfterCuponAmount * this.state.cgstPercent) / 100;
              newSgstAmount =
                (this.state.AfterCuponAmount * this.state.sgstPercent) / 100;
            }
          }
          this.setState({
            isAddedCoupon: false,
            coupenDiscountAmount: 0,
            cuponMessage: response.data.message,
            totalPrice: this.state.AfterCuponAmount,
            coupneVoucher: "",
            cuponMessageSuccess: "",
            igstAmount: newIgstAmount,
            cgstAmount: newCgstAmount,
            sgstAmount: newSgstAmount,
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }

  showReviewSection() {
    return (
      <div
        className={
          "reviewSlideWrp " +
          (this.state.productDetailsData.recurring_product ? "mb-5 " : "")
        }
      >
        <div className="item">
          <div className="reviewWrp">
            <h3>{strings.Reviews}</h3>
            <span className="reviewCount">
              {this.state.productDetailsData.reviews.length}
            </span>
            {this.state.productDetailsData.reviews.length > 0 ? (
              <OwlCarousel ref="car" options={reviewOption}>
                {this.reviewSliderData()}
              </OwlCarousel>
            ) : (
              <div className="reviewNotFound">Review Not found</div>
            )}
            <div className="reviewTestComment">
              {this.props.authData.loginSuccess ? (
                <span>
                  {(this.props.authData.subscribeStatus !== false ||
                    this.state.productDetailsData.is_purchased ||
                    this.state.productDetailsData.is_free) && (
                      <a
                        href="javascript:;"
                        onClick={this.reviewProductModalFunction}
                      >
                        {strings.addComments}
                      </a>
                    )}
                </span>
              ) : (
                <a href="/sign-in" title="">
                  {strings.signAddComments}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const front_base_url = window.location.origin;
    var { productLastPlayedTrackData, productDetailsData, showPlayAllButton } =
      this.state;
    return (
      <div>
        <div className="container productDetailsMain_Wrapper">
          {this.state.isLoading ? (
            <div id="loading">
              <div id="loading-center">
                <div id="loading-center-absolute">
                  <img src="images/loader.gif" />
                </div>
              </div>
            </div>
          ) : null}
          {this.state.productDetailsData["custom_translation"] != undefined && (
            <Row className="productDetails-container">
              <Col md="5" className="popularImage_Wrap">
                <div>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.productDetailsData.name}</title>
                    <meta property="og:url" content={api_base_url} />
                    <meta property="og:type" content="article" />
                    <meta
                      property="og:title"
                      content={this.state.productDetailsData.name}
                    />
                    <meta property="og:description" content="" />
                    <meta
                      property="og:image"
                      content={
                        this.state.productDetailsData.images.length > 0 &&
                        this.state.productDetailsData.images[0]["image3"]
                      }
                    />
                  </Helmet>
                  <div className="popularImage_Wrapper">
                    {this.state.productDetailsData.images.length > 0 ? (
                      <Fragment>
                        {this.state.productDetailsData["is_free"] && (
                          <span className="freeProduct">
                            {strings.FreeText}
                          </span>
                        )}
                        <img
                          src={
                            this.state.productDetailsData.images[0]["image3"]
                          }
                          alt="video"
                          className="popularImage"
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        {this.state.productDetailsData["is_free"] && (
                          <span className="freeProduct">
                            {strings.FreeText}
                          </span>
                        )}
                        <img
                          src={"/images/default.jpg"}
                          alt="video"
                          className="popularImage"
                        />
                      </Fragment>
                    )}
                  </div>
                  <div className="audioPlayer_Wrapper">
                    {this.state.popularTitleSelectedAudio.length > 0 ? (
                      <AudioPlayer
                        rearrange={rearrangedPlayer}
                        audioFiles={this.state.popularTitleSelectedAudio}
                        playIcon={"/images/Play.png"}
                        playHoverIcon={"/images/Play.png"}
                        pauseIcon={"/images/Puse.png"}
                        pauseHoverIcon={"/images/Puse.png"}
                        forwardIcon={"/images/forword.png"}
                        forwardHoverIcon={"/images/forword.png"}
                        rewindIcon={"/images/rewind.png"}
                        rewindHoverIcon={"/images/rewind.png"}
                        volumeIcon={"/images/speaker.png"}
                        volumeEngagedIcon={"/images/speaker.png"}
                        muteEngagedIcon={"/images/speaker.png"}
                      />
                    ) : (
                      <div className="blankAudioPlayer">
                        <AudioPlayer
                          rearrange={rearrangedPlayer}
                          audioFiles={[""]}
                          playIcon={"/images/Play.png"}
                          playHoverIcon={"/images/Play.png"}
                          pauseIcon={"/images/Puse.png"}
                          pauseHoverIcon={"/images/Puse.png"}
                          forwardIcon={"/images/forword.png"}
                          forwardHoverIcon={"/images/forword.png"}
                          rewindIcon={"/images/rewind.png"}
                          rewindHoverIcon={"/images/rewind.png"}
                          volumeIcon={"/images/speaker.png"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="7">
                <div class="product-summery">
                  <h4>
                    {this.state.productDetailsData.custom_translation[
                      this.state.selectedLanguage
                    ] !== undefined &&
                      this.state.productDetailsData.custom_translation[
                        this.state.selectedLanguage
                      ].name}
                  </h4>
                  <span class="productPostType">
                    {this.state.productDetailsData.product_audio_type ===
                      "audiobook" ? (
                      <span>{strings.audiobook}</span>
                    ) : this.state.productDetailsData.product_audio_type ===
                      "snoveloriginal" ? (
                      <span>{strings.snoveloriginal}</span>
                    ) : this.state.productDetailsData.product_audio_type ===
                      "kids" ? (
                      <span></span>
                    ) : this.state.productDetailsData.product_audio_type ===
                      "languagelab" ? (
                      <span></span>
                    ) : (
                      <span>{strings.podcast}</span>
                    )}
                  </span>
                  <div className="product-content-details">
                    {this.state.productDetailsData.authors.length > 0 && (
                      <div className="productDetails_content">
                        <div className="headingText">
                          <span className="author">
                            {!this.state.productDetailsData.recurring_product
                              ? strings.authorText
                              : strings.hostText}
                          </span>
                        </div>
                        <div className="colon-symbol">:</div>
                        <div className="loopdata">
                          <span
                            className="authorDes authorDesLink"
                            onClick={() => {
                              this.authorDetailsId(
                                this.state.productDetailsData.authors[0].id,
                                this.state.productDetailsData.authors[0].name
                              );
                            }}
                          >
                            {this.authorsSelected(
                              this.state.productDetailsData.authors
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                    {!this.state.productDetailsData.recurring_product &&
                      this.state.productDetailsData.directors.length > 0 && (
                        <div className="productDetails_content">
                          <div className="headingText">
                            <span className="author">
                              {strings.directorText}
                            </span>
                          </div>
                          <div className="colon-symbol">:</div>
                          <div className="loopdata">
                            <span className="authorDes">
                              {this.directorsSelected(
                                this.state.productDetailsData.directors
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                    {!this.state.productDetailsData.recurring_product &&
                      this.state.productDetailsData.artists.length > 0 && (
                        <div className="productDetails_content">
                          <div className="headingText">
                            <span className="author">
                              {strings.artistsText}
                            </span>
                          </div>
                          <div className="colon-symbol">:</div>
                          <div className="loopdata">
                            <span className="authorDes">
                              {this.artistsSelected(
                                this.state.productDetailsData.artists
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                    {!this.state.productDetailsData.recurring_product && (
                      <div className="productDetails_content">
                        {this.state.productDetailsData.product_audio_type !==
                          "kids" && (
                            <Fragment>
                              <div className="headingText">
                                <span className="author">
                                  {strings.genreText}
                                </span>
                              </div>
                              <div className="colon-symbol">:</div>
                            </Fragment>
                          )}
                        {this.state.productDetailsData.product_audio_type ===
                          "kids" &&
                          this.state.productDetailsData["kid_category"] &&
                          this.state.productDetailsData["kid_category"].length >
                          0 && (
                            <Fragment>
                              <div className="headingText">
                                <span className="author">
                                  {strings.genreText}
                                </span>
                              </div>
                              <div className="colon-symbol">:</div>
                            </Fragment>
                          )}
                        {this.state.productDetailsData.product_audio_type ===
                          "kids" ? (
                          <div className="loopdata">
                            {this.state.productDetailsData["kid_category"] &&
                              this.state.productDetailsData["kid_category"]
                                .length > 0 &&
                              this.state.productDetailsData.kid_category.map(
                                (categoryList, index) => {
                                  if (
                                    categoryList.custom_translation[
                                    this.state.selectedLanguage
                                    ] != undefined
                                  ) {
                                    return (
                                      <NavLink
                                        tag={Link}
                                        to={{
                                          pathname: "/browse",
                                        }}
                                        onClick={() => {
                                          this.props.dispatch({
                                            type: "MENU_SELECTED_CATEGORY",
                                            payload: [
                                              categoryList.name,
                                              this.state.productDetailsData
                                                .product_audio_type,
                                              categoryList.id,
                                            ],
                                          });
                                          this.props.dispatch(
                                            browseCategoryProductDetailsDataAction(
                                              this.state.productDetailsData
                                                .product_audio_type,
                                              categoryList.name,
                                              1
                                            )
                                          );
                                        }}
                                        key={index}
                                        className="genreLinkText"
                                      >
                                        <span key={index}>
                                          {
                                            categoryList.custom_translation[
                                              this.state.selectedLanguage
                                            ].name
                                          }
                                        </span>
                                      </NavLink>
                                    );
                                  }
                                }
                              )}
                          </div>
                        ) : (
                          <div className="loopdata">
                            {this.state.productDetailsData.category.map(
                              (categoryList, index) => {
                                if (
                                  categoryList.custom_translation[
                                  this.state.selectedLanguage
                                  ] != undefined
                                ) {
                                  return (
                                    <NavLink
                                      tag={Link}
                                      to={{
                                        pathname: "/browse",
                                      }}
                                      onClick={() => {
                                        this.props.dispatch({
                                          type: "MENU_SELECTED_CATEGORY",
                                          payload: [
                                            categoryList.name,
                                            this.state.productDetailsData
                                              .product_audio_type,
                                            categoryList.id,
                                          ],
                                        });
                                        this.props.dispatch(
                                          browseCategoryProductDetailsDataAction(
                                            this.state.productDetailsData
                                              .product_audio_type,
                                            categoryList.name,
                                            1
                                          )
                                        );
                                      }}
                                      key={index}
                                      className="genreLinkText"
                                    >
                                      <span key={index}>
                                        {
                                          categoryList.custom_translation[
                                            this.state.selectedLanguage
                                          ].name
                                        }
                                      </span>
                                    </NavLink>
                                  );
                                }
                              }
                            )}
                          </div>
                        )}
                      </div>
                    )}


                    {

                      <>

                        <Modal isOpen={this.state.modal} toggle2={this.toggle2}
                          style={{ marginTop: "20vh", padding: "10vh" }}
                        >
                          <ModalHeader toggle2={this.toggle2}>Subscribe</ModalHeader>
                          <ModalBody>
                            Please subscribe or purchase the product to listen
                          </ModalBody>
                          <ModalFooter>
                            <Button onClick={this.toggle2}
                              class="button" style={{
                                marginLeft: '5px', border: "2px solid #da145a", padding: "10px 20px", borderRadius:
                                  '30px', backgroundColor: "#da145a"
                              }}

                            >
                              <a href="/subscribe" style={{ color: "white" }}>
                                {strings.subscribe}</a></Button>{' '}
                            <Button onClick={this.toggle2}

                              style={{
                                border: "1px solid white", borderRadius: '30px'
                                , color: "#da145a", backgroundColor: "white"
                              }}
                            ><span className="subscribe_btn" >cancel</span></Button>

                          </ModalFooter>

                        </Modal>

                      </>
                    }


                    <div className="productDetails_content">
                      <div className="headingText">
                        <span className="author">{strings.durationText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span className="authorDes">
                          {getHoursFormat(
                            this.state.productDetailsData.total_tracks_length
                          ) +
                            " " +
                            strings.hrsText}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="shareDiv">
                    <div className="starRating">
                      <StarRatings
                        rating={this.state.productDetailsData.total_reviews}
                        starRatedColor="#ff8c00"
                        numberOfStars={5}
                        name="rating"
                        starDimension="20px"
                      />
                    </div>
                    <div className="shareIconDiv">
                      <span className="shareIcon">
                        <a href="javascript:;">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/share_icon.png"
                            }
                            alt=""
                            onClick={this.popOverToggle}
                          />
                        </a>
                      </span>
                      <div
                        className="popupShareButton productDetailShare"
                        style={{
                          display: this.state.popoverOpenShare
                            ? "block"
                            : "none",
                        }}
                      >
                        <div className="popupShareButton_inline">
                          <FacebookShareButton
                            url={
                              front_base_url +
                              "/product-details/" +
                              this.state.productDetailsData.id +
                              "/" +
                              this.state.productDetailsData.name
                            }
                            quote={this.state.productDetailsData.name}
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={
                              this.getSharingContent() +
                              front_base_url +
                              "/product-details/" +
                              this.state.productDetailsData.id +
                              "/" +
                              this.state.productDetailsData.name
                            }
                            quote={this.state.productDetailsData.name}
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                          <WhatsappShareButton
                            url={
                              this.getSharingContent() +
                              front_base_url +
                              "/product-details/" +
                              this.state.productDetailsData.id +
                              "/" +
                              this.state.productDetailsData.name
                            }
                            quote={this.state.productDetailsData.name}
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                          <PinterestShareButton
                            url={
                              this.getSharingContent() +
                              front_base_url +
                              "/product-details/" +
                              this.state.productDetailsData.id +
                              "/" +
                              this.state.productDetailsData.name
                            }
                            media={`${String(
                              this.state.productDetailsData.images.length > 0 &&
                              this.state.productDetailsData.images[0][
                              "image3"
                              ]
                            )}`}
                          >
                            <PinterestIcon size={32} round />
                          </PinterestShareButton>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="videoPrice">
                    {!this.state.productDetailsData.is_free && (
                      <div>

                        {this.state.currcountry == "IN" ? (
                          <Fragment>
                            {this.state.currcountry == "IN"
                              ? strings.priceSign +
                              "" +
                              this.state.productDetailsData.inr_price.amount
                              : "$" +
                              this.state.productDetailsData.price.amount}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {this.state.currcountry === "IN"
                              ? strings.priceSign +
                              " " +
                              this.state.productDetailsData.inr_price.amount
                              : "$" +
                              this.state.productDetailsData.price.amount}
                          </Fragment>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="videoPurchase">
                    {this.props.authData.subscribeStatus === false ||
                      this.props.authData.subscribeStatus == "" ? (
                      <div className="mobileButtons">
                        {this.state.productDetailsData.subscription_only === true && this.state.Product_reps === false && (
                          <span className="subsCribeButton">

                            <a href="/subscribe"
                            >
                              {strings.subscribe}
                            </a>
                          </span>

                        )}
                        {this.state.productDetailsData.is_free === true
                          && (
                            <span className="purchaseButton">
                              <a
                                className="mb-2"
                                href="javascript:;"
                                onClick={() => {
                                  this.cartProductsModal(
                                    this.state.productDetailsData.variant
                                  );
                                }}
                              >
                                {strings.addToLibrary}
                              </a>
                            </span>
                          )}
                        {this.state.productDetailsData.is_purchased === true ? (
                          <span className="purchaseButton">
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.goToLibraryProduct(
                                  this.state.productDetailsData.id
                                );
                              }}
                            >
                              {strings.goToLibrary}
                            </a>
                          </span>
                        ) : (
                          <Fragment>
                            {this.state.productDetailsData.inr_price.amount >
                              0 && this.state.productDetailsData.purchase_only === true && (
                                <span className="purchaseButton">
                                  <a
                                    href="javascript:;"
                                    onClick={() => {
                                      this.cartProductsModal(
                                        this.state.productDetailsData.variant
                                      );
                                    }}
                                  >
                                    {strings.purchaseTitle}
                                  </a>
                                </span>
                              )}{" "}
                          </Fragment>
                        )}
                      </div>
                    ) : (
                      <Fragment>
                        {this.state.productDetailsData.is_free === true && (
                          <span className="purchaseButton">
                            <a
                              className="mb-2 mr-2"
                              href="javascript:;"
                              onClick={() => {
                                this.addToLibraryProduct(
                                  this.state.productDetailsData.id
                                );
                              }}
                            >
                              {strings.addToLibrary}
                            </a>
                          </span>
                        )}
                        {this.state.productDetailsData.is_purchased ===
                          true && (
                            <span className="purchaseButton">
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.goToLibraryProduct(
                                    this.state.productDetailsData.id
                                  );
                                }}
                              >
                                {strings.goToLibrary}
                              </a>
                            </span>
                          )}
                      </Fragment>
                    )}
                    {this.props.authData.loginSuccess ? (
                      <Fragment>
                        {!this.state.productDetailsData.is_free === true && (
                          <span className="purchaseButton gift-button product-details-gift-button">
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.giftProductToOther(
                                  this.state.productDetailsData.variant
                                );
                              }}
                            >
                              {strings.Gift}
                            </a>
                          </span>
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {!this.state.productDetailsData.is_free === true && (
                          <span className="purchaseButton gift-button product-details-gift-button">
                            <a href="/sign-in">{strings.Gift}</a>
                          </span>
                        )}
                      </Fragment>
                    )}
                    {
                      this.state.subId > 6 ?
                        <span className="subsCribeButton">

                          <a href="/subscribe"
                          >
                            {strings.subscribe}
                          </a>
                        </span> : ""
                    }

                    {showPlayAllButton && this.state.subId > 6 && this.state.Product_reps === false
                      ? (
                        <span className="purchaseButton">
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.cartProductsModal(
                                this.state.productDetailsData.variant
                              );
                            }}
                          >
                            {strings.purchaseTitle}
                          </a>
                        </span>) :
                      (this.state.tokenToCheck == "" && this.state.Product_reps) ?
                        <span className="purchaseButton play-all-button">
                          {/* {console.log("is_free frllmlllll", this.state.Product_reps)} */}
                          <a
                            onClick={this.toggle}
                            className="mr-2"
                            href="javascript:;"
                            onClick={() => {
                              this.cartProductsModal(
                                this.state.productDetailsData.variant
                              );
                            }}
                          >
                            {strings.playAll}
                          </a>
                        </span>

                        : (this.state.tokenToCheck && (!this.state.Product_reps || this.state.Product_reps)) ?
                          <span className="purchaseButton play-all-button">
                            {/* {console.log("is_free", this.state.tokenToCheck)} */}
                            <a
                              onClick={this.toggle}
                              className="mr-2"
                              href="javascript:;"
                              onClick={() => {
                                this.playProductTrack(0)
                              }}
                            >
                              {strings.playAll}
                            </a>
                          </span> : ""


                    }



                    {this.state.cartFailureMessage.length > 0 && (
                      <div className="loginErrorMsg">
                        {this.state.cartFailureMessage}
                      </div>
                    )}
                  </div>
                  <div className="browseDetailsVideo">
                    <span>{strings.weRecommend}</span>
                  </div>
                </div>
              </Col>
              <div class="productDescript_Wrapper">
                <Row>
                  <Col
                    className={
                      productDetailsData.recurring_product
                        ? "col-md-5"
                        : "col-md-7"
                    }
                  >
                    {this.state.productDetailsData.custom_translation[
                      this.state.selectedLanguage
                    ].description.length > 0 && (
                        <div
                          className={
                            this.state.showLargeDescription
                              ? "product-description large"
                              : "product-description"
                          }
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.productDetailsData.custom_translation[
                                this.state.selectedLanguage
                              ].description,
                          }}
                        ></div>
                      )}
                    {this.state.productDetailsData.custom_translation[
                      this.state.selectedLanguage
                    ].description.length > 1000 && (
                        <div className="readMoreDesc" onClick={this.readMoreDesc}>
                          <span>
                            {!this.state.showLargeDescription
                              ? strings.readMoreText
                              : strings.LessText}
                          </span>
                        </div>
                      )}
                    <div className="haveQuery">
                      <h2>{strings.queryPurchasing}</h2>
                      <p>
                        {strings.ContactUs}:
                        <a href="mailto:hello@snovel.in">hello@snovel.in</a>
                      </p>
                    </div>
                    {productDetailsData.recurring_product && (
                      <Fragment>{this.showReviewSection()}</Fragment>
                    )}
                  </Col>
                  <Col
                    className={
                      productDetailsData.recurring_product
                        ? "col-md-7"
                        : "col-md-5"
                    }
                  >
                    {productDetailsData.recurring_product &&
                      productLastPlayedTrackData.length > 0 && (
                        <div className="episodsWrapper">
                          {this.productLastEpisodsTracks(
                            productLastPlayedTrackData
                          )}
                        </div>
                      )}
                    {!productDetailsData.recurring_product && (
                      <Fragment>{this.showReviewSection()}</Fragment>
                    )}
                  </Col>
                </Row>
              </div>
            </Row>
          )}
          {this.state.categorySliderData.length > 0 ? (
            <div className="singlePage-productSlider">
              {this.state.productDetailsData["custom_translation"] !=
                undefined && (
                  <h2>
                    {this.categorySelected(
                      this.state.productDetailsData.category
                    )}{" "}
                    from{" "}
                    <span>
                      {this.state.productDetailsData.product_audio_type ===
                        "audiobook" ? (
                        <span>{strings.audiobook}</span>
                      ) : this.state.productDetailsData.product_audio_type ===
                        "snoveloriginal" ? (
                        <span>{strings.snoveloriginal}</span>
                      ) : this.state.productDetailsData.product_audio_type ===
                        "kids" ? (
                        <span>{strings.kidsMenu}</span>
                      ) : this.state.productDetailsData.product_audio_type ===
                        "languagelab" ? (
                        <span>{strings.languageLab}</span>
                      ) : (
                        <span>{strings.podcast}</span>
                      )}
                    </span>
                  </h2>
                )}
              <div className="authorSlider">
                <OwlCarousel ref="car" options={optionss}>
                  {this.singleProductSlider()}
                </OwlCarousel>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>


        {/* <div className="subBenefit_Wrapeer">
          <div className="container">
            <Row>
              <Col xs="12" md="12" lg="4">
                <h2>{strings.benefitOfOneHead}</h2>
                <p>{strings.benefitOfOne}</p>
              </Col>
              <Col xs="12" md="12" lg="4" className="subsCriptionMid">
                <h2>{strings.benefitOfTwoHead}</h2>
                <p>{strings.benefitOfTwo}</p>
              </Col>
              <Col xs="12" md="12" lg="4">
                <h2>{strings.benefitOfThreeHead}</h2>
                <p>{strings.benefitOfThree}</p>
              </Col>
            </Row>
            <div class="subsCriBtnBtm">
              <span class="subsCribeButton">
                <a href="/subscribe">{strings.subscribeNow}</a>
              </span>
            </div>
          </div>
        </div> */}



        <div className="container-fluid stellar-wrapper-footer">
          <a className="subscribe_btn-audio " href="/subscribe">{strings.subscribeNow}</a>
          {
            strings._language === strings._defaultLanguage ? (
              <img src="/images/stellar audio.png"
                className="home-stellar-kids-img"

              />
            ) : (
              <img src="/images/snovel web home marathi 1-06.png"
                className="home-stellar-kids-img"

              />
            )
          }
        </div>



        <Modal
          isOpen={this.state.giftProductModal}
          className={"giftProduct-model"}
        >
          <ModalHeader toggle={this.closeGiftProductModal}>
            <img
              src={process.env.PUBLIC_URL + "/images/susbcription_bx.png"}
              alt=""
            />{" "}
            {strings.giftProduct}
          </ModalHeader>
          <ModalBody>
            <div className="giftSubscriptionForm">
              <div className="giftSubscriptionHeader"></div>
              <Form onSubmit={this.onSubmitGift}>
                <div className="giftSubscriptionFrmLft">
                  <Input
                    type="text"
                    name="fname"
                    id="fname"
                    placeholder={strings.firstName}
                    value={this.state.firstName}
                    onChange={(event) => {
                      this.setState({ firstName: event.target.value });
                    }}
                  />
                  <div class="loginErrorMsg">{this.state.firstNameError}</div>
                </div>
                <div className="giftSubscriptionFrmLfRgt">
                  <Input
                    type="text"
                    name="lname"
                    id="lname"
                    placeholder={strings.lastName}
                    value={this.state.lastName}
                    onChange={(event) => {
                      this.setState({ lastName: event.target.value });
                    }}
                  />
                  <div class="loginErrorMsg">{this.state.lastNameError}</div>
                </div>
                <div className="giftSubscriptionFrmFull">
                  <Input
                    type="text"
                    name="email"
                    id="userEmail"
                    maxlength="50"
                    placeholder={strings.regiEmail}
                    value={this.state.email}
                    onChange={(event) => {
                      this.setState({ email: event.target.value });
                    }}
                  />
                  <div class="loginErrorMsg">{this.state.emailError}</div>
                </div>
                <div className="giftSubscriptionFrmFull">
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="text"
                      placeholder={strings.messageText}
                      value={this.state.message}
                      maxlength="500"
                      onChange={(event) => {
                        this.setState({ message: event.target.value });
                      }}
                    />
                  </FormGroup>
                  <div class="loginErrorMsg">{this.state.messageError}</div>
                </div>
                <div className="giftSubscriptionFrmFull text-center">
                  <input
                    type="submit"
                    className="giftSubmit giftSubmitWrap"
                    value={strings.Gift}
                  />
                </div>
              </Form>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.giftProductModalDetail}
          className={"giftProduct-model"}
        >
          <ModalHeader toggle={this.closeGiftProductModal}></ModalHeader>
          <ModalBody>
            <div className="giftSubscriptionForm">
              <div class="giftSubscriptionGift">
                <div class="ontheWay">
                  <div class="giftSubscriptionHeader bgGiftontheway">
                    <img
                      src={process.env.PUBLIC_URL + "/images/GiftWay.png"}
                      alt=""
                    />
                  </div>
                  <div class="yourGift">{strings.giftOnTheWay}</div>
                  <div className="yourProdctDivName">
                    <div className="yourPrdNameCheckLft left__wrapper">
                      {strings.CouponCode}:
                    </div>
                    <div className="yourPrdNameCheckRgt right__wrapper">
                      <input
                        type="text"
                        name="coupon_code"
                        value={this.state.coupneVoucher}
                        onChange={this.handleCoupenValue}
                      />
                      {!this.state.isAddedCoupon && (
                        <input
                          type="button"
                          value={strings.ApplyCoupon}
                          className="applyCoupen"
                          onClick={this.applyCoupen}
                        />
                      )}
                      {this.state.isAddedCoupon && (
                        <input
                          type="button"
                          value={strings.RemoveCoupon}
                          className="applyCoupen"
                          onClick={this.removeCopenCode}
                        />
                      )}
                      {this.state.couponError.length > 0 && (
                        <div className="cuponErrorMessage">
                          {this.state.couponError}
                        </div>
                      )}
                      {this.state.cuponMessageSuccess.length > 0 && (
                        <div className="cuponSuccessMessage">
                          {this.state.cuponMessageSuccess}
                        </div>
                      )}
                      {this.state.cuponMessage.length > 0 && (
                        <div className="cuponErrorMessage">
                          {this.state.cuponMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="yourGiftCnt pt-1">
                    <p>
                      {this.state.firstName}
                      <br />
                      {this.state.email}
                      <br />
                      {this.getProductName()}
                    </p>
                    <div className="paypmentTerms">
                      <label className="checkbox">
                        {strings.ReadAndAcceptThe}
                        <input
                          type="checkbox"
                          name="acceptTerm"
                          onChange={(event) => {
                            this.setState({ acceptTerm: event.target.checked });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <a href="/terms-of-service" title="Terms Of Service">
                        {" "}
                        {strings.termsAndConditions}*{" "}
                      </a>
                    </div>
                    <div class="loginErrorMsg">
                      {this.state.acceptTermError}
                    </div>
                    <div class="yourGiftProceed">
                      <a
                        href="javascript:;"
                        onClick={this.makePaymentGiftedProduct}
                      >
                        {strings.ProceedForPayment}
                      </a>
                    </div>
                    <div class="yourGiftCancel">
                      <a href="javascript:;" onClick={this.cancelGiftPayment}>
                        {strings.Cancel}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.reviewProductModal}
          className={"reviewProduct-model"}
          id="sharefeedbackpopup"
        >
          {this.state.productDetailsData["custom_translation"] != undefined && (
            <div>
              <ModalHeader toggle={this.reviewGiftProductModal}>
                <button
                  type="button"
                  class="close closeReview"
                  data-dismiss="modal"
                  onClick={this.closeReviewModel}
                >
                  &times;
                </button>
                <h4 class="modal-title">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/share_icon_popup.png"
                    }
                    alt=""
                    class="shareFeedIcon"
                  />
                  Share your feedback
                </h4>
                <div class="checkoutIndWrp">
                  <div class="checkoutIndImg">
                    {this.state.productDetailsData.images.length > 0 ? (
                      <img
                        src={this.state.productDetailsData.images[0]["image2"]}
                        alt="video"
                        className="popularImage"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="checkoutIndCnt">
                    <h4>
                      {this.state.productDetailsData.custom_translation[
                        this.state.selectedLanguage
                      ] !== undefined &&
                        this.state.productDetailsData.custom_translation[
                          this.state.selectedLanguage
                        ].name}
                    </h4>
                    <span className="author">{strings.authorText}</span> :{" "}
                    <span
                      className="authorDes authorDesLink"
                      onClick={() => {
                        this.authorDetailsId(
                          this.state.productDetailsData.authors[0].id,
                          this.state.productDetailsData.authors[0].name
                        );
                      }}
                    >
                      {this.authorsSelected(
                        this.state.productDetailsData.authors
                      )}
                    </span>
                  </div>
                </div>
              </ModalHeader>
              <ModalBody>
                <Form className="review-form-wrapper">
                  <div class="startCntFeedWrp">
                    <div class="starCntFeed">{strings.OverallExperience}</div>
                    <div class="starCntFeedRating">
                      <div class="starRating">
                        <StarRatings
                          rating={this.state.rating}
                          numberOfStars={5}
                          name="rating"
                          starDimension="25px"
                          isSelectable={true}
                          starRatedColor="#f3a111"
                          changeRating={this.changeRating}
                        />
                        <div class="loginErrorMsg">
                          {this.state.ratingRequire}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="startCntFeedWrp">
                    <div class="starCntFeed">{strings.ReviewTitle}</div>
                    <div class="starCntFeedRating">
                      <Input
                        type="text"
                        name="fname"
                        id="reviewTitle"
                        maxlength="100"
                        value={this.state.reviewTitle}
                        onChange={(event) => {
                          this.setState({ reviewTitle: event.target.value });
                        }}
                      />
                      <div class="loginErrorMsg">
                        {this.state.reviewTitleError}
                      </div>
                    </div>
                  </div>
                  <div class="startCntFeedWrp">
                    <div class="starCntFeed myFeedbackTextarea">
                      {strings.MyFeedback}
                    </div>
                    <div class="starCntFeedRating myFeedbackTextarea marginBtm">
                      <span class="maxiRight">{strings.MaximumWords}</span>
                      <Input
                        type="textarea"
                        name="text"
                        id="reviewText"
                        maxlength="100"
                        value={this.state.reviewBody}
                        onChange={(event) => {
                          this.setState({ reviewBody: event.target.value });
                        }}
                      />
                      <div class="loginErrorMsg">
                        {this.state.reviewBodyError}
                      </div>
                    </div>
                  </div>
                  <div class="startCntFeedWrp">
                    <div class="starCntFeed"></div>
                    <div class="starCntFeedRating myFeedbackTextarea">
                      <input
                        type="submit"
                        value="Submit"
                        onClick={this.onSubmitReview}
                      />
                      <input
                        type="button"
                        value="Cancel"
                        class="cancelfeed"
                        onClick={this.cancelReview}
                      />
                    </div>
                  </div>
                </Form>
              </ModalBody>{" "}
            </div>
          )}
        </Modal>
        <Modal
          isOpen={this.state.reviewProductModalSuccess}
          className={"reviewProduct-model"}
          id="feedsuccessMess"
        >
          <ModalHeader toggle={this.reviewCloseModel}></ModalHeader>
          <ModalBody>
            <div class="welcomeBack">
              <img
                src={process.env.PUBLIC_URL + "/images/rightFeedbackimg.png"}
                alt=""
              />
              <p class="access">
                <strong>Thank you for submitting your feedback.</strong>
                <br />
                <span>
                  As per our policy, we will review this feeddback and it will
                  appear on the Snovel Platform in next 24 hours.
                </span>
              </p>
              <p class="liraryBtnFav">
                <span class="subsCribeButton">
                  <a href="javascript:;" onClick={this.reviewCloseModel}>
                    Ok
                  </a>
                </span>
              </p>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    languageTab: state.languageTab,
    modelSelectedFunction: state.modelSelectedFunction,
    authData: state.authonticationDetails,
    selectedProductToPlay: state.selectedProductToPlay,
    isIndian: state.isReisteredDevice.isIndian,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(productDetails));
