import axios from 'axios';
import { api_base_url } from '../config';

export function getRegisteredDeviceLocation(apiParams) {
    return function(dispatch){
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': "Token "+apiParams.token
        };
        axios.post(api_base_url+'/api/v1/register-device/', apiParams, {headers:headers}).then(response => {
            if(response.data.status){
                dispatch({
                    type: 'REGISTERED_DEVICE_SUCCESS',
                    payload: response.data.is_indian
                });
            }else{
                dispatch({
                    type: 'REGISTERED_DEVICE_SUCCESS',
                    payload: response.data.is_indian
                });
            }
        }).catch(err => {
            dispatch({
                type: 'REGISTERED_DEVICE_FAIL',
                payload: null
            });
        });
    }
}