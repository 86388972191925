const initialState = {
    selectedProduct: {},
    selectedTrackIndex: 0,
    isPauseSelectedTrack: false,
    isPlaySelectedTrack: false,
    isPauseFromPlayer: false,
    isPlayFromPlayer: false,
    isPlayFromSection: 'purchasedInLibrary'
  }

  export default function selectedProductToPlay (state = initialState, action) {
    switch (action.type) {
        case 'PLAY_SELECTED_PRODUCT': {
            var newSelectedProduct = action.payload[0];
            var newTrackIndex = action.payload[1];
            var isPauseTrack = action.payload[2];
            var isPlayTrack = action.payload[3];
            var isPausePlayer = action.payload[4];
            var isPlayPlayer = action.payload[5];
            var isFromSection = action.payload[6];
            return { ...state, 
                selectedProduct: newSelectedProduct, 
                selectedTrackIndex: newTrackIndex, 
                isPauseSelectedTrack: isPauseTrack, 
                isPlaySelectedTrack: isPlayTrack, 
                isPauseFromPlayer: isPausePlayer, 
                isPlayFromPlayer: isPlayPlayer, 
                isPlayFromSection: isFromSection};
        }
        default: {
            return { ...state };
        }
    }
  }