import axios from 'axios';
import { api_base_url } from '../config';

export function orderProductAction(userToken, offset, limit) {
    return function (dispatch) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': "Token " + userToken
        };
        var apiParams = {
            offset: offset,
            limit: limit
        }

        axios.post(api_base_url + '/api/v1/my-orders/', apiParams, { headers: headers }).then(response => {
            if (response.data.status === true) {
                dispatch({
                    type: "MY_ORDERS_DATA",
                    payload: response.data
                });
            } else {
                dispatch({
                    type: "FAILED_MY_ORDERS_DATA",
                    payload: response.data
                });
            }
        }).catch(err => {
            // console.log('fetched.', err);
        });
    }
}