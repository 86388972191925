const initialState = {
    userProfileDetails: {}
}

export default function auditionFormReducer (state = initialState, action) {
    switch (action.type) {
        case 'USER_DETAILS_FOR_AUDITION': {
            return { ...state, userProfileDetails: action.payload};
        }
        case 'FAILED_USER_DETAILS_FOR_AUDITION': {
            return { ...state};
        }
        default: {
            return { ...state };
        }
    }
}