import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import { Button, Form, FormGroup, Input, Row, Col, NavLink } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import {
  api_base_url,
  guestSubscriptionToken,
  cartToken,
  encryptDecryptKey,
  googleAnalyticsTrackingId,
} from "../config";
import { connect } from "react-redux";
import LocalizedStrings from "react-localization";
import { priceConversion } from "../utlity";
import SimpleCrypto from "simple-crypto-js";
import languages from "../language/language.json";
import { subscriptionListAction } from "../action/subscriptionAction";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

class subscribeSnovel extends Component {
  constructor(props) {
    super(props);
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }

    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    var userEmail = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
        userEmail = userDetailsObject.userEmail || "";
      }
    }

    this.state = {
      isLoading: false,
      isSubscriptionAPIcall: false,
      subscribePackages: [],
      token: token || "",
      userEmail: userEmail || "",
      varientValue: "",
      selectedLanguage: selectedLanguage || "EN",
      cartFailureMessage: "",
      isIndian: null,
      subscribeMessage: {},
      currcountry: ""
    };
    //  this.cartProductsModal = this.cartProductsModal.bind(this);
    ReactGA.pageview(this.props.location.pathname);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    if (!this.state.isSubscriptionAPIcall) {
      this.props.dispatch(subscriptionListAction());
    }
    this.giftSubscriptionMessage();
    this.getuserCountry();
  }


  getuserCountry = async () => {
    let getcountrycode;
    try {
      getcountrycode = await localStorage.getItem("currentcountrycode")
      this.setState({ currcountry: getcountrycode })
      // alert(this.state.currcountry)
    }
    catch (e) {

    }
  }

  subscribeIdCheckout(id, variant) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .get(api_base_url + "/api/otp/check-user-profile-status/", { headers: headers })
      .then((response) => {
        if (!response.data.is_profile_completed) {
          console.log(response.data.is_profile_completed);
          this.props.history.push({
            pathname: "/library",
            state: { is_new_user: "true", redirectionFromCart: "true"}
          })
          // this.props.history.push({
          //   pathname:"/library", 
          //   state: response.data.is_profile_completed})
          // this.toggleEditProfile()
        }
      else{
    var apiParams = {
      email: this.state.userEmail,
      discount_amount: 0.0,
      country_sub: this.state.countryCode,
      discount_amount_inr: 0.0,
      gift_cart: false,
      cart_lines: [
        {
          variant: variant,
          quantity: 1,
          data: {},
        },
      ],
    };

    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/cart/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status === true) {
          this.props.dispatch({
            type: "PRODUCT_CART_COUNT",
            payload: response.data.data.quantity,
          });
          this.props.dispatch({ type: "MODEL_SELECTED", payload: true });
        }
        if (response.data.status === false) {
          this.setState({
            cartFailureMessage: "Subscription already added in cart",
            subscriptionId: id,
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
        this.setState({
          cartFailureMessage: "Subscription already added in cart",
          subscriptionId: id,
        });
      });
    }
    })
  }

  redirectTOLogin() {
    this.props.history.push("/sign-in");
  }
  giftSubscriptionMessage() {
    var headers = {
      "Content-Type": "application/json",
      "guest-subscription-token": guestSubscriptionToken,
    };
    axios.get(api_base_url + "/api/gift-subscription/", { headers: headers })
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            subscribeMessage: response.data && response.data.data && response.data.data.length > 0 ? response.data.data[0] : {},
          });
        } else {
          // console.log("Not Loaded");
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        // console.log("fetched.", err);
        this.setState({ isLoading: false });
      });
  }

  renderSwitch(param) {
    switch (param) {

      case "30 days subscription":
        return this.state.currcountry === "IN" ? (
          <span className="pricelinethrow">Rs. 99</span>
        ) : (
          <span className="pricelinethrow">$4.9</span>
        );
      case "अमर्याद - 30 दिवस":
        return this.state.currcountry === "IN" ? (
          <span className="pricelinethrow">Rs. 99</span>
        ) : (
          <span className="pricelinethrow">$4.9</span>
        );
      case "90 days subscription":
        return this.state.currcountry === "IN" ? (
          <span className="pricelinethrow">Rs. 267</span>
        ) : (
          <span className="pricelinethrow">$12.99</span>
        );
      case "अमर्याद - ९० दिवस":
        return this.state.currcountry === "IN" ? (
          <span className="pricelinethrow">Rs. 267</span>
        ) : (
          <span className="pricelinethrow">$12.99</span>
        );
      case "365 days subscription":
        return this.state.currcountry === "IN" ? (
          <span className="pricelinethrow">Rs. 899</span>
        ) : (
          <span className="pricelinethrow">$39.99</span>
        );
      case "अमर्याद - ३६५ दिवस":
        return this.state.currcountry === "IN" ? (
          <span className="pricelinethrow">Rs. 899</span>
        ) : (
          <span className="pricelinethrow">$39.99</span>
        );
      case "Premium":
        return this.state.currcountry === "IN" ? (
          <span className="pricelinethrow">Rs. 2500</span>
        ) : (
          <span className="pricelinethrow">$18.90</span>
        );
      case "प्रीमियम":
        return this.state.currcountry === "IN" ? (
          <span className="pricelinethrow">Rs. 2500</span>
        ) : (
          <span className="pricelinethrow">$18.90</span>
        );
      default:
        return "none";
    }
  }

  // subStatedata() {
  //   return this.state.subscribePackages.map((subscribe, index) => {
  //     return (
  //       <>
  //         {subscribe.name}
  //       </>
  //     )
  //   })


  // }

  // subStatedataOne() {
  //   return this.state.subscribePackages.map((subscribe, index) => {
  //     return (
  //       <>
  //         {subscribe.custom_translation[this.state.selectedLanguage] !== undefined &&
  //           <div
  //             className="unlimiTedAnnulBx"
  //             dangerouslySetInnerHTML={{
  //               __html:
  //                 subscribe.custom_translation[this.state.selectedLanguage]
  //                   .description,
  //             }}
  //           ></div>}
  //       </>
  //     )
  //   })
  // }

  // SubscribeButton() {
  //   return this.state.subscribePackages.map((subscribe, index) => {
  //     return (

  //       <>
  //         {this.props.authData.loginSuccess ? (
  //           <span
  //             class="subsCribeButton"
  //             onClick={() => {
  //               this.subscribeIdCheckout(subscribe.id, subscribe.variant);
  //             }}
  //           >
  //             <a href="JavaScript:void(0);">{strings.subscribeNow}</a>
  //           </span>
  //         ) : (
  //           <span class="subsCribeButton">
  //             <a
  //               href="JavaScript:void(0);"
  //               onClick={() => {
  //                 this.redirectTOLogin();
  //               }}
  //             >
  //               {strings.subscribeNow}
  //             </a>
  //           </span>
  //         )}

  //       </>
  //     )
  //   })

  // }

  // errormessage() {
  //   return this.state.subscribePackages.map((subscribe, index) => {
  //     return (
  //       <>
  //         {this.state.cartFailureMessage.length > 0 &&
  //           this.state.subscriptionId === subscribe.id && (
  //             <div className="loginErrorMsg">
  //               {this.state.cartFailureMessage}
  //             </div>
  //           )}
  //       </>
  //     )
  //   })
  // }

  subscribeStateData() {
    return this.state.subscribePackages.map((subscribe, index) => {
      return (

        <Col xs="12" md="4" className="subscriptionBox_Wrapper"
          style={{ paddingTop: '20px', justifyContent: "space-between" }}>
          {/* {console.log("subscribe", subscribe.subscription_duration)} */}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                width: '105px', borderRadius: '50%', lineHeight: '105px',
                backgroundColor: '#da145a', textAlign: 'center', fontSize: '32px'
              }}>
              <div
                style={{
                  textAlign: 'center', justifyContent: 'center',
                  fontWeight: 900, color: 'white'
                }}
              >
                {index + 1}
              </div>
            </div>
          </div>
          <div className="unlimiTedAnnul" style={{ marginLeft: '15px' }}>
            {

              index <= 2 ? <h3>{strings.lang}</h3> : index === 3 ? <h3>{strings.premium}</h3> :
                index == 4 ? <h3>{strings.audionCourse}</h3> : ""

            }
            {/* {alert(this.props.authData.userCountry)} */}

            <br />
            <h2>
              {subscribe.custom_translation[this.state.selectedLanguage] !== undefined ? subscribe.custom_translation[this.state.selectedLanguage].name : ""}
            </h2>
            {/* <h3>{this.renderSwitch(subscribe.custom_translation[this.state.selectedLanguage].name)}{this.props.authData.userCountry === 'IN' ? strings.priceSign+' '+subscribe.inr_price.amount : '$'+subscribe.price.amount}</h3> */}
            <h3>
              {this.state.currcountry !== null && subscribe.can_purchase === true ?
                <Fragment>
                  {index === 0 && <Fragment>
                    {this.state.currcountry == "IN" ?
                      strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? subscribe.per_day_price : priceConversion(subscribe.per_day_price)) + " " + strings.perDays
                      : "$ " + (this.state.selectedLanguage == "EN" ? subscribe.price.amount : priceConversion(subscribe.price.amount))}
                  </Fragment>}
                  {index === 1 && <Fragment>
                    {this.state.currcountry == "IN" ?
                      strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? subscribe.per_day_price : priceConversion(subscribe.per_day_price)) + " " + strings.perDays
                      : "$ " + (this.state.selectedLanguage == "EN" ? subscribe.price.amount : priceConversion(subscribe.price.amount))}
                  </Fragment>}

                  {index === 2 && <Fragment>
                    {this.state.currcountry == "IN" ?
                      strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? subscribe.per_day_price : priceConversion(subscribe.per_day_price)) + " " + strings.perDays
                      : "$ " + (this.state.selectedLanguage == "EN" ? subscribe.price.amount : priceConversion(subscribe.price.amount))}
                  </Fragment>}
                  {index === 3 && <Fragment>
                    {this.state.currcountry == "IN" ?
                      strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? subscribe.per_day_price : priceConversion(subscribe.per_day_price)) + " " + strings.perDays
                      : "$ " + (this.state.selectedLanguage == "EN" ? subscribe.price.amount : priceConversion(subscribe.price.amount))}
                  </Fragment>}
                  {index === 4 && <Fragment>
                    {this.state.currcountry == "IN" ?
                      strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? subscribe.per_day_price : priceConversion(subscribe.per_day_price)) + " " + strings.perDays
                      : "$ " + (this.state.selectedLanguage == "EN" ? subscribe.price.amount : priceConversion(subscribe.price.amount))}
                  </Fragment>}
                </Fragment>
                :
                <Fragment>
                  {index === 0 && subscribe.can_purchase === true && <Fragment>
                    {this.state.currcountry === "IN"
                      ? strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? subscribe.per_day_price : priceConversion(subscribe.per_day_price)) + " " + strings.perDays
                      : "$ " + (this.state.selectedLanguage == "EN" ? subscribe.price.amount : priceConversion(subscribe.price.amount))}
                  </Fragment>}
                  {index === 1 && subscribe.can_purchase === true && <Fragment>
                    {this.state.currcountry === "IN"
                      ? strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? subscribe.per_day_price : priceConversion(subscribe.per_day_price)) + " " + strings.perDays
                      : "$ " + (this.state.selectedLanguage == "EN" ? subscribe.price.amount : priceConversion(subscribe.price.amount))}
                  </Fragment>}
                  {index === 2 && subscribe.can_purchase === true && <Fragment>
                    {this.state.currcountry === "IN"
                      ? strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? subscribe.per_day_price : priceConversion(subscribe.per_day_price)) + " " + strings.perDays
                      : "$ " + (this.state.selectedLanguage == "EN" ? subscribe.price.amount : priceConversion(subscribe.price.amount))}
                  </Fragment>}
                  {index === 3 && subscribe.can_purchase === true && <Fragment>
                    {this.state.currcountry === "IN"
                      ? strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? subscribe.per_day_price : priceConversion(subscribe.per_day_price)) + " " + strings.perDays
                      : "$ " + (this.state.selectedLanguage == "EN" ? subscribe.price.amount : priceConversion(subscribe.price.amount))}
                  </Fragment>}
                  {index === 4 && subscribe.can_purchase === true && <Fragment>
                    {this.state.currcountry === "IN"
                      ? strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? subscribe.per_day_price : priceConversion(subscribe.per_day_price)) + " " + strings.perDays
                      : "$ " + (this.state.selectedLanguage == "EN" ? subscribe.price.amount : priceConversion(subscribe.price.amount))}
                  </Fragment>}
                </Fragment>
              }
            </h3>
            <span style={{ marginRight: "0px" }}>
              {index === 0 && subscribe.can_purchase === true && this.state.currcountry === "IN"
                ? <Fragment>{subscribe.discounted_inr_price['amount'] && subscribe.discounted_inr_price['amount'] != "0.00" ? <span className="price-through">{strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? parseInt(subscribe.discounted_inr_price['amount']) : priceConversion(parseInt(subscribe.discounted_inr_price['amount']))) + " "}</span> : null} <span>{strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? Math.trunc(subscribe.inr_price.amount) : priceConversion(Math.trunc(subscribe.inr_price.amount))) + " "}</span> {strings.excludingGST}
                </Fragment> : ""}
            </span>
            <span style={{ marginRight: "0px" }}>
              {index === 1 && subscribe.can_purchase === true && this.state.currcountry === "IN"
                ? <Fragment>{subscribe.discounted_inr_price['amount'] && subscribe.discounted_inr_price['amount'] != "0.00" ? <span className="price-through">{strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? parseInt(subscribe.discounted_inr_price['amount']) : priceConversion(parseInt(subscribe.discounted_inr_price['amount']))) + " "}</span> : null} <span>{strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? Math.trunc(subscribe.inr_price.amount) : priceConversion(Math.trunc(subscribe.inr_price.amount))) + " "}</span> {strings.excludingGST}
                </Fragment> : ""}
            </span>
            <span style={{ marginRight: "0px" }}>
              {index === 2 && subscribe.can_purchase === true && this.state.currcountry === "IN"
                ? <Fragment>{subscribe.discounted_inr_price['amount'] && subscribe.discounted_inr_price['amount'] != "0.00" ? <span className="price-through">{strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? parseInt(subscribe.discounted_inr_price['amount']) : priceConversion(parseInt(subscribe.discounted_inr_price['amount']))) + " "}</span> : null} <span>{strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? Math.trunc(subscribe.inr_price.amount) : priceConversion(Math.trunc(subscribe.inr_price.amount))) + " "}</span> {strings.excludingGST}
                </Fragment> : ""}
            </span>
            <span style={{ marginRight: "0px" }}>
              {index === 3 && subscribe.can_purchase === true && this.state.currcountry === "IN"
                ? <Fragment>{subscribe.discounted_inr_price['amount'] && subscribe.discounted_inr_price['amount'] != "0.00" ? <span className="price-through">{strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? parseInt(subscribe.discounted_inr_price['amount']) : priceConversion(parseInt(subscribe.discounted_inr_price['amount']))) + " "}</span> : null} <span>{strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? Math.trunc(subscribe.inr_price.amount) : priceConversion(Math.trunc(subscribe.inr_price.amount))) + " "}</span> {strings.excludingGST}
                </Fragment> : ""}
            </span>
            <span style={{ marginRight: "0px" }}>
              {index === 4 && subscribe.can_purchase === true && this.state.currcountry === "IN"
                ? <Fragment>{subscribe.discounted_inr_price['amount'] && subscribe.discounted_inr_price['amount'] != "0.00" ? <span className="price-through">{strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? parseInt(subscribe.discounted_inr_price['amount']) : priceConversion(parseInt(subscribe.discounted_inr_price['amount']))) + " "}</span> : null} <span>{strings.priceSign + " " + (this.state.selectedLanguage == "EN" ? Math.trunc(subscribe.inr_price.amount) : priceConversion(Math.trunc(subscribe.inr_price.amount))) + " "}</span> {strings.excludingGST}
                </Fragment> : ""}
            </span>
            {subscribe.custom_translation[this.state.selectedLanguage] !== undefined &&
              <div
                className="unlimiTedAnnulBx"
                dangerouslySetInnerHTML={{
                  __html:
                    subscribe.custom_translation[this.state.selectedLanguage]
                      .description,
                }}
              ></div>}
            {this.props.authData.loginSuccess ? (
              subscribe.can_purchase === true ? (
                <span
                  class="subsCribeButton"
                  onClick={() => {
                    this.subscribeIdCheckout(subscribe.id, subscribe.variant);
                  }}
                >
                  {/* {console.log("can_purchase", subscribe.can_purchase)} */}
                  <a href="JavaScript:void(0);">{strings.subscribeNow}</a>
                </span>
              ) : (
                <span
                  class="subsCribeButton"

                >

                  <a href="https://forms.gle/dbiKPTkfVwt5yYqX8" target="_blanck"> {strings.inquiry}</a>
                </span>
              )
            ) : subscribe.can_purchase === false ? <span class="subsCribeButton">
              <a
                href="https://forms.gle/dbiKPTkfVwt5yYqX8" target="_blanck"
              >
                {strings.inquiry}
              </a>
            </span> :
              <span class="subsCribeButton">
                <a
                  href="JavaScript:void(0);"
                  onClick={() => {
                    this.redirectTOLogin();
                  }}
                >
                  {strings.subscribeNow}
                </a>
              </span>
            }

            {this.state.cartFailureMessage.length > 0 &&
              this.state.subscriptionId === subscribe.id && (
                <div className="loginErrorMsg">
                  {this.state.cartFailureMessage}
                </div>
              )}
          </div>
        </Col>
      );
    });
  }


  componentWillReceiveProps(nextProps, prevState) {
    // alert(JSON.stringify(nextProps.isIndian))
    if (nextProps.isIndian !== undefined) {
      this.setState({ isDeviceRegisteredAPICalled: false });
    }

    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
    if (
      nextProps.subscriptionListReducer !== prevState.subscriptionListReducer &&
      nextProps.subscriptionListReducer.length > 0
    ) {
      var new_data = nextProps.subscriptionListReducer.filter(function( element ) {
        return element !== undefined;
     });
      this.setState({
        subscribePackages: new_data,

        subhandle: nextProps.subscriptionListReducer,
        isSubscriptionAPIcall: true,
      });
      // console.log("reducer----", nextProps.subscriptionListReducer)
    }

  }

  // subscribeData = "https://newapi.snovel.in/api/subscription-list/"

  // componentDidMount () {

  //   fetch(api_base_url + "/api/subscription-list/")

  //   .then(response => response.json())

  //   .then(data => console.log("========>>>><<<<",data))

  //  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        ) : null}
        <div className="container-fluid">
          <div className="pageTitle_Wrapper">
            <h1 className="pageTitle">{strings.snovelSubTitle}</h1>
          </div>
          <p>{strings.snovelSubscription}</p>
          <b style={{ fontSize: "20px" }}>{strings.snovelSubscription_info}</b>
          <Row className="subscribePack_Wrapper" style={{ justifyContent: 'space-between' }}>
            <Col md="12" >

              <Row>

                {this.subscribeStateData()}
                {/* <Col xs="12" md="4" className="subscriptionBox_Wrapper"
                  style={{ paddingTop: '20px', justifyContent: "space-between" }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                      style={{
                        width: '105px', borderRadius: '50%', lineHeight: '105px',
                        backgroundColor: '#da145a', textAlign: 'center', fontSize: '32px'
                      }}>
                      <div
                        style={{
                          textAlign: 'center', justifyContent: 'center',
                          fontWeight: 900, color: 'white'
                        }}
                      >
                        {5}
                      </div>
                    </div>
                  </div>
                  <div className="unlimiTedAnnul" style={{ marginLeft: '15px' }}>

                    <h3>{strings.audionCourse}</h3>
                    <br />
                   

                    <h2>{strings.subscriptionFifth_pkg}</h2>

                    <h5 style={{ textTransform: "capitalize" }}>{strings.tosubscribeFifth_pkg}</h5>
                    <h5 style={{ textTransform: "initial" }}>{strings.tocall_fithpkg}</h5>
                    <h5 style={{ textTransform: "capitalize" }}>{strings.or_fithpkg}</h5>

                  </div>
                </Col> */}
              </Row>

            </Col>

          </Row>

          <div className="container-fluid" style={{ paddingRight: "0px" }}>
            <h6
              style={{
                border: '1px solid black', padding: '12px',
                textAlign: 'center', fontSize: '18px',
                textTransform: 'initial'
              }}>

              For Institutional discount please contact {"  "} {"  "}
              <a href="">sound@snovel.in</a> or call +91 8956978155


            </h6>
          </div>

          {/* 
          <div className='container'
            style={{ border: '1px solid white', backgroundColor: '#eaeaf0' }}
          >
            <Row style={{ margin: '30px' }}>
              <h6 style={{
                border: '1px solid black', padding: '10px', backgroundColor: '#212433'
                , marginTop: '10px', textAlign: 'center', fontSize: '20px',
                color: 'white', textTransform: 'capitalize'
              }}
              >
                {this.subStatedata()[3]}

               
              </h6>
              <br />
              <br />
              <h6
                style={{
                  border: '1px solid black', padding: '10px', backgroundColor: '#212433',
                  marginTop: '10px', textAlign: 'center', color: 'white', fontSize: '18px',
                  textTransform: 'capitalize'
                }}>

                {this.subStatedataOne()[3]}
               

              </h6>


            </Row>
            <h4
              style={{
                textAlign: "center", fontSize: '28px',
                textTransform: 'capitalize', color: 'black'
              }}
            >

              {this.subStatedata()[2]}
            
            </h4>
            <h6
              style={{
                marginTop: "30px", textAlign: "center", color: 'black',
                fontSize: '18px',
                textTransform: 'initial'
              }}
            ><span
              style={{ textDecoration: "line-through solid red" }}>Rs 2500</span> Rs 1890 {strings.gst}</h6>
            <div className='container'>
              <Row style={{ margin: '20px' }}>
                <h6 style={{
                  border: '1px solid black', padding: '10px', backgroundColor: '#212433'
                  , textAlign: 'center', color: 'white', fontSize: '18px',
                  textTransform: 'capitalize'
                }}>
                  {strings.innovative}
                </h6>
              </Row>
            </div>
            <div className='container'>
              <Row style={{ margin: '20px' }}>
                <h6 style={{
                  border: '1px solid black', padding: '10px', textAlign: 'center',
                  backgroundColor: '#212433', fontSize: '18px',
                  color: 'white', textTransform: 'capitalize'
                }}>
                  {strings.access}
                </h6>
                <h6 style={{
                  border: '1px solid black', padding: '10px',
                  marginTop: '10px', backgroundColor: '#212433', fontSize: '18px',
                  textAlign: 'center', color: 'white', textTransform: 'capitalize'
                }}>
                  {strings.discount}

                </h6>
                <br />
                <br />
                <a className='col-md-3'
                  style={{
                    marginLeft: '56vh', border: "2px solid #da145a",
                    padding: "15px 30px", borderRadius: '30px', fontSize: '20px'
                    , backgroundColor: "#da145a", marginTop: '30px',
                    color: 'white', textTransform: 'uppercase'
                  }}
                >
                  {this.SubscribeButton()[3]}
                
                </a>
                <span style={{ textAlign: 'center' }}>{this.errormessage()[3]}</span>
              </Row>
            </div>
          </div> */}



          <Row>
            <Col sm="12">

              {Object.keys(this.state.subscribeMessage).length > 0 &&
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.subscribeMessage.custom_translation[this.state.selectedLanguage]['subscription_message'],
                  }}
                ></div>
              }
            </Col>
          </Row>
          <Row>
            <div class="giftSubsCription">
              <NavLink
                tag={Link}
                to="/gift-snovel"
                className="subscriptionLink"
              >
                {strings.giftSubscription}
              </NavLink>
            </div>
          </Row>
          <div className="mySnovelFaq">
            <Row className="left-right-margin-none">
              <Col lg="9" className="visitFaq">
                {strings.queryPurchasing}
                <br />
                <span>
                  <NavLink className="visit-faq-link" tag={Link} to="/faq">
                    {strings.visitourFAQ}
                  </NavLink>
                </span>
              </Col>
              <Col lg="3" className="visitFaq emailRight">
                {strings.contactUsText}:{" "}
                <a href="mailto:hello@snovel.in">hello@snovel.in</a>
              </Col>
            </Row>
          </div>
        </div>


        <div className="container-fluid stellar-wrapper-footer">
          <a className="subscribe_btn-audio " href="/subscribe">{strings.subscribeNow}</a>
          {
            strings._language === strings._defaultLanguage ? (
              <img src="/images/stellar audio.png"
                className="stellar-subscribe-img"

              />
            ) : (
              <img src="/images/snovel web home marathi 1-06.png"
                className="stellar-subscribe-img"

              />
            )
          }
        </div>
        {/* <div className="subBenefit_Wrapeer">
          <div className="container">
            <Row>
              <Col xs="12" md="12" lg="4">
                <h2>{strings.benefitOfOneHead}</h2>
                <p>{strings.benefitOfOne}</p>
              </Col>
              <Col xs="12" md="12" lg="4" className="subsCriptionMid">
                <h2>{strings.benefitOfTwoHead}</h2>
                <p>{strings.benefitOfTwo}</p>
              </Col>
              <Col xs="12" md="12" lg="4">
                <h2>{strings.benefitOfThreeHead}</h2>
                <p>{strings.benefitOfThree}</p>
              </Col>
            </Row>
            <div class="subsCriBtnBtm">
              <span class="subsCribeButton">
                <a href="/subscribe">{strings.subscribeNow} </a>
              </span>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    authData: state.authonticationDetails,
    modelSelectedFunction: state.modelSelectedFunction,
    isIndian: state.isReisteredDevice.isIndian,
    subscriptionListReducer: state.subscriptionListReducer.subscriptionList,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(subscribeSnovel));
