import axios from 'axios';
import { api_base_url } from '../config';

function readAllNotification(userToken) {
    var headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + userToken
    };
    axios.get(api_base_url + '/api/notification-read/', { headers: headers }).then(response => {

    }).catch(err => {
        //   console.log('error ', err)
    });
}

export function productNotification(userToken) {
    return function (dispatch) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': "Token " + userToken
        };
        var apiParams = {};
        axios.post(api_base_url + '/api/my-notification/', apiParams, { headers: headers }).then(response => {
            if (response.data.status === true) {
                dispatch({
                    type: "PRODUCT_NOTIFICATION",
                    payload: response.data.data
                });
            } else {
                dispatch({
                    type: "FAILED_PRODUCT_NOTIFICATION",
                    payload: response.data
                });
            }
            readAllNotification(userToken);
        }).catch(err => {

            readAllNotification(userToken);
        });
    }
}



export function productMediaNews(userToken) {
    return function (dispatch) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': "Token " + userToken
        };
        axios.get(api_base_url + '/api/news/20/', { headers: headers }).then(response => {
            if (response.data.status === true) {
                dispatch({
                    type: "ACCOLADES_NEWS_NOTIFICATION",
                    payload: response.data.data.accolades_news
                });
                dispatch({
                    type: "MEDIA_NEWS_NOTIFICATION",
                    payload: response.data.data.media_news
                });
            } else {
                dispatch({
                    type: "FAILED_NEWS_NOTIFICATION",
                    payload: response.data
                });
            }
        }).catch(err => {
            // console.log('fetched.', err);
        });
    }
}