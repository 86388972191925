import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { googleAnalyticsTrackingId } from '../../config';
ReactGA.initialize(googleAnalyticsTrackingId);

class termAndCondition extends Component {
    constructor(props){
        super(props);
        ReactGA.pageview(this.props.location.pathname);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <div className="container privacrPolicy">
                  <h2>Terms Of Service</h2>
                    <div class="entry-content">
                        <div class="legal-txt-box">Snovel Platform (refer definition 1(f)) is owned by Snovel Creations Private Limited (hereinafter referred as ‘Owner’ or ‘Company’). The Company designs and develops digital media content including audio, video, digital text productions and technical (software, hardware) platforms related to its distribution, and organize related events for promotion and sales of its products and platform. Snovel produces audio experiences including but not limited to audiobooks, audio plays/audio movies, podcast etc. and sells those experiences on its platform.</div>
                        <div class="legal-txt-box">Snovel Platform helps the User [refer definition 1(c)] to listen to Snovel experience on their mobile device or desktop, laptop or tablet or any other appropriate device by registering (creating Snovel account) and becoming Registered Users [refer definition 1(d)] paying digitally. Users can listen to one particular audio work by paying for that particular work and also can pay an annual or quarterly or monthly subscription fees and listen to entire catalog for unlimited time. (hereinafter referred to as ‘Services’).</div>
                        <div class="legal-txt-box">By accessing Snovel Platform, the User or Registered User agrees to abide by and bound by the Terms and Conditions of Snovel Platform. The Terms and Conditions contained herein along with the Privacy Policy form an Agreement regulating our relationship with regard to the use of the Snovel Platform by the User or Registered User. Please read this Agreement carefully. By singing in or logging on the Snovel Platform the User/Registered User accepts the terms and conditions and by default follows Snovel Platform. The User or Registered User is advised to regularly check for any amendments or updates to the terms and conditions from time to time.</div>
                        <div class="legal-txt-box">The Snovel Platform reserves the right to modify the terms and conditions of the Service Agreement ("Terms of SERVICE") and Disclaimer without any prior notification. The User /Registered User is advised to regularly review the Terms of Service. If the User/Registered User does not agree with any of the Terms and Conditions and any amendments thereto, the User/ Registered User must not use this Snovel Platform and/or Services.</div>
                        <div class="legal-txt-box">These Terms and Conditions constitute a binding legal agreement between the Owner and User /Registered User/s; and govern legal rights, remedies, obligations and various other terms like limitations, jurisdiction, applicable law etc.</div>
                        <div class="legal-txt-box">The Agreement between the User/ Registered User and the Snovel Platform shall be subject to following Terms and Conditions: -</div>
                        <div class="legal-sub-hd">
                        <h3>1. Definitions: </h3>
                        </div>
                        <div class="legal-txt-box">The terms wherever used shall unless contrary, carry the meaning as given under:</div>
                        <ul>
                        <li><b>a) “Snovel Platform”</b> means and includes the Snovel’s Website or mobile application owned by Snovel on Internet and respective stores.</li>
                        <li><b>b) “Agreement”</b> means the Terms and Conditions contained herein along with any Privacy Policy of any other Website/Application/portals whose links are provided on Snovel Platform. It shall also include reference to this Agreement as and when amended, novated, supplemented varied or replaced.</li>
                        <li><b>c) “User”</b> means and includes any person who visits the Snovel Platform to see the various Services available which are displayed on Snovel Platform.</li>
                        <li><b>d) “Registered User”</b> means and includes any person who registers with the Snovel Platform by accessing the Snovel Platform through <a href="https://snovel.in/">https://snovel.in/</a> and/or registering on the Snovel’s mobile application by providing his/her/their required predefined details along with the payments.</li> 
                        <li><b>e) “Offerings” </b> means and includes any type of service or product capabilities which has to be showcased to others and put up by a User/Registered User on Snovel Platform.</li>
                        <li><b>f) “Requirements” </b> means and includes anything that is needed or required by a User/Registered User for his business on Snovel Platform.</li>
                        </ul> 
                        <div class="legal-sub-hd">
                        <h3>2. Eligibility, Registration and Termination:</h3>
                        </div>
                        <div class="legal-txt-box">Snovel Platform can be accessed by going on <a href="https://snovel.in/">https://snovel.in/</a> and/or can be downloaded from Google Play Store or Apple Play Store as required. Snovel Platform can be used/accessed only by persons who can form a legally binding contract under the Indian Contract Act, 1872. If User/Registered User is a minor i.e. under the age of 18 years, you may use Snovel Platform only with the involvement of a parent or guardian. The User may use Snovel Platform and shall have access to sample audio provided for each audio work while a User can also register on the Snovel Platform and creates an account to become a Registered User and get complete access to Snovel Platform.</div>
                        <div class="legal-txt-box">While creating an account, the User is required to provide his/her/their personal information such as full name, mobile number, e-mail ID. The User may register by providing his/her/their email address, SNOVEL shall send a system generated email on the email address of the user for verification, post verification the User’s SNOVEL account is activated. Alternate way of Registering on the Snovel Platform is through the User’s Google Account. The Google Account shall be used as SNOVEL’s platform account adhering to Google’s Terms and Conditions. The User may also register using his/her/their Facebook Account. The Facebook Account shall be used as SNOVEL’s platform account adhering to Facebook’s Terms and Conditions. The User may also register using his/her/their Twitter Account. The Twitter Account shall be used as SNOVEL’s platform account adhering to Twitter’s Terms and Conditions. Post opting any one method out of the above four for registration the User becomes a Registered User with a unique user ID.</div>
                        <div class="legal-txt-box">The Registered User agrees that the Information provided by him/her/them at the time of registration is true, complete and correct and that any change in the Information will be updated to the Owner with immediate effect of the change. If the Information details are found not to be true (wholly or partly), the Owner has the right in its sole discretion to reject the registration and debar the Registered User from using the Services of the Snovel Platform and/or other affiliated Website/Websites without prior intimation whatsoever.</div>
                        <div class="legal-txt-box">The Owner reserves the right at its sole discretion to terminate, refuse, restrict or suspend any Registered User’s account at any time, for any reason, without notice, including when a Registered User fails to abide by these Terms and Conditions or any other policy or terms posted anywhere on the Snovel Platform.</div>
                        <div class="legal-txt-box">The Owner reserves the right at its sole discretion to terminate, refuse, restrict or suspend any Registered User’s account at any time if any Registered User -</div>
                        <ul>
                        <li>a) uploads pornographic contents/photos/videos/links on Snovel Platform.</li>
                        <li>b) uploads any detrimental or offensive contents/photos/videos/links or offensive comments/photos/videos/links pertaining to religion on Snovel Platform.</li>
                        <li>c) Any content that promotes or incites violence, terrorism, illegal acts, or hatred on the grounds of race, ethnicity, cultural identity, religious belief, disability, gender, identity or sexual orientation, or is otherwise objectionable.</li>
                        </ul>
                        <div class="legal-txt-box">The Registered User or may be prohibited from creating a new account if he/she/they has/have been previously terminated as per the above-mentioned circumstances or for any other reason whatsoever objectionable by the Owner.</div>
                        <div class="legal-txt-box">If any Registered User misuses the details of any other Registered User then the Owner reserves the right at its sole discretion to terminate that Registered User account and the owner reserves the right at its sole discretion to initiate appropriate legal action against the Registered User(s).</div>
                        <div class="legal-sub-hd">
                        <h3>3. Facilities for Users</h3>
                        </div>
                        <div class="legal-txt-box">The facilities for user are as follows-</div>
                        <ul>
                            <li>a) Browse the Snovel Platform</li>
                            <li>b) Listen to sample provided for each audio work.</li>
                        </ul>
                        <div class="legal-sub-hd">
                        <h3>4. Facilities for Registered Users:</h3>
                        </div>
                        <div class="legal-txt-box">The facilities for Registered Users are as follows-</div>
                        <ul>
                            <li>a) Listen to free audio works.</li>
                            <li>b) Purchase any audio work of any individual title.</li>
                            <li>c) Subscribe to any individual title for periods given on the platform.</li>
                            <li>d) Avail auto renewal facility for subscription products, packs, service</li>
                            <li>e) Can provide comments</li>
                            <li>f) Share the audio work further</li>
                            <li>g) Gift a particular audio work or subscription package to someone else</li>
                        </ul>
                        <div class="legal-sub-hd">
                        <h3>5. Use of Contents and restrictions on Registered User:</h3>
                        </div>
                        <div class="legal-txt-box">The Registered User agrees not to display, upload, modify, publish, transmit, update, share or otherwise make available on the Snovel Platform, any content and/or material that is unlawful, unsolicited including unsolicited messages, e-mails, and/or material which contains viruses, trojans, worms, files or software intended to interrupt, corrupt, destroy, limit the use, access or functionality of the Snovel Platform.</div>
                        <div class="legal-txt-box">The Registered User also agrees not to display, upload, modify, publish, transmit, update, share or otherwise make available on the Snovel Platform, any content and/or material that is harmful, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic, abusive, invasive of another person’s privacy, hateful or racially, ethnically objectionable, disparaging or relating to or encouraging money laundering or gambling etc.</div>
                        <div class="legal-txt-box">The Registered User agrees not to display, upload, modify, publish, transmit, update, share or otherwise make available any content and/or material which is in violation of patents, trade mark, copyright or any other proprietary rights of other person/people.</div>
                        <div class="legal-txt-box">The Registered User can only access and/or listen the content available on Snovel Platform only for his/her purpose and shall not copy/modify/broadcast/share the content from the Snovel Platform for anybody else.</div>
                        <div class="legal-txt-box">There might be some content on Snovel Platform for which, to be consumed, a Parental Advisory Label will be either displayed or recorded.</div>
                        <div class="legal-txt-box">The Registered user is advised to use parental guidance to consume content where appropriate notice is either displayed or recorded while consuming that content for children.</div>
                        <div class="legal-txt-box">The Parental Advisory Label is a notice to consumers that content identified by this label may contain strong language or depictions of violence, sex or substance abuse. Parental discretion is advised.</div>
                        <div class="legal-txt-box">The use of the Parental Advisory Label isn’t a determination of whether the content is or is not appropriate for particular consumers or users or listeners. Rather, the explicit, non-removable label is a “heads up” to registered users, consumers, if parents, that parental discretion is advised when purchasing, consuming the particular content for children or when listening to the recording in the home with family.</div>
                        <div class="legal-sub-hd">
                        <h3>6. Privacy Settings</h3>
                        </div>
                        <div class="legal-txt-box">A User or Registered User can adjust its own privacy settings on the Snovel Platform if he/they does not wish to continue with the default settings provided by Snovel Platform.</div>
                        <div class="legal-sub-hd">
                        <h3>7. Notification Settings</h3>
                        </div>
                        <div class="legal-txt-box">A User or Registered User agrees to receive messages from SNOVEL for notifications purpose.</div>
                        <div class="legal-sub-hd">
                        <h3>8. Conduct of Registered Users</h3>
                        </div>
                        <div class="legal-txt-box">The Registered Users accept and agree that he/she/they are entirely responsible for compliance of all the laws, rules or regulations etc. that applies to Registered User’s use and/or access of the Snovel Platform or Services.</div>
                        <div class="legal-sub-hd">
                        <h3>9. Applicable laws and Jurisdiction</h3>
                        </div>
                        <div class="legal-txt-box">These terms shall be governed, interpreted and construed in accordance with the laws of the Republic of India applicable from time to time. The Snovel Platform and the Registered User agrees to submit to the exclusive jurisdiction of courts located in Pune (M.S.) India for any claims, actions, demands, suits, disputes arising out of and/or in connection with Services provided by Snovel.</div>
                        <div class="legal-sub-hd">
                        <h3>10. Limitation of Liability</h3>
                        </div>
                        <div class="legal-txt-box">To the fullest extent permissible by law, in no event shall the Owner or its employees, group companies or agents be liable for any direct, indirect, special, consequential damages or damages of any other kind incurred by the Registered User or any third party arising out of the Registered User’s access or use or inability to access or use Snovel Platform and/or its Services.</div>
                        <div class="legal-txt-box">The Information and Services displayed through the Snovel Platform may include inaccuracies. Additionally, a possibility exist that unauthorized additions, deletions and alterations could be made by third parties to Snovel Platform.</div>
                        <div class="legal-txt-box">The Owner shall not be held liable and/or responsible for any fake identity, fake calls, frauds caused through people through the help of Snovel Platform.</div>
                        <div class="legal-txt-box">In any event, the Owner will not be held liable for any loss or damage including without limitation, indirect or consequential loss or damage or any loss or damage whatsoever arising from loss of data arising out of, or in connection with, the use of Snovel Platform.</div>
                        <div class="legal-txt-box">The User or Registered User expressly agrees that the Owner is not responsible for the information posted and/or shared by the User Registered User</div>
                        <div class="legal-txt-box">The Registered User expressly agrees that Owner is not responsible and/or liable for any threatening, defamatory, obscene, unlawful, harmful, offensive or illegal content or conduct of any other party or any infringement of another’s rights, including intellectual property rights. The Registered User specifically agrees that the Owner will not be held responsible and/or liable for any content/data sent using and/or included on /Snovel Platform or Service by any third party/parties.</div>
                        <div class="legal-txt-box">NOTWITHSTANDING anything contained herein, in any event, the liability of the, financial or otherwise, suffered to the Owner of the Snovel Platform shall be limited to the amount paid by the Registered User and shall not exceed the sale value.</div>
                        <div class="legal-sub-hd">
                        <h3>11. Disclaimer</h3>
                        </div>
                        <div class="legal-txt-box">Every effort is made to keep the Snovel Platform up and running smoothly. However, the Owner takes no responsibility for, and will not be held liable for the Snovel Platform being temporarily unavailable due to technical issues beyond our control.</div>
                        <div class="legal-sub-hd">
                        <h3>12. Warranty and Indemnification</h3>
                        </div>
                        <div class="legal-txt-box">Snovel Platform is provided by the Owner, on “AS IS” and “AS AVAILABLE” basis without express or implied warranty or condition of any kind. The Registered User will use Snovel Platform at his/her/their own risk. The Snovel Platform disclaims all warranties, express or implied, including but not limited to, implied warranties of merchantability.</div>
                        <div class="legal-txt-box">The details and/or information provided by the Owner make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the Snovel Platform and/or the information contained on the Snovel Platform for any purpose. Any relation the Registered User places on such information are therefore strictly at their own risk.</div>
                        <div class="legal-txt-box">The Registered User agrees that the Owner is not held responsible or held liable for any transaction between the Registered User and third-party providers (including payment gateways etc.) of Snovel Platform or Services provided through Snovel Platform. No advice or information whether oral or in writing received from the Registered User of the Snovel Platform shall create any warranty on behalf of the Snovel Platform in this regard.</div>
                        <div class="legal-txt-box">The Registered User agrees to indemnify, defend and hold harmless the Owner from any suits, claims, losses, fines, penalties, damages, liabilities, demands, expenses including, but not limited to, reasonable legal expenses and accounting fees arising out of and/or in connection with any violation and/or act and/or omission committed and/or alleged to be committed by the Registered User.</div>
                        <div class="legal-sub-hd">
                        <h3>13. Intellectual Property</h3>
                        </div>
                        <div class="legal-txt-box">The User and Registered User acknowledges and accepts that unless otherwise expressly stated, all the content on the Snovel Platform including, but not limited to, images, graphics, photos, sounds, music, videos, software, script, trade names, trademarks, logos, labels, service marks and/or any other proprietary marks are either owned by the Owner or licensed thereto. No information, content or portion uploaded on the Snovel Platform shall be reproduced, duplicated, copied, sold or otherwise exploited for any commercial purposes not expressly permitted by Owner. It is the policy of the Snovel Platform and/or Owner to terminate in its sole discretion, accounts of Registered User or in appropriate circumstances found repeatedly infringing the rights of Owner of copyright, trademark or other intellectual property rights. Snovel Platform, its Services and all the content is protected by copyright, trademark and other relevant laws of India. In case of any violation user or registered user is liable to appropriate legal action.</div>
                        <div class="legal-sub-hd">
                        <h3>14. Booking Terms</h3>
                        </div>
                        <div class="legal-txt-box">The Registered User shall place orders through Snovel Platform. The Registered User is required to purchase the paid facilities in the Snovel Platform by paying through the payment gateway on Snovel Platform via subscription models. Once the payment is made successfully by the Registered User, he/she/they will receive a receipt of payment immediately.</div>
                        <div class="legal-txt-box">If user wishes to avail auto-renewal facility or feature for its subscription service on Snovel platform, this facility of feature is available on Snovel platform. For a particular user, this can be availed by selecting a particular feature; this can be done only if the user selects the auto renewal facility for subscription pack and by no other means. In such cases, the payment gateway and Snovel might store payment related details (payment card etc.) from the user, which is strictly used only for better uninterrupted service for the user on Snovel platform and nowhere else.</div>
                        <div class="legal-txt-box">Snovel Platform reserves the right to use third party platform to co-ordinate such payment transaction by the Registered Users. The Owner shall not be held responsible for loss or damages cost to the Registered User by such third party/parties.</div>
                        <div class="legal-sub-hd">
                        <h3>15. Snovel’s Refund Policy</h3>
                        </div>
                        <div class="legal-txt-box">At the outset, we thank you for choosing Snovel for treating your ears. It is an honest attempt to make the best possible literature available in Audio format, with a unique effort. At Snovel.in we offer digital content via streaming facility on its platform. We try our best to provide technically flawless product/service. In some cases, it is not always possible for us to refund the amount paid by customer. Having said that we will always try our best to address your queries and concerns.</div>
                        <div class="legal-sub-hd">
                        <h3>a. Acceptance of Refund Policy:</h3>
                        </div>
                        <div class="legal-txt-box">By placing an order for any of our products, you indicate that you have read this legal policy information, terms & conditions and Snovel’s refund policy and that you agree with and fully agree and accept the terms & conditions of this refund policy.</div>
                        <div class="legal-sub-hd">
                        <h3>b. Instances where Registered Users will get Refund?</h3>
                        </div>
                        <ul>
                            <li>i. Snovel might not refund the amount but it will help user with the error, if the Registered User has paid, but not received the audio product at all. It is recommended that you go with compatible devices to listen to Snovel’s content.</li>
                        </ul>
                        <div class="legal-sub-hd">
                        <h3>c. Instances where Registered Users won’t get Refund?</h3>
                        </div>
                        <ul>
                        <li>i. If the Registered User does not like the audio product after downloading or listening.</li>
                        <li>ii. If due to technical error, any portion of the audio product is not working properly:</li>
                        </ul>
                        <div class="legal-txt-box">Snovel might replace the audio file in question on a case-by-case basis. However, this might not be possible every time.</div>
                        <div class="legal-sub-hd">
                        <h3>d. In case of cancellation of auto renewal facility or feature of subscription pack/product/service.</h3>
                        </div>
                        <div class="legal-txt-box">If user wants to cancel or stop the auto renewal facility or feature of subscription pack/product/service this can be done only by the user. In that case, the current, running subscription period will continue till its end date and the user shall not be charged from the subsequent period.</div>
                        {/* <div class="legal-txt-box text-center" style={{marginTop: '40px'}}>Last updated: 27-December-2019  </div> */}
                        <div class="legal-sub-hd">
                            <h3>e. If in case the audio file is not compatible with your device:</h3>
                        </div>
                        <div class="legal-txt-box">It is recommended that you go with compatible devices to listen to Snovel’s content. Snovel might replace the concerned audio file for compatibility on a case-by-case basis. However, this might not be possible every time.</div>
                        <div class="legal-sub-hd">
                        <h3>16. Contact Us</h3>
                        </div>
                        <div class="legal-txt-box">If you have any questions about this Terms of Service, please contact us at</div>
                        <div class="legal-sub-hd">
                            <h3>Email: <a href="mailto:hello@snovel.in">hello@snovel.in</a></h3>
                        </div>
                        <div class="legal-sub-hd">
                            <h3>Our postal Address:</h3>
                        </div>
                        <div class="legal-txt-box">A-102 DSK Gandhakosh, Sr. No. 153-155 Baner, Pune-411045, India</div>
                        <div class="legal-txt-box">Last date of document update and release: 23-Sep-2021</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default termAndCondition;