const initialState = {
    subscriptionList: [],
}
export default function subscriptionListReducer(state = initialState, action) {
    switch (action.type) {
        case 'SUBSCRIPTION_LIST': {
            return { ...state, subscriptionList: action.payload };
        }
        case 'FAILED_SUBSCRIPTION_LIST': {
            return { ...state, subscriptionList: [] };
        }
        default: {
            return { ...state };
        }
    }
}