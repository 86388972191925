import React, { Component } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import { googleAnalyticsTrackingId, api_base_url } from '../../config';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import OwlCarousel from 'react-owl-carousel2';
import LocalizedStrings from 'react-localization';
import languages from '../../language/language.json';
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

const optionss = {
    nav: true,
    rewind: true,
    autoplay: false,
    navText: ["<div></div>", "<div></div>"],
    margin: 15,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1000: {
            items: 4
        }
    }
};

class faq extends Component {
    constructor(props) {
        super(props);
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
        }
        this.state = {
            collapse: '',
            topic: 0,
            selectedLanguage: selectedLanguage || 'EN',
            faqDetails: [],
            isLoading: true
        };
        ReactGA.pageview(this.props.location.pathname);
        this.getFaqDetails(selectedLanguage);
    }

    getFaqDetails(selectedLanguage) {
        var faqLanguage = selectedLanguage || 'EN';
        this.setState({
            isLoading: true
        });
        var headers = {
            'Content-Type': 'application/json'
        };
        axios.get(api_base_url + '/api/v1/FAQ/' + faqLanguage.toLowerCase(), { headers: headers }).then(response => {
            this.setState({
                faqDetails: response.data.data || [],
                isLoading: false
            });
        }).catch(err => {
            this.setState({
                isLoading: false
            });
        });
    }

    toggle(selectedIndex) {
        if (this.state.collapse === selectedIndex) {
            this.setState(state => ({ collapse: '' }));
        } else {
            this.setState(state => ({ collapse: selectedIndex }));
        }
    }
    topicSelection(topicIndex) {
        this.setState(state => ({ topic: topicIndex }));
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    componentWillReceiveProps(nextProps) {
        var selectedLanguage = window.localStorage.getItem("language");
        if (this.state.selectedLanguage !== selectedLanguage) {
            this.getFaqDetails(selectedLanguage);
        }
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
            this.setState({ selectedLanguage: selectedLanguage });
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
            this.setState({ selectedLanguage: "EN" });
        }
    }
    render() {
        return (
            <div className="faq">
                <div className="clearfix" />
                {this.state.isLoading ?
                    <div id="loading">
                        <div id="loading-center">
                            <div id="loading-center-absolute">
                                <img src="images/loader.gif"/>
                            </div>
                        </div>
                    </div> : null
                }
                <div className="container">
                    <div>
                        {this.state.faqDetails.length > 0 &&
                            <div className="faqTitleWrp">
                                <h1>{strings.viewFaqByTopic}</h1>
                                <p>{strings.faqSubcontent} <a href="mailto:hello@snovel.in">hello@snovel.in</a></p>
                            </div>
                        }
                        {this.state.faqDetails.length > 0 &&
                            <OwlCarousel id="owlFaq" ref="car" options={optionss} >
                                {this.state.faqDetails.map((eachFaq, topicIndex) => {
                                    return (
                                        <div onClick={() => { this.topicSelection(topicIndex) }} className={this.state.topic === topicIndex ? "item activetab" : "item"}>
                                            <div>
                                                <h4>{eachFaq.title}</h4>
                                            </div>
                                        </div>
                                    )
                                })}
                            </OwlCarousel>
                        }
                        {this.state.faqDetails.map((eachFaq, topicIndex) => {
                            return (
                                <div className={this.state.topic === topicIndex ? "accordian accordian-display-block" : "accordian accordian-display-none"}>
                                    {eachFaq.queries.map((eachQueries, queryIndex) => {
                                        return (
                                            <div className="accordian-wrap">
                                                <h3 onClick={() => { this.toggle(queryIndex) }} className={(this.state.collapse === queryIndex) ? "ui-accordion-header ui-state-active" : 'ui-accordion-header'}>
                                                    {eachQueries.que} {(this.state.collapse === queryIndex) ? <i class="fas fa-sort-up"></i> : <i class="fas fa-sort-down "></i>}</h3>
                                                <Collapse isOpen={this.state.collapse === queryIndex}>
                                                    <Card>
                                                        <CardBody>{eachQueries.ans}</CardBody>
                                                    </Card>
                                                </Collapse>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                        <div class="faqContact">
                            <p>Still have more questions?<br /><span>Contact us: <a href="mailto:hello@snovel.in">hello@snovel.in</a></span></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default faq;