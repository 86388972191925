import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, FormGroup, Input } from "reactstrap";
import ReactGA from "react-ga";
import {
  api_base_url,
  guestSubscriptionToken,
  emailReg,
  giftMobileRegularExpression,
  encryptDecryptKey,
  googleAnalyticsTrackingId,
  nameRegularExpression,
  countryList,
} from "../../config";
import axios from "axios";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel2";
import LocalizedStrings from "react-localization";
import SimpleCrypto from "simple-crypto-js";
import languages from "../../language/language.json";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

const carouselOptions = {
  nav: true,
  rewind: true,
  autoplay: true,
  navText: ["<div></div>", "<div></div>"],
  margin: 15,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};
class giftSnovel extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      subscribePackages: [],
      // selectedSubscription: "",
      selectedSubscription: "0",
      isEmptyState: true,
      firstName: "",
      lastName: "",
      message: "",
      email: "",
      emailError: "",
      firstNameError: "",
      lastNameError: "",
      messageError: "",
      token: token || "",
      cartDetailsProducts: [],
      varientID: "",
      selectedSubscriptionError: "",
      cartFailureMessage: "",
      acceptTerm: false,
      acceptTermError: "",
      selectedLanguage: selectedLanguage || "EN",
      coupneVoucher: "",
      isAddedCoupon: false,
      couponError: "",
      cuponMessageSuccess: "",
      cuponMessage: "",
      selectedCountry: "",
      selectedCountryError: "",
      mobileNumber: "",
      mobileNumberError: "",
      fromFirstName: "",
      fromLastName: "",
      fromEmail: "",
      fromSelectedCountry: "",
      fromMobileNumber: "",
      fromFirstNameError: "",
      fromLastNameError: "",
      fromEmailError: "",
      fromSelectedCountryError: "",
      fromMobileNumberError: "",
      currcountry: ""
    };
    ReactGA.pageview(this.props.location.pathname);
    this.onSubmitGift = this.onSubmitGift.bind(this);
    this.removeCartSubscription = this.removeCartSubscription.bind(this);
    this.makePaymentSubscription = this.makePaymentSubscription.bind(this);
    this.cancelGiftSubscription = this.cancelGiftSubscription.bind(this);
    this.handleCoupenValue = this.handleCoupenValue.bind(this);
    this.applyCoupen = this.applyCoupen.bind(this);
    this.removeCopenCode = this.removeCopenCode.bind(this);
    this.getuserCountry()
  }

  /********* Select Subscription option **************/

  subscriptionList() {
    return this.state.subscribePackages.map((list, index) => {
      return (
        list.can_purchase === true ? (
          <option value={index}>
            {list.name}</option>
        ) : ""
      )
    });
  }

  selectedPackge(event) {
    var selectedIndex = event.target.value;
    this.setState({
      selectedSubscription: selectedIndex,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    var headers = {
      "Content-Type": "application/json",
      "guest-subscription-token": guestSubscriptionToken,
    };
    axios
      .get(api_base_url + "/api/subscription-list/", { headers: headers })
      .then((response) => {
        if (response.status == 200) {
          var subscriptionList = response.data || [];

          var newSubscriptionList = [];
          for (let responseJson of response.data) {
            newSubscriptionList.push(responseJson)
          }
          this.setState({
            subscribePackages: newSubscriptionList,
          });
          //console.log("subscribePackages---", response)
        }
      })
      .catch((err) => {
        // console.log("Error", err);
      });
    if (this.props.authData && this.props.authData.loginSuccess) {
      this.setState({
        fromFirstName: this.props.authData.firstName || "",
        fromLastName: this.props.authData.lastName || "",
        fromEmail: this.props.authData.userEmail || "",
        fromSelectedCountry: this.state.currcountry || "",
        fromMobileNumber: this.props.authData.userMobile || "",
      });
    }
  }
  cancelGiftSubscription(event) {
    event.preventDefault();
    this.setState({
      firstName: "",
      lastName: "",
      message: "",
      email: "",
      selectedSubscription: "",
      selectedCountry: "",
      mobileNumber: "",
      fromFirstName: "",
      fromLastName: "",
      fromEmail: "",
      fromSelectedCountry: "",
      fromMobileNumber: "",
    });
    this.props.history.push("/subscribe");
  }

  onSubmitGift(e) {
    e.preventDefault();
    this.setState({
      emailError: "",
      firstNameError: "",
      lastNameError: "",
      messageError: "",
      selectedSubscriptionError: "",
      selectedCountryError: "",
      mobileNumberError: "",
      fromFirstNameError: "",
      fromLastNameError: "",
      fromEmailError: "",
      fromSelectedCountryError: "",
      fromMobileNumberError: "",
    });
    var isValid = true;
    if (this.state.fromFirstName == "") {
      this.setState({
        fromFirstNameError: strings.firstNameError,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.fromFirstName)) {
      this.setState({
        fromFirstNameError:
          "First name field accept alphabets(A-Z,a-z), length (2-50) char only",
      });
      isValid = false;
    }
    if (this.state.fromLastName == "") {
      this.setState({
        fromLastNameError: strings.lastNameError,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.fromLastName)) {
      this.setState({
        fromLastNameError:
          "Last name field accept alphabets(A-Z,a-z), length (2-50) char only",
      });
      isValid = false;
    }
    if (this.state.fromSelectedCountry == "") {
      this.setState({
        fromSelectedCountryError: strings.countryCodeError,
      });
      isValid = false;
    }
    if (this.state.fromMobileNumber == "") {
      this.setState({
        fromMobileNumberError: strings.enterMobileNumber,
      });
      isValid = false;
    } else if (!giftMobileRegularExpression.test(this.state.fromMobileNumber)) {
      this.setState({
        fromMobileNumberError: strings.ValidNumber,
      });
      isValid = false;
    }
    if (this.state.fromEmail == "") {
      this.setState({
        fromEmailError: strings.enterEmail,
      });
      isValid = false;
    } else if (emailReg.test(this.state.fromEmail) === false) {
      this.setState({
        fromEmailError: strings.enterValidEmail,
      });
      isValid = false;
    }
    if (this.state.firstName == "") {
      this.setState({
        firstNameError: strings.firstNameError,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.firstName)) {
      this.setState({
        firstNameError:
          "First name field accept alphabets(A-Z,a-z), length (2-50) char only",
      });
      isValid = false;
    }
    if (this.state.lastName == "") {
      this.setState({
        lastNameError: strings.lastNameError,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.lastName)) {
      this.setState({
        lastNameError:
          "Last name field accept alphabets(A-Z,a-z), length (2-50) char only",
      });
      isValid = false;
    }
    if (this.state.selectedCountry == "") {
      this.setState({
        selectedCountryError: strings.countryCodeError,
      });
      isValid = false;
    }
    if (this.state.mobileNumber == "") {
      this.setState({
        mobileNumberError: strings.enterMobileNumber,
      });
      isValid = false;
    } else if (!giftMobileRegularExpression.test(this.state.mobileNumber)) {
      this.setState({
        mobileNumberError: strings.ValidNumber,
      });
      isValid = false;
    }
    if (this.state.message == "") {
      this.setState({
        messageError: strings.messageTextError,
      });
      isValid = false;
    } else if (this.state.message.length > 500) {
      this.setState({
        messageError: "Message should not be more than 500 characters",
      });
      isValid = false;
    }
    if (this.state.selectedSubscription == "") {
      this.setState({
        selectedSubscriptionError: strings.messageTextError,
      });
      isValid = false;
    }
    if (this.state.email == "") {
      this.setState({
        emailError: strings.enterEmail,
      });
      isValid = false;
    } else if (emailReg.test(this.state.email) === false) {
      this.setState({
        emailError: strings.enterValidEmail,
      });
      isValid = false;
    }
    if (isValid) {
      this.setState({
        cartFailureMessage: "",
      });
      if (this.props.authData.loginSuccess !== true) {
        var newApiParams = {
          email: this.state.fromEmail,
          first_name: this.state.fromFirstName,
          last_name: this.state.fromLastName,
          mobile: this.state.fromMobileNumber,
          country: this.state.fromSelectedCountry,
        };
        var newHeaders = {
          "Content-Type": "application/json",
          "guest-subscription-token": guestSubscriptionToken,
        };
        axios
          .post(api_base_url + "/api/add-gifting-user/", newApiParams, { headers: newHeaders })
          .then((newResponse) => {
            if (newResponse.data && newResponse.data.status === true) {
              let token = newResponse.data.token;
              this.setState({
                token
              });
              var apiParams = {
                email: "",
                discount_amount: 0.0,
                discount_amount_inr: 0.0,
                gift_cart: true,
                cart_lines: [
                  {
                    variant:
                      this.state.subscribePackages[this.state.selectedSubscription]
                        .variant || "",
                    quantity: 1,
                    data: {},
                  },
                ],
              };
              var headers = {
                "Content-Type": "application/json",
                Authorization: "Token " + token,
              };
              axios
                .post(api_base_url + "/api/cart/", apiParams, { headers: headers })
                .then((response) => {
                  if (response.data.status === true) {
                    this.setState({
                      cartDetailsProducts: response.data.data,
                      isAddTripState: true,
                      isEmptyState: false,
                    });
                  }
                  if (response.data.status === false) {
                    this.setState({
                      cartFailureMessage: strings.ProductAlreadyAdded,
                    });
                  }
                })
                .catch((err) => {
                  // console.log("fetched.", err);
                });
            }
          })
          .catch((err) => {
            // console.log("fetched.", err);
          });
      } else {
        var apiParams = {
          email: "",
          discount_amount: 0.0,
          discount_amount_inr: 0.0,
          gift_cart: true,
          cart_lines: [
            {
              variant:
                this.state.subscribePackages[this.state.selectedSubscription]
                  .variant || "",
              quantity: 1,
              data: {},
            },
          ],
        };
        var headers = {
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        };
        axios
          .post(api_base_url + "/api/cart/", apiParams, { headers: headers })
          .then((response) => {
            if (response.data.status === true) {
              this.setState({
                cartDetailsProducts: response.data.data,
                isAddTripState: true,
                isEmptyState: false,
              });
            }
            if (response.data.status === false) {
              this.setState({
                cartFailureMessage: strings.ProductAlreadyAdded,
              });
            }
          })
          .catch((err) => {
            // console.log("fetched.", err);
          });
      }
    } else {
      this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  componentWillReceiveProps(nextProps) {
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }

  removeCartSubscription() {
    this.setState({
      // selectedSubscription: "",
      selectedCountry: "",
      mobileNumber: "",
      isEmptyState: true,
      isAddTripState: false,
      firstName: "",
      lastName: "",
      message: "",
      email: "",
      // fromFirstName: "",
      // fromLastName: "",
      // fromEmail: "",
      // fromSelectedCountry: "",
      // fromMobileNumber: "",
      cartFailureMessage: "",
      emailError: "",
      firstNameError: "",
      lastNameError: "",
      messageError: "",
      selectedSubscriptionError: "",
      selectedCountryError: "",
      mobileNumberError: "",
      fromFirstNameError: "",
      fromLastNameError: "",
      fromEmailError: "",
      fromSelectedCountryError: "",
      fromMobileNumberError: "",
      cuponMessageSuccess: "",
      cuponMessage: "",
      couponError: "",
      acceptTermError: "",
      coupneVoucher: "",
    });

    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      token: this.state.cartDetailsProducts.token,
    };
    axios
      .post(api_base_url + "/api/cancel-gift-order/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }

  /***************************************************************
   * When user click on payment button then below method is called.
   * Make payment to require
   * User Authorization token
   *
   ***************************************************************/

  makePaymentSubscription() {
    if (this.state.acceptTerm) {
      this.setState({
        cuponMessageSuccess: "",
        cuponMessage: "",
        couponError: "",
        acceptTermError: "",
        coupneVoucher: "",
      });
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      var orderCurrency = "1";
      if (this.props.authData.loginSuccess !== true ? this.state.fromSelectedCountry === "IN" : this.state.currcountry === "IN") {
        orderCurrency = "2";
      }
      var apiParams = {
        order_currency: orderCurrency,
        gift_order: true,
        email: this.state.email,
        cart_token: this.state.cartDetailsProducts.token,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        message: this.state.message,
        country: this.state.selectedCountry,
        mobile: this.state.mobileNumber,
      };
      axios
        .post(api_base_url + "/api/v1/orders/", apiParams, { headers: headers })
        .then((response) => {
          if (response.data.status === true) {
            var orderToken = response.data.data.order_token;
            var headers = {
              "Content-Type": "application/json",
              Authorization: "Token " + this.state.token,
            };
            var apiParams = {
              token: orderToken,
            };
            if (this.props.authData.loginSuccess !== true ? this.state.fromSelectedCountry === "IN" : this.state.currcountry === "IN") {
              axios
                .post(api_base_url + "/api/v1/payu-checkout/", apiParams, {
                  headers: headers,
                })
                .then((response) => {
                  if (response.data == "cc_avenue") {
                    window.open(api_base_url + '/order/ccavenue-pay/' + orderToken, '_self', 'top=10', 'replace=false');
                  } else {
                    this.props.history.push({
                      pathname: "/proceed-payment",
                      state: { detail: response.data, isPayAutoRenewal: false },
                    });
                  }
                })
                .catch((err) => {
                  // console.log("fetched.", err);
                });
            } else {
              axios
                .post(api_base_url + "/api/v1/paypal-checkout/", apiParams, {
                  headers: headers,
                })
                .then((response) => {
                  this.props.history.push({
                    pathname: "/proceed-payment",
                    state: { detail: response.data, isPayAutoRenewal: false },
                  });
                })
                .catch((err) => {
                  // console.log("fetched.", err);
                });
            }
          }
        })
        .catch((err) => {
          // console.log("fetched.", err);
        });
    } else {
      this.setState({
        acceptTermError: "Please accept terms of service",
      });
    }
  }

  getuserCountry = async () => {
    let getcountrycode;
    try {
      getcountrycode = await localStorage.getItem("currentcountrycode")
      this.setState({ currcountry: getcountrycode })
      // alert(this.state.currcountry)
    }
    catch (e) {

    }
  }

  handleCoupenValue(e) {
    this.setState({ coupneVoucher: e.target.value });
    if (this.state.coupneVoucher.length > 0) {
      this.setState({
        hiddenRemoveCupon: true,
      });
    }
  }

  /*********  Apply coupon code **************/
  applyCoupen() {
    this.setState({
      cuponMessageSuccess: "",
      cuponMessage: "",
      couponError: "",
    });

    var isValid = false;

    if (this.state.coupneVoucher === "") {
      this.setState({
        couponError: strings.EnterValidCoupon,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    if (isValid) {
      var apiParams = {
        token: this.state.cartDetailsProducts.token,
        voucher_code: this.state.coupneVoucher,
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .post(api_base_url + "/api/apply-coupon/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status === true) {
            var newDiscount = response.data.data.discount_amount;
            if (this.props.authData.loginSuccess !== true ? this.state.fromSelectedCountry === "IN" : this.state.currcountry === "IN") {
              newDiscount = response.data.data.discount_amount_inr;
            }
            var newIgstAmount = 0;
            var newCgstAmount = 0;
            var newSgstAmount = 0;
            if (this.props.authData.loginSuccess !== true ? this.state.fromSelectedCountry === "IN" : this.state.currcountry === "IN") {
              var newTotal = this.state.AfterCuponAmount - newDiscount;
              if (this.state.igstPercent !== 0) {
                newIgstAmount = (newTotal * this.state.igstPercent) / 100;
              } else {
                newCgstAmount = (newTotal * this.state.cgstPercent) / 100;
                newSgstAmount = (newTotal * this.state.sgstPercent) / 100;
              }
            }
            this.setState({
              isAddedCoupon: true,
              coupenDiscountAmount: newDiscount,
              cuponMessageSuccess: response.data.message,
              cuponMessage: "",
              igstAmount: newIgstAmount,
              cgstAmount: newCgstAmount,
              sgstAmount: newSgstAmount,
            });
          }
          if (response.data.status === false) {
            this.setState({
              cuponMessage: response.data.data[0].voucher_code[0],
            });
          }
        })
        .catch((err) => {
          // console.log("fetched.", err);
        });
    }
  }

  /*****  Remove coupon code **********/
  removeCopenCode() {
    var apiParams = {
      token: this.state.cartDetailsProducts.token,
      voucher_code: "",
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/apply-coupon/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          var newIgstAmount = 0;
          var newCgstAmount = 0;
          var newSgstAmount = 0;
          if (this.props.authData.loginSuccess !== true ? this.state.fromSelectedCountry === "IN" : this.state.currcountry === "IN") {
            if (this.state.igstPercent !== 0) {
              newIgstAmount =
                (this.state.AfterCuponAmount * this.state.igstPercent) / 100;
            } else {
              newCgstAmount =
                (this.state.AfterCuponAmount * this.state.cgstPercent) / 100;
              newSgstAmount =
                (this.state.AfterCuponAmount * this.state.sgstPercent) / 100;
            }
          }
          this.setState({
            isAddedCoupon: false,
            coupenDiscountAmount: 0,
            cuponMessage: response.data.message,
            totalPrice: this.state.AfterCuponAmount,
            coupneVoucher: "",
            cuponMessageSuccess: "",
            igstAmount: newIgstAmount,
            cgstAmount: newCgstAmount,
            sgstAmount: newSgstAmount,
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }

  render() {
    return (
      <div className="container-fluid stellar-wrapper-footer">
        <div>
          {this.state.selectedLanguage === "EN" ? (

            <img src="/images/stellar audio.png"
              className="gift-stellar-img"

            />

          ) : (

            <img src="/images/snovel web home marathi 1-06.png"
              className="gift-stellar-img"

            />

          )}
        </div>
        <div className="giftSubscriptionWrp">
          <div className="container">
            <div className="row">
              {this.state.isEmptyState && (
                <div className="giftSubscriptionForm">
                  <div className="giftSubscriptionHeader">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/gift_susbcriptionHeader.png"
                      }
                      alt=""
                    />
                  </div>
                  <Form autocomplete="off">
                    <div className="giftFromOuter" ref={this.myRef}>{strings.giftFrom}</div>
                    <div className="giftSubscriptionFrmLft">
                      <Input
                        type="text"
                        name="fname"
                        id="fname"
                        autocomplete="off"
                        placeholder={strings.firstName}
                        value={this.state.fromFirstName}
                        onChange={(event) => {
                          this.setState({ fromFirstName: event.target.value });
                        }}
                      />
                      <div class="loginErrorMsg">
                        {this.state.fromFirstNameError}
                      </div>
                    </div>
                    <div className="giftSubscriptionFrmLfRgt">
                      <Input
                        type="text"
                        name="lname"
                        id="lname"
                        autocomplete="off"
                        placeholder={strings.lastName}
                        value={this.state.fromLastName}
                        onChange={(event) => {
                          this.setState({ fromLastName: event.target.value });
                        }}
                      />
                      <div class="loginErrorMsg">
                        {this.state.fromLastNameError}
                      </div>
                    </div>
                    <div className="giftSubscriptionFrmFull">
                      <Input
                        type="text"
                        name="email"
                        id="userEmail"
                        autocomplete="off"
                        maxLength="50"
                        placeholder={strings.regiEmail}
                        value={this.state.fromEmail}
                        onChange={(event) => {
                          this.setState({ fromEmail: event.target.value });
                        }}
                      />
                      <div class="loginErrorMsg">{this.state.fromEmailError}</div>
                    </div>
                    <div className="giftSubscriptionFrmFull">
                      <FormGroup>
                        <Input
                          type="select"
                          name="select"
                          className="select-style"
                          value={this.state.fromSelectedCountry}
                          onChange={(event) => {
                            this.setState({
                              fromSelectedCountry: event.target.value,
                            });
                          }}
                        >
                          <option value="">{strings.countryCode}</option>
                          {countryList.map((eachCountry, index) => {
                            return (
                              <option
                                value={eachCountry.code}
                                key={eachCountry.code}
                              >
                                {eachCountry.name + ' (' + eachCountry.dial_code + ')'}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                      <div class="loginErrorMsg">
                        {this.state.fromSelectedCountryError}
                      </div>
                    </div>
                    <div className="giftSubscriptionFrmFull">
                      <Input
                        className="profile-input-field"
                        type="number"
                        name="phone"
                        id="phone"
                        placeholder={strings.mobileNumber}
                        maxLength={10}
                        value={this.state.fromMobileNumber}
                        onChange={(event) => {
                          this.setState({
                            fromMobileNumber: event.target.value,
                          });
                        }}
                      />
                      <div class="loginErrorMsg">{this.state.fromMobileNumberError}</div>
                    </div>
                    <div className="giftToOuter">{strings.giftTo}</div>
                    <div className="giftSubscriptionFrmLft">
                      <Input
                        type="text"
                        name="fname"
                        id="fname"
                        autocomplete="off"
                        placeholder={strings.firstName}
                        value={this.state.firstName}
                        onChange={(event) => {
                          this.setState({ firstName: event.target.value });
                        }}
                      />
                      <div class="loginErrorMsg">
                        {this.state.firstNameError}
                      </div>
                    </div>
                    <div className="giftSubscriptionFrmLfRgt">
                      <Input
                        type="text"
                        name="lname"
                        id="lname"
                        autocomplete="off"
                        placeholder={strings.lastName}
                        value={this.state.lastName}
                        onChange={(event) => {
                          this.setState({ lastName: event.target.value });
                        }}
                      />
                      <div class="loginErrorMsg">
                        {this.state.lastNameError}
                      </div>
                    </div>
                    <div className="giftSubscriptionFrmFull">
                      <Input
                        type="text"
                        name="email"
                        id="userEmail"
                        autocomplete="off"
                        maxLength="50"
                        placeholder={strings.regiEmail}
                        value={this.state.email}
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                      />
                      <div class="loginErrorMsg">{this.state.emailError}</div>
                    </div>
                    <div className="giftSubscriptionFrmFull countryCodeOuter">
                      <FormGroup>
                        <Input
                          type="select"
                          name="select"
                          className="select-style"
                          value={this.state.selectedCountry}
                          onChange={(event) => {
                            this.setState({
                              selectedCountry: event.target.value,
                            });
                          }}
                        >
                          <option value="">{strings.countryCode}</option>
                          {countryList.map((eachCountry, index) => {
                            return (
                              <option
                                value={eachCountry.code}
                                key={eachCountry.code}
                              >
                                {eachCountry.name + ' (' + eachCountry.dial_code + ')'}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                      <div class="loginErrorMsg">
                        {this.state.selectedCountryError}
                      </div>
                    </div>
                    <div className="giftSubscriptionFrmFull">
                      <Input
                        className="profile-input-field"
                        type="number"
                        name="phone"
                        id="phone"
                        placeholder={strings.mobileNumber}
                        maxLength={10}
                        value={this.state.mobileNumber}
                        onChange={(event) => {
                          this.setState({
                            mobileNumber: event.target.value,
                          });
                        }}
                      />
                      <div class="loginErrorMsg">{this.state.mobileNumberError}</div>
                    </div>
                    <div className="giftSubscriptionFrmFull extra-bottom-space">
                      <FormGroup>
                        <Input
                          type="select"
                          name="select"
                          className="select-style"
                          value={this.state.selectedSubscription}
                          onChange={(val) => this.selectedPackge(val)}
                        >
                          <option value="">{strings.SelectSubscription}</option>
                          {this.subscriptionList()}
                        </Input>
                      </FormGroup>
                      <div class="loginErrorMsg">
                        {this.state.selectedSubscriptionError}
                      </div>
                    </div>
                    <div className="giftSubscriptionFrmFull">
                      <FormGroup>
                        <Input
                          type="textarea"
                          name="text"
                          placeholder={strings.messageText}
                          maxLength="500"
                          value={this.state.message}
                          onChange={(event) => {
                            this.setState({ message: event.target.value });
                          }}
                        />
                      </FormGroup>
                      <div class="loginErrorMsg">{this.state.messageError}</div>
                    </div>
                    <div className="giftSubscriptionFrmLftSubmit">
                      <input
                        type="submit"
                        className="giftSubmit"
                        value={strings.submitText}
                        onClick={this.onSubmitGift}
                      />
                    </div>
                    <div className="giftSubscriptionFrmRgtSubmit">
                      <input
                        type="submit"
                        className="giftCancel"
                        value={strings.Cancel}
                        onClick={this.cancelGiftSubscription}
                      />
                    </div>
                    {this.state.cartFailureMessage.length > 0 && (
                      <div className="loginErrorMsg">
                        {this.state.cartFailureMessage}
                      </div>
                    )}
                  </Form>
                </div>
              )}
              {this.state.isAddTripState && (
                <div class="giftSubscriptionWrp">
                  <div class="container">
                    <div class="row">
                      <div class="giftSubscriptionGift">
                        <div class="ontheWay">
                          <div class="giftSubscriptionHeader bgGiftontheway">
                            <img
                              src={
                                process.env.PUBLIC_URL + "/images/GiftWay.png"
                              }
                              alt=""
                            />
                          </div>
                          <div class="yourGift">{strings.YourGiftWayTo}</div>
                          <div className="yourProdctDivName">
                            <div className="yourPrdNameCheckLft left__wrapper">{strings.CouponCode}:</div>
                            <div className="yourPrdNameCheckRgt right__wrapper">
                              <input
                                type="text"
                                name="coupon_code"
                                value={this.state.coupneVoucher}
                                onChange={this.handleCoupenValue}
                              />
                              {!this.state.isAddedCoupon && (
                                <input
                                  type="button"
                                  value={strings.ApplyCoupon}
                                  className="applyCoupen"
                                  onClick={this.applyCoupen}
                                />
                              )}
                              {this.state.isAddedCoupon && (
                                <input
                                  type="button"
                                  value={strings.RemoveCoupon}
                                  className="applyCoupen"
                                  onClick={this.removeCopenCode}
                                />
                              )}
                              {this.state.couponError.length > 0 && (
                                <div className="cuponErrorMessage">
                                  {this.state.couponError}
                                </div>
                              )}
                              {this.state.cuponMessageSuccess.length > 0 && (
                                <div className="cuponSuccessMessage">
                                  {this.state.cuponMessageSuccess}
                                </div>
                              )}
                              {this.state.cuponMessage.length > 0 && (
                                <div className="cuponErrorMessage">
                                  {this.state.cuponMessage}
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="yourGiftCnt">
                            <p>
                              {this.state.firstName}
                              <br />
                              {this.state.email}
                              <br />
                              {
                                this.state.subscribePackages[
                                  this.state.selectedSubscription
                                ].custom_translation[this.state.selectedLanguage].name
                              }
                            </p>
                            <div className="paypmentTerms">
                              <label className="checkbox">
                                {strings.ReadAndAcceptThe}
                                <input
                                  type="checkbox"
                                  name="acceptTerm"
                                  onChange={(event) => {
                                    this.setState({
                                      acceptTerm: event.target.checked,
                                    });
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <a
                                href="/terms-of-service"
                                title="Terms Of Service"
                              >
                                {" "}
                                {strings.termsAndConditions}*{" "}
                              </a>
                            </div>
                            <div class="loginErrorMsg">
                              {this.state.acceptTermError}
                            </div>
                            <div class="yourGiftProceed">
                              <a
                                href="javascript:;"
                                onClick={this.makePaymentSubscription}
                              >
                                {strings.ProceedForPayment}
                              </a>
                            </div>
                            <div class="yourGiftCancel">
                              <a
                                href="javascript:;"
                                onClick={this.removeCartSubscription}
                              >
                                {strings.Cancel}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="snovelapp-left">
                <div class="left-contents">
                  <h2>{strings.getTheSnovelApp}</h2>
                  <p>{strings.snovelAppDesc}</p>
                  <div class="app-btn">
                    <a
                      href="https://apps.apple.com/in/app/snovel/id1064404656"
                      title=""
                      target="_blank"
                    >
                      <div class="iphone-app-btn">
                        <img
                          src="images/iphone-app-icon.png"
                          alt="iphone-app"
                          class="iphone-app-icon"
                        />
                        <p>{strings.appledownload}</p>
                      </div>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.sn.snovel&hl=en"
                      title=""
                      target="_blank"
                    >
                      <div class="android-app-btn">
                        <img
                          src="images/android-app-icon.png"
                          alt="android-app"
                          class="android-app-icon"
                        />

                        <p>{strings.platStoreDownload}</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    authData: state.authonticationDetails,
  };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(giftSnovel));