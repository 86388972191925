import axios from "axios";
import { api_base_url } from "../config";

export function languageLabCategoryAction(currentSlug) {
  return function (dispatch) {
    var headers = {
      "Content-Type": "application/json",
      "index-api-token": "rb9jB8KSb4WQkHIFZ92r7GAUuVtcNgnQ",
    };

    var productHeaders = {
      "Content-Type": "application/json",
      "index-api-token": "rb9jB8KSb4WQkHIFZ92r7GAUuVtcNgnQ",
    };
    dispatch({
      type: "SHOW_LOADER",
    });
    axios
      .get(api_base_url + "/api/v2/category-list/language-lab/", {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          // var updatedArray = [];
          var promises = [];
          (response.data.category_list || []).forEach(
            (element) => {
              var mainCategory = new Object();
              mainCategory["id"] = element["id"];
              mainCategory["custom_translation"] =
                element["custom_translation"];
              mainCategory["sliders"] = [];
              let categoryID = element["id"];
              var output = new Promise((resolve, reject) => {
                axios
                  .get(
                    api_base_url +
                    "/api/v2/category-product-list/language-lab/" +
                    categoryID +
                    "/10",
                    { headers: productHeaders }
                  )
                  .then((response) => {
                    if (response["status"] === 200) {
                      mainCategory["sliders"] =
                        response["data"]["category_products"];
                      resolve(mainCategory);
                    }
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
              promises.push(output);
            }
          );
          Promise.all(promises).then((result) => {
            dispatch({
              type: "LANGUAGE_LAB_CATEGORY_LIST",
              payload: result,
            });
            dispatch({
              type: "HIDE_LOADER",
            });
          });
        } else {
          dispatch({
            type: "FAILED_LANGUAGE_LAB_CATEGORY_LIST",
            payload: [],
          });
        }
      })
      .catch((err) => {
        // console.log('fetched.', err);
        dispatch({
          type: "HIDE_LOADER",
        });
      });
  };
}
