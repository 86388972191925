import axios from 'axios';
import { api_base_url, dashboardToken } from '../config';

export function homeTrendingProductDetailsData() {
  return function (dispatch) {
    var headers = {
      'Content-Type': 'application/json',
      'index-api-token': dashboardToken
    }
    dispatch({
      type: "SHOW_LOADER"
  });
    axios.get(api_base_url + '/api/v2/browse-top-snovel/10/', { headers: headers })
      .then(response => {
        if (response) {
          dispatch({
            type: "HOME_TRENDING_PRODUCT",
            payload: response.data
          });
        } else {
          dispatch({
            type: "FAILED_HOME_TRENDING_PRODUCT",
            payload: response.data
          });
        }
        dispatch({
          type: "HIDE_LOADER"
      });
      })
      .catch(err => {
        // console.log('fetched.', err)
        dispatch({
          type: "HIDE_LOADER"
      });
      });

  }
}