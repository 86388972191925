import React, { Component } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import {
  NavLink,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";
import {
  api_base_url,
  dashboardToken,
  encryptDecryptKey,
  googleAnalyticsTrackingId,
} from "../config";
import axios from "axios";
import SimpleCrypto from "simple-crypto-js";
import { Link, withRouter, useHistory } from "react-router-dom";
import {
  homeFeaturedAction
} from "../action/homeFeaturedAction"
import swal from "sweetalert";
import OwlCarousel from "react-owl-carousel2";
import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);
const optionss = {
  nav: true,
  rewind: true,
  autoplay: true,
  navText: ["<div></div>", "<div></div>"],
  margin: 15,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};
const sliderLaunched = {
  nav: true,
  autoplay: false,
  margin: 15,
  navText: ["<div></div>", "<div></div>"],
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  },
};
class HomePage extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      selectedLanguage: selectedLanguage || "EN",
      audioObject: undefined,
      latestLaunchAudio: {},
      latestLaunchLastPlayed: "",
      kidsPopularAudio: {},
      kidsPopularLastPlayed: "",
      audioProductList: [],
      audiosubcriptionId: [],
      featuredProductList: [],
      kidsCategoryLastPlayed: "",
      isFeaturedProductsAPIcall: false,
      userToken: token || "",
      cookiesPopup: false,
      buttonToggle: false

    };

    ReactGA.pageview("/home");

    this.targetImageButton = this.targetImageButton.bind(this);
    this.userSubscribedApi = this.userSubscribedApi.bind(this)

    this.closeCookiesPolicyPopup = this.closeCookiesPolicyPopup.bind(this);
  }
  componentDidMount() {
    if (!this.state.isFeaturedProductsAPIcall) {
      this.props.dispatch(homeFeaturedAction());

    }
    this.setState({ audioProductList: this.state.featuredProductList[2] })

    // this.targetImageButton()

  }

  componentWillMount() {
    this.showCookiePolicyPopup();
    this.featuredCategoryAudioList()
  }
  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.isPauseInnerProduct === true && this.state.audioObject) {
      this.state.audioObject.pause();
      this.setState({
        latestLaunchLastPlayed: "",
        kidsPopularLastPlayed: "",
        kidsCategoryLastPlayed: "",
      });
      this.props.onPauseSample("innerProduct");
    }
    if (
      nextProps.homeFeaturedProductData !== prevState.homeFeaturedProductData &&
      nextProps.homeFeaturedProductData.length > 0
    ) {
      this.setState({
        featuredProductList: nextProps.homeFeaturedProductData,
        isFeaturedProductsAPIcall: true,
        audioProductList: this.state.featuredProductList[0]
      });
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  componentWillUnmount() {
    if (this.state.audioObject) {
      this.state.audioObject.pause();
    }

  }

  productDetailsId(productID, slug) {
    localStorage.setItem("productId", productID);
    this.props.history.push("/product-details/" + productID + "/" + slug);
  }

  showCookiePolicyPopup() {
    if (!window.localStorage.getItem("isShowCookiePolicy")) {
      window.localStorage.setItem("isShowCookiePolicy", true);
      this.setState({
        cookiesPopup: true,
      });
    }
  }

  closeCookiesPolicyPopup() {
    this.setState({
      cookiesPopup: false,
      loadingPopup: true,
    });
  }


  //  let history = useHistory()


  targetImageButton(e) {
    var totalWidth = e.target.width;
    var totalHeight = e.target.height;
    const x = e.pageX - e.target.offsetLeft;
    const y = e.pageY - e.target.offsetTop;

    var halfWidth = totalWidth / 2;
    var halfHeight = totalHeight / 2;

    if ((x > halfWidth / 2 && x < halfWidth) && (y > (totalHeight - (halfHeight / 2)) && y < totalHeight)) {
      // window.open(_self, '/subscribe')
      window.location.href = "/subscribe";
    } else if ((x > halfWidth && x < (halfWidth + halfWidth / 2) && (y > (totalHeight - (halfHeight / 2)) && y < totalHeight))) {
      // window.open('/try-free-snovel')
      window.location.href = "/try-free-snovel";
    }

    // // this.setState({ buttonToggle: !this.state.buttonToggle })


  }


  featuredCategoryAudioList() {
    var headers = {
      'Content-Type': 'application/json',
      'index-api-token': dashboardToken
    };
    axios.get(api_base_url + '/api/audio-courses-index-featured/10/', { headers: headers })
      .then(response => {

        let subType = []
        if (response.data.featured_product) {
          for (let responseJson of response.data.featured_product) {
            subType.push(responseJson)

          }
        }
        this.setState({ audiosubcriptionId: subType })

      })

  }



  /***********************************
   *  Kids Category section
   * ********************************* */
  featuredCategorySliderContent(kidsCategoryResult) {

    return kidsCategoryResult.map((sliderElement, slideIndex) => {
      return (
        <div >
          {sliderElement.images.length > 0 ? (
            <div className="sliderThumb">
              {sliderElement.is_free && (
                <span className="freeProduct">{strings.FreeText}</span>
              )}
              <img
                src={sliderElement.images[0]["image2"]}
                className="sampleProduct_thumb"
                onClick={() => {
                  this.productDetailsId(
                    sliderElement.id,
                    sliderElement.product_slug
                  );
                }}
              />
            </div>
          ) : (
            <div className="sliderThumb">
              {sliderElement.is_free && (
                <span className="freeProduct">{strings.FreeText}</span>
              )}
              <img
                src="/images/default.jpg"
                className="sampleProduct_thumb"
                onClick={() => {
                  this.productDetailsId(
                    sliderElement.id,
                    sliderElement.product_slug
                  );
                }}
              />
            </div>
          )}

          <div className="sliderTrack_Wrap">
            {sliderElement.sampletracks.length > 0 && (
              <div>
                {sliderElement.sampletracks[0].map_url !=
                  this.state.kidsCategoryLastPlayed && (
                    <img
                      onClick={() => {
                        this.kidsCategoryLaunchPlayAudio(
                          sliderElement.sampletracks[0].map_url
                        );
                      }}
                      src="/images/play.png"
                    />
                  )}
                {sliderElement.sampletracks[0].map_url ==
                  this.state.kidsCategoryLastPlayed && (
                    <img
                      onClick={() => {
                        this.kidsCategoryLaunchPauseAudio(
                          sliderElement.sampletracks[0].map_url
                        );
                      }}
                      src="/images/round-pause-button.svg"
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      );
    });
  }


  userSubscribedApi() {
    return (
      <div className="audio-course-carousel">


        <button
          className="home_btn_audio">
          <a href="/language-lab" className="text-audio"> {strings.viewAll}</a>
        </button>

        <OwlCarousel options={sliderLaunched}>
          {
            this.state.audiosubcriptionId.map((ele) => (
              <div >
                <div>

                  <div className="homepage-audio-wrapper">

                    {ele.images.length > 0 && (
                      <div className="sliderThumb">
                        {ele.is_free && (
                          <span className="freeProduct">{strings.FreeText}</span>
                        )}
                        <img
                          src={ele.images[0]["image2"]}
                          className="sampleProduct_thumb"
                          onClick={() => {
                            this.productDetailsId(
                              ele.id,
                              ele.product_slug
                            );
                          }}
                        />
                      </div>
                    )}
                    {ele.images.length < 1 && (
                      <div className="sliderThumb">
                        {ele.is_free && (
                          <span className="freeProduct">{strings.FreeText}</span>
                        )}
                        <img
                          src="/images/default.jpg"
                          className="sampleProduct_thumb"
                          onClick={() => {
                            this.productDetailsId(
                              ele.id,
                              ele.product_slug
                            );
                          }}
                        />
                      </div>
                    )}


                    {ele.sampletracks.length > 0 && (
                      <div className="sliderTrack1_Wrap">
                        {ele.sampletracks[0].map_url !=
                          this.state.kidsCategoryLastPlayed && (
                            <img
                              onClick={() => {
                                this.kidsCategoryLaunchPlayAudio(
                                  ele.sampletracks[0].map_url
                                );
                              }}
                              src="/images/play.png"
                            />
                          )}
                        {ele.sampletracks[0].map_url ==
                          this.state.kidsCategoryLastPlayed && (
                            <img style={{ marginLeft: "80px" }}
                              onClick={() => {
                                this.kidsCategoryLaunchPauseAudio(
                                  ele.sampletracks[0].map_url
                                );
                              }}
                              src="/images/round-pause-button.svg"
                            />
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

            ))
          }
        </OwlCarousel>
      </div>
    )
  }




  snovelAppSubscriptionSection() {
    return (
      <div>
        <h4 style={{ fontSize: "18px", textTransform: "capitalize", fontWeight: "bold" }}>{strings.course}</h4>
        {this.userSubscribedApi()}
        <Row className="snovel-app-subscription-section">
          <Col md="12" lg="8">
            <div className="snovelapp-left">
              <div class="left-contents">
                <h2>{strings.getTheSnovelApp}</h2>
                <p>{strings.snovelAppDesc}</p>
                <div class="app-btn">
                  <a
                    href="https://apps.apple.com/in/app/snovel/id1064404656"
                    title=""
                    target="_blank"
                  >
                    <div class="iphone-app-btn">
                      <img
                        src="images/iphone-app-icon.png"
                        alt="iphone-app"
                        class="iphone-app-icon"
                      />
                      <p>{strings.appledownload}</p>
                    </div>
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.sn.snovel&hl=en"
                    title=""
                    target="_blank"
                  >
                    <div class="android-app-btn">
                      <img
                        src="images/android-app-icon.png"
                        alt="android-app"
                        class="android-app-icon"
                      />

                      <p>{strings.platStoreDownload}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="snovelapp-right">
              <div class="right-contents">
                <h2>{strings.giftSnovelExp}</h2>
                <p>{strings.snovelAppDescright}</p>
                <div class="snovel-gift">
                  <div class="gift-icon">
                    <img
                      src="images/gift-icon.png"
                      alt="gift-icon"
                      class="gift-icon1"
                    />
                  </div>
                  <div class="gift-text">
                    <a href="/gift-snovel">
                      {" "}
                      <p>{strings.giftSnovelExp}</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )

  }






  kidsCategoryLaunchPlayAudio(map_url) {
    if (this.state.kidsPopularAudio[map_url]) {
      this.setState({
        kidsCategoryLastPlayed: map_url,
        latestLaunchLastPlayed: "",
        kidsPopularLastPlayed: "",
      });
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                kidsCategoryLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newLatestLaunchAudio = {};
            newLatestLaunchAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              kidsPopularAudio: newLatestLaunchAudio,
              kidsCategoryLastPlayed: map_url,
              latestLaunchLastPlayed: "",
              kidsPopularLastPlayed: "",
            });
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }

  kidsCategoryLaunchPauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      kidsCategoryLastPlayed: "",
    });
  }

  render() {
    return (
      <div className="kids-main-wrapper">
        {this.props.loader.isLoader && (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        )}

        {

          <div className="container" >
            <img src="/images/audio lib snovel mobile app home 1-01-29112022.png" alt=""
              className="audio_story_img"
            />
          </div>
        }
        {
          <div className="container audio-courses-main-tag">
            {/* <div className="audio-banner-wrapper">
              <div className="audio-banner ">
                <NavLink className="audio-img1"
                  tag={Link}

                  to='' >
                  {strings.lang}
                </NavLink>

              </div>
              <div className="audio-banner2 ">
                <NavLink className="audio-img1"
                  tag={Link}

                  to='language-lab' >
                  {strings.course}
                </NavLink>

              </div>
              <div className="audio-banner3 ">

                <NavLink className="audio-img1"
                  tag={Link}

                  to='/images/VOB Leaflet.pdf' target="_blank" >
                  {strings.voice}
                </NavLink>
              </div>
              <div className="audio-banner4">
                <NavLink className="audio-img1"
                  tag={Link}

                  to='/images/Audio OTT (1).pdf' target="_blank" >
                  {strings.audioOTT}
                </NavLink>

              </div>
            </div> */}

            {
              strings._language === strings._defaultLanguage ? (
                <div class="row">
                  <div class="column">

                    <img src="/images/snovel web home 1-02.png" alt="Snow" style={{ width: "100%" }} />

                  </div>
                  <div class="column">
                    <a href="language-lab">
                      <img src="/images/snovel web home 1-03.png" alt="Forest" style={{ width: "100%" }} />
                    </a>
                  </div>
                  <div class="column">
                    <a href="/images/Snovel Voice of Brand Leaflet.pdf" target="_blank" >
                      <img src="/images/snovel web home 1-04.png" alt="Mountains" style={{ width: "100%" }} />
                    </a>
                  </div>
                  <div class="column">
                    <a href="/images/Snovel Audio OTT Solution Leaflet.pdf" target="_blank">
                      <img src="/images/snovel web home 1-05.png" alt="Mountains" style={{ width: "100%" }} />
                    </a>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="column">
                    <img src="/images/snovel web home marathi 1-02.png" alt="Snow" style={{ width: "100%" }} />
                  </div>
                  <div class="column">
                    <a href="audio-courses">
                      <img src="/images/snovel web home marathi 1-03.png" alt="Forest" style={{ width: "100%" }} />
                    </a>
                  </div>
                  <div class="column">
                    <a href="/images/Snovel Voice of Brand Leaflet.pdf" target="_blank" >
                      <img src="/images/snovel web home marathi 1-04.png" alt="Mountains" style={{ width: "100%" }} />
                    </a>
                  </div>
                  <div class="column">
                    <a href="/images/Snovel Audio OTT Solution Leaflet.pdf" target="_blank">
                      <img src="/images/snovel web home marathi 1-05.png" alt="Mountains" style={{ width: "100%" }} />
                    </a>
                  </div>
                </div>
              )
            }


            {/* <div className="row audio-banner-wrapper">
              <img src="/images/snovel web home 1-02.png" alt=""
                className="audio-story-media"
              />
              <img src="/images/snovel web home 1-03.png" alt=""
                className="audio-story-media"
              />
              <img src="/images/snovel web home 1-04.png" alt=""
                className="audio-story-media"
              />
              <img src="/images/snovel web home 1-05.png" alt=""
                className="audio-story-media"
              />
            </div> */}

          </div>
        }

        {/* 
        {
          <div className="container audio-courses-main-tag" >
            <div className="row audio-banner-wrapper">
              <div className="audio-banner4">
                <NavLink className="audio-img1"
                  tag={Link}

                  to='' >
                  {strings.lang}
                </NavLink>

              </div>
              <div className="audio-banner4">
                <NavLink className="audio-img1"
                  tag={Link}

                  to='language-lab' >
                  {strings.course}
                </NavLink>

              </div>
              <div className="audio-banner4 ">

                <NavLink className="audio-img1"
                  tag={Link}

                  to='/images/VOB Leaflet.pdf' target="_blank" >
                  {strings.voice}
                </NavLink>
              </div>
              <div className="audio-banner4 ">
                <NavLink className="audio-img1"
                  tag={Link}

                  to='/images/Audio OTT (1).pdf' target="_blank" >
                  {strings.audioOTT}
                </NavLink>

              </div>
            </div>
          </div>

        } */}


        {/* <section className="baner-section kidsHomePage-Banner">
          {this.state.selectedLanguage === "EN" ? (
            <OwlCarousel id="homePage" ref="car" options={optionss}>
              <div>
                <img src="/images/homeBanner01.png" />
              </div>
              <div >
                <img src="/images/homeBanner02.png" />
              </div>

            </OwlCarousel>
          ) : (
            <OwlCarousel id="homePage" ref="car" options={optionss}>
              <div>
                <img src="/images/homeBanner01.png" />
              </div>
              <div>
                <img src="/images/homeBanner02.png" />
              </div>
            </OwlCarousel>
          )}
        </section> */}

        {/* <div class="container" >
          <Row style={{ justifyContent: "space-evenly" }}>
            <Col xs="2" md-2
              style={{ border: "2px solid black", padding: "50px", textAlign: "center" }}>
              <a href="/" style={{ textDecoration: 'none', color: 'black' }}><b>Kids</b></a>
            </Col>
            <Col xs="2" md-2
              style={{ border: "2px solid black", padding: "50px", textAlign: "center" }}>
              <a href="/family" style={{ textDecoration: 'none', color: 'black' }}><b>Family</b></a>
            </Col>
            <Col xs="2" md-2
              style={{ border: "2px solid black", padding: "50px", textAlign: "center" }}>
              <a href="/language-lab" style={{ textDecoration: 'none', color: 'black' }}> <b>Audio Courses</b> </a>
            </Col>
          </Row>
        </div> */}
        <br />



        <div className="container homepage-design">
          {this.state.featuredProductList.length > 0 ?
            <div className="sliderContentWrapper kidsCategorySliders">
              {this.state.featuredProductList.map((cat, catIndex) => {
                if (cat.length > 0) {
                  return (
                    <div>
                      <div className="kidsCategoryMain-wrapper" key={'featured-prouct-' + catIndex + 1}>
                        <h4 className={"float-left custom-category-title"}>{catIndex == 0 ? strings.kidsMenu : catIndex == 1 ? strings.familyText : catIndex == 2 ? strings.kidsMenu + " - " + strings.popularTitles : catIndex == 3 ? strings.kidsMenu + " - " + strings.editorPicksSmall : catIndex == 4 ? strings.familyText + " - " + strings.popularTitles : catIndex == 5 ? strings.familyText + " - " + strings.editorPicksSmall : ''}</h4>
                        {catIndex < 6 ?
                          <NavLink
                            tag={Link}
                            to={catIndex == 0 ? '/kids' : catIndex == 1 ? '/family' : catIndex == 2 ? '/kids' : catIndex == 3 ? '/kids' : catIndex == 4 ? '/family' : '/family'}
                          >
                            <button className={"view-all-button"}>{strings.viewAll}</button>
                          </NavLink>
                          :
                          null
                        }
                        <OwlCarousel options={sliderLaunched}>
                          {this.featuredCategorySliderContent(cat)}
                        </OwlCarousel>
                      </div>

                      {
                        catIndex == 1 ?
                          <div>

                            {this.snovelAppSubscriptionSection()}
                          </div>

                          :
                          null
                      }
                    </div>
                  );
                }
              })}
            </div>
            :
            this.snovelAppSubscriptionSection()
          }


          {/* <div className="collaborateText_Wrapper">
            <h2>{strings.collaboratewithSnovel}</h2>
            <p>{strings.collaboratewithSnovelText}</p>
          </div> */}
          {/* <img src="/images/snovel web home 1-07.png" alt=""

              style={{ width: "100%", height: "300px" }}
            /> */}
          {/* <div className="row footer-banner ">
            <div className="col-md-6 hidden-sm"></div>
            <div className="col-md-6">
              <h3>{strings.collbrateContent}</h3>
              <div className="collabrate-inner-text">
                <h6 className="collabrate-wrapper">{strings.collbrateTxt}</h6>
                <h6 className="collabrate-wrapper">{strings.collbrateTxtOne}</h6>
                <h6 className="collabrate-wrapper">{strings.collbrateTxtSec}</h6>
              </div>
              <h5>{strings.snovelContact}</h5>
            </div>
          </div> */}
          <div className="container stellar-text-wrapper-content">
            {
              strings._language === strings._defaultLanguage ? (
                <img src="/images/Collabrate with snovel.png"
                  className="home-collbrate-img"

                />
              ) : (
                <img src="/images/snovel web home marathi 1-07.png"

                  className="home-collbrate-img"
                />
              )
            }
          </div>
          {/* <div class="collaboration-points collaboration-pointsmobile">
            <div class="collab director">
              <NavLink
                tag={Link}
                to={{
                  pathname: "/contact",
                  state: { params: { user: "director" } },
                }}
              >
                <div class="directCircle">
                  <img src="images/director-icon.png" alt="director-icon" />
                </div>
              </NavLink>
              <p class="desktopPoints">{strings.amDirector}</p>
            </div>
            <div class="collab writer">
              <NavLink
                tag={Link}
                to={{
                  pathname: "/contact",
                  state: { params: { user: "writer" } },
                }}
              >
                <div class="directCircle">
                  <img src="images/IMWritter.png" alt="writer-icon" />
                </div>
              </NavLink>
              <p class="desktopPoints">{strings.amWriter}</p>
            </div>
            <div class="collab publisher">
              <NavLink
                tag={Link}
                to={{
                  pathname: "/contact",
                  state: { params: { user: "publisher" } },
                }}
              >
                <div class="directCircle">
                  <img src="images/IMPublisher.png" alt="publisher-icon" />
                </div>
              </NavLink>
              <p class="desktopPoints">{strings.amPublisher}</p>
            </div>
            <div class="collab voice-artist">
              <NavLink
                tag={Link}
                to={{
                  pathname: "/contact",
                  state: { params: { user: "artist" } },
                }}
              >
                <div class="directCircle">
                  <img
                    src="images/IMVoiceOverArtist.png"
                    alt="voice-artist-icon"
                  />
                </div>
              </NavLink>
              <p class="desktopPoints">{strings.amArtist}</p>
            </div>
          </div> */}

          {/* <div className="row stellar-banner mt-5">

            <div className="stellar-text">
              <h3 className="stellar-cotation1">{strings.stellar}
                <br />
                {strings.stellarExp}
                <br />
                {strings.stellarExpwith}
              </h3>

              <h5 className="stellar-cotation">Enrichment.Education.Entertainment</h5>
            </div>
          </div> */}

          <div className="container stellar-text-wrapper-content">
            {
              strings._language === strings._defaultLanguage ? (
                <img src="/images/stellar audio.png"
                  className="home-stellar-img"

                />
              ) : (
                <img src="/images/snovel web home marathi 1-06.png"
                  className="home-stellar-img"

                />
              )
            }
          </div>


        </div >
        {/* <div className="track-background mt-3">

          

          <div class="container">
            <Row>
              <div className="each-track-cont-wrapper">
                <div className="each-track-cont">
                  <h2>{strings.readyToBegin}</h2>
                  <p>{strings.readyToBeginDesc}</p>
                  <p>{strings.readyToBeginDesc2}</p>
                  <p>{strings.readyToBeginDesc3}</p>
                  <div className="track-btn">
                    <span className="subsCribeButton">
                      <NavLink tag={Link} to="/subscribe">
                        {strings.subscribeNow}
                      </NavLink>
                    </span>
                    <span className="purchaseButton">
                      {this.props.authData.loginSuccess ? (
                        <NavLink tag={Link} to="/try-free-snovel">
                          {strings.tryFreeSnovel}
                        </NavLink>
                      ) : (
                        <NavLink tag={Link} to="/sign-in">
                          {strings.tryFreeSnovel}
                        </NavLink>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div> */}



        {/* <img src="/images/snovel web home 1-09-09.png"
            className="snovel-subscription-img"

          /> */}
        {/* <div className="row snovel-enhacment ">
            <div className="snovel-text-wrapper">
              <h1 className="snovel-text-value">Snovel</h1>

            </div>
            <div className="snovel-text1-wrapper">
              <p className="snovel-value">Enrichment.Education.Entertainment</p>
            </div>
            <div className="snovel-text2-wrapper">
              <p className="snovel-value-ready">{strings.readyToBegin}</p>
            </div>
            <div className="snovel-text3-wrapper">
              <p className="snovel-value-ready1">{strings.readyToBeginDesc1}</p>
            </div>
            <div className="snovel-text4-wrapper">
              <p className="snovel-value-ready2">{strings.readyToBeginDesc4}</p>
            </div>
            <div className="snovel-wrapper-btn">
              <a href="/subscribe" className="snovel-btn1">Subscribe Now</a>
              <a href="/try-free-snovel" className="snovel-btn2">Listen Free</a>
            </div>
          </div> */}





        {/* <div className="row snovel-enhacment">
            <h1 className="snovel-value">Snovel</h1>
            <p className="snovel-value1">Enrichment.Education.Entertainment</p>
            {
              strings._language === strings._defaultLanguage ? (
                <div>
                  <p className="snovel-value-ready">{strings.readyToBegin}</p>
                  <p className="snovel-value-ready1">{strings.readyToBeginDesc1}</p>
                  <p className="snovel-value-ready2">{strings.readyToBeginDesc4}</p>
                  <div className="snovel-wrapper-btn">
                    <a href="/subscribe" className="snovel-btn1">{strings.subscribeNow}</a>
                    <a href="/try-free-snovel" className="snovel-btn2">{strings.tryFreeSnovel}</a>
                  </div>
                </div>

              ) : (
                <div>
                  <p className="snovel-ready">{strings.readyToBegin}</p>
                  <p className="snovel-ready1">{strings.readyToBeginDesc1}</p>
                  <p className="snovel-ready2">{strings.readyToBeginDesc4}</p>
                  <div className="snovel-wrapper-btn">
                    <a href="/subscribe" className="snovel-btn1-marathi">{strings.subscribeNow}</a>
                    <a href="/try-free-snovel" className="snovel-btn2-marathi">{strings.tryFreeSnovel}</a>
                  </div>
                </div>
              )
            }

          </div> */}


        <div className="container stellar-text-wrapper-content1">
          {
            strings._language === strings._defaultLanguage ? (
              <>

                <img src="/images/home_page_footer.png"
                  className="home-education-img"
                  onClick={(e) => this.targetImageButton(e)}
                />

              </>
            ) : (
              <>

                <img src="/images/snovel web home marathi 1-09.png"
                  className="home-education-img"
                  onClick={(e) => this.targetImageButton(e)}
                />

              </>
            )
          }
        </div>








        {/* {
          <NavLink tag={Link} to="/subscribe">
            x
          </NavLink>
        } */}

        <Modal isOpen={this.state.cookiesPopup} size={"sm"} id="myCookies">
          <ModalBody>
            <div class="leftCookies">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "images/ico_SnovelOriginals-Normal.png"
                }
              />
            </div>
            <div class="rightCookies">
              <h3>This website uses cookies</h3>
              <p>
                This website uses cookies to improve user experience. By using
                our website you consent to all cookies in accordance with our{" "}
                <a href="/privacy-policy" title="">
                  Cookie Policy.
                </a>
              </p>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                onClick={this.closeCookiesPolicyPopup}
              >
                Alright
              </button>
            </div>
          </ModalBody>
        </Modal>

      </div >
    );
  }
}

function mapStatesToProps(state) {
  return {
    loader: state.loader,
    homeFeaturedProductData:
      state.homeFeaturedProductData.homeFeaturedProducts,
    authData: state.authonticationDetails,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(HomePage));