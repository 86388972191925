const initialState = {
    selectedTrackId: 0,
    selectedProductId: '',
    fromType: '',
    productType: ''
}

export default function selectedTrackIdReducer (state = initialState, action) {
    switch (action.type) {
        case 'SELECTED_TRACK_ID': {
            return { ...state, 
                selectedProductId: action.payload[0],
                selectedTrackId: action.payload[1],
                fromType: 'notification' };
        }
        case 'SELECTED_PRODUCT_DETAILS': {
            return { ...state, 
                selectedProductId: action.payload[0],
                productType: action.payload[1]};
        }
        case 'FAILED_SELECTED_TRACK_ID': {
            return { ...state,
                selectedProductId: action.payload[0],
                selectedTrackId: action.payload[1]
             };
        }
        case 'FAILED_SELECTED_PRODUCT_DETAILS': {
            return { ...state, 
                selectedProductId: action.payload,
                productType: '' };
        }
        default: {
            return { ...state };
        }
    }
}