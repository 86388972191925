export function priceConversion(price) {
    var convertedPrice = price ? price.toString() : "";
    var replacePrice = "";
    var mapObj = {
        '0': "0",
        '1': "१",
        '2': "२",
        '3': "३",
        '4': "४",
        '5': "५",
        '6': "६",
        '7': "७",
        '8': "८",
        '9': "९"
    };
    replacePrice = convertedPrice.replace(/0|1|2|3|4|5|6|7|8|9/gi, function (matched) {
        return mapObj[matched];
    });
    return replacePrice;
}