import axios from 'axios';
import { api_base_url } from '../config';

export function tryFreeProductDetailsDataAction(updatePostType, token) {
    return function (dispatch) {
        var newUpdatedPostType = updatePostType != null ? updatePostType : 'audiobook';
        var apiParams = {
            product_type: newUpdatedPostType,
        }
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': "Token " + token
        };
        dispatch({
            type: "SHOW_LOADER"
        });
        axios.post(api_base_url + '/api/v2/tryfree-products/', apiParams, { headers: headers }).then(response => {
            if (response) {
                dispatch({
                    type: "TRY_FREE_PRODUCTS",
                    payload: response.data.all_product
                });
            } else {
                dispatch({
                    type: "FAILED_TRY_FREE_PRODUCTS",
                    payload: response.data
                });
            }
            dispatch({
                type: "HIDE_LOADER"
            });
        }).catch(err => {
            // console.log('fetched.', err);
            dispatch({
                type: "HIDE_LOADER"
            });
        });
    }
}