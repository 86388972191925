import React, { Component } from "react";
import "./App.css";
import "./style/style.css";
import "./style/media.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Subscribe from "./componant/subscribeSnovel";
import Browse from "./componant/browseSnovel";
import CartProductModal from "../src/componant/product/cart";
import FreeSnovel from "./componant/freeSnovel";
import SignInComponant from "./componant/signInComponant";
import SignUpComponent from "./componant/signUpComponent";
import EmailSignUpConfirmation from "./componant/EmailSignUpConfirmation";
import ShowKidsCategoryProducts from "./componant/ShowKidsCategoryProducts";
import ShowLanguageLabCategoryProducts from "./componant/ShowLanguageLabCategoryProducts";
import { Route, Link, Redirect, withRouter, Switch } from "react-router-dom";
import LoginDetails from "./componant/loginComponanat";
import RegisterComponant from "./componant/registerComponant";
import AboutUs from "./componant/pages/aboutUs";
import Director from "./componant/pages/director";
import TryFreeSnovelProducts from "./componant/pages/tryFreeSnovel";
import Publisher from "./componant/pages/publisher";
import Writer from "./componant/pages/writer";
import AudioBooks from "./componant/pages/audioBook";
import SnovelOriginal from "./componant/pages/snovelOriginal";
import SnovelPodcast from "./componant/pages/podcasts";
import Artist from "./componant/pages/artist";
import PrivacyPolicy from "./componant/pages/privacyPolicy";
import TermsAndCondition from "./componant/pages/termAndCondition";
import Contact from "./componant/pages/contact";
import RedeemGift from "./componant/pages/redeemGift";
import GiftSnovel from "./componant/pages/giftSnovel";
import PurchaseTitle from "./componant/pages/purchaseTitle";
import UserProfile from "./componant/pages/userProfile";
import ProductDetail from "./componant/product/productDetails";
import AudioCourseProductDetail from "./componant/product/audioCourseProductDetails";
import PaymentSuccess from "./componant/product/paymentSuccess";
import PaymentCancel from "./componant/product/paymentCancel";
import PaymentFailure from "./componant/product/paymentFailure";
import ProceedForPayment from "./componant/product/proceedForPayment";
import Checkout from "./componant/product/checkout";
import ConfirmPassword from "./componant/pages/confirmPassword";
import AuditionFormComponent from "./componant/pages/AuditionFormComponent";
import HomePage from "./componant/HomePage";
import KidsHomePage from "./componant/KidsHomePage";
import LanguageLabPage from "./componant/LanguageLabPage";
import SearchResult from "./componant/product/searchResult";
import Faq from "./componant/pages/faq";
import Page404 from "./componant/page404";
import AuthorDetails from "./componant/pages/authorDetails";
import { connect } from "react-redux";
import axios from "axios";
import SimpleCrypto from "simple-crypto-js";
import HlsPlayer from "./componant/player/hlsPlayer";
import { userAuthentication } from "./action/loginAction";
import {
  api_base_url,
  dashboardToken,
  encryptDecryptKey,
  maintanaceData,
  getHoursFormat,
} from "./config";
import { browseCategoryProductDetailsDataAction } from "./action/browseCategoryAction";
import {
  kidsCategoryAction,
  kidsCategoryEbookAction,
} from "./action/kidsHomeAction";
import ScrollUpButton from "react-scroll-up-button";
import LocalizedStrings from "react-localization";
import languages from "./language/language.json";
import { geolocated } from "react-geolocated";
import { getRegisteredDeviceLocation } from "./action/registeredDevice.actions";
import ShowFamilyCategoryProducts from "./componant/ShowFamilyCategoryProducts";
var strings = new LocalizedStrings(languages);
var selectedLanguage = localStorage.getItem("language");
if (selectedLanguage) {
  strings.setLanguage(selectedLanguage);
} else {
  strings.setLanguage("EN");
  localStorage.setItem("language", "EN");
}

console.clear()


class mainComponant extends Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    var selectedLanguage = localStorage.getItem("language");
    if (!selectedLanguage) {
      selectedLanguage = "EN";
    }

    /***** Get User Data *****/
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    var firstName = "";
    var lastName = "";
    var userEmail = "";
    var newProfileImage = "";
    var userCountryCode = "";
    var userStateCode = "";
    var userMobileNumber = "";
    var userInstituteName = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
        firstName = userDetailsObject.firstName || "";
        lastName = userDetailsObject.lastName || "";
        userEmail = userDetailsObject.userEmail || "";
        newProfileImage = userDetailsObject.userProfile || "";
        userCountryCode = userDetailsObject.userCountry || "";
        userStateCode = userDetailsObject.userState || "";
        userMobileNumber = userDetailsObject.userMobile || "";
        userInstituteName = userDetailsObject.instituteName || "";
      }
      // console.log("userDetailsObject--", userDetailsObject)
    }

    this.state = {
      isShowHeaderFooter: false,
      pathName: '',
      isOpen: false,
      isToggle: false,
      searchboxOpen: false,
      searchBoxIsActive: false,
      showDropdownMenu: false,
      menuCategoryList: [],
      audioBooksCat: [],
      snovelOriginalCat: [],
      podcastCat: [],
      selectedLanguage: selectedLanguage || "EN",
      profileOpen: false,
      cartProductModal: false,
      isShowPlayer: false,
      isPausePlayer: false,
      isPlayPlayer: false,
      productTracks: [],
      selectedTrackIndex: 0,
      selectedProductId: "",
      selectedProductDetails: {},
      isPlayFromSection: "purchasedInLibrary",
      cartProductCount: 0,
      token: token || "",
      searchResult: [],
      searchQuery: "",
      initiateTrackDetails: {},
      actualStartTime: 0,
      signUpModal: false,
      searchProductSuggetion: [],
      searchAuthorSuggetion: [],
      typing: false,
      typingTimeout: 0,
      currentTime: 0,
      firstName: firstName || "",
      lastName: lastName || "",
      userEmail: userEmail || "",
      newProfileImage: newProfileImage || "",
      userCountryCode: userCountryCode || "",
      userStateCode: userStateCode || "",
      userInstituteName: userInstituteName || "",
      userMobileNumber: userMobileNumber || "",
      maintanaceDataObject: {},
      isMaintenanceMode: false,
      mySnovelProducts: [],
      isPlaying: false,
      fetchLastPlayed: true,
      showProductTracks: false,
      loadingPopup: false,
      isDeviceRegisteredAPICalled: false,
      isTimeZoneIndian: "",
      isActiveMenu: "home",
      headerMenus: [],
      ebookAuthModel: false,
      eBookDetails: [],
    };
    this.toggle = this.toggle.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.profileOpenWrap = this.profileOpenWrap.bind(this);
    this.cartProductsModalToggle = this.cartProductsModalToggle.bind(this);
    this.onPlayEvent = this.onPlayEvent.bind(this);
    this.onPauseEvent = this.onPauseEvent.bind(this);
    this.onEndedEvent = this.onEndedEvent.bind(this);
    this.onTimeUpdateEvent = this.onTimeUpdateEvent.bind(this);
    this.onDragEvent = this.onDragEvent.bind(this);
    this.searchResult = this.searchResult.bind(this);
    this.signUpEmailModal = this.signUpEmailModal.bind(this);
    this.searchQuerySuggetionFunction =
      this.searchQuerySuggetionFunction.bind(this);
    this.removedSuggetion = this.removedSuggetion.bind(this);
    this.closeCartProdcutModel = this.closeCartProdcutModel.bind(this);
    this.closeBanerPopup = this.closeBanerPopup.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  openBannerPopup() {
    this.setState({
      loadingPopup: false,
    });
  }

  closeBanerPopup() {
    this.setState({
      loadingPopup: false,
    });
  }



  closeCartProdcutModel() {
    this.props.dispatch({ type: "CART_MODEL_SELECTED_FALSE", payload: false });
    this.setState({
      cartProductModal: false,
    });
  }

  cartProductsModalToggle() {
    this.setState({
      cartProductModal: true,
    });
  }

  signUpEmailModal() {
    this.setState({
      signUpModal: !this.state.signUpModal,
    });
  }
  componentWillMount() {
    const { pathname } = this.props.location;
    this.setState({
      pathName: pathname,
    });
    if (pathname === '/registration') {
      this.setState({
        isShowHeaderFooter: false,
      });
    } else {
      this.setState({
        isShowHeaderFooter: true,
      });
    }
  }
  componentWillReceiveProps(nextProps, prevState) {
    const { pathname } = this.props.location;
    this.setState({
      pathName: pathname,
    });
    if (pathname === '/registration') {
      this.setState({
        isShowHeaderFooter: false,
      });
    } else {
      this.setState({
        isShowHeaderFooter: true,
      });
    }
    if (nextProps.isGeolocationEnabled) {
      if (this.state.isDeviceRegisteredAPICalled) {
        var apiParams = {
          name: "",
          type: "web",
          device_id: "",
          registration_id: "",
          token: this.state.token,
          lat: nextProps.coords ? nextProps.coords.latitude.toString() : "00",
          lon: nextProps.coords ? nextProps.coords.longitude.toString() : "00",
          is_indian: this.state.isTimeZoneIndian || "",
        };

        this.props.dispatch(getRegisteredDeviceLocation(apiParams));
      }
    }
    if (nextProps.isIndian !== undefined) {
      this.setState({ isDeviceRegisteredAPICalled: true });
    }

    if (this.state.token == "") {
      var userDetails = localStorage.getItem("userDetails");
      if (userDetails) {
        var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
        userDetails = simpleCrypto.decrypt(userDetails);
        var userDetailsObject = JSON.parse(userDetails);
        if (
          userDetailsObject.loginSuccess == true &&
          userDetailsObject.token != ""
        ) {
          this.setState({
            token: userDetailsObject.token || "",
          });
          this.getLastPlayedProduct(userDetailsObject.token);
        }
      }
    } else this.getLastPlayedProduct(this.state.token);
    this.setState({
      isShowPlayer: this.state.mySnovelProducts.length > 0 ? true : false,
    });
    if (nextProps.modelSelectedFunction.selectedModel === true) {
      this.cartProductsModalToggle();
    }
    if (nextProps.authData.signUpModal === true) {
      this.signUpEmailModal();
    }
    if (
      nextProps.eBookDetails !== prevState.eBookDetails &&
      nextProps.eBookDetails.length > 0
    ) {
      this.setState({
        eBookDetails: nextProps.eBookDetails,
      });
    }

    this.setState({
      cartProductCount: nextProps.productCartCountReducer || 0,
    });
    var newSelectedProduct = nextProps.selectedProductToPlay.selectedProduct;
    var isPauseTrack = nextProps.selectedProductToPlay.isPauseSelectedTrack;
    var isPlayTrack = nextProps.selectedProductToPlay.isPlaySelectedTrack;
    var isPausePlayer = nextProps.selectedProductToPlay.isPauseFromPlayer;
    var isPlayPlayer = nextProps.selectedProductToPlay.isPlayFromPlayer;

    if (newSelectedProduct.id != undefined)
      this.setState({
        selectedProductDetails: newSelectedProduct,
      });
    this.setState({
      isPausePlayer: isPauseTrack,
      isPlayPlayer: isPlayTrack,
    });
    var newSelectedTrackIndex =
      nextProps.selectedProductToPlay.selectedTrackIndex;
    if (!isPauseTrack && !isPlayTrack && !isPausePlayer && !isPlayPlayer) {
      if (Object.keys(newSelectedProduct).length > 0) {
        if (
          this.state.selectedProductId != "" &&
          this.state.selectedProductId !=
          nextProps.selectedProductToPlay.selectedProduct.id
        ) {
          if (
            this.state.currentTime > 0 &&
            Object.keys(this.state.selectedProductDetails).length > 0 &&
            Object.keys(this.state.initiateTrackDetails).length > 0
          ) {
            var anotherTrackIndex = 0;
            if (newSelectedTrackIndex !== this.state.selectedTrackIndex) {
              anotherTrackIndex = newSelectedTrackIndex;
            }
            var selectedProductId = 0;
            if (
              this.state.selectedProductId !==
              nextProps.selectedProductToPlay.selectedProduct.id
            ) {
              selectedProductId =
                nextProps.selectedProductToPlay.selectedProduct.id;
            }

            var newApiParams = {
              request_type: "pause",
              product_id: selectedProductId,
              track_id: anotherTrackIndex,
              map_key: this.state.initiateTrackDetails.map_key,
              map_id: this.state.initiateTrackDetails.map_id,
              pause_seconds: parseInt(this.state.currentTime) + "",
            };
            var newHeaders = {
              "Content-Type": "application/json",
              Authorization: "Token " + this.state.token,
            };
            axios
              .post(api_base_url + "/api/player/", newApiParams, {
                headers: newHeaders,
              })
              .then((newResponse) => { })
              .catch((err) => {
                // console.log("Error - ", err);
              });
          }
          this.setState({
            selectedProductId: selectedProductId,
            productTracks: [],
            isShowPlayer: true,
            selectedTrackIndex: 0,
            isPlayFromSection:
              nextProps.selectedProductToPlay.isPlayFromSection,
          });
          setTimeout(
            function () {
              var apiParams = {
                request_type: "initiate",
                product_id: nextProps.selectedProductToPlay.selectedProduct.id,
                track_id:
                  nextProps.selectedProductToPlay.selectedProduct
                    .producttracks[0].id,
              };
              var headers = {
                "Content-Type": "application/json",
                Authorization: "Token " + this.state.token,
              };
              axios
                .post(api_base_url + "/api/player/", apiParams, {
                  headers: headers,
                })
                .then((response) => {
                  var newInitiateTrackDetails = {};
                  var trackStartTime = 0;
                  if (response.data.status) {
                    newInitiateTrackDetails = response.data.data;
                    var newTrackStartTime =
                      newInitiateTrackDetails.actual_start_time;
                    if (newTrackStartTime != null) {
                      trackStartTime = newTrackStartTime;
                    }
                  }
                  this.setState({
                    initiateTrackDetails: newInitiateTrackDetails,
                    actualStartTime: trackStartTime,
                    currentTime: 0,
                    productTracks:
                      nextProps.selectedProductToPlay.selectedProduct
                        .producttracks,
                  });
                })
                .catch((err) => {
                  // console.log("Error - ", err);
                  this.setState({
                    initiateTrackDetails: {},
                    actualStartTime: 0,
                    currentTime: 0,
                    productTracks:
                      nextProps.selectedProductToPlay.selectedProduct
                        .producttracks,
                  });
                });
            }.bind(this),
            100
          );
        } else {
          if (
            this.state.currentTime > 0 &&
            Object.keys(this.state.selectedProductDetails).length > 0 &&
            Object.keys(this.state.initiateTrackDetails).length > 0 &&
            this.state.selectedProductDetails.producttracks != undefined
          ) {
            var newApiParams = {
              request_type: "pause",
              product_id: this.state.selectedProductDetails.id,
              track_id:
                this.state.selectedProductDetails.producttracks[
                  this.state.selectedTrackIndex
                ].id,
              map_key: this.state.initiateTrackDetails.map_key,
              map_id: this.state.initiateTrackDetails.map_id,
              pause_seconds: parseInt(this.state.currentTime) + "",
            };
            var newHeaders = {
              "Content-Type": "application/json",
              Authorization: "Token " + this.state.token,
            };
            axios
              .post(api_base_url + "/api/player/", newApiParams, {
                headers: newHeaders,
              })
              .then((newResponse) => { })
              .catch((err) => {
                // console.log("Error - ", err);
              });
          }
          var anotherTrackIndex = 0;
          if (newSelectedTrackIndex !== this.state.selectedTrackIndex) {
            anotherTrackIndex = newSelectedTrackIndex;
          }
          var selectedProductId = 0;
          if (
            this.state.selectedProductId !==
            nextProps.selectedProductToPlay.selectedProduct.id
          ) {
            selectedProductId =
              nextProps.selectedProductToPlay.selectedProduct.id;
          }
          var apiParams = {
            request_type: "initiate",
            product_id: selectedProductId,
            track_id:
              nextProps.selectedProductToPlay.selectedProduct.producttracks[
                anotherTrackIndex
              ].id,
          };
          var headers = {
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          };
          axios
            .post(api_base_url + "/api/player/", apiParams, {
              headers: headers,
            })
            .then((response) => {
              var newInitiateTrackDetails = {};
              var trackStartTime = 0;
              if (response.data.status) {
                newInitiateTrackDetails = response.data.data;
                var newTrackStartTime =
                  newInitiateTrackDetails.actual_start_time;
                if (newTrackStartTime != null) {
                  trackStartTime = newTrackStartTime;
                }
              }
              this.setState({
                initiateTrackDetails: newInitiateTrackDetails,
                actualStartTime: trackStartTime,
                currentTime: 0,
                selectedProductId:
                  nextProps.selectedProductToPlay.selectedProduct.id,
                productTracks:
                  nextProps.selectedProductToPlay.selectedProduct.producttracks,
                isPlayFromSection:
                  nextProps.selectedProductToPlay.isPlayFromSection,
                isShowPlayer: true,
                selectedTrackIndex: anotherTrackIndex,
              });
            })
            .catch((err) => {
              // console.log("Error - ", err);
              this.setState({
                initiateTrackDetails: {},
                actualStartTime: 0,
                currentTime: 0,
                selectedProductId:
                  nextProps.selectedProductToPlay.selectedProduct.id,
                isPlayFromSection:
                  nextProps.selectedProductToPlay.isPlayFromSection,
                productTracks:
                  nextProps.selectedProductToPlay.selectedProduct.producttracks,
                isShowPlayer: true,
                selectedTrackIndex: anotherTrackIndex,
              });
            });
        }
      } else {
        if (
          this.state.currentTime > 0 &&
          Object.keys(this.state.selectedProductDetails).length > 0 &&
          Object.keys(this.state.initiateTrackDetails).length > 0
        ) {
          var newApiParams = {
            request_type: "pause",
            product_id: this.state.selectedProductDetails.id,
            track_id:
              this.state.selectedProductDetails.producttracks[
                this.state.selectedTrackIndex
              ].id,
            map_key: this.state.initiateTrackDetails.map_key,
            map_id: this.state.initiateTrackDetails.map_id,
            pause_seconds: parseInt(this.state.currentTime) + "",
          };
          var newHeaders = {
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          };
          axios
            .post(api_base_url + "/api/player/", newApiParams, {
              headers: newHeaders,
            })
            .then((newResponse) => { })
            .catch((err) => {
              // console.log("Error - ", err);
            });
        }
        this.setState({
          productTracks: [],
          isShowPlayer: false,
          selectedTrackIndex: 0,
          selectedProductId: "",
          isPlayFromSection: "purchasedInLibrary",
        });
      }
    }
  }

  getLastPlayedProduct(token) {
    if (this.state.fetchLastPlayed) {
      this.setState({ fetchLastPlayed: false });
    } else return;

    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/my-snovel/", { headers: headers })
      .then((response) => {
        if (response.data.status && this.props.authData.loginSuccess) {
          const lastPlayedProduct = response.data.last_browse || [];
          const productTracks = lastPlayedProduct[0].producttracks;
          const lastPlayedTrackId =
            response.data.latest_track || [].length > 0
              ? response.data.latest_track[0].id
              : 0;
          let selectedTrackIndex = 0;
          if (productTracks.length > 0 && lastPlayedTrackId > 0)
            productTracks.forEach((item, index) => {
              if (item.id == lastPlayedTrackId) {
                selectedTrackIndex = index;
              }
            });
          this.setState({
            mySnovelProducts: lastPlayedProduct,
            isShowPlayer: lastPlayedProduct.length > 0 ? true : false,
            selectedProductDetails: lastPlayedProduct[0],
            productTracks,
            selectedTrackIndex,
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          isLoading: false,
        });
      });
  }

  profileOpenWrap() {
    this.setState({
      profileOpen: !this.state.profileOpen,
    });
  }
  handleClick(e) {
    this.setState({ isToggle: !this.state.isToggle });
  }



  handleLogOut(e) {
    this.props.dispatch({ type: "USER_LOGGED_OUT", payload: null });
    this.props.dispatch({
      type: "PLAY_SELECTED_PRODUCT",
      payload: [
        {},
        this.state.selectedTrackIndex,
        false,
        false,
        false,
        false,
        false,
        "purchasedInLibrary",
      ],
    });
    this.setState({
      signUpModal: false,
      token: "",
      fetchLastPlayed: true,
      mySnovelProducts: [],
    });
    localStorage.removeItem("currentcountrycode")
  }

  onSelectedLanguage(selectedLanguage) {
    this.setState({
      selectedLanguage: selectedLanguage,
    });
    strings.setLanguage(selectedLanguage);
    this.handleClick();
    window.localStorage.setItem("language", selectedLanguage);
    this.props.dispatch({ type: "CHNAGE_LANGUAGE", payload: selectedLanguage });
  }

  searchBoxMax() {
    this.setState({
      searchboxOpen: true,
      searchBoxIsActive: true,
    });
  }
  removeSearchBox() {
    this.setState({
      searchboxOpen: false,
      searchBoxIsActive: false,
      searchProductSuggetion: [],
      searchAuthorSuggetion: [],
    });
    this.myFormRef.reset();
    this.myFormRefMob.reset();
  }
  removedSuggetion() {
    this.setState({
      searchProductSuggetion: [],
      searchAuthorSuggetion: [],
    });
    this.myFormRef.reset();
    this.myFormRefMob.reset();
  }
  showDropdownMenu() {
    this.setState({
      showDropdownMenu: !this.state.showDropdownMenu,
    });
  }
  closeDropdownMenu() {
    this.setState({
      showDropdownMenu: false,
    });
  }

  componentDidMount() {
    var currentTimeZone = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.toLowerCase();
    var isTimeZoneIndian = "";
    if (
      currentTimeZone.includes("calcutta") ||
      currentTimeZone.includes("kolkata")
    ) {
      isTimeZoneIndian = "IST";
    } else if (
      currentTimeZone != "" &&
      !currentTimeZone.includes("calcutta") &&
      !currentTimeZone.includes("kolkata")
    ) {
      isTimeZoneIndian = "Other";
    }
    this.setState({ isTimeZoneIndian });
    this.getHeaderMenuData();
    this.homeProductDetailsData();
    //this.userSubscribeedAfterLogin();
    if (this.props.authData.loginSuccess) {
      this.cartProductsCount();
    }
    this.maintanaceData();
    this.openBannerPopup();
    // this.props.dispatch(kidsCategoryEbookAction(this.state.token));
  }


  componentWillUnmount() {
    this.userSubscribeedAfterLogin();
  }



  maintanaceData() {
    axios
      .get(api_base_url + "/media/maintenance/maintenance.json")
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            maintanaceDataObject: response.data,
            isMaintenanceMode: response.data.is_maintenance || false,
            isMaintenanceMode: false,
            
          });
        } else {
          this.setState({
            maintanaceDataObject: {},
            isMaintenanceMode: false,
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
        this.setState({
          maintanaceDataObject: {},
          isMaintenanceMode: false,
        });
      });
  }


  userSubscribeedAfterLogin() {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .get(api_base_url + "/api/user-subscribed/", { headers: headers })
      .then((response) => {
        if (response.data.status === true) {
          this.props.dispatch(
            userAuthentication(
              this.state.userEmail,
              this.state.token,
              response.data.is_subsribed,
              this.state.firstName,
              this.state.lastName,
              this.state.newProfileImage,
              this.state.userCountryCode,
              this.state.userStateCode,
              this.state.userMobileNumber,
              this.state.userInstituteName,
            )
          );
        }
      })
      .catch((err) => {
        // console.log("error ", err);
      });
  }

  getHeaderMenuData() {
    var headers = {
      "Content-Type": "application/json",
      "index-api-token": dashboardToken,
    };
    axios
      .get(api_base_url + "/api/v2/menu/", { headers: headers })
      .then((response) => {
        if (response && response.data && response.data.data) {
          this.setState({
            headerMenus: response.data.data || [],
          });
        } else {
          this.setState({
            headerMenus: [],
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        this.setState({
          headerMenus: [],
        });
      });
  }

  homeProductDetailsData() {
    var headers = {
      "Content-Type": "application/json",
      "index-api-token": dashboardToken,
    };
    axios
      .get(api_base_url + "/api/v2/index-product-type/", { headers: headers })
      .then((response) => {
        if (response) {
          var categoryCountObject = {
            audioBooksCount: 0,
            snovelOriginalsCount: 0,
            podcastCount: 0,
          };
          response.data.product_type.map((postTypeCat) => {
            if (postTypeCat.product_type_value === "audiobook") {
              postTypeCat.product_type_categories["product_type_categories"] =
                postTypeCat.product_type_value;
              this.setState({
                audioBooksCat: postTypeCat.product_type_categories,
              });
              categoryCountObject.audioBooksCount =
                postTypeCat.product_type_count;
            } else if (postTypeCat.product_type_value === "snoveloriginal") {
              this.setState({
                snovelOriginalCat: postTypeCat.product_type_categories,
              });
              categoryCountObject.snovelOriginalsCount =
                postTypeCat.product_type_count;
            } else if (postTypeCat.product_type_value === "podcast") {
              this.setState({
                podcastCat: postTypeCat.product_type_categories,
              });
              categoryCountObject.podcastCount = postTypeCat.product_type_count;
            } else {
              // console.log("Category Not found");
            }
          });
          this.setState({
            menuCategoryList: response.data.product_type,
          });
          this.props.dispatch({
            type: "CHANGE_CATEGORY_COUNT",
            payload: categoryCountObject,
          });
        } else {
          // console.log("Not Loaded");
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }

  cartProductsCount() {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .get(api_base_url + "/api/cart/", { headers: headers })
      .then((response) => {
        if (response.data.status === true) {
          this.props.dispatch({
            type: "PRODUCT_CART_COUNT",
            payload: response.data.data,
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }

  audioBooksCategory() {
    return this.state.audioBooksCat.map((abItem, index) => {
      return (
        <li className="category_section">
          <DropdownItem>
            <NavLink
              tag={Link}
              onClick={() => {
                this.props.history.push("/browse");
                this.props.dispatch({
                  type: "MENU_SELECTED_CATEGORY",
                  payload: [
                    abItem.custom_translation[this.state.selectedLanguage] !==
                      undefined
                      ? abItem.custom_translation[this.state.selectedLanguage]
                        .name
                      : abItem.name,
                    this.state.audioBooksCat.product_type_categories,
                    abItem.id,
                  ],
                });
                this.props.dispatch(
                  browseCategoryProductDetailsDataAction(
                    this.state.audioBooksCat.product_type_categories,
                    abItem.name,
                    1
                  )
                );
              }}
            >
              {abItem.custom_translation[this.state.selectedLanguage] !==
                undefined &&
                abItem.custom_translation[this.state.selectedLanguage].name}
            </NavLink>
          </DropdownItem>
        </li>
      );
    });
  }

  snovelOriginalCategory() {
    return this.state.snovelOriginalCat.map((abItem, index) => {
      return (
        <li className="category_section">
          <DropdownItem>
            <NavLink
              tag={Link}
              onClick={() => {
                this.props.history.push("/browse");
                this.props.dispatch({
                  type: "MENU_SELECTED_CATEGORY",
                  payload: [
                    abItem.custom_translation[this.state.selectedLanguage] !==
                      undefined
                      ? abItem.custom_translation[this.state.selectedLanguage]
                        .name
                      : abItem.name,
                    this.state.audioBooksCat.product_type_categories,
                    abItem.id,
                  ],
                });
                this.props.dispatch(
                  browseCategoryProductDetailsDataAction(
                    this.state.audioBooksCat.product_type_categories,
                    abItem.name,
                    1
                  )
                );
              }}
            >
              {abItem.custom_translation[this.state.selectedLanguage] !==
                undefined &&
                abItem.custom_translation[this.state.selectedLanguage].name}
            </NavLink>
          </DropdownItem>
        </li>
      );
    });
  }
  podcastCategory() {
    return this.state.podcastCat.map((abItem, index) => {
      return (
        <li className="category_section">
          <DropdownItem>
            <NavLink
              tag={Link}
              onClick={() => {
                this.props.history.push("/browse");
                this.props.dispatch({
                  type: "MENU_SELECTED_CATEGORY",
                  payload: [
                    abItem.custom_translation[this.state.selectedLanguage] !==
                      undefined
                      ? abItem.custom_translation[this.state.selectedLanguage]
                        .name
                      : abItem.name,
                    this.state.audioBooksCat.product_type_categories,
                    abItem.id,
                  ],
                });
                this.props.dispatch(
                  browseCategoryProductDetailsDataAction(
                    this.state.audioBooksCat.product_type_categories,
                    abItem.name,
                    1
                  )
                );
              }}
            >
              {abItem.custom_translation[this.state.selectedLanguage] !==
                undefined &&
                abItem.custom_translation[this.state.selectedLanguage].name}
            </NavLink>
          </DropdownItem>
        </li>
      );
    });
  }

  searchResult(event) {
    clearTimeout(this.timeout);
    this.setState({
      searchAuthorSuggetion: [],
      searchProductSuggetion: [],
    });
    this.props.history.push({ pathname: "/search/" + this.state.searchQuery });
    event.preventDefault();
  }

  playProductTrack(trackIndex) {
    if (this.state.selectedTrackIndex !== trackIndex) {
      this.props.dispatch({
        type: "PLAY_SELECTED_PRODUCT",
        payload: [
          this.state.selectedProductDetails,
          trackIndex,
          false,
          false,
          false,
          false,
          "purchasedInLibrary",
        ],
      });
      this.setState({
        selectedTrackIndex: trackIndex,
        isPlaying: true,
      });
    } else {
      if (!this.state.isPlaying) {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.selectedProductDetails,
            trackIndex,
            false,
            true,
            false,
            false,
            "purchasedInLibrary",
          ],
        });
        this.setState({
          isPlaying: true,
        });
      } else {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.selectedProductDetails,
            trackIndex,
            true,
            false,
            false,
            false,
            "purchasedInLibrary",
          ],
        });
        this.setState({
          isPlaying: false,
        });
      }
    }
  }

  onFastForward = () => {
    this.playerRef.current.onFastForward();
    this.onDragEvent(this.state.currentTime, this.state.currentTime + 10);
  };

  onRewind = () => {
    this.playerRef.current.onRewind();
    this.onDragEvent(this.state.currentTime, this.state.currentTime - 10);
  };

  onPlayEvent(seconds) {
    this.setState({ isPlaying: true });
    this.props.dispatch({
      type: "PLAY_SELECTED_PRODUCT",
      payload: [
        this.state.selectedProductDetails,
        this.state.selectedTrackIndex,
        false,
        false,
        false,
        true,
        this.state.isPlayFromSection,
      ],
    });
    var newApiParams = {
      request_type: "initiate",
      product_id: this.state.selectedProductDetails.id,
      track_id:
        this.state.selectedProductDetails.producttracks[
          this.state.selectedTrackIndex
        ].id,
    };
    var newHeaders = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/player/", newApiParams, {
        headers: newHeaders,
      })
      .then((anotherResponse) => {
        var newInitiateTrackDetails = {};
        var trackStartTime = 0;
        if (anotherResponse.data.status) {
          newInitiateTrackDetails = anotherResponse.data.data;
          var newTrackStartTime = newInitiateTrackDetails.actual_start_time;
          if (newTrackStartTime != null) {
            trackStartTime = newTrackStartTime;
          }
        }
        this.setState({
          initiateTrackDetails: newInitiateTrackDetails,
          actualStartTime: trackStartTime,
          currentTime: 0,
        });
      })
      .catch((err) => {
        // console.log("Error - ", err);
        this.setState({
          initiateTrackDetails: {},
          actualStartTime: 0,
          currentTime: 0,
        });
      });
  }

  onPauseEvent(seconds) {
    this.setState({ isPlaying: false });
    this.props.dispatch({
      type: "PLAY_SELECTED_PRODUCT",
      payload: [
        this.state.selectedProductDetails,
        this.state.selectedTrackIndex,
        false,
        false,
        true,
        false,
        this.state.isPlayFromSection,
      ],
    });
    var apiParams = {
      request_type: "pause",
      product_id: this.state.selectedProductDetails.id,
      track_id:
        this.state.selectedProductDetails.producttracks[
          this.state.selectedTrackIndex
        ].id,
      map_key: this.state.initiateTrackDetails.map_key,
      map_id: this.state.initiateTrackDetails.map_id,
      pause_seconds: parseInt(seconds) + "",
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/player/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          this.setState({
            initiateTrackDetails:
              response.data.data || this.state.initiateTrackDetails,
          });
        }
      })
      .catch((err) => {
        // console.log("Error - ", err);
      });
  }

  onEndedEvent(seconds) {
    var newIndex = this.state.selectedTrackIndex + 1;
    var apiParams = {
      request_type: "end",
      product_id: this.state.selectedProductDetails.id,
      track_id:
        this.state.selectedProductDetails.producttracks[
          this.state.selectedTrackIndex
        ].id,
      map_key: this.state.initiateTrackDetails.map_key,
      map_id: this.state.initiateTrackDetails.map_id,
      pause_seconds: parseInt(seconds) + "",
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/player/", apiParams, { headers: headers })
      .then((response) => {
        if (newIndex < this.state.productTracks.length) {
          var newApiParams = {
            request_type: "initiate",
            product_id: this.state.selectedProductDetails.id,
            track_id:
              this.state.selectedProductDetails.producttracks[newIndex].id,
          };
          var newHeaders = {
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          };
          axios
            .post(api_base_url + "/api/player/", newApiParams, {
              headers: newHeaders,
            })
            .then((anotherResponse) => {
              var newInitiateTrackDetails = {};
              var trackStartTime = 0;
              if (anotherResponse.data.status) {
                newInitiateTrackDetails = anotherResponse.data.data;
                var newTrackStartTime =
                  newInitiateTrackDetails.actual_start_time;
                if (newTrackStartTime != null) {
                  trackStartTime = newTrackStartTime;
                }
              }
              this.setState({
                selectedTrackIndex: newIndex,
                initiateTrackDetails: newInitiateTrackDetails,
                actualStartTime: trackStartTime,
                currentTime: 0,
              });
            })
            .catch((err) => {
              // console.log("Error - ", err);
              this.setState({
                selectedTrackIndex: newIndex,
                initiateTrackDetails: {},
                actualStartTime: 0,
                currentTime: 0,
              });
            });
        }
      })
      .catch((err) => {
        // console.log("Error - ", err);
        if (newIndex < this.state.productTracks.length) {
          var newApiParams = {
            request_type: "initiate",
            product_id: this.state.selectedProductDetails.id,
            track_id:
              this.state.selectedProductDetails.producttracks[newIndex].id,
          };
          var newHeaders = {
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          };
          axios
            .post(api_base_url + "/api/player/", newApiParams, {
              headers: newHeaders,
            })
            .then((anotherResponse) => {
              var newInitiateTrackDetails = {};
              var trackStartTime = 0;
              if (anotherResponse.data.status) {
                newInitiateTrackDetails = anotherResponse.data.data;
                var newTrackStartTime =
                  newInitiateTrackDetails.actual_start_time;
                if (newTrackStartTime != null) {
                  trackStartTime = newTrackStartTime;
                }
              }
              this.setState({
                selectedTrackIndex: newIndex,
                initiateTrackDetails: newInitiateTrackDetails,
                actualStartTime: trackStartTime,
                currentTime: 0,
              });
            })
            .catch((err) => {
              // console.log("Error - ", err);
              this.setState({
                selectedTrackIndex: newIndex,
                initiateTrackDetails: {},
                actualStartTime: 0,
                currentTime: 0,
              });
            });
        }
      });
  }

  onTimeUpdateEvent(seconds) {
    this.setState({
      currentTime: parseInt(seconds),
    });
  }

  onDragEvent(fromSeconds, toSeconds) {
    var apiParams = {
      request_type: "drag",
      product_id: this.state.selectedProductDetails.id,
      track_id:
        this.state.selectedProductDetails.producttracks[
          this.state.selectedTrackIndex
        ].id,
      map_key: this.state.initiateTrackDetails.map_key,
      map_id: this.state.initiateTrackDetails.map_id,
      pause_seconds: fromSeconds,
      start_seconds: toSeconds,
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/player/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          this.setState({
            initiateTrackDetails:
              response.data.data || this.state.initiateTrackDetails,
          });
        }
      })
      .catch((err) => {
        // console.log("Error - ", err);
      });
  }

  onPreviousTrack() {
    var newIndex = this.state.newSelectedTrackIndex - 1;
    alert(newIndex)
    if (newIndex >= 0) {
      if (
        this.state.currentTime > 0 &&
        Object.keys(this.state.selectedProductDetails).length > 0 &&
        Object.keys(this.state.initiateTrackDetails).length > 0
      ) {
        var newApiParams = {
          request_type: "pause",
          product_id: this.state.selectedProductDetails.id,
          track_id:
            this.state.selectedProductDetails.producttracks[
              this.state.selectedTrackIndex
            ].id,
          map_key: this.state.initiateTrackDetails.map_key,
          map_id: this.state.initiateTrackDetails.map_id,
          pause_seconds: parseInt(this.state.currentTime) + "",
        };
        var newHeaders = {
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        };
        axios
          .post(api_base_url + "/api/player/", newApiParams, {
            headers: newHeaders,
          })
          .then((newResponse) => { })
          .catch((err) => {
            // console.log("Error - ", err);
          });
      }
      var apiParams = {
        request_type: "initiate",
        product_id: this.state.selectedProductDetails.id,
        track_id: this.state.selectedProductDetails.producttracks[newIndex].id,
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .post(api_base_url + "/api/player/", apiParams, { headers: headers })
        .then((response) => {
          var newInitiateTrackDetails = {};
          var trackStartTime = 0;
          if (response.data.status) {
            newInitiateTrackDetails = response.data.data;
            var newTrackStartTime = newInitiateTrackDetails.actual_start_time;
            if (newTrackStartTime != null) {
              trackStartTime = newTrackStartTime;
            }
          }
          this.setState({
            initiateTrackDetails: newInitiateTrackDetails,
            actualStartTime: trackStartTime,
            currentTime: 0,
            selectedTrackIndex: newIndex,
          });
        })
        .catch((err) => {
          // console.log("Error - ", err);
          this.setState({
            initiateTrackDetails: {},
            actualStartTime: 0,
            currentTime: 0,
            selectedTrackIndex: newIndex,
          });
        });
    }
  }
  onNextTrack() {
    var newIndex = this.state.selectedTrackIndex + 1;
    if (newIndex < this.state.productTracks.length) {
      if (
        this.state.currentTime > 0 &&
        Object.keys(this.state.selectedProductDetails).length > 0 &&
        Object.keys(this.state.initiateTrackDetails).length > 0
      ) {
        var newApiParams = {
          request_type: "pause",
          product_id: this.state.selectedProductDetails.id,
          track_id:
            this.state.selectedProductDetails.producttracks[
              this.state.selectedTrackIndex
            ].id,
          map_key: this.state.initiateTrackDetails.map_key,
          map_id: this.state.initiateTrackDetails.map_id,
          pause_seconds: parseInt(this.state.currentTime) + "",
        };
        var newHeaders = {
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        };
        axios
          .post(api_base_url + "/api/player/", newApiParams, {
            headers: newHeaders,
          })
          .then((newResponse) => { })
          .catch((err) => {
            // console.log("Error - ", err);
          });
      }
      var apiParams = {
        request_type: "initiate",
        product_id: this.state.selectedProductDetails.id,
        track_id: this.state.selectedProductDetails.producttracks[newIndex].id,
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .post(api_base_url + "/api/player/", apiParams, { headers: headers })
        .then((response) => {
          var newInitiateTrackDetails = {};
          var trackStartTime = 0;
          if (response.data.status) {
            newInitiateTrackDetails = response.data.data;
            var newTrackStartTime = newInitiateTrackDetails.actual_start_time;
            if (newTrackStartTime != null) {
              trackStartTime = newTrackStartTime;
            }
          }
          this.setState({
            initiateTrackDetails: newInitiateTrackDetails,
            actualStartTime: trackStartTime,
            currentTime: 0,
            selectedTrackIndex: newIndex,
          });
        })
        .catch((err) => {
          // console.log("Error - ", err);
          this.setState({
            initiateTrackDetails: {},
            actualStartTime: 0,
            currentTime: 0,
            selectedTrackIndex: newIndex,
          });
        });
    }
  }

  getSearchSuggetion(value) {
    var productName = "";
    axios
      .get(api_base_url + "/api/search-suggestion/?q=" + value)
      .then((response) => {
        this.setState({
          searchProductSuggetion: response.data.suggested_products || [],
          searchAuthorSuggetion: response.data.suggested_authors || [],
        });
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }

  searchQuerySuggetionFunction(event) {
    clearTimeout(this.timeout);
    this.setState({
      searchQuery: event.target.value,
    });

    if (this.state.searchQuery.length > 0) {
      this.timeout = setTimeout(() => {
        this.getSearchSuggetion(this.state.searchQuery);
      }, 2000);
    } else {
      this.setState({
        searchAuthorSuggetion: [],
        searchProductSuggetion: [],
      });
    }
  }
  productDetailsId(productID, title) {
    var titleUrl = title.toLowerCase();
    var productTitle = titleUrl.replace(" ", "-");
    localStorage.setItem("productId", productID);
    this.props.history.push(
      "/product-details/" + productID + "/" + productTitle
    );
  }

  authorDetailsId(authorID, authorName) {
    var titleUrl = authorName.toLowerCase();
    var authorTitle = titleUrl.replace(".", "");
    var authorName = authorTitle.replace(" ", "-");
    this.props.history.push("/author/" + authorID + "/" + authorName);
  }
  handleActiveMenu(activeMenu) {
    this.setState({
      isActiveMenu: activeMenu,
    });
  }
  handleEbookModal() {
    this.setState({
      ebookAuthModel: true,
    });
  }
  handleSigninModal() {
    this.setState({
      ebookAuthModel: false,
    });
    this.props.history.push("/sign-in");
  }
  render() {
    var searchClassName = this.state.searchboxOpen ? "searchbox-open" : "";
    var closeSearchBoxDiv = this.state.closeSearchBox ? "searchbox-open" : "";
    var searchBoxIsActive = this.state.searchBoxIsActive
      ? "searchBoxIsOpen"
      : "";
    return (
      <div>
        {!this.state.isMaintenanceMode && (
          <div
            className="App"
            ref={(node) => {
              this.node = node;
            }}
          >
            <header>
              <nav className="navbar bootsnav">
                <div className="container">
                  {this.state.isShowHeaderFooter ?
                    <NavbarToggler onClick={this.toggle} />
                    :
                    null
                  }
                  <NavbarBrand className={!this.state.isShowHeaderFooter ? "new-sign-up-navbar-brand" : null} tag={Link} to="/">
                    <img
                      className="logo"
                      src={process.env.PUBLIC_URL + "/images/logo-snovel.png"}
                      alt=""
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_UNSELECTED_CATEGORY",
                          payload: [false, null],
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(null, null, 2)
                        );
                        this.handleActiveMenu("home");
                      }}
                    />
                  </NavbarBrand>
                  {this.state.isShowHeaderFooter ?
                    <div className="headerRightCont">
                      <Navbar className="" expand="lg">
                        {this.state.headerMenus.length > 0 ?
                          <Collapse
                            isOpen={this.state.isOpen}
                            navbar
                            className="desktopMenu"
                          >
                            <Nav navbar>
                              {this.state.headerMenus.map((eachMenu, menuIndex) => {
                                return (
                                  <NavItem
                                    className={
                                      this.state.pathName === (eachMenu.slug === "home" ? "/" : "/" + eachMenu.slug)
                                        ? "active" + (eachMenu.slug === "family" ? " browseDropdown" : "")
                                        : (eachMenu.slug === "family" ? " browseDropdown" : null)
                                    }
                                  >
                                    <NavLink className={eachMenu.slug === "subscribe" ? "subscribe-nav-link" : ""} tag={Link} to={eachMenu.slug === "home" ? "/" : "/" + eachMenu.slug+"/"}>
                                      {
                                        eachMenu.custom_translation[this.state.selectedLanguage] ?
                                          eachMenu.custom_translation[this.state.selectedLanguage].name
                                          :
                                          eachMenu.custom_translation["EN"].name
                                      }
                                    </NavLink>
                                    {/* The above code is rendering a dropdown menu button with
                                    three categories (audiobook, snoveloriginal, and podcast) if
                                    the value of the `eachMenu.slug` is "family". The dropdown
                                    menu is toggled by clicking on the button and displays the
                                    categories in three columns. If the value of `eachMenu.slug`
                                    is not "family", then nothing is rendered. */}
                                    {/* 
                                    {eachMenu.slug === "family" ?
                                      <ButtonDropdown
                                        isOpen={this.state.showDropdownMenu}
                                        toggle={this.showDropdownMenu}
                                        className="browseMenuWrapper"
                                      >
                                        <DropdownToggle caret>
                                          <i class="fas fa-sort-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <i
                                            class="fa fa-close closeBrowseMenu"
                                            onClick={() => {
                                              this.closeDropdownMenu();
                                            }}
                                          ></i>
                                          <div className="container">
                                            <Row>
                                              <Col md="12" lg="4">
                                                <div className="browseDropDownTitle">
                                                  <img
                                                    src="images/Audiobook_Normal.svg"
                                                    alt=""
                                                  />{" "}
                                                  <span>{strings.audiobook}</span>
                                                </div>
                                                <div className="menuCategory_Wrapper">
                                                  <ul>{this.audioBooksCategory()}</ul>
                                                </div>
                                              </Col>
                                              <Col md="12" lg="4">
                                                <div className="browseDropDownTitle">
                                                  <img
                                                    src="images/ico_SnovelOriginals-Normal.png"
                                                    alt=""
                                                  />{" "}
                                                  <span>{strings.snoveloriginal}</span>
                                                </div>
                                                <div className="menuCategory_Wrapper">
                                                  <ul>{this.snovelOriginalCategory()}</ul>
                                                </div>
                                              </Col>
                                              <Col md="12" lg="4">
                                                <div className="browseDropDownTitle">
                                                  <img
                                                    src="images/ico_Podcast-Normal.png"
                                                    alt=""
                                                  />{" "}
                                                  <span>{strings.podcast}</span>
                                                </div>
                                                <div className="menuCategory_Wrapper">
                                                  <ul>{this.podcastCategory()}</ul>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </DropdownMenu>
                                      </ButtonDropdown>
                                      :
                                      null
                                    } */}
                                  </NavItem>
                                )
                              })}
                            </Nav>
                          </Collapse>
                          :
                          null
                        }
                        <div className="topLanguage">
                          {this.state.cartProductCount > 0 &&
                            this.props.authData.loginSuccess && (
                              <div
                                class="libraryBskDiv"
                                onClick={() => {
                                  this.cartProductsModalToggle();
                                }}
                              >
                                <img
                                  src="/images/library_basket_icon.png"
                                  alt=""
                                />
                                <p>
                                  <span>{this.state.cartProductCount}</span>

                                </p>
                              </div>
                            )}
                          <div className="languageSelection">
                            <ButtonDropdown
                              isOpen={this.state.isToggle}
                              toggle={this.handleClick}
                              className="languageDropdown"
                            >
                              <DropdownToggle caret>
                                <span className="">
                                  {this.state.selectedLanguage == "EN" ? (
                                    <span>
                                      English <i class="fas fa-sort-down"></i>{" "}
                                    </span>
                                  ) : (
                                    <span>
                                      मराठी <i class="fas fa-sort-down"></i>
                                    </span>
                                  )}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                <Nav>
                                  <NavItem>
                                    <NavLink
                                      onClick={() => {
                                        this.onSelectedLanguage("EN");
                                      }}
                                    >
                                      English
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      onClick={() => {
                                        this.onSelectedLanguage("MR");
                                      }}
                                    >
                                      मराठी
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </div>
                        </div>
                      </Navbar>
                      <div className="btmHeader">
                        <div className="search-container">
                          <form
                            onSubmit={this.searchResult}
                            ref={(el) => (this.myFormRef = el)}
                            autocomplete="off"
                          >
                            <input
                              type="text"
                              placeholder={strings.searchText}
                              autocomplete="false"
                              autocomplete="off"
                              name="search"
                              className="searchToggle"
                              maxLength="50"
                              onChange={this.searchQuerySuggetionFunction}
                              onClick={() => {
                                this.searchBoxMax();
                              }}
                            />
                            <div className="searchButton_Wrapper">
                              {this.state.searchboxOpen && (
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/cross_icon.png"
                                  }
                                  onClick={() => {
                                    this.removeSearchBox();
                                  }}
                                />
                              )}
                              <button type="submit">
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </form>
                          {this.state.searchProductSuggetion.length > 0 ||
                            this.state.searchAuthorSuggetion.length > 0 ? (
                            <div
                              className="searchSuggetion_Wrapper"
                              onClick={this.removedSuggetion}
                            >
                              <div className="searchAuthor">
                                <h3>{strings.authorText}</h3>
                                {this.state.searchAuthorSuggetion.map(
                                  (author, index) => {
                                    return (
                                      <span>
                                        {author.name !== "" ? (
                                          <span
                                            onClick={() => {
                                              this.authorDetailsId(
                                                author.id,
                                                author.name
                                              );
                                            }}
                                          >
                                            {author.name}
                                          </span>
                                        ) : (
                                          "No author found"
                                        )}
                                      </span>
                                    );
                                  }
                                )}
                              </div>
                              <div className="SearchProduct">
                                <h3>{strings.ProductText}</h3>
                                {this.state.searchProductSuggetion.map(
                                  (product, index) => {
                                    return (
                                      <span>
                                        {product.name !== "" ? (
                                          <span
                                            onClick={() => {
                                              this.productDetailsId(
                                                product.id,
                                                product.name
                                              );
                                            }}
                                          >
                                            {product.name}
                                          </span>
                                        ) : (
                                          "No product found"
                                        )}
                                      </span>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="headerProfileContainer">
                        {this.props.authData.loginSuccess ? (
                          <div>
                            <Nav className="try-free-snovel-nav">
                              <NavItem>
                                <NavLink
                                  className={
                                    this.state.pathName === "/try-free-snovel"
                                      ? "active"
                                      : null
                                  }
                                  tag={Link}
                                  to="/try-free-snovel"
                                  onClick={() => {
                                    this.props.dispatch({
                                      type: "MENU_UNSELECTED_CATEGORY",
                                      payload: [false, null],
                                    });
                                    this.props.dispatch(
                                      browseCategoryProductDetailsDataAction(
                                        null,
                                        null,
                                        2
                                      )
                                    );
                                    this.handleActiveMenu("tryFreeSnovel");
                                  }}
                                >
                                  {strings.tryFreeSnovel}
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <div className="dropdownMenu loggedUserWrap">
                              <span className="profileImg">
                                {this.props.authData.userProfile !== "" &&
                                  this.props.authData.userProfile !== null ? (
                                  <img
                                    src={this.props.authData.userProfile}
                                    width="50px"
                                    height="50px"
                                    alt="Profile Image"
                                  />
                                ) : (
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/images/user_circle.png"
                                    }
                                    width="50px"
                                    height="50px"
                                    alt="Profile Image"
                                  />
                                )}
                              </span>
                              <div className="profileDrop" data-toggle="dropdown">
                                <ButtonDropdown
                                  isOpen={this.state.profileOpen}
                                  toggle={this.profileOpenWrap}
                                >
                                  <DropdownToggle caret>
                                    <i className="fa fa-caret-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <div className="profileDropdown">
                                      <Nav>
                                        <NavItem toggle={false}>
                                          <NavLink tag={Link} to="/library">
                                            <DropdownItem>
                                              {strings.library}
                                            </DropdownItem>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem toggle={false}>
                                          <NavLink
                                            tag={Link}
                                            to="/logout"
                                            onClick={this.handleLogOut}
                                          >
                                            <DropdownItem>
                                              {strings.logOut}
                                            </DropdownItem>
                                          </NavLink>
                                        </NavItem>
                                      </Nav>
                                    </div>
                                  </DropdownMenu>
                                </ButtonDropdown>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="signup_Wrapper">
                            {" "}
                            <Nav>
                              <NavItem>
                                <NavLink
                                  tag={Link}
                                  to="/sign-in"
                                  onClick={() => {
                                    this.handleActiveMenu("signIn");
                                  }}
                                >
                                  {strings.tryFreeSnovel}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  tag={Link}
                                  to="/sign-in"
                                  onClick={() => {
                                    this.handleActiveMenu("signIn");
                                  }}
                                >
                                  {strings.signInSmall}
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        )}
                      </div>
                    </div>
                    :
                    null
                  }
                </div>
              </nav>
              {this.state.isShowHeaderFooter ?
                <div className="mobile-show-content">
                  <div className="btmHeader">
                    <div className="search-container">
                      <form
                        onSubmit={this.searchResult}
                        ref={(elv) => (this.myFormRefMob = elv)}
                        autocomplete="off"
                      >
                        <input
                          type="text"
                          placeholder={strings.searchText}
                          autocomplete="false"
                          autocomplete="off"
                          name="search"
                          className="searchToggle"
                          maxLength="50"
                          onChange={this.searchQuerySuggetionFunction}
                          onClick={() => {
                            this.searchBoxMax();
                          }}
                        />
                        <div className="searchButton_Wrapper">
                          {this.state.searchboxOpen && (
                            <img
                              src={
                                process.env.PUBLIC_URL + "/images/cross_icon.png"
                              }
                              onClick={() => {
                                this.removeSearchBox();
                              }}
                            />
                          )}
                          <button type="submit">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </form>
                      {this.state.searchProductSuggetion.length > 0 ||
                        this.state.searchAuthorSuggetion.length > 0 ? (
                        <div
                          className="searchSuggetion_Wrapper"
                          onClick={this.removedSuggetion}
                        >
                          <div className="searchAuthor">
                            <h3>{strings.authorText}</h3>
                            {this.state.searchAuthorSuggetion.map(
                              (author, index) => {
                                return (
                                  <span>
                                    {author.name !== "" ? (
                                      <span
                                        onClick={() => {
                                          this.authorDetailsId(
                                            author.id,
                                            author.name
                                          );
                                        }}
                                      >
                                        {author.name}
                                      </span>
                                    ) : (
                                      "No author found"
                                    )}
                                  </span>
                                );
                              }
                            )}
                          </div>
                          <div className="SearchProduct">
                            <h3>{strings.ProductText}</h3>
                            {this.state.searchProductSuggetion.map(
                              (product, index) => {
                                return (
                                  <span>
                                    {product.name !== "" ? (
                                      <span
                                        onClick={() => {
                                          this.productDetailsId(
                                            product.id,
                                            product.name
                                          );
                                        }}
                                      >
                                        {product.name}
                                      </span>
                                    ) : (
                                      "No product found"
                                    )}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                :
                null
              }
            </header>
            <div className="content_Wrapper">
              <Switch>
                <Route
                  exact
                  path="/family"
                  render={(props) => <FreeSnovel {...props} />}
                />
                <Route
                  exact
                  path="/try-free-snovel"
                  render={(props) => <TryFreeSnovelProducts {...props} />}
                />
                <Route path="/sign-in" render={() => <SignInComponant />} />
                <Route path="/registration" render={() => <SignUpComponent />} />
                <Route
                  exact
                  path="/sign-up/:token/:email"
                  render={(props) => <RegisterComponant {...props} />}
                />
                <Route
                  exact
                  path="/sign-up/:token"
                  render={(props) => <RegisterComponant {...props} />}
                />
                <Route
                  exact
                  path="/browse"
                  render={(props) => <Browse {...props} />}
                />
                <Route
                  exact
                  path="/"
                  render={(props) => <HomePage {...props} />}
                />
                <Route
                  exact
                  path="/kids"
                  render={(props) => <KidsHomePage {...props} />}
                />
                <Route
                  exact
                  path="/language-lab"
                  render={(props) => <LanguageLabPage {...props} />}
                />
                <Route
                  exact
                  path="/subscribe"
                  render={(props) => <Subscribe {...props} />}
                />
                <Route
                  exact
                  path="/about-us"
                  render={(props) => <AboutUs {...props} />}
                />
                <Route
                  exact
                  path="/faq"
                  render={(props) => <Faq {...props} />}
                />
                <Route
                  exact
                  path="/contact"
                  render={(props) => <Contact {...props} />}
                />
                <Route
                  exact
                  path="/privacy-policy"
                  render={(props) => <PrivacyPolicy {...props} />}
                />
                <Route
                  exact
                  path="/terms-of-service"
                  render={(props) => <TermsAndCondition {...props} />}
                />
                <Route
                  exact
                  path="/director"
                  render={(props) => <Director {...props} />}
                />
                <Route
                  exact
                  path="/publisher"
                  render={(props) => <Publisher {...props} />}
                />
                <Route
                  exact
                  path="/artist"
                  render={(props) => <Artist {...props} />}
                />
                <Route
                  exact
                  path="/writer"
                  render={(props) => <Writer {...props} />}
                />
                <Route
                  exact
                  path="/audiobooks"
                  render={(props) => <AudioBooks {...props} />}
                />
                <Route
                  exact
                  path="/snovel-original"
                  render={(props) => <SnovelOriginal {...props} />}
                />
                <Route
                  exact
                  path="/podcast"
                  render={(props) => <SnovelPodcast {...props} />}
                />
                <Route
                  exact
                  path="/redeem-gift"
                  render={(props) => <RedeemGift {...props} />}
                />
                <Route
                  exact
                  path="/gift-snovel"
                  render={(props) => <GiftSnovel {...props} />}
                />
                <Route
                  exact
                  path="/search/:searchText"
                  render={(props) => <SearchResult {...props} />}
                />
                <Route exact path="/library" render={() => <UserProfile />} />
                <Route
                  exact
                  path="/payment-success/:token"
                  render={(props) => <PaymentSuccess {...props} />}
                />
                <Route
                  exact
                  path="/payment-failure/:token"
                  render={(props) => <PaymentFailure {...props} />}
                />
                <Route
                  exact
                  path="/payment-cancel/:token"
                  render={(props) => <PaymentCancel {...props} />}
                />
                <Route
                  exact
                  path="/purchase-title"
                  render={(props) => <PurchaseTitle {...props} />}
                />
                <Route
                  exact
                  path="/author/:id/:name"
                  render={(props) => <AuthorDetails {...props} />}
                />
                <Route
                  exact
                  path="/proceed-payment"
                  render={(props) => <ProceedForPayment {...props} />}
                />
                <Route exact path="/checkout" render={() => <Checkout />} />
                <Route
                  exact
                  path="/reset-password/:token"
                  render={(props) => <ConfirmPassword {...props} />}
                />
                <Route
                  exact
                  path="/audition"
                  render={(props) => <AuditionFormComponent {...props} />}
                />
                <Route
                  exact
                  path="/product-details/:id/:title"
                  render={(props) => <ProductDetail {...props} />}
                />
                <Route
                  exact
                  path="/audio-course-product-details/:id/:title"
                  render={(props) => <AudioCourseProductDetail {...props} />}
                />
                <Route
                  exact
                  path="/category-details/:id/:name"
                  render={(props) => <ShowKidsCategoryProducts {...props} />}
                />
                <Route
                  exact
                  path="/family-category-details/:name"
                  render={(props) => <ShowFamilyCategoryProducts {...props} />}
                />
                <Route
                  exact
                  path="/language-lab-category-details/:id/:name"
                  render={(props) => <ShowLanguageLabCategoryProducts {...props} />}
                />
                <Route
                  exact
                  path="/logout"
                  render={() => <Redirect to="/sign-in" />}
                />
                <Route exact path="/*" component={Page404} />
              </Switch>
            </div>
            <footer >
              {/* {this.props.authData.loginSuccess && this.state.isShowPlayer && ( */}
              {this.state.mySnovelProducts.length > 0 &&
                this.state.selectedProductDetails.producttracks !=
                undefined && (
                  <div class="footer-hls-player">
                    {this.state.showProductTracks &&
                      this.state.selectedProductDetails.producttracks !=
                      undefined &&
                      this.state.selectedProductDetails.producttracks.length >
                      0 && (
                        <div class="track-list">
                          <div
                            class="close-button"
                            onClick={() => {
                              this.setState({ showProductTracks: false });
                            }}
                          >
                            <img
                              class="close-image"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAD7klEQVRoge3Yz08cZRjA8e/7zuIie7ABwVK2NxPvmpgKGIo2SiI1qSf/AqI92YvhV4GyUJWT9mDsn6FJJUZjCbb44+TRcxdI0JpW3W2W7Lzv08OywDA778wOJruHfS4bhryzn2fe533edxY60YlOdKKVoaL+UX73/XcEdRsYEhEQgKNPOfE3AlK7CCc+A9cd4w+vB8dvI0z13l9fb+TUUQkI6quW42v3zYvY21HOyASAfIufPFK7OQjnm06g5XiRwNimE2gn/OH9mkqgnfDR/vgZaA98yhloG7xjBjKR/IT4qlgqxpBRmm6lEuErxuCLJas0XUrF40+7BqLw+9by79l+nltdoPraK5R9PxZf9qv4oxc4s7bMf4MDVKyJxbsWceQMJHnypcF+zn/+GZm+XnKvvszu8qeUNrbIeV4knvFR8jdmUZ5H90sv8uCDa+idPboUTeMhwQxE1XzFGPqvTpHp6wVAeR7nFqaRseHDmWiEHzrAA3Q930f/Rx+yb40bn6qEYhZsRmke31lHjDkcojyPocVp5OJwoJwa4QHEGB5/822tDFz4NAnEdZtupfDu/cbu0s1wEkuzcGmMku9T9qvI2EgYby07S5/AxhZZpZ14VxG5zkKxrbLH82DzF3YWTyShNeeuf4y6NIaMjZAvzIXxizex390lp714vGMGIo/T/7z1Xl3dEH+82zwxPnb0AkPLQShWEASlj55TKrwIA79vNrQmmoG4TapHe+iffmZnYTUwE2j1v+BTHiWS4Y8noTa32J5fAWsb3E5OgU+1kSXH16+LFVCq4depgKU5vGsrSNaFEuDr3SZfmAuUzdE3qdoG9vY4Zd80h093lEiJP95tRALlpLQmv1xLouT7TeDTJHBavLXsLKzyYGYp1GLzhTn0xJu1zS4JPt0iPiX+YMGqu/cpztwIJ7Eyh554g5IxsfjUb2QufMUY5PXhMN4YtucLh92mR2v48R7F2UKDJOZhfIR9a9z4dEeJaDwi+GI5c3kijL++gvywGWiVOa2R7zcoziyHjh29VyapGkmFdybgwgNklebPL76k+vBvJ77ebXKeV0ti+qicqn89ZG/tFlmt3Pg0R4lHFy9LFL5erxUxPBl8gYFrV3n09R3Y2IrdpErGwPgIvVcm2Vu7xbPFXZ5BufEinP3j14ZWRwKT4sLXf3SqWmHfGjIHs+LC17vNvjVUjZDVikwCvACDEQnEvpGF8BzhEehSkNE6WG4xm1QWTdaLqfmT4yIivgudxIdanjSFT9RtQuOile4u1C74VDPQRnjXO1ny94FW4tOUUFvh0y1ittsHL8XmExCmENluE/yU40F3ohOd6EQL4ykyR+/DNuRk9AAAAABJRU5ErkJggg=="
                            />
                          </div>
                          <div class="tracks-background">
                            <div class="track-background-div">
                              {this.state.selectedProductDetails.producttracks.map(
                                (eachTrack, trackIndex) => {
                                  return (
                                    <div
                                      //   id={
                                      //     "library" +
                                      //     eachProduct.id +
                                      //     "track" +
                                      //     eachTrack.id
                                      //   }
                                      class={[
                                        this.state.selectedTrackIndex ==
                                          trackIndex
                                          ? "videoPlayList active-play-list"
                                          : "videoPlayList",
                                      ]}
                                    >
                                      <div
                                        class="videoPlayP"
                                        onClick={() => {
                                          this.playProductTrack(trackIndex);
                                        }}
                                      >
                                        <img
                                          src={
                                            this.state.selectedTrackIndex ==
                                              trackIndex && this.state.isPlaying
                                              ? "/images/p_video.png"
                                              : "/images/v_play.png"
                                          }
                                        />
                                      </div>
                                      <div class="videoPlayTitle">
                                        {/* <span>{trackIndex + 1}</span> */}
                                        {eachTrack.custom_translation[
                                          this.state.selectedLanguage
                                        ]
                                          ? eachTrack.custom_translation[
                                            this.state.selectedLanguage
                                          ].name
                                          : eachTrack.custom_translation["EN"]
                                            .name}
                                      </div>
                                      <div class="videoPlayDuration">
                                        {strings.durationText}
                                        <br />
                                        <span>
                                          {getHoursFormat(
                                            eachTrack.track_length
                                          ) +
                                            " " +
                                            strings.hrsText}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    <div class="player-background">
                      <div class="product-artwork">
                        {this.state.selectedProductDetails.images &&
                          this.state.selectedProductDetails.images.length >
                          0 && (
                            <img
                              class="artwork"
                              src={
                                this.state.selectedProductDetails.images[0]
                                  .image1
                              }
                            />
                          )}
                      </div>

                      <div class="product-info">
                        {this.state.selectedProductDetails.producttracks &&
                          this.state.selectedProductDetails.producttracks
                            .length > 0 && (
                            <div
                              class="product-name"
                              onClick={() => {
                                this.setState({ showProductTracks: true });
                              }}
                            >
                              {this.state.selectedProductDetails.producttracks
                                ? (this.state.selectedProductDetails
                                  .custom_translation[
                                  this.state.selectedLanguage
                                ] !== undefined
                                  ? this.state.selectedProductDetails
                                    .custom_translation[
                                    this.state.selectedLanguage
                                  ].name
                                  : this.state.selectedProductDetails.name) +
                                " - " +
                                // (this.state.selectedTrackIndex + 1) +
                                " " +
                                (this.state.selectedProductDetails
                                  .producttracks[
                                  this.state.selectedTrackIndex
                                ].custom_translation[
                                  this.state.selectedLanguage
                                ] !== undefined
                                  ? this.state.selectedProductDetails
                                    .producttracks[
                                    this.state.selectedTrackIndex
                                  ].custom_translation[
                                    this.state.selectedLanguage
                                  ].name
                                  : this.state.selectedProductDetails
                                    .producttracks[
                                    this.state.selectedTrackIndex
                                  ].title)
                                : ""}
                            </div>
                          )}
                        <div class="footer-player-heading">
                          <div
                            class="previous-track-outer"
                            onClick={() => {
                              this.onPreviousTrack();
                            }}
                          >
                            <img src="/images/previous-track.png" />
                          </div>
                          <div
                            class="previous-track-outer"
                            onClick={() => {
                              this.onRewind();
                            }}
                          >
                            <img src="/images/rewind_icon.png" />
                          </div>
                          <div
                            class="hls-background"
                            key={this.state.selectedProductDetails.id}
                          >
                            {this.state.selectedProductDetails.producttracks !=
                              undefined &&
                              this.state.selectedProductDetails.producttracks.map(
                                (eachTrack, trackIndex) => {
                                  return (
                                    <div>
                                      {this.state.selectedTrackIndex ==
                                        trackIndex && (
                                          <HlsPlayer
                                            ref={this.playerRef}
                                            trackId={eachTrack.id}
                                            newKey={eachTrack.key}
                                            startTime={this.state.actualStartTime}
                                            onPlay={this.onPlayEvent}
                                            onPause={this.onPauseEvent}
                                            onEnded={this.onEndedEvent}
                                            onTimeUpdate={this.onTimeUpdateEvent}
                                            onDrag={this.onDragEvent}
                                            isPausePlayer={
                                              this.state.isPausePlayer
                                            }
                                            isPlayPlayer={this.state.isPlayPlayer}
                                          />
                                        )}
                                    </div>
                                  );
                                }
                              )}
                          </div>
                          <div
                            class="next-track-outer"
                            onClick={() => {
                              this.onFastForward();
                            }}
                          >
                            <img src="/images/fast_forward_icon.png" />
                          </div>
                          <div
                            class="next-track-outer"
                            onClick={() => {
                              this.onNextTrack();
                            }}
                          >
                            <img src="/images/next-track.png" />
                          </div>
                          {/* <div
                          class="title-outer"
                          onClick={() => {
                            this.setState({ showProductTracks: true });
                          }}
                        >
                          <NavLink
                          // tag={Link}
                          // to={
                          //   this.state.isPlayFromSection == "mySnovel" ||
                          //   this.state.isPlayFromSection == "purchasedInLibrary"
                          //     ? "/library"
                          //     : "/try-free-snovel"
                          // }
                          >
                            {this.state.selectedProductDetails.producttracks
                              ? this.state.selectedProductDetails.name +
                                " - " +
                                (this.state.selectedTrackIndex + 1) +
                                " " +
                                this.state.selectedProductDetails.producttracks[
                                  this.state.selectedTrackIndex
                                ].title
                              : ""}
                          </NavLink>
                        </div> */}
                        </div>
                      </div>
                      {/* {this.state.selectedProductDetails.producttracks !=
                      undefined &&
                      this.state.selectedProductDetails.producttracks.map(
                        (eachTrack, trackIndex) => {
                          return (
                            <div>
                              {this.state.selectedTrackIndex == trackIndex && (
                                <HlsPlayer
                                  ref={this.playerRef}
                                  trackId={eachTrack.id}
                                  newKey={eachTrack.key}
                                  startTime={this.state.actualStartTime}
                                  onPlay={this.onPlayEvent}
                                  onPause={this.onPauseEvent}
                                  onEnded={this.onEndedEvent}
                                  onTimeUpdate={this.onTimeUpdateEvent}
                                  onDrag={this.onDragEvent}
                                  isPausePlayer={this.state.isPausePlayer}
                                  isPlayPlayer={this.state.isPlayPlayer}
                                />
                              )}
                            </div>
                          );
                        }
                      )} */}
                    </div>
                  </div>
                )}
              <div class="container footer_top ">
                <Row class="homedesign_top ">
                  <Col xs="12" md="12" lg="2" className="ftFirstCol">
                    <div className="footer_item">
                      <NavLink
                        tag={Link}
                        to="/"
                        className="footer_item_link"
                        onClick={() => {
                          this.handleActiveMenu("home");
                        }}
                      >
                        <img
                          className="logo-footer"
                          src={
                            process.env.PUBLIC_URL + "/images/footer-logo1.png"
                          }
                          alt=""
                        />
                      </NavLink>

                      <a href="https://aws.amazon.com/education/edstart/" target="_blank">
                        <img src="/images/1663221144745"
                          className="footer_member"
                          alt="" />
                      </a>
                    </div>
                  </Col>
                  <Col xs="12" md="12" lg="2" className="ftFirstCol">
                    {this.state.isShowHeaderFooter ?
                      <div className="footer_item">
                        <h4 className="footer-title">{strings.Audiotext}</h4>
                        <Nav className="list-unstyled footer_menu">
                          {/* <NavItem>
                            <NavLink
                              tag={Link}
                              to="/browse"
                              onClick={() => {
                                this.props.dispatch(
                                  browseCategoryProductDetailsDataAction(
                                    "audiobook",
                                    null,
                                    2
                                  )
                                );
                                window.scrollTo(0, 0);
                              }}
                            >
                              {strings.audiobook}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              tag={Link}
                              to="/browse"
                              onClick={() => {
                                this.props.dispatch(
                                  browseCategoryProductDetailsDataAction(
                                    "snoveloriginal",
                                    null,
                                    2
                                  )
                                );
                                window.scrollTo(0, 0);
                              }}
                            >
                              {strings.snoveloriginal}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              tag={Link}
                              to="/browse"
                              onClick={() => {
                                this.props.dispatch(
                                  browseCategoryProductDetailsDataAction(
                                    "podcast",
                                    null,
                                    2
                                  )
                                );
                                window.scrollTo(0, 0);
                              }}
                            >
                              {strings.podcast}
                            </NavLink>
                          </NavItem> */}
                          <NavItem>
                            <NavLink
                              tag={Link}
                              to="/language-lab"
                              onClick={() => {
                                this.handleActiveMenu("audio-course");
                              }}
                            >
                              {strings.course}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              tag={Link}
                              to="/kids"
                              onClick={() => {
                                this.handleActiveMenu("kids");
                              }}
                            >

                              {strings.kidsMenu}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              tag={Link}
                              to="/family"
                              onClick={() => {
                                this.handleActiveMenu("family");
                              }}
                            >

                              {strings.familyText}
                            </NavLink>
                          </NavItem>
                          {/* <NavItem>
                            <NavLink
                              tag={Link}
                              to="/language-lab"
                              onClick={() => {
                                this.handleActiveMenu("languageLab");
                              }}
                            >
                              {strings.languageLab}
                            </NavLink>
                          </NavItem> */}

                          {/* {!this.props.authData.loginSuccess && (
                            <NavItem>
                              <NavLink
                                className={"cursorPointer"}
                                onClick={() => {
                                  this.handleEbookModal();
                                }}
                              >
                                {strings.chintoosEbook}
                              </NavLink>
                            </NavItem>
                          )} */}
                          {/* {this.props.authData.loginSuccess && this.state.eBookDetails.length > 0 && (
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                target="_blank"
                                href={this.state.eBookDetails[0]["pdf_url"]}
                              >{strings.chintoosEbook}</a>
                            </li>
                          )} */}
                        </Nav>
                      </div>
                      :
                      null
                    }
                  </Col>
                  <Col xs="12" md="12" lg="2" className="ftSecCol">
                    {this.state.isShowHeaderFooter ?
                      <div className="footer_item">
                        <h4 className="footer-title">
                          {strings.subscribe}
                        </h4>
                        <Nav className="list-unstyled footer_menu">
                          <NavItem>
                            <NavLink
                              tag={Link}
                              to="/subscribe"
                              onClick={() => {
                                this.handleActiveMenu("subscribe");
                              }}
                            >
                              {strings.subscribeSnovelMenu}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              tag={Link}
                              to="/gift-snovel"
                              onClick={() => {
                                this.handleActiveMenu("giftSnovel");
                              }}
                            >
                              {strings.giftSnovel}
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                      :
                      null
                    }
                  </Col>
                  <Col xs="12" md="12" lg="2" className="ftSecCol">
                    {this.state.isShowHeaderFooter ?
                      <div className="footer_item">
                        <h4 className="footer-title">{strings.company}</h4>
                        <Nav className="list-unstyled footer_menu">
                          <NavItem>
                            <NavLink
                              tag={Link}
                              to="/about-us"
                              onClick={() => {
                                this.handleActiveMenu("aboutUs");
                              }}
                            >
                              {strings.aboutSnovel}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              tag={Link}
                              to="/contact"
                              onClick={() => {
                                this.handleActiveMenu("contact");
                              }}
                            >
                              {strings.contactUsText}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              tag={Link}
                              to="/faq"
                              onClick={() => {
                                this.handleActiveMenu("faq");
                              }}
                            >
                              {strings.FAQ}
                            </NavLink>

                          </NavItem>
                          {/* <NavItem>
                            <NavLink
                              tag={Link}
                              to="/faq"
                              onClick={() => {
                                this.handleActiveMenu("faq");
                              }}
                            >
                              {strings.FAQ}
                            </NavLink>

                          </NavItem> */}
                        </Nav>
                      </div>
                      :
                      null
                    }

                  </Col>


                  <Col xs="12" md="12" lg="2" className="ftSecCol">
                    {this.state.isShowHeaderFooter ?
                      <div className="footer_item">
                        <h4 className="footer-title">{strings.offer}</h4>
                        <Nav className="list-unstyled footer_menu">
                          <NavItem>
                            <NavLink
                              tag={Link}
                              style={{ textDecoration: "none" }}
                              to='/images/Snovel Voice of Brand Leaflet.pdf' target="_blank" >
                              {strings.voice}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              tag={Link}
                              style={{ textDecoration: "none" }}
                              to='/images/Snovel Audio OTT Solution Leaflet.pdf' target="_blank" >
                              {strings.audioOTT}
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                      :
                      null
                    }
                  </Col>




                  <Col xs="12" md="12" lg="4" className="ftSecCol">
                    <div className="footer_item">
                      <div></div>
                      <ul class="list-inline footer_social_icon">
                        <li>
                          <a
                            target="_blank"
                            href="https://www.facebook.com/snovel.in"
                          >
                            <span class="fa fa-facebook"></span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.youtube.com/channel/UCA4ernLEMd7XWNhXd2B-_dg"
                          >
                            <span class="fa fa-youtube-play"></span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.instagram.com/snovel.india"
                          >
                            <span class="fa fa-instagram"></span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://twitter.com/FollowSnovel"
                          >
                            <span class="fa fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="mailto:hello@snovel.in">
                            <span class="fa fa-envelope"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                class={
                  this.state.isShowPlayer
                    ? "footer_bottom text-center player-footer-bottom"
                    : "footer_bottom text-center"
                }
              >
                <NavLink
                  tag={Link}
                  to="/terms-of-service"
                  onClick={() => {
                    this.handleActiveMenu("termsOfService");
                  }}
                >
                  {strings.termsAndConditions}
                </NavLink>
                |
                <NavLink
                  tag={Link}
                  to="/privacy-policy"
                  onClick={() => {
                    this.handleActiveMenu("privacyPolicy");
                  }}
                >
                  {strings.privacyPolicy}
                </NavLink>
              </div>
              <ScrollUpButton
                StopPosition={0}
                ShowAtPosition={150}
                EasingType="easeOutCubic"
                AnimationDuration={500}
                ContainerClassName="ScrollUpButton__Container"
                TransitionClassName={
                  this.state.isShowPlayer
                    ? "ScrollUpButton__Toggled toggle-with-player"
                    : "ScrollUpButton__Toggled"
                }
              >
                <span>
                  <img src="/images/top.png" alt="" />
                </span>
              </ScrollUpButton>
            </footer>
            <Modal
              isOpen={this.state.cartProductModal}
              toggle={this.cartProductsModalToggle}
              size={"sm"}
              className={"cart-modal"}
            >
              <ModalHeader toggle={this.cartProductsModalToggle}>
                {strings.yourItemsBasket}
                <button
                  type="button"
                  class="close closeReview"
                  data-dismiss="modal"
                  onClick={this.closeCartProdcutModel}
                >
                  &times;
                </button>
              </ModalHeader>
              <ModalBody>
                <CartProductModal closeModal={this.closeCartProdcutModel} />
              </ModalBody>
            </Modal>
            {!this.props.authData.loginSuccess && (
              <Modal
                isOpen={this.state.signUpModal}
                toggle={this.signUpEmailModal}
                className={"signup-model"}
              >
                <ModalHeader toggle={this.signUpEmailModal}></ModalHeader>
                <ModalBody>
                  <h4 className="createAccount">{strings.WelcometoSnovel}</h4>
                  <h5 class="model-title-sub">{strings.JoinSnovel}</h5>
                  <EmailSignUpConfirmation />
                </ModalBody>
              </Modal>
            )}
          </div>
        )}
        <Modal
          isOpen={this.state.loadingPopup}
          size={"lg"}
          id="bannerModel-wrapper"
        >
          <ModalBody>
            <div class="rightCookies">
              <img
                src={process.env.PUBLIC_URL + "/images/cross_icon.png"}
                onClick={this.closeBanerPopup}
                className="banner-model-close"
              />
              <img src={process.env.PUBLIC_URL + "/images/snovel-audio-show.jpg"} />
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.ebookAuthModel} size={"sm"} id="myCookies">
          <ModalBody>
            <button
              type="button"
              class="close custom-close-button"
              data-dismiss="modal"
              onClick={() => {
                this.setState({
                  ebookAuthModel: false,
                });
              }}
            >
              &times;
            </button>
            <div class="auditionformModal">
              <h3>
                {strings.ebookPopupText}
              </h3>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                onClick={() => this.handleSigninModal()}
              >
                {strings.signInSmall}
              </button>
            </div>
          </ModalBody>
        </Modal>
        {this.state.isMaintenanceMode && (
          <div className="maintanaceMode">
            <img src="images/UnderConstruction.png" alt="Mantanance Image" />
          </div>
        )}
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    authData: state.authonticationDetails,
    tabData: state.authTab,
    languageTab: state.languageTab,
    selectedPostAndCategory: state.selectedPostAndCategory,
    modelSelectedFunction: state.modelSelectedFunction,
    selectedProductToPlay: state.selectedProductToPlay,
    productCartCountReducer: state.productCartCountReducer.cartCount,
    isIndian: state.isReisteredDevice.isIndian,
    eBookDetails: state.kidsHomeCategoryReducer.eBookDetsils,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(
  myConnector(
    geolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    })(mainComponant)
  )
);
// TODO replace all
// R: var userDetailsObject = JSON.parse(userDetails);
// F: var userDetailsObject = userDetails;
