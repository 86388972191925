const initialState = {
    tryFreeSnovelProducts:{}
}

export default function tryFreeProductDetailsData (state = initialState, action) {
    switch (action.type) {
        case 'TRY_FREE_PRODUCTS': {
            return { ...state, tryFreeSnovelProducts: action.payload};
        }
        case 'FAILED_TRY_FREE_PRODUCTS': {
            return { ...state };
        }
        default: {
            return { ...state };
        }
    }
}