import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { googleAnalyticsTrackingId } from '../../config';
ReactGA.initialize(googleAnalyticsTrackingId);

class privacyPolicy extends Component {
    constructor(props){
        super(props);
        ReactGA.pageview(this.props.location.pathname);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <div className="container privacrPolicy">
                  <h2>Privacy Policy</h2>
                    <div class="entry-content">
                        <div class="legal-txt-box">This privacy policy is for Snovel Platform and the privacy policy is served by the owner of the Snovel Platform i.e. Snovel Creations Private Limited. This privacy policy governs the privacy of its Users/Registered Users that choose to use it.</div>
                        <div class="legal-txt-box">Snovel platform can be accessed by going on <a href="https://snovel.in/">https://snovel.in/</a> and/or the Snovel mobile application can be downloaded from Google Play Store or Apple iStore as required. Snovel Platform showcase’s Snovel’s work and provide a platform for customers to easily listen to audio works. SNOVEL produces audio experiences (audiobooks, audio plays/audio movies, originals, audio shows, podcast etc.) and sells those experiences on its platform. Snovel Platform provides access to a User in a limited form for few selected free products, and User can only listen to the sample audio provided for each audio work. In order to become a Registered User, the User has to Register himself/herself on Snovel platform and create an account for themselves. For listening to entire catalog Registered User has to subscribe to Snovel Platform by paying a Subscription Fee on a monthly, quarterly or annual basis, or Registered user also has an option of purchasing any audio work as individual title/product and just listen to that particular title/product. The Registered user can also provide comments on each audio work and share.</div>
                        <div class="legal-txt-box">The privacy policy informs the Users and the Registered Users of policies and procedures regarding collection, use and disclosure of personal information that we receive from Users/Registered Users. The owner of Snovel Platform reserves the right to change the provisions of the privacy policy from time to time without any prior notice and restrict Users/Registered Users to access Snovel Platform upon the owner’s sole discretion.</div>
                        <div class="legal-sub-hd">
                        <h3>1. Information Collected</h3>
                        </div>
                        <div class="legal-txt-box">A User is required to provide personal information to register on Snovel Platform by providing his/her/their email address/Google Account/Facebook/Twitter Account. Snovel Platform may collect personal information when the User registers. While registering, the Snovel Platform asks for the personal information of the User that is personally identifiable like name, address, mobile number, e-mail address. Snovel Platform may collect any information which is required to store and verify.</div>
                        <div class="legal-txt-box">There is certain information which is also collected automatically such as Internet Protocol (IP) address, browser type, access time etc. IP Address is received automatically from the User’s/Registered User’s internet service provider and recorded into the owner’s server logs.</div>
                        <div class="legal-sub-hd">
                        <h3>2. Information use</h3>
                        </div>
                        <div class="legal-txt-box">Snovel Platform uses information like Internet Protocol (IP) address, browser type, access time and Registered User’s personal information to maintain the quality of its service.</div>
                        <div class="legal-txt-box">Why Snovel wants user data: Snovel is a subscription-based audiobook, podcast platform. Only registered users on the Snovel platform can access Snovel's digital streaming products. Snovel uses user data only to the extent that user should get better experience on Snovel platform for listening.</div>
                        <div class="legal-txt-box">Where the user data is stored: The data is stored very securely in encrypted format on AWS cloud.</div>
                        <div class="legal-txt-box">Can user request the deletion of his/her data: Yes, we comply with GDPR. Users can request data deletion. User can send us an email at <a href="mailto:hello@snovel.in">hello@snovel.in</a> for data deletion.</div>
                        <div class="legal-txt-box">Is the user data really deleted: Yes, we comply with GDPR. If users request data deletion, we do delete the data.</div>
                        <div class="legal-txt-box">How Snovel is using user data. Is Snovel selling user data or not? How do Snovel ensure end user about data privacy: Snovel is a subscription-based audiobook, podcast platform. Only registered users on Snovel platform can access Snovel's digital streaming products. Snovel uses user data only to the extent that user should get better experience on Snovel platform for listening. Snovel is not selling user data to anybody.</div>
                        <div class="legal-txt-box">What data we process: We process user's registration details just to ensure that we are providing access to legitimate user. We process user locations, so that we can show location-based content recommendations. To access entire Snovel catalog on the portal and app, one needs to register, thereby we are avoiding any spam user.</div>
                        <div class="legal-txt-box">Deletion request from user: If any registered user wants to request deletion of the user's data, then user can send us an email at <a href="mailto:hello@snovel.in">hello@snovel.in</a> and we will ensure that the specific user data is deleted from our database.</div>
                        <div class="legal-txt-box">Why Snovel doesn't have this kind of information published publicly at some URL: Snovel is an open platform, where Snovel provides curated content specially understanding user requirements, needs; if users wish to discover Snovel they can reach at <a href="https://snovel.in">https://snovel.in</a> if they wish and like the content, they can register and subscribe.</div>
                        <div class="legal-txt-box">We follow all the laws and regulations.</div>
                        <div class="legal-sub-hd">
                        <h3>3. Information Sharing and Disclosing Personal Information to Third Parties</h3>
                        </div>
                        <div class="legal-txt-box">Any personal information or non-personal information may be disclosed to owner’s other companies, agents, service providers or other companies working for the owner like processing payments, provision of data storage, hosting Snovel Platform, marketing the owner’s services, organizing audits and performing web analysis.</div>
                        <div class="legal-txt-box">If user wishes to avail auto-renewal facility or feature for its subscription service on Snovel platform, this facility of feature is available on Snovel platform. For a particular user, this can be availed by selecting a particular feature; this can be done only if the user selects the auto renewal facility for subscription pack and by no other means. In such cases, the payment gateway and Snovel might store payment related details (payment card etc.) from the user, which is strictly used only for better uninterrupted service for the user on Snovel platform and nowhere else.</div>
                        <div class="legal-txt-box">Additionally, Snovel reserves the right to disclose a User/Registered Users personal information, without notice, if required to do so by any law for the time being in force or if it is necessary to comply with legal process prevailing in the country or if it is necessary to protect the rights and property of Snovel or to protect the rights and property of other Users/Registered Users of Snovel Platform.</div>
                        <div class="legal-sub-hd">
                        <h3>4. Information Security</h3>
                        </div>
                        <div class="legal-txt-box">Snovel Platform follows General Data Protection Regulation (GDPR). Snovel Platform secures the personal information provided by the User/Registered User on computer servers in a controlled and protected environment to prevent loss, misuse, unauthorized access, disclosure, alteration and destruction of the information. It is necessary that Users/Registered Users keep their personal information including, but not limited to, email address and password confidential and must not share it with anyone to prevent unauthorized use or access of their personal information. The owner will not be held responsible for any misuse of Snovel Platform by the Users/ Registered Users. Misuse of Snovel Platform data by the Users/Registered Users includes but not limited to sharing the data, selling data, providing incorrect information, removing/erasing records, intentional tampering with data. The owner shall not sell, trade or lease collected information without the User’s/Registered User’s consent.</div>
                        <div class="legal-sub-hd">
                        <h3>5. Use of Cookies</h3>
                        </div>
                        <div class="legal-txt-box">A cookie is a small text file which is automatically created on the hard disk of your computer by a web page server. Cookies are not used to run programs or transmit viruses to your computer. Cookies are uniquely assigned to the User/Registered User and can only be read by a web server in the domain that issued the cookie to the User/Registered User. Snovel Platform does use cookies.</div>
                        <div class="legal-sub-hd">
                        <h3>6. Contact Us</h3>
                        </div>
                        <div class="legal-txt-box">If you have any questions about this Privacy Policy, please contact us at</div>
                        <div class="legal-txt-box"><b>Email:</b> <a href="mailto:hello@snovel.in">hello@snovel.in</a></div>
                        <div class="legal-txt-box"><b>Our postal Address:</b> A-102 DSK Gandhakosh, Sr. No. 153-155 Baner, Pune-411045, India</div>
                        <div class="legal-txt-box">Last date of document update and release: 30-Sep-2021</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default privacyPolicy;