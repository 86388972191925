import React, { Component } from 'react';
import { userAuthentication } from '../action/loginAction';
import { connect } from 'react-redux';
import { Route, Link, Redirect, withRouter } from 'react-router-dom';

class loginComponant extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="dropdownMenu">
                <span className="profileImg"><img src="images/profile.png" width="50px" height="50px" alt="Profile Image" /></span>
                <span className="profileText">{this.props.authonticationDetails.userEmail}</span>
                <span className="profileDrop" data-toggle="dropdown"><a href=""><i className="fa fa-caret-down"></i></a></span>   
                <div className="profileDropdown">
                 <ul>
                     <li><a href="#">Profile</a></li>
                     <li><a href="#">Logout</a></li>
                 </ul>
                </div>      
            </div>
        );
    }
}

function mapStatesToProps(globalData) {
    return {
        authonticationDetails: globalData.authonticationDetails,
    };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(loginComponant));

