import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import {
  Row,
  Col,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Form,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import {
  api_base_url,
  encryptDecryptKey,
  countryList,
  nameRegularExpression,
  mobileRegularExpression,
  googleAnalyticsTrackingId,
  emailReg,
} from "../../config";
import { Link, withRouter } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import axios from "axios";
import UserProfileSubscribe from "../userProfile/userProfileSubscribe";
import LocalizedStrings from "react-localization";
import languages from "../../language/language.json";

ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

class checkout extends Component {
  constructor(props) {
    super(props);
    /********* Language selection *********/
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    /***** Get User Data *****/
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
        this.getUserProfileDetails(token);
      }
    }
    /***** Get Country Data *****/
    var newCountryNameList = {};
    var newDialCodeList = {};
    for (var i = 0; i < countryList.length; i++) {
      var eachCountry = countryList[i];
      newCountryNameList[eachCountry.code] = eachCountry.name;
      newDialCodeList[eachCountry.code] = eachCountry.dial_code;
    }

    /******* State Data  AC09EF8C1F65 ********/
    this.state = {
      token: token,
      firstName: "",
      lastName: "",
      subscription: [],
      countryCode: "",
      mobileNumber: "",
      email: "",
      actualEmail: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      mobileNumberError: "",
      isSubcribeNewsletter: false,
      cartDetailsProducts: [],
      applyCoupen: "",
      coupneVoucher: "",
      totalPrice: 0,
      igstAmount: 0,
      cgstAmount: 0,
      sgstAmount: 0,
      igstPercent: 0,
      cgstPercent: 0,
      sgstPercent: 0,
      coupenDiscountAmount: 0,
      acceptTerm: false,
      acceptTermError: "",
      selectedLanguage: selectedLanguage || "EN",
      hiddenRemoveCupon: false,
      cuponCodeValue: "",
      cuponMessage: "",
      cuponMessageSuccess: "",
      AfterCuponAmount: "",
      isAddedCoupon: false,
      couponError: "",
      notificationCount: 0,
      isAutoRenewalOn: false,
      isAutoRenewalVisible: false,
      isIndian: null,
      currcountry: "",
      currentstate: "",
      openEditProfile: false,
    };
    this.handleCoupenValue = this.handleCoupenValue.bind(this);
    this.applyCoupen = this.applyCoupen.bind(this);
    this.placeProductsOrder = this.placeProductsOrder.bind(this);
    this.removeCopenCode = this.removeCopenCode.bind(this);
    this.checkAutoRenewal = this.checkAutoRenewal.bind(this);
    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.checkoutCartDetails();
    this.getuserCountry()
    this.getuserState()
  }

  getuserCountry = async () => {
    let getcountrycode;
    try {
      getcountrycode = await localStorage.getItem("currentcountrycode")
      this.setState({ currcountry: getcountrycode })
      // alert(this.state.currcountry)
    }
    catch (e) {

    }
  }

  getuserState = async () => {
    let getstatecode;
    try {
      getstatecode = await localStorage.getItem("currState")
      this.setState({ currentstate: getstatecode })
    }
    catch (e) {

    }
  }



  componentWillReceiveProps(nextProps) {
    if (nextProps.isIndian !== null && nextProps.isIndian !== undefined) {
      this.setState({ isIndian: nextProps.isIndian });
    }
    // console.log("-----", this.state.isIndian)
    var cartProductList = this.state.cartDetailsProducts.lines || [];
    if (cartProductList.length > 0) {
      var isAutoRenewalVisible = false;
      cartProductList.map((cartProduct, index) => {
        if (cartProduct.product.subscription_product === true) {
          isAutoRenewalVisible = true;
        }
      });
      this.setState({ isAutoRenewalVisible });
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
    if (nextProps.authData) {
      if (
        nextProps.authData.userCountry !== this.state.currcountry ||
        (nextProps.authData.userCountry === "IN" &&
          this.state.currcountry === "IN" &&
          nextProps.authData.userState !== this.props.authData.userState)
      ) {
        this.checkoutCartDetails();
      }
      this.setState({
        //firstName: nextProps.authData.firstName,
        //lastName: nextProps.authData.lastName,
        //countryCode: nextProps.authData.userCountry,
      });
    }
  }

  getNotificationList(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    var apiParams = {
      offset: 0,
      limit: 10,
    };
    axios
      .post(api_base_url + "/api/my-notification/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status) {
          this.setState({
            notificationCount: response.data.count || 0,
          });
        } else {
          this.setState({
            notificationCount: 0,
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          notificationCount: 0,
        });
      });
  }

  getUserProfileDetails(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/v1/my-profile/", { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var userDetails = response.data.data.my_profile;
          var newsletter_subscribed =
            userDetails.newsletter_subscribed || false;
          var first_name = userDetails.first_name || "";
          var last_name = userDetails.last_name || "";
          var country = userDetails.country || "";
          var mobile = userDetails.mobile || "";
          var email = userDetails.email || "";
          var actualEmail = userDetails.email || "";
          this.setState({
            firstName: first_name,
            lastName: last_name,
            countryCode: country,
            mobileNumber: mobile,
            email: email,
            actualEmail: actualEmail,
            isSubcribeNewsletter: newsletter_subscribed,
          });
        }
        this.setState({
          isLoading: false,
        });
        this.getNotificationList(token);
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          isLoading: false,
        });
        this.getNotificationList(token);
      });
  }
  handleCoupenValue(e) {
    this.setState({ coupneVoucher: e.target.value });
    if (this.state.coupneVoucher.length > 0) {
      this.setState({
        hiddenRemoveCupon: true,
      });
    }
  }
  checkoutCartDetails() {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .get(api_base_url + "/api/v1/cart-details/", { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.length > 0) {
            var isAddedCouponAlready = false;
            var newDiscountAmount = 0;
            if (
              response.data.data[0].voucher_code != null &&
              response.data.data[0].discount_amount_inr.amount != "0.00" &&
              response.data.data[0].discount_amount.amount != "0.00"
            ) {
              isAddedCouponAlready = true;
              newDiscountAmount = response.data.data[0].discount_amount.amount;
              if (this.state.currcountry === "IN") {
                newDiscountAmount =
                  response.data.data[0].discount_amount_inr.amount;
              }
              this.setState({
                isAddedCoupon: isAddedCouponAlready,
                coupneVoucher: response.data.data[0].voucher_code,
                coupenDiscountAmount: newDiscountAmount,
              });
            }
            this.setState({
              cartDetailsProducts: response.data.data[0] || undefined,
            });
            var newIgstPercent = 0;
            var newCgstPercent = 0;
            var newSgstPercent = 0;
            if (this.state.currcountry === "IN") {
              var taxRate = response.data.data[0].tax_rate;
              if (taxRate) {
                if (this.state.currentstate === "MH") {
                  newCgstPercent = taxRate.standard_rate.CGST;
                  newSgstPercent = taxRate.standard_rate.SGST;
                } else {
                  newIgstPercent = taxRate.standard_rate.IGST;
                }
              }
            }
            this.setState({
              igstPercent: newIgstPercent,
              cgstPercent: newCgstPercent,
              sgstPercent: newSgstPercent,
            });

            var totalAmount = 0;
            response.data.data[0].lines.map((subTotal, index) => {
              var newAmount = subTotal.product.price.amount;
              if (this.state.currcountry === "IN") {
                newAmount = subTotal.product.inr_price.amount;
              }
              totalAmount = totalAmount + parseFloat(newAmount);
              if (index === response.data.data[0].lines.length - 1) {
                var newIgstAmount = 0;
                var newCgstAmount = 0;
                var newSgstAmount = 0;
                if (this.state.currcountry === "IN") {
                  var newTotal = totalAmount - newDiscountAmount;
                  if (newIgstPercent !== 0) {
                    newIgstAmount = (newTotal * newIgstPercent) / 100;
                  } else {
                    newCgstAmount = (newTotal * newCgstPercent) / 100;
                    newSgstAmount = (newTotal * newSgstPercent) / 100;
                  }
                }
                this.setState({
                  totalPrice: totalAmount,
                  AfterCuponAmount: totalAmount,
                  igstAmount: newIgstAmount,
                  cgstAmount: newCgstAmount,
                  sgstAmount: newSgstAmount,
                });

              }
            });
          }
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  /*********  Apply coupon code **************/
  applyCoupen() {
    this.setState({
      cuponMessageSuccess: "",
      cuponMessage: "",
      couponError: "",
    });

    var isValid = false;

    if (this.state.coupneVoucher === "") {
      this.setState({
        couponError: strings.EnterValidCoupon,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    if (isValid) {
      var apiParams = {
        token: this.state.cartDetailsProducts.token,
        voucher_code: this.state.coupneVoucher,
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .post(api_base_url + "/api/apply-coupon/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status === true) {
            var newDiscount = response.data.data.discount_amount;
            if (this.state.currcountry === "IN") {
              newDiscount = response.data.data.discount_amount_inr;
            }
            var newIgstAmount = 0;
            var newCgstAmount = 0;
            var newSgstAmount = 0;
            if (this.state.currcountry === "IN") {
              var newTotal = this.state.AfterCuponAmount - newDiscount;
              if (this.state.igstPercent !== 0) {
                newIgstAmount = (newTotal * this.state.igstPercent) / 100;
              } else {
                newCgstAmount = (newTotal * this.state.cgstPercent) / 100;
                newSgstAmount = (newTotal * this.state.sgstPercent) / 100;
              }
            }
            this.setState({
              isAddedCoupon: true,
              coupenDiscountAmount: newDiscount,
              cuponMessageSuccess: response.data.message,
              cuponMessage: "",
              igstAmount: newIgstAmount,
              cgstAmount: newCgstAmount,
              sgstAmount: newSgstAmount,
            });
          }
          if (response.data.status === false) {
            this.setState({
              cuponMessage: response.data.data[0].voucher_code[0],
            });
          }
        })
        .catch((err) => {
          // console.log("fetched.", err);
        });
    }
  }
  /*****  Remove coupon code **********/
  removeCopenCode() {
    var apiParams = {
      token: this.state.cartDetailsProducts.token,
      voucher_code: "",
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/apply-coupon/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          var newIgstAmount = 0;
          var newCgstAmount = 0;
          var newSgstAmount = 0;
          if (this.state.currcountry === "IN") {
            if (this.state.igstPercent !== 0) {
              newIgstAmount =
                (this.state.AfterCuponAmount * this.state.igstPercent) / 100;
            } else {
              newCgstAmount =
                (this.state.AfterCuponAmount * this.state.cgstPercent) / 100;
              newSgstAmount =
                (this.state.AfterCuponAmount * this.state.sgstPercent) / 100;
            }
          }
          this.setState({
            isAddedCoupon: false,
            coupenDiscountAmount: 0,
            cuponMessage: response.data.message,
            totalPrice: this.state.AfterCuponAmount,
            coupneVoucher: "",
            cuponMessageSuccess: "",
            igstAmount: newIgstAmount,
            cgstAmount: newCgstAmount,
            sgstAmount: newSgstAmount,
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  popCartModelData() {
    return this.state.cartDetailsProducts.lines.map((cartItem, index) => {
      return (
        <div className="yourProdctDivName">
          <div className="yourPrdNameLft">{cartItem.product.name}</div>
          <div className="yourPrdNameRgt">
            {this.state.isIndian !== null ?
              <Fragment>
                {this.state.currcountry ? strings.priceSign + "" + cartItem.product.inr_price.amount
                  : "$" + cartItem.product.price.amount}
              </Fragment>
              :
              <Fragment>
                {this.state.currcountry === "IN"
                  ? strings.priceSign + " " + cartItem.product.inr_price.amount
                  : "$" + cartItem.product.price.amount}
              </Fragment>
            }
          </div>
        </div>
      );
    });
  }

  placeProductsOrder(event) {
    event.preventDefault();
    this.setState({
      acceptTermError: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      mobileNumberError: "",
    });
    var isValid = true;
    if (this.state.firstName === "") {
      this.setState({
        firstNameError: strings.thisFieldRequire,
      });
      isValid = false;
    }

    if (this.state.lastName === "") {
      this.setState({
        lastNameError: strings.thisFieldRequire,
      });
      isValid = false;
    }

    if (this.state.email === "") {
      this.setState({
        emailError: strings.thisFieldRequire,
      });
      isValid = false;
    } else if (emailReg.test(this.state.email) === false) {
      this.setState({
        emailError: strings.enterValidEmail,
      });
      isValid = false;
    }

    if (
      this.state.currcountry === "IN" &&
      this.props.authData.userState === ""
    ) {
      this.setState({
        acceptTermError: strings.completeProfile,
      });
      isValid = false;
    } else if (!this.state.acceptTerm) {
      this.setState({
        acceptTermError: strings.ourTermsConditions,
      });
      isValid = false;
    }

    if (isValid) {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      var orderCurrency = "1";
      if (this.state.currcountry === "IN") {
        orderCurrency = "2";
      }
      var apiParams = {
        order_currency: orderCurrency,
        gift_order: false,
        email: this.state.actualEmail,
        cart_token: this.state.cartDetailsProducts.token,
        first_name: "",
        last_name: "",
        message: "",
      };
      axios
        .post(api_base_url + "/api/v1/orders/", apiParams, { headers: headers })
        .then((response) => {
          if (response.data.status === true) {
            var orderToken = response.data.data.order_token;
            var headers = {
              "Content-Type": "application/json",
              Authorization: "Token " + this.state.token,
            };
            var apiParams = {
              token: orderToken,
            };
            if (this.state.currcountry === "IN") {
              var api_url = "/api/v1/payu-checkout/";
              if (this.state.isAutoRenewalOn) {
                api_url = "/api/auto_renewal_checkout/";
              }
              axios
                .post(api_base_url + api_url, apiParams, {
                  headers: headers,
                })
                .then((response) => {
                  if (response.data == "cc_avenue") {
                    window.open(api_base_url + '/order/ccavenue-pay/' + orderToken, '_self', 'top=10', 'replace=false');
                  } else {
                    this.props.history.push({
                      pathname: "/proceed-payment",
                      state: { detail: response.data, isPayAutoRenewal: false },
                    });
                  }
                })
                .catch((err) => {
                  // console.log("fetched.", err);
                });
            } else {
              axios
                .post(api_base_url + (this.state.isAutoRenewalOn ? "/api/paypal_recurring" : "/api/v1/paypal-checkout/"), apiParams, {
                  headers: headers,
                })
                .then((response) => {
                  this.props.history.push({
                    pathname: "/proceed-payment",
                    state: { detail: response.data, isPayAutoRenewal: this.state.isAutoRenewalOn },
                  });
                })
                .catch((err) => {
                  // console.log("fetched.", err);
                });
            }
          }
        })
        .catch((err) => {
          // console.log("fetched.", err);
        });
    }
  }

  checkAutoRenewal() {
    this.setState({ isAutoRenewalOn: !this.state.isAutoRenewalOn });
  }

  render() {
    var { isAutoRenewalVisible, token } = this.state;
    return (
      <div>
        <div className="userSubscribeCheckout">
          <UserProfileSubscribe
            notificationCount={this.state.notificationCount}
          />
        </div>

        <div className="clearfix"></div>
        <div className="container formBillWrp">
          <h2>{strings.BillingDetails}</h2>
          <Form>
            <div className="formBillLft">
              <div className="billFrmInputLft">
                {strings.firstName}
                <br />
                <Input
                  className="custom-disable-field"
                  disabled={true}
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={this.state.firstName}
                  onChange={(event) => {
                    this.setState({ firstName: event.target.value });
                  }}
                />
                <div class="loginErrorMsg">{this.state.firstNameError}</div>
              </div>
              <div className="billFrmInputRgt">
                {strings.lastName}
                <br />
                <Input
                  className="custom-disable-field"
                  disabled={true}
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={this.state.lastName}
                  onChange={(event) => {
                    this.setState({ lastName: event.target.value });
                  }}
                />
                <div class="loginErrorMsg">{this.state.lastNameError}</div>
              </div>
              <div className="billFrmInputLft">
                {strings.userMobile}
                <br />
                <Input
                  className="custom-disable-field"
                  disabled={true}
                  type="tel"
                  name="phone"
                  id="phone"
                  value={this.state.mobileNumber}
                  onChange={(event) => {
                    this.setState({ mobileNumber: event.target.value });
                  }}
                />
              </div>
              <div className="billFrmInputRgt">
                {strings.emailText}
                <br />
                <Input
                  className="custom-disable-field"
                  disabled={true}
                  type="text"
                  name="email"
                  id="email"
                  value={this.state.email}
                  onChange={(event) => {
                    this.setState({ email: event.target.value });
                  }}
                />
                <div className="loginErrorMsg">{this.state.emailError}</div>
              </div>
              <div className="billFrmInputLft">
                {strings.country}
                <br />
                <Input
                  disabled={true}
                  type="select"
                  name="countryCode"
                  value={this.state.countryCode}
                  id="countryCode"
                  onChange={(event) => {
                    this.setState({ countryCode: event.target.value });
                  }}
                >
                  {countryList.map((eachCountry, index) => {
                    return (
                      <option value={eachCountry.code} key={eachCountry.code}>
                        {eachCountry.name}
                      </option>
                    );
                  })}
                  <div className="loginErrorMsg">
                    {this.state.firstNameError}
                  </div>
                </Input>
              </div>
              <div className="billCheckBx">
                <label className="checkbox">
                  {strings.SubscribeNewsletter}
                  <input
                    disabled={true}
                    checked={this.state.isSubcribeNewsletter}
                    type="checkbox"
                  />
                  <span className="checkmarkbx"></span>
                </label>
              </div>
              <p>
                {strings.alwaysbeeager}{" "}
                <a href="mailto:hello@snovel.in"> hello@snovel.in</a>
              </p>
            </div>
            <div className="formBillRgt">
              <div className="yourOrder">{strings.YourOrder}</div>
              <div className="yourProdctDiv">
                <div className="yourPrdLft">{strings.ProductText}</div>
                <div className="yourPrdRgt">{strings.totalText}</div>
              </div>
              {this.state.cartDetailsProducts["lines"] !== undefined &&
                this.popCartModelData()}
              <div className="yourProdctDivName">
                <div className="yourPrdNameLft">{strings.subtotal}</div>
                <div className="yourPrdNameRgt">

                  {this.state.isIndian !== null ?
                    <Fragment>

                      {this.state.currcountry ? strings.priceSign + ""
                        : "$"}
                    </Fragment>
                    :
                    <Fragment>
                      {this.state.currcountry === "IN"
                        ? strings.priceSign + " "
                        : "$"}
                    </Fragment>
                  }
                  {parseFloat(this.state.totalPrice).toFixed(2)}
                </div>
              </div>
              {this.state.isAddedCoupon && (
                <div className="yourProdctDivName">
                  <div className="yourPrdNameLft">{strings.discountText}</div>
                  <div className="yourPrdNameRgt">
                    {this.state.isIndian !== null ?
                      <Fragment>
                        {this.state.currcountry ? strings.priceSign + ""
                          : "$"}
                      </Fragment>
                      :
                      <Fragment>
                        {this.state.currcountry === "IN"
                          ? strings.priceSign + " "
                          : "$"}
                      </Fragment>
                    }
                    {parseFloat(this.state.coupenDiscountAmount).toFixed(2)}
                  </div>
                </div>
              )}
              {this.state.cartDetailsProducts["lines"] !== undefined &&
                this.state.currcountry === "IN" && (
                  <div>
                    {this.state.currentstate !== "MH" &&
                      this.state.currentstate !== "" && (
                        <div className="yourProdctDivName">
                          <div className="yourPrdNameLft">
                            {strings.igstText}
                          </div>
                          <div className="yourPrdNameRgt">
                            {this.state.isIndian !== null ?
                              <Fragment>
                                {this.state.currcountry ? strings.priceSign + ""
                                  : "$"}
                              </Fragment>
                              :
                              <Fragment>
                                {this.state.currcountry === "IN"
                                  ? strings.priceSign + " "
                                  : "$"}
                              </Fragment>
                            }
                            {parseFloat(this.state.igstAmount).toFixed(2)}
                          </div>
                        </div>
                      )}
                    {this.state.currentstate === "MH" && (
                      <div>
                        <div className="yourProdctDivName">
                          <div className="yourPrdNameLft">
                            {strings.cgstText}
                          </div>
                          <div className="yourPrdNameRgt">
                            {this.state.isIndian !== null ?
                              <Fragment>
                                {this.state.currcountry ? strings.priceSign + ""
                                  : "$"}
                              </Fragment>
                              :
                              <Fragment>
                                {this.state.currcountry === "IN"
                                  ? strings.priceSign + " "
                                  : "$"}
                              </Fragment>
                            }
                            {parseFloat(this.state.cgstAmount).toFixed(2)}
                          </div>
                        </div>
                        <div className="yourProdctDivName">
                          <div className="yourPrdNameLft">
                            {strings.sgstText}
                          </div>
                          <div className="yourPrdNameRgt">
                            {this.state.isIndian !== null ?
                              <Fragment>
                                {this.state.currcountry ? strings.priceSign + ""
                                  : "$"}
                              </Fragment>
                              :
                              <Fragment>
                                {this.state.currcountry === "IN"
                                  ? strings.priceSign + " "
                                  : "$"}
                              </Fragment>
                            }
                            {parseFloat(this.state.sgstAmount).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              <div className="yourProdctDivName">
                <div className="yourPrdNameLft">
                  <strong>{strings.totalText}</strong>
                </div>
                <div className="yourPrdNameRgt">
                  <strong>
                    {this.state.isIndian !== null ?
                      <Fragment>
                        {this.state.currcountry ? strings.priceSign + ""
                          : "$"}
                      </Fragment>
                      :
                      <Fragment>
                        {this.state.currcountry === "IN"
                          ? strings.priceSign + " "
                          : "$"}
                      </Fragment>
                    }
                    {parseFloat(
                      this.state.totalPrice -
                      this.state.coupenDiscountAmount +
                      (this.state.igstAmount +
                        this.state.cgstAmount +
                        this.state.sgstAmount)
                    ).toFixed(2)}
                  </strong>
                </div>
              </div>
              <div className="yourProdctDivName">
                <div className="yourPrdNameCheckLft">{strings.CouponCode}:</div>
                <div className="yourPrdNameCheckRgt">
                  <input
                    type="text"
                    name="coupon_code"
                    value={this.state.coupneVoucher}
                    onChange={this.handleCoupenValue}
                  />
                  {!this.state.isAddedCoupon && (
                    <input
                      type="button"
                      value={strings.ApplyCoupon}
                      className="applyCoupen"
                      onClick={this.applyCoupen}
                    />
                  )}
                  {this.state.isAddedCoupon && (
                    <input
                      type="button"
                      value={strings.RemoveCoupon}
                      className="applyCoupen"
                      onClick={this.removeCopenCode}
                    />
                  )}
                </div>
                {this.state.couponError.length > 0 && (
                  <div className="cuponErrorMessage">
                    {this.state.couponError}
                  </div>
                )}
                {this.state.cuponMessageSuccess.length > 0 && (
                  <div className="cuponSuccessMessage">
                    {this.state.cuponMessageSuccess}
                  </div>
                )}
                {this.state.cuponMessage.length > 0 && (
                  <div className="cuponErrorMessage">
                    {this.state.cuponMessage}
                  </div>
                )}
              </div>
              <div className="paymentMode"></div>
              {isAutoRenewalVisible &&
                <Row className="billFrmInputRgt m-0" >
                  <Col className="autoRenewalColumn col-md-12">
                    <div className="billCheckBx billFrmInputRgt pl-3 mt-0 ml-3">
                      <label className="checkbox pl-1">
                        Auto Renewal &nbsp;
                        <input
                          defaultChecked={this.state.isAutoRenewalOn}
                          type="checkbox"
                          onChange={this.checkAutoRenewal}
                        />
                        <span className="checkmarkbx"></span>
                      </label>
                    </div>
                  </Col>
                </Row>
              }
              <div className="paypmentTerms">
                <label className="checkbox">
                  {strings.ReadAndAcceptThe}
                  <input
                    type="checkbox"
                    name="acceptTerm"
                    defaultChecked={this.state.acceptTerm}
                    onChange={(event) => {
                      this.setState({ acceptTerm: event.target.checked });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <a href="/terms-of-service" title="Terms Of Service">
                  {strings.termsAndConditions}*{" "}
                </a>
              </div>
              {this.state.acceptTermError &&
                <div className="error__wrapper">
                  <div className="alert alert-danger loginErrorMsg">{this.state.acceptTermError}</div>
                </div>
              }
              {this.state.cartDetailsProducts.lines === undefined ||
                this.state.cartDetailsProducts.lines.length == 0 ? (
                <div className="placeOrder placeOrderDisable">
                  <input type="submit" value={strings.PlaceOrder} />
                </div>
              ) : (
                <div className="placeOrder">
                  <input
                    type="submit"
                    value={strings.PlaceOrder}
                    onClick={this.placeProductsOrder}
                  />
                </div>
              )}
            </div>
          </Form>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    authData: state.authonticationDetails,
    isIndian: state.isReisteredDevice.isIndian,
  };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(checkout));
