export default function modelSelectedFunction (state = {selectedModel:false}, action) {
    switch (action.type) {
        case 'MODEL_SELECTED': {
            return { ...state, selectedModel: action.payload };
        }
        case 'CART_MODEL_SELECTED_FALSE': {
            return { ...state, selectedModel: action.payload };
        }
        default: {
            return { ...state };
        }
    }
}