const initialState = {
    languageLabCategoryList: [],
}
export default function languageLabCategoryReducer (state = initialState, action) {
    switch (action.type) {
        case 'LANGUAGE_LAB_CATEGORY_LIST': {
            return { ...state, languageLabCategoryList: action.payload };
        }
        case 'FAILED_LANGUAGE_LAB_CATEGORY_LIST': {
            return { ...state, languageLabCategoryList:[] };
        }
        default: {
            return { ...state };
        }
    }
}