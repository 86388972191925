import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, NavLink, Table, Button } from "reactstrap";
import {
  api_base_url,
  encryptDecryptKey,
  subscriptionDefaultImage,
} from "../../config";
import axios from "axios";
import LocalizedStrings from "react-localization";
import SimpleCrypto from "simple-crypto-js";
import { orderProductAction } from "../../action/orderAction";
import languages from "../../language/language.json";
var strings = new LocalizedStrings(languages);

class myOrderData extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(this.props.selectedLanguage);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    this.state = {
      token: token || "",
      orderList: [],
      monthShortName: {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sept",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      },
      isIndian: null,
      currcountry: "",
    };
  }


  getuserCountry = async () => {

    try {
      let getcountrycode = await localStorage.getItem("currentcountrycode")

      await this.setState({ currcountry: getcountrycode })


      // alert(this.state.currcountry)
    }
    catch (e) {

    }
  }



  componentDidMount() {

    window.scrollTo(0, 0);
    document.addEventListener("scroll", this.trackScrolling);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isIndian !== null && nextProps.isIndian !== undefined) {
      this.setState({ isIndian: nextProps.isIndian });
    }
    this.getuserCountry()
    if (nextProps.isStartMyOrdersScrollEventListener === true) {
      document.addEventListener("scroll", this.trackScrolling);
    } else {
      document.removeEventListener("scroll", this.trackScrolling);
    }
    var selectedLanguage = nextProps.selectedLanguage;
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    }
  }







  numberSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  subscriptionDateString(dateString) {
    var dateStringArray = dateString.split("-");
    var newYear = dateStringArray[0];
    var newMonth = parseInt(dateStringArray[1]) + "";
    var newDay = parseInt(dateStringArray[2]);
    var newString =
      this.numberSuffix(newDay) +
      " " +
      this.state.monthShortName[newMonth] +
      ", " +
      newYear;
    return newString;
  }
  renderSwitch(eachOrder) {
    var giftDetails = "";
    if (eachOrder.gift_details) {
      giftDetails =
        "<br/>" +
        eachOrder.gift_details.first_name +
        " " +
        eachOrder.gift_details.last_name +
        " (" +
        eachOrder.gift_details.email +
        ")";
    }
    if (eachOrder.gift_order === true) {
      return strings.Gift + giftDetails;
    } else if (eachOrder.is_subscribed === true) {
      return strings.productSubscribed;
    } else {
      return strings.ProductText;
    }
  }
  isBottom(el) {
    if (this.props.orderList.length > 0) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
  }


  componentWillUnmount() {
    if (this.props.orderList.length > 0) {
      document.removeEventListener("scroll", this.trackScrolling);
    }
  }
  trackScrolling = () => {
    const wrappedElement = document.getElementById("scrollApiContentForOrder");
    if (this.isBottom(wrappedElement)) {
      document.removeEventListener("scroll", this.trackScrolling);
      this.props.changeOffsetOrder();
    }
  };
  rePaymentOrder(orderID) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      order_number: orderID,
    };
    axios
      .post(api_base_url + "/api/v1/order-new/regenerate-order/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          var orderToken = response.data.new_order_token;
          var headers = {
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          };
          var apiParams = {
            token: orderToken,
          };
          if (this.props.authData.userCountry === "IN") {
            axios
              .post(api_base_url + "/api/v1/payu-checkout/", apiParams, {
                headers: headers,
              })
              .then((response) => {
                if (response.data == "cc_avenue") {
                  window.open(api_base_url + '/order/ccavenue-pay/' + orderToken, '_self', 'top=10', 'replace=false');
                } else {
                  this.props.history.push({
                    pathname: "/proceed-payment",
                    state: { detail: response.data, isPayAutoRenewal: false },
                  });
                }
              })
              .catch((err) => {
                // console.log("fetched.", err);
              });
          } else {
            axios
              .post(api_base_url + "/api/v1/paypal-checkout/", apiParams, {
                headers: headers,
              })
              .then((response) => {
                this.props.history.push({
                  pathname: "/proceed-payment",
                  state: { detail: response.data, isPayAutoRenewal: false },
                });
              })
              .catch((err) => {
                // console.log("fetched.", err);
              });
          }
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  render() {
    return (
      <div>
        {this.props.orderList.length > 0 ? (
          <div className="orderWRapper" id="scrollApiContentForOrder">
            <Table className="" responsive={true}>
              <thead>
                <tr>
                  <th className="orders-table__header">
                    <span className="nobr">{strings.orderId}</span>
                  </th>
                  <th className="orders-table__header imageHeader">
                    <span className="nobr">{strings.imageText}</span>
                  </th>
                  <th className="orders-table__header">
                    <span className="nobr">{strings.title}</span>
                  </th>
                  <th className="orders-table__header">
                    <span className="nobr">{strings.statusText}</span>
                  </th>
                  <th className="orders-table__header">
                    <span className="nobr">{strings.datePurchased}</span>
                  </th>
                  <th className="orders-table__header">
                    <span className="nobr">{strings.priceText}</span>
                  </th>
                  <th className="orders-table__header">
                    <span className="nobr">{strings.purchasedType}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.orderList.map((eachOrder, selectedIndex) => {
                  if (eachOrder && eachOrder.product_details) {
                    return eachOrder.product_details.map((oderProduct, index) => {
                      return (
                        <tr className="">
                          <td className="">{eachOrder.id}</td>
                          <td className="">
                            <div className="orderImage">
                              {" "}
                              {oderProduct.images.length > 0 ? (
                                <img src={oderProduct.images[0].image1} />
                              ) : oderProduct.product_type ==
                                "subscription_product" ? (
                                <img
                                  src={subscriptionDefaultImage}
                                  alt="video"
                                  className="popularImage"
                                />
                              ) : (
                                <img
                                  src={"/images/default.jpg"}
                                  alt="video"
                                  className="popularImage"
                                />
                              )}{" "}
                            </div>
                          </td>
                          <td className="">
                            {
                              oderProduct.custom_translation[
                                this.props.selectedLanguage
                              ].name
                            }
                          </td>
                          <td className="">
                            {strings[eachOrder.status]}
                            <br />
                            {(eachOrder.status === "canceled" ||
                              eachOrder.status === "failed") && (
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    this.rePaymentOrder(eachOrder.id);
                                  }}
                                >
                                  Re-payment
                                </Button>
                              )}
                          </td>
                          <td className="">
                            {this.subscriptionDateString(eachOrder.created)}
                          </td>
                          {/* {console.log("isIndian---", this.state.currcountry)} */}
                          <td className="">
                            {this.state.isIndian !== null ?
                              <Fragment>

                                {this.state.currcountry == "IN" ? strings.priceSign + "" + eachOrder.total_gross_inr.amount
                                  : "$" + eachOrder.total_gross.amount}
                              </Fragment>
                              :
                              <Fragment>
                                {this.state.currcountry == "IN"
                                  ? strings.priceSign + " " + eachOrder.total_gross_inr.amount
                                  : "$" + eachOrder.total_gross.amount}
                              </Fragment>
                            }
                          </td>
                          <td
                            className=""
                            dangerouslySetInnerHTML={{
                              __html: this.renderSwitch(eachOrder),
                            }}
                          ></td>
                        </tr>
                      );
                    });
                  }
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="orderWRapper">
            <span>{strings.OrdersNotFound}</span>
          </div>
        )}
      </div>
    );
  }
}

function mapStatesToProps(globalData) {
  return {
    userSpecificOrderData: globalData.userSpecificOrderData.allOrderData,
    authData: globalData.authonticationDetails,
    isIndian: globalData.isReisteredDevice.isIndian,
  };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(myOrderData));
