import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import axios from "axios";
import {
  api_base_url,
  encryptDecryptKey,
  googleAnalyticsTrackingId,
  searchAPIToken,
} from "../../config";
import { Row, Col, NavLink } from "reactstrap";
import { Route, Link, withRouter } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import { connect } from "react-redux";
import StarRatings from "react-star-ratings";
import { browseCategoryProductDetailsDataAction } from "../../action/browseCategoryAction";
import LocalizedStrings from "react-localization";
import languages from "../../language/language.json";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

class searchResult extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    var userEmail = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }

    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    let name = this.props.match.params.searchText;
    this.state = {
      searchResult: name || "",
      searchResultDataAudioBook: [],
      searchResultDataPodcast: [],
      searchKidsResultProducts: [],
      searchLanguageLabResultProducts: [],
      searchResultDataSnovelOriginal: [],
      topSearchResult: [],
      token: token || "",
      selectedLanguage: selectedLanguage || "EN",
      totalProductCount: {},
      isLoading: false,
      searchApiToken: token || searchAPIToken,
      isIndian: null,
    };

    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.searchResultResponse(this.state.searchResult);
    this.unlisten = this.props.history.listen((location, action) => {
      var pathName = location.pathname;
      var lastRoute = this.props.location.pathname;
      if (
        pathName.indexOf("search") > -1 &&
        lastRoute.indexOf("search") > -1 &&
        pathName != lastRoute
      ) {
        var newArray = pathName.split("/");
        var searchText = newArray[2];
        if (searchText) {
          this.setState({
            searchResultDataAudioBook: [],
            searchResultDataPodcast: [],
            searchResultDataSnovelOriginal: [],
            searchKidsResultProducts: [],
            searchLanguageLabResultProducts: [],
            topSearchResult: [],
            totalProductCount: {},
            searchResult: searchText,
          });
          this.searchResultResponse(searchText);
        }
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  searchResultResponse(searchText) {
    this.setState({ isLoading: true });
    var headers = {
      "Content-Type": "application/json",
      "search-api-token": this.state.searchApiToken,
    };
    axios
      .get(api_base_url + "/api/v2/search/?q=" + searchText, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            searchResultDataPodcast: response.data.podcast || [],
            searchResultDataAudioBook: response.data.audiobook || [],
            searchResultDataSnovelOriginal: response.data.snoveloriginal || [],
            totalProductCount: response.data.total_product_count || {},
            topSearchResult: response.data.top_products || [],
            searchKidsResultProducts: response.data.kids || [],
            searchLanguageLabResultProducts: response.data['language-lab'] || [],
          });
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  /**********  Author detail page link **************/
  authorDetailsId(authorID, authorName) {
    var titleUrl = authorName.toLowerCase();
    var authorTitle = titleUrl.replace(".", "");
    var authorName = authorTitle.replace(" ", "-");
    this.props.history.push("/author/" + authorID + "/" + authorName);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isIndian !== null && nextProps.isIndian !== undefined) {
      this.setState({ isIndian: nextProps.isIndian });
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  /****************  Cart model *************/
  cartProductsModal(variantId) {
    if (this.props.authData.loginSuccess) {
      var apiParams = {
        email: "",
        discount_amount: 0.0,
        discount_amount_inr: 0.0,
        gift_cart: false,
        cart_lines: [
          {
            variant: variantId,
            quantity: 1,
            data: {},
          },
        ],
      };

      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .post(api_base_url + "/api/cart/", apiParams, { headers: headers })
        .then((response) => {
          if (response.data.status === true) {
            this.props.dispatch({
              type: "PRODUCT_CART_COUNT",
              payload: response.data.data.quantity,
            });
            this.props.dispatch({ type: "MODEL_SELECTED", payload: true });
          }
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          // console.log("fetched.", err);
        });
    } else {
      this.props.history.push("/sign-in");
    }
  }
  /**************** Author selection *************/
  authorsSelected(authorsList) {
    var authorArray = [];
    authorsList.map((authorName) => {
      if (
        authorName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        authorArray.push(
          authorName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return authorArray.toString();
  }
  /**************** Product detail page *************/
  productDetailsId(productID, slug) {
    localStorage.setItem("productId", productID);
    this.props.history.push("/product-details/" + productID + "/" + slug);
  }
  /*********  Add product to Library **********/
  addToLibraryProduct(productID) {
    var apiParams = {
      product: productID,
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/add-to-library/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          this.props.history.push("/library");
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  /*********** Search result for podcast *************/
  searchProductsPodcast() {
    return this.state.searchResultDataPodcast.map((product, index) => {
      return (
        <div class="searchAdvRgtDivInnerWrp" key={index - 1}>
          <div class="searchAdvRgtDiv">
            {product.images.length > 0 ? (
              <div class="searchAdvRgtDivImg">
                {product.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                <img
                  src={product.images[0].image2}
                  alt=""
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                />
              </div>
            ) : (
              <div class="searchAdvRgtDivImg">
                {product.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                <img
                  src="/images/default.jpg"
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                />
              </div>
            )}
            <div class="searchAdvRgtDivCnt">
              {product.custom_translation[this.state.selectedLanguage] !==
                undefined && (
                  <h2
                    onClick={() => {
                      this.productDetailsId(product.id, product.product_slug);
                    }}
                  >
                    {product.custom_translation[this.state.selectedLanguage].name}
                  </h2>
                )}
              <div class="searchAuthour">
                {strings.authorText}:{" "}
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.authorDetailsId(
                      product.authors[0].id,
                      product.authors[0].name
                    );
                  }}
                >
                  {this.authorsSelected(product.authors)}
                </a>
              </div>
              <div class="searchCateAdv">
                {product.category.map((categoryList, index) => {
                  if (
                    categoryList.custom_translation[
                    this.state.selectedLanguage
                    ] !== undefined
                  ) {
                    return (
                      <div className="searchCateAdvName">
                        <NavLink
                          tag={Link}
                          to={{
                            pathname: "/browse",
                          }}
                          onClick={() => {
                            this.props.dispatch({
                              type: "MENU_SELECTED_CATEGORY",
                              payload: [
                                categoryList.name,
                                product.product_audio_typ,
                                categoryList.id,
                              ],
                            });
                            this.props.dispatch(
                              browseCategoryProductDetailsDataAction(
                                product.product_audio_type,
                                categoryList.name,
                                1
                              )
                            );
                          }}
                          key={index}
                        >
                          <span key={index}>
                            {categoryList.custom_translation[
                              this.state.selectedLanguage
                            ].name.toString()}
                          </span>
                        </NavLink>
                        {index + 1 != product.category.length && (
                          <span> | </span>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
              <div class="searchRead">
                <a
                  href="javascript:;"
                  title=""
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                >
                  {strings.readMoreText}
                </a>
              </div>
              <div class="searchStar">
                <div class="starRating">
                  <StarRatings
                    rating={product.total_reviews}
                    starRatedColor="#ff8c00"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="searchPrice">
            {!product.is_free && (
              <div class="searchPricDiv">
                {this.state.isIndian !== null ?
                  <Fragment>
                    {this.state.isIndian ? strings.priceSign + "" + product.inr_price.amount
                      : "$" + product.price.amount}
                  </Fragment>
                  :
                  <Fragment>
                    {this.props.authData.userCountry === "IN"
                      ? strings.priceSign + " " + product.inr_price.amount
                      : "$" + product.price.amount}
                  </Fragment>
                }
              </div>
            )}
            {this.props.authData.subscribeStatus === false ||
              this.props.authData.subscribeStatus == "" ? (
              <div>
                <div class="searchPricDivLink">
                  <a href="/subscribe" title="">
                    {strings.subscribe}
                  </a>
                </div>
                {product.is_purchased === true || product.is_free === true ? (
                  <div class="searchPricDivLink">
                    <a href="/library">{strings.goToLibrary}</a>
                  </div>
                ) : (
                  <div class="searchPricDivLink">
                    <a
                      href="javascript:;"
                      title=""
                      onClick={() => {
                        this.cartProductsModal(product.variant);
                      }}
                    >
                      {strings.purchaseTitle}
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div class="searchPricDivLink">
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.addToLibraryProduct(product.id);
                    }}
                    title=""
                  >
                    {strings.addToLibrary}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });
  }
  /*********  Search result for snovel original ************/
  searchProductsSnovelOriginal() {
    return this.state.searchResultDataSnovelOriginal.map((product, index) => {
      return (
        <div class="searchAdvRgtDivInnerWrp" key={index - 1}>
          <div class="searchAdvRgtDiv">
            {product.images.length > 0 ? (
              <div class="searchAdvRgtDivImg">
                {product.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                <img
                  src={product.images[0].image2}
                  alt=""
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                />
              </div>
            ) : (
              <div class="searchAdvRgtDivImg">
                {product.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                <img
                  src="/images/default.jpg"
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                />
              </div>
            )}
            <div class="searchAdvRgtDivCnt">
              {product.custom_translation[this.state.selectedLanguage] !==
                undefined && (
                  <h2
                    onClick={() => {
                      this.productDetailsId(product.id, product.product_slug);
                    }}
                  >
                    {product.custom_translation[this.state.selectedLanguage].name}
                  </h2>
                )}
              <div class="searchAuthour">
                {strings.authorText}:{" "}
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.authorDetailsId(
                      product.authors[0].id,
                      product.authors[0].name
                    );
                  }}
                >
                  {this.authorsSelected(product.authors)}
                </a>
              </div>
              <div class="searchCateAdv">
                {product.category.map((categoryList, index) => {
                  if (
                    categoryList.custom_translation[
                    this.state.selectedLanguage
                    ] !== undefined
                  ) {
                    return (
                      <div className="searchCateAdvName">
                        <NavLink
                          tag={Link}
                          to={{
                            pathname: "/browse",
                          }}
                          onClick={() => {
                            this.props.dispatch({
                              type: "MENU_SELECTED_CATEGORY",
                              payload: [
                                categoryList.name,
                                product.product_audio_type,
                                categoryList.id,
                              ],
                            });
                            this.props.dispatch(
                              browseCategoryProductDetailsDataAction(
                                product.product_audio_type,
                                categoryList.name,
                                1
                              )
                            );
                          }}
                          key={index}
                        >
                          <span key={index}>
                            {categoryList.custom_translation[
                              this.state.selectedLanguage
                            ].name.toString()}
                          </span>
                        </NavLink>
                        {index + 1 != product.category.length && (
                          <span> | </span>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
              <div class="searchRead">
                <a
                  href="javascript:;"
                  title=""
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                >
                  {strings.readMoreText}
                </a>
              </div>
              <div class="searchStar">
                <div class="starRating">
                  <StarRatings
                    rating={product.total_reviews}
                    starRatedColor="#ff8c00"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="searchPrice">
            {!product.is_free && (
              <div class="searchPricDiv">
                {this.state.isIndian !== null ?
                  <Fragment>
                    {this.state.isIndian ? strings.priceSign + "" + product.inr_price.amount
                      : "$" + product.price.amount}
                  </Fragment>
                  :
                  <Fragment>
                    {this.props.authData.userCountry === "IN"
                      ? strings.priceSign + " " + product.inr_price.amount
                      : "$" + product.price.amount}
                  </Fragment>
                }
              </div>
            )}
            {this.props.authData.subscribeStatus === false ||
              this.props.authData.subscribeStatus == "" ? (
              <div>
                <div class="searchPricDivLink">
                  <a href="/subscribe" title="">
                    {strings.subscribe}
                  </a>
                </div>
                {product.is_purchased === true || product.is_free === true ? (
                  <div class="searchPricDivLink">
                    <a href="/library">{strings.goToLibrary}</a>
                  </div>
                ) : (
                  <div class="searchPricDivLink">
                    <a
                      href="javascript:;"
                      title=""
                      onClick={() => {
                        this.cartProductsModal(product.variant);
                      }}
                    >
                      {strings.purchaseTitle}
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div class="searchPricDivLink">
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.addToLibraryProduct(product.id);
                  }}
                  title=""
                >
                  {strings.addToLibrary}
                </a>
              </div>
            )}
          </div>
        </div>
      );
    });
  }
  /*********  Search result for Audio Book **********/
  searchProductsAudioBook() {
    return this.state.searchResultDataAudioBook.map((product, index) => {
      return (
        <div class="searchAdvRgtDivInnerWrp" key={index - 1}>
          <div class="searchAdvRgtDiv">
            {product.images.length > 0 ? (
              <div class="searchAdvRgtDivImg">
                {product.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                <img
                  src={product.images[0].image2}
                  alt=""
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                />
              </div>
            ) : (
              <div class="searchAdvRgtDivImg">
                {product.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                <img
                  src="/images/default.jpg"
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                />
              </div>
            )}
            <div class="searchAdvRgtDivCnt">
              {product.custom_translation[this.state.selectedLanguage] !==
                undefined && (
                  <h2
                    onClick={() => {
                      this.productDetailsId(product.id, product.product_slug);
                    }}
                  >
                    {product.custom_translation[this.state.selectedLanguage].name}
                  </h2>
                )}
              <div class="searchAuthour">
                {strings.authorText}:{" "}
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.authorDetailsId(
                      product.authors[0].id,
                      product.authors[0].name
                    );
                  }}
                >
                  {this.authorsSelected(product.authors)}
                </a>
              </div>
              <div class="searchCateAdv">
                {product.category.map((categoryList, index) => {
                  if (
                    categoryList.custom_translation[
                    this.state.selectedLanguage
                    ] !== undefined
                  ) {
                    return (
                      <div className="searchCateAdvName">
                        <NavLink
                          tag={Link}
                          to={{
                            pathname: "/browse",
                          }}
                          onClick={() => {
                            this.props.dispatch({
                              type: "MENU_SELECTED_CATEGORY",
                              payload: [
                                categoryList.name,
                                product.product_audio_type,
                                categoryList.id,
                              ],
                            });
                            this.props.dispatch(
                              browseCategoryProductDetailsDataAction(
                                product.product_audio_type,
                                categoryList.name,
                                1
                              )
                            );
                          }}
                          key={index}
                        >
                          <span key={index}>
                            {categoryList.custom_translation[
                              this.state.selectedLanguage
                            ].name.toString()}
                          </span>
                        </NavLink>
                        {index + 1 != product.category.length && (
                          <span> | </span>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
              <div class="searchRead">
                <a
                  href="javascript:;"
                  title=""
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                >
                  {strings.readMoreText}
                </a>
              </div>
              <div class="searchStar">
                <div class="starRating">
                  <StarRatings
                    rating={product.total_reviews}
                    starRatedColor="#ff8c00"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="searchPrice">
            {product.is_free === false ? (
              <div class="searchPricDiv">
                {this.state.isIndian !== null ?
                  <Fragment>
                    {this.state.isIndian ? strings.priceSign + "" + product.inr_price.amount
                      : "$" + product.price.amount}
                  </Fragment>
                  :
                  <Fragment>
                    {this.props.authData.userCountry === "IN"
                      ? strings.priceSign + " " + product.inr_price.amount
                      : "$" + product.price.amount}
                  </Fragment>
                }
              </div>
            ) : (
              <div class="searchPricDiv">{strings.FreeText}</div>
            )}
            {this.props.authData.subscribeStatus === false ||
              this.props.authData.subscribeStatus == "" ? (
              <div>
                <div class="searchPricDivLink">
                  <a href="/subscribe" title="">
                    {strings.subscribe}
                  </a>
                </div>
                {product.is_purchased === true || product.is_free === true ? (
                  <div class="searchPricDivLink">
                    <a href="/library">{strings.goToLibrary}</a>
                  </div>
                ) : (
                  <div class="searchPricDivLink">
                    <a
                      href="javascript:;"
                      title=""
                      onClick={() => {
                        this.cartProductsModal(product.variant);
                      }}
                    >
                      {strings.purchaseTitle}
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div class="searchPricDivLink">
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.addToLibraryProduct(product.id);
                  }}
                  title=""
                >
                  {strings.addToLibrary}
                </a>
              </div>
            )}
          </div>
        </div>
      );
    });
  }
  /*********  Search result for Kids Products **********/
  searchKidsResultProducts() {
    return this.state.searchKidsResultProducts.map((product, index) => {
      return (
        <div class="searchAdvRgtDivInnerWrp" key={index - 1}>
          <div class="searchAdvRgtDiv">
            {product.images.length > 0 ? (
              <div class="searchAdvRgtDivImg">
                {product.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                <img
                  src={product.images[0].image2}
                  alt=""
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                />
              </div>
            ) : (
              <div class="searchAdvRgtDivImg">
                {product.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                <img
                  src="/images/default.jpg"
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                />
              </div>
            )}
            <div class="searchAdvRgtDivCnt">
              {product.custom_translation[this.state.selectedLanguage] !==
                undefined && (
                  <h2
                    onClick={() => {
                      this.productDetailsId(product.id, product.product_slug);
                    }}
                  >
                    {product.custom_translation[this.state.selectedLanguage].name}
                  </h2>
                )}
              <div class="searchAuthour">
                {strings.authorText}:{" "}
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.authorDetailsId(
                      product.authors[0].id,
                      product.authors[0].name
                    );
                  }}
                >
                  {this.authorsSelected(product.authors)}
                </a>
              </div>
              <div class="searchCateAdv">
                {product.category.map((categoryList, index) => {
                  if (
                    categoryList.custom_translation[
                    this.state.selectedLanguage
                    ] !== undefined
                  ) {
                    return (
                      <div className="searchCateAdvName">
                        <NavLink
                          tag={Link}
                          to={{
                            pathname: "/browse",
                          }}
                          onClick={() => {
                            this.props.dispatch({
                              type: "MENU_SELECTED_CATEGORY",
                              payload: [
                                categoryList.name,
                                product.product_audio_type,
                                categoryList.id,
                              ],
                            });
                            this.props.dispatch(
                              browseCategoryProductDetailsDataAction(
                                product.product_audio_type,
                                categoryList.name,
                                1
                              )
                            );
                          }}
                          key={index}
                        >
                          <span key={index}>
                            {categoryList.custom_translation[
                              this.state.selectedLanguage
                            ].name.toString()}
                          </span>
                        </NavLink>
                        {index + 1 != product.category.length && (
                          <span> | </span>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
              <div class="searchRead">
                <a
                  href="javascript:;"
                  title=""
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                >
                  {strings.readMoreText}
                </a>
              </div>
              <div class="searchStar">
                <div class="starRating">
                  <StarRatings
                    rating={product.total_reviews}
                    starRatedColor="#ff8c00"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="searchPrice">
            {product.is_free === false ? (
              <div class="searchPricDiv">
                {this.state.isIndian !== null ?
                  <Fragment>
                    {this.state.isIndian ? strings.priceSign + "" + product.inr_price.amount
                      : "$" + product.price.amount}
                  </Fragment>
                  :
                  <Fragment>
                    {this.props.authData.userCountry === "IN"
                      ? strings.priceSign + " " + product.inr_price.amount
                      : "$" + product.price.amount}
                  </Fragment>
                }
              </div>
            ) : (
              <div class="searchPricDiv">{strings.FreeText}</div>
            )}
            {this.props.authData.subscribeStatus === false ||
              this.props.authData.subscribeStatus == "" ? (
              <div>
                <div class="searchPricDivLink">
                  <a href="/subscribe" title="">
                    {strings.subscribe}
                  </a>
                </div>
                {product.is_purchased === true || product.is_free === true ? (
                  <div class="searchPricDivLink">
                    <a href="/library">{strings.goToLibrary}</a>
                  </div>
                ) : (
                  <div class="searchPricDivLink">
                    <a
                      href="javascript:;"
                      title=""
                      onClick={() => {
                        this.cartProductsModal(product.variant);
                      }}
                    >
                      {strings.purchaseTitle}
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div class="searchPricDivLink">
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.addToLibraryProduct(product.id);
                  }}
                  title=""
                >
                  {strings.addToLibrary}
                </a>
              </div>
            )}
          </div>
        </div>
      );
    });
  }
  /*********  Search result for Language Lab Products **********/
  searchLanguageLabResultProducts() {
    return this.state.searchLanguageLabResultProducts.map((product, index) => {
      return (
        <div class="searchAdvRgtDivInnerWrp" key={index - 1}>
          <div class="searchAdvRgtDiv">
            {product.images.length > 0 ? (
              <div class="searchAdvRgtDivImg">
                {product.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                <img
                  src={product.images[0].image2}
                  alt=""
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                />
              </div>
            ) : (
              <div class="searchAdvRgtDivImg">
                {product.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                <img
                  src="/images/default.jpg"
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                />
              </div>
            )}
            <div class="searchAdvRgtDivCnt">
              {product.custom_translation[this.state.selectedLanguage] !==
                undefined && (
                  <h2
                    onClick={() => {
                      this.productDetailsId(product.id, product.product_slug);
                    }}
                  >
                    {product.custom_translation[this.state.selectedLanguage].name}
                  </h2>
                )}
              <div class="searchAuthour">
                {strings.authorText}:{" "}
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.authorDetailsId(
                      product.authors[0].id,
                      product.authors[0].name
                    );
                  }}
                >
                  {this.authorsSelected(product.authors)}
                </a>
              </div>
              <div class="searchCateAdv">
                {product.category.map((categoryList, index) => {
                  if (
                    categoryList.custom_translation[
                    this.state.selectedLanguage
                    ] !== undefined
                  ) {
                    return (
                      <div className="searchCateAdvName">
                        <NavLink
                          tag={Link}
                          to={{
                            pathname: "/browse",
                          }}
                          onClick={() => {
                            this.props.dispatch({
                              type: "MENU_SELECTED_CATEGORY",
                              payload: [
                                categoryList.name,
                                product.product_audio_type,
                                categoryList.id,
                              ],
                            });
                            this.props.dispatch(
                              browseCategoryProductDetailsDataAction(
                                product.product_audio_type,
                                categoryList.name,
                                1
                              )
                            );
                          }}
                          key={index}
                        >
                          <span key={index}>
                            {categoryList.custom_translation[
                              this.state.selectedLanguage
                            ].name.toString()}
                          </span>
                        </NavLink>
                        {index + 1 != product.category.length && (
                          <span> | </span>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
              <div class="searchRead">
                <a
                  href="javascript:;"
                  title=""
                  onClick={() => {
                    this.productDetailsId(product.id, product.product_slug);
                  }}
                >
                  {strings.readMoreText}
                </a>
              </div>
              <div class="searchStar">
                <div class="starRating">
                  <StarRatings
                    rating={product.total_reviews}
                    starRatedColor="#ff8c00"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="searchPrice">
            {product.is_free === false ? (
              <div class="searchPricDiv">
                {this.state.isIndian !== null ?
                  <Fragment>
                    {this.state.isIndian ? strings.priceSign + "" + product.inr_price.amount
                      : "$" + product.price.amount}
                  </Fragment>
                  :
                  <Fragment>
                    {this.props.authData.userCountry === "IN"
                      ? strings.priceSign + " " + product.inr_price.amount
                      : "$" + product.price.amount}
                  </Fragment>
                }
              </div>
            ) : (
              <div class="searchPricDiv">{strings.FreeText}</div>
            )}
            {this.props.authData.subscribeStatus === false ||
              this.props.authData.subscribeStatus == "" ? (
              <div>
                <div class="searchPricDivLink">
                  <a href="/subscribe" title="">
                    {strings.subscribe}
                  </a>
                </div>
                {product.is_purchased === true || product.is_free === true ? (
                  <div class="searchPricDivLink">
                    <a href="/library">{strings.goToLibrary}</a>
                  </div>
                ) : (
                  <div class="searchPricDivLink">
                    <a
                      href="javascript:;"
                      title=""
                      onClick={() => {
                        this.cartProductsModal(product.variant);
                      }}
                    >
                      {strings.purchaseTitle}
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div class="searchPricDivLink">
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.addToLibraryProduct(product.id);
                  }}
                  title=""
                >
                  {strings.addToLibrary}
                </a>
              </div>
            )}
          </div>
        </div>
      );
    });
  }
  /*********  Search result top products ***************/
  topSearchProducts() {
    return this.state.topSearchResult.map((topProduct, index) => {
      return (
        <div className="advBtmDiv">
          <div className="advBtmImgDiv">
            {topProduct.images.length > 0 ? (
              <img
                src={topProduct.images[0].image1}
                alt=""
                onClick={() => {
                  this.productDetailsId(topProduct.id, topProduct.product_slug);
                }}
              />
            ) : (
              <img
                src="/images/default.jpg"
                onClick={() => {
                  this.productDetailsId(topProduct.id, topProduct.product_slug);
                }}
              />
            )}
          </div>
          <div className="advBtmCntDiv">
            {topProduct.custom_translation[this.state.selectedLanguage] !==
              undefined && (
                <h4
                  onClick={() => {
                    this.productDetailsId(topProduct.id, topProduct.product_slug);
                  }}
                >
                  {
                    topProduct.custom_translation[this.state.selectedLanguage]
                      .name
                  }
                </h4>
              )}
            <div className="starRating">
              <StarRatings
                rating={topProduct.total_reviews}
                starRatedColor="#ff8c00"
                numberOfStars={5}
                name="rating"
                starDimension="20px"
              />
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="container">
        {this.state.isLoading ? (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        ) : null}
        <h3 className="search-resultFor">
          Search result for: {this.state.searchResult}
        </h3>
        {this.state.totalProductCount.audiobook !== 0 ||
          this.state.totalProductCount.snoveloriginal !== 0 ||
          this.state.totalProductCount.kids !== 0 ||
          this.state.totalProductCount['language-lab'] !== 0 ||
          this.state.totalProductCount.podcast !== 0 ? (
          <Row>
            <Col sm="3">
              <div className="searchLftdivWrp">
                <div className="searchLftdiv">
                  <div className="serachLftImg">
                    <img src="images/voice-artist-icon.png" alt="" />
                    <span>{strings.kidsMenu}</span>
                  </div>
                  <div className="serachCount">
                    <span>{this.state.totalProductCount.kids}</span>
                  </div>
                </div>
                {/* <div className="searchLftdiv">
                  <div className="serachLftImg">
                    <img src="images/ico_SnovelOriginals-Normal.png" alt="" />
                    <span>{strings.languageLab}</span>
                  </div>
                  <div className="serachCount">
                    <span>{this.state.totalProductCount['language-lab']}</span>
                  </div>
                </div> */}
                <div className="searchLftdiv">
                  <div className="serachLftImg">
                    <img src="images/ico_AudioBook-Normal.png" alt="" />
                    <span>{strings.audiobook}</span>
                  </div>
                  <div className="serachCount">
                    <span>{this.state.totalProductCount.audiobook}</span>
                  </div>
                </div>
                <div className="searchLftdiv">
                  <div className="serachLftImg">
                    <img src="images/ico_SnovelOriginals-Normal.png" alt="" />
                    <span>{strings.snoveloriginal}</span>
                  </div>
                  <div className="serachCount">
                    <span>{this.state.totalProductCount.snoveloriginal}</span>
                  </div>
                </div>
                <div className="searchLftdiv">
                  <div className="serachLftImg">
                    <img src="images/ico_Podcast-Normal.png" alt="" />
                    <span>{strings.podcast}</span>
                  </div>
                  <div className="serachCount">
                    <span>{this.state.totalProductCount.podcast}</span>
                  </div>
                </div>
              </div>

              <div className="btmSearch">
                <h2>{strings.RelatedTop}</h2>
                {this.topSearchProducts()}
              </div>
            </Col>
            <Col sm="9">
              <div className="searchAdvRgtDivWrp">
                {this.state.searchKidsResultProducts.length > 0 && (
                  <div>
                    <h3>{strings.kidsMenu}</h3>
                    {this.searchKidsResultProducts()}{" "}
                  </div>
                )}
                {/* {this.state.searchLanguageLabResultProducts.length > 0 && (
                  <div>
                    <h3>{strings.languageLab}</h3>
                    {this.searchLanguageLabResultProducts()}{" "}
                  </div>
                )} */}
                {this.state.searchResultDataAudioBook.length > 0 && (
                  <div>
                    <h3>{strings.audiobook}</h3>
                    {this.searchProductsAudioBook()}{" "}
                  </div>
                )}
                {this.state.searchResultDataSnovelOriginal.length > 0 && (
                  <div>
                    <h3>{strings.snoveloriginal}</h3>
                    {this.searchProductsSnovelOriginal()}{" "}
                  </div>
                )}
                {this.state.searchResultDataPodcast.length > 0 && (
                  <div>
                    <h3>{strings.podcast}</h3>
                    {this.searchProductsPodcast()}{" "}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <div className="notFoundResult">{strings.SearchResultNot}</div>
        )}
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    modelSelectedFunction: state.modelSelectedFunction,
    languageTab: state.languageTab,
    authData: state.authonticationDetails,
    isIndian: state.isReisteredDevice.isIndian,
  };
}
const myConnector = connect(mapStatesToProps);
export default myConnector(searchResult);
