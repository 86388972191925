import axios from 'axios';
import { api_base_url } from '../config';

export function userSubscribeProfileDetails(token) {
    return function (dispatch) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + token
        }
        axios.get(api_base_url + '/api/v1/my-profile/', { headers: headers }).then(response => {

            if (response.data.status) {
                dispatch({
                    type: "MY_SUBSCRIPTION_DATA",
                    payload: response.data.data.my_subscription || []
                });
                dispatch({
                    type: "USER_PROFILE_DETAILS",
                    payload: response.data.data.my_profile || []
                });
            } else {
                dispatch({
                    type: "FAILED_USER_PROFILE_DETAILS",
                    payload: response.data.data
                });
            }
        }).catch(err => {
            // console.log('error ', err)
        });
    }
}