const initialState = {
    treandingProducts:[]
}

export default function treandingProductsAction (state = initialState, action) {
    switch (action.type) {
        case 'HOME_TRENDING_PRODUCT': {
            var treandingProductsAction = [];
            treandingProductsAction.push(action.payload);
            return { ...state, treandingProducts:treandingProductsAction };
        }
        default: {
            return { ...state };
        }
    }
}