import React, { Component } from 'react';
import {
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import SimpleCrypto from "simple-crypto-js";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  api_base_url,
  emailReg,
  mobileRegularExpression,
  nameRegularExpression,
  googleRecaptcha,
  countryList,
  allAudioExtensions,
  encryptDecryptKey
} from "../../config";
import LocalizedStrings from "react-localization";
import pdf from "../../pdf/Chintoo-Voice-Audition-Appeal-from-Snovel.pdf";
import { auditionFormAction } from '../../action/AuditionFormAction';
import languages from "../../language/language.json";
var strings = new LocalizedStrings(languages);
var Recaptcha = require("react-recaptcha");

class AuditionFormComponent extends Component {
  constructor(props) {
    super(props);
    var token = "";
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var selectedLanguage = window.localStorage.getItem("language");
    var userDetails = localStorage.getItem("userDetails");
    var userDetailsObject = {};
    if (userDetails) {
      var userDetailsDec = simpleCrypto.decrypt(userDetails);
      userDetailsObject = JSON.parse(userDetailsDec);
    }
    if (userDetailsObject) {
      token = userDetailsObject.token || ""
    }
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      token: token,
      firstname: '',
      lastname: '',
      projectname: '',
      mobile: '',
      city: '',
      homeRecording: '',
      email: '',
      country: '',
      professionalVOA: '',
      dateOfBirth: null,
      gender: '',
      uploadFile: '',
      termAndCondition: '',
      firstNameError: '',
      lastNameError: '',
      cityError: '',
      editGender: '',
      projectnameError: '',
      homeRecordingError: '',
      professionalVOAError: '',
      termAndConditionError: '',
      uploadFileError: '',
      emailError: '',
      successAuditionModal: false,
      genderList: {
        "1": "Male",
        "2": "Female",
        "3": "Other",
      },
      isLoading: false,
      isAgree: false,
      showTermAndCondition: false,
      successMessage: '',
      uploadFileSizeError: '',
      selectedFileName: '',
      isVerified: false,
      googleCaptchaError: '',
      isAuditionModal: true
    }
    this.dobDateChange = this.dobDateChange.bind(this);
    this.fileUploadHandler = this.fileUploadHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.showTermAndCondition = this.showTermAndCondition.bind(this);
    this.closeTermAndCondition = this.closeTermAndCondition.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.expiredCallback = this.expiredCallback.bind(this);
    this.handleCaptcha = this.handleCaptcha.bind(this);
    this.handleAuditionForm = this.handleAuditionForm.bind(this);
    this.closeAuditionModal = this.closeAuditionModal.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(auditionFormAction(this.state.token));
  }
  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.auditionForm !== prevState.auditionForm && Object.keys(nextProps.auditionForm).length > 0) {
      this.setState({
        firstname: nextProps.auditionForm.first_name,
        lastname: nextProps.auditionForm.last_name,
        city: nextProps.auditionForm.city,
        mobile: nextProps.auditionForm.mobile,
        country: nextProps.auditionForm.country,
        gender: nextProps.auditionForm.gender,
        email: nextProps.auditionForm.email
      })
    }
  }
  dobDateChange(newDate) {
    this.setState({
      dateOfBirth: newDate,
    });
  }
  fileUploadHandler(event) {
    if (event.target.files[0].size > 8455756) {
      this.setState({
        uploadFileSizeError: "File size exceeds 10MB",
        uploadFileError: ''
      });
    } else {
      this.setState({
        uploadFile: event.target.files[0],
        uploadFileSizeError: ""
      });
    }

  }
  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  }
  expiredCallback() {
    this.setState({
      isVerified: false,
    });
  }

  selectCountry(val) {
    this.setState({ country: val.target.value });
    if (val.target.value !== "IN") {
      this.setState({
        state: "",
      });
    }
  }

  countryList() {
    return countryList.map((country, index) => {
      return <option value={country.code}>{country.name}</option>;
    });
  }

  showTermAndCondition() {
    this.setState({
      showTermAndCondition: true
    });
  }
  closeTermAndCondition() {
    this.setState({
      showTermAndCondition: !this.state.showTermAndCondition
    });
  }

  submitHandler(event) {
    event.preventDefault();
    this.setState({
      projectnameError: '',
      homeRecordingError: '',
      termAndConditionError: '',
      firstNameError: '',
      lastNameError: '',
      professionalVOAError: '',
      uploadFileError: '',
      emailError: '',
      uploadFileSizeError: '',
      successMessage: '',
      userMobileError: '',
      cityError: '',
      googleCaptchaError: ''
    });
    var isValid = true;
    if (this.state.firstname == "") {
      this.setState({
        firstNameError: strings.thisFieldRequire,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.firstname)) {
      this.setState({
        firstNameError: strings.AcceptAlphabet,
      });
      isValid = false;
    }
    if (this.state.lastname === "") {
      this.setState({
        lastNameError: strings.thisFieldRequire,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.lastname)) {
      this.setState({
        lastNameError: strings.lastNameAlphabet,
      });
      isValid = false;
    }
    if (this.state.city === "") {
      this.setState({
        cityError: strings.thisFieldRequire,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.city)) {
      this.setState({
        cityError: strings.AcceptAlphabetOnly,
      });
      isValid = false;
    }
    if (this.state.mobile === "") {
      this.setState({
        userMobileError: strings.thisFieldRequire,
      });
      isValid = false;
    }
    if (this.state.mobile !== "" && !mobileRegularExpression.test(this.state.mobile)) {
      this.setState({
        userMobileError: strings.mobileError,
      });
      isValid = false;
    }
    if (this.state.projectname === "") {
      this.setState({
        projectnameError: strings.thisFieldRequire,
      });
      isValid = false;
    }
    if (this.state.homeRecording === "") {
      this.setState({
        homeRecordingError: strings.thisFieldRequire,
      });
      isValid = false;
    }
    if (this.state.professionalVOA === "") {
      this.setState({
        professionalVOAError: strings.thisFieldRequire,
      });
      isValid = false;
    }
    if (this.state.email === "") {
      this.setState({
        emailError: strings.thisFieldRequire,
      });
      isValid = false;
    } else if (emailReg.test(this.state.email) === false) {
      this.setState({
        emailError: strings.enterValidEmail,
      });
      isValid = false;
    }
    if (this.state.uploadFile === "") {
      this.setState({
        uploadFileError: strings.thisFieldRequire,
      });
      isValid = false;
    }
    if (isValid) {
      if (!this.state.isVerified) {
        this.setState({
          googleCaptchaError: strings.verifyhuman,
        });
      } else if (!this.state.isAgree) {
        this.setState({
          termAndConditionError: strings.thisFieldRequire
        });
      } else {
        var extension = /[.]/.exec(this.state.uploadFile.name) ? /[^.]+$/.exec(this.state.uploadFile.name) : undefined;
        if (allAudioExtensions.indexOf(extension + "") > -1) {
          var selectedDate = '';
          if (this.state.dateOfBirth != null) {
            var newMonth = this.state.dateOfBirth.getMonth() + 1;
            if (newMonth < 10) {
              newMonth = "0" + newMonth;
            }
            var newDate = this.state.dateOfBirth.getDate();
            if (newDate < 10) {
              newDate = "0" + newDate;
            }
            var newDateString =
              this.state.dateOfBirth.getFullYear() + "-" + newMonth + "-" + newDate;
            selectedDate = newDateString;
          }
          this.setState({
            isLoading: true,
          });
          var headers = {
            "index-api-token": "rb9jB8KSb4WQkHIFZ92r7GAUuVtcNgnQ",
          };

          var headers = {
            'Content-Type': 'application/json',
            'Authorization': "Token " + this.state.token
          };

          const formData = new FormData();
          formData.append("first_name", this.state.firstname);
          formData.append("last_name", this.state.lastname);
          formData.append("project_name", this.state.projectname);
          formData.append("mobile", this.state.mobile);
          formData.append("city", this.state.city);
          formData.append("is_recording_setup", this.state.homeRecording);
          formData.append("email", this.state.email);
          formData.append("country", this.state.country);
          formData.append("is_professional", this.state.professionalVOA);
          formData.append("birth_date", selectedDate);
          formData.append("gender", this.state.editGender);
          formData.append("recorded_file", this.state.uploadFile);
          axios.post(api_base_url + "/api/upload-audition/", formData, { headers: headers }).then((response) => {
            if (response.data.status) {
              this.setState({
                projectname: '',
                homeRecording: '',
                professionalVOA: '',
                gender: '',
                uploadFile: undefined,
                projectnameError: '',
                homeRecordingError: '',
                termAndConditionError: '',
                firstNameError: '',
                lastNameError: '',
                editGender: '',
                professionalVOAError: '',
                uploadFileError: '',
                emailError: '',
                uploadFileSizeError: '',
                successMessage: 'Thank you for your participation. Your audition application is submitted successfully. You will hear soon from us.',
                isAgree: false,
                successAuditionModal: true
              })
            }
            this.setState({
              isLoading: false,
            });
          }).catch((err) => {
            // console.log("Error - ", err);
            this.setState({
              isLoading: false,
            });
          });
        } else {
          this.setState({
            uploadFileError: "Insert only mp3, MP3, ogg, mp4, ogx, wma file",
            isLoading: false,
          });
        }
      }
    }
  }
  handleCaptcha() { }
  handleAuditionForm() {
    this.setState({
      isAuditionModal: false
    });
    this.props.history.push("/sign-in");
    localStorage.setItem("auditionForm", true)
  }
  closeAuditionModal() {
    this.setState({
      successAuditionModal: false
    });
  }
  render() {
    return (
      <div className="audition-wrapper">
        {this.state.isLoading ? (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        ) : null}

        <div className="container">
          <div className="project-audition-wrapper">
            <h2>Project Audition</h2>

          </div>
          <Form onSubmit={this.submitHandler}>
            <FormGroup>
              <Label for="firstname">First Name</Label>
              <Input
                type="text"
                name="firstname"
                id="firstname"
                maxLength="50"
                value={this.state.firstname}
                onChange={(event) => {
                  this.setState({ firstname: event.target.value });
                }}
              />
              <div class="loginErrorMsg">{this.state.firstNameError}</div>
            </FormGroup>
            <FormGroup>
              <Label for="lastname">Last Name</Label>
              <Input
                type="text"
                name="lastname"
                id="lastname"
                maxLength="50"
                value={this.state.lastname}
                onChange={(event) => {
                  this.setState({ lastname: event.target.value });
                }}
              />
              <div class="loginErrorMsg">{this.state.lastNameError}</div>
            </FormGroup>
            <FormGroup>
              <Label for="mobile">Mobile</Label>
              <Input
                type="tel"
                name="mobile"
                id="mobile"
                maxLength="15"
                value={this.state.mobile}
                onChange={(event) => {
                  this.setState({ mobile: event.target.value });
                }}
              />
              <div class="loginErrorMsg">{this.state.userMobileError}</div>
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="text"
                name="email"
                id="email"
                maxLength="50"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
              <div class="loginErrorMsg">{this.state.emailError}</div>
            </FormGroup>
            <FormGroup>
              <Label for="city">City</Label>
              <Input
                type="text"
                name="city"
                id="city"
                maxLength="50"
                value={this.state.city}
                onChange={(event) => {
                  this.setState({ city: event.target.value });
                }}
              />
              <div class="loginErrorMsg">{this.state.cityError}</div>
            </FormGroup>
            <FormGroup>
              <Label for="country">Country</Label>
              <Input
                type="select"
                name="select"
                id="selectCountry"
                value={this.state.country}
                onChange={(val) => this.selectCountry(val)}
              >
                {this.countryList()}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="dateofbirth">Date Of Birth</Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.dateOfBirth}
                onChange={this.dobDateChange}
                maxDate={new Date()}
              />
            </FormGroup>
            <FormGroup>
              <Label for="projectname">Project Name</Label>
              <Input
                type="text"
                name="projectname"
                id="projectname"
                maxLength="50"
                value={this.state.projectname}
                onChange={(event) => {
                  this.setState({ projectname: event.target.value });
                }}
              />
              <div class="loginErrorMsg">{this.state.projectnameError}</div>
            </FormGroup>
            <FormGroup>
              <Label className="fullwidth-label" for="homeRecording">Do you have home recording set-up?</Label>
              <div className="radioSelection">
                <Label check className="custom-male-radio">
                  <Input
                    type="radio"
                    name="homeRecording"
                    checked={this.state.homeRecording === "true"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        this.setState({ homeRecording: "true" });
                      }
                    }}
                  /> Yes
                </Label>
                <Label check className="custom-female-radio">
                  <Input
                    type="radio"
                    name="homeRecording"
                    checked={this.state.homeRecording === "false"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        this.setState({ homeRecording: "false" });
                      }
                    }}
                  /> No
                </Label>
              </div>
              <div class="loginErrorMsg">{this.state.homeRecordingError}</div>
            </FormGroup>
            <FormGroup>
              <Label for="upload">Recorded file</Label>
              <Input
                type="file"
                name="file"
                id="upload"
                onChange={this.fileUploadHandler} />
              <span className="uploadsize">(Upload mp3 file up to 10 MB)</span>
              <div class="loginErrorMsg uploadsizeError">{this.state.uploadFileError}</div>
              {this.state.uploadFileSizeError !== "" && <div class="loginErrorMsg uploadsizeError">{this.state.uploadFileSizeError}</div>}
            </FormGroup>

            <FormGroup>
              <Label className="fullwidth-label" for="professionalVOA">Are you a trained/professional VOA?</Label>
              <div className="radioSelection">
                <Label check className="custom-male-radio">
                  <Input
                    type="radio"
                    name="professionalVOA"
                    checked={this.state.professionalVOA === "true"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        this.setState({ professionalVOA: "true" });
                      }
                    }}
                  /> Yes
                </Label>
                <Label check className="custom-female-radio">
                  <Input
                    type="radio"
                    name="professionalVOA"
                    checked={this.state.professionalVOA === "false"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        this.setState({ professionalVOA: "false" });
                      }
                    }}
                  /> No
                </Label>
              </div>
              <div class="loginErrorMsg">{this.state.professionalVOAError}</div>
            </FormGroup>
            <FormGroup>
              <Label className="fullwidth-label" for="homeRecording">Gender</Label>
              <div className="radioSelection">
                <Label check className="custom-male-radio">
                  <Input
                    type="radio"
                    name="gender"
                    checked={this.state.editGender === "male"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        this.setState({ editGender: "male" });
                      }
                    }}
                  /> Male</Label>

                <Label check className="custom-female-radio">
                  <Input
                    type="radio"
                    name="gender"
                    checked={this.state.editGender === "female"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        this.setState({ editGender: "female" });
                      }
                    }}
                  /> Female</Label>
                <Label check className="custom-other-radio">
                  <Input
                    type="radio"
                    name="gender"
                    checked={this.state.editGender === "other"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        this.setState({ editGender: "other" });
                      }
                    }}
                  /> Other</Label>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="billCheckBx">
                <Recaptcha
                  sitekey={googleRecaptcha}
                  render="explicit"
                  onloadCallback={this.handleCaptcha}
                  verifyCallback={this.verifyCallback}
                  expiredCallback={this.expiredCallback}
                />
                <div class="loginErrorMsg">{this.state.googleCaptchaError}</div>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="billCheckBx emailSign-upBox">
                <label className="checkbox"><span>I accept <span className="text-termsandcondition" onClick={this.showTermAndCondition}>Terms and Conditions</span></span>
                  <input
                    type="checkbox"
                    name="agreeCheckbox"
                    id="agreeCheckbox"
                    defaultChecked={this.state.isAgree}
                    onChange={(event) => { this.setState({ isAgree: event.target.checked }); }}
                  /><span className="checkmarkbx"></span>
                </label>
                <div class="loginErrorMsg">{this.state.termAndConditionError}</div>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="contact-button-wrapper">
                {/* <Recaptcha
                    sitekey={googleRecaptcha}
                    render="explicit"
                    onloadCallback={this.handleCaptcha}
                    verifyCallback={this.verifyCallback}
                    expiredCallback={this.expiredCallback}
                  /> */}
                <div className="contactSubmit">
                  <Button type="submit">Submit</Button>
                </div>
                {/* <div class="loginErrorMsg">
                    {this.state.googleCaptchaError}
                  </div> */}
              </div>
            </FormGroup>

          </Form>
        </div>
        <Modal isOpen={!this.props.authData.loginSuccess ? this.state.isAuditionModal : null} size={"sm"} id="myCookies">
          <ModalBody>
            <button
              type="button"
              class="close custom-close-button"
              data-dismiss="modal"
              onClick={() => {
                this.setState({
                  isAuditionModal: false,
                });
              }}
            >&times;</button>
            <div class="auditionformModal">
              <h3>To submit the audition form, you need to sign in. Please click on sign in to proceed.</h3>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                onClick={this.handleAuditionForm}
              >
                Sign in
              </button>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.showTermAndCondition}
          toggle={this.closeTermAndCondition}
          className="termandconstion-model"
        >
          <ModalHeader toggle={this.closeTermAndCondition}></ModalHeader>
          <ModalBody>
            <h4>Terms And Conditions</h4>
            <ul>
              <li>1. I hereby acknowledge the copyright of Snovel Creations Pvt Ltd over the content</li>
              <li>2. I understand that Snovel might not select my voice for the said project</li>
              <li>3. I shall not disclose this project related information to anybody</li>
              <li>4. I will keep my interactions with Snovel Creations Pvt Ltd confidential</li>
              <li>5. I will not use/share my audition content (audio mp3) for any other use; otherwise I will be liable for legal action</li>
            </ul>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.successAuditionModal}
          toggle={this.closeAuditionModal}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={this.closeAuditionModal}></ModalHeader>
          <ModalBody>
            <div className="successMessage">{this.state.successMessage}</div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    loader: state.loader,
    authData: state.authonticationDetails,
    auditionForm: state.auditionFormReducer.userProfileDetails
  };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(AuditionFormComponent));