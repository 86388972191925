import React, { Component } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { NavLink } from "reactstrap";
import {
  api_base_url,
  dashboardToken,
  encryptDecryptKey,
  googleAnalyticsTrackingId,
} from "../config";
import axios from "axios";
import SimpleCrypto from "simple-crypto-js";
import { Link, withRouter } from "react-router-dom";
import {
  kidsCategoryAction,
} from "../action/kidsHomeAction";
import swal from "sweetalert";
import OwlCarousel from "react-owl-carousel2";
import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);
const optionss = {
  nav: true,
  rewind: true,
  autoplay: true,
  navText: ["<div></div>", "<div></div>"],
  margin: 15,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};
const latestLaunched = {
  nav: true,
  autoplay: false,
  margin: 15,
  navText: ["<div></div>", "<div></div>"],
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  },
};
const poplarLaunched = {
  nav: true,
  autoplay: false,
  margin: 15,
  navText: ["<div></div>", "<div></div>"],
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  },
};
const sliderLaunched = {
  nav: true,
  autoplay: false,
  margin: 15,
  navText: ["<div></div>", "<div></div>"],
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  },
};
class KidsHomePage extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      selectedLanguage: selectedLanguage || "EN",
      isLoading: false,
      audioObject: undefined,
      selectedIndex: "",
      latestLaunchAudio: {},
      latestLaunchLastPlayed: "",
      kidsPopularAudio: {},
      kidsPopularLastPlayed: "",
      latestLauchApiProducts: [],
      kidsPopularPicksData: [],
      kidsCategoryList: [],
      kidsCategoryProductList: [],
      subCategoryId: [],
      kidsCategoryLastPlayed: "",
      isCategoryAPIcall: false,
      userToken: token || "",
    };
    this.getCategoryPage = this.getCategoryPage.bind(this);
    ReactGA.pageview("/kids");
  }
  componentDidMount() {
    if (!this.state.isCategoryAPIcall) {
      this.props.dispatch(kidsCategoryAction());
    }
  }
  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.isPauseInnerProduct === true && this.state.audioObject) {
      this.state.audioObject.pause();
      this.setState({
        latestLaunchLastPlayed: "",
        kidsPopularLastPlayed: "",
        kidsCategoryLastPlayed: "",
      });
      this.props.onPauseSample("innerProduct");
    }
    if (
      nextProps.kidsHomeCategoryReducer !== prevState.kidsHomeCategoryReducer &&
      nextProps.kidsHomeCategoryReducer.length > 0
    ) {
      this.setState({
        kidsCategoryList: nextProps.kidsHomeCategoryReducer,
        isCategoryAPIcall: true,
      });
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  componentWillUnmount() {
    if (this.state.audioObject) {
      this.state.audioObject.pause();
    }
  }

  productDetailsId(productID, slug) {
    localStorage.setItem("productId", productID);
    this.props.history.push("/product-details/" + productID + "/" + slug);
  }

  /**************************
   *
   * Create Author Links
   *
   ***************************/
  authorLinkList(authorList) {
    return authorList.map((authorName, index) => {
      if (
        authorName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        return (
          <NavLink
            tag={Link}
            className="pl-0"
            onClick={() => {
              this.authorDetailsId(authorName.id, authorName.name);
            }}
            key={index}
          >
            <span key={index}>
              {authorName.custom_translation[this.state.selectedLanguage].name}
            </span>
          </NavLink>
        );
      }
    });
  }
  /***********************************************
   * Author details page when click on author name
   *
   ************************************************/
  authorDetailsId(authorID, authorName) {
    var titleUrl = authorName.toLowerCase();
    var authorTitle = titleUrl.replace(".", "");
    var authorName = authorTitle.replace(" ", "-");
    this.props.history.push("/author/" + authorID + "/" + authorName);
  }

  /***********************************
   *  Kids Category section
   * ********************************* */
  kidsCategorySliderContent(kidsCategoryResult) {
    return kidsCategoryResult.map((sliderElement, slideIndex) => {
      return (
        <div>
          {sliderElement.images.length > 0 ? (
            <div className="sliderThumb">
              {sliderElement.is_free && (
                <span className="freeProduct">{strings.FreeText}</span>
              )}
              <img
                src={sliderElement.images[0]["image2"]}
                className="sampleProduct_thumb"
                onClick={() => {
                  this.productDetailsId(
                    sliderElement.id,
                    sliderElement.product_slug
                  );
                }}
              />
            </div>
          ) : (
            <div className="sliderThumb">
              {sliderElement.is_free && (
                <span className="freeProduct">{strings.FreeText}</span>
              )}
              <img
                src="/images/default.jpg"
                className="sampleProduct_thumb"
                onClick={() => {
                  this.productDetailsId(
                    sliderElement.id,
                    sliderElement.product_slug
                  );
                }}
              />
            </div>
          )}
          <div className="sliderTrack_Wrap">
            {sliderElement.sampletracks.length > 0 && (
              <div>
                {sliderElement.sampletracks[0].map_url !=
                  this.state.kidsCategoryLastPlayed && (
                    <img
                      onClick={() => {
                        this.kidsCategoryLaunchPlayAudio(
                          sliderElement.sampletracks[0].map_url
                        );
                      }}
                      src="/images/play.png"
                    />
                  )}
                {sliderElement.sampletracks[0].map_url ==
                  this.state.kidsCategoryLastPlayed && (
                    <img
                      onClick={() => {
                        this.kidsCategoryLaunchPauseAudio(
                          sliderElement.sampletracks[0].map_url
                        );
                      }}
                      src="/images/round-pause-button.svg"
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      );
    });
  }

  kidsCategoryLaunchPlayAudio(map_url) {

    if (this.state.kidsPopularAudio[map_url]) {
      this.setState({
        kidsCategoryLastPlayed: map_url,
        latestLaunchLastPlayed: "",
        kidsPopularLastPlayed: "",
      });
      // this.props.onPlaySample('innerProduct');
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                kidsCategoryLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newLatestLaunchAudio = {};
            newLatestLaunchAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              kidsPopularAudio: newLatestLaunchAudio,
              kidsCategoryLastPlayed: map_url,
              latestLaunchLastPlayed: "",
              kidsPopularLastPlayed: "",
            });
            // this.props.onPlaySample('innerProduct');
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }

  kidsCategoryLaunchPauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      kidsCategoryLastPlayed: "",
    });
  }

  getCategoryPage(catID, customTranslation) {
    var titleUrl = customTranslation["EN"].name.toLowerCase();
    var categorySlug = titleUrl.split(" ").join("-");
    localStorage.setItem("categoryID", catID);
    localStorage.setItem("categoryName", JSON.stringify(customTranslation));
    this.props.history.push("/category-details/" + catID + "/" + categorySlug);
  }
  render() {
    return (
      <div className="kids-main-wrapper">
        {this.props.loader.isLoader && (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        )}
        <section className="baner-section kidsHomePage-Banner">
          {this.state.selectedLanguage === "EN" ? (
            <OwlCarousel id="homePage" ref="car" options={optionss}>
              <div>
                <img src="/images/Banner01.png" />
              </div>
              <div>
                <img src="/images/kidsBanner02.png" />
              </div>
            </OwlCarousel>
          ) : (
            <OwlCarousel id="homePage" ref="car" options={optionss}>
              <div>
                <img src="/images/Banner01.png" />
              </div>
              <div>
                <img src="/images/kidsBanner02.png" />
              </div>
            </OwlCarousel>
          )}
        </section>
        <div className="container">
          {this.state.kidsCategoryList.length > 0 && (
            <div className="sliderContentWrapper kidsCategorySliders">
              {this.state.kidsCategoryList.map((cat, catIndex) => {
                return (
                  <div className="kidsCategoryMain-wrapper">
                    {cat["sliders"].length > 0 && (
                      <h4 className={"float-left custom-category-title"}>
                        {
                          cat.custom_translation[this.state.selectedLanguage] ?
                            cat.custom_translation[this.state.selectedLanguage].name
                            :
                            cat.custom_translation["EN"].name
                        }
                      </h4>
                    )}
                    {cat["sliders"].length > 5 && (
                      <button
                        className={"category-view-all-button"}
                        onClick={() => {
                          this.getCategoryPage(cat.id, cat.custom_translation);
                        }}
                      >
                        {strings.viewAll}
                      </button>
                    )}
                    {cat["sliders"].length > 0 && (
                      <OwlCarousel options={sliderLaunched}>
                        {this.kidsCategorySliderContent(cat["sliders"])}
                      </OwlCarousel>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    languageTab: state.languageTab,
    loader: state.loader,
    kidsHomeCategoryReducer: state.kidsHomeCategoryReducer.kidsCategoryList,
    authData: state.authonticationDetails,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(KidsHomePage));
