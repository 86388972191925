import React, { Component } from "react";
import ReactGA from "react-ga";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  NavLink,
} from "reactstrap";
import { connect } from "react-redux";
import { Route, Link, withRouter } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import {
  api_base_url,
  signUpGuestToken,
  emailReg,
  loginGuestToken,
  encryptDecryptKey,
  countryList,
  mobileRegularExpression,
  googleAnalyticsTrackingId,
  nameRegularExpression,
  stateList,
} from "../config";
import axios from "axios";
import { CountryDropdown } from "react-country-region-selector";
import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

class registerComponant extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (userDetailsObject.loginSuccess) {
        this.props.history.push("/");
      }
    }
    var tokenFrom = this.props.match.params.token;
    var tokenFromEmail = this.props.match.params.email;
    var decryptEmail = window.atob(tokenFromEmail);
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      firstName: "",
      lastName: "",
      mobile: "",
      email: decryptEmail || "",
      emailError: "",
      confirmEmail: "",
      confirmEmailError: "",
      password: "",
      passwordError: "",
      confirmPassword: "",
      confirmPasswordError: "",
      socialLoginError: "",
      country: "IN",
      state: "",
      isAgree: false,
      isReceiveEmail: false,
      isLoading: false,
      selectedLanguage: selectedLanguage || "EN",
      tokenEncrypt: tokenFrom || "",
      clientIpAdrees: "",
      apipasswordError: "",
      registerEmailError: "",
      stateError: "",
    };
    this.onSubmitCreateAccount = this.onSubmitCreateAccount.bind(this);
    ReactGA.pageview(this.props.location.pathname);
  }
  componentDidMount() {
    this.clientIpAddress();
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }
  clientIpAddress() {
    axios
      .get("http://jsonip.com/")
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            clientIpAdrees: response.data.ip,
          });
        }
      })
      .catch((err) => {
        // console.log("Error - ", err);
      });
  }
  componentWillReceiveProps(nextProps) {
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  selectCountry(val) {
    this.setState({ country: val.target.value });
    if (val.target.value !== "IN") {
      this.setState({
        state: "",
      });
    }
  }
  selectState(val) {
    this.setState({ state: val.target.value });
  }
  countryList() {
    return countryList.map((country, index) => {
      return <option value={country.code}>{country.name}</option>;
    });
  }
  stateList() {
    return stateList.map((state, index) => {
      return <option value={state.code}>{state.name}</option>;
    });
  }
  onSubmitCreateAccount(event) {
    event.preventDefault();
    this.setState({
      firstNameError: "",
      lastNameError: "",
      userMobileError: "",
      countryError: "",
      stateError: "",
      emailError: "",
      passwordError: "",
      confirmPasswordError: "",
      apipasswordError: "",
      registerEmailError: "",
    });
    var isValid = true;
    if (this.state.firstName === "") {
      this.setState({
        firstNameError: strings.firstNameError,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.firstName)) {
      this.setState({
        firstNameError: strings.AcceptAlphabet,
      });
      isValid = false;
    }
    if (this.state.lastName === "") {
      this.setState({
        lastNameError: strings.lastNameError,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.lastName)) {
      this.setState({
        lastNameError: strings.lastNameAlphabet,
      });
      isValid = false;
    }
    if (this.state.mobile === "") {
      this.setState({
        userMobileError: strings.mobileError,
      });
      isValid = false;
    }
    if (
      this.state.mobile !== "" &&
      !mobileRegularExpression.test(this.state.mobile)
    ) {
      this.setState({
        userMobileError: strings.mobileError,
      });
      isValid = false;
    }
    if (this.state.email === "") {
      this.setState({
        emailError: strings.enterEmail,
      });
      isValid = false;
    } else if (emailReg.test(this.state.email) === false) {
      this.setState({
        emailError: strings.enterValidEmail,
      });
      isValid = false;
    }
    if (this.state.country === "IN" && this.state.state == "") {
      this.setState({
        stateError: strings.stateError,
      });
      isValid = false;
    }
    if (this.state.password === "") {
      this.setState({
        passwordError: strings.enterPassword,
      });
      isValid = false;
    } else if (this.state.password.length < 5) {
      this.setState({
        passwordError: strings.PasswordLess,
      });
      isValid = false;
    } else if (this.state.password.length > 15) {
      this.setState({
        passwordError: strings.PasswordMore,
      });
    }
    if (this.state.confirmPassword === "") {
      this.setState({
        confirmPasswordError: strings.confirmPassword,
      });
      isValid = false;
    } else if (this.state.confirmPassword !== this.state.password) {
      this.setState({
        confirmPasswordError: strings.passwordDoesNotMatch,
      });
      isValid = false;
    }
    if (isValid) {
      if (!this.state.isAgree) {
        this.setState({
          confirmPasswordError: strings.MembershipPolicy,
        });
      } else {
        this.setState({
          isLoading: true,
        });

        var apiParamsVerify = {
          token: this.state.tokenEncrypt,
        };
        var headers = {
          "Content-Type": "application/json",
          "guest-regs-token": signUpGuestToken,
        };
        axios
          .post(api_base_url + "/api/register-verify-token/", apiParamsVerify, {
            headers: headers,
          })
          .then((response) => {
            if (response) {
              var apiParams = {
                email: this.state.email,
                password: this.state.password,
                country: this.state.country,
                state: this.state.state,
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                mobile: this.state.mobile,
                newsletter_subscribed: this.state.isReceiveEmail,
                token: this.state.tokenEncrypt,
                customer_ip_address: this.state.clientIpAdrees,
              };
              var headers = {
                "Content-Type": "application/json",
                "guest-regs-token": signUpGuestToken,
              };
              axios
                .post(api_base_url + "/api/v1/register/", apiParams, {
                  headers: headers,
                })
                .then((response) => {
                  if (response.data.status === true) {
                    this.props.history.push("/sign-in");
                  }
                  if (response.data.status === false) {
                    if (response.data.data.mobile != undefined) {
                      this.setState({
                        confirmPasswordError: response.data.data.mobile[0],
                      });
                    } else if (response.data.data.password) {
                      this.setState({
                        registerEmailError: response.data.data.password[0],
                      });
                    } else {
                      this.setState({
                        registerEmailError: response.data.data,
                      });
                    }
                    this.setState({
                      isLoading: false,
                    });
                  }
                })
                .catch((err) => {
                  // console.log("Error - ", err);
                  this.setState({
                    isLoading: false,
                  });
                });
            }
          })
          .catch((err) => {
            // console.log("Error - ", err);
          });
      }
    }
  }
  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        ) : null}

        <div className="clearfix"></div>
        <div className="container">
          <div className="snovelSignUp">
            <div class="welcomeBack">
              <h1>{strings.createAccount}</h1>
              <p></p>
              <Form onSubmit={this.onSubmitCreateAccount}>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      type="text"
                      name="fname"
                      id="fname"
                      maxLength="50"
                      placeholder={strings.firstName}
                      value={this.state.firstName}
                      onChange={(event) => {
                        this.setState({ firstName: event.target.value });
                      }}
                    />
                    <div class="loginErrorMsg">{this.state.firstNameError}</div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      type="text"
                      name="lname"
                      id="lname"
                      maxLength="50"
                      placeholder={strings.lastName}
                      value={this.state.lastName}
                      onChange={(event) => {
                        this.setState({ lastName: event.target.value });
                      }}
                    />
                    <div class="loginErrorMsg">{this.state.lastNameError}</div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      type="text"
                      name="email"
                      id="userEmail"
                      placeholder={strings.regiEmail}
                      value={this.state.email}
                      maxLength="50"
                      onChange={(event) => {
                        this.setState({ email: event.target.value });
                      }}
                      disabled
                    />
                    <div class="loginErrorMsg">{this.state.emailError}</div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      type="tel"
                      name="mobile"
                      id="mobile"
                      maxLength="10"
                      placeholder={strings.userMobile}
                      value={this.state.mobile}
                      onChange={(event) => {
                        this.setState({ mobile: event.target.value });
                      }}
                    />
                    <div class="loginErrorMsg">
                      {this.state.userMobileError}
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      type="select"
                      name="select"
                      id="selectCountry"
                      value={this.state.country}
                      onChange={(val) => this.selectCountry(val)}
                    >
                      {this.countryList()}
                    </Input>
                    <div class="loginErrorMsg">{this.state.countryError}</div>
                  </Col>
                </FormGroup>
                {this.state.country === "IN" && (
                  <FormGroup row>
                    <Col sm={12}>
                      <Input
                        type="select"
                        name="select"
                        id="selectCity"
                        value={this.state.state}
                        onChange={(val) => this.selectState(val)}
                      >
                        <option value="">{strings.selectState}</option>
                        {this.stateList()}
                      </Input>
                      <div class="loginErrorMsg">{this.state.stateError}</div>
                    </Col>
                  </FormGroup>
                )}
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      type="password"
                      name="password"
                      id="userPassword"
                      maxLength="15"
                      placeholder={strings.passwordReg}
                      value={this.state.password}
                      onChange={(event) => {
                        this.setState({ password: event.target.value });
                      }}
                    />
                    <div class="loginErrorMsg">{this.state.passwordError}</div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="conformPassword"
                      maxLength="15"
                      placeholder={strings.passwordRegConfirm}
                      value={this.state.confirmPassword}
                      onChange={(event) => {
                        this.setState({ confirmPassword: event.target.value });
                      }}
                    />
                    <div class="loginErrorMsg">
                      {this.state.confirmPasswordError}
                    </div>
                    <div class="loginErrorMsg">
                      {this.state.registerEmailError}
                    </div>
                    {this.state.apipasswordError.length > 0 && (
                      <div class="loginErrorMsg">
                        {this.state.apipasswordError}
                      </div>
                    )}
                  </Col>
                </FormGroup>
                <div className="text-center">
                  <Button type="submit">{strings.createAccount}</Button>
                </div>
              </Form>
            </div>

            <div xs="12">
              <div className="snovelSignUpBtm">
                <div className="billCheckBx">
                  <label className="checkbox">
                    {strings.agreePrivacyPolicy}
                    <input
                      type="checkbox"
                      name="agreeCheckbox"
                      id="agreeCheckbox"
                      defaultChecked={this.state.isAgree}
                      onChange={(event) => {
                        this.setState({ isAgree: event.target.checked });
                      }}
                    />
                    <span className="checkmarkbx"></span>
                  </label>
                </div>
                <div className="billCheckBx">
                  <label className="checkbox">
                    {strings.notificationEmails}
                    <input
                      type="checkbox"
                      name="receiveEmailCheckbox"
                      id="receiveEmailCheckbox"
                      defaultChecked={this.state.isReceiveEmail}
                      onChange={(event) => {
                        this.setState({ isReceiveEmail: event.target.checked });
                      }}
                    />
                    <span className="checkmarkbx"></span>
                  </label>
                </div>
                <div className="brdsnovelSignUpBtm"></div>
                <div className="col-lg-7 visitFaq">
                  {strings.queryPurchasing}
                  <br />
                  <span>
                    <NavLink className="visit-faq-link" tag={Link} to="/faq">
                      {strings.visitourFAQ}
                    </NavLink>
                  </span>
                </div>
                <div className="col-lg-5 visitFaq emailRight">
                  {strings.ContactUs}:{" "}
                  <span className="emailRight">
                    <a href="mailto:hello@snovel.in">hello@snovel.in</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="subBenefit_Wrapeer">
          <div className="container">
            <Row>
              <Col xs="12" md="12" lg="4">
                <h2>{strings.benefitOfOneHead}</h2>
                <p>{strings.benefitOfOne}</p>
              </Col>
              <Col xs="12" md="12" lg="4" className="subsCriptionMid">
                <h2>{strings.benefitOfTwoHead}</h2>
                <p>{strings.benefitOfTwo}</p>
              </Col>
              <Col xs="12" md="12" lg="4">
                <h2>{strings.benefitOfThreeHead}</h2>
                <p>{strings.benefitOfThree}</p>
              </Col>
            </Row>
            <div class="subsCriBtnBtm">
              <span class="subsCribeButton">
                <a href="/subscribe">{strings.subscribeNow}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStatesToProps(globalData) {
  return {
    authData: globalData.authonticationDetails,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(registerComponant));
