import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'reactstrap';
import swal from 'sweetalert';
import OwlCarousel from 'react-owl-carousel2';
import { Link, withRouter } from 'react-router-dom';
import { api_base_url, dashboardToken } from '../config';
import axios from 'axios';
import { homeTrendingProductDetailsData } from '../action/trendingProductAction';
import { browseCategoryProductDetailsDataAction } from '../action/browseCategoryAction';
import LocalizedStrings from 'react-localization';
import languages from '../language/language.json';
var strings = new LocalizedStrings(languages);

const latestLaunched = {
    nav: true,
    rewind: true,
    autoplay: false,
    margin: 15,
    navText: ["<div></div>", "<div></div>"],
    responsive: {
        0: {
            items: 2
        },
        600: {
            items: 3
        },
        1000: {
            items: 6
        }
    }
};
const featured = {
    nav: true,
    autoplay: false,
    margin: 15,
    navText: ["<div></div>", "<div></div>"],
    responsive: {
        0: {
            items: 2
        },
        600: {
            items: 3
        },
        1000: {
            items: 6
        }
    }
}

class featuredProductsSlider extends Component {
    constructor(props) {
        super(props);
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
        }

        this.state = {
            currentSlide: '',
            selectedLanguage: selectedLanguage || 'EN',
            audioObject: undefined,
            selectedIndex: '',
            latestLaunchAudio: {},
            latestLaunchLastPlayed: '',
            featuredAudio: {},
            featuredLastPlayed: '',
            latestLauchApiProducts: [],
            featuredApiProducts: []
        }
    }
    productDetailsId(productID, slug) {
        localStorage.setItem('productId', productID);
        this.props.history.push('/product-details/' + productID + '/' + slug);
    }

    /*****   Author Specific details link ***************/
    authorDetailsId(authorID, authorName) {
        var titleUrl = authorName.toLowerCase();
        var authorTitle = titleUrl.replace('.', '');
        var authorName = authorTitle.replace(' ', '-');
        this.props.history.push('/author/' + authorID + '/' + authorName);
    }
    authorLinkList(authorList) {
        return authorList.map((authorName, index) => {
            if (authorName.custom_translation[this.state.selectedLanguage] != undefined) {
                return (<NavLink tag={Link} className="pl-0"
                    onClick={() => { this.authorDetailsId(authorName.id, authorName.name) }} key={index}>
                    <span key={index}>{authorName.custom_translation[this.state.selectedLanguage].name}</span>
                </NavLink>);
            }
        });
    }
    componentWillMount() {
        this.props.dispatch(homeTrendingProductDetailsData());
    }
    componentWillUnmount() {
        if (this.state.audioObject) {
            this.state.audioObject.pause();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.isPauseInnerProduct === true && this.state.audioObject) {
            this.state.audioObject.pause();
            this.setState({
                latestLaunchLastPlayed: '',
                featuredLastPlayed: ''
            });
            this.props.onPauseSample('innerProduct');
        }
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
            this.setState({
                selectedLanguage: selectedLanguage
            });
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
            this.setState({
                selectedLanguage: "EN"
            });
        }
    }

    componentDidMount() {
        // this.featuredProductsData();
        this.latestLauchProductsData();
    }

    featuredProductsData() {
        var headers = {
            'Content-Type': 'application/json',
            'index-api-token': dashboardToken
        }
        axios.get(api_base_url + '/api/v2/index-featured/10', { headers: headers })
            .then(response => {
                if (response.data.status == true) {
                    this.setState({
                        featuredApiProducts: response.data.featured_product || []
                    })
                } else {
                    //   console.log("Not Loaded");
                }
            })
            .catch(err => {
                // console.log('fetched.', err)
            });
    }

    latestLaunchPauseAudio(map_url) {
        this.state.audioObject.pause();
        this.setState({
            latestLaunchLastPlayed: ''
        });
    }

    latestLaunchPlayAudio(map_url) {
        if (this.state.latestLaunchAudio[map_url]) {
            this.setState({
                latestLaunchLastPlayed: map_url,
                featuredLastPlayed: '',
            });
            this.props.onPlaySample('innerProduct');
            var playPromise = this.state.audioObject.play();
            if (playPromise !== undefined) {
                playPromise.then(function () { }).catch(function (error) {
                    const isFirefox = typeof InstallTrigger !== 'undefined';
                    if (isFirefox) {
                        swal("", "Something went wrong with your browser. Please try another one.", "error");
                    }
                });
            }
        } else {
            if (this.state.audioObject) {
                this.state.audioObject.pause();
            }
            var apiParams = {
                map_url: map_url
            };
            var headers = {
                'Content-Type': 'application/json',
                'index-api-token': dashboardToken
            };
            axios.post(api_base_url + '/api/get-sample-signed-url/', apiParams, { headers: headers }).then(response => {
                if (response.data) {
                    var url = response.data.data.signed_url || "";
                    var audio = new Audio(url);
                    var parentThis = this;
                    audio.addEventListener("ended", function () {
                        parentThis.setState({
                            latestLaunchLastPlayed: ''
                        });
                    });
                    audio.type = 'audio/mpeg';
                    var newLatestLaunchAudio = {};
                    newLatestLaunchAudio[map_url] = url;
                    this.setState({
                        audioObject: audio,
                        latestLaunchAudio: newLatestLaunchAudio,
                        latestLaunchLastPlayed: map_url,
                        featuredLastPlayed: ''
                    });
                    this.props.onPlaySample('innerProduct');
                    var playPromise = audio.play();
                    if (playPromise !== undefined) {
                        playPromise.then(function () { }).catch(function (error) {
                            const isFirefox = typeof InstallTrigger !== 'undefined';
                            if (isFirefox) {
                                swal("", "Something went wrong with your browser. Please try another one.", "error");
                            }
                        });
                    }
                }
            }).catch(err => {
                // console.log('Error - ', err);
            });
        }
    }
    latestLauchProductsData() {
        var headers = {
            'Content-Type': 'application/json',
            'index-api-token': dashboardToken
        }
        axios.get(api_base_url + '/api/v2/index-latest-launch/10', { headers: headers })
            .then(response => {
                if (response.data.status == true) {
                    this.setState({
                        latestLauchApiProducts: response.data.latest_launch || []
                    })
                } else {
                    //   console.log("Not Loaded");
                }
            })
            .catch(err => {
                // console.log('fetched.', err)
            });
    }

    /******** Latest Launch slider content **********/
    latestLaunchSliderContent() {
        return this.state.latestLauchApiProducts.map((sliderElement, slideIndex) => {
            return (
                <div>
                    {sliderElement.images.length > 0 ? <div className="sliderThumb">{sliderElement.is_free && <span className="freeProduct">{strings.FreeText}</span>}<img src={sliderElement.images[0]["image2"]} className="sampleProduct_thumb" onClick={() => { this.productDetailsId(sliderElement.id, sliderElement.product_slug) }} /></div> : <div className="sliderThumb">{sliderElement.is_free && <span className="freeProduct">{strings.FreeText}</span>}<img src="/images/default.jpg" className="sampleProduct_thumb" onClick={() => { this.productDetailsId(sliderElement.id, sliderElement.product_slug) }} /></div>}
                    {/* {sliderElement.custom_translation[this.state.selectedLanguage] !== undefined && <div className="sliderProductTitle"><span onClick={() => {this.productDetailsId(sliderElement.id, sliderElement.product_slug)}}>{sliderElement.custom_translation[this.state.selectedLanguage].name}</span></div>}
                    {sliderElement.authors.length>0 && <div className="sliderProductAuthor">
                       
                        {this.authorLinkList(sliderElement.authors)}</div>}
                    {sliderElement.category.length>0 && 
                    <div className="sliderProductCategory">
                    {sliderElement.category.map((categoryList, index) => {
                        if(categoryList.custom_translation[this.state.selectedLanguage] !== undefined){
                            return <NavLink tag={Link} to={{
                                pathname: '/browse'
                            }} onClick={() => {
                                this.props.dispatch({
                                type: 'MENU_SELECTED_CATEGORY',
                                payload: [categoryList.name, sliderElement.product_audio_type, categoryList.id]
                            });
                            this.props.dispatch(browseCategoryProductDetailsDataAction(sliderElement.product_audio_type, categoryList.name, 1));
                            }} key={index}><span key={index}>{categoryList.custom_translation[this.state.selectedLanguage].name.toString()}</span></NavLink>
                        } 
                        })}
                    </div>} */}
                    <div className="sliderTrack_Wrap">
                        {sliderElement.sampletracks.length > 0 &&
                            <>
                                <div>
                                    {sliderElement.sampletracks[0].map_url != this.state.latestLaunchLastPlayed &&
                                        <img onClick={() => { this.latestLaunchPlayAudio(sliderElement.sampletracks[0].map_url); }} src="/images/play.png" />
                                    }
                                    {sliderElement.sampletracks[0].map_url == this.state.latestLaunchLastPlayed &&
                                        <img onClick={() => { this.latestLaunchPauseAudio(sliderElement.sampletracks[0].map_url); }} src="/images/round-pause-button.svg" />
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            );
        })
    }

    featuredPlayAudio(map_url) {
        if (this.state.featuredAudio[map_url]) {
            this.setState({
                featuredLastPlayed: map_url,
                latestLaunchLastPlayed: ''
            });
            this.props.onPlaySample('innerProduct');
            var playPromise = this.state.audioObject.play();
            if (playPromise !== undefined) {
                playPromise.then(function () { }).catch(function (error) {
                    const isFirefox = typeof InstallTrigger !== 'undefined';
                    if (isFirefox) {
                        swal("", "Something went wrong with your browser. Please try another one.", "error");
                    }
                });
            }
        } else {
            if (this.state.audioObject) {
                this.state.audioObject.pause();
            }
            var apiParams = {
                map_url: map_url
            };
            var headers = {
                'Content-Type': 'application/json',
                'index-api-token': dashboardToken
            };
            axios.post(api_base_url + '/api/get-sample-signed-url/', apiParams, { headers: headers }).then(response => {
                if (response.data) {
                    var url = response.data.data.signed_url || "";
                    var audio = new Audio(url);
                    var parentThis = this;
                    audio.addEventListener("ended", function () {
                        parentThis.setState({
                            featuredLastPlayed: ''
                        });
                    });
                    audio.type = 'audio/mpeg';
                    var newFeaturedAudio = {};
                    newFeaturedAudio[map_url] = url;
                    this.setState({
                        audioObject: audio,
                        featuredAudio: newFeaturedAudio,
                        featuredLastPlayed: map_url,
                    });
                    this.props.onPlaySample('innerProduct');
                    var playPromise = audio.play();
                    if (playPromise !== undefined) {
                        playPromise.then(function () { }).catch(function (error) {
                            const isFirefox = typeof InstallTrigger !== 'undefined';
                            if (isFirefox) {
                                swal("", "Something went wrong with your browser. Please try another one.", "error");
                            }
                        });
                    }
                }
            }).catch(err => {
                // console.log('Error - ', err);
            });
        }
    }

    featuredPauseAudio(map_url) {
        this.state.audioObject.pause();
        this.setState({
            featuredLastPlayed: ''
        });
    }

    getCategoryPage() {
        var post_type = 'family'
        var customTranslation = 'latest_launch'
        this.props.dispatch({
            type: "MENU_SELECTED_CATEGORY",
            payload: ['latest_launch', 'family', 1],
        });
        this.props.dispatch(
            browseCategoryProductDetailsDataAction(null, 'latest_launch', 1)
        );
        localStorage.setItem('post_type', post_type);
        localStorage.setItem("categoryName", JSON.stringify(customTranslation));
        this.props.history.push("/family-category-details/" + 'latest_launch');
    }

    render() {
        return (
            <div className="kids-main-wrapper">
                <div div className="container">

                    {this.state.latestLauchApiProducts.length > 0 &&
                        <div className="newSliderContentWrapper kidsCategorySliders">
                            <div className="kidsCategoryMain-wrapper">
                                <h4 className={"float-left custom-category-title"}>
                                    {strings.latestLaunch}
                                </h4>
                                {this.state.latestLauchApiProducts.length > 5 ?
                                    <button
                                        className={"category-view-all-button"}
                                        onClick={() => {
                                            // console.log(this.state.latestLauchApiProducts)
                                            this.getCategoryPage();
                                        }}
                                    >
                                        {strings.viewAll}
                                    </button>
                                    :
                                    ""
                                }
                                <OwlCarousel ref="car" options={latestLaunched} >{this.latestLaunchSliderContent()}</OwlCarousel>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStatesToProps(state) {
    return {
        homeMainCategoryList: state.homeMainCategoryList.homePageAllData,
        homePageLatestProducts: state.homeMainCategoryList.homePageLatestProducts,
        languageTab: state.languageTab,
        trendingProductsDetails: state.trendingProducts
    };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(featuredProductsSlider));