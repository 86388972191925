import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import createHistory from 'history/createBrowserHistory';
import { connectRouter } from 'connected-react-router';
import rootReducer from './reducer';
import { loadState, saveState } from './localStorage';
import logger from 'redux-logger'

const loggerMiddleware = createLogger();
export const history = createHistory();

const persistedState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = applyMiddleware(promise, thunk,
    // loggerMiddleware
);

const store = createStore(rootReducer, persistedState, composeEnhancers(middleware));

store.subscribe(() => {
    var authData = store.getState().authonticationDetails
    saveState(authData);
});

export default store;
