const initialState = {
    kidsCategoryList: [],
    eBookDetsils: []
}
export default function kidsHomeCategoryReducer (state = initialState, action) {
    switch (action.type) {
        case 'KIDS_CATEGORY_LIST': {
            return { ...state, kidsCategoryList: action.payload };
        }
        case 'FAILED_KIDS_CATEGORY_LIST': {
            return { ...state, kidsCategoryList:[] };
        }
        case 'EBOOK_PDF_DETIALS': {
            return { ...state, eBookDetsils: action.payload };
        }
        case 'FAILED_EBOOK_PDF_DETIALS': {
            return { ...state, eBookDetsils:[] };
        }
        default: {
            return { ...state };
        }
    }
}