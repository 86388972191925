import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { googleAnalyticsTrackingId } from '../../config';
ReactGA.initialize(googleAnalyticsTrackingId);

class writer extends Component {
    constructor(props){
        super(props);
        ReactGA.pageview(this.props.location.pathname);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
               <div className="container">
                 <h2>Writer</h2>
                </div>
            </div>
        );
    }
}

export default writer;