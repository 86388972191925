import { combineReducers } from 'redux';
import authonticationDetails from './loginReducer';
import homeMainCategoryList from './mainCategoryReducer';
import tabReducer from './tabReducer';
import languageReducerAction from './languageReducer'; 
import treandingProductsAction from './trendingProductReducer';
import selectedPostAndCategory from './menuReducer';
import modelSelectedFunction from './modelReducer';
import selectedProductToPlay from './playerReducer';
import browseCategoryProductDetailsData from './categoryBrowseDetails';
import loader from './loaderReducer';
import productNotificationReducer from './productNotificationReducer';
import userSubscriptionReducer from './userProfileDetailReducer';
import tryFreeProductDetailsData from './tryFreeSnovelProductReducer';
import userSpecificOrderData from './myOrderDataReducer';
import productCartCountReducer from './CartCountReducer';
import myLibraryActiveTabReducer from './activeLibraryReducer';
import selectedTrackIdReducer from './episodeSelectedTrackIdReducer';
import isReisteredDevice from './registeredDevice.reducer';
import kidsHomeCategoryReducer from './kidsHomePageReducer';
import languageLabCategoryReducer from './languageLabPageReducer';
import subscriptionListReducer from './subscriptionReducer';
import auditionFormReducer from "./AuditionFormReducer";
import homeFeaturedProductData from "./homeFeaturedProductReducer";
import familyHomeCategoryReducer from './familyHomePageReducer';
const appReducer = combineReducers({
    authonticationDetails: authonticationDetails,
    homeMainCategoryList: homeMainCategoryList,
    authTab : tabReducer,
    languageTab: languageReducerAction,
    trendingProducts: treandingProductsAction,
    selectedPostAndCategory: selectedPostAndCategory,
    modelSelectedFunction: modelSelectedFunction,
    selectedProductToPlay: selectedProductToPlay,
    browseCategoryProductDetailsData: browseCategoryProductDetailsData,
    loader: loader,
    productNotificationReducer: productNotificationReducer,
    userSubscriptionReducer: userSubscriptionReducer,
    tryFreeProductDetailsData: tryFreeProductDetailsData,
    userSpecificOrderData: userSpecificOrderData,
    productCartCountReducer: productCartCountReducer,
    myLibraryActiveTabReducer: myLibraryActiveTabReducer,
    selectedTrackIdReducer: selectedTrackIdReducer,
    isReisteredDevice: isReisteredDevice,
    kidsHomeCategoryReducer: kidsHomeCategoryReducer,
    familyHomeCategoryReducer: familyHomeCategoryReducer,
    languageLabCategoryReducer: languageLabCategoryReducer,
    subscriptionListReducer: subscriptionListReducer,
    auditionFormReducer: auditionFormReducer,
    homeFeaturedProductData: homeFeaturedProductData,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;