import React, { Component } from 'react';
import {
    api_base_url, signUpGuestToken, emailReg,
    loginGuestToken, facebookAppId, googleClientId,
    encryptDecryptKey, mobileRegularExpression,
    googleAnalyticsTrackingId
} from '../config';
import {
    Button, Form, FormGroup, Label,
    Input, FormText, Row
} from 'reactstrap';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { userAuthentication } from '../action/loginAction';
import { connect } from 'react-redux';
import { Route, Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import languages from '../language/language.json';
var strings = new LocalizedStrings(languages);

class EmailSignUpConfirmation extends Component {
    constructor(props) {
        super(props);
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
        }
        this.state = {
            confirmEmail: '',
            signupEmailError: '',
            successMessage: '',
            selectedLanguage: selectedLanguage || 'EN',
            emailSignupBox: false,
            clientIpAdrees: '',
            isAgree: false,
            acceptanceTerm: ''
        }
        this.onSubmitEmailConfirmation = this.onSubmitEmailConfirmation.bind(this);
        this.emailSignUpBox = this.emailSignUpBox.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
            this.setState({
                selectedLanguage: selectedLanguage
            });
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
            this.setState({
                selectedLanguage: "EN"
            });
        }
    }
    componentDidMount() {
        this.clientIpAddress();
    }
    clientIpAddress() {
        axios.get("http://jsonip.com/").then(response => {
            if (response.status === 200) {
                this.setState({
                    clientIpAdrees: response.data.ip
                });
            }
        }).catch(err => {
            // console.log('Error - ', err);
        });
    }
    facebookCustomButton() {
        return (<span>
            <img src={process.env.PUBLIC_URL + "/images/facebook-login.svg"} alt="Facebook" /><span>{strings.signUpWIthFacebook}</span></span>
        );
    }
    emailSignUpBox() {
        this.setState({
            emailSignupBox: !this.state.emailSignupBox
        });
    }
    onSubmitEmailConfirmation(e) {
        e.preventDefault();
        this.setState({
            signupEmailError: '',
            successMessage: '',
            acceptanceTerm: ''
        });
        var isValid = false;
        var isValidEmailAddress = false;
        if (this.state.confirmEmail === '') {
            this.setState({
                signupEmailError: strings.enterEmail
            });
        } else if (emailReg.test(this.state.confirmEmail) === false) {
            this.setState({
                signupEmailError: strings.enterValidEmail
            });
        } else {
            isValid = true;
        }
        if (isValid) {
            if (!this.state.isAgree) {
                this.setState({
                    acceptanceTerm: strings.ourTermsConditions
                });
            } else {
                var apiParams = {
                    email: this.state.confirmEmail,
                };
                var headers = {
                    'Content-Type': 'application/json',
                    'guest-regs-token': signUpGuestToken
                };
                axios.post(api_base_url + '/api/register-email/', apiParams, { headers: headers }).then(response => {
                    if (response.data.status === true) {
                        this.setState({
                            successMessage: strings.registrationEmailSent
                        });
                    } else {
                        this.setState({
                            signupEmailError: response.data.data
                        });
                    }
                }).catch(err => {
                    // console.log('Error - ', err);
                });
            }
        }
    }
    render() {
        const componentClicked = () => {
            this.setState({
                socialLoginError: ''
            });
        }
        const responseSuccessGoogle = (googleResponse) => {

            if (googleResponse['profileObj'] && googleResponse['accessToken']) {
                this.setState({
                    isLoading: true
                });
                var apiParams = {
                    login_type: "google",
                    email: googleResponse['profileObj']['email'] || "",
                    uid: googleResponse['profileObj']['googleId'] || "",
                    access_token: googleResponse['accessToken'] || "",
                    customer_ip_address: this.state.clientIpAdrees,
                    extra_data: googleResponse || {}
                };
                var headers = {
                    'Content-Type': 'application/json',
                    'guest-login-token': loginGuestToken
                };
                axios.post(api_base_url + '/api/v1/login/', apiParams, { headers: headers }).then(response => {
                    this.setState({
                        isLoading: false
                    });
                    if (response.data['status'] === '1') {
                        if (this.props.isFromNewSignUp) {
                            setTimeout(() => {
                                this.props.history.push('/library');
                            }, 100);
                        } else {
                            this.props.history.push('/');
                        }
                        return this.props.dispatch(userAuthentication(googleResponse['profileObj']['email'] || "",
                            response.data['token'],
                            response.data['is_subscribed'],
                            response.data['first_name'],
                            response.data['last_name'],
                            response.data['profile_image'],
                            response.data['country_code'],
                            response.data['state'],
                            response.data['mobile'],
                            response.data['instituteName']));
                    } else if (response.data['status'] === '2' || response.data['status'] === '10' || response.data['status'] === '11') {
                        this.setState({
                            socialLoginError: response.data['message']
                        });
                    }
                }).catch(err => {
                    // console.log('Error - ', err);
                    this.setState({
                        isLoading: false
                    });
                });
            }
        }
        const responseFailureGoogle = (error) => {
            // console.log('Google Error', error);
        }
        const responseFacebook = (socialLoginResponse) => {

            if (socialLoginResponse['email'] != undefined) {
                this.setState({
                    isLoading: true
                });
                var apiParams = {
                    login_type: "facebook",
                    email: socialLoginResponse['email'] || "",
                    uid: socialLoginResponse['userID'] || "",
                    access_token: socialLoginResponse['accessToken'] || "",
                    customer_ip_address: this.state.clientIpAdrees,
                    extra_data: socialLoginResponse || {}
                };
                var headers = {
                    'Content-Type': 'application/json',
                    'guest-login-token': loginGuestToken
                };
                axios.post(api_base_url + '/api/v1/login/', apiParams, { headers: headers }).then(response => {
                    this.setState({
                        isLoading: false
                    });
                    if (response.data['status'] === '1') {
                        if (this.props.isFromNewSignUp) {
                            setTimeout(() => {
                                this.props.history.push('/library');
                            }, 100);
                        } else {
                            this.props.history.push('/');
                        }
                        return this.props.dispatch(userAuthentication(socialLoginResponse['email'] || "",
                            response.data['token'],
                            response.data['is_subscribed'],
                            response.data['first_name'],
                            response.data['last_name'],
                            response.data['profile_image'],
                            response.data['country_code'],
                            response.data['state'],
                            response.data['mobile'],
                            response.data['instituteName']));
                    } else if (response.data['status'] === '2' || response.data['status'] === '10' || response.data['status'] === '11') {
                        this.setState({
                            socialLoginError: response.data['message']
                        });
                    }
                }).catch(err => {
                    // console.log('Error - ', err);
                    this.setState({
                        isLoading: false
                    });
                });
            }
        }
        return (
            <div className="signupWrapper">
                {!this.props.isFromNewSignUp ?
                    <div className="faceLogin">
                        <FacebookLogin
                            appId={facebookAppId}
                            fields="name,email,picture"
                            onClick={componentClicked}
                            textButton={this.facebookCustomButton()}
                            callback={responseFacebook} />
                    </div>
                    :
                    null
                }
                <div className="googleLogin">
                    <GoogleLogin
                        clientId={googleClientId}
                        render={renderProps => (
                            <button class="google-login-button" onClick={renderProps.onClick} disabled={renderProps.disabled}><img src={process.env.PUBLIC_URL + "/images/google.svg"} alt="Gmail" /><span>{strings.signUpWIthGoogle}</span></button>
                        )}
                        buttonText="Login"
                        onSuccess={responseSuccessGoogle}
                        onFailure={responseFailureGoogle}
                        cookiePolicy={'single_host_origin'}
                    />
                </div>
                <div class="signup-error-outer">
                    <div class="loginErrorMsg">{this.state.socialLoginError}</div>
                </div>
                <div className="signupWithMail" onClick={this.emailSignUpBox}>
                    <img src={process.env.PUBLIC_URL + "/images/mail-signup.svg"} alt="Mail" /><span>{strings.signUpWithEMail}</span>
                </div>
                {this.state.emailSignupBox ? <div className="ForgetPassword" isOpen={this.state.profileOpen} toggle={this.profileOpenWrap}>
                    <form id="register-form" onSubmit={this.onSubmitEmailConfirmation} autocomplete="off" class="form">
                        <FormGroup>
                            <Input
                                type="text"
                                name="forgetemail"
                                value={this.state.confirmEmail}
                                id="forgetEmail"
                                placeholder={strings.emailText}
                                onChange={(event) => { this.setState({ confirmEmail: event.target.value }); }} />
                        </FormGroup>
                        <div className="loginErrorMsg">{this.state.signupEmailError}</div>
                        <div className="resetPasswordSuccess">{this.state.successMessage}</div>
                        <div class="form-group">
                            <input name="recover-submit" class="btn btn-lg btn-primary btn-block" value="Send" type="submit" />
                        </div>
                    </form>
                </div> : null}
                <div className="signupToLogin">
                    <span>{strings.Alreadyaccount} <a href="/sign-in">{strings.signInSmall}</a>.</span>
                </div>
                <div class="loginErrorMsg">{this.state.acceptanceTerm}</div>
                <div className="billCheckBx emailSign-upBox">
                    <label className="checkbox"><a href="/terms-of-service">{strings.termsAndConditions}</a>
                        <input
                            type="checkbox"
                            name="agreeCheckbox"
                            id="agreeCheckbox"
                            defaultChecked={this.state.isAgree}
                            onChange={(event) => { this.setState({ isAgree: event.target.checked }); }}
                        /><span className="checkmarkbx"></span>
                    </label>
                </div>

            </div>
        );
    }
}

function mapStatesToProps(globalData) {
    return {
        authData: globalData.authonticationDetails
    };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(EmailSignUpConfirmation));