import axios from "axios";
import { api_base_url, guestSubscriptionToken } from "../config";

export function subscriptionListAction() {
  return function (dispatch) {
    var headers = {
      "Content-Type": "application/json",
      "guest-subscription-token": guestSubscriptionToken,
    };
    dispatch({
      type: "SHOW_LOADER",
    });
    axios
      .get(api_base_url + "/api/subscription-list/", {
        headers: headers,
      })
      .then((response) => {

        if (response.status == 200) {

          let user = []
          for (let responseJson of response.data) {
            user.push(responseJson)
          }

          let valueofSub = user.sort((x, y) => Number(x.subscription_duration) > Number(y.subscription_duration) ? 1 : -1
          )



          let sortedSubscriptionList = valueofSub.filter((item, index) => item.id != (item.can_purchase == false ? item.id : ""))

          sortedSubscriptionList.push(valueofSub.filter((item, index) => item.id == (item.can_purchase == false ? item.id : ""))[0])



          // console.log("sortedSubscriptionList", response.data)


          dispatch({
            type: "SUBSCRIPTION_LIST",
            payload: sortedSubscriptionList || [],
          })
          dispatch({
            type: "HIDE_LOADER",
          });
        } else {
          dispatch({
            type: "FAILED_SUBSCRIPTION_LIST",
            payload: [],
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
        dispatch({
          type: "HIDE_LOADER",
        });
      });
  };
}
