import React, { Component } from "react";
import { api_base_url, s3Url, s3Bucket, s3Playlist } from "../../config";
import $ from "jquery";
import videojs from "video.js";
import "../../style/video-js.css";
import "../../style/video-js-custom.css";
class HlsPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoJsObject: {},
    };
  }
  componentDidMount() {
    var newUrl = s3Url + s3Bucket + this.props.newKey + s3Playlist;
    var anotherKey = this.props.newKey;
    videojs.Hls.xhr.beforeRequest = function (options) {
      var data = {
        url: options.uri,
        key: anotherKey,
      };
      $.ajax({
        type: "POST",
        url: api_base_url + "/api/get-track-signed-url-example/",
        data: data,
        async: false,
        success: function (data, textStatus, request) {
          if (request.getResponseHeader("response")) {
            options.uri = options.uri.replace(
              options.uri,
              request.getResponseHeader("response")
            );
          }
        },
        error: function (data, textStatus, request) {},
      });
      return options;
    };
    videojs.options.hls.overrideNative = true;
    videojs.options.html5.nativeAudioTracks = false;
    videojs.options.html5.nativeTextTracks = false;
    var player = videojs("video" + this.props.trackId, {
      controls: true,
      autoplay: true,
      preload: "auto",
      inactivityTimeout: 0,
    });
    this.setState({ videoJsObject: player });
    var parentThis = this;
    var timeUpdate = 0;
    var mouseDownTimeUpdate = 0;
    player.ready(function () {
      this.src({
        src: newUrl,
        type: "application/x-mpegURL",
      });
      var mouseUpTime = 0;
      var mouseDownTime = 0;
      this.controlBar.progressControl.seekBar.on("mousedown", function (event) {
        mouseDownTimeUpdate = parseInt(timeUpdate);
        mouseDownTime = parseInt(player.currentTime());
      });
      this.controlBar.progressControl.seekBar.on("mouseup", function (event) {
        mouseUpTime = parseInt(player.currentTime());
      });
      this.controlBar.progressControl.on("mousedown", function (event) {
        mouseDownTimeUpdate = parseInt(timeUpdate);
        mouseDownTime = parseInt(player.currentTime());
      });
      this.controlBar.progressControl.on("mouseup", function (event) {
        mouseUpTime = parseInt(player.currentTime());
        if (mouseDownTime != mouseUpTime) {
          parentThis.props.onDrag(mouseDownTime, mouseUpTime);
        } else {
          parentThis.props.onDrag(mouseDownTimeUpdate, mouseUpTime);
        }
        mouseDownTime = 0;
        mouseDownTimeUpdate = 0;
        mouseUpTime = 0;
      });
    });
    player.currentTime(this.props.startTime);
    player.on("play", function () {
      parentThis.props.onPlay(player.currentTime());
    });
    player.on("pause", function () {
      parentThis.props.onPause(player.currentTime());
    });
    player.on("ended", function () {
      parentThis.props.onEnded(player.currentTime());
    });
    player.on("timeupdate", function () {
      timeUpdate = parseInt(player.currentTime());
      parentThis.props.onTimeUpdate(player.currentTime());
    });
  }

  onFastForward() {
    const player = this.state.videoJsObject;
    player.currentTime(parseInt(player.currentTime()) + 10);
  }

  onRewind() {
    const player = this.state.videoJsObject;
    player.currentTime(parseInt(player.currentTime()) - 10);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isPausePlayer &&
      !this.props.isPausePlayer &&
      this.state.videoJsObject
    ) {
      this.state.videoJsObject.pause();
    }
    if (
      nextProps.isPlayPlayer &&
      !this.props.isPlayPlayer &&
      this.state.videoJsObject
    ) {
      this.state.videoJsObject.play();
    }
  }
  componentWillUnmount() {
    if (this.state.videoJsObject) {
      this.state.videoJsObject.dispose();
    }
  }
  render() {
    return (
      <div>
        <video id={"video" + this.props.trackId} class="video-js"></video>
      </div>
    );
  }
}

export default HlsPlayer;
