const initialState = {
    familyCategoryList: [],
}
export default function familyHomeCategoryReducer (state = initialState, action) {
    switch (action.type) {
        case 'FAMILY_CATEGORY_LIST': {
            return { ...state, familyCategoryList: action.payload };
        }
        case 'FAILED_FAMILY_CATEGORY_LIST': {
            return { ...state, familyCategoryList:[] };
        }
        default: {
            return { ...state };
        }
    }
}