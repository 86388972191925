const initialState = {
    userSubscriptionDetails:[],
    userProfileDetails: [],
    shortName: ''
}

export default function userSubscriptionReducer (state = initialState, action) {
    switch (action.type) {
        case 'MY_SUBSCRIPTION_DATA': {
            return { ...state, userSubscriptionDetails: action.payload};
        }
        case 'USER_PROFILE_DETAILS': {
            var user_profile = action.payload;
            var firstName = user_profile['first_name'] || '';
            var lastName = user_profile['last_name'] || '';
            var newShortName = '';
                if(firstName == '' && lastName == ''){
                    newShortName = 'UN'
                }else{
                    if(firstName != ''){
                        var firstNameLetter = firstName.charAt(0);
                        newShortName = firstNameLetter.toUpperCase();
                    }
                    if(lastName != ''){
                        var lastNameLetter = lastName.charAt(0);
                        newShortName = newShortName + lastNameLetter.toUpperCase();
                    }
                }
            return { ...state, shortName:newShortName, userProfileDetails: action.payload};
        }
        case 'FAILED_USER_PROFILE_DETAILS': {
            return { ...state};
        }
        default: {
            return { ...state };
        }
    }
}