import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, NavLink } from "reactstrap";
import { api_base_url, encryptDecryptKey, getHoursFormat } from "../../config";
import axios from "axios";
import LocalizedStrings from "react-localization";
import SimpleCrypto from "simple-crypto-js";
import { browseCategoryProductDetailsDataAction } from "../../action/browseCategoryAction";
import StarRating from "../rating/startRating";
import $ from "jquery";
import languages from "../../language/language.json";
import { setTimeout } from "timers";
var strings = new LocalizedStrings(languages);

class PurchasedInLibraryTab extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(this.props.selectedLanguage);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    this.state = {
      token: token,
      selectedProductIndex: "",
      selectedTrackIndex: 0,
      selectedProductId: "",
      isAllowUpdate: true,
      isPaused: false,
      isLoading: true,
      showTrackProductId: "",
      selectedProductIndexMySnovel: "",
      selectedTrackIndexMySnovel: 0,
      isPausedMySnovel: false,
      selectedProductIdMySnovel: "",
      isAllowUpdateMySnovel: true,
    };
  }
  /**********************
   *  Author details page *
   **********************/
  authorDetailsId(authorID, authorName) {
    var titleUrl = authorName.toLowerCase();
    var authorTitle = titleUrl.replace(".", "");
    var authorName = authorTitle.split(" ").join("-");
    this.props.history.push("/author/" + authorID + "/" + authorName);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener("scroll", this.trackScrolling);
  }
  componentWillReceiveProps(nextProps) {
    if (
      Object.keys(nextProps.selectedProductToPlay.selectedProduct).length > 0 &&
      nextProps.selectedProductToPlay.isPlayFromSection == "purchasedInLibrary"
    ) {
      var newSelectedProduct = nextProps.selectedProductToPlay.selectedProduct;
      var newTrackIndex = nextProps.selectedProductToPlay.selectedTrackIndex;
      var newPauseStatus = nextProps.selectedProductToPlay.isPauseFromPlayer;
      this.setState({
        selectedProductId: newSelectedProduct.id,
        selectedTrackIndex: newTrackIndex,
        isPaused: newPauseStatus,
      });
    } else if (
      Object.keys(nextProps.selectedProductToPlay.selectedProduct).length > 0
    ) {
      this.setState({
        selectedProductIndex: "",
        selectedTrackIndex: 0,
        selectedProductId: "",
        isAllowUpdate: true,
        isPaused: false,
      });
    }

    if (
      Object.keys(nextProps.selectedProductToPlay.selectedProduct).length > 0 &&
      nextProps.selectedProductToPlay.isPlayFromSection == "mySnovel"
    ) {
      var newSelectedProduct = nextProps.selectedProductToPlay.selectedProduct;
      var newTrackIndex = nextProps.selectedProductToPlay.selectedTrackIndex;
      var newPauseStatus = nextProps.selectedProductToPlay.isPauseFromPlayer;
      this.setState({
        selectedProductIdMySnovel: newSelectedProduct.id,
        selectedTrackIndexMySnovel: newTrackIndex,
        isPausedMySnovel: newPauseStatus,
      });
    } else if (
      Object.keys(nextProps.selectedProductToPlay.selectedProduct).length > 0
    ) {
      this.setState({
        selectedProductIndexMySnovel: "",
        selectedTrackIndexMySnovel: 0,
        selectedProductIdMySnovel: "",
        isAllowUpdateMySnovel: true,
        isPausedMySnovel: false,
      });
    }

    if (
      nextProps.selectedTrackIdReducer.selectedTrackId !== 0 &&
      nextProps.selectedTrackIdReducer.selectedProductId !== ""
    ) {
      if (nextProps.selectedTrackIdReducer.fromType == "notification") {
        var scrollProductId =
          "#library" +
          nextProps.selectedTrackIdReducer.selectedProductId +
          "track" +
          nextProps.selectedTrackIdReducer.selectedTrackId;
        $(
          "#library" +
          nextProps.selectedTrackIdReducer.selectedProductId +
          " .videoThumbDiv"
        ).trigger("click");
        $(
          "#library" +
          nextProps.selectedTrackIdReducer.selectedProductId +
          " .videoPlayList:first-child .videoPlayP"
        ).trigger("click");
        setTimeout(() => {
          $(scrollProductId + " .videoPlayP").trigger("click");
        }, 200);
      }
      this.props.dispatch({
        type: "FAILED_SELECTED_TRACK_ID",
        payload: ["", 0],
      });
    }
    if (
      nextProps.selectedTrackIdReducer.selectedProductId !== "" &&
      nextProps.selectedTrackIdReducer.productType !== ""
    ) {
      var scrollProductId =
        "#library" + nextProps.selectedTrackIdReducer.selectedProductId;
      // setTimeout(() => {
      //   $("html, body").animate(
      //     { scrollTop: $(scrollProductId).offset().top - 50 },
      //     "slow"
      //   );
      // }, 1000);
      this.props.dispatch({
        type: "FAILED_SELECTED_PRODUCT_DETAILS",
        payload: ["", ""],
      });
    }
    if (nextProps.isStartScrollEventListener === true) {
      document.addEventListener("scroll", this.trackScrolling);
    } else {
      document.removeEventListener("scroll", this.trackScrolling);
    }
    var selectedLanguage = nextProps.selectedLanguage;
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    }
  }

  showPlayerForProduct(productIndex) {
    if (this.props.purchasedProducts[productIndex].producttracks.length > 0) {
      if (
        this.state.selectedProductIndex === "" ||
        this.state.selectedProductIndex !== productIndex
      ) {
        this.showPlayerForProductAfterAnalytics(productIndex);
        var headers = {
          "Content-Type": "application/json",
        };
        axios
          .get(api_base_url + "/api/player-analytics/", { headers: headers })
          .then((response) => { })
          .catch((err) => {
            // console.log("error ", err);
          });
      } else {
        this.setState({
          selectedProductIndex: "",
          selectedTrackIndex: 0,
          isPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            {},
            0,
            false,
            false,
            false,
            false,
            false,
            "purchasedInLibrary",
          ],
        });
      }
    }
  }
  showPlayerForProductAfterAnalytics(productIndex) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      product_id: this.props.purchasedProducts[productIndex].id,
    };
    axios
      .post(api_base_url + "/api/last-played/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var latestTrackId = response.data.data.track_id || "";
          var newSelectedTrackIndex = 0;
          for (
            var i = 0;
            i < this.props.purchasedProducts[productIndex].producttracks.length;
            i++
          ) {
            var eachTrack = this.props.purchasedProducts[productIndex]
              .producttracks[i];
            if (eachTrack.id === latestTrackId) {
              newSelectedTrackIndex = i;
            }
          }
          this.setState({
            selectedProductIndex: productIndex,
            selectedTrackIndex: newSelectedTrackIndex,
            isPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.props.purchasedProducts[productIndex],
              newSelectedTrackIndex,
              false,
              false,
              false,
              false,
              "purchasedInLibrary",
            ],
          });
        } else {
          this.setState({
            selectedProductIndex: productIndex,
            selectedTrackIndex: 0,
            isPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.props.purchasedProducts[productIndex],
              0,
              false,
              false,
              false,
              false,
              "purchasedInLibrary",
            ],
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          selectedProductIndex: productIndex,
          selectedTrackIndex: 0,
          isPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.props.purchasedProducts[productIndex],
            0,
            false,
            false,
            false,
            false,
            "purchasedInLibrary",
          ],
        });
      });
  }

  authorsSelected(authorsList) {
    var authorArray = [];
    authorsList.map((authorName) => {
      if (
        authorName.custom_translation[this.props.selectedLanguage] != undefined
      ) {
        authorArray.push(
          authorName.custom_translation[this.props.selectedLanguage].name
        );
      }
    });
    return authorArray.join(", ");
  }

  directorsSelected(directorList) {
    var directorArray = [];
    directorList.map((directorName) => {
      if (
        directorName.custom_translation[this.props.selectedLanguage] !=
        undefined
      ) {
        directorArray.push(
          directorName.custom_translation[this.props.selectedLanguage].name
        );
      }
    });
    return directorArray.join(", ");
  }

  artistsSelected(artistsList) {
    var artistsArray = [];
    artistsList.map((artistsName) => {
      if (
        artistsName.custom_translation[this.props.selectedLanguage] != undefined
      ) {
        artistsArray.push(
          artistsName.custom_translation[this.props.selectedLanguage].name
        );
      }
    });
    return artistsArray.join(", ");
  }

  categorySelected(categoryList) {
    var categoryArray = [];
    categoryList.map((categoryName) => {
      if (
        categoryName.custom_translation[this.props.selectedLanguage] !=
        undefined
      ) {
        categoryArray.push(
          categoryName.custom_translation[this.props.selectedLanguage].name
        );
      }
    });
    return categoryArray.join(", ");
  }

  playProductTrack(trackIndex) {
    if (this.state.selectedTrackIndex !== trackIndex) {
      this.props.dispatch({
        type: "PLAY_SELECTED_PRODUCT",
        payload: [
          this.props.purchasedProducts[this.state.selectedProductIndex],
          trackIndex,
          false,
          false,
          false,
          false,
          "purchasedInLibrary",
        ],
      });
      this.setState({
        selectedTrackIndex: trackIndex,
        isPaused: false,
      });
    } else {
      if (this.state.isPaused) {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.props.purchasedProducts[this.state.selectedProductIndex],
            trackIndex,
            false,
            true,
            false,
            false,
            "purchasedInLibrary",
          ],
        });
        this.setState({
          isPaused: false,
        });
      } else {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.props.purchasedProducts[this.state.selectedProductIndex],
            trackIndex,
            true,
            false,
            false,
            false,
            "purchasedInLibrary",
          ],
        });
        this.setState({
          isPaused: true,
        });
      }
    }
  }
  isBottom(el) {
    if (this.props.purchasedProducts.length > 0) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
  }

  componentWillUnmount() {
    if (this.props.purchasedProducts.length > 0) {
      document.removeEventListener("scroll", this.trackScrolling);
    }
  }
  trackScrolling = () => {
    const wrappedElement = document.getElementById("scrollApiContent");
    if (this.isBottom(wrappedElement)) {
      document.removeEventListener("scroll", this.trackScrolling);
      this.props.changeOffset();
    }
  };
  /***********  Product details page link *****/
  productDetailsId(productID, slug) {
    this.props.history.push("/product-details/" + productID + "/" + slug);
  }
  /****************************************************
   *     MY SNOVEL
   ****************************************************/
  showPlayerForProductMySnovel(productIndex) {
    if (this.props.mySnovelProducts[productIndex].producttracks.length > 0) {
      if (
        this.state.selectedProductIndexMySnovel === "" ||
        this.state.selectedProductIndexMySnovel !== productIndex
      ) {
        this.showPlayerForProductAfterAnalyticsMySnovel(productIndex);
        var headers = {
          "Content-Type": "application/json",
        };
        axios
          .get(api_base_url + "/api/player-analytics/", { headers: headers })
          .then((response) => { })
          .catch((err) => {
            // console.log("error ", err);
          });
      } else {
        this.setState({
          selectedProductIndexMySnovel: "",
          selectedTrackIndexMySnovel: 0,
          isPausedMySnovel: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [{}, 0, false, false, false, false, "mySnovel"],
        });
      }
    }
  }
  showPlayerForProductAfterAnalyticsMySnovel(productIndex) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      product_id: this.props.mySnovelProducts[productIndex].id,
    };
    axios
      .post(api_base_url + "/api/last-played/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var latestTrackId = response.data.data.track_id || "";
          var newSelectedTrackIndex = 0;
          for (
            var i = 0;
            i < this.props.mySnovelProducts[productIndex].producttracks.length;
            i++
          ) {
            var eachTrack = this.props.mySnovelProducts[productIndex]
              .producttracks[i];
            if (eachTrack.id === latestTrackId) {
              newSelectedTrackIndex = i;
            }
          }
          this.setState({
            selectedProductIndexMySnovel: productIndex,
            selectedTrackIndexMySnovel: newSelectedTrackIndex,
            isPausedMySnovel: false,
          });
          let newSelectedProduct = this.props.mySnovelProducts[productIndex];
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              newSelectedProduct,
              newSelectedTrackIndex,
              false,
              false,
              false,
              false,
              "mySnovel",
            ],
          });
        } else {
          this.setState({
            selectedProductIndexMySnovel: productIndex,
            selectedTrackIndexMySnovel: 0,
            isPausedMySnovel: false,
          });
          let newSelectedProduct = this.props.mySnovelProducts[productIndex];
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              newSelectedProduct,
              0,
              false,
              false,
              false,
              false,
              "mySnovel",
            ],
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          selectedProductIndexMySnovel: productIndex,
          selectedTrackIndexMySnovel: 0,
          isPausedMySnovel: false,
        });
        let newSelectedProduct = this.props.mySnovelProducts[productIndex];
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            newSelectedProduct,
            0,
            false,
            false,
            false,
            false,
            "mySnovel",
          ],
        });
      });
  }
  playProductTrackMySnovel(trackIndex) {
    if (this.state.selectedTrackIndexMySnovel !== trackIndex) {
      let newSelectedProduct = this.props.mySnovelProducts[
        this.state.selectedProductIndexMySnovel
      ];
      this.props.dispatch({
        type: "PLAY_SELECTED_PRODUCT",
        payload: [
          newSelectedProduct,
          trackIndex,
          false,
          false,
          false,
          false,
          "mySnovel",
        ],
      });
      this.setState({
        selectedTrackIndexMySnovel: trackIndex,
        isPausedMySnovel: false,
      });
    } else {
      let newSelectedProduct = this.props.mySnovelProducts[
        this.state.selectedProductIndexMySnovel
      ];
      if (this.state.isPausedMySnovel) {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            newSelectedProduct,
            trackIndex,
            false,
            true,
            false,
            false,
            "mySnovel",
          ],
        });
        this.setState({
          isPausedMySnovel: false,
        });
      } else {
        let newSelectedProduct = this.props.mySnovelProducts[
          this.state.selectedProductIndexMySnovel
        ];
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            newSelectedProduct,
            trackIndex,
            true,
            false,
            false,
            false,
            "mySnovel",
          ],
        });
        this.setState({
          isPausedMySnovel: true,
        });
      }
    }
  }

  render() {
    return (
      <Row>
        <Col sm="12" className="mySnovel">
          {this.props.mySnovelProducts.length == 0 && <p></p>}
          {this.props.mySnovelProducts.length > 0 && (
            <div>
              <p>{strings.myLastPlayedTitle}</p>
              {this.props.mySnovelProducts.map((eachProduct, selectedIndex) => {
                if (
                  eachProduct.id == this.state.selectedProductIdMySnovel &&
                  this.state.isAllowUpdateMySnovel
                ) {
                  this.setState({
                    selectedProductIndexMySnovel: selectedIndex,
                    isAllowUpdateMySnovel: false,
                  });
                }
                return (
                  <div>
                    <div class="mySnovelVideo">
                      <Row className="left-right-margin-none">
                        <Col md="10" lg="10">
                          <div
                            class="videoThumbDiv"
                            onClick={() => {
                              this.showPlayerForProductMySnovel(selectedIndex);
                            }}
                          >
                            {eachProduct.images.length > 0 ? (
                              <img
                                class="purchase-library-image"
                                src={eachProduct.images[0]["image2"]}
                                alt=""
                              />
                            ) : (
                              <img
                                class="purchase-library-image"
                                src="/images/default.jpg"
                                alt=""
                              />
                            )}
                            <a title="" class="playButn">
                              {this.state.selectedProductIndexMySnovel !==
                                selectedIndex && (
                                  <img src="/images/play-button.svg" alt="" />
                                )}
                              {/* {this.state.selectedProductIndexMySnovel ===
                                selectedIndex && (
                                <img
                                  src="/images/round-pause-button.svg"
                                  alt=""
                                />
                              )} */}
                            </a>
                          </div>
                          <div class="videoDesc">
                            <h4>
                              <span
                                onClick={() => {
                                  this.productDetailsId(
                                    eachProduct.id,
                                    eachProduct.product_slug
                                  );
                                }}
                              >
                                {
                                  eachProduct.custom_translation[
                                  this.props.selectedLanguage
                                  ]["name"]
                                }
                              </span>
                            </h4>
                            <span class="cateTitle">
                              {strings[eachProduct.product_audio_type]}
                            </span>
                            {eachProduct.authors.length > 0 && (
                              <div class="videoCnt">
                                <div className="headingText">
                                  <span class="author">
                                    {strings.authorText}
                                  </span>
                                </div>
                                <div className="colon-symbol">:</div>
                                <div className="loopdata">
                                  <span
                                    class="authorDes authorDesLink"
                                    onClick={() => {
                                      this.authorDetailsId(
                                        eachProduct.authors[0].id,
                                        eachProduct.authors[0].name
                                      );
                                    }}
                                  >
                                    {this.authorsSelected(eachProduct.authors)}
                                  </span>
                                </div>
                              </div>
                            )}
                            {eachProduct.directors.length > 0 && (
                              <div class="videoCnt">
                                <div className="headingText">
                                  <span class="author">
                                    {strings.directorText}
                                  </span>
                                </div>
                                <div className="colon-symbol">:</div>
                                <div className="loopdata">
                                  <span class="authorDes">
                                    {this.directorsSelected(
                                      eachProduct.directors
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                            {eachProduct.artists.length > 0 && (
                              <div class="videoCnt">
                                <div className="headingText">
                                  <span class="author">
                                    {strings.artistsText}
                                  </span>
                                </div>
                                <div className="colon-symbol">:</div>
                                <div className="loopdata">
                                  <span class="authorDes">
                                    {this.artistsSelected(eachProduct.artists)}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div class="videoCnt">
                              <div className="headingText">
                                <span class="author">{strings.genreText}</span>
                              </div>
                              <div className="colon-symbol">:</div>
                              <div className="loopdata">
                                {eachProduct.category.map(
                                  (categoryList, index) => {
                                    if (
                                      categoryList.custom_translation[
                                      this.props.selectedLanguage
                                      ] != undefined
                                    ) {
                                      return (
                                        <NavLink
                                          tag={Link}
                                          to={{
                                            pathname: "/browse",
                                          }}
                                          onClick={() => {
                                            this.props.dispatch({
                                              type: "MENU_SELECTED_CATEGORY",
                                              payload: [
                                                categoryList.name,
                                                eachProduct.product_audio_type,
                                                categoryList.id,
                                              ],
                                            });
                                            this.props.dispatch(
                                              browseCategoryProductDetailsDataAction(
                                                eachProduct.product_audio_type,
                                                categoryList.name,
                                                1
                                              )
                                            );
                                          }}
                                          key={index}
                                          className="genreLinkText"
                                        >
                                          <span key={index}>
                                            {
                                              categoryList.custom_translation[
                                                this.props.selectedLanguage
                                              ].name
                                            }
                                          </span>
                                        </NavLink>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </div>
                            <div
                              class="videoCnt"
                              onClick={() => {
                                this.showPlayerForProductMySnovel(selectedIndex);
                              }}
                            >
                              <span className="subsCribeButton playAll">
                                {strings.playAll}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col md="2" lg="2">
                          <div class="mySnoveltrackDiv">
                            <span>
                              <strong>
                                {eachProduct.producttracks.length}
                              </strong>
                              <br />
                              {strings.trackText}
                            </span>
                          </div>
                          <div class="mySnoveldurDiv">
                            <span>
                              {strings.durationText}
                              <br />
                              <strong>
                                {getHoursFormat(
                                  eachProduct.total_tracks_length
                                ) +
                                  " " +
                                  strings.hrsText}
                              </strong>
                            </span>
                          </div>
                          <StarRating rating={eachProduct.total_reviews} />
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>

                    {this.state.selectedProductIndexMySnovel ===
                      selectedIndex && (
                        <div>
                          {eachProduct.producttracks.map(
                            (eachTrack, trackIndex) => {
                              return (
                                <div
                                  class={
                                    this.state.selectedTrackIndexMySnovel ==
                                      trackIndex
                                      ? "videoPlayList active-play-list"
                                      : "videoPlayList"
                                  }
                                >
                                  <div
                                    class="videoPlayP"
                                    onClick={() => {
                                      this.playProductTrackMySnovel(trackIndex);
                                    }}
                                  >
                                    <img
                                      src={
                                        this.state.selectedTrackIndexMySnovel ==
                                          trackIndex &&
                                          !this.state.isPausedMySnovel
                                          ? "/images/p_video.png"
                                          : "/images/v_play.png"
                                      }
                                    />
                                  </div>
                                  <div class="videoPlayTitle">
                                    <span>{trackIndex + 1}</span>
                                    {eachTrack.custom_translation[
                                      this.props.selectedLanguage
                                    ]
                                      ? eachTrack.custom_translation[
                                        this.props.selectedLanguage
                                      ].name
                                      : eachTrack.custom_translation["EN"].name}
                                  </div>
                                  <div class="videoPlayDuration">
                                    {strings.durationText}
                                    <br />
                                    <span>
                                      {getHoursFormat(eachTrack.track_length) +
                                        " " +
                                        strings.hrsText}
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                  </div>
                );
              })}
              <div className="clearfix"></div>
              <div className="library-player"></div>
            </div>
          )}
        </Col>

        <Col sm="12" className="mySnovel">
          {this.props.purchasedProducts.length == 0 && <p>Not Purchased yet</p>}
          {this.props.purchasedProducts.length > 0 && (
            <div id="scrollApiContent">
              {this.props.purchasedProducts.map(
                (eachProduct, selectedIndex) => {
                  if (
                    eachProduct.id == this.state.selectedProductId &&
                    this.state.isAllowUpdate
                  ) {
                    this.setState({
                      selectedProductIndex: selectedIndex,
                      isAllowUpdate: false,
                    });
                  }
                  return (
                    <div id={"library" + eachProduct.id}>
                      <div class="mySnovelVideo purchasedLibraryWrap">
                        <Row className="left-right-margin-none">
                          <Col md="10" lg="10">
                            <div
                              class="videoThumbDiv"
                              onClick={() => {
                                this.showPlayerForProduct(selectedIndex);
                              }}
                            >
                              {eachProduct.images.length > 0 ? (
                                <img
                                  class="purchase-library-image"
                                  src={eachProduct.images[0]["image2"]}
                                  alt=""
                                />
                              ) : (
                                <img
                                  class="purchase-library-image"
                                  src="/images/default.jpg"
                                  alt=""
                                />
                              )}
                              <a title="" class="playButn">
                                {this.state.selectedProductIndex !==
                                  selectedIndex && (
                                    <img src="/images/play-button.svg" alt="" />
                                  )}
                                {/* {this.state.selectedProductIndex ===
                                  selectedIndex && (
                                  <img
                                    src="/images/round-pause-button.svg"
                                    alt=""
                                  />
                                )} */}
                              </a>
                            </div>
                            <div class="videoDesc">
                              <h4>
                                {eachProduct.custom_translation[
                                  this.props.selectedLanguage
                                ] ? (
                                  <span
                                    onClick={() => {
                                      this.productDetailsId(
                                        eachProduct.id,
                                        eachProduct.product_slug
                                      );
                                    }}
                                  >
                                    {
                                      eachProduct.custom_translation[
                                      this.props.selectedLanguage
                                      ]["name"]
                                    }
                                  </span>
                                ) : (
                                  <span>
                                    {
                                      eachProduct.custom_translation["EN"][
                                      "name"
                                      ]
                                    }
                                  </span>
                                )}
                              </h4>
                              <span class="cateTitle">
                                {strings[eachProduct.product_audio_type]}
                              </span>
                              {eachProduct.authors.length > 0 && (
                                <div class="videoCnt">
                                  <div className="headingText">
                                    <span class="author">
                                      {strings.authorText}
                                    </span>
                                  </div>
                                  <div className="colon-symbol">:</div>
                                  <div className="loopdata">
                                    <span
                                      class="authorDes authorDesLink"
                                      onClick={() => {
                                        this.authorDetailsId(
                                          eachProduct.authors[0].id,
                                          eachProduct.authors[0].name
                                        );
                                      }}
                                    >
                                      {this.authorsSelected(
                                        eachProduct.authors
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {eachProduct.directors.length > 0 && (
                                <div class="videoCnt">
                                  <div className="headingText">
                                    <span class="author">
                                      {strings.directorText}
                                    </span>
                                  </div>
                                  <div className="colon-symbol">:</div>
                                  <div className="loopdata">
                                    <span class="authorDes">
                                      {this.directorsSelected(
                                        eachProduct.directors
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {eachProduct.artists && (
                                <div class="videoCnt">
                                  <div className="headingText">
                                    <span class="author">
                                      {strings.artistsText}
                                    </span>
                                  </div>
                                  <div className="colon-symbol">:</div>
                                  <div className="loopdata">
                                    <span class="authorDes">
                                      {this.artistsSelected(
                                        eachProduct.artists
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div class="videoCnt purchaseGenere">
                                <div className="headingText">
                                  <span class="author">
                                    {strings.genreText}
                                  </span>
                                </div>
                                <div className="colon-symbol">:</div>
                                <div className="loopdata">
                                  {eachProduct.category.map(
                                    (categoryList, index) => {
                                      if (
                                        categoryList.custom_translation[
                                        this.props.selectedLanguage
                                        ] != undefined
                                      ) {
                                        return (
                                          <NavLink
                                            tag={Link}
                                            to={{
                                              pathname: "/browse",
                                            }}
                                            onClick={() => {
                                              this.props.dispatch({
                                                type: "MENU_SELECTED_CATEGORY",
                                                payload: [
                                                  categoryList.name,
                                                  eachProduct.product_audio_type,
                                                  categoryList.id,
                                                ],
                                              });
                                              this.props.dispatch(
                                                browseCategoryProductDetailsDataAction(
                                                  eachProduct.product_audio_type,
                                                  categoryList.name,
                                                  1
                                                )
                                              );
                                            }}
                                            key={index}
                                            className="genreLinkText"
                                          >
                                            <span key={index}>
                                              {
                                                categoryList.custom_translation[
                                                  this.props.selectedLanguage
                                                ].name
                                              }
                                            </span>
                                          </NavLink>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                              </div>
                              <div
                                class="videoCnt"
                                onClick={() => {
                                  this.showPlayerForProduct(selectedIndex);
                                }}
                              >
                                <span className="subsCribeButton playAll">
                                  {strings.playAll}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md="2" lg="2">
                            <div class="mySnoveltrackDiv">
                              <span>
                                <strong>
                                  {eachProduct.producttracks.length}
                                </strong>
                                <br />
                                {strings.trackText}
                              </span>
                            </div>
                            <div class="mySnoveldurDiv">
                              <span>
                                {strings.durationText}
                                <br />
                                <strong>
                                  {getHoursFormat(
                                    eachProduct.total_tracks_length
                                  ) +
                                    " " +
                                    strings.hrsText}
                                </strong>
                              </span>
                            </div>
                            <StarRating rating={eachProduct.total_reviews} />
                          </Col>
                        </Row>
                      </div>
                      <div className="clearfix"></div>

                      {(this.state.selectedProductIndex === selectedIndex ||
                        this.state.showTrackProductId == eachProduct.id) && (
                          <div>
                            {eachProduct.producttracks.map(
                              (eachTrack, trackIndex) => {
                                return (
                                  <div
                                    id={
                                      "library" +
                                      eachProduct.id +
                                      "track" +
                                      eachTrack.id
                                    }
                                    class={
                                      this.state.selectedTrackIndex == trackIndex
                                        ? "videoPlayList active-play-list"
                                        : "videoPlayList"
                                    }
                                  >
                                    <div
                                      class="videoPlayP"
                                      onClick={() => {
                                        this.playProductTrack(trackIndex);
                                      }}
                                    >
                                      <img
                                        src={
                                          this.state.selectedTrackIndex ==
                                            trackIndex && !this.state.isPaused
                                            ? "/images/p_video.png"
                                            : "/images/v_play.png"
                                        }
                                      />
                                    </div>
                                    <div class="videoPlayTitle">
                                      <span>{trackIndex + 1}</span>
                                      {eachTrack.custom_translation[
                                        this.props.selectedLanguage
                                      ]
                                        ? eachTrack.custom_translation[
                                          this.props.selectedLanguage
                                        ].name
                                        : eachTrack.custom_translation["EN"].name}
                                    </div>
                                    <div class="videoPlayDuration">
                                      {strings.durationText}
                                      <br />
                                      <span>
                                        {getHoursFormat(eachTrack.track_length) +
                                          " " +
                                          strings.hrsText}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                    </div>
                  );
                }
              )}
            </div>
          )}
        </Col>
        <Col sm="12" className="mySnovel">
          <Row className="left-right-margin-none snovelRunTime-wrapper">
            <Col className="left-padding-none" lg="3">
              <h3>{strings.mySnovelRunTime}</h3>
              {this.props.runTimeDuration != 0 && (
                <div class="mySnovelBx">
                  <p class="snovel-run-time">
                    <strong>
                      {getHoursFormat(this.props.runTimeDuration)}
                    </strong>
                    <small>{strings.hrsText}</small>
                  </p>
                </div>
              )}
              {this.props.runTimeDuration == 0 && <p>Not played yet</p>}
            </Col>
            <Col className="left-padding-none-mobile" lg="3">
              <h3>{strings.myPurchasedLibrary}</h3>
              {this.props.purshaseProductCount !== "" && (
                <div class="mySnovelBx mySnovelBxSec">
                  <p>
                    <span>
                      {this.props.purshaseProductCount
                        ? this.props.purshaseProductCount
                        : 0}
                    </span>
                    <br />
                    {strings.titles}
                  </p>
                </div>
              )}
              {this.props.purshaseProductCount == "" && (
                <p>{strings.notPurchasedYet}</p>
              )}
              {/* <a class="goToLib" onClick={()=>{this.goToLibrary()}}>{strings.goToLibrary}</a> */}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapStatesToProps(state) {
  return {
    selectedProductToPlay: state.selectedProductToPlay,
    selectedTrackIdReducer: state.selectedTrackIdReducer,
  };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(PurchasedInLibraryTab));
