const initialState = {
    homeFeaturedProducts: []
}

export default function homeFeaturedProductData(state = initialState, action) {
    switch (action.type) {
        case 'HOME_FEATURED_PRODUCTS': {
            return { ...state, homeFeaturedProducts: action.payload };
        }
        case 'FAILED_HOME_FEATURED_PRODUCTS': {
            return { ...state };
        }
        default: {
            return { ...state };
        }
    }
}