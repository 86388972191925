import axios from "axios";
import { api_base_url, encryptDecryptKey } from "../config";
import SimpleCrypto from "simple-crypto-js";

const initialState = {
  menuSelectedCategory: [
    {
      selectedCategory: false,
      selectedPostType: "",
    },
  ],
  audioBooksCount: 0,
  snovelOriginalsCount: 0,
  podcastCount: 0,
};

function categoryAnalytics(categoryId) {
  var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
  var userDetails = localStorage.getItem("userDetails");
  var token = "";
  if (userDetails) {
    userDetails = simpleCrypto.decrypt(userDetails);
    var userDetailsObject = JSON.parse(userDetails);
    if (
      userDetailsObject.loginSuccess == true &&
      userDetailsObject.token != ""
    ) {
      var apiParams = {
        category_id: categoryId,
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + userDetailsObject.token,
      };
      axios
        .post(api_base_url + "/api/product-analytics/", apiParams, {
          headers: headers,
        })
        .then((response) => { })
        .catch((err) => {
          // console.log("error", err);
        });
    }
  }
}

export default function selectedPostAndCategory(state = initialState, action) {
  switch (action.type) {
    case "MENU_SELECTED_CATEGORY": {
      var categoryId = action.payload[2];
      if (categoryId) {
        categoryAnalytics(categoryId);
      }
      var oldDataObject = [];
      var newObject = {
        selectedCategory: action.payload[0],
        selectedPostType: action.payload[1],
      };
      oldDataObject.push(newObject);
      return { ...state, menuSelectedCategory: oldDataObject };
    }
    case "MENU_UNSELECTED_CATEGORY": {
      var removeDataObject = [];
      var newRemoveObject = {
        selectedCategory: action.payload[0],
        selectedPostType: action.payload[1],
      };
      removeDataObject.push(newRemoveObject);
      return { ...state, menuSelectedCategory: removeDataObject };
    }
    case "CHANGE_CATEGORY_COUNT": {
      var newAudioBooksCount = action.payload.audioBooksCount;
      var newSnovelOriginalsCount = action.payload.snovelOriginalsCount;
      var newPodcastCount = action.payload.podcastCount;
      return {
        ...state,
        audioBooksCount: newAudioBooksCount,
        snovelOriginalsCount: newSnovelOriginalsCount,
        podcastCount: newPodcastCount,
      };
    }
    default: {
      return { ...state };
    }
  }
}
