import axios from 'axios';
import { api_base_url, dashboardToken } from '../config';

export function browseCategoryProductDetailsDataAction(updatePostType, categoryName, selectedTab) {
    if (categoryName !== "latest_launch" && categoryName !== "popular_titles" && categoryName !== "editors_pick") {
        return function (dispatch) {
            var newUpdatedPostType = updatePostType != null ? updatePostType : 'family';
            var apiParams = {};
            // if (categoryName === undefined || categoryName === null) {
            //     apiParams = {
            //         product_type: newUpdatedPostType,
            //         browse_product: selectedTab
            //     }
            // } else {
            apiParams = {
                product_type: newUpdatedPostType,
                category: categoryName,
                browse_product: selectedTab
            }
            // }
            dispatch({
                type: "SHOW_LOADER"
            });
            axios.post(api_base_url + '/api/v2/browse-snovel-products/', apiParams)
                .then(response => {
                    if (response) {
                        // console.log(response)
                        dispatch({
                            type: "BROWSE_CATEGORY_PRODUCTS",
                            payload: response.data.data.total_products
                        });
                    } else {
                        dispatch({
                            type: "FAILED_BROWSE_CATEGORY_PRODUCTS",
                            payload: response.data
                        });
                    }
                    dispatch({
                        type: "HIDE_LOADER"
                    });
                })
                .catch(err => {
                    // console.log('fetched.', err);
                    dispatch({
                        type: "HIDE_LOADER"
                    });
                });
        }
    } else if(categoryName === "latest_launch" && categoryName !== "popular_titles" && categoryName !=="editors_pick"){
        return function (dispatch) {
            var headers = {
                'Content-Type': 'application/json',
                'index-api-token': dashboardToken
            }
            dispatch({
                type: "SHOW_LOADER"
            });
            axios.get(api_base_url + '/api/v2/index-latest-launch/10', { headers: headers })
                .then(response => {
                    if (response.data.status == true) {
                        // console.log(response);
                        dispatch({
                            type: "BROWSE_CATEGORY_PRODUCTS",
                            payload: response.data.latest_launch || []
                        });
                       
                    } else {
                        dispatch({
                            type: "FAILED_BROWSE_CATEGORY_PRODUCTS",
                            payload: response.data
                        });
                    }
                    dispatch({
                        type: "HIDE_LOADER"
                    });
                })
                .catch(err => {
                    // console.log('fetched.', err)
                    dispatch({
                        type: "HIDE_LOADER"
                    });
                });
        }
    }else if(categoryName === "popular_titles" && categoryName !== "latest_launch" && categoryName !=="editors_pick"){
        return function (dispatch) {
        var headers = {
            "Content-Type": "application/json",
            "index-api-token": dashboardToken,
          };
          dispatch({
            type: "SHOW_LOADER"
        });
          axios.get(api_base_url + "/api/v2/index-popular/10", { headers: headers })
            .then((response) => {
              if (response.data.status == true) {
                dispatch({
                    type: "BROWSE_CATEGORY_PRODUCTS",
                    payload: response.data.popular_picks || []
                });
              } else {
                // console.log("Not Loaded");
                dispatch({
                    type: "FAILED_BROWSE_CATEGORY_PRODUCTS",
                    payload: response.data
                });
              }
              dispatch({
                type: "HIDE_LOADER"
            });
            })
            .catch((err) => {
              // console.log("fetched.", err);
              dispatch({
                type: "HIDE_LOADER"
            });
            });
    }
}else if(categoryName === "editors_pick"){
    return function (dispatch) {
    var headers = {
        'Content-Type': 'application/json',
        'index-api-token': dashboardToken
    }
    dispatch({
        type: "SHOW_LOADER"
    });
    axios.get(api_base_url + '/api/v2/index-editors-pick/10', { headers: headers })
        .then(response => {
            if (response.data.status == true) {
                dispatch({
                    type: "BROWSE_CATEGORY_PRODUCTS",
                    payload: response.data.editor_choice || []
                });
            } else {
                dispatch({
                    type: "FAILED_BROWSE_CATEGORY_PRODUCTS",
                    payload: response.data
                });
            }
            dispatch({
                type: "HIDE_LOADER"
            });
        })
        .catch(err => {
            dispatch({
                type: "HIDE_LOADER"
            });
        });
}
}
}