const initialState = {
  activeTab: false,
};

export default function myLibraryActiveTabReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "MY_LIBRARY_ACTIVE_TAB": {
      return { ...state, activeTab: action.payload };
    }
    case "FAILED_MY_LIBRARY_ACTIVE_TAB": {
      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
}
