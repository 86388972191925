import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Provider } from 'react-redux';
import { connectRouter } from 'connected-react-router';
import store, { history } from './store';
import 'react-multi-carousel/lib/styles.css';
import 'react-owl-carousel2/lib/styles.css';

ReactDOM.render(
  <Provider store={store}>
    <connectRouter history={history}>
      <Router>
        <App />
      </Router>
    </connectRouter>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
