export default function isReisteredDevice (state={}, action) {
    switch (action.type) {
        case 'REGISTERED_DEVICE_SUCCESS': {
            return { ...state, isIndian: action.payload };
        }
        case 'REGISTERED_DEVICE_FAIL': {
            return { ...state, isIndian: action.payload };
        }
        default: {
            return { ...state };
        }
    }
}