const initialState = {
    homePageAllData:[]
  }

  export default function homeMainCategoryList (state = initialState, action) {
    switch (action.type) {
        case 'HOME_ALL_PRODUCT_DATA': {
            var homePageAllDataObject = [];
            homePageAllDataObject.push(action.payload);
            return { ...state, 
                    homePageAllData: homePageAllDataObject };
        }
        default: {
            return { ...state };
        }
    }
  }