const initialState = {
    cartCount: 0
}

export default function productCartCountReducer (state = initialState, action) {
    switch (action.type) {
        case 'PRODUCT_CART_COUNT': {
            return { ...state, cartCount: action.payload };
        }
        case 'FAILED_PRODUCT_CART_COUNT': {
            return { ...state };
        }
        default: {
            return { ...state };
        }
    }
}