import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Row, Col, NavLink } from 'reactstrap';
import swal from 'sweetalert';
import { api_base_url, guestProductToken, dashboardToken, googleAnalyticsTrackingId } from '../../config';
import axios from 'axios';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
import { Route, Link, Redirect, withRouter } from 'react-router-dom';
import { browseCategoryProductDetailsDataAction } from '../../action/browseCategoryAction';
import LocalizedStrings from 'react-localization';
import languages from '../../language/language.json';
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

const optionss = {
    nav: true,
    autoplay: false,
    navText: ["<div></div>", "<div></div>"],
    margin: 15,
    responsive: {
        0: {
            items: 2
        },
        600: {
            items: 3
        },
        1000: {
            items: 6
        }
    }
};
class authorDetails extends Component {
    constructor(props) {
        super(props);
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
        }
        this.state = {
            authorDetails: {},
            authorProductSlider: [],
            selectedLanguage: selectedLanguage || 'EN',
            latestLaunchLastPlayed: '',
            authorSliderAudio: {},
            audioObject: undefined,
            isLoading: false,
            isShowReadMore: true
        }
        this.authorDetailPage(this.props.match.params.id);
        ReactGA.pageview(this.props.location.pathname);
    }
    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            var pathName = location.pathname;
            var lastRoute = this.props.location.pathname;
            if (pathName.indexOf('author') > -1 && lastRoute.indexOf('author') > -1 && pathName != lastRoute) {
                var newArray = pathName.split("/");
                var authorID = newArray[2];
                if (authorID) {
                    this.setState({
                        authorDetails: {},
                        authorProductSlider: [],
                        latestLaunchLastPlayed: '',
                        authorSliderAudio: {},
                        audioObject: undefined,
                        isShowReadMore: true
                    });
                    this.authorDetailPage(authorID);
                }
            }
        });
        window.scrollTo(0, 0);
    }
    componentWillUnmount() {
        if (this.state.audioObject) {
            this.state.audioObject.pause();
        }
        this.unlisten();
    }
    componentWillReceiveProps(nextProps) {
        var selectedLanguage = window.localStorage.getItem("language");
        if (selectedLanguage) {
            strings.setLanguage(selectedLanguage);
            this.setState({
                selectedLanguage: selectedLanguage
            });
        } else {
            strings.setLanguage("EN");
            window.localStorage.setItem("language", "EN");
            this.setState({
                selectedLanguage: "EN"
            });
        }
    }
    authorDetailPage(stateProductId) {
        this.setState({ isLoading: true });
        axios.get(api_base_url + '/api/author-product/' + stateProductId + '/')
            .then(response => {
                if (response.data.status === true) {
                    this.setState({
                        authorDetails: response.data.author_details[0],
                        authorProductSlider: response.data.author_products
                    });
                }
                this.setState({ isLoading: false });
            }).catch(err => {
                // console.log('Error - ', err);
            });
    }

    authorPauseAudio(map_url) {
        this.state.audioObject.pause();
        this.setState({
            latestLaunchLastPlayed: ''
        });
    }

    authorPlayAudio(map_url) {
        if (this.state.authorSliderAudio[map_url]) {
            this.setState({
                latestLaunchLastPlayed: map_url,
            });
            var playPromise = this.state.audioObject.play();
            if (playPromise !== undefined) {
                playPromise.then(function () { }).catch(function (error) {
                    const isFirefox = typeof InstallTrigger !== 'undefined';
                    if (isFirefox) {
                        swal("", "Something went wrong with your browser. Please try another one.", "error");
                    }
                });
            }
        } else {
            if (this.state.audioObject) {
                this.state.audioObject.pause();
            }
            var apiParams = {
                map_url: map_url
            };
            var headers = {
                'Content-Type': 'application/json',
                'index-api-token': dashboardToken
            };
            axios.post(api_base_url + '/api/get-sample-signed-url/', apiParams, { headers: headers }).then(response => {
                if (response.data) {
                    var url = response.data.data.signed_url || "";
                    var audio = new Audio(url);
                    var parentThis = this;
                    audio.addEventListener("ended", function () {
                        parentThis.setState({
                            latestLaunchLastPlayed: ''
                        });
                    });
                    audio.type = 'audio/mpeg';
                    var newLatestLaunchAudio = {};
                    newLatestLaunchAudio[map_url] = url;
                    this.setState({
                        audioObject: audio,
                        latestLaunchAudio: newLatestLaunchAudio,
                        latestLaunchLastPlayed: map_url,
                    });
                    var playPromise = audio.play();
                    if (playPromise !== undefined) {
                        playPromise.then(function () { }).catch(function (error) {
                            const isFirefox = typeof InstallTrigger !== 'undefined';
                            if (isFirefox) {
                                swal("", "Something went wrong with your browser. Please try another one.", "error");
                            }
                        });
                    }
                }
            }).catch(err => {
                // console.log('Error - ', err);
            });
        }
    }

    authorsSelected(authorsList) {
        var authorArray = [];
        authorsList.map(authorName => {
            if (authorName.custom_translation[this.state.selectedLanguage] != undefined) {
                authorArray.push(authorName.custom_translation[this.state.selectedLanguage].name);
            }
        })
        return authorArray.toString();
    }

    productDetailsId(productID, title) {
        var titleUrl = title.toLowerCase();
        var productTitle = titleUrl.split(" ").join("-");
        localStorage.setItem('productId', productID);
        this.props.history.push('/product-details/' + productID + '/' + productTitle);
    }

    authorProductSlider() {
        return this.state.authorProductSlider.map((sliderContent, index) => {
            return (
                <div>
                    <div class="videoThumbDiv">
                        {sliderContent.images.length > 0 ? <div><img src={sliderContent.images[0]["image2"]} /></div> : <div className="authorSliderDefualt"><img src="/images/default.jpg" /></div>}
                    </div>
                    <div class="videoThumbTitleDiv">
                        <span onClick={() => { this.productDetailsId(sliderContent.id, sliderContent.name) }}>{sliderContent.custom_translation[this.state.selectedLanguage].name}</span><br />
                        <span class="briefTitle">{strings.authorText}: {this.authorsSelected(sliderContent.authors)}</span>
                    </div>
                    <div class="videoThumbCateDiv">
                        {sliderContent.category.map((categoryList, index) => {
                            if (categoryList.custom_translation[this.state.selectedLanguage] !== undefined) {
                                return <div className="productCategoryText"><NavLink tag={Link} to={{
                                    pathname: '/browse'
                                }} onClick={() => {
                                    this.props.dispatch({
                                        type: 'MENU_SELECTED_CATEGORY',
                                        payload: [categoryList.name, sliderContent.product_audio_type, categoryList.id]
                                    });
                                    this.props.dispatch(browseCategoryProductDetailsDataAction(sliderContent.product_audio_type, categoryList.name, 1));
                                }} key={index}><span key={index}>{categoryList.custom_translation[this.state.selectedLanguage].name.toString()}</span></NavLink>{(index + 1) != sliderContent.category.length && <span> | </span>}</div>
                            }
                        })}
                    </div>
                    <div className="sliderTrack_Wrap">
                        {sliderContent.sampletracks.length > 0 &&
                            <div className="playButn">
                                {sliderContent.sampletracks[0].map_url != this.state.latestLaunchLastPlayed &&
                                    <img onClick={() => { this.authorPlayAudio(sliderContent.sampletracks[0].map_url); }} src="/images/play.png" />
                                }
                                {sliderContent.sampletracks[0].map_url == this.state.latestLaunchLastPlayed &&
                                    <img onClick={() => { this.authorPauseAudio(sliderContent.sampletracks[0].map_url); }} src="/images/round-pause-button.svg" />
                                }
                            </div>
                        }
                    </div>
                </div>
            );
        })
    }
    render() {
        return (
            <div>
                {this.state.isLoading ?
                    <div id="loading">
                        <div id="loading-center">
                            <div id="loading-center-absolute">
                                <img src="images/loader.gif" />
                            </div>
                        </div>
                    </div> : null
                }
                <div class="container">
                    {Object.keys(this.state.authorDetails).length > 0 &&
                        <Row>
                            <div class="authorDivWrp">
                                <div class="authorDivImg">
                                    <img src={this.state.authorDetails.profile_image} alt="" />
                                </div>
                                <div class="authorDivCnt">
                                    <h1>{this.state.authorDetails.custom_translation[this.state.selectedLanguage].name}</h1>
                                    <span>{strings.authorText}</span>
                                    {this.state.isShowReadMore &&
                                        <div>
                                            <p dangerouslySetInnerHTML={{ __html: this.state.authorDetails.custom_translation[this.state.selectedLanguage].short_description }}></p>
                                            <div class="moreAuthor" onClick={() => { this.setState({ isShowReadMore: false }) }}><a title="">{strings.readMoreText} >></a></div>
                                        </div>
                                    }
                                    {!this.state.isShowReadMore &&
                                        <p dangerouslySetInnerHTML={{ __html: this.state.authorDetails.custom_translation[this.state.selectedLanguage].description }}></p>
                                    }
                                </div>
                            </div>
                        </Row>
                    }
                </div>
                <div id="services" className="authorProdcutSlider_Wrap">
                    <div class="container adveTitleDiv">
                        {Object.keys(this.state.authorDetails).length > 0 &&
                            <h2>{this.state.authorDetails.custom_translation[this.state.selectedLanguage].name}{strings.famusTitle}</h2>
                        }
                        {this.state.authorProductSlider.length > 0 ? <div className="authorSlider"><OwlCarousel ref="car" options={optionss} >{this.authorProductSlider()}</OwlCarousel></div> : ""}
                    </div>
                </div>
            </div>
        );
    }
}
function mapStatesToProps(state) {
    return {
        languageTab: state.languageTab,
    };
}

const myConnector = connect(mapStatesToProps);
export default myConnector(authorDetails);