import React, { Component } from "react";
import SimpleCrypto from "simple-crypto-js";
import ReactGA from "react-ga";
import {
  encryptDecryptKey,
  googleAnalyticsTrackingId,
  getHoursFormat,
} from "../../config";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { api_base_url } from "../../config";
import classnames from "classnames";
import UserProfileSubscribe from "../userProfile/userProfileSubscribe";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { tryFreeProductDetailsDataAction } from "../../action/tryFreeProductAction";
import { browseCategoryProductDetailsDataAction } from "../../action/browseCategoryAction";
import StarRating from "../rating/startRating";
import LocalizedStrings from "react-localization";
import languages from "../../language/language.json";
import { Fragment } from "react";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

class tryFreeSnovelProducts extends Component {
  constructor(props) {
    super(props);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
        this.getNotificationList(token);
      } else {
        this.props.history.push("/sign-in");
      }
    } else {
      this.props.history.push("/sign-in");
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }

    this.state = {
      token: token || "",
      userShortName: "",
      activeTab: "1",
      selectedLanguage: selectedLanguage || "EN",
      audiobookAllProducts: [],
      snovelOriginalAllProducts: [],
      podcastAllProducts: [],
      selectedTrackIndex: 0,
      selectedProductIndex: "",
      selectedProductId: "",
      selectedPodcastTrackIndex: 0,
      selectedPodcastProductIndex: "",
      selectedPodcastProductId: "",
      selectedSnovelOriginalTrackIndex: 0,
      selectedSnovelOriginalProductIndex: "",
      selectedSnovelOriginalProductId: "",
      selectedKidsTrackIndex: 0,
      selectedKidsProductIndex: "",
      selectedKidsProductId: "",
      selectedLanguageLabTrackIndex: 0,
      selectedLanguageLabProductIndex: "",
      selectedLanguageLabProductId: "",
      isAllowUpdate: true,
      isPaused: false,
      isSnovelOriginalPaused: false,
      isPodcastPaused: false,
      isKidsPaused: false,
      isLanguageLabPaused: false,
      notificationCount: 0,
      kidsAllProducts: [],
      languageLabAllProducts: [],
    };
    this.tabToggle = this.tabToggle.bind(this);
  }
  getNotificationList(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    var apiParams = {
      offset: 0,
      limit: 10,
    };
    axios
      .post(api_base_url + "/api/my-notification/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status) {
          this.setState({
            notificationCount: response.data.count || 0,
          });
        } else {
          this.setState({
            notificationCount: 0,
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          notificationCount: 0,
        });
      });
  }
  tabToggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentWillMount() {
    this.props.dispatch(
      tryFreeProductDetailsDataAction(null, this.state.token)
    );
  }
  /***********  Product details page link *****/
  productDetailsId(productID, slug) {
    this.props.history.push("/product-details/" + productID + "/" + slug);
  }
  /**********************
   *  Author details page *
   **********************/
  authorDetailsId(authorID, authorName) {
    var titleUrl = authorName.toLowerCase();
    var authorTitle = titleUrl.replace(".", "");
    var authorName = authorTitle.split(" ").join("-");
    this.props.history.push("/author/" + authorID + "/" + authorName);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.tryFreeProductDetailsData.audiobook !== undefined) {
      this.setState({
        audiobookAllProducts:
          nextProps.tryFreeProductDetailsData.audiobook.all_product || [],
      });
    }

    if (nextProps.tryFreeProductDetailsData.snoveloriginal !== undefined) {
      this.setState({
        snovelOriginalAllProducts:
          nextProps.tryFreeProductDetailsData.snoveloriginal.all_product || [],
      });
    }

    if (nextProps.tryFreeProductDetailsData.podcast !== undefined) {
      this.setState({
        podcastAllProducts:
          nextProps.tryFreeProductDetailsData.podcast.all_product || [],
      });
    }
    if (nextProps.tryFreeProductDetailsData.kids !== undefined) {
      this.setState({
        kidsAllProducts:
          nextProps.tryFreeProductDetailsData.kids.all_product || [],
      });
    }
    if (nextProps.tryFreeProductDetailsData['language-lab'] !== undefined) {
      this.setState({
        languageLabAllProducts:
          nextProps.tryFreeProductDetailsData['language-lab'].all_product || [],
      });
    }

    if (
      Object.keys(nextProps.selectedProductToPlay.selectedProduct).length > 0 &&
      (nextProps.selectedProductToPlay.isPlayFromSection ==
        "tryFreeAudioBook" ||
        nextProps.selectedProductToPlay.isPlayFromSection ==
        "tryFreeSnovelOriginal" ||
        nextProps.selectedProductToPlay.isPlayFromSection == "tryFreePodcast" ||
        nextProps.selectedProductToPlay.isPlayFromSection == "tryFreeKids" ||
        nextProps.selectedProductToPlay.isPlayFromSection == "tryFreeLanguageLab")
    ) {
      var newSelectedProduct = nextProps.selectedProductToPlay.selectedProduct;
      var newTrackIndex = nextProps.selectedProductToPlay.selectedTrackIndex;
      var newPauseStatus = nextProps.selectedProductToPlay.isPauseFromPlayer;
      if (
        nextProps.selectedProductToPlay.isPlayFromSection == "tryFreeAudioBook"
      ) {
        this.setState({
          selectedProductId: newSelectedProduct.id,
          selectedPodcastTrackIndex: newTrackIndex,
          isPaused: newPauseStatus,
          selectedPodcastTrackIndex: 0,
          selectedPodcastProductIndex: "",
          selectedPodcastProductId: "",
          selectedSnovelOriginalTrackIndex: 0,
          selectedSnovelOriginalProductIndex: "",
          selectedSnovelOriginalProductId: "",
          selectedKidsTrackIndex: 0,
          selectedKidsProductIndex: "",
          selectedKidsProductId: "",
          selectedLanguageLabTrackIndex: 0,
          selectedLanguageLabProductIndex: "",
          selectedLanguageLabProductId: ""
        });
      } else if (
        nextProps.selectedProductToPlay.isPlayFromSection ==
        "tryFreeSnovelOriginal"
      ) {
        this.setState({
          selectedSnovelOriginalProductId: newSelectedProduct.id,
          selectedSnovelOriginalTrackIndex: newTrackIndex,
          isSnovelOriginalPaused: newPauseStatus,
          selectedProductIndex: "",
          selectedTrackIndex: 0,
          selectedProductId: "",
          selectedPodcastTrackIndex: 0,
          selectedPodcastProductIndex: "",
          selectedPodcastProductId: "",
          selectedKidsTrackIndex: 0,
          selectedkidsProductIndex: "",
          selectedKidsProductId: "",
          selectedLanguageLabTrackIndex: 0,
          selectedLanguageLabProductIndex: "",
          selectedLanguageLabProductId: ""
        });
      } else if (
        nextProps.selectedProductToPlay.isPlayFromSection == "tryFreePodcast"
      ) {
        this.setState({
          selectedPodcastProductId: newSelectedProduct.id,
          selectedPodcastTrackIndex: newTrackIndex,
          isPodcastPaused: newPauseStatus,
          selectedProductIndex: "",
          selectedTrackIndex: 0,
          selectedProductId: "",
          selectedSnovelOriginalTrackIndex: 0,
          selectedSnovelOriginalProductIndex: "",
          selectedSnovelOriginalProductId: "",
          selectedKidsTrackIndex: 0,
          selectedKidsProductIndex: "",
          selectedKidsProductId: "",
          selectedLanguageLabTrackIndex: 0,
          selectedLanguageLabProductIndex: "",
          selectedLanguageLabProductId: ""
        });
      } else if (
        nextProps.selectedProductToPlay.isPlayFromSection == "tryFreeKids"
      ) {
        this.setState({
          selectedKidsProductId: newSelectedProduct.id,
          selectedKidsTrackIndex: newTrackIndex,
          isKidsPaused: newPauseStatus,
          selectedProductIndex: "",
          selectedTrackIndex: 0,
          selectedProductId: "",
          selectedSnovelOriginalTrackIndex: 0,
          selectedSnovelOriginalProductIndex: "",
          selectedSnovelOriginalProductId: "",
          selectedPodcastTrackIndex: 0,
          selectedPodcastProductIndex: "",
          selectedPodcastProductId: "",
          selectedLanguageLabTrackIndex: 0,
          selectedLanguageLabProductIndex: "",
          selectedLanguageLabProductId: ""
        });
      } else if (
        nextProps.selectedProductToPlay.isPlayFromSection == "tryFreeLanguageLab"
      ) {
        this.setState({
          selectedLanguageLabProductId: newSelectedProduct.id,
          selectedLanguageLabTrackIndex: newTrackIndex,
          isLanguageLabPaused: newPauseStatus,
          selectedProductIndex: "",
          selectedTrackIndex: 0,
          selectedProductId: "",
          selectedSnovelOriginalTrackIndex: 0,
          selectedSnovelOriginalProductIndex: "",
          selectedSnovelOriginalProductId: "",
          selectedPodcastTrackIndex: 0,
          selectedPodcastProductIndex: "",
          selectedPodcastProductId: "",
          selectedKidsTrackIndex: 0,
          selectedKidsProductIndex: "",
          selectedKidsProductId: ""
        });
      }
    } else if (
      Object.keys(nextProps.selectedProductToPlay.selectedProduct).length > 0
    ) {
      this.setState({
        selectedProductIndex: "",
        selectedTrackIndex: 0,
        selectedProductId: "",
        selectedPodcastTrackIndex: 0,
        selectedPodcastProductIndex: "",
        selectedPodcastProductId: "",
        selectedSnovelOriginalTrackIndex: 0,
        selectedSnovelOriginalProductIndex: "",
        selectedSnovelOriginalProductId: "",
        selectedKidsTrackIndex: 0,
        selectedKidsProductIndex: "",
        selectedKidsProductId: "",
        selectedLanguageLabTrackIndex: 0,
        selectedLanguageLabProductIndex: "",
        selectedLanguageLabProductId: "",
        isAllowUpdate: true,
        isPaused: false,
        isPodcastPaused: false,
        isSnovelOriginalPaused: false,
        isKidsPaused: false,
        isLanguageLabPaused: false
      });
    }

    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  authorsSelected(authorsList) {
    var authorArray = [];
    authorsList.map((authorName) => {
      if (
        authorName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        authorArray.push(
          authorName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return authorArray.join(", ");
  }

  directorsSelected(directorList) {
    var directorArray = [];
    directorList.map((directorName) => {
      if (
        directorName.custom_translation[this.state.selectedLanguage] !=
        undefined
      ) {
        directorArray.push(
          directorName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return directorArray.join(", ");
  }

  artistsSelected(artistsList) {
    var artistsArray = [];
    artistsList.map((artistsName) => {
      if (
        artistsName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        artistsArray.push(
          artistsName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return artistsArray.join(", ");
  }
  getDuration(duration) {
    var newDuration = "";
    if (duration == 0) {
      newDuration = duration;
    } else {
      var newMinutes = duration / 60;
      newDuration = parseFloat(newMinutes / 60).toFixed(2);
    }
    return newDuration + " " + strings.hrsText;
  }
  /*****************************
   * Audio Book Try Free Snovel
   *****************************/
  showPlayerForProduct(productIndex) {
    if (
      this.state.audiobookAllProducts[productIndex].producttracks.length > 0
    ) {
      if (
        this.state.selectedProductIndex === "" ||
        this.state.selectedProductIndex !== productIndex
      ) {
        this.showPlayerForProductAfterAnalytics(productIndex);
        var headers = {
          "Content-Type": "application/json",
        };
        axios
          .get(api_base_url + "/api/player-analytics/", { headers: headers })
          .then((response) => { })
          .catch((err) => {
            // console.log("error ", err);
          });
      } else {
        this.setState({
          selectedProductIndex: "",
          selectedTrackIndex: 0,
          isPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            {},
            0,
            false,
            false,
            false,
            false,
            false,
            "tryFreeAudioBook",
          ],
        });
      }
    }
  }
  showPlayerForProductAfterAnalytics(productIndex) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      product_id: this.state.audiobookAllProducts[productIndex].id,
    };
    axios
      .post(api_base_url + "/api/last-played/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var latestTrackId = response.data.data.track_id || "";
          var newSelectedTrackIndex = 0;
          for (
            var i = 0;
            i <
            this.state.audiobookAllProducts[productIndex].producttracks.length;
            i++
          ) {
            var eachTrack = this.state.audiobookAllProducts[productIndex]
              .producttracks[i];
            if (eachTrack.id === latestTrackId) {
              newSelectedTrackIndex = i;
            }
          }
          this.setState({
            selectedProductIndex: productIndex,
            selectedTrackIndex: newSelectedTrackIndex,
            isPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.state.audiobookAllProducts[productIndex],
              newSelectedTrackIndex,
              false,
              false,
              false,
              false,
              "tryFreeAudioBook",
            ],
          });
        } else {
          this.setState({
            selectedProductIndex: productIndex,
            selectedTrackIndex: 0,
            isPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.state.audiobookAllProducts[productIndex],
              0,
              false,
              false,
              false,
              false,
              "tryFreeAudioBook",
            ],
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          selectedProductIndex: productIndex,
          selectedTrackIndex: 0,
          isPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.audiobookAllProducts[productIndex],
            0,
            false,
            false,
            false,
            false,
            "tryFreeAudioBook",
          ],
        });
      });
  }
  playProductTrack(trackIndex) {
    if (this.state.selectedTrackIndex !== trackIndex) {
      this.props.dispatch({
        type: "PLAY_SELECTED_PRODUCT",
        payload: [
          this.state.audiobookAllProducts[this.state.selectedProductIndex],
          trackIndex,
          false,
          false,
          false,
          false,
          "tryFreeAudioBook",
        ],
      });
      this.setState({
        selectedTrackIndex: trackIndex,
        isPaused: false,
      });
    } else {
      if (this.state.isPaused) {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.audiobookAllProducts[this.state.selectedProductIndex],
            trackIndex,
            false,
            true,
            false,
            false,
            "tryFreeAudioBook",
          ],
        });
        this.setState({
          isPaused: false,
        });
      } else {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.audiobookAllProducts[this.state.selectedProductIndex],
            trackIndex,
            true,
            false,
            false,
            false,
            "tryFreeAudioBook",
          ],
        });
        this.setState({
          isPaused: true,
        });
      }
    }
  }
  audioBookAllFreeProducts() {
    return this.state.audiobookAllProducts.map((audioBook, selectedIndex) => {
      if (
        audioBook.id == this.state.selectedProductId &&
        this.state.isAllowUpdate
      ) {
        this.setState({
          selectedProductIndex: selectedIndex,
          isAllowUpdate: false,
        });
      }
      return (
        <div>
          <div class="mySnovelVideo freeTrailMpbileVideo">
            <Row className="mySnovelVideo-margin">
              <Col md="10" lg="10" className="freeTrailMobile">
                <div
                  class="videoThumbDiv"
                  onClick={() => {
                    this.showPlayerForProduct(selectedIndex);
                  }}
                >
                  {audioBook.images.length > 0 ? (
                    <Fragment>
                      {audioBook.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                      <img
                        class="purchase-library-image"
                        src={audioBook.images[0]["image2"]}
                        alt=""
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      {audioBook.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                      <img
                        class="purchase-library-image"
                        src="/images/default.jpg"
                        alt=""
                      />
                    </Fragment>
                  )}
                  <a title="" class="playButn">
                    {this.state.selectedProductIndex !== selectedIndex && (
                      <img src="/images/play-button.svg" alt="" />
                    )}
                    {/* {this.state.selectedProductIndex === selectedIndex && (
                      <img src="/images/round-pause-button.svg" alt="" />
                    )} */}
                  </a>
                  {/* <a href="#" title="" class="playButn"><img src="images/play_icon.png" alt="" /></a> */}
                </div>
                <div class="videoDesc">
                  <h4>
                    <span
                      onClick={() => {
                        this.productDetailsId(
                          audioBook.id,
                          audioBook.product_slug
                        );
                      }}
                    >
                      {
                        audioBook.custom_translation[
                          this.state.selectedLanguage
                        ].name
                      }
                    </span>
                  </h4>
                  <span class="cateTitle">{strings.audiobook}</span>
                  {audioBook.authors.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        <span class="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span
                          class="authorDes authorDesLink"
                          onClick={() => {
                            this.authorDetailsId(
                              audioBook.authors[0].id,
                              audioBook.authors[0].name
                            );
                          }}
                        >
                          {this.authorsSelected(audioBook.authors)}
                        </span>
                      </div>
                    </div>
                  )}
                  {audioBook.directors.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        <span class="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span class="authorDes">
                          {this.directorsSelected(audioBook.directors)}
                        </span>
                      </div>
                    </div>
                  )}
                  {audioBook.artists.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        <span class="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span class="authorDes">
                          {this.artistsSelected(audioBook.artists)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div class="videoCnt">
                    <div className="headingText">
                      <span class="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {audioBook.category.map((categoryList, index) => {
                        if (
                          categoryList.custom_translation[
                          this.state.selectedLanguage
                          ] != undefined
                        ) {
                          return (
                            <NavLink
                              tag={Link}
                              to={{
                                pathname: "/browse",
                              }}
                              onClick={() => {
                                this.props.dispatch({
                                  type: "MENU_SELECTED_CATEGORY",
                                  payload: [
                                    categoryList.name,
                                    audioBook.product_audio_type,
                                    categoryList.id,
                                  ],
                                });
                                this.props.dispatch(
                                  browseCategoryProductDetailsDataAction(
                                    audioBook.product_audio_type,
                                    categoryList.name,
                                    1
                                  )
                                );
                              }}
                              key={index}
                              className="genreLinkText"
                            >
                              <span key={index}>
                                {
                                  categoryList.custom_translation[
                                    this.state.selectedLanguage
                                  ].name
                                }
                              </span>
                            </NavLink>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div
                    class="videoCnt"
                    onClick={() => {
                      this.showPlayerForProduct(selectedIndex);
                    }}
                  >
                    <span className="subsCribeButton playAll">
                      {strings.playAll}
                    </span>
                  </div>
                </div>
              </Col>
              <Col md="2" lg="2" className="freeTrailTrackMobile">
                <div class="mySnoveltrackDiv">
                  <span>
                    <strong>{audioBook.producttracks.length}</strong>
                    <br />
                    {strings.trackText}
                  </span>
                </div>
                <div class="mySnoveldurDiv">
                  <span>
                    {strings.durationText}
                    <br />
                    <strong>
                      {getHoursFormat(audioBook.total_tracks_length) +
                        " " +
                        strings.hrsText}
                    </strong>
                  </span>
                </div>
                <StarRating rating={audioBook.total_reviews} />
              </Col>
            </Row>
          </div>
          {this.state.selectedProductIndex === selectedIndex && (
            <div>
              {audioBook.producttracks.map((eachTrack, trackIndex) => {

                return (
                  <div
                    class={
                      this.state.selectedTrackIndex == trackIndex
                        ? "videoPlayList active-play-list"
                        : "videoPlayList"
                    }
                  >
                    <div
                      class="videoPlayP"
                      onClick={() => {
                        this.playProductTrack(trackIndex);
                      }}
                    >
                      <img
                        src={
                          this.state.selectedTrackIndex == trackIndex &&
                            !this.state.isPaused
                            ? "/images/p_video.png"
                            : "/images/v_play.png"
                        }
                      />
                    </div>
                    <div class="videoPlayTitle">
                      <span>{trackIndex + 1}</span>
                      {eachTrack.custom_translation[this.state.selectedLanguage]
                        ? eachTrack.custom_translation[
                          this.state.selectedLanguage
                        ].name
                        : eachTrack.custom_translation["EN"].name}
                    </div>
                    <div class="videoPlayDuration">
                      {strings.durationText}
                      <br />
                      <span>
                        {getHoursFormat(eachTrack.track_length) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  }
  /*****************************
   * Snovel Original Try Free Snovel
   *****************************/
  snovelOriginalFreeProducts() {
    return this.state.snovelOriginalAllProducts.map(
      (snovelOriginal, selectedIndex) => {
        if (
          snovelOriginal.id == this.state.selectedSnovelOriginalProductId &&
          this.state.isAllowUpdate
        ) {
          this.setState({
            selectedSnovelOriginalProductIndex: selectedIndex,
            isAllowUpdate: false,
          });
        }
        return (
          <div>
            <div class="mySnovelVideo freeTrailMpbileVideo">
              <Row className="mySnovelVideo-margin">
                <Col md="10" lg="10" className="freeTrailMobile">
                  <div
                    class="videoThumbDiv"
                    onClick={() => {
                      this.showPlayerForProductSnovelOriginal(selectedIndex);
                    }}
                  >
                    {snovelOriginal.images.length > 0 ? (
                      <Fragment>
                        {snovelOriginal.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                        <img
                          class="purchase-library-image"
                          src={snovelOriginal.images[0]["image2"]}
                          alt=""
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        {snovelOriginal.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                        <img
                          class="purchase-library-image"
                          src="/images/default.jpg"
                          alt=""
                        />
                      </Fragment>
                    )}
                    <a title="" class="playButn">
                      {this.state.selectedSnovelOriginalProductIndex !==
                        selectedIndex && (
                          <img src="/images/play-button.svg" alt="" />
                        )}
                      {/* {this.state.selectedSnovelOriginalProductIndex ===
                        selectedIndex && (
                        <img src="/images/round-pause-button.svg" alt="" />
                      )} */}
                    </a>
                  </div>
                  <div class="videoDesc">
                    <h4>
                      <span
                        onClick={() => {
                          this.productDetailsId(
                            snovelOriginal.id,
                            snovelOriginal.product_slug
                          );
                        }}
                      >
                        {
                          snovelOriginal.custom_translation[
                            this.state.selectedLanguage
                          ].name
                        }
                      </span>
                    </h4>
                    <span class="cateTitle">{strings.snoveloriginal}</span>
                    {snovelOriginal.authors && (
                      <div class="videoCnt">
                        <div className="headingText">
                          <span class="author">{strings.authorText}</span>
                        </div>
                        <div className="colon-symbol">:</div>
                        <div className="loopdata">
                          <span
                            class="authorDes authorDesLink"
                            onClick={() => {
                              this.authorDetailsId(
                                snovelOriginal.authors[0].id,
                                snovelOriginal.authors[0].name
                              );
                            }}
                          >
                            {this.authorsSelected(snovelOriginal.authors)}
                          </span>
                        </div>
                      </div>
                    )}
                    {snovelOriginal.directors && (
                      <div class="videoCnt">
                        <div className="headingText">
                          <span class="author">{strings.directorText}</span>
                        </div>
                        <div className="colon-symbol">:</div>
                        <div className="loopdata">
                          <span class="authorDes">
                            {this.directorsSelected(snovelOriginal.directors)}
                          </span>
                        </div>
                      </div>
                    )}
                    {snovelOriginal.artists && (
                      <div class="videoCnt">
                        <div className="headingText">
                          <span class="author">{strings.artistsText}</span>
                        </div>
                        <div className="colon-symbol">:</div>
                        <div className="loopdata">
                          <span class="authorDes">
                            {this.artistsSelected(snovelOriginal.artists)}
                          </span>
                        </div>
                      </div>
                    )}
                    <div class="videoCnt">
                      <div className="headingText">
                        <span class="author">{strings.genreText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        {snovelOriginal.category.map((categoryList, index) => {
                          if (
                            categoryList.custom_translation[
                            this.state.selectedLanguage
                            ] != undefined
                          ) {
                            return (
                              <NavLink
                                tag={Link}
                                to={{
                                  pathname: "/browse",
                                }}
                                onClick={() => {
                                  this.props.dispatch({
                                    type: "MENU_SELECTED_CATEGORY",
                                    payload: [
                                      categoryList.name,
                                      snovelOriginal.product_audio_type,
                                      categoryList.id,
                                    ],
                                  });
                                  this.props.dispatch(
                                    browseCategoryProductDetailsDataAction(
                                      snovelOriginal.product_audio_type,
                                      categoryList.name,
                                      1
                                    )
                                  );
                                }}
                                key={index}
                                className="genreLinkText"
                              >
                                <span key={index}>
                                  {
                                    categoryList.custom_translation[
                                      this.state.selectedLanguage
                                    ].name
                                  }
                                </span>
                              </NavLink>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div
                      class="videoCnt"
                      onClick={() => {
                        this.showPlayerForProductSnovelOriginal(selectedIndex);
                      }}
                    >
                      <span className="subsCribeButton playAll">
                        {strings.playAll}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md="2" lg="2" className="freeTrailTrackMobile">
                  <div class="mySnoveltrackDiv">
                    <span>
                      <strong>{snovelOriginal.producttracks.length}</strong>
                      <br />
                      {strings.trackText}
                    </span>
                  </div>
                  <div class="mySnoveldurDiv">
                    <span>
                      {strings.durationText}
                      <br />
                      <strong>
                        {getHoursFormat(snovelOriginal.total_tracks_length) +
                          " " +
                          strings.hrsText}
                      </strong>
                    </span>
                  </div>
                  <StarRating rating={snovelOriginal.total_reviews} />
                </Col>
              </Row>
            </div>
            {this.state.selectedSnovelOriginalProductIndex ===
              selectedIndex && (
                <div>
                  {snovelOriginal.producttracks.map((eachTrack, trackIndex) => {

                    return (
                      <div
                        class={
                          this.state.selectedSnovelOriginalTrackIndex ==
                            trackIndex
                            ? "videoPlayList active-play-list"
                            : "videoPlayList"
                        }
                      >
                        <div
                          class="videoPlayP"
                          onClick={() => {
                            this.playProductTrackSnovelOriginal(trackIndex);
                          }}
                        >
                          <img
                            src={
                              this.state.selectedSnovelOriginalTrackIndex ==
                                trackIndex && !this.state.isSnovelOriginalPaused
                                ? "/images/p_video.png"
                                : "/images/v_play.png"
                            }
                          />
                        </div>
                        <div class="videoPlayTitle">
                          <span>{trackIndex + 1}</span>
                          {eachTrack.custom_translation[
                            this.state.selectedLanguage
                          ]
                            ? eachTrack.custom_translation[
                              this.state.selectedLanguage
                            ].name
                            : eachTrack.custom_translation["EN"].name}
                        </div>
                        <div class="videoPlayDuration">
                          {strings.durationText}
                          <br />
                          <span>
                            {getHoursFormat(eachTrack.track_length) +
                              " " +
                              strings.hrsText}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
        );
      }
    );
  }
  showPlayerForProductSnovelOriginal(productIndex) {
    if (
      this.state.snovelOriginalAllProducts[productIndex].producttracks.length >
      0
    ) {
      if (
        this.state.selectedSnovelOriginalProductIndex === "" ||
        this.state.selectedSnovelOriginalProductIndex !== productIndex
      ) {
        this.showPlayerForProductSnovelOriginalAfterAnalytics(productIndex);
        var headers = {
          "Content-Type": "application/json",
        };
        axios
          .get(api_base_url + "/api/player-analytics/", { headers: headers })
          .then((response) => { })
          .catch((err) => {
            // console.log("error ", err);
          });
      } else {
        this.setState({
          selectedSnovelOriginalProductIndex: "",
          selectedSnovelOriginalTrackIndex: 0,
          isPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            {},
            0,
            false,
            false,
            false,
            false,
            false,
            "tryFreeSnovelOriginal",
          ],
        });
      }
    }
  }
  showPlayerForProductSnovelOriginalAfterAnalytics(productIndex) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      product_id: this.state.snovelOriginalAllProducts[productIndex].id,
    };
    axios
      .post(api_base_url + "/api/last-played/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var latestTrackId = response.data.data.track_id || "";
          var newSelectedTrackIndex = 0;
          for (
            var i = 0;
            i <
            this.state.snovelOriginalAllProducts[productIndex].producttracks
              .length;
            i++
          ) {
            var eachTrack = this.state.snovelOriginalAllProducts[productIndex]
              .producttracks[i];
            if (eachTrack.id === latestTrackId) {
              newSelectedTrackIndex = i;
            }
          }
          this.setState({
            selectedSnovelOriginalProductIndex: productIndex,
            selectedSnovelOriginalTrackIndex: newSelectedTrackIndex,
            isPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.state.snovelOriginalAllProducts[productIndex],
              newSelectedTrackIndex,
              false,
              false,
              false,
              false,
              "tryFreeSnovelOriginal",
            ],
          });
        } else {
          this.setState({
            selectedSnovelOriginalProductIndex: productIndex,
            selectedSnovelOriginalTrackIndex: 0,
            isPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.state.snovelOriginalAllProducts[productIndex],
              0,
              false,
              false,
              false,
              false,
              "tryFreeSnovelOriginal",
            ],
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          selectedSnovelOriginalProductIndex: productIndex,
          selectedSnovelOriginalTrackIndex: 0,
          isPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.snovelOriginalAllProducts[productIndex],
            0,
            false,
            false,
            false,
            false,
            "tryFreeSnovelOriginal",
          ],
        });
      });
  }
  playProductTrackSnovelOriginal(trackIndex) {
    if (this.state.selectedSnovelOriginalTrackIndex !== trackIndex) {
      this.props.dispatch({
        type: "PLAY_SELECTED_PRODUCT",
        payload: [
          this.state.snovelOriginalAllProducts[
          this.state.selectedSnovelOriginalProductIndex
          ],
          trackIndex,
          false,
          false,
          false,
          false,
          "tryFreeSnovelOriginal",
        ],
      });
      this.setState({
        selectedSnovelOriginalTrackIndex: trackIndex,
        isPaused: false,
      });
    } else {
      if (this.state.isPaused) {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.snovelOriginalAllProducts[
            this.state.selectedSnovelOriginalProductIndex
            ],
            trackIndex,
            false,
            true,
            false,
            false,
            "tryFreeSnovelOriginal",
          ],
        });
        this.setState({
          isPaused: false,
        });
      } else {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.snovelOriginalAllProducts[
            this.state.selectedSnovelOriginalProductIndex
            ],
            trackIndex,
            true,
            false,
            false,
            false,
            "tryFreeSnovelOriginal",
          ],
        });
        this.setState({
          isPaused: true,
        });
      }
    }
  }
  /*****************************
   * Podcast Try Free Snovel
   *****************************/
  podCatFreeProducts() {
    return this.state.podcastAllProducts.map((podCast, selectedIndex) => {
      if (
        podCast.id == this.state.selectedPodcastProductId &&
        this.state.isAllowUpdate
      ) {
        this.setState({
          selectedPodcastProductIndex: selectedIndex,
          isAllowUpdate: false,
        });
      }
      return (
        <div>
          <div class="mySnovelVideo freeTrailMpbileVideo">
            <Row className="mySnovelVideo-margin">
              <Col md="10" lg="10" className="freeTrailMobile">
                <div
                  class="videoThumbDiv"
                  onClick={() => {
                    this.showPlayerForPodcast(selectedIndex);
                  }}
                >
                  {podCast.images.length > 0 ? (
                    <Fragment>
                      {podCast.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                      <img
                        class="purchase-library-image"
                        src={podCast.images[0]["image2"]}
                        alt=""
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      {podCast.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                      <img
                        class="purchase-library-image"
                        src="/images/default.jpg"
                        alt=""
                      />
                    </Fragment>
                  )}
                  <a title="" class="playButn">
                    {this.state.selectedPodcastProductIndex !==
                      selectedIndex && (
                        <img src="/images/play-button.svg" alt="" />
                      )}
                    {/* {this.state.selectedPodcastProductIndex ===
                      selectedIndex && (
                      <img src="/images/round-pause-button.svg" alt="" />
                    )} */}
                  </a>
                </div>
                <div class="videoDesc">
                  <h4>
                    <span
                      onClick={() => {
                        this.productDetailsId(podCast.id, podCast.product_slug);
                      }}
                    >
                      {
                        podCast.custom_translation[this.state.selectedLanguage]
                          .name
                      }
                    </span>
                  </h4>
                  <span class="cateTitle">{strings.podcast}</span>
                  {podCast.authors.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        <span class="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span
                          class="authorDes authorDesLink"
                          onClick={() => {
                            this.authorDetailsId(
                              podCast.authors[0].id,
                              podCast.authors[0].name
                            );
                          }}
                        >
                          {this.authorsSelected(podCast.authors)}
                        </span>
                      </div>
                    </div>
                  )}
                  {podCast.directors.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        {" "}
                        <span class="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span class="authorDes">
                          {this.directorsSelected(podCast.directors)}
                        </span>
                      </div>
                    </div>
                  )}
                  {podCast.artists.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        <span class="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span class="authorDes">
                          {this.artistsSelected(podCast.artists)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div class="videoCnt">
                    <div className="headingText">
                      <span class="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {podCast.category.map((categoryList, index) => {
                        if (
                          categoryList.custom_translation[
                          this.state.selectedLanguage
                          ] != undefined
                        ) {
                          return (
                            <NavLink
                              tag={Link}
                              to={{
                                pathname: "/browse",
                              }}
                              onClick={() => {
                                this.props.dispatch({
                                  type: "MENU_SELECTED_CATEGORY",
                                  payload: [
                                    categoryList.name,
                                    podCast.product_audio_type,
                                    categoryList.id,
                                  ],
                                });
                                this.props.dispatch(
                                  browseCategoryProductDetailsDataAction(
                                    podCast.product_audio_type,
                                    categoryList.name,
                                    1
                                  )
                                );
                              }}
                              key={index}
                              className="genreLinkText"
                            >
                              <span key={index}>
                                {
                                  categoryList.custom_translation[
                                    this.state.selectedLanguage
                                  ].name
                                }
                              </span>
                            </NavLink>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div
                    class="videoCnt"
                    onClick={() => {
                      this.showPlayerForPodcast(selectedIndex);
                    }}
                  >
                    <span className="subsCribeButton playAll">
                      {strings.playAll}
                    </span>
                  </div>
                </div>
              </Col>
              <Col md="2" lg="2" className="freeTrailTrackMobile">
                <div class="mySnoveltrackDiv">
                  <span>
                    <strong>{podCast.producttracks.length}</strong>
                    <br />
                    {strings.trackText}
                  </span>
                </div>
                <div class="mySnoveldurDiv">
                  <span>
                    {strings.durationText}
                    <br />
                    <strong>
                      {getHoursFormat(podCast.total_tracks_length) +
                        " " +
                        strings.hrsText}
                    </strong>
                  </span>
                </div>
                <StarRating rating={podCast.total_reviews} />
              </Col>
            </Row>
          </div>
          {this.state.selectedPodcastProductIndex === selectedIndex && (
            <div>
              {podCast.producttracks.map((eachTrack, trackIndex) => {

                return (
                  <div
                    class={
                      this.state.selectedPodcastTrackIndex == trackIndex
                        ? "videoPlayList active-play-list"
                        : "videoPlayList"
                    }
                  >
                    <div
                      class="videoPlayP"
                      onClick={() => {
                        this.playProductTrackPodcast(trackIndex);
                      }}
                    >
                      <img
                        src={
                          this.state.selectedPodcastTrackIndex == trackIndex &&
                            !this.state.isPodcastPaused
                            ? "/images/p_video.png"
                            : "/images/v_play.png"
                        }
                      />
                    </div>
                    <div class="videoPlayTitle">
                      <span>{trackIndex + 1}</span>
                      {eachTrack.custom_translation[this.state.selectedLanguage]
                        ? eachTrack.custom_translation[
                          this.state.selectedLanguage
                        ].name
                        : eachTrack.custom_translation["EN"].name}
                    </div>
                    <div class="videoPlayDuration">
                      {strings.durationText}
                      <br />
                      <span>
                        {getHoursFormat(eachTrack.track_length) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  }
  playProductTrackPodcast(trackIndex) {
    if (this.state.selectedPodcastTrackIndex !== trackIndex) {
      this.props.dispatch({
        type: "PLAY_SELECTED_PRODUCT",
        payload: [
          this.state.podcastAllProducts[this.state.selectedPodcastProductIndex],
          trackIndex,
          false,
          false,
          false,
          false,
          "tryFreePodcast",
        ],
      });
      this.setState({
        selectedPodcastTrackIndex: trackIndex,
        isPodcastPaused: false,
      });
    } else {

      if (this.state.isPaused) {

        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.podcastAllProducts[
            this.state.selectedPodcastProductIndex
            ],
            trackIndex,
            false,
            true,
            false,
            false,
            "tryFreePodcast",
          ],
        });
        this.setState({
          isPodcastPaused: !this.state.isPaused,
          isPaused: !this.state.isPaused,
        });
      } else {

        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.podcastAllProducts[
            this.state.selectedPodcastProductIndex
            ],
            trackIndex,
            true,
            false,
            false,
            false,
            "tryFreePodcast",
          ],
        });
        this.setState({
          isPodcastPaused: !this.state.isPaused,
          isPaused: !this.state.isPaused,
        });
      }
    }
  }
  showPlayerForPodcast(productIndex) {
    if (this.state.podcastAllProducts[productIndex].producttracks.length > 0) {
      if (
        this.state.selectedPodcastProductIndex === "" ||
        this.state.selectedPodcastProductIndex !== productIndex
      ) {
        this.showPlayerForPodcastAfterAnalytics(productIndex);
        var headers = {
          "Content-Type": "application/json",
        };
        axios
          .get(api_base_url + "/api/player-analytics/", { headers: headers })
          .then((response) => { })
          .catch((err) => {
            // console.log("error ", err);
          });
      } else {
        this.setState({
          selectedPodcastProductIndex: "",
          selectedPodcastTrackIndex: 0,
          isPodcastPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [{}, 0, false, false, false, false, false, "tryFreePodcast"],
        });
      }
    }
  }
  showPlayerForPodcastAfterAnalytics(productIndex) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      product_id: this.state.podcastAllProducts[productIndex].id,
    };
    axios
      .post(api_base_url + "/api/last-played/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var latestTrackId = response.data.data.track_id || "";
          var newSelectedTrackIndex = 0;
          for (
            var i = 0;
            i <
            this.state.podcastAllProducts[productIndex].producttracks.length;
            i++
          ) {
            var eachTrack = this.state.podcastAllProducts[productIndex]
              .producttracks[i];
            if (eachTrack.id === latestTrackId) {
              newSelectedTrackIndex = i;
            }
          }
          this.setState({
            selectedPodcastProductIndex: productIndex,
            selectedPodcastTrackIndex: newSelectedTrackIndex,
            isPodcastPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.state.podcastAllProducts[productIndex],
              newSelectedTrackIndex,
              false,
              false,
              false,
              false,
              "tryFreePodcast",
            ],
          });
        } else {
          this.setState({
            selectedPodcastProductIndex: productIndex,
            selectedPodcastTrackIndex: 0,
            isPodcastPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.state.podcastAllProducts[productIndex],
              0,
              false,
              false,
              false,
              false,
              "tryFreePodcast",
            ],
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          selectedPodcastProductIndex: productIndex,
          selectedPodcastTrackIndex: 0,
          isPodcastPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.podcastAllProducts[productIndex],
            0,
            false,
            false,
            false,
            false,
            "tryFreePodcast",
          ],
        });
      });
  }
  /*********************End Pod cast chnages  *****************/
  /*****************************
   * Podcast Try Free Snovel
   *****************************/
  kidsCatFreeProducts() {
    return this.state.kidsAllProducts.map((kidsItem, selectedIndex) => {

      if (
        kidsItem.id == this.state.selectedKidsProductId &&
        this.state.isAllowUpdate
      ) {
        this.setState({
          selectedKidsProductIndex: selectedIndex,
          isAllowUpdate: false,
        });
      }
      return (
        <div>
          <div class="mySnovelVideo freeTrailMpbileVideo">
            <Row className="mySnovelVideo-margin">
              <Col md="10" lg="10" className="freeTrailMobile">
                <div
                  class="videoThumbDiv"
                  onClick={() => {
                    this.showPlayerForKids(selectedIndex);
                  }}
                >
                  {kidsItem.images.length > 0 ? (
                    <Fragment>
                      {kidsItem.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                      <img
                        class="purchase-library-image"
                        src={kidsItem.images[0]["image2"]}
                        alt=""
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      {kidsItem.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                      <img
                        class="purchase-library-image"
                        src="/images/default.jpg"
                        alt=""
                      />
                    </Fragment>
                  )}
                  <a title="" class="playButn">
                    {this.state.selectedKidsProductIndex !==
                      selectedIndex && (
                        <img src="/images/play-button.svg" alt="" />
                      )}
                    {/* {this.state.selectedPodcastProductIndex ===
                      selectedIndex && (
                      <img src="/images/round-pause-button.svg" alt="" />
                    )} */}
                  </a>
                </div>
                <div class="videoDesc">
                  <h4>
                    <span
                      onClick={() => {
                        this.productDetailsId(kidsItem.id, kidsItem.product_slug);
                      }}
                    >
                      {
                        kidsItem.custom_translation[this.state.selectedLanguage]
                          .name
                      }
                    </span>
                  </h4>
                  <span class="cateTitle">{strings.kidsItem}</span>
                  {kidsItem.authors.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        <span class="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span
                          class="authorDes authorDesLink"
                          onClick={() => {
                            this.authorDetailsId(
                              kidsItem.authors[0].id,
                              kidsItem.authors[0].name
                            );
                          }}
                        >
                          {this.authorsSelected(kidsItem.authors)}
                        </span>
                      </div>
                    </div>
                  )}
                  {kidsItem.directors.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        {" "}
                        <span class="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span class="authorDes">
                          {this.directorsSelected(kidsItem.directors)}
                        </span>
                      </div>
                    </div>
                  )}
                  {kidsItem.artists.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        <span class="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span class="authorDes">
                          {this.artistsSelected(kidsItem.artists)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div class="videoCnt">
                    <div className="headingText">
                      <span class="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {kidsItem.category.map((categoryList, index) => {
                        if (
                          categoryList.custom_translation[
                          this.state.selectedLanguage
                          ] != undefined
                        ) {
                          return (
                            <NavLink
                              tag={Link}
                              to={{
                                pathname: "/browse",
                              }}
                              onClick={() => {
                                this.props.dispatch({
                                  type: "MENU_SELECTED_CATEGORY",
                                  payload: [
                                    categoryList.name,
                                    kidsItem.product_audio_type,
                                    categoryList.id,
                                  ],
                                });
                                this.props.dispatch(
                                  browseCategoryProductDetailsDataAction(
                                    kidsItem.product_audio_type,
                                    categoryList.name,
                                    1
                                  )
                                );
                              }}
                              key={index}
                              className="genreLinkText"
                            >
                              <span key={index}>
                                {
                                  categoryList.custom_translation[
                                    this.state.selectedLanguage
                                  ].name
                                }
                              </span>
                            </NavLink>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div
                    class="videoCnt"
                    onClick={() => {
                      this.showPlayerForKids(selectedIndex);
                    }}
                  >
                    <span className="subsCribeButton playAll">
                      {strings.playAll}
                    </span>
                  </div>
                </div>
              </Col>
              <Col md="2" lg="2" className="freeTrailTrackMobile">
                <div class="mySnoveltrackDiv">
                  <span>
                    <strong>{kidsItem.producttracks.length}</strong>
                    <br />
                    {strings.trackText}
                  </span>
                </div>
                <div class="mySnoveldurDiv">
                  <span>
                    {strings.durationText}
                    <br />
                    <strong>
                      {getHoursFormat(kidsItem.total_tracks_length) +
                        " " +
                        strings.hrsText}
                    </strong>
                  </span>
                </div>
                <StarRating rating={kidsItem.total_reviews} />
              </Col>
            </Row>
          </div>
          {this.state.selectedKidsProductIndex === selectedIndex && (
            <div>
              {kidsItem.producttracks.map((eachTrack, trackIndex) => {

                return (
                  <div
                    class={
                      this.state.selectedKidsTrackIndex == trackIndex
                        ? "videoPlayList active-play-list"
                        : "videoPlayList"
                    }
                  >
                    <div
                      class="videoPlayP"
                      onClick={() => {
                        this.playProductTrackKids(trackIndex);
                      }}
                    >
                      <img
                        src={
                          this.state.selectedKidsTrackIndex == trackIndex &&
                            !this.state.isKidsPaused
                            ? "/images/p_video.png"
                            : "/images/v_play.png"
                        }
                      />
                    </div>
                    <div class="videoPlayTitle">
                      <span>{trackIndex + 1}</span>
                      {eachTrack.custom_translation[this.state.selectedLanguage]
                        ? eachTrack.custom_translation[
                          this.state.selectedLanguage
                        ].name
                        : eachTrack.custom_translation["EN"].name}
                    </div>
                    <div class="videoPlayDuration">
                      {strings.durationText}
                      <br />
                      <span>
                        {getHoursFormat(eachTrack.track_length) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  }
  /*****************************
   * Language Lab Try Free Snovel
   *****************************/
  languageLabCatFreeProducts() {
    return this.state.languageLabAllProducts.map((languageLabItem, selectedIndex) => {
      if (
        languageLabItem.id == this.state.selectedLanguageLabProductId &&
        this.state.isAllowUpdate
      ) {
        this.setState({
          selectedLanguageLabProductIndex: selectedIndex,
          isAllowUpdate: false,
        });
      }
      return (
        <div>
          <div class="mySnovelVideo freeTrailMpbileVideo">
            <Row className="mySnovelVideo-margin">
              <Col md="10" lg="10" className="freeTrailMobile">
                <div
                  class="videoThumbDiv"
                  onClick={() => {
                    this.showPlayerForLanguageLab(selectedIndex);
                  }}
                >
                  {languageLabItem.images.length > 0 ? (
                    <Fragment>
                      {languageLabItem.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                      <img
                        class="purchase-library-image"
                        src={languageLabItem.images[0]["image2"]}
                        alt=""
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      {languageLabItem.is_free && <span className="freeProduct">{strings.FreeText}</span>}
                      <img
                        class="purchase-library-image"
                        src="/images/default.jpg"
                        alt=""
                      />
                    </Fragment>
                  )}
                  <a title="" class="playButn">
                    {this.state.selectedLanguageLabProductIndex !==
                      selectedIndex && (
                        <img src="/images/play-button.svg" alt="" />
                      )}
                  </a>
                </div>
                <div class="videoDesc">
                  <h4>
                    <span
                      onClick={() => {
                        this.productDetailsId(languageLabItem.id, languageLabItem.product_slug);
                      }}
                    >
                      {
                        languageLabItem.custom_translation[this.state.selectedLanguage]
                          .name
                      }
                    </span>
                  </h4>
                  <span class="cateTitle">{strings.languageLabItem}</span>
                  {languageLabItem.authors.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        <span class="author">{strings.authorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span
                          class="authorDes authorDesLink"
                          onClick={() => {
                            this.authorDetailsId(
                              languageLabItem.authors[0].id,
                              languageLabItem.authors[0].name
                            );
                          }}
                        >
                          {this.authorsSelected(languageLabItem.authors)}
                        </span>
                      </div>
                    </div>
                  )}
                  {languageLabItem.directors.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        {" "}
                        <span class="author">{strings.directorText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span class="authorDes">
                          {this.directorsSelected(languageLabItem.directors)}
                        </span>
                      </div>
                    </div>
                  )}
                  {languageLabItem.artists.length > 0 && (
                    <div class="videoCnt">
                      <div className="headingText">
                        <span class="author">{strings.artistsText}</span>
                      </div>
                      <div className="colon-symbol">:</div>
                      <div className="loopdata">
                        <span class="authorDes">
                          {this.artistsSelected(languageLabItem.artists)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div class="videoCnt">
                    <div className="headingText">
                      <span class="author">{strings.genreText}</span>
                    </div>
                    <div className="colon-symbol">:</div>
                    <div className="loopdata">
                      {languageLabItem.category.map((categoryList, index) => {
                        if (
                          categoryList.custom_translation[
                          this.state.selectedLanguage
                          ] != undefined
                        ) {
                          return (
                            <NavLink
                              tag={Link}
                              to={{
                                pathname: "/browse",
                              }}
                              onClick={() => {
                                this.props.dispatch({
                                  type: "MENU_SELECTED_CATEGORY",
                                  payload: [
                                    categoryList.name,
                                    languageLabItem.product_audio_type,
                                    categoryList.id,
                                  ],
                                });
                                this.props.dispatch(
                                  browseCategoryProductDetailsDataAction(
                                    languageLabItem.product_audio_type,
                                    categoryList.name,
                                    1
                                  )
                                );
                              }}
                              key={index}
                              className="genreLinkText"
                            >
                              <span key={index}>
                                {
                                  categoryList.custom_translation[
                                    this.state.selectedLanguage
                                  ].name
                                }
                              </span>
                            </NavLink>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div
                    class="videoCnt"
                    onClick={() => {
                      this.showPlayerForLanguageLab(selectedIndex);
                    }}
                  >
                    <span className="subsCribeButton playAll">
                      {strings.playAll}
                    </span>
                  </div>
                </div>
              </Col>
              <Col md="2" lg="2" className="freeTrailTrackMobile">
                <div class="mySnoveltrackDiv">
                  <span>
                    <strong>{languageLabItem.producttracks.length}</strong>
                    <br />
                    {strings.trackText}
                  </span>
                </div>
                <div class="mySnoveldurDiv">
                  <span>
                    {strings.durationText}
                    <br />
                    <strong>
                      {getHoursFormat(languageLabItem.total_tracks_length) +
                        " " +
                        strings.hrsText}
                    </strong>
                  </span>
                </div>
                <StarRating rating={languageLabItem.total_reviews} />
              </Col>
            </Row>
          </div>
          {this.state.selectedLanguageLabProductIndex === selectedIndex && (
            <div>
              {languageLabItem.producttracks.map((eachTrack, trackIndex) => {
                return (
                  <div
                    class={
                      this.state.selectedLanguageLabTrackIndex == trackIndex
                        ? "videoPlayList active-play-list"
                        : "videoPlayList"
                    }
                  >
                    <div
                      class="videoPlayP"
                      onClick={() => {
                        this.playProductTrackLanguageLab(trackIndex);
                      }}
                    >
                      <img
                        src={
                          this.state.selectedLanguageLabTrackIndex == trackIndex &&
                            !this.state.isLanguageLabPaused
                            ? "/images/p_video.png"
                            : "/images/v_play.png"
                        }
                      />
                    </div>
                    <div class="videoPlayTitle">
                      <span>{trackIndex + 1}</span>
                      {eachTrack.custom_translation[this.state.selectedLanguage]
                        ? eachTrack.custom_translation[
                          this.state.selectedLanguage
                        ].name
                        : eachTrack.custom_translation["EN"].name}
                    </div>
                    <div class="videoPlayDuration">
                      {strings.durationText}
                      <br />
                      <span>
                        {getHoursFormat(eachTrack.track_length) +
                          " " +
                          strings.hrsText}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  }
  playProductTrackLanguageLab(trackIndex) {
    if (this.state.selectedLanguageLabTrackIndex !== trackIndex) {
      this.props.dispatch({
        type: "PLAY_SELECTED_PRODUCT",
        payload: [
          this.state.languageLabAllProducts[this.state.selectedLanguageLabProductIndex],
          trackIndex,
          false,
          false,
          false,
          false,
          "tryFreeLanguageLab",
        ],
      });
      this.setState({
        selectedLanguageLabTrackIndex: trackIndex,
        isLanguageLabPaused: false,
      });
    } else {
      if (this.state.isPaused) {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.languageLabAllProducts[
            this.state.selectedLanguageLabProductIndex
            ],
            trackIndex,
            false,
            true,
            false,
            false,
            "tryFreeLanguageLab",
          ],
        });
        this.setState({
          isLanguageLabPaused: !this.state.isPaused,
          isPaused: !this.state.isPaused,
        });
      } else {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.languageLabAllProducts[
            this.state.selectedLanguageLabProductIndex
            ],
            trackIndex,
            true,
            false,
            false,
            false,
            "tryFreeLanguageLab",
          ],
        });
        this.setState({
          isLanguageLabPaused: !this.state.isPaused,
          isPaused: !this.state.isPaused,
        });
      }
    }
  }
  playProductTrackKids(trackIndex) {
    if (this.state.selectedKidsTrackIndex !== trackIndex) {
      this.props.dispatch({
        type: "PLAY_SELECTED_PRODUCT",
        payload: [
          this.state.kidsAllProducts[this.state.selectedKidsProductIndex],
          trackIndex,
          false,
          false,
          false,
          false,
          "tryFreeKids",
        ],
      });
      this.setState({
        selectedKidsTrackIndex: trackIndex,
        isKidsPaused: false,
      });
    } else {

      if (this.state.isPaused) {

        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.kidsAllProducts[
            this.state.selectedKidsProductIndex
            ],
            trackIndex,
            false,
            true,
            false,
            false,
            "tryFreeKids",
          ],
        });
        this.setState({
          isKidsPaused: !this.state.isPaused,
          isPaused: !this.state.isPaused,
        });
      } else {

        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.kidsAllProducts[
            this.state.selectedKidsProductIndex
            ],
            trackIndex,
            true,
            false,
            false,
            false,
            "tryFreeKids",
          ],
        });
        this.setState({
          isKidsPaused: !this.state.isPaused,
          isPaused: !this.state.isPaused,
        });
      }
    }
  }
  showPlayerForKids(productIndex) {
    if (this.state.kidsAllProducts[productIndex].producttracks.length > 0) {
      if (
        this.state.selectedKidsProductIndex === "" ||
        this.state.selectedKidsProductIndex !== productIndex
      ) {
        this.showPlayerForKidsAfterAnalytics(productIndex);
        var headers = {
          "Content-Type": "application/json",
        };
        axios
          .get(api_base_url + "/api/player-analytics/", { headers: headers })
          .then((response) => { })
          .catch((err) => {
            // console.log("error ", err);
          });
      } else {
        this.setState({
          selectedKidsProductIndex: "",
          selectedKidsTrackIndex: 0,
          isKidsPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [{}, 0, false, false, false, false, false, "tryFreeKids"],
        });
      }
    }
  }
  showPlayerForLanguageLab(productIndex) {
    if (this.state.languageLabAllProducts[productIndex].producttracks.length > 0) {
      if (
        this.state.selectedLanguageLabProductIndex === "" ||
        this.state.selectedLanguageLabProductIndex !== productIndex
      ) {
        this.showPlayerForLanguageLabAfterAnalytics(productIndex);
        var headers = {
          "Content-Type": "application/json",
        };
        axios
          .get(api_base_url + "/api/player-analytics/", { headers: headers })
          .then((response) => { })
          .catch((err) => {
            // console.log("error ", err);
          });
      } else {
        this.setState({
          selectedLanguageLabProductIndex: "",
          selectedLanguageLabTrackIndex: 0,
          isLanguageLabPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [{}, 0, false, false, false, false, false, "tryFreeLanguageLab"],
        });
      }
    }
  }
  showPlayerForKidsAfterAnalytics(productIndex) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      product_id: this.state.kidsAllProducts[productIndex].id,
    };
    axios
      .post(api_base_url + "/api/last-played/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var latestTrackId = response.data.data.track_id || "";
          var newSelectedTrackIndex = 0;
          for (
            var i = 0;
            i <
            this.state.kidsAllProducts[productIndex].producttracks.length;
            i++
          ) {
            var eachTrack = this.state.kidsAllProducts[productIndex]
              .producttracks[i];
            if (eachTrack.id === latestTrackId) {
              newSelectedTrackIndex = i;
            }
          }
          this.setState({
            selectedKidsProductIndex: productIndex,
            selectedKidsTrackIndex: newSelectedTrackIndex,
            isKidsPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.state.kidsAllProducts[productIndex],
              newSelectedTrackIndex,
              false,
              false,
              false,
              false,
              "tryFreeKids",
            ],
          });
        } else {
          this.setState({
            selectedKidsProductIndex: productIndex,
            selectedKidsTrackIndex: 0,
            isKidsPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.state.kidsAllProducts[productIndex],
              0,
              false,
              false,
              false,
              false,
              "tryFreeKids",
            ],
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          selectedKidsProductIndex: productIndex,
          selectedKidsTrackIndex: 0,
          isKidsPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.kidsAllProducts[productIndex],
            0,
            false,
            false,
            false,
            false,
            "tryFreeKids",
          ],
        });
      });
  }
  showPlayerForLanguageLabAfterAnalytics(productIndex) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      product_id: this.state.languageLabAllProducts[productIndex].id,
    };
    axios
      .post(api_base_url + "/api/last-played/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var latestTrackId = response.data.data.track_id || "";
          var newSelectedTrackIndex = 0;
          for (
            var i = 0;
            i <
            this.state.languageLabAllProducts[productIndex].producttracks.length;
            i++
          ) {
            var eachTrack = this.state.languageLabAllProducts[productIndex]
              .producttracks[i];
            if (eachTrack.id === latestTrackId) {
              newSelectedTrackIndex = i;
            }
          }
          this.setState({
            selectedLanguageLabProductIndex: productIndex,
            selectedLanguageLabTrackIndex: newSelectedTrackIndex,
            isLanguageLabPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.state.languageLabAllProducts[productIndex],
              newSelectedTrackIndex,
              false,
              false,
              false,
              false,
              "tryFreeLanguageLab",
            ],
          });
        } else {
          this.setState({
            selectedLanguageLabProductIndex: productIndex,
            selectedLanguageLabTrackIndex: 0,
            isLanguageLabPaused: false,
          });
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              this.state.languageLabAllProducts[productIndex],
              0,
              false,
              false,
              false,
              false,
              "tryFreeLanguageLab",
            ],
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          selectedLanguageLabProductIndex: productIndex,
          selectedLanguageLabTrackIndex: 0,
          isLanguageLabPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            this.state.languageLabAllProducts[productIndex],
            0,
            false,
            false,
            false,
            false,
            "tryFreeLanguageLab",
          ],
        });
      });
  }
  /***************  End Kids Category ******************/
  selectedProductType(updatePostType) {
    if (updatePostType === "audiobook") {
      this.tabToggle("1");
      this.props.dispatch(
        tryFreeProductDetailsDataAction(updatePostType, this.state.token)
      );
    } else if (updatePostType === "snoveloriginal") {
      this.tabToggle("2");
      this.props.dispatch(
        tryFreeProductDetailsDataAction(updatePostType, this.state.token)
      );
    } else if (updatePostType === "podcast") {
      this.tabToggle("3");
      this.props.dispatch(
        tryFreeProductDetailsDataAction(updatePostType, this.state.token)
      );
    } else if (updatePostType === "kids") {
      this.tabToggle("4");
      this.props.dispatch(
        tryFreeProductDetailsDataAction(updatePostType, this.state.token)
      );
    } else if (updatePostType === "language-lab") {
      this.tabToggle("5");
      this.props.dispatch(
        tryFreeProductDetailsDataAction(updatePostType, this.state.token)
      );
    }

  }
  render() {
    return (
      <div>
        <div className="freeSubscription-wrap">
          <UserProfileSubscribe
            notificationCount={this.state.notificationCount}
          />
        </div>
        <div className="homeTabs tryFree-snovel-wrapper">
          <div className="container mobileRemovePadding">
            <Nav tabs className="nav-pills">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.selectedProductType("audiobook");
                  }}
                >
                  <div className="tabIcon_image">
                    {this.state.activeTab === "1" ? (
                      <img src="/images/Audiobook_Active.svg" />
                    ) : (
                      <img src="/images/Audiobook_Normal.svg" />
                    )}
                  </div>
                  <div className="tabRight_Content">{strings.audiobook} </div>
                </NavLink>
              </NavItem>
              <NavItem className="snovelOriginal">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.selectedProductType("snoveloriginal");
                  }}
                >
                  <div className="tabIcon_image">
                    {this.state.activeTab === "2" ? (
                      <img src="/images/ico_SnovelOriginals-Active.png" />
                    ) : (
                      <img src="/images/ico_SnovelOriginals-Normal.png" />
                    )}
                  </div>
                  <div className="tabRight_Content">
                    {strings.snoveloriginal}{" "}
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => {
                    this.selectedProductType("podcast");
                  }}
                >
                  <div className="tabIcon_image">
                    {this.state.activeTab === "3" ? (
                      <img src="/images/ico_Podcast-Active.png" />
                    ) : (
                      <img src="/images/ico_Podcast-Normal.png" />
                    )}
                  </div>
                  <div className="tabRight_Content"> {strings.podcast} </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "4",
                  })}
                  onClick={() => {
                    this.selectedProductType("kids");
                  }}
                >
                  <div className="tabIcon_image chintoo">
                    {this.state.activeTab === "4" ? (
                      <img src="/images/chintoo-active.png" />
                    ) : (
                      <img src="/images/chintoo.png" />
                    )}
                  </div>
                  <div className="tabRight_Content">
                    {strings.kidsMenu}{" "}
                  </div>
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "5",
                  })}
                  onClick={() => {
                    this.selectedProductType("language-lab");
                  }}
                >
                  <div className="tabIcon_image">
                    {this.state.activeTab === "5" ? (
                      <img src="/images/ico_SnovelOriginals-Active.png" />
                    ) : (
                      <img src="/images/ico_SnovelOriginals-Normal.png" />
                    )}
                  </div>
                  <div className="tabRight_Content">
                    {strings.languageLab}{" "}
                  </div>
                </NavLink>
              </NavItem> */}
            </Nav>
          </div>
          <hr />
          <div className="container">
            <TabContent
              activeTab={this.state.activeTab}
              className="audioBook_Wrapper audioBook-Wrapper-freeSnovel"
            >
              <TabPane tabId="1">
                <div class="mySnovel">
                  {this.state.audiobookAllProducts.length > 0
                    ? this.audioBookAllFreeProducts()
                    : strings.ProductNotFound}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div class="mySnovel">
                  {this.state.snovelOriginalAllProducts.length > 0
                    ? this.snovelOriginalFreeProducts()
                    : strings.ProductNotFound}
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div class="mySnovel">
                  {this.state.podcastAllProducts.length > 0
                    ? this.podCatFreeProducts()
                    : strings.ProductNotFound}
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div class="mySnovel">
                  {this.state.kidsAllProducts.length > 0
                    ? this.kidsCatFreeProducts()
                    : strings.ProductNotFound}
                </div>
              </TabPane>
              {/* <TabPane tabId="5">
                <div class="mySnovel">
                {this.state.languageLabAllProducts.length > 0
                    ? this.languageLabCatFreeProducts()
                    : strings.ProductNotFound} 
                </div>
              </TabPane> */}
            </TabContent>
          </div>
        </div>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    userSubscriptionReducer:
      state.userSubscriptionReducer.userSubscriptionDetails,
    userShortName: state.userSubscriptionReducer.shortName,
    tryFreeProductDetailsData:
      state.tryFreeProductDetailsData.tryFreeSnovelProducts,
    selectedProductToPlay: state.selectedProductToPlay,
    authData: state.authonticationDetails,
  };
}

const myConnector = connect(mapStatesToProps);
export default myConnector(tryFreeSnovelProducts);
