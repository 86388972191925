import React, { Component } from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import "react-datepicker/dist/react-datepicker.css";
import {
  api_base_url,
  encryptDecryptKey,
  countryList,
  nameRegularExpression,
  mobileRegularExpression,
  allImageExtensions,
  stateList,
  emailReg
} from "../../config";
import { withRouter } from "react-router-dom";
import axios from "axios";
import LocalizedStrings from "react-localization";
import SimpleCrypto from "simple-crypto-js";
import languages from "../../language/language.json";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
var strings = new LocalizedStrings(languages);

class MyProfileModal extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(this.props.selectedLanguage);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    var loginWithMobile = "";
    var originalEmail = "";
    var originalSubscribeStatus = false;
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
        originalEmail = userDetailsObject.userEmail || "";
        originalSubscribeStatus = userDetailsObject.subscribeStatus || false;
        loginWithMobile = userDetailsObject.isLoggedInWithMobile || "";
        this.getUserProfileDetails(token);
        this.getUserSubscriptionDetails(userDetailsObject.token);
      } else {
        this.props.closeModal();
      }
    } else {
      this.props.closeModal();
    }
    var newCountryNameList = {};
    const { location } = this.props;
    var newDialCodeList = {};
    for (var i = 0; i < countryList.length; i++) {
      var eachCountry = countryList[i];
      newCountryNameList[eachCountry.code] = eachCountry.name;
      newDialCodeList[eachCountry.code] = eachCountry.dial_code;
    }
    var newStateNameList = {};
    for (var i = 0; i < stateList.length; i++) {
      var eachState = stateList[i];
      newStateNameList[eachState.code] = eachState.name;
    }
    var yesterdayDateObject = new Date();
    yesterdayDateObject.setDate(yesterdayDateObject.getDate() - 1);
    this.state = {
      token: token,
      originalEmail: originalEmail,
      originalSubscribeStatus: originalSubscribeStatus,
      profileImage: "",
      firstName: "",
      institute: "",
      editFirstName: "",
      lastName: "",
      editLastName: "",
      subscription: [],
      countryCode: "",
      stateCode: "",
      fixdata: "",
      editCountryCode: "",
      editStateCode: "",
      countryNameList: newCountryNameList,
      stateNameList: newStateNameList,
      dialCodeList: newDialCodeList,
      gender: "",
      editGender: "",
      genderList: {
        "1": "Male",
        "2": "Female",
        "3": "Other",
      },
      monthShortName: {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sept",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      },
      dateOfBirth: "",
      mobileNumber: "",
      editMobileNumber: "",
      mobileNumberError: "",
      email: "",
      editMode: false,
      isLoading: true,
      isSubcribeNewsletter: false,
      dobDate: null,
      currentDate: yesterdayDateObject,
      changePasswordMode: false,
      confirmPassword: "",
      newPassword: "",
      oldPassword: "",
      confirmPasswordError: "",
      newPasswordError: "",
      oldPasswordError: "",
      invalidPassword: "",
      subscriptionlist: [],
      isLoginWithMobileNumber : loginWithMobile,
      emailError: "",
      isEditEmail: false,
    };
    this.dobDateChange = this.dobDateChange.bind(this);

  }

  dobDateChange(newDate) {
    this.setState({
      dobDate: newDate,
    });
  }

  componentDidMount() {
    if (this.props.profileStatus) {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .get(api_base_url + "/api/otp/check-user-profile-status/", { headers: headers })
        .then((response) => {
          if (response) {
            if (!response.data.is_profile_completed) {
              this.editProfile();
              this.setState({
                isNewUser: true
              })
            } else {
              this.setState({
                isNewUser: false
              })
            }
          }
        })

    }

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.is_new_user) {
        this.editProfile();
      }
    }
  }

  getUserProfileDetails(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/v1/my-profile/", { headers: headers })
      .then((response) => {

        if (response.data.status) {
          var userDetails = response.data.data.my_profile;
          var my_subscription = response.data.data.my_subscription || [];
          var newsletter_subscribed =
            userDetails.newsletter_subscribed || false;
          var first_name = userDetails.first_name || "";
          var last_name = userDetails.last_name || "";
          var profile_image = userDetails.profile_image || "";
          var country = userDetails.country || "";
          var state = userDetails.state || "";
          var gender = userDetails.gender || "";
          var birth_date = userDetails.birth_date || "";
          var mobile = userDetails.mobile || "";
          var email = userDetails.email || "";
          var institute_name = userDetails.institute_name || "";
          this.setState({
            isSubcribeNewsletter: newsletter_subscribed,
            profileImage: profile_image,
            firstName: first_name,
            editFirstName: "",
            firstNameError: "",
            lastName: last_name,
            editLastName: "",
            lastNameError: "",
            stateError: "",
            fixdata: "Indian Institute of Knowledge",
            subscription: my_subscription,
            countryCode: country,
            stateCode: state,
            editCountryCode: country,
            editStateCode: state,
            gender: gender,
            dateOfBirth: birth_date,
            mobileNumber: mobile,
            email: email,
            institute: institute_name
          });

        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          isLoading: false,
        });
      });
  }


  // getuserCountry = async () => {

  //   try {
  //     let getcountrycode = this.state.countryCode;
  //     await 
  //   }
  //   catch (e) {

  //   }
  // }



  editProfile() {
    if (this.state.isNewUser) {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      axios
        .get(api_base_url + "/api/v1/my-profile/", {
          headers: headers
        })
        .then((response) => {
          // console.log("mobile",response.data.my_profile.mobile)
          this.setState({
            editMobileNumber: response.data.my_profile.mobile
          })
        })
    }
    this.setState({
      editFirstName: this.state.firstName,
      editLastName: this.state.lastName,
      editCountryCode: this.state.countryCode,
      editStateCode: this.state.stateCode,
      editGender: this.state.gender,
      editMobileNumber: this.state.mobileNumber,
      editMode: true,
      changePasswordMode: false,
    });
    if (this.state.dateOfBirth == "") {
      this.setState({
        dobDate: null,
      });
    } else {
      var newStringArray = this.state.dateOfBirth.split("-");
      var newYear = parseInt(newStringArray[0]);
      var newMonth = parseInt(newStringArray[1]) - 1;
      var newDate = parseInt(newStringArray[2]);
      var newDateObject = new Date(newYear, newMonth, newDate);
      this.setState({
        dobDate: newDateObject,
      });
    }
  }
  saveProfileDetails() {

    this.setState({
      firstNameError: "",
      lastNameError: "",
      stateError: "",
      mobileNumberError: "",
      emailError: ""
    });
    var isValid = true;
    if (this.state.editFirstName == "") {
      this.setState({
        firstNameError: strings.ValidFirstName,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.editFirstName)) {
      this.setState({
        firstNameError: strings.AcceptAlphabet,
      });
      isValid = false;
    }
    if (this.state.editLastName == "") {
      this.setState({
        lastNameError: strings.ValidLastName,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.editLastName)) {
      this.setState({
        lastNameError: strings.lastNameAlphabet,
      });
      isValid = false;
    }
    if (
      this.state.editCountryCode === "IN" &&
      this.state.editStateCode === ""
    ) {
      this.setState({
        stateError: strings.stateError,
      });
      isValid = false;
    }
  if (this.state.editMobileNumber == "") {
    this.setState({
      mobileNumberError: strings.ValidNumber,
    });
    isValid = false;
  } else if (!mobileRegularExpression.test(this.state.editMobileNumber)) {
    this.setState({
      mobileNumberError: strings.ValidNumber,
    });
    isValid = false;
  }
  if (this.state.editEmail == "") {
    this.setState({
      emailError: strings.enterEmail,
    });
    isValid = false;
  }
    // if (
    //   this.state.editMobileNumber != "" &&
    //   !mobileRegularExpression.test(this.state.editMobileNumber)
    // ) {
    //   this.setState({
    //     mobileNumberError: strings.ValidNumber,
    //   });
    //   isValid = false;
    // }
    if (isValid) {
      this.setState({
        isLoading: true,
      });
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      var newStateCode = "";
      if (this.state.editCountryCode === "IN") {
        newStateCode = this.state.editStateCode;
      }
      var apiParams = {
        first_name: this.state.editFirstName,
        last_name: this.state.editLastName,
        country: this.state.editCountryCode,
        state: newStateCode,
        mobile: null,
        email: this.state.email
      };
      if (this.state.editGender != "") {
        apiParams["gender"] = this.state.editGender;
      }
      if (this.state.dobDate != null) {
        var newMonth = this.state.dobDate.getMonth() + 1;
        if (newMonth < 10) {
          newMonth = "0" + newMonth;
        }
        var newDate = this.state.dobDate.getDate();
        if (newDate < 10) {
          newDate = "0" + newDate;
        }
        var newDateString =
          this.state.dobDate.getFullYear() + "-" + newMonth + "-" + newDate;
        apiParams["birth_date"] = newDateString;
      }
      if (this.state.editMobileNumber != "") {
        apiParams["mobile"] = this.state.editMobileNumber;
      }
      axios
        .post(api_base_url + "/api/v2/my-profile/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          axios
            .get(api_base_url + "/api/otp/complete-user-profile-status/", {
              headers: headers,
            }).then((response) => {
              this.setState({isEditEmail: response.is_profile_completed})
              if( this.props.location.state !== undefined && this.props.location.state.redirectionFromCart){
              this.props.history.replace({
                pathname: '/',
                state: { redirectionFromCart: "" }
              });
            }
            })
          if (response.data.status) {
            // console.log(response.data.status)
            var newFirstName = response.data.data.first_name || "";
            var newLastName = response.data.data.last_name || "";
            var newCountry = response.data.data.country || "";
            var newState = response.data.data.state || "";
            var newGender = response.data.data.gender || "";
            var newBirthDate = response.data.data.birth_date || "";
            var newMobileNumber = response.data.data.mobile || "";
            var newState = response.data.data.state || "";
            var newinstitute = response.data.data.institute_name || "";
            var newEmail = response.data.data.email || "";
            this.setState({
              firstName: newFirstName,
              lastName: newLastName,
              countryCode: newCountry,
              stateCode: newState,
              gender: newGender,
              dateOfBirth: newBirthDate,
              mobileNumber: newMobileNumber,
              institute: newinstitute,
              email: newEmail,
              editMode: false,
            });
            localStorage.setItem("currentcountrycode", response.data.data.country)
            localStorage.setItem("currState", this.state.stateCode)
            this.props.refreshData(
              response.data.data.email,
              this.state.originalSubscribeStatus,
              newFirstName,
              newLastName,
              response.data.data.profile_image,
              newCountry,
              newState,
              newMobileNumber,
              newinstitute,
              newEmail,
            );
          }
          this.setState({
            isLoading: false,
          });
        })

        .catch((err) => {
          // console.log("error ", err);
          this.setState({
            emailError: strings.enterEmail,
          });
          this.setState({
            isLoading: false,
          });
        });
    }
  }
  changeUserPassword() {
    this.setState({
      changePasswordMode: true,
      editMode: false,
      confirmPassword: "",
      newPassword: "",
      oldPassword: "",
      confirmPasswordError: "",
      newPasswordError: "",
      oldPasswordError: "",
    });
  }
  saveUserPassword() {
    this.setState({
      confirmPasswordError: "",
      newPasswordError: "",
      oldPasswordError: "",
      invalidPassword: "",
    });
    var isValid = true;
    if (this.state.oldPassword == "") {
      this.setState({
        oldPasswordError: strings.oldPassword,
      });
      isValid = false;
    } else if (this.state.oldPassword.length < 5) {
      this.setState({
        oldPasswordError: strings.PasswordLess,
      });
      isValid = false;
    } else if (this.state.oldPassword.length > 15) {
      this.setState({
        oldPasswordError: strings.PasswordMore,
      });
      isValid = false;
    }
    if (this.state.newPassword == "") {
      this.setState({
        newPasswordError: strings.newPassword,
      });
      isValid = false;
    } else if (this.state.newPassword.length < 5) {
      this.setState({
        newPasswordError: strings.PasswordLess,
      });
      isValid = false;
    } else if (this.state.newPassword.length > 15) {
      this.setState({
        newPasswordError: strings.PasswordMore,
      });
      isValid = false;
    }
    if (this.state.confirmPassword == "") {
      this.setState({
        confirmPasswordError: strings.newPassword,
      });
      isValid = false;
    } else if (this.state.confirmPassword.length < 5) {
      this.setState({
        confirmPasswordError: strings.PasswordLess,
      });
      isValid = false;
    } else if (this.state.confirmPassword.length > 15) {
      this.setState({
        confirmPasswordError: strings.PasswordMore,
      });
      isValid = false;
    } else if (this.state.confirmPassword !== this.state.newPassword) {
      this.setState({
        confirmPasswordError: strings.PasswordMatch,
      });
      isValid = false;
    }
    if (isValid) {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      };
      var apiParams = {
        old_password: this.state.oldPassword,
        password: this.state.newPassword,
        confirm_password: this.state.confirmPassword,
      };
      axios
        .post(api_base_url + "/api/change-password/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status === false) {
            this.setState({
              invalidPassword: response.data.data[0],
            });
          }
          if (response.data.status === true) {
            this.setState({
              changePasswordMode: false,
            });
            swal("", "Your password has been changed successfully", "success");
          }
        })
        .catch((err) => {
          // console.log("error ", err);
        });
    }
  }
  clickOnOk() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.is_new_user) {
        this.props.history.push({
          pathname: '/',
          state: { is_new_user: "", redirectionFromCart: "" }
        });
      }
    }
    this.props.closeModal();
  }
  changeSubscribeNewsletter(isChecked) {
    this.setState({
      isSubcribeNewsletter: isChecked,
      isLoading: true,
    });
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      newsletter_subscribed: isChecked,
    };
    axios
      .post(api_base_url + "/api/v1/my-profile/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          isLoading: false,
        });
      });
  }
  numberSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  subscriptionDateString(dateString) {
    var dateStringArray = dateString.split("-");
    var newYear = dateStringArray[0];
    var newMonth = parseInt(dateStringArray[1]) + "";
    var newDay = parseInt(dateStringArray[2]);
    var newString =
      this.numberSuffix(newDay) +
      " " +
      this.state.monthShortName[newMonth] +
      ", " +
      newYear;
    return newString;
  }
  birthDateString(dateString) {
    var dateStringArray = dateString.split("-");
    var newYear = dateStringArray[0];
    var newMonth = parseInt(dateStringArray[1]) + "";
    var newDay = parseInt(dateStringArray[2]);
    var newString =
      newDay + " " + this.state.monthShortName[newMonth] + " " + newYear;
    return newString;
  }
  uploadProfile(selectedFile) {
    if (selectedFile) {
      var extension = /[.]/.exec(selectedFile.name)
        ? /[^.]+$/.exec(selectedFile.name)
        : undefined;
      if (allImageExtensions.indexOf(extension + "") > -1) {
        this.setState({
          isLoading: true,
        });
        var headers = {
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        };
        const formData: FormData = new FormData();
        formData.append("profile_image", selectedFile);
        axios
          .post(api_base_url + "/api/my-profilepic/", formData, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status) {
              var newImageUrl = response.data.data.profile_image;
              if (newImageUrl) {
                this.setState({
                  profileImage: newImageUrl,
                });
                this.props.refreshData(
                  this.state.originalEmail,
                  this.state.originalSubscribeStatus,
                  this.state.firstName,
                  this.state.lastName,
                  newImageUrl,
                  this.state.countryCode,
                  this.state.stateCode,
                  this.state.mobileNumber,
                  this.state.institute,
                );
              }

            }
            this.setState({
              isLoading: false,
            });
          })
          .catch((err) => {
            // console.log("error ", err);
            this.setState({
              isLoading: false,
            });
          });
      } else {
        swal("", "Please select image file only", "error");
      }
    }
  }



  getUserSubscriptionDetails(token) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
    axios
      .get(api_base_url + "/api/v1/order-new/multiple-subscription/", {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status) {
          var my_subscriptionData = response.data.data || [];
          this.setState({
            subscriptionlist: my_subscriptionData,
          });
          if (my_subscriptionData.length == 0) {
            this.getSubscribePackages();
          }
        }

        this.getMyBasketDetails(token);
        this.getMyLibraryDetails(token, true);
        this.getMySnovelDetails(this.state.token);
        this.getNotificationList(token);
      })
      .catch((err) => {
        // console.log("error ", err);

      });
  }



  render() {
    var isSubscriptionActive = false
    return (
      <div>
        {this.state.isLoading ? (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        ) : null}
        <div class="myprofilePop">
          {!this.state.changePasswordMode && (
            <div class="myprofilePopLft">
              {this.state.profileImage == "" && (
                <img src="images/user_circle.png" alt="" />
              )}
              {this.state.profileImage != "" && (
                <img src={this.state.profileImage} alt="" />
              )}
              {this.state.editMode && (
                <span class="subsCribeButton newSubscriptButton uploadButton">
                  <input
                    type="file"
                    onChange={(event) => {
                      this.uploadProfile(event.target.files[0]);
                    }}
                  />
                  <a>{strings.uploadImage}</a>
                </span>
              )}
            </div>
          )}
          <div class="myprofilePopRgt">
            {!this.state.changePasswordMode && (
              <div class="myprofilePopRgtTop">
                <h4 class="modal-title">{strings.MyProfile}</h4>
                {!this.state.editMode && (
                  <div>
                    {(this.state.firstName != "" ||
                      this.state.lastName != "") && (
                        <span class="myprofilePopTitle">
                          {this.state.firstName + " " + this.state.lastName}
                        </span>
                      )}
                    {this.state.firstName == "" &&
                      this.state.lastName == "" && (
                        <span class="myprofilePopTitle">
                          {strings.UnknownName}
                        </span>
                      )}
                    {this.state.subscriptionlist.length > 0 && this.state.subscriptionlist.map((subscription, index) => {
                      if (index === 0 || index === 1 && subscription.is_current === true) {
                        isSubscriptionActive = true;
                        return (
                          <div className="planNameDiv">
                            {/* {console.log("subscription", this.state.subscriptionlist)} */}
                            <p>{strings.Planname}:</p>
                            <h1>
                              {isSubscriptionActive ?
                                subscription.subscription_type :
                                (
                                  <span class="subscription-expired">
                                    {" "}
                                    ({strings.subscriptionExpired})
                                  </span>
                                )}
                            </h1>
                            <p>
                              {strings.startDate}:{" "}
                              <span>
                                {this.subscriptionDateString(
                                  subscription.start_date
                                )}
                              </span>{" "}
                              | {strings.endDate}:{" "}
                              <span>
                                {this.subscriptionDateString(
                                  subscription.end_date
                                )}
                              </span>
                            </p>
                          </div>
                        )
                      }
                    })}
                    {this.state.subscription.length == 0 && (
                      <div class="planNameDiv">
                        <p>{strings.Subscriptionnotactivated}.</p>
                      </div>
                    )}
                    <div class="myprofilePopLocation">
                      <div class="myProfilePopLocDiv">
                        <span class="spanCon">{strings.country}</span>
                        <span class="spanInd">
                          :{" "}
                          {this.state.countryNameList[this.state.countryCode]
                            ? this.state.countryNameList[this.state.countryCode]
                            : "-"}
                        </span>
                      </div>
                      {this.state.countryCode === "IN" && (
                        <div class="myProfilePopLocDiv">
                          <span class="spanCon">{strings.state}</span>
                          <span class="spanInd">
                            :{" "}
                            {this.state.stateNameList[this.state.stateCode]
                              ? this.state.stateNameList[this.state.stateCode]
                              : "-"}
                          </span>
                        </div>
                      )}
                      <div class="myProfilePopLocDiv">
                        <span class="spanCon">{strings.Gender}</span>
                        <span class="spanInd">
                          :{" "}
                          {this.state.genderList[this.state.gender]
                            ? this.state.genderList[this.state.gender]
                            : "-"}
                        </span>
                      </div>
                      <div class="myProfilePopLocDiv">
                        <span class="spanCon">{strings.dateOfBirth}</span>
                        <span class="spanInd">
                          :{" "}
                          {this.state.dateOfBirth
                            ? this.birthDateString(this.state.dateOfBirth)
                            : "-"}
                        </span>
                      </div>
                      <div class="myProfilePopLocDiv">
                        <span class="spanCon">{strings.institute}</span>
                        <span class="spanInd">
                          :
                        </span>
                        <span style={{ marginLeft: "1vh" }}>{this.state.institute}</span>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.editMode && (
                  <div class="field-section-outer">
                    <Row>
                      <Col sm={6}>
                        <label>* {strings.firstName}</label>
                        <Input
                          className="profile-input-field"
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder="First Name"
                          maxLength={50}
                          value={this.state.editFirstName}
                          onChange={(event) => {
                            this.setState({
                              editFirstName: event.target.value,
                            });
                          }}
                        />
                        <div class="profile-error-message">
                          {this.state.firstNameError}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <label>* {strings.lastName}</label>
                        <Input
                          className="profile-input-field"
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder="Last Name"
                          maxLength={50}
                          value={this.state.editLastName}
                          onChange={(event) => {
                            this.setState({ editLastName: event.target.value });
                          }}
                        />
                        <div class="profile-error-message">
                          {this.state.lastNameError}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <label>* {strings.country}</label>
                        <Input
                          type="select"
                          name="countryCode"
                          value={this.state.editCountryCode}
                          id="countryCode"
                          onChange={(event) => {
                            this.setState({
                              editCountryCode: event.target.value,
                            });
                          }}
                        >
                          {countryList.map((eachCountry, index) => {
                            {/* { alert(JSON.stringify(eachCountry)) } */ }
                            return (
                              <option
                                value={eachCountry.code}
                                key={eachCountry.code}
                              >
                                {eachCountry.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col sm={6}>
                        <label className="gender-custom-label">
                         * {strings.Gender}
                        </label>
                        <FormGroup check>
                          <Label check className="custom-male-radio">
                            <Input
                              type="radio"
                              name="gender"
                              checked={this.state.editGender === "1"}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  this.setState({ editGender: "1" });
                                }
                              }}
                            />
                            {strings.Male}
                          </Label>
                          <Label check className="custom-female-radio">
                            <Input
                              type="radio"
                              name="gender"
                              checked={this.state.editGender === "2"}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  this.setState({ editGender: "2" });
                                }
                              }}
                            />
                            {strings.Female}
                          </Label>
                          <Label check className="custom-other-radio">
                            <Input
                              type="radio"
                              name="gender"
                              checked={this.state.editGender === "3"}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  this.setState({ editGender: "3" });
                                }
                              }}
                            />
                            {strings.Other}
                          </Label>
                        </FormGroup>
                        {/*<label>Gender</label>
                                            <div><Input type="radio" name="gender" />{' '}Male</div>*/}
                      </Col>
                    </Row>
                    <Row>
                      {this.state.editCountryCode === "IN" && (
                        <Col sm={6}>
                          <label>* {strings.state}</label>
                          <Input
                            className="profile-input-field"
                            type="select"
                            name="stateCode"
                            value={this.state.editStateCode}
                            id="countryCode"
                            onChange={(event) => {
                              this.setState({
                                editStateCode: event.target.value,
                              });
                            }}
                          >
                            <option value="">{strings.selectState}</option>
                            {stateList.map((eachState, index) => {
                              return (
                                <option
                                  value={eachState.code}
                                  key={eachState.code}
                                >
                                  {eachState.name}
                                </option>
                              );
                            })}
                          </Input>
                          <div class="profile-error-message">
                            {this.state.stateError}
                          </div>
                        </Col>
                      )}
                      <Col sm={6}>
                        <label>* {strings.dateOfBirth}</label>
                        <div>
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.dobDate}
                            onChange={this.dobDateChange}
                            maxDate={this.state.currentDate}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.editCountryCode === "IN" && (
                        <Col sm={6}>
                          <label>{strings.institute}</label>
                          <Input
                            className="profile-input-field"
                            type="text"
                            value={this.state.institute}
                            onChange={(event) => {
                              this.setState({
                                editStateCode: event.target.value,
                              });
                            }}
                            disabled
                          >
                          </Input>
                        </Col>
                      )}
                    </Row>
                  </div>
                )}
              </div>

            )}
            <div class="myprofilePopLocation">
              {!this.state.editMode && !this.state.changePasswordMode && (
                <div class="myProfilePopLocDiv">
                  <span class="spanConBtm">
                    <img src="images/icon_mobile.png" alt="" />
                    {strings.userMobile}
                  </span>
                  <span class="spanIndBtm">
                    :{" "}
                    {this.state.mobileNumber != ""
                      ? this.state.dialCodeList[this.state.countryCode] +
                      " " +
                      this.state.mobileNumber
                      : "-"}
                  </span>
                </div>
              )}
              {!this.state.editMode && !this.state.changePasswordMode && (
                <div class="myProfilePopLocDiv">
                  <span class="spanConBtm">
                    <img src="images/icon_mail.png" alt="" />
                    {strings.editEmail}
                  </span>
                  <span class="spanIndBtm">
                    :{" "}
                    <a href={"mailto:" + this.state.email}>
                      {this.state.email}
                    </a>
                  </span>
                </div>
              )}
              {this.state.editMode && (
                <div class="myProfilePopLocDiv">
                  <Row>
                    <Col sm={6}>
                      <label>* {strings.userMobile}</label>
                      <Input
                        className="profile-input-field"
                        type="number"
                        name="phone"
                        id="phone"
                        placeholder="Phone"
                        maxLength={10}
                        value={this.state.editMobileNumber}
                        onChange={(event) => {
                          this.setState({
                            editMobileNumber: event.target.value,
                          });
                        }}
                      />
                      <div class="profile-error-message">
                        {this.state.mobileNumberError}
                      </div>
                    </Col>
                    <Col sm={6}>
                      <label>* {strings.editEmail}</label>
                      {/* {console.log("this",this.state.isEditEmail)} */}
                      { this.state.isLoginWithMobileNumber == true|| this.state.isEditEmail !== true ?
                        <Input
                          className="profile-input-field"
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={(event) => {
                            this.setState({
                              email: event.target.value,
                            });
                          }}
                        />
                        :
                        <Input
                          className="profile-input-field"
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Email"
                          value={this.state.email}
                          disabled={true}
                        />
                      }
                       <div class="profile-error-message">
                        {this.state.emailError}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {this.state.changePasswordMode && (
                <div class="myProfilePopLocDiv changePasswordWrap">
                  <Row>
                    <Col sm={12}>
                      <label>{strings.OldPasswordText}</label>
                      <Input
                        className="profile-input-field"
                        type="password"
                        name="oldpass"
                        id="oldpass"
                        placeholder={strings.OldPasswordText}
                        maxLength={15}
                        value={this.state.oldPassword}
                        onChange={(event) => {
                          this.setState({ oldPassword: event.target.value });
                        }}
                      />
                      <div class="profile-error-message">
                        {this.state.oldPasswordError}
                      </div>
                      <div class="profile-error-message">
                        {this.state.invalidPassword}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <label>{strings.NewPasswordText}</label>
                      <Input
                        className="profile-input-field"
                        type="password"
                        name="newpass"
                        id="newpass"
                        placeholder={strings.NewPasswordText}
                        maxLength={15}
                        value={this.state.newPassword}
                        onChange={(event) => {
                          this.setState({ newPassword: event.target.value });
                        }}
                      />
                      <div class="profile-error-message">
                        {this.state.newPasswordError}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <label>{strings.NewConfirmPassword}</label>
                      <Input
                        className="profile-input-field"
                        type="password"
                        name="newpass"
                        id="newpass"
                        placeholder={strings.NewConfirmPassword}
                        maxLength={15}
                        value={this.state.confirmPassword}
                        onChange={(event) => {
                          this.setState({
                            confirmPassword: event.target.value,
                          });
                        }}
                      />
                      <div class="profile-error-message">
                        {this.state.confirmPasswordError}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {!this.state.changePasswordMode && (
                <div class="myProfilePopLocDiv">
                  <div class="billCheckBx">
                    <label class="checkbox">
                      <span class="subsCribeNews">
                        {strings.SubscribeNewsletter}
                      </span>
                      <input
                        type="checkbox"
                        name="newsletterCheckbox"
                        id="newsletterCheckbox"
                        checked={this.state.isSubcribeNewsletter}
                        onChange={(event) => {
                          this.changeSubscribeNewsletter(event.target.checked);
                        }}
                      />
                      <span class="checkmarkbx"></span>
                    </label>
                  </div>
                </div>
              )}
              <div class="myProfilePopLocDiv">
                <span class="subsCribeButton newSubscriptButton">
                  <a
                    onClick={() => {
                      this.clickOnOk();
                    }}
                  >
                    {strings.Ok}
                  </a>
                </span>
                {!this.state.editMode && (
                  <span
                    class="purchaseButton"
                    onClick={() => {
                      this.editProfile();
                    }}
                  >
                    <a>
                      <img src="images/icon_edit.png" alt="" />
                      {strings.EditProfile}
                    </a>
                  </span>
                )}
                {this.state.editMode && (
                  <span
                    class="purchaseButton"
                    onClick={() => {
                      this.saveProfileDetails();
                    }}
                  >
                    <a>
                      <img src="images/icon_save.png" alt="" />
                      {strings.SaveProfile}
                    </a>
                  </span>
                )}
                {!this.state.changePasswordMode && (
                  <span
                    class="purchaseButton changePasswordBtn"
                    onClick={() => {
                      this.changeUserPassword();
                    }}
                  >
                    <a>
                      <i class="fa fa-key" aria-hidden="true"></i>{" "}
                      {strings.ChangePassword}
                    </a>
                  </span>
                )}
                {this.state.changePasswordMode && (
                  <span
                    class="purchaseButton changePasswordBtn"
                    onClick={() => {
                      this.saveUserPassword();
                    }}
                  >
                    <a>
                      <img src="images/icon_save.png" alt="" />
                      {strings.SavePassword}
                    </a>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MyProfileModal);
