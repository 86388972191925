const initialState = {
    allOrderData:[],
    allOrderCount:0
}

export default function userSpecificOrderData (state = initialState, action) {
    switch (action.type) {
        case 'MY_ORDERS_DATA': {
            return { ...state, allOrderData: action.payload.data, allOrderCount: action.payload.count};
        }
        case 'FAILED_MY_ORDERS_DATA': {
            return { ...state };
        }
        default: {
            return { ...state };
        }
    }
}