import SimpleCrypto from "simple-crypto-js";
import { encryptDecryptKey } from './config';
export const loadState = () => {
    try {
        var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
        var newUserDetails = localStorage.getItem('userDetails');
        if (newUserDetails) {
            newUserDetails = simpleCrypto.decrypt(newUserDetails);
        }
        const serializedState = newUserDetails;

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);

    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
        const serializedState = simpleCrypto.encrypt(state);
        localStorage.setItem('userDetails', serializedState);
        if (state.userCountry) {
            localStorage.setItem('currentcountrycode', state.userCountry);
        }
    } catch (err) {
        // die
        // console.log(err);
    }
};