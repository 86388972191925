import React, { Component, Fragment } from "react";
import classnames from "classnames";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Popover,
  PopoverBody,
} from "reactstrap";
import swal from "sweetalert";
import { connect } from "react-redux";
import { Route, Link, withRouter } from "react-router-dom";
import {
  api_base_url,
  dashboardToken,
  encryptDecryptKey,
  guestProductToken,
  getHoursFormat,
} from "../config";
import axios from "axios";
import Carousel from "react-multi-carousel";
import OwlCarousel from "react-owl-carousel2";
import SimpleCrypto from "simple-crypto-js";
import HomeSlider from "../homeSlider.json";
import { CustomLeftArrow, CustomRightArrow } from "./CustomArrows";
import { browseCategoryProductDetailsDataAction } from "../action/browseCategoryAction";
import AudioPlayer from "react-modular-audio-player";
import StarRatings from "react-star-ratings";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  PinterestIcon,
} from "react-share";

import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
import $ from "jquery";
var strings = new LocalizedStrings(languages);

let rearrangedPlayer = [
  {
    className: "tier-top",
    innerComponents: [
      {
        type: "rewind",
        style: { width: "fit-content" },
      },
      {
        type: "play",
        style: { width: "fit-content" },
      },
      {
        type: "forward",
        style: { width: "fit-content" },
      },
      {
        type: "seek",
      },
      {
        type: "volume",
      },
    ],
  },
];
const options = {
  nav: true,
  autoplay: false,
  margin: 15,
  navText: ["<div></div>", "<div></div>"],
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  },
};

class popularTitleAuthorComponant extends Component {
  constructor(props) {
    super(props);
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    var userEmail = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
        userEmail = userDetailsObject.userEmail || "";
      }
    }
    this.state = {
      activeTab: "1",
      currentSlide: "",
      popularPicksData: [],
      popularAuthorData: [],
      evergreenSelectedData: [],
      selectedPopularIndex: 0,
      selectedPopularAuthorIndex: 0,
      selectedPopularEverGreenIndex: 0,
      popularTitleSelectedAudio: [],
      classicSelectedAudio: [],
      popularAuthorSelectedAudio: [],
      selectedLanguage: selectedLanguage || "EN",
      audioObject: undefined,
      audioFirstObject: undefined,
      audioSecondObject: undefined,
      audioThirdObject: undefined,
      popularTitleAudio: {},
      popularAuthorAudio: {},
      classicAudio: {},
      popularTitleLastPlayed: "",
      popularAuthorLastPlayed: "",
      classicLastPlayed: "",
      popularTitleApiData: [],
      evergreenApiData: [],
      popularAuthorApiData: [],
      popoverOpenShare: false,
      token: token || "",
      cartFailureMessage: "",
      popularProductToken: token || dashboardToken,
      isIndian: null,
    };
    this.tabToggle = this.tabToggle.bind(this);
    this.popularEvergreenAuthor();
    this.popOverToggle = this.popOverToggle.bind(this);
    this.cartProductsModal = this.cartProductsModal.bind(this);
    this.getSharingContent = this.getSharingContent.bind(this);
  }

  tabToggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  popOverToggle() {
    this.setState({
      popoverOpenShare: !this.state.popoverOpenShare,
    });
  }

  addToLibraryProduct(productID) {
    var apiParams = {
      product: productID,
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/add-to-library/", apiParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          this.props.history.push("/library");
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }

  cartProductsModal(variantId) {
    this.setState({
      cartFailureMessage: "",
    });
    var apiParams = {
      email: "",
      discount_amount: 0.0,
      discount_amount_inr: 0.0,
      gift_cart: false,
      cart_lines: [
        {
          variant: variantId,
          quantity: 1,
          data: {},
        },
      ],
    };

    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .post(api_base_url + "/api/cart/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status === true) {
          this.props.dispatch({
            type: "PRODUCT_CART_COUNT",
            payload: response.data.data.quantity,
          });
          this.props.dispatch({ type: "MODEL_SELECTED", payload: true });
        }
        if (response.data.status === false) {
          this.setState({
            cartFailureMessage: "Product already in cart",
          });
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  componentWillUnmount() {
    if (this.state.audioObject) {
      this.state.audioObject.pause();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isIndian !== null && nextProps.isIndian !== undefined) {
      this.setState({ isIndian: nextProps.isIndian });
    }
    if (nextProps.isPausePopularTitle === true && this.state.audioObject) {
      this.state.audioObject.pause();
      this.setState({
        popularAuthorLastPlayed: "",
        classicLastPlayed: "",
        popularTitleLastPlayed: "",
      });
      this.props.onPauseSample("popularTitle");
    }
    if (nextProps.isPausePopularTitle === true && this.state.audioFirstObject) {
      this.state.audioFirstObject.pause();
      this.props.onPauseSample("popularTitle");
    }
    if (
      nextProps.isPausePopularTitle === true &&
      this.state.audioSecondObject
    ) {
      this.state.audioSecondObject.pause();
      this.props.onPauseSample("popularTitle");
    }
    if (nextProps.isPausePopularTitle === true && this.state.audioThirdObject) {
      this.state.audioThirdObject.pause();
      this.props.onPauseSample("popularTitle");
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  // productDetailsId(productID, title) {
  //   var titleUrl = title.toLowerCase();
  //   var productTitle = titleUrl.split(" ").join("-");
  //   localStorage.setItem("productId", productID);
  //   this.props.history.push(
  //     "/product-details/" + productID + "/" + productTitle
  //   );
  // }
  productDetailsId(productID, slug) {
    localStorage.setItem("productId", productID);
    this.props.history.push("/product-details/" + productID + "/" + slug);
  }
  authorDetailsId(authorID, authorName) {
    var titleUrl = authorName.toLowerCase();
    var authorTitle = titleUrl.replace(".", "");
    var authorName = authorTitle.split(" ").join("-");
    this.props.history.push("/author/" + authorID + "/" + authorName);
  }
  updateSletedIndex(slideIndex) {
    this.setState({
      selectedPopularIndex: slideIndex,
    });
    this.getPopularTitleAudio(this.state.popularPicksData[slideIndex]);
  }
  /*****   Author Specific details link ***************/
  authorLinkList(authorList) {
    return authorList.map((authorName, index) => {
      if (
        authorName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        return (
          <NavLink
            tag={Link}
            className="pl-0"
            onClick={() => {
              this.authorDetailsId(authorName.id, authorName.name);
            }}
            key={index}
          >
            <span key={index}>
              {authorName.custom_translation[this.state.selectedLanguage].name}
            </span>
          </NavLink>
        );
      }
    });
  }
  /****************************************
   *   Popular title slider and tab content  *
   * ***************************************/

  getPopularTitleAudio(productDetails) {
    if (productDetails.sampletracks.length > 0) {
      var apiParams = {
        map_url: productDetails.sampletracks[0].map_url || "",
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            this.setState({
              popularTitleSelectedAudio: [{ src: url }],
            });
            var newAudioObject = $("#firstPlayerAudio .audio-player audio");
            if (newAudioObject.length > 0) {
              var parentThis = this;
              this.setState({
                audioFirstObject: newAudioObject[0],
              });
              newAudioObject[0].addEventListener("play", function () {
                parentThis.props.onPlaySample("popularTitle");
                if (parentThis.state.audioObject) {
                  parentThis.state.audioObject.pause();
                  parentThis.setState({
                    popularAuthorLastPlayed: "",
                    classicLastPlayed: "",
                    popularTitleLastPlayed: "",
                  });
                }
              });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    } else {
      this.setState({
        popularTitleSelectedAudio: [],
      });
    }
  }
  popularUpdateSletedIndex(slideIndex) {
    this.setState({
      selectedPopularAuthorIndex: slideIndex,
    });
    this.getPopularAuthorAudio(this.state.popularAuthorData[slideIndex]);
  }
  popularTitlePauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      popularTitleLastPlayed: "",
    });
  }
  popularTitlePlayAudio(map_url) {
    if (this.state.popularTitleAudio[map_url]) {
      this.setState({
        popularTitleLastPlayed: map_url,
        popularAuthorLastPlayed: "",
        classicLastPlayed: "",
      });
      this.props.onPlaySample("popularTitle");
      if (this.state.audioFirstObject) {
        this.state.audioFirstObject.pause();
      }
      if (this.state.audioSecondObject) {
        this.state.audioSecondObject.pause();
      }
      if (this.state.audioThirdObject) {
        this.state.audioThirdObject.pause();
      }
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                popularTitleLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newPopularTitleAudio = {};
            newPopularTitleAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              popularTitleAudio: newPopularTitleAudio,
              popularTitleLastPlayed: map_url,
              popularAuthorLastPlayed: "",
              classicLastPlayed: "",
            });
            this.props.onPlaySample("popularTitle");
            if (this.state.audioFirstObject) {
              this.state.audioFirstObject.pause();
            }
            if (this.state.audioSecondObject) {
              this.state.audioSecondObject.pause();
            }
            if (this.state.audioThirdObject) {
              this.state.audioThirdObject.pause();
            }
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }
  popularSliderContent() {
    return this.state.popularPicksData.map((sliderElement, slideIndex) => {
      return (
        <div>
          <div className="popular-slider-content">
            {sliderElement.images.length > 0 ? (
              <div className="sliderThumb">
                {sliderElement.is_free && (
                  <span className="freeProduct">{strings.FreeText}</span>
                )}
                <img
                  src={sliderElement.images[0]["image2"]}
                  className="sampleProduct_thumb"
                  onClick={() => {
                    // this.updateSletedIndex(slideIndex);
                    this.productDetailsId(
                      sliderElement.id,
                      sliderElement.product_slug
                    );
                  }}
                />
              </div>
            ) : (
              <div className="sliderThumb">
                {sliderElement.is_free && (
                  <span className="freeProduct">{strings.FreeText}</span>
                )}
                <img
                  src="/images/default.jpg"
                  className="sampleProduct_thumb"
                  onClick={() => {
                    // this.updateSletedIndex(slideIndex);
                    this.productDetailsId(
                      sliderElement.id,
                      sliderElement.product_slug
                    );
                  }}
                />
              </div>
            )}
            {/* {sliderElement.custom_translation[this.state.selectedLanguage] !==
              undefined && (
              <div
                className="sliderProductTitle whiteTitle"
                onClick={() => {
                  this.updateSletedIndex(slideIndex);
                }}
              >
                {
                  sliderElement.custom_translation[this.state.selectedLanguage]
                    .name
                }
              </div>
            )}
            {sliderElement.authors && (
              <div className="sliderProductAuthor">
                
                {this.authorLinkList(sliderElement.authors)}
              </div>
            )}
            <div className="sliderProductCategory">
              {sliderElement.category.map((categoryList, index) => {
                if (
                  categoryList.custom_translation[
                    this.state.selectedLanguage
                  ] != undefined
                ) {
                  return (
                    <NavLink
                      tag={Link}
                      to={{
                        pathname: "/browse",
                      }}
                      onClick={() => {
                        this.props.dispatch({
                          type: "MENU_SELECTED_CATEGORY",
                          payload: [
                            categoryList.name,
                            sliderElement.product_audio_type,
                            categoryList.id,
                          ],
                        });
                        this.props.dispatch(
                          browseCategoryProductDetailsDataAction(
                            sliderElement.product_audio_type,
                            categoryList.name,
                            1
                          )
                        );
                      }}
                      key={index}
                    >
                      <span key={index}>
                        {
                          categoryList.custom_translation[
                            this.state.selectedLanguage
                          ].name
                        }
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div> */}
            <div className="sliderTrack_Wrap">
              {sliderElement.sampletracks.length > 0 && (
                <div>
                  {sliderElement.sampletracks[0].map_url !=
                    this.state.popularTitleLastPlayed && (
                      <img
                        onClick={() => {
                          this.popularTitlePlayAudio(
                            sliderElement.sampletracks[0].map_url
                          );
                        }}
                        src="/images/play.png"
                      />
                    )}
                  {sliderElement.sampletracks[0].map_url ==
                    this.state.popularTitleLastPlayed && (
                      <img
                        onClick={() => {
                          this.popularTitlePauseAudio(
                            sliderElement.sampletracks[0].map_url
                          );
                        }}
                        src="/images/round-pause-button.svg"
                      />
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  }
  /****************************************
   *   Classic slider and tab content  *
   ****************************************/
  popularEvergreenAuthor() {
    var headers = {
      "Content-Type": "application/json",
      "index-api-token": this.state.popularProductToken,
    };
    this.props.dispatch({
      type: "SHOW_LOADER"
    });
    axios
      .get(api_base_url + "/api/v2/index-popular/10", { headers: headers })
      .then((response) => {
        if (response.data.status == true) {
          this.setState({
            popularPicksData: response.data.popular_picks || [],
            evergreenSelectedData: response.data.evergreen_picks || [],
            popularAuthorData: response.data.popular_author || [],
          });
          if (response.data.popular_picks.length > 0) {
            this.getPopularTitleAudio(response.data.popular_picks[0]);
          }
          if (response.data.evergreen_picks.length > 0) {
            this.getClassicAudio(response.data.evergreen_picks[0]);
          }
          if (response.data.popular_author.length > 0) {
            this.getPopularAuthorAudio(response.data.popular_author[0]);
          }
          this.props.dispatch({
            type: "HIDE_LOADER"
          });
        } else {
          // console.log("Not Loaded");
          this.props.dispatch({
            type: "HIDE_LOADER"
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
        this.props.dispatch({
          type: "HIDE_LOADER"
        });
      });
  }
  evergreenUpdateSletedIndex(slideIndex) {
    this.setState({
      selectedPopularEverGreenIndex: slideIndex,
    });
    this.getClassicAudio(this.state.evergreenSelectedData[slideIndex]);
  }
  classicPauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      classicLastPlayed: "",
    });
  }
  getClassicAudio(productDetails) {
    if (productDetails.sampletracks.length > 0) {
      var apiParams = {
        map_url: productDetails.sampletracks[0].map_url || "",
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            this.setState({
              classicSelectedAudio: [{ src: url }],
            });
            var newAudioObject = $("#secondPlayerAudio .audio-player audio");
            if (newAudioObject.length > 0) {
              var parentThis = this;
              this.setState({
                audioSecondObject: newAudioObject[0],
              });
              newAudioObject[0].addEventListener("play", function () {
                parentThis.props.onPlaySample("popularTitle");
                if (parentThis.state.audioObject) {
                  parentThis.state.audioObject.pause();
                  parentThis.setState({
                    popularAuthorLastPlayed: "",
                    classicLastPlayed: "",
                    popularTitleLastPlayed: "",
                  });
                }
              });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    } else {
      this.setState({
        classicSelectedAudio: [],
      });
    }
  }
  classicPlayAudio(map_url) {
    if (this.state.classicAudio[map_url]) {
      this.setState({
        classicLastPlayed: map_url,
        popularTitleLastPlayed: "",
        popularAuthorLastPlayed: "",
      });
      this.props.onPlaySample("popularTitle");
      if (this.state.audioFirstObject) {
        this.state.audioFirstObject.pause();
      }
      if (this.state.audioSecondObject) {
        this.state.audioSecondObject.pause();
      }
      if (this.state.audioThirdObject) {
        this.state.audioThirdObject.pause();
      }
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                classicLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newClassicAudio = {};
            newClassicAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              classicAudio: newClassicAudio,
              classicLastPlayed: map_url,
              popularTitleLastPlayed: "",
              popularAuthorLastPlayed: "",
            });
            this.props.onPlaySample("popularTitle");
            if (this.state.audioFirstObject) {
              this.state.audioFirstObject.pause();
            }
            if (this.state.audioSecondObject) {
              this.state.audioSecondObject.pause();
            }
            if (this.state.audioThirdObject) {
              this.state.audioThirdObject.pause();
            }
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }
  evergreenSliderContent() {
    return this.state.evergreenSelectedData.map(
      (evergreenElement, slideIndex) => {
        return (
          <div>
            <div className="popular-slider-content">
              {/* {slideIndex == this.state.selectedPopularEverGreenIndex && (
                <span className="selectedProductSlider">
                  <img src="/images/up-arrow.png" />
                </span>
              )} */}
              {evergreenElement.images.length > 0 ? (
                <div className="sliderThumb">
                  {evergreenElement.is_free && (
                    <span className="freeProduct">{strings.FreeText}</span>
                  )}
                  <img
                    src={evergreenElement.images[0]["image2"]}
                    className="sampleProduct_thumb"
                    onClick={() => {
                      // this.evergreenUpdateSletedIndex(slideIndex);
                      this.productDetailsId(
                        evergreenElement.id,
                        evergreenElement.product_slug
                      );
                    }}
                  />
                </div>
              ) : (
                <div className="sliderThumb">
                  {evergreenElement.is_free && (
                    <span className="freeProduct">{strings.FreeText}</span>
                  )}
                  <img
                    src="/images/default.jpg"
                    className="sampleProduct_thumb"
                    onClick={() => {
                      // this.evergreenUpdateSletedIndex(slideIndex);
                      this.productDetailsId(
                        evergreenElement.id,
                        evergreenElement.product_slug
                      );
                    }}
                  />
                </div>
              )}
              {/* {evergreenElement.custom_translation[
                this.state.selectedLanguage
              ] != undefined && (
                <div
                  className="sliderProductTitle whiteTitle"
                  onClick={() => {
                    this.evergreenUpdateSletedIndex(slideIndex);
                  }}
                >
                  {
                    evergreenElement.custom_translation[
                      this.state.selectedLanguage
                    ]["name"]
                  }
                </div>
              )}
              {evergreenElement.authors && (
                <div className="sliderProductAuthor">
                 
                  {this.authorLinkList(evergreenElement.authors)}
                </div>
              )}
              <div className="sliderProductCategory">
                {evergreenElement.category.map((categoryList, index) => {
                  if (
                    categoryList.custom_translation[
                      this.state.selectedLanguage
                    ] != undefined
                  ) {
                    return (
                      <NavLink
                        tag={Link}
                        to={{
                          pathname: "/browse",
                        }}
                        onClick={() => {
                          this.props.dispatch({
                            type: "MENU_SELECTED_CATEGORY",
                            payload: [
                              categoryList.name,
                              evergreenElement.product_audio_type,
                              categoryList.id,
                            ],
                          });
                          this.props.dispatch(
                            browseCategoryProductDetailsDataAction(
                              evergreenElement.product_audio_type,
                              categoryList.name,
                              1
                            )
                          );
                        }}
                        key={index}
                      >
                        <span key={index}>
                          {
                            categoryList.custom_translation[
                              this.state.selectedLanguage
                            ].name
                          }
                        </span>
                      </NavLink>
                    );
                  }
                })}
              </div> */}
              <div className="sliderTrack_Wrap">
                {evergreenElement.sampletracks.length > 0 && (
                  <div>
                    {evergreenElement.sampletracks[0].map_url !=
                      this.state.classicLastPlayed && (
                        <img
                          onClick={() => {
                            this.classicPlayAudio(
                              evergreenElement.sampletracks[0].map_url
                            );
                          }}
                          src="/images/play.png"
                        />
                      )}
                    {evergreenElement.sampletracks[0].map_url ==
                      this.state.classicLastPlayed && (
                        <img
                          onClick={() => {
                            this.classicPauseAudio(
                              evergreenElement.sampletracks[0].map_url
                            );
                          }}
                          src="/images/round-pause-button.svg"
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }
    );
  }

  /****************************************
   *   Popular Author slider and tab content  *
   ****************************************/
  getPopularAuthorAudio(productDetails) {
    if (productDetails.sampletracks.length > 0) {
      var apiParams = {
        map_url: productDetails.sampletracks[0].map_url || "",
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            this.setState({
              popularAuthorSelectedAudio: [{ src: url }],
            });
            var newAudioObject = $("#thirdPlayerAudio .audio-player audio");
            if (newAudioObject.length > 0) {
              var parentThis = this;
              this.setState({
                audioThirdObject: newAudioObject[0],
              });
              newAudioObject[0].addEventListener("play", function () {
                parentThis.props.onPlaySample("popularTitle");
                if (parentThis.state.audioObject) {
                  parentThis.state.audioObject.pause();
                  parentThis.setState({
                    popularAuthorLastPlayed: "",
                    classicLastPlayed: "",
                    popularTitleLastPlayed: "",
                  });
                }
              });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    } else {
      this.setState({
        popularAuthorSelectedAudio: [],
      });
    }
  }
  popularAuthorPauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      popularAuthorLastPlayed: "",
    });
  }
  popularAuthorPlayAudio(map_url) {
    if (this.state.popularAuthorAudio[map_url]) {
      this.setState({
        popularAuthorLastPlayed: map_url,
        popularTitleLastPlayed: "",
        classicLastPlayed: "",
      });
      this.props.onPlaySample("popularTitle");
      if (this.state.audioFirstObject) {
        this.state.audioFirstObject.pause();
      }
      if (this.state.audioSecondObject) {
        this.state.audioSecondObject.pause();
      }
      if (this.state.audioThirdObject) {
        this.state.audioThirdObject.pause();
      }
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                popularAuthorLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newPopularAuthorAudio = {};
            newPopularAuthorAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              popularAuthorAudio: newPopularAuthorAudio,
              popularAuthorLastPlayed: map_url,
              popularTitleLastPlayed: "",
              classicLastPlayed: "",
            });
            this.props.onPlaySample("popularTitle");
            if (this.state.audioFirstObject) {
              this.state.audioFirstObject.pause();
            }
            if (this.state.audioSecondObject) {
              this.state.audioSecondObject.pause();
            }
            if (this.state.audioThirdObject) {
              this.state.audioThirdObject.pause();
            }
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }
  popularAuthorSliderContent() {
    return this.state.popularAuthorData.map(
      (popularAuthorElement, slideIndex) => {
        var authorArray = [];
        popularAuthorElement.authors.map((authorName) => {
          if (
            authorName.custom_translation[this.state.selectedLanguage] !=
            undefined
          ) {
            authorArray.push(
              authorName.custom_translation[this.state.selectedLanguage].name
            );
          }
        });
        return (
          <div>
            <div className="popular-slider-content">
              {/* {slideIndex == this.state.selectedPopularAuthorIndex && (
                <span className="selectedProductSlider">
                  <img src="/images/up-arrow.png" />
                </span>
              )} */}
              {popularAuthorElement.images.length > 0 ? (
                <div className="sliderThumb">
                  {popularAuthorElement.is_free && (
                    <span className="freeProduct">{strings.FreeText}</span>
                  )}
                  <img
                    src={popularAuthorElement.images[0]["image2"]}
                    className="sampleProduct_thumb"
                    onClick={() => {
                      // this.popularUpdateSletedIndex(slideIndex);
                      this.productDetailsId(
                        popularAuthorElement.id,
                        popularAuthorElement.product_slug
                      );
                    }}
                  />
                </div>
              ) : (
                <div className="sliderThumb">
                  {popularAuthorElement.is_free && (
                    <span className="freeProduct">{strings.FreeText}</span>
                  )}
                  <img
                    src="/images/default.jpg"
                    className="sampleProduct_thumb"
                    onClick={() => {
                      // this.popularUpdateSletedIndex(slideIndex);
                      this.productDetailsId(
                        popularAuthorElement.id,
                        popularAuthorElement.product_slug
                      );
                    }}
                  />
                </div>
              )}
              <div className="sliderTrack_Wrap">
                {popularAuthorElement.sampletracks.length > 0 && (
                  <div>
                    {popularAuthorElement.sampletracks[0].map_url !=
                      this.state.popularAuthorLastPlayed && (
                        <img
                          onClick={() => {
                            this.popularAuthorPlayAudio(
                              popularAuthorElement.sampletracks[0].map_url
                            );
                          }}
                          src="/images/play.png"
                        />
                      )}
                    {popularAuthorElement.sampletracks[0].map_url ==
                      this.state.popularAuthorLastPlayed && (
                        <img
                          onClick={() => {
                            this.popularAuthorPauseAudio(
                              popularAuthorElement.sampletracks[0].map_url
                            );
                          }}
                          src="/images/round-pause-button.svg"
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }
    );
  }
  authorsSelected(authorsList) {
    var authorArray = [];
    authorsList.map((authorName) => {
      if (
        authorName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        authorArray.push(
          authorName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return authorArray.toString();
  }
  directorsSelected(directorList) {
    var directorArray = [];
    directorList.map((directorName) => {
      if (
        directorName.custom_translation[this.state.selectedLanguage] !=
        undefined
      ) {
        directorArray.push(
          directorName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return directorArray.toString();
  }
  artistsSelected(artistsList) {
    var artistsArray = [];
    artistsList.map((artistsName) => {
      if (
        artistsName.custom_translation[this.state.selectedLanguage] != undefined
      ) {
        artistsArray.push(
          artistsName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return artistsArray.join(" | ");
  }
  categorySelected(categoryList) {
    var categoryArray = [];
    categoryList.map((categoryName) => {
      if (
        categoryName.custom_translation[this.state.selectedLanguage] !=
        undefined
      ) {
        categoryArray.push(
          categoryName.custom_translation[this.state.selectedLanguage].name
        );
      }
    });
    return categoryArray.join("| ");
  }
  getDuration(duration) {
    var newDuration = "";
    if (duration == 0) {
      newDuration = duration;
    } else {
      var newMinutes = duration / 60;
      newDuration = parseFloat(newMinutes / 60).toFixed(2);
    }
    return newDuration + " " + strings.hrsText;
  }

  getSharingContent(productData) {
    var selectedLanguage = this.state.selectedLanguage;
    let productDetailsData = productData;
    var productDetail = productDetailsData.custom_translation
      ? productDetailsData.custom_translation
      : {};
    var productAuthorDetails = productDetailsData.authors || [];
    var productName = productDetailsData.name;
    var Authors = "";
    if (Object.keys(productDetail).length > 0) {
      if (selectedLanguage == "MR") {
        productName = productDetail.MR.name;
      } else {
        productName = productDetail.EN.name;
      }
    }
    var sharingContent =
      strings.socialSharingText +
      "\n" +
      productName +
      "\n" +
      strings.authorText +
      ": ";
    if (productDetailsData && productAuthorDetails) {
      productAuthorDetails.forEach((author, index) => {
        let autorDetails = author.custom_translation
          ? author.custom_translation
          : {};
        var authorName = author.name;
        if (Object.keys(autorDetails).length > 0) {
          if (selectedLanguage == "MR") {
            authorName = autorDetails.MR.name;
          } else {
            authorName = autorDetails.EN.name;
          }
        }
        Authors =
          Authors +
          authorName +
          (index < productAuthorDetails.length - 1 ? ", " : ".");
      });
    }
    var happyListening = "\n" + strings.happyListening + "\n";
    return sharingContent + Authors + happyListening;
  }

  getCategoryPage() {
    var post_type = 'family'
    var customTranslation = 'popular_titles'
    this.props.dispatch({
      type: "MENU_SELECTED_CATEGORY",
      payload: ['popular_titles', 'family', 1],
    });
    this.props.dispatch(
      browseCategoryProductDetailsDataAction(null, 'popular_titles', 1)
    );
    this.props.history.push("/family-category-details/" + 'popular_titles');
    localStorage.setItem('post_type', post_type);
    localStorage.setItem("categoryName", JSON.stringify(customTranslation));
  }
  render() {
    const front_base_url = window.location.origin;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6,
      },
      tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 4,
      },
      mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 2,
      },
    };
    return (
      <div  className="newfamilyInnerSlider_Wrapper">
      <div className="container"> 
          {/* <div className="innerSlider_Wrapper"> */}
          {this.state.popularPicksData.length > 0 && (

            // <div className="newSliderContentWrapper kidsCategorySliders">
            <div className="newsliderContentWrapper kidsCategorySliders">
                 <div className="kidsCategoryMain-wrapper">
                <h4 className={"float-left custom-category-title"}>
                  {strings.popularTitles}
                </h4>
                {this.state.popularPicksData.length > 5 ?
                  <button
                    className={"category-view-all-button"}
                    onClick={() => {
                      this.getCategoryPage();
                    }}
                  >
                    {strings.viewAll}
                  </button>

                  :
                  ""
                }
                <OwlCarousel ref="car" options={options}>
                  {this.popularSliderContent()}
                </OwlCarousel>
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    homeMainCategoryList: state.homeMainCategoryList.homePageAllData,
    languageTab: state.languageTab,
    authData: state.authonticationDetails,
    isIndian: state.isReisteredDevice.isIndian,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(popularTitleAuthorComponant));
