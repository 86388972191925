import React, { Component, Fragment } from "react";
import { Button, Form, Input, Row, Col } from "reactstrap";
import {
  api_base_url,
  guestProductToken,
  dashboardToken,
  encryptDecryptKey,
  subscriptionDefaultImage,
} from "../../config";
import axios from "axios";
import { connect } from "react-redux";
import { Route, Link, Redirect, withRouter } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import LocalizedStrings from "react-localization";
import languages from "../../language/language.json";
var strings = new LocalizedStrings(languages);

class cart extends Component {
  constructor(props) {
    super(props);
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    var userEmail = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
        userEmail = userDetailsObject.userEmail || "";
      }
    }
    this.state = {
      isLoading: false,
      token: token || "",
      cartDetailsProducts: {},
      totalPrice: "",
      selectedLanguage: selectedLanguage || "EN",
      isIndian: null,
      currcountry: ""
    };
  }

  componentDidMount() {
    this.cartProductsModal();
    this.getuserCountry()
  }


  getuserCountry = async () => {
    let getcountrycode;
    try {
      getcountrycode = await localStorage.getItem("currentcountrycode")
      this.setState({ currcountry: getcountrycode })
      // alert(this.state.currcountry)
    }
    catch (e) {

    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isIndian !== null || nextProps.isIndian !== undefined) {
      this.setState({ isIndian: nextProps.isIndian });
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }

  cartProductsModal() {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .get(api_base_url + "/api/v1/cart-details/", { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.length > 0) {
            this.setState({
              cartDetailsProducts: response.data.data[0] || undefined,
            });
            var totalAmount = 0;
            response.data.data[0].lines.map((subTotal, index) => {
              if (this.state.currcountry === "IN") {
                totalAmount =
                  totalAmount + parseFloat(subTotal.product.inr_price.amount);
              } else {
                totalAmount =
                  totalAmount + parseFloat(subTotal.product.price.amount);
              }
              if (index === response.data.data[0].lines.length - 1) {
                this.setState({
                  totalPrice: totalAmount,
                });
              }
            });
          }
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }
  removeProductCart(selectedIndex) {
    var variantId = this.state.cartDetailsProducts.lines[selectedIndex].variant;
    if (this.state.currcountry === "IN") {
      var productPrice = this.state.cartDetailsProducts.lines[selectedIndex]
        .product.inr_price.amount;
    } else {
      var productPrice = this.state.cartDetailsProducts.lines[selectedIndex]
        .product.price.amount;
    }

    var totalPrice = this.state.totalPrice;
    var apiParams = {
      email: "",
      discount_amount: 0.0,
      discount_amount_inr: 0.0,
      token: this.state.cartDetailsProducts.token,
      gift_cart: false,
      cart_lines: [
        {
          variant: variantId,
          quantity: 0,
          data: {},
        },
      ],
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    axios
      .put(api_base_url + "/api/cart/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var oldCartDetailsProducts = this.state.cartDetailsProducts;
          var oldLines = oldCartDetailsProducts.lines;
          oldLines.splice(selectedIndex, 1);
          oldCartDetailsProducts.lines = oldLines;
          var updatedPrice = totalPrice - parseFloat(productPrice);
          this.setState({
            cartDetailsProducts: oldCartDetailsProducts,
            totalPrice: updatedPrice,
          });
          this.props.dispatch({
            type: "PRODUCT_CART_COUNT",
            payload: response.data.data.quantity,
          });
        }
      })
      .catch((err) => {
        // console.log("fetched.", err);
      });
  }

  closeCartModal() {
    this.props.closeModal();
  }
  /***********  Product details page link *****/
  productDetailsId(productID, title) {
    var titleUrl = title.toLowerCase();
    var productTitle = titleUrl.split(" ").join("-");
    this.props.history.push(
      "/product-details/" + productID + "/" + productTitle
    );
    this.props.closeModal();
  }

  popCartModelData() {
    return this.state.cartDetailsProducts.lines.map((cartItem, index) => {
      return (
        <div>
          <div class="checkoutIndWrp">
            {
              <div class="checkoutIndImg">
                {cartItem.product.images.length > 0 ? (
                  <img src={cartItem.product.images[0]["image2"]} alt="" />
                ) : cartItem.product.subscription_product ? (
                  <img src={subscriptionDefaultImage} alt="" />
                ) : (
                  <img src="/images/default.jpg" />
                )}
              </div>
            }
            <div class="checkoutIndCnt">
              {cartItem.product.subscription_product !== true ? (
                <h3>
                  {cartItem.product.custom_translation[
                    this.state.selectedLanguage
                  ] != undefined ? (
                    <span
                      className="pointer-cursor"
                      onClick={() => {
                        this.productDetailsId(
                          cartItem.product.id,
                          cartItem.product.name
                        );
                      }}
                    >
                      {
                        cartItem.product.custom_translation[
                          this.state.selectedLanguage
                        ].name
                      }
                    </span>
                  ) : (
                    <span
                      className="pointer-cursor"
                      onClick={() => {
                        this.productDetailsId(
                          cartItem.product.id,
                          cartItem.product.name
                        );
                      }}
                    >
                      {cartItem.product.name}
                    </span>
                  )}
                </h3>
              ) : (
                <h3>
                  {cartItem.product.custom_translation[
                    this.state.selectedLanguage
                  ] != undefined ? (
                    <span>
                      {
                        cartItem.product.custom_translation[
                          this.state.selectedLanguage
                        ].name
                      }
                    </span>
                  ) : (
                    <span>{cartItem.product.name}</span>
                  )}
                </h3>
              )}
              {cartItem.product.subscription_product !== true && (
                <span>
                  {cartItem.product.audio_type ? (
                    <span>
                      {
                        cartItem.product.audio_type.custom_translation && cartItem.product.audio_type.custom_translation[this.state.selectedLanguage] ?
                          cartItem.product.audio_type.custom_translation[this.state.selectedLanguage].name
                          :
                          cartItem.product.audio_type.custom_translation ? cartItem.product.audio_type.custom_translation["EN"].name : cartItem.product.audio_type.name
                      }
                    </span>
                  ) : (
                    null
                  )}
                </span>
              )}
            </div>
            <div class="checkoutIndPrice">
              {this.state.isIndian !== null ?
                <Fragment>

                  {this.state.currcountry == "IN" ? strings.priceSign + "" + cartItem.product.inr_price.amount
                    : "$" + cartItem.product.price.amount}
                </Fragment>
                :
                <Fragment>

                  {this.state.currcountry === "IN"
                    ? strings.priceSign + " " + cartItem.product.inr_price.amount
                    : "$" + cartItem.product.price.amount}
                </Fragment>
              }
            </div>
            <div class="checkoutIndCross">
              <a
                href="javascript:;"
                title=""
                onClick={() => {
                  this.removeProductCart(index);
                }}
              >
                <i class="fa fa-trash-o removeProduct" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        {this.state.cartDetailsProducts["lines"] !== undefined &&
          this.state.cartDetailsProducts.lines.length > 0 ? (
          <div>
            {this.popCartModelData()}
            <div class="checkoutIndTotalWrp">
              <div class="checkoutIndTotal">{strings.subtotal}:</div>
              <div class="checkoutIndTotalPrice">
                {this.state.isIndian !== null ?
                  <Fragment>
                    {this.state.currcountry == "IN" ? strings.priceSign + "" + parseFloat(this.state.totalPrice).toFixed(2)
                      : "$" + parseFloat(this.state.totalPrice).toFixed(2)}
                  </Fragment>
                  :
                  <Fragment>

                    {this.state.currcountry === "IN"
                      ? strings.priceSign + " " + parseFloat(this.state.totalPrice).toFixed(2)
                      : "$" + parseFloat(this.state.totalPrice).toFixed(2)}
                  </Fragment>
                }
              </div>
            </div>
            <div class="checkoutIndBtnWrp">
              <div class="checkoutIndBtnLft">
                <a
                  className="whiteColor"
                  onClick={() => {
                    this.closeCartModal();
                  }}
                >
                  {strings.continueShopping}
                </a>
              </div>
              <div class="checkoutIndBtnRgt">
                <a href="/checkout" title="">
                  {strings.checkout}
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="cartIsEmpty">{strings.YourBasketIsEMpty}</div>
        )}
      </div>
    );
  }
}

function mapStatesToProps(state) {
  return {
    productCartCountReducer: state.productCartCountReducer.cartCount,
    authData: state.authonticationDetails,
    isIndian: state.isReisteredDevice.isIndian,
  };
}

const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(cart));
