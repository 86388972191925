import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import SimpleCrypto from "simple-crypto-js";
import axios from "axios";
import ReactGA from "react-ga";
import {
  api_base_url,
  googleAnalyticsTrackingId,
  emailReg,
  encryptDecryptKey,
  contactFormGuestToken,
  mobileRegularExpression,
  nameRegularExpression,
  googleRecaptcha,
} from "../../config";
import {
  Row,
  Col,
  NavLink,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import LocalizedStrings from "react-localization";
import languages from "../../language/language.json";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);
var Recaptcha = require("react-recaptcha");

class contact extends Component {
  constructor(props) {
    super(props);
    const userType =
      (this.props.location.state && this.props.location.state.params.user) !=
        undefined
        ? this.props.location.state.params.user
        : "customer";
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    this.state = {
      isLoading: false,
      collaborate: userType,
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      phone: "",
      phoneError: "",
      message: "",
      messageError: "",
      token: token || contactFormGuestToken,
      successMessage: "",
      isVerified: false,
      googleCaptchaError: "",
    };
    this.contactFormSubmit = this.contactFormSubmit.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.expiredCallback = this.expiredCallback.bind(this);
    this.handleCaptcha = this.handleCaptcha.bind(this);
    ReactGA.pageview(this.props.location.pathname);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }
  componentWillReceiveProps(nextProps) {
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }

  contactFormSubmit(event) {
    event.preventDefault();
    this.setState({
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      successMessage: "",
      phoneError: "",
      messageError: "",
      googleCaptchaError: "",
    });

    var isValid = true;
    if (this.state.firstName === "") {
      this.setState({
        firstNameError: strings.thisFieldRequire,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.firstName)) {
      this.setState({
        firstNameError: strings.AcceptAlphabet,
      });
      isValid = false;
    }
    if (this.state.phone === "") {
      this.setState({
        phoneError: strings.thisFieldRequire,
      });
      isValid = false;
    } else if (!mobileRegularExpression.test(this.state.phone)) {
      this.setState({
        phoneError: strings.validPhoneNumber,
      });
      isValid = false;
    }
    if (this.state.lastName === "") {
      this.setState({
        lastNameError: strings.thisFieldRequire,
      });
      isValid = false;
    } else if (!nameRegularExpression.test(this.state.lastName)) {
      this.setState({
        lastNameError: strings.lastNameAlphabet,
      });
      isValid = false;
    }
    if (this.state.message === "") {
      this.setState({
        messageError: strings.thisFieldRequire,
      });
      isValid = false;
    }
    if (this.state.email === "") {
      this.setState({
        emailError: strings.thisFieldRequire,
      });
      isValid = false;
    } else if (emailReg.test(this.state.email) === false) {
      this.setState({
        emailError: strings.enterValidEmail,
      });
      isValid = false;
    }

    if (isValid) {
      if (!this.state.isVerified) {
        this.setState({
          googleCaptchaError: strings.verifyhuman,
        });
      } else {
        var apiParams = {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          email: this.state.email,
          comments: this.state.message,
          mobile: this.state.phone,
          type: this.state.collaborate,
        };
        var headers = {
          "Content-Type": "application/json",
          "contact-api-token": this.state.token,
        };
        axios
          .post(api_base_url + "/api/contact-us/", apiParams, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status === true) {
              this.setState({
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                message: "",
              });
              swal("", strings.contactFormSuccess, "success");
            }
          })
          .catch((err) => {
            // console.log("Error - ", err);
          });
      }
    }
  }
  renderStatus() {
    switch (this.state.collaborate) {
      case "director":
        return strings.CollaborateSnovel;
      case "writer":
        return strings.Collaboratewriter;
      case "publisher":
        return strings.Collaboratepublisher;
      case "artist":
        return strings.Collaborateartist;
      default:
        return "";
    }
  }
  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  }
  expiredCallback() {
    this.setState({
      isVerified: false,
    });
  }

  handleCaptcha() { }
  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        ) : null}
        <div className="contactusTopWrp">
          <div className="container">
            <div className="topContainer">
              <Row>
                <Col md="2" className="toplogoDiv">
                  <div className="contactLogo">
                    <img src="images/contactuslogo.png" alt="" />
                  </div>
                </Col>
                <Col md="10" className="topcontentDiv">
                  <div className="contactTopleft">
                    <h4>Contact Us:</h4>
                    <h2>Snovel Creations Pvt. Ltd.</h2>
                    <div className="emiilDiv">
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      <span>
                        <a href="mailto:hello@snovel.in" target="_top">
                          hello@snovel.in
                        </a>
                      </span>
                    </div>
                    <div className="phoneDiv">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      <span>
                        <a href="tel:+91 8956978155">+91 8956978155</a>
                      </span>
                    </div>
                    <div className="contactAddress">
                      <p>
                        Address: A-102, DSK Gandhakosh, Sr. No. 153/55 (Part),
                        Baner, Pune-411045, India
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="contactFormDiv">
          <div className="container">
            <h4>{this.renderStatus()}</h4>
            <div className="contactform">
              <Form onSubmit={this.contactFormSubmit}>
                <FormGroup className="formDivleft">
                  <Label for="fname">{strings.firstName}</Label>
                  <Input
                    type="text"
                    name="fname"
                    id="fname"
                    maxLength="50"
                    value={this.state.firstName}
                    onChange={(event) => {
                      this.setState({ firstName: event.target.value });
                    }}
                  />
                  <div class="loginErrorMsg">{this.state.firstNameError}</div>
                  <Label for="email">{strings.regiEmail}</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    maxLength="50"
                    value={this.state.email}
                    onChange={(event) => {
                      this.setState({ email: event.target.value });
                    }}
                  />
                  <div class="loginErrorMsg">{this.state.emailError}</div>
                </FormGroup>
                <FormGroup className="formDivright">
                  <Label for="lname">{strings.lastName}</Label>
                  <Input
                    type="text"
                    name="lname"
                    id="lname"
                    maxLength="50"
                    value={this.state.lastName}
                    onChange={(event) => {
                      this.setState({ lastName: event.target.value });
                    }}
                  />
                  <div class="loginErrorMsg">{this.state.lastNameError}</div>
                  <Label for="phone">{strings.userMobile}</Label>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    maxLength="10"
                    value={this.state.phone}
                    onChange={(event) => {
                      this.setState({ phone: event.target.value });
                    }}
                  />
                  <div class="loginErrorMsg">{this.state.phoneError}</div>
                </FormGroup>
                <FormGroup className="formfullwidth">
                  <Label for="message">{strings.messageText}</Label>
                  <Input
                    type="textarea"
                    name="text"
                    id="message"
                    maxlength="500"
                    value={this.state.message}
                    onChange={(event) => {
                      this.setState({ message: event.target.value });
                    }}
                  />
                  <div class="loginErrorMsg">{this.state.messageError}</div>
                </FormGroup>
                <div className="contact-button-wrapper">
                  <Recaptcha
                    sitekey={googleRecaptcha}
                    render="explicit"
                    onloadCallback={this.handleCaptcha}
                    verifyCallback={this.verifyCallback}
                    expiredCallback={this.expiredCallback}
                  />
                  <div className="contactSubmit">
                    <Button type="submit">Submit</Button>
                  </div>
                  <div class="loginErrorMsg">
                    {this.state.googleCaptchaError}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStatesToProps(globalData) {
  return {
    authData: globalData.authonticationDetails,
  };
}
const myConnector = connect(mapStatesToProps);
export default myConnector(contact);
