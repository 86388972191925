import SimpleCrypto from "simple-crypto-js";
import { encryptDecryptKey } from "../config";
var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
var newUserDetails = localStorage.getItem("userDetails");
if (newUserDetails) {
  newUserDetails = simpleCrypto.decrypt(newUserDetails);
}
// TODO
var localStorageState = JSON.parse(newUserDetails);
// var localStorageState = newUserDetails;
const initialState =
  localStorageState && localStorageState.loginSuccess
    ? localStorageState
    : {
        loginSuccess: false,
        token: "",
        firstName: "",
        lastName: "",
        userEmail: "",
        userPassword: "",
        userLoggedOut: false,
        loginMessage: "",
        userProfile: "",
        signUpModal: false,
        subscribeStatus: "",
        loggedUserDetails: [],
        userCountry: "IN",
        userState: "",
        userMobile: "",
        instituteName: "",
        isLoggedInWithMobile: false,
      };

export default function authonticationDetails(state = initialState, action) {
  switch (action.type) {
    case "AUTHENTICATION_SUCCESS": {
      return {
        ...state,
        loginSuccess: true,
        userLoggedOut: false,
        userEmail: action.payload.email,
        token: action.payload.token,
        subscribeStatus: action.payload.subscribe,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        userProfile: action.payload.userProfile,
        userCountry: action.payload.userCountry,
        userState: action.payload.userState,
        userMobile: action.payload.userMobile,
        instituteName: action.payload.instituteName,
        isLoggedInWithMobile: action.payload.isLoggedInWithMobile,
      };
    }
    case "AUTHENTICATION_FAILED": {
      return { ...state };
    }
    case "SIGNUP_MODEL": {
      return { ...state, signUpModal: action.payload };
    }
    case "SIGNUP_SELECTED_MODEL": {
      return { ...state, signUpModal: action.payload };
    }
    case "USER_LOGGED_OUT": {
      return {
        ...state,
        loginSuccess: false,
        userLoggedOut: true,
        userEmail: "",
        token: "",
        subscribeStatus: "",
        userCountry: "IN",
        userState: "",
        userMobile: "",
        instituteName: "",
        isLoggedInWithMobile: ""
      };
    }
    default: {
      return { ...state };
    }
  }
}
