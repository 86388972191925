import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, NavLink } from "reactstrap";
import { api_base_url, encryptDecryptKey, getHoursFormat } from "../../config";
import axios from "axios";
import LocalizedStrings from "react-localization";
import { browseCategoryProductDetailsDataAction } from "../../action/browseCategoryAction";
import SimpleCrypto from "simple-crypto-js";
import StarRating from "../rating/startRating";
import languages from "../../language/language.json";
var strings = new LocalizedStrings(languages);
let rearrangedPlayer = [
  {
    innerComponents: [
      {
        type: "loop",
        style: { width: "fit-content" },
      },
      {
        type: "rewind",
        style: { width: "fit-content" },
      },
      {
        type: "play",
        style: { width: "fit-content" },
      },
      {
        type: "forward",
        style: { width: "fit-content" },
      },
      {
        type: "seek",
      },
      {
        type: "volume",
        style: { width: "fit-content" },
      },
    ],
  },
];

class MySnovelTab extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(this.props.selectedLanguage);
    var simpleCrypto = new SimpleCrypto(encryptDecryptKey);
    var userDetails = localStorage.getItem("userDetails");
    var token = "";
    if (userDetails) {
      userDetails = simpleCrypto.decrypt(userDetails);
      var userDetailsObject = JSON.parse(userDetails);
      if (
        userDetailsObject.loginSuccess == true &&
        userDetailsObject.token != ""
      ) {
        token = userDetailsObject.token || "";
      }
    }
    this.state = {
      token: token,
      sampleUrl: [
        {
          src: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
        },
      ],
      monthShortName: {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sept",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      },
      selectedProductIndex: "",
      selectedTrackIndex: 0,
      selectedProductId: "",
      isAllowUpdate: true,
      isPaused: false,
      isIndian: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isIndian !== null && nextProps.isIndian !== undefined) {
      this.setState({ isIndian: nextProps.isIndian });
    }
    if (
      Object.keys(nextProps.selectedProductToPlay.selectedProduct).length > 0 &&
      nextProps.selectedProductToPlay.isPlayFromSection == "mySnovel"
    ) {
      var newSelectedProduct = nextProps.selectedProductToPlay.selectedProduct;
      var newTrackIndex = nextProps.selectedProductToPlay.selectedTrackIndex;
      var newPauseStatus = nextProps.selectedProductToPlay.isPauseFromPlayer;
      this.setState({
        selectedProductId: newSelectedProduct.id,
        selectedTrackIndex: newTrackIndex,
        isPaused: newPauseStatus,
      });
    } else if (
      Object.keys(nextProps.selectedProductToPlay.selectedProduct).length > 0
    ) {
      this.setState({
        selectedProductIndex: "",
        selectedTrackIndex: 0,
        selectedProductId: "",
        isAllowUpdate: true,
        isPaused: false,
      });
    }
    var selectedLanguage = nextProps.selectedLanguage;
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    }
  }
  authorsSelected(authorsList) {
    var authorArray = [];
    authorsList.map((authorName) => {
      if (
        authorName.custom_translation[this.props.selectedLanguage] != undefined
      ) {
        authorArray.push(
          authorName.custom_translation[this.props.selectedLanguage].name
        );
      }
    });
    return authorArray.join(", ");
  }
  directorsSelected(directorList) {
    var directorArray = [];
    directorList.map((directorName) => {
      if (
        directorName.custom_translation[this.props.selectedLanguage] !=
        undefined
      ) {
        directorArray.push(
          directorName.custom_translation[this.props.selectedLanguage].name
        );
      }
    });
    return directorArray.join(", ");
  }
  artistsSelected(artistsList) {
    var artistsArray = [];
    artistsList.map((artistsName) => {
      if (
        artistsName.custom_translation[this.props.selectedLanguage] != undefined
      ) {
        artistsArray.push(
          artistsName.custom_translation[this.props.selectedLanguage].name
        );
      }
    });
    return artistsArray.join(", ");
  }
  categorySelected(categoryList) {
    var categoryArray = [];
    categoryList.map((categoryName) => {
      if (
        categoryName.custom_translation[this.props.selectedLanguage] !=
        undefined
      ) {
        categoryArray.push(
          categoryName.custom_translation[this.props.selectedLanguage].name
        );
      }
    });
    return categoryArray.join(", ");
  }
  numberSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  subscriptionDateString(dateString) {
    var dateStringArray = dateString.split("-");
    var newYear = dateStringArray[0];
    var newMonth = parseInt(dateStringArray[1]) + "";
    var newDay = parseInt(dateStringArray[2]);
    var newString =
      this.numberSuffix(newDay) +
      " " +
      this.state.monthShortName[newMonth] +
      ", " +
      newYear;
    return newString;
  }
  goToLibrary() {
    window.scrollTo(0, 0);
    this.props.goToLibraryTab("2");
  }
  getDuration(duration, isShowHrsText) {
    var newDuration = "";
    if (duration == 0) {
      newDuration = duration;
    } else {
      var newMinutes = duration / 60;
      newDuration = parseFloat(newMinutes / 60).toFixed(2);
    }
    if (isShowHrsText) {
      return newDuration + " " + strings.hrsText;
    } else {
      return newDuration;
    }
  }
  showPlayerForProduct(productIndex) {
    if (this.props.mySnovelProducts[productIndex].producttracks.length > 0) {
      if (
        this.state.selectedProductIndex === "" ||
        this.state.selectedProductIndex !== productIndex
      ) {
        var headers = {
          "Content-Type": "application/json",
        };
        axios
          .get(api_base_url + "/api/player-analytics/", { headers: headers })
          .then((response) => {
            this.showPlayerForProductAfterAnalytics(productIndex);
          })
          .catch((err) => {
            // console.log("error ", err);
            this.showPlayerForProductAfterAnalytics(productIndex);
          });
      } else {
        this.setState({
          selectedProductIndex: "",
          selectedTrackIndex: 0,
          isPaused: false,
        });
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [{}, 0, false, false, false, false, "mySnovel"],
        });
      }
    }
  }
  showPlayerForProductAfterAnalytics(productIndex) {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + this.state.token,
    };
    var apiParams = {
      product_id: this.props.mySnovelProducts[productIndex].id,
    };
    axios
      .post(api_base_url + "/api/last-played/", apiParams, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var latestTrackId = response.data.data.track_id || "";
          var newSelectedTrackIndex = 0;
          for (
            var i = 0;
            i < this.props.mySnovelProducts[productIndex].producttracks.length;
            i++
          ) {
            var eachTrack = this.props.mySnovelProducts[productIndex]
              .producttracks[i];
            if (eachTrack.id === latestTrackId) {
              newSelectedTrackIndex = i;
            }
          }
          this.setState({
            selectedProductIndex: productIndex,
            selectedTrackIndex: newSelectedTrackIndex,
            isPaused: false,
          });
          let newSelectedProduct = this.props.mySnovelProducts[productIndex];
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              newSelectedProduct,
              newSelectedTrackIndex,
              false,
              false,
              false,
              false,
              "mySnovel",
            ],
          });
        } else {
          this.setState({
            selectedProductIndex: productIndex,
            selectedTrackIndex: 0,
            isPaused: false,
          });
          let newSelectedProduct = this.props.mySnovelProducts[productIndex];
          this.props.dispatch({
            type: "PLAY_SELECTED_PRODUCT",
            payload: [
              newSelectedProduct,
              0,
              false,
              false,
              false,
              false,
              "mySnovel",
            ],
          });
        }
      })
      .catch((err) => {
        // console.log("error ", err);
        this.setState({
          selectedProductIndex: productIndex,
          selectedTrackIndex: 0,
          isPaused: false,
        });
        let newSelectedProduct = this.props.mySnovelProducts[productIndex];
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            newSelectedProduct,
            0,
            false,
            false,
            false,
            false,
            "mySnovel",
          ],
        });
      });
  }
  playProductTrack(trackIndex) {
    if (this.state.selectedTrackIndex !== trackIndex) {
      let newSelectedProduct = this.props.mySnovelProducts[
        this.state.selectedProductIndex
      ];
      this.props.dispatch({
        type: "PLAY_SELECTED_PRODUCT",
        payload: [
          newSelectedProduct,
          trackIndex,
          false,
          false,
          false,
          false,
          "mySnovel",
        ],
      });
      this.setState({
        selectedTrackIndex: trackIndex,
        isPaused: false,
      });
    } else {
      let newSelectedProduct = this.props.mySnovelProducts[
        this.state.selectedProductIndex
      ];
      if (this.state.isPaused) {
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            newSelectedProduct,
            trackIndex,
            false,
            true,
            false,
            false,
            "mySnovel",
          ],
        });
        this.setState({
          isPaused: false,
        });
      } else {
        let newSelectedProduct = this.props.mySnovelProducts[
          this.state.selectedProductIndex
        ];
        this.props.dispatch({
          type: "PLAY_SELECTED_PRODUCT",
          payload: [
            newSelectedProduct,
            trackIndex,
            true,
            false,
            false,
            false,
            "mySnovel",
          ],
        });
        this.setState({
          isPaused: true,
        });
      }
    }
  }
  /***********  Product details page link *****/
  productDetailsId(productID, slug) {
    this.props.history.push("/product-details/" + productID + "/" + slug);
  }
  /**********************
   *  Author details page *
   **********************/
  authorDetailsId(authorID, authorName) {
    var titleUrl = authorName.toLowerCase();
    var authorTitle = titleUrl.replace(".", "");
    var authorName = authorTitle.split(" ").join("-");
    this.props.history.push("/author/" + authorID + "/" + authorName);
  }
  render() {
    return (
      <Row>
        <Col sm="12" className="mySnovel">
          {this.props.mySnovelProducts.length == 0 && <p></p>}
          {this.props.mySnovelProducts.length > 0 && (
            <div>
              <p>{strings.myLastPlayedTitle}</p>
              {this.props.mySnovelProducts.map((eachProduct, selectedIndex) => {
                if (
                  eachProduct.id == this.state.selectedProductId &&
                  this.state.isAllowUpdate
                ) {
                  this.setState({
                    selectedProductIndex: selectedIndex,
                    isAllowUpdate: false,
                  });
                }
                return (
                  <div>
                    <div class="mySnovelVideo">
                      <Row className="left-right-margin-none">
                        <Col md="10" lg="10">
                          <div
                            class="videoThumbDiv"
                            onClick={() => {
                              this.showPlayerForProduct(selectedIndex);
                            }}
                          >
                            {eachProduct.images.length > 0 ? (
                              <img
                                class="purchase-library-image"
                                src={eachProduct.images[0]["image2"]}
                                alt=""
                              />
                            ) : (
                              <img
                                class="purchase-library-image"
                                src="/images/default.jpg"
                                alt=""
                              />
                            )}
                            <a title="" class="playButn">
                              {this.state.selectedProductIndex !==
                                selectedIndex && (
                                  <img src="/images/play-button.svg" alt="" />
                                )}
                              {this.state.selectedProductIndex ===
                                selectedIndex && (
                                  <img
                                    src="/images/round-pause-button.svg"
                                    alt=""
                                  />
                                )}
                            </a>
                          </div>
                          <div class="videoDesc">
                            <h4>
                              <span
                                onClick={() => {
                                  this.productDetailsId(
                                    eachProduct.id,
                                    eachProduct.product_slug
                                  );
                                }}
                              >
                                {
                                  eachProduct.custom_translation[
                                  this.props.selectedLanguage
                                  ]["name"]
                                }
                              </span>
                            </h4>
                            <span class="cateTitle">
                              {strings[eachProduct.product_audio_type]}
                            </span>
                            {eachProduct.authors.length > 0 && (
                              <div class="videoCnt">
                                <div className="headingText">
                                  <span class="author">
                                    {strings.authorText}
                                  </span>
                                </div>
                                <div className="colon-symbol">:</div>
                                <div className="loopdata">
                                  <span
                                    class="authorDes authorDesLink"
                                    onClick={() => {
                                      this.authorDetailsId(
                                        eachProduct.authors[0].id,
                                        eachProduct.authors[0].name
                                      );
                                    }}
                                  >
                                    {this.authorsSelected(eachProduct.authors)}
                                  </span>
                                </div>
                              </div>
                            )}
                            {eachProduct.directors.length > 0 && (
                              <div class="videoCnt">
                                <div className="headingText">
                                  <span class="author">
                                    {strings.directorText}
                                  </span>
                                </div>
                                <div className="colon-symbol">:</div>
                                <div className="loopdata">
                                  <span class="authorDes">
                                    {this.directorsSelected(
                                      eachProduct.directors
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                            {eachProduct.artists.length > 0 && (
                              <div class="videoCnt">
                                <div className="headingText">
                                  <span class="author">
                                    {strings.artistsText}
                                  </span>
                                </div>
                                <div className="colon-symbol">:</div>
                                <div className="loopdata">
                                  <span class="authorDes">
                                    {this.artistsSelected(eachProduct.artists)}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div class="videoCnt">
                              <div className="headingText">
                                <span class="author">{strings.genreText}</span>
                              </div>
                              <div className="colon-symbol">:</div>
                              <div className="loopdata">
                                {eachProduct.category.map(
                                  (categoryList, index) => {
                                    if (
                                      categoryList.custom_translation[
                                      this.props.selectedLanguage
                                      ] != undefined
                                    ) {
                                      return (
                                        <NavLink
                                          tag={Link}
                                          to={{
                                            pathname: "/browse",
                                          }}
                                          onClick={() => {
                                            this.props.dispatch({
                                              type: "MENU_SELECTED_CATEGORY",
                                              payload: [
                                                categoryList.name,
                                                eachProduct.product_audio_type,
                                                categoryList.id,
                                              ],
                                            });
                                            this.props.dispatch(
                                              browseCategoryProductDetailsDataAction(
                                                eachProduct.product_audio_type,
                                                categoryList.name,
                                                1
                                              )
                                            );
                                          }}
                                          key={index}
                                          className="genreLinkText"
                                        >
                                          <span key={index}>
                                            {
                                              categoryList.custom_translation[
                                                this.props.selectedLanguage
                                              ].name
                                            }
                                          </span>
                                        </NavLink>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="2" lg="2">
                          <div class="mySnoveltrackDiv">
                            <span>
                              <strong>
                                {eachProduct.producttracks.length}
                              </strong>
                              <br />
                              {strings.trackText}
                            </span>
                          </div>
                          <div class="mySnoveldurDiv">
                            <span>
                              {strings.durationText}
                              <br />
                              <strong>
                                {getHoursFormat(
                                  eachProduct.total_tracks_length
                                ) +
                                  " " +
                                  strings.hrsText}
                              </strong>
                            </span>
                          </div>
                          <StarRating rating={eachProduct.total_reviews} />
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                    {this.state.selectedProductIndex === selectedIndex && (
                      <div>
                        {eachProduct.producttracks.map(
                          (eachTrack, trackIndex) => {
                            return (
                              <div
                                class={
                                  this.state.selectedTrackIndex == trackIndex
                                    ? "videoPlayList active-play-list"
                                    : "videoPlayList"
                                }
                              >
                                <div
                                  class="videoPlayP"
                                  onClick={() => {
                                    this.playProductTrack(trackIndex);
                                  }}
                                >
                                  <img
                                    src={
                                      this.state.selectedTrackIndex ==
                                        trackIndex && !this.state.isPaused
                                        ? "/images/p_video.png"
                                        : "/images/v_play.png"
                                    }
                                  />
                                </div>
                                <div class="videoPlayTitle">
                                  <span>{trackIndex + 1}</span>
                                  {eachTrack.custom_translation[
                                    this.props.selectedLanguage
                                  ]
                                    ? eachTrack.custom_translation[
                                      this.props.selectedLanguage
                                    ].name
                                    : eachTrack.custom_translation["EN"].name}
                                </div>
                                <div class="videoPlayDuration">
                                  {strings.durationText}
                                  <br />
                                  <span>
                                    {getHoursFormat(eachTrack.track_length) +
                                      " " +
                                      strings.hrsText}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
              <div className="clearfix"></div>
              <div className="library-player"></div>
            </div>
          )}
          <Row className="left-right-margin-none">
            <Col className="left-padding-none" lg="3">
              <h3>{strings.mySnovelRunTime}</h3>
              {this.props.runTimeDuration != 0 && (
                <div class="mySnovelBx">
                  <p class="snovel-run-time">
                    <strong>
                      {getHoursFormat(this.props.runTimeDuration)}
                    </strong>
                    <small>{strings.hrsText}</small>
                  </p>
                </div>
              )}
              {this.props.runTimeDuration == 0 && <p>Not played yet</p>}
            </Col>
            <Col className="left-padding-none-mobile" lg="3">
              <h3>{strings.myPurchasedLibrary}</h3>
              {this.props.purshaseProductCount !== "" && (
                <div class="mySnovelBx mySnovelBxSec">
                  <p>
                    <span>
                      {this.props.purshaseProductCount
                        ? this.props.purshaseProductCount
                        : 0}
                    </span>
                    <br />
                    {strings.titles}
                  </p>
                </div>
              )}
              {this.props.purshaseProductCount == "" && (
                <p>{strings.notPurchasedYet}</p>
              )}
              <a
                class="goToLib"
                onClick={() => {
                  this.goToLibrary();
                }}
              >
                {strings.goToLibrary}
              </a>
            </Col>
            <Col className="left-padding-none-mobile" lg="3">
              <h3>{strings.mySubscription}</h3>
              {this.props.subscription.length > 0 && (
                <div class="mySnovelBx mySnovelBxThird">
                  <p>
                    {strings.Planname}:<br />
                    <strong>{this.props.subscription[0].name}</strong>
                  </p>
                  <p>
                    {strings.SubcriptionAmount}:<br />
                    <span>
                      {this.state.isIndian !== null ?
                        <Fragment>
                          {this.state.isIndian ? strings.priceSign + "" + this.props.subscription[0].inr_price.amount
                            : "$" + this.props.subscription[0].price.amount}
                        </Fragment>
                        :
                        <Fragment>
                          {this.props.authData.userCountry === "IN"
                            ? strings.priceSign + " " + this.props.subscription[0].inr_price.amount
                            : "$ " + this.props.subscription[0].price.amount}
                        </Fragment>
                      }
                    </span>
                  </p>
                  <p>
                    {strings.subscriptionstartDate}:<br />
                    <span>
                      {this.subscriptionDateString(
                        this.props.subscription[0].start_date
                      )}
                    </span>
                  </p>
                  <p>
                    {strings.subscriptionEndDate}:<br />
                    <span>
                      {this.subscriptionDateString(
                        this.props.subscription[0].end_date
                      )}
                    </span>
                  </p>
                </div>
              )}
              {this.props.subscription.length == 0 && (
                <div>
                  <p>{strings.Subscriptionnotactivated}</p>
                </div>
              )}
              {this.props.subscription.length > 0 && (
                <div>
                  {this.props.subscription[0].subscription_status && (
                    <p class="mySnovelBxThirdLast">
                      {this.props.selectedLanguage === "EN"
                        ? `You have ${this.props.renewSubscriptionDays} days to renew your subscription.`
                        : `तुमच्या सदस्यत्त्व नूतनीकरणासाठी ${this.props.renewSubscriptionDays} दिवस शिल्लक आहेत.`}
                    </p>
                  )}
                  {!this.props.subscription[0].subscription_status && (
                    <p class="mySnovelBxThirdLast">
                      {strings.subscriptionExpired}
                    </p>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapStatesToProps(state) {
  return {
    selectedProductToPlay: state.selectedProductToPlay,
    authData: state.authonticationDetails,
    isIndian: state.isReisteredDevice.isIndian,
  };
}
const myConnector = connect(mapStatesToProps);
export default withRouter(myConnector(MySnovelTab));
