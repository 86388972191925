import React, { Component } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel2";
import classnames from "classnames";
import axios from "axios";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Route, Link, Redirect, withRouter } from "react-router-dom";
import InnerProductSlider from "./innerProductSlider";
import FeaturedProductSlider from "./featuredProductSlider";
import PopularTitleAuthor from "./popularTitleAuthorComponant";
import {
  api_base_url,
  dashboardToken,
  googleAnalyticsTrackingId,
} from "../config";
import { browseCategoryProductDetailsDataAction } from "../action/browseCategoryAction";
import LocalizedStrings from "react-localization";
import languages from "../language/language.json";
import { familyCategoryAction } from "../action/freeSnovelHomeAction";
import swal from "sweetalert";
ReactGA.initialize(googleAnalyticsTrackingId);
var strings = new LocalizedStrings(languages);

const items = [
  {
    src: "/images/banner1.png",
    caption: "Loose yourself, listen!",
    header: "Experience the treat for your ears!",
  },
  {
    src: "/images/banner2.png",
    caption: "Listen anywhere, on any device!",
    header: "Experience the treat for your ears!",
  },
  {
    src: "/images/banner3.png",
    caption: "Hear.Here.",
    header: "Experience the treat for your ears!",
  },
  {
    src: "/images/banner4.png",
    caption: "Hear.Here.",
    header: "Experience the treat for your ears!",
  },
];

const optionss = {
  nav: true,
  rewind: true,
  autoplay: true,
  navText: ["<div></div>", "<div></div>"],
  margin: 15,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};
const sliderLaunched = {
  nav: true,
  autoplay: false,
  margin: 15,
  navText: ["<div></div>", "<div></div>"],
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  },
};
class freeSnovel extends Component {
  constructor(props) {
    super(props);
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      activeIndex: 0,
      selectedLanguage: selectedLanguage || "EN",
      familyCategoryList: [],
      kidsPopularAudio: {},
      isCategoryAPIcall: false,
    };
    ReactGA.pageview("/family");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.state.isCategoryAPIcall) {
    this.props.dispatch(familyCategoryAction());
    }
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (
      nextProps.familyHomeCategoryReducer !== prevState.familyHomeCategoryReducer &&
      nextProps.familyHomeCategoryReducer.length > 0
    ) {
      this.setState({
        familyCategoryList: nextProps.familyHomeCategoryReducer,
        isCategoryAPIcall: true,
      }); 
    }
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }
  render() {
    return (
      <div>
        {this.state.selectedLanguage === "EN" ? (
          <OwlCarousel id="homePage" ref="car" options={optionss}>
            <div>
              <img src="/images/familyBanner01.png" />
            </div>
            <div>
              <img src="/images/familyBanner02.png" />
            </div>
          </OwlCarousel>
        ) : (
          <OwlCarousel id="homePage" ref="car" options={optionss}>
            <div>
              <img src="/images/familyBanner01.png" />
            </div>
            <div>
              <img src="/images/familyBanner02.png" />
            </div>
          </OwlCarousel>
        )}
      </div>
    );
  }
}

class homeCategory extends Component {
  constructor(props) {
    super(props);
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      activeTab: 0,
      arrayData: [],
      homeCategoryList: [],
      familyCategoryList: [],
      kidsPopularAudio: {},
      isCategoryAPIcall: false,
      isLoading: false,
      selectedLanguage: selectedLanguage || "EN",
      categoryHeaderImage: {
        audiobook: "/images/Audiobook_Normal.svg",
        snoveloriginal: "/images/ico_SnovelOriginals-Normal.png",
        podcast: "/images/ico_Podcast-Normal.png",
      },
      categoryHeaderImageSelected: {
        audiobook: "images/Audiobook_Active.svg",
        snoveloriginal: "/images/ico_SnovelOriginals-Active.png",
        podcast: "/images/ico_Podcast-Active.png",
      },
      cookiesPopup: false
    };
    this.tabToggle = this.tabToggle.bind(this);
    this.closeCookiesPolicyPopup = this.closeCookiesPolicyPopup.bind(this);
  }

  componentDidMount(){
    if (!this.state.isCategoryAPIcall) {
      this.setState({
        isLoading: true
      })
      this.props.dispatch(familyCategoryAction());
      }
  }

  componentWillReceiveProps(nextProps, prevState) {

    if (
      nextProps.familyHomeCategoryReducer !== prevState.familyHomeCategoryReducer &&
      nextProps.familyHomeCategoryReducer.length > 0
    ) {
      this.setState({
        familyCategoryList: nextProps.familyHomeCategoryReducer,
        isCategoryAPIcall: true,
        isLoading: false,
      }); 
    }

    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }

  componentWillMount() {
    this.homeProductDetailsData();
  }

  homeProductDetailsData() {
    // this.setState({ isLoading: true });
    var headers = {
      "Content-Type": "application/json",
      "index-api-token": dashboardToken,
    };
    axios
      .get(api_base_url + "/api/v2/index-product-type/", { headers: headers })
      .then((response) => {
        if (response) {
          var categoryImages = [];
          response.data.product_type.map((element, index) => {
            var eachCategoryNewElement = [];
            element.product_type_categories.map((eachCategory) => {
              eachCategory["image_src"] = eachCategory.icon_image;
              eachCategoryNewElement.push(eachCategory);
            });
            response.data.product_type[index][
              "product_type_categories"
            ] = eachCategoryNewElement;
          });
          this.setState({
            homeCategoryList: response.data.product_type,
            arrayData: categoryImages,
          });
        } else {
          // console.log("Not Loaded");
        }
        // this.setState({
        //   isLoading: false,
        // });
        // this.showCookiePolicyPopup();
      })
      .catch((err) => {
        // console.log("fetched.", err);
        this.setState({
          isLoading: false,
        });
        // this.showCookiePolicyPopup();
      });
  }

  showCookiePolicyPopup() {
    if (!window.localStorage.getItem("isShowCookiePolicy")) {
      window.localStorage.setItem("isShowCookiePolicy", true);
      this.setState({
        cookiesPopup: true,
      });
    }
  }

  closeCookiesPolicyPopup() {
    this.setState({
      cookiesPopup: false,
      loadingPopup: true,
    });
  }

  tabToggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }



  headerSection() {
    return this.state.homeCategoryList.map((element, index) => {
      return (
        <NavItem>
          <NavLink
            className={classnames({ active: this.state.activeTab === index })}
            onClick={() => {
              this.tabToggle(index);
            }}
          >
            <div className="tabIcon_image">
              {this.state.activeTab === index ? (
                <img
                  src={
                    this.state.categoryHeaderImageSelected[
                    element.product_type_value
                    ]
                  }
                />
              ) : (
                <img
                  src={
                    this.state.categoryHeaderImage[element.product_type_value]
                  }
                />
              )}
            </div>
            <div className="tabRight_Content">
              {strings[element.product_type_value]}
            </div>
          </NavLink>
        </NavItem>
      );
    });
  }

  categorySelection(categoryName, postType, categoryId) {
    this.props.selectCategory(categoryName, postType, categoryId);
  }
  productDetailsId(productID, slug) {
    localStorage.setItem('productId', productID);
    this.props.history.push('/product-details/' + productID + '/' + slug);
}
 
  subProducts(categoryProduct) {
    return categoryProduct.map((sliderElement, slideIndex) => {
      return (
        <div>
          {sliderElement.images.length > 0 ? (
            <div className="sliderThumb">
              {sliderElement.is_free && (
                <span className="freeProduct">{strings.FreeText}</span>
              )}
              <img
                src={sliderElement.images[0]["image2"]}
                className="sampleProduct_thumb"
                onClick={() => {
                  this.productDetailsId(
                    sliderElement.id,
                    sliderElement.product_slug
                  );
                }}
              />
            </div>
          ) : (
            <div className="sliderThumb">
              {sliderElement.is_free && (
                <span className="freeProduct">{strings.FreeText}</span>
              )}
              <img
                src="/images/default.jpg"
                className="sampleProduct_thumb"
                onClick={() => {
                  this.productDetailsId(
                    sliderElement.id,
                    sliderElement.product_slug
                  );
                }}
              />
            </div>
          )}
          <div className="sliderTrack_Wrap">
            {sliderElement.sampletracks.length > 0 && (
              <div>
                {sliderElement.sampletracks[0].map_url !=
                  this.state.kidsCategoryLastPlayed && (
                    <img
                      onClick={() => {
                        this.kidsCategoryLaunchPlayAudio(
                          sliderElement.sampletracks[0].map_url
                        );
                      }}
                      src="/images/play.png"
                    />
                  )}
                {sliderElement.sampletracks[0].map_url ==
                  this.state.kidsCategoryLastPlayed && (
                    <img
                      onClick={() => {
                        this.kidsCategoryLaunchPauseAudio(
                          sliderElement.sampletracks[0].map_url
                        );
                      }}
                      src="/images/round-pause-button.svg"
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      );
    });
  }


  kidsCategoryLaunchPlayAudio(map_url) {

    if (this.state.kidsPopularAudio[map_url]) {
      this.setState({
        kidsCategoryLastPlayed: map_url,
        latestLaunchLastPlayed: "",
        kidsPopularLastPlayed: "",
      });
      // this.props.onPlaySample('innerProduct');
      var playPromise = this.state.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () { })
          .catch(function (error) {
            const isFirefox = typeof InstallTrigger !== "undefined";
            if (isFirefox) {
              swal(
                "",
                "Something went wrong with your browser. Please try another one.",
                "error"
              );
            }
          });
      }
    } else {
      if (this.state.audioObject) {
        this.state.audioObject.pause();
      }
      var apiParams = {
        map_url: map_url,
      };
      var headers = {
        "Content-Type": "application/json",
        "index-api-token": dashboardToken,
      };
      axios
        .post(api_base_url + "/api/get-sample-signed-url/", apiParams, {
          headers: headers,
        })
        .then((response) => {
          if (response.data) {
            var url = response.data.data.signed_url || "";
            var audio = new Audio(url);
            var parentThis = this;
            audio.addEventListener("ended", function () {
              parentThis.setState({
                kidsCategoryLastPlayed: "",
              });
            });
            audio.type = "audio/mpeg";
            var newLatestLaunchAudio = {};
            newLatestLaunchAudio[map_url] = url;
            this.setState({
              audioObject: audio,
              kidsPopularAudio: newLatestLaunchAudio,
              kidsCategoryLastPlayed: map_url,
              latestLaunchLastPlayed: "",
              kidsPopularLastPlayed: "",
            });
            // this.props.onPlaySample('innerProduct');
            var playPromise = audio.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () { })
                .catch(function (error) {
                  const isFirefox = typeof InstallTrigger !== "undefined";
                  if (isFirefox) {
                    swal(
                      "",
                      "Something went wrong with your browser. Please try another one.",
                      "error"
                    );
                  }
                });
            }
          }
        })
        .catch((err) => {
          // console.log("Error - ", err);
        });
    }
  }

  kidsCategoryLaunchPauseAudio(map_url) {
    this.state.audioObject.pause();
    this.setState({
      kidsCategoryLastPlayed: "",
    });
  }
  getCategoryPage(catID, customTranslation, post_type, custTrans) {
    // console.log(catID, customTranslation, post_type);
    var titleUrl = customTranslation.toLowerCase();
    var categorySlug = titleUrl.split(" ").join("-");
    this.props.dispatch({
      type: "MENU_SELECTED_CATEGORY",
      payload: [customTranslation, post_type, 1],
    });
    this.props.dispatch(
      browseCategoryProductDetailsDataAction(post_type, customTranslation, 1)
    );
    this.props.history.push("/family-category-details/" + categorySlug);
    localStorage.setItem('post_type',post_type);
    localStorage.setItem("categoryID", catID);
    localStorage.setItem("categoryName", JSON.stringify(customTranslation));
    localStorage.setItem("customTranslation",JSON.stringify(custTrans));
  }

 
  render() {
    return (
      <div>
        {this.props.loader.isLoader ? (
          <div id="loading">
            <div id="loading-center">
              <div id="loading-center-absolute">
                <img src="images/loader.gif" />
              </div>
            </div>
          </div>
        ) : null}
        <div>
        {this.state.selectedLanguage === "EN" ? (
          <OwlCarousel id="homePage" ref="car" options={optionss}>
            <div>
              <img src="/images/familyBanner01.png" />
            </div>
            <div>
              <img src="/images/familyBanner02.png" />
            </div>
          </OwlCarousel>
        ) : (
          <OwlCarousel id="homePage" ref="car" options={optionss}>
            <div>
              <img src="/images/familyBanner01.png" />
            </div>
            <div>
              <img src="/images/familyBanner02.png" />
            </div>
          </OwlCarousel>
        )}
      </div>
      <HomeLatestLaunchConnected />
        
        <Modal isOpen={this.state.cookiesPopup} size={"sm"} id="myCookies">
          <ModalBody>
            <div class="leftCookies">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "images/ico_SnovelOriginals-Normal.png"
                }
              />
            </div>
            <div class="rightCookies">
              <h3>This website uses cookies</h3>
              <p>
                This website uses cookies to improve user experience. By using
                our website you consent to all cookies in accordance with our{" "}
                <a href="/privacy-policy" title="">
                  Cookie Policy.
                </a>
              </p>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                onClick={this.closeCookiesPolicyPopup}
              >
                Alright
              </button>
            </div>
          </ModalBody>
        </Modal>
        <div  className="kids-main-wrapper">
        <div className="container"> 
        {this.state.familyCategoryList && this.state.familyCategoryList.length > 0 && (
            <div className="sliderContentWrapper kidsCategorySliders">
            {this.state.familyCategoryList.map((cat, catIndex) => {
              return (
                <>
                {cat["sliders"].length > 0 && (
                <div className="kidsCategoryMain-wrapper">
                  
                    <h4 className={"float-left custom-category-title"}>
                        {
                          cat.custom_translation[this.state.selectedLanguage] ?
                            cat.custom_translation[this.state.selectedLanguage].name
                            :
                            cat.custom_translation["EN"].name
                        }
                    </h4>
                  
                  {cat["sliders"].length > 5 && (
                    <button
                      className="family_category_view_all_button"
                      onClick={() => {
                        this.getCategoryPage(cat.id, cat.name,cat.product_type_value, cat.custom_translation);
                      }}
                    >
                      {strings.viewAll}
                    </button>
                  )}
                  {cat["sliders"].length > 0 && (
                    <OwlCarousel options={sliderLaunched}>
                      {this.subProducts(cat["sliders"])}
                    </OwlCarousel>
                  )}
                </div>
                )}
                </>
              );
            })}
          </div>

          )}
        </div>
        </div>
      </div>
    );
  }
}

class homeLatestLaunch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPauseInnerProduct: false,
      isPausePopularTitle: false,
    };
    this.onPlaySampleEvent = this.onPlaySampleEvent.bind(this);
    this.onPauseSampleEvent = this.onPauseSampleEvent.bind(this);
  }

  onPlaySampleEvent(sectionName) {
    if (sectionName == "innerProduct") {
      this.setState({
        isPausePopularTitle: true,
        isPauseInnerProduct: false,
      });
    } else if (sectionName == "popularTitle") {
      this.setState({
        isPauseInnerProduct: true,
        isPausePopularTitle: false,
      });
    }
  }
  onPauseSampleEvent(sectionName) {
    if (sectionName == "innerProduct") {
      this.setState({
        isPauseInnerProduct: false,
      });
    } else if (sectionName == "popularTitle") {
      this.setState({
        isPausePopularTitle: false,
      });
    }
  }

  render() {
    return (
      <div>
        <PopularTitleAuthor
          onPauseSample={this.onPauseSampleEvent}
          onPlaySample={this.onPlaySampleEvent}
          isPausePopularTitle={this.state.isPausePopularTitle}
        />
         <FeaturedProductSlider
          onPauseSample={this.onPauseSampleEvent}
          onPlaySample={this.onPlaySampleEvent}
          isPauseInnerProduct={this.state.isPauseInnerProduct}
        />
        <InnerProductSlider
          onPauseSample={this.onPauseSampleEvent}
          onPlaySample={this.onPlaySampleEvent}
          isPauseInnerProduct={this.state.isPauseInnerProduct}
        />
      </div>
    );
  }
}

class homeContent extends Component {
  constructor(props) {
    super(props);
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
    }
    this.state = {
      selectedLanguage: selectedLanguage || "EN",
    };
    this.onSelectCategory = this.onSelectCategory.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    var selectedLanguage = window.localStorage.getItem("language");
    if (selectedLanguage) {
      strings.setLanguage(selectedLanguage);
      this.setState({
        selectedLanguage: selectedLanguage,
      });
    } else {
      strings.setLanguage("EN");
      window.localStorage.setItem("language", "EN");
      this.setState({
        selectedLanguage: "EN",
      });
    }
  }

  onSelectCategory(categoryName, postType, categoryId) {
    this.props.dispatch({
      type: "MENU_SELECTED_CATEGORY",
      payload: [categoryName, postType, categoryId],
    });
    this.props.dispatch(
      browseCategoryProductDetailsDataAction(postType, categoryName, 1)
    );
    this.props.history.push("/browse");
  }
  render() {
    return (
      <div>
        <FreeHomeSliderConnected />
        <HomeCategoryConnected selectCategory={this.onSelectCategory} />
        <HomeLatestLaunchConnected />

        <div className="container-fluid stellar-wrapper-footer">
          <a className="subscribe_btn-audio " href="/subscribe">{strings.subscribeNow}</a>
          {
            strings._language === strings._defaultLanguage ? (
              <img src="/images/stellar audio.png"
                className="home-stellar-kids-img"

              />
            ) : (
              <img src="/images/snovel web home marathi 1-06.png"
                className="home-stellar-kids-img"

              />
            )
          }
        </div>
      </div>
    );
  }
}

function mapStatesToProps(globalData) {
  return {
    homeMainCategoryList: globalData.homeMainCategoryList.freeCategoryList,
    languageTab: globalData.languageTab,
    loader: globalData.loader,
    familyHomeCategoryReducer: globalData.familyHomeCategoryReducer.familyCategoryList,
    authData: globalData.authonticationDetails,
  };
}

const myConnector = connect(mapStatesToProps);
const FreeHomeSliderConnected = myConnector(freeSnovel);
const HomeCategoryConnected = myConnector(homeContent);
const HomeLatestLaunchConnected = myConnector(homeLatestLaunch);
export default withRouter(myConnector(homeCategory));
