import axios from 'axios';
import { api_base_url } from '../config';

export function auditionFormAction(userToken) {
    return function (dispatch) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': "Token " + userToken
        };
        axios.get(api_base_url + '/api/upload-audition/', { headers: headers }).then(response => {
            if (response.data.status === true) {
                dispatch({
                    type: "USER_DETAILS_FOR_AUDITION",
                    payload: response.data.data
                });
            } else {
                dispatch({
                    type: "FAILED_USER_DETAILS_FOR_AUDITION",
                    payload: response.data
                });
            }
        }).catch(err => {
            // console.log('fetched.', err);
        });
    }
}